import React, { useState, useEffect } from 'react'
import { Header, Content, ToogleButton, ToogleButtonBar, Nav, List, ListItem, NavLink } from './styled'

export function LandingHeader({active}) {

    const [activeMenu, setActiveMenu] = useState(false);

    useEffect(() => {

        activeMenu ? document.body.classList.add('disabled-scroll') : document.body.classList.remove('disabled-scroll')

        return () =>  document.body.classList.remove('disabled-scroll')

     }, [activeMenu]);

    return (
        <>
            <Header active={activeMenu}>
                <Content>
                    <ToogleButton onClick={() => setActiveMenu(prevActiveMenu => !prevActiveMenu)}>
                        <ToogleButtonBar ariaHidden="true" className="bar-1"></ToogleButtonBar>
                        <ToogleButtonBar ariaHidden="true" className="bar-2"></ToogleButtonBar>
                        <ToogleButtonBar ariaHidden="true" className="bar-3"></ToogleButtonBar>
                    </ToogleButton>
                    <Nav>
                        <List>
                            <ListItem className="init"><NavLink activeLink={active === 'home'} to="/">Inicio</NavLink></ListItem>
                            <ListItem><NavLink activeLink={active === 'matrix'} to="/landing/biometricMatrix">Mátriz biométrica</NavLink></ListItem>
                            <ListItem><NavLink activeLink={active === 'practices'} to="/landing/practices">Prácticas</NavLink></ListItem>
                            <ListItem><NavLink activeLink={active === 'studio'} to="/landing/studio">Estudio</NavLink></ListItem>
                            <ListItem><NavLink activeLink={active === 'plans'} to="/landing/plans">Precios</NavLink></ListItem>
                            <ListItem><NavLink activeLink={active === 'news'} as="a" href="https://blog.yogabot.app" target="_blank" rel="noopener noreferrer">Novedades</NavLink></ListItem>
                        </List>
                    </Nav>
                </Content>
            </Header>
        </>

    )

}
