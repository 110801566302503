import { Link } from 'react-router-dom';
import styled, { css, keyframes  } from 'styled-components/macro';
import OButton from '../../../../../styled/components/button';
import { sectionPadding } from './helpers';

import YogaBotIcon from '../../../../../assets/img/yogabot_icon.svg';
import yLogoLarge from '../../../../../assets/img/logo_large.svg';
import subfooterBh from '../../../../../assets/img/nexo_stuff.jpg';

const breatheAnimation = keyframes`
 0% { top:0; opacity:0 }
 100% { top:33rem; opacity:0.1 }
`;

const OverflowEd = styled.div`
    overflow:hidden;
    position: relative;
    width: 100%;
`;

const LandingWrap = styled.div`
    min-height:100%;
`;

const Section = styled.section`
    overflow:${({visible}) => visible ? 'visible' : 'hidden'};
    ${({noPadding}) => noPadding ? css`padding:0;` : `${sectionPadding}`};
`;

const InnerSection = styled.div`
    max-width:93rem;
    width:100%;
    margin:0 auto;

    ${({sectionSubHeader}) => sectionSubHeader && css`

        display:flex;
        align-items:center;
        
    `}

    ${({sectionQueryFlow}) => sectionQueryFlow && css`

        display:flex;
        flex-direction:column;
        @media (min-width: 800px) {
            flex-direction:row;
        }

    `}    

    ${({sectionHero}) => sectionHero && css`

        display:flex;
        @media (min-width: 800px) {
            
            align-items:center;
        }

        @media (min-width: 800px) {

            &:before{
                
                content: '';
                position: absolute;
                z-index: -2;
                top: 33rem;
                left: 49vw;
                width: 200rem;
                height: 28rem;
                background-color: var(--brand-secondary);
                border-radius: 100rem;
                opacity: 0.1;
                transform: rotate(-45deg);
                transform-origin: 0 0;
                animation-name: ${breatheAnimation};
                animation-duration: 5s;
                animation-fill-mode: forwards;
                
            }

        }
        
    
    `}


    ${({sectionMarket}) => sectionMarket && css`

        p {
            
            @media (min-width: 800px) {
                margin: 0 auto 2.5rem auto;
            }
        }

        .title {
            text-align:left;
            @media (min-width: 800px) {
                text-align:center;
                max-width: 81rem;
            }
            
        }

        .description {
            text-align:center;
            
        }
    
    `}

    ${({sectionFeatures}) => sectionFeatures && css`
        max-width:72rem;
        .title {
            text-align:left;
            @media (min-width: 800px) {
                text-align:center;
                margin-bottom:5rem;
            }
            margin-bottom:2rem;
            
        }
        .description{
            text-align:left;
            max-width:35rem;
            
            padding-bottom: 2rem;
            @media (min-width: 800px) {
                padding-bottom:5rem;
                margin:0 auto;
            }

        }

        position:relative;
        z-index:2;
        @media (min-width: 800px) {
        
            &:before{
                content: '';
                position: absolute;
                z-index: -2;
                top: 83%;
                left: -2vw;
                width: 200rem;
                height: 7rem;
                background-color: var(--brand-secondary);
                border-radius: 100rem;
                opacity: 0.15;
                -webkit-transform-origin: 0 0;
                -ms-transform-origin: 0 0;
                transform-origin: 0 0;
    
            }
         
        }
    `}

    ${({sectionGrid}) => sectionGrid && css`
        .title {
            text-align:left;
            max-width:46rem;
            @media (min-width: 800px) {
                text-align:center;
                margin:0 auto 4rem auto;
            }
            margin:0 auto 2rem auto;
            
        }
    `}

    ${({sectionWhatSay}) => sectionWhatSay && css`
    
        .title {
            text-align:left;
            @media (min-width: 800px) {
                text-align:center;
                margin-bottom:4rem;
            }
            margin-bottom:2rem;
        }
    `}

    ${({sectionLegacy}) => sectionLegacy && css`

        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        position:relative;
        @media (min-width: 800px) {
            flex-direction:row;
            align-items:center;

            ${({withLine}) => withLine && css`
                &:before{
                    content: '';
                    position: absolute;
                    z-index: -2;
                    top: 77%;
                    right: 7vw;
                    width: 200rem;
                    height: 7rem;
                    background-color: var(--brand-secondary);
                    border-radius: 100rem;
                    opacity: 0.15;
                    -webkit-transform-origin: 0 0;
                    -ms-transform-origin: 0 0;
                    transform-origin: 0 0;
        
                }
            
            
            `};
            
         
        }
        
    `}
    

    ${({sectionChallenge}) => sectionChallenge && css`
        .title {
            text-align:left;
            @media (min-width: 800px) {
                text-align:center;
                margin-bottom:3rem;
            }
            margin-bottom:2rem;
            
        }

        .subtitle{
            text-align:left;
            margin-top:2rem;
            @media (min-width: 800px) {
                text-align:center;
                margin-top:3rem;
            }
            margin-bottom:2rem;
        }

        .description {
            text-align:left;
            max-width:50rem;
            
            @media (min-width: 800px) {
                text-align:center;
                margin:0 auto 3rem auto;
            }
            margin-bottom:2rem;
        }
        position:relative;
        @media (min-width: 800px) {
        
            &:before{
                
                content: '';
                position: absolute;
                border-radius: 54% 62% 87% 46% / 74% 57% 77% 26%;
                height: 28rem;
                width: 38%;
                background: var(--brand-secondary);
                z-index: -1;
                opacity: .2;
                top: 10rem;
                left: -10rem;
            }

            &:after{
                
                content: '';
                position: absolute;
                border-radius: 54% 62% 87% 25% / 74% 57% 77% 71%;
                height: 28rem;
                width: 37%;
                background: var(--brand-secondary);
                z-index: -1;
                opacity: .2;
                bottom: 12rem;
                right: -10rem;
            }

         
        }

    `};
    
    ${({sectionTestimonials}) => sectionTestimonials && css`
        .title {
            text-align:left;
            @media (min-width: 800px) {
                text-align:center;
                margin-bottom:5rem;
            }
            margin-bottom:2rem;
            
        }
    
    `};

    ${({sectionSubFooter}) => sectionSubFooter && css`
            display:flex;
            flex-direction:column;
            text-align:center;
            justify-content: space-around;
            min-height:25rem;
            position: relative;
            z-index: 3;
    `};
    
    
    ${({sectionQuery}) => sectionQuery && css`
        @media (min-width: 800px) {
            
            ${SectionGridItemImage} {

                position:relative;
                &:before{
                
                    content: '';
                    position: absolute;
                    border-radius: 49% 51% 76% 24% / 45% 54% 46% 55%;
                    height: 100%;
                    width: 40%;
                    background: var(--brand-secondary);
                    z-index: -1;
                    opacity: .2;
                    top: -4rem;
                    left: 3rem;
                }
            }
        }
        
    `};
    ${({sectionPrices}) => sectionPrices && css`
        .title {
            text-align:left;
            @media (min-width: 800px) {
                text-align:center;
                margin-bottom:5rem;
            }
            margin-bottom:2rem;
            
        }
        position:relative;
        z-index:2;
        @media (min-width: 800px) {
        
            &:before{
                
                content: '';
                position: absolute;
                border-radius:44% 56% 64% 36% / 74% 23% 77% 26%;
                height: 42rem;
                width:100%;
                background: var(--brand-secondary);
                z-index: -1;
                opacity:.2;
                top:10rem;
            }

            &:after{
                
                content: '';
                position: absolute;
                border-radius:30% 70% 20% 80% / 69% 20% 80% 31%;
                height: 35rem;
                width:50%;
                right:0;
                background: var(--brand-secondary);
                z-index: -1;
                opacity:.2;
                bottom:0;
            }
         
        }
    `}

    ${({sectionPricesAlternative}) => sectionPricesAlternative && css`
        .title {
            text-align:left;
            @media (min-width: 800px) {
                text-align:center;
                margin-bottom:3rem;
            }
            margin-bottom:2rem;
            
        }

        .subtitle {
            text-align:left;
            @media (min-width: 800px) {
                text-align:center;
            }
            margin-top:2rem;
            
        }

    `}
    
`;

//
// SUBHEADER
//
const SectionSubHeaderLogo = styled.div`
    
    flex: 0 0 2.85rem;
    background-image:url(${YogaBotIcon});
    height:auto;
    margin-right:auto;
    height: 2.85rem;
    background-size: contain;
    background-repeat:no-repeat;
    cursor:pointer;
    @media (min-width: 760px) {
        
        flex: 0 0 20rem;
        background-image:url(${yLogoLarge});
        height: 3.85rem;
    }

`;

const SectionSubHeaderCta = styled.div``;

const CtaButtons =  styled(Link)`

    border-radius: 10px;
    text-transform:uppercase;
    padding: 0.70em 1em;
    font-size:0.85rem;
    transition: all .2s;
    display:inline-block;
    &:hover, &:focus{
        transform: scale(0.95);
    
    }

    @media (min-width: 800px) {
        font-size:1.14rem;
    }
`;

const CtaLogin = styled(CtaButtons)`

    color:#fff;
    background-color:var(--brand-secondary);
    margin-right:5px;
    &:hover, &:focus{
        color:#fff;
        background-color:var(--brand-secondary-dark);
    }
    
    
`;

const CtaRegister = styled(CtaButtons)`
    color:var(--brand-secondary);
    background-color:#fff;
    position:relative;
    &:after{
        content:"";
        border:2px solid var(--brand-secondary);
        position:absolute;
        pointer-events: none;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: inherit;
    }


    &:hover, &:focus{
        color:var(--brand-secondary-dark);
        background-color:#fff;
        &:after{
            border:2px solid var(--brand-secondary-dark);
        }
    }

`;

//
//SECTION HERO
//
const SectionHeroText = styled.div`

    color: var(--brand-primary);
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;
    flex:1 0 100%;
    align-self:center;
    && {

        p {
            max-width:40rem;
        }

    }

    @media (min-width: 800px) {
        flex:1 0 35rem;
        margin-bottom:0;
    }

`;

const SectionHeroImage = styled.div`
        display:flex;
        flex-direction:column;
        min-width:0;
        align-items: center;
        position:relative;
        opacity:0.3;
        img {
            width:auto;
            
        }
        @media (min-width: 720px) {
            opacity:1;
        }
        @media (min-width: 800px) {
            flex:1 1 auto;
            img {
                min-width:36rem;
                
            }
        }
`;

//
// SECTION MARKETS
//

const MarketsLogos = styled.div`
    display:flex;
    justify-content:center;
    a {
        flex: 0 1 50%;
        min-width:0;
        height: auto;
        max-width:16rem;
        &:first-child{
            margin-right:0.5rem;
        }

        img {
            max-width:100%;
        }
    }

`;

//
// SECTION LEGACY
//

const SectionLegacyImage = styled.div`
    flex: 1;
    img {
        max-width: 100%;
        object-fit: cover;
        width: auto;
        height: auto;

    }

    @media (min-width: 800px) {
        flex: 0 0 51%;
        
       
    }
`;
const SectionLegacyContent = styled.div`
    padding:1.5rem 0 ;
    text-align:start;
    flex: 1;
    background-color:#fff;
    @media (min-width: 800px) {
        flex: 0 1 50%;
        padding:3rem;
        
    }
`;

const LegacyPoints = styled.div`
    
    padding-bottom:2.5rem;
    padding-top:1.5rem;
`;

const LegacyPoint = styled.div`
    display:flex;
    align-items:center;
    padding-bottom:1.3rem;
    
`;

const LegacyPointIcon = styled.div`

    color:#fff;
    background-color:var(--brand-primary);
    margin-right:1rem;
    border-radius:10px;
    padding: 0.3rem 1.2rem;
    display:flex;
    align-items:center;
    justify-content:center;
    i {
        height:auto;
        margin:0;
    }


`;

//
// SECTION FEATURES
//
const FeaturesGrid = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin-left:-0.5rem;
    margin-right: -0.5rem;
    justify-content: center;
    padding-bottom:1.8rem;
`;

const FeaturesGridItem = styled.div`
    flex: 1 1 140px;
    max-width: 17.5rem;
    display:flex;
    border-radius:23px;
    flex-direction:column;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    overflow:hidden;
    background-color:#fff;
    padding:1.6rem 1.2rem 2rem 1.2rem;
    margin:0.5rem;
    text-align:left;
    transition: all .3s;

    img {
        min-width:0;
        width:70px;
        height:70px;
        margin-bottom:1.5rem;
    }
    @media (min-width: 800px) {
        text-align:center;
        align-items:center;
    }

    &:hover{
        transform:scale(1.03);
        box-shadow: 20px 30px 40px -20px rgb(0 0 0 / 5%);
        cursor:pointer;
    }
`;


//
// SECTION MATRIX
//

const SectionGrid = styled.div`
    
    display:flex;
    align-items:flex-start;
    flex-direction:${({reverse}) => reverse ? 'column-reverse' : 'column'};
    @media (min-width: 800px) {
        flex-direction:row;
        align-items:center;

    }
    
`;

const SectionGridItemText = styled.div`

    @media (min-width: 800px) {
        flex-grow:0;
        flex-shrink:1;
        flex-basis:${({basis}) => basis ? `${basis}rem` : '35rem'};
        ${({reverse}) => reverse ? css`
            margin-right:2rem;
        ` : css`
            margin-left:2rem;
            text-align: right;
        `};
        
    }
`;

const SectionGridItemImage = styled.div`
    width:100%;
    @media (min-width: 800px) {
        flex:0 1 50%;
        width:auto;
        ${({reverse}) => reverse ? css`
        margin-left:auto;
        img {
            margin-left:auto;
        }
        ` : css`
            margin-right:auto;
            img {
                margin-right:auto;
            }
        `};
    }
    
    margin-bottom:2rem;
    img {
        max-width: 100%;
        height: auto;
        display:block;
    }

    ${({fullImage}) => fullImage && css`

        img {
            width:100%;
        }
    
    `};
`;

const ButtonWrapper = styled.div`
    display:flex;
    margin-top: 2.5rem;
    justify-content:${({reverse}) => reverse ? 'flex-start' : 'flex-end'};
    ${OButton} {
        flex: 0 1 22.7rem;
    }
`;

const ButtonChallengeWrapper = styled.div`
    display:flex;
    margin-top: 2rem;
    padding-bottom:2.5rem;
    justify-content:center;
    ${OButton} {
        flex: 0 1 22.7rem;
    }
    ${({toY}) => toY && css `transform:translateY(calc(2.95rem + 2.5rem));`};
    
`;

const SectionBackground = styled.div`
    ${sectionPadding};
    ${({withBg}) => withBg ? 
    css`
        background-image:url(${subfooterBh});
        background-repeat:no-repeat;
        background-size:cover;
        background-position:center center;
        position:relative;
        z-index:2;
        &:before {
            content:"";
            z-index:1;
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background-color:rgba(255,255,255,0.5);
        }
    ` : 
    css`
        background-color:${({light}) => light ? 'var(--light-grey-3' : 'var(--brand-primary'} );
    `};
`;

const AuxSeparator = styled.div`
    padding-top:2rem;

    @media (min-width: 800px) {
        padding-top:3rem;

    }
`;

export {
    AuxSeparator,
    InnerSection,
    Section,
    SectionSubHeaderLogo,
    SectionSubHeaderCta,
    CtaLogin,
    CtaRegister,
    SectionHeroText,
    SectionHeroImage,
    MarketsLogos,
    FeaturesGrid,
    FeaturesGridItem,
    SectionLegacyContent,
    SectionLegacyImage,
    SectionGrid,
    SectionGridItemText,
    SectionGridItemImage,
    ButtonWrapper,
    LegacyPoints,
    LegacyPoint,
    LegacyPointIcon,
    LandingWrap,
    SectionBackground,
    OverflowEd,
    ButtonChallengeWrapper
}