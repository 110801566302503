// Third party libraries
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import moment from 'moment';

// Utils
import { hasOneYearPaymentPromotion } from '../../../utils/checkPromotions';

// History
import history from '../../../history';

// Locales
import I18n from '../../../i18n';

// Styles
import './fixedBanner.scss';

class FixedBanner extends PureComponent {

    onAction = () => hasOneYearPaymentPromotion() ? history.push('/profile/promotion') : history.push('/profile/plans');

    handleBlock = () => {

        const { auth, role } = this.props;
        const roles = _.get(role || {}, 'role', []);

        if (!role.hasActiveSubscription
            && (!roles.includes('premium')
                || (role.premiumExpirationDate !== 'NOT_EXPIRE'
                    && moment(role.premiumExpirationDate).startOf('day').diff(moment().startOf('day'), 'days') < 30))) {

            const isTrialExpired = moment(auth.userSession.createdAt).startOf('days') <= moment().add(-15, 'days').startOf('days');

            if (isTrialExpired) {

                return (
                    <div className="fixed-banner">
                        <span>{I18n.t('banner.fixed.trialExpired.title')}</span>
                        <span className="fixed-banner__button" onClick={()=> this.onAction()}>{I18n.t('banner.fixed.trialExpired.action')}</span>
                    </div>
                );

            } else {

                const remainingDays = 15 - moment().diff(moment(auth.userSession.createdAt), 'days');

                return (
                    <div className="fixed-banner">
                        {Math.floor(remainingDays) === 1 ? <span>{I18n.t('banner.fixed.notTrialExpired.title', {remainingDays})}</span> : <span>{I18n.t('banner.fixed.notTrialExpired.title_plural', {remainingDays})}</span>}
                        <span className="fixed-banner__button" onClick={()=> this.onAction()}>{I18n.t('banner.fixed.notTrialExpired.action')}</span>
                    </div>
                );

            }

        }

        return '';

    }

    render() {

        return this.handleBlock();

    }

}

const mapStateToProps = state => {

    return {
        auth: state.auth,
        role: state.role
    };

};

export default connect(mapStateToProps, {})(FixedBanner)
