import styled, { css } from 'styled-components/macro';


const Wrapper = styled.div`
    overflow-y: auto;
    position: relative;
    height: calc(100% - var(--nav-height));
`;

const InnerWrapper = styled.div`
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        padding:0 32px;
        @media (max-width: 420px) {
            padding: 0 20px;
        }
        &&& {
            ${({nopadding}) => nopadding &&
                css`
                    padding: 0;
                
            `};
        }
`;


const NoResultsBox = styled.div`
    padding: 20px;
    background-color: var(--brand-primary-extra-light-secondary);
`;

const Separator = styled.div`
    padding-bottom: ${ p => (p.xL ? 'var(--padding-large-y)' : 'var(--padding-regular-y)') }
`;


export {
    Wrapper,
    InnerWrapper,
    NoResultsBox,
    Separator
};