// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import ChallengeHeader from '../components/header';
import StepsNav from '../components/stepsNav';
import ProgressIndicator from '../../../components/progressIndicator';
import GenericCard from '../../../components/genericCard';

// Redux
import { getCustomSessionStats } from '../../../../redux-store/customSessionStats';

// Utils
import { toHHMMSS } from '../../../../utils/formatTime';

// Config
import Config from '../../../../config';

// Locales
import I18n from '../../../../i18n';

// Styles
import { Frame, FrameGrid, FooterResume, FooterResumeInner } from './styles';
import { Container, InnerContainerFluid } from '../styles';

// Assets
import seqShortImage from '../../../assets/img/secuencia_corta.jpg';
import seqMediumImage from '../../../assets/img/secuencia_media.jpg';
import seqLargeImage from '../../../assets/img/secuencia_larga.jpg';

class Step3 extends Component {

    constructor(props){
        super(props);
        this.state = {
            customPracticeList : [

                {
                    practiceCover: seqShortImage,
                    numOfAsanas: '13',
                    sequenceDuration: I18n.t('practice.sequenceShort'),
                    sequenceValue: "-1",
                    id: 1,
                    time: I18n.t('practice.seqShort')
                },
                {
                    practiceCover: seqMediumImage,
                    numOfAsanas: '15',
                    sequenceDuration: I18n.t('practice.sequenceMedium'),
                    sequenceValue: "0",
                    id: 2,
                    time: I18n.t('practice.seqMedium')
                },
                {
                    practiceCover: seqLargeImage,
                    numOfAsanas: '25',
                    sequenceDuration: I18n.t('practice.sequenceLong'),
                    sequenceValue: "1",
                    id: 3,
                    time: I18n.t('practice.seqLong')
                }

            ]
        }
    }

    async componentDidMount() {

        try {

            await this.props.getCustomSessionStats();

        } catch (error) {


        }

    }

    selectPractice = duration => {

        const { userConfig, bioMetricMatrix, history } = this.props;
        let sequence = window.btoa(duration + bioMetricMatrix.sequence + '_' + userConfig.practiceLevel + '/' + userConfig.practiceAudioLanguage + '/' + userConfig.practicePresentationType + '/' + false);
        history.push(`/mode/grow/practice/${sequence}`);

    }

    render() {

        const { customPracticeList } = this.state;
        const { customSessionStats } = this.props;

        const configCard = {
            type:'colored',
            mode: 'slider',
            list: customPracticeList,
            mainAction: item => { this.selectPractice(item.sequenceValue) },
            isAllowed: item => true,
            renderTitle: item => I18n.t('steps.step3.cardTitle'),
            renderDescription: item => item.sequenceDuration,
            renderIndicator: item => item.time,
            thumbnail: { attribute : 'practiceCover', ratio: '116%' }

        }

        const progress = Math.min(Math.round(customSessionStats.userCustomSessionsCount / Config.growChallenge.customPractices * 100), 100);
        const accumulatedHoursOfPractice = toHHMMSS(customSessionStats.timeElapsedInSeconds || 0);

        return (
            <Container>
                <InnerContainerFluid>
                    <ChallengeHeader {...this.props} />
                    <StepsNav activedStep={3} {...this.props} />
                    <Frame>
                        <ProgressIndicator progress={ progress } title={ I18n.t('steps.step3.title') } progressText={ I18n.t('steps.step3.progress.practice', { progress }) } />
                    </Frame>
                    <FrameGrid>
                        <Frame>
                            <p className="heading-3">{I18n.t('steps.step3.text')}</p>
                            <GenericCard {...configCard} />
                        </Frame>
                    </FrameGrid>
                </InnerContainerFluid>
                <FooterResume>
                    <FooterResumeInner>
                        <p className="caption white">{I18n.t('steps.step3.footerText1', { practices: customSessionStats.userCustomSessionsCount })}</p>
                        <p className="caption white">{I18n.t('steps.step3.footerText2', { time: accumulatedHoursOfPractice })}</p>
                    </FooterResumeInner>
                </FooterResume>
            </Container>
        );
    }
}

const mapStateToProps = state => {

    return {
        customSessionStats: _.get(state, 'customSessionStats', {}),
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        role: state.role,
        userConfig: _.get(state,'auth.userSession.config')
    };
};

export default connect(mapStateToProps, { getCustomSessionStats })(Step3);
