import styled from 'styled-components/macro';

const FrameBlock = styled.div`
    padding: 20px 32px;
    @media (max-width:420px) {
        padding: 20px;
    }
`;

const FrameBackGround = styled.div`
    padding: 0 32px 20px 32px;
    @media (max-width: 1472px) {
        padding:0 0 20px 0;
    }
    
`;

const Container = styled.div`
    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;
`;

const InnerContainer = styled.div`
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
`;

export {
    FrameBlock,
    FrameBackGround,
    Container,
    InnerContainer
}