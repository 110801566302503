import styled, { css } from 'styled-components/macro';

const FrameNav = styled.div`
    padding: 0 32px;
    flex:none;
    width:100%;
    max-width:1440px;
    margin: 0 auto;
    @media only screen and (max-width:420px) {
        padding:0 20px; 
    }
`;

const NavItems = styled.div`
    display:flex;
    justify-content:space-between;
    max-width:280px;
    align-items:center;
`;

const Item = styled.div`
    position:relative;
    padding-bottom:5px;
    &:after{
        content:"";
        position:relative;
        display:block;
        height:7px;
    }

    ${({active}) => active &&
        css`
            &:after{
                background-color:var(--brand-secondary)
            }
            
    `};

`;

export {
    FrameNav,
    NavItems,
    Item
}