
import styled, { css } from 'styled-components/macro';

const FrameCircle = styled.div``;

const FrameCirclePercent = styled.div`
    text-align: center;
    position: relative;
    ${ p => p.large ? largeSize : defaultSize };
`;

const defaultSize = css`
    height: 97px;
    width: 97px;

    @media only screen and (max-width: 420px) {
        height: 60px;
        width: 60px;
    }
`;

const largeSize = css`
    height:138px;
    width:138px;
`;

const defaultRadius = css`
    stroke-width: 10;
    transform: translate(5px, 5px);
    @media only screen and (max-width: 420px) {
        stroke-width: 5;
    }
`;

const largeRadius = css`
    stroke-width: 15;
    transform: translate(9px, 9px);
`;

const CircleSvg = styled.svg`
    position: relative;
    z-index: 50;
    width: 100%;
    height: 100%;
`;

const CircleShape = styled.circle`
    width: 100%;
    height: 100%;
    fill: none;
    stroke: var(--y-grey14);
    stroke-linecap: round;
    &:nth-child(2) {
        stroke-dasharray: ${p=> p.dashoffset && p.dashoffset };
        stroke-dashoffset: ${p=> p.dashoffset && p.dashoffset };
    }

    ${p => p.large ? largeRadius : defaultRadius};
`;

const CircleNumber = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 420px) {
        span {
            font-size: 14px;
        }
    }
`;

export {
    FrameCircle,
    FrameCirclePercent,
    CircleSvg,
    CircleShape,
    CircleNumber
}