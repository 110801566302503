import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import I18n from '../../../../../i18n';
import Abduction_1 from '../../../../assets/img/abduction_1.png';//-1
import Abduction_2 from '../../../../assets/img/abduction_2.png';//1

import Extension_1 from '../../../../assets/img/extension_1.png';//-1
import Extension_2 from '../../../../assets/img/extension_2.png';//1

import Lateral_1 from '../../../../assets/img/lateral_1.png';//-1
import Lateral_2 from '../../../../assets/img/lateral_2.png';//1

import Muscular_1 from '../../../../assets/img/muscular_1.png';//0
import Muscular_2 from '../../../../assets/img/muscular_3.png';//-1
import Muscular_3 from '../../../../assets/img/muscular_2.png';//1

import TopBar from '../../../../components/navbar/topbar';
import Footer from '../../../../components/footer';

import MenuPractice from '../../../../components/menu/practice';

import './practiceTargets.scss';
import OButton from '../../../../styled/components/button';
import { Icon } from 'semantic-ui-react';

class PracticeTargets extends React.Component {

    setTextIndicationsByType = type => I18n.t(`practice.modal.${type + `#` + this.props.bioMetricMatrix[type]}`);

    render() {

        return (
            <React.Fragment>
                <div className="practice-target-wrapper">
                    <TopBar {...this.props} callback={()=>this.props.history.push(`/practice/${this.props.match.params.sequence}`)} text={""} invert={false} />
                    <div className="inner">
                        <div className="pr-nav">
                            <div className="pr-item active" onClick={() =>{ this.props.history.push(`/practiceDiscover/targets/${this.props.match.params.sequence}`)} }>Objetivos</div>
                            <div className="pr-item" onClick={() =>{ this.props.history.push(`/practiceDiscover/preview/${this.props.match.params.sequence}`)} }>Tu secuencia</div>
                        </div>
                        <div className="pr-flex">
                            <div className="pr-aside">
                                <MenuPractice active="targets" {...this.props} />
                            </div>
                            <div className="pr-content">
                                <div className="i-back" onClick={()=>this.props.history.push(`/practice/${this.props.match.params.sequence}`)}><Icon name="angle left" /><span>{I18n.t('actions.back')}</span></div>
                                <div className="practice-target-header">
                                    <p className="practice-target-header-title">{I18n.t('practice.modal.indicator')}</p>
                                    <p className="practice-target-header-subtitle" dangerouslySetInnerHTML={{__html: I18n.t('practice.modal.title')}}></p>
                                </div>
                                <div className="practice-target-grid">
                                    <div className="item left-i grey-b">
                                        <div className="item-content">
                                            <p className="text">{I18n.t('practice.modal.firstPreambule')} </p>
                                        </div>
                                    </div>
                                    <div className="item right-i blue-b">
                                        <div className="item-content">
                                            <p className="secondary-text">{this.setTextIndicationsByType('flexoextensionIndicator')}</p>
                                            {(this.props.bioMetricMatrix['flexoextensionIndicator'] === -1 ||  this.props.bioMetricMatrix['flexoextensionIndicator'] === 0) && <div className="w-image">
                                                <img src={Extension_1} alt="" />
                                            </div>}
                                            {(this.props.bioMetricMatrix['flexoextensionIndicator'] === 1 ||  this.props.bioMetricMatrix['flexoextensionIndicator'] === 0) && <div className="w-image">
                                                <img src={Extension_2} alt="" />
                                            </div>} 
                                        </div>
                                    </div>
                                    <div className="item left-i grey-b">
                                        <div className="item-content">
                                            <p className="text">{I18n.t('practice.modal.secondPreambule')} </p>
                                        </div>
                                    </div>
                                    <div className="item right-i grey-2-b">
                                        <div className="item-content">
                                            <p className="secondary-text">{this.setTextIndicationsByType('lateralizationIndicator')}</p>
                                            {(this.props.bioMetricMatrix['lateralizationIndicator'] === -1 ||  this.props.bioMetricMatrix['lateralizationIndicator'] === 0) && <div className="w-image">
                                                <img src={Lateral_1} alt="" />
                                            </div>}
                                            {(this.props.bioMetricMatrix['lateralizationIndicator'] === 1 ||  this.props.bioMetricMatrix['lateralizationIndicator'] === 0) && <div className="w-image">
                                                <img src={Lateral_2} alt="" />
                                            </div>} 
                                        </div>
                                    </div>
                                    <div className="item left-i grey-b">
                                        <div className="item-content">
                                            <p className="text">{I18n.t('practice.modal.thirdPreambule')} </p>
                                        </div>
                                    </div>
                                    <div className="item right-i blue-b">
                                        <div className="item-content">
                                            <p className="secondary-text">{this.setTextIndicationsByType('abductionAductionIndicator')}</p>
                                            {(this.props.bioMetricMatrix['abductionAductionIndicator'] === -1 ||  this.props.bioMetricMatrix['abductionAductionIndicator'] === 0) && <div className="w-image">
                                                <img src={Abduction_1} alt="" />
                                            </div>}
                                            {(this.props.bioMetricMatrix['abductionAductionIndicator'] === 1 ||  this.props.bioMetricMatrix['abductionAductionIndicator'] === 0) && <div className="w-image">
                                                <img src={Abduction_2} alt="" />
                                            </div>} 
                                        </div>
                                    </div>
                                    <div className="item left-i grey-b">
                                        <div className="item-content">
                                            <p className="text">{I18n.t('practice.modal.fourthPreambule')} </p>
                                        </div>
                                    </div>
                                    <div className="item right-i grey-2-b">
                                        <div className="item-content">
                                            <p className="secondary-text">{this.setTextIndicationsByType('muscularTrainIndicator')}</p>
                                            {(this.props.bioMetricMatrix['muscularTrainIndicator'] === 0) && <div className="w-image">
                                                <img src={Muscular_1} alt="" />
                                            </div>}
                                            {(this.props.bioMetricMatrix['muscularTrainIndicator'] === -1) && <div className="w-image">
                                                <img src={Muscular_2} alt="" />
                                            </div>}
                                            {(this.props.bioMetricMatrix['muscularTrainIndicator'] === 1 ) && <div className="w-image">
                                                <img src={Muscular_3} alt="" />
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-practice-wrapper-a">
                                    <OButton primary type="button" color="#FFF" upper onClick={()=> this.props.history.push(`/practice/${this.props.match.params.sequence}`)}>
                                        <span>{I18n.t('auth.practiceNow')}</span>
                                    </OButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer { ...this.props } type="branded"/>
                </div>
            </React.Fragment>
        );

    }
}

const mapStateToProps = state => {

    return {
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {})
    };
};

export default connect(mapStateToProps, {})(PracticeTargets);
