import React from 'react';
import I18n from '../../../../i18n';
import './extraTopBar.scss';
import OButton from '../../../styled/components/button';

class ExtraTopBar extends React.Component {

    render() {

        return (
            
            <div className={"extra-nav " + (this.props.className || '') }>
                <div className="icon"></div>
                {this.props.hasButton && <div>
                    <OButton type="button" onClick={ this.props.onClick } primary upper ><span>{I18n.t('auth.login')}</span></OButton>
                </div>}
            </div>

        );

    }

}

export default ExtraTopBar;