import styled from 'styled-components/macro';

const Container = styled.div`
    min-height:100%;
    display:flex;
    flex-direction:column;
`;

const InnerContainer = styled.div`
    flex-grow:1;
    flex-shrink:0;
    flex-basis:auto;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display:flex;
    flex-direction:column;
    align-items: stretch;
`;

const InnerContainerFluid = styled.div`
    flex-grow:1;
    flex-shrink:0;
    flex-basis:auto;
    width: 100%;
    margin: 0 auto;
    display:flex;
    flex-direction:column;
    align-items: stretch;
`;

const Frame = styled.div`
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 32px;
    display:flex;
    justify-content:center;
    flex-direction:column;
    @media (max-width: 420px) {
        padding: 20px;
    }
`;

const FrameCard = styled.div`
    display:flex;
    flex-wrap:wrap;

`;
const FrameText = styled.div`
    padding-right:5px;
    margin-bottom:20px;

`;
const Card = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis:auto;
    padding:15px;
    overflow:hidden;
    max-width:350px;
    display:flex;
    flex-direction:column;
    margin:10px 10px 10px 0;
    position:relative;
    border-radius:5px;
    @media (max-width: 625px) {
        max-width:100%;
        flex-grow:1;
    }
    &:after {
        content:"";
        width:100%;
        height:100%;
        position:absolute;
        border:solid 1px #c1c1c1;
        top:0;
        left:0;
        border-radius:5px;
        pointer-events:none;
    } 
`;

const CornerLabel = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-color: transparent var(--brand-error) transparent transparent;
    border-color: rgba(255, 255, 255, 0) var(--brand-error) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
    height: 0;
    width: 0;
    border-width: 0 75px 75px 0;
`;

const CornerLabelText = styled.div`
    color: white;
    position: relative;
    left: 3.1px;
    top: 19.6px;
    white-space: nowrap;
    transform: rotate(45deg);
    width: 84.8528137424px;
    text-align: center;
`;

const FrameOptions = styled.div`
    display:flex;
    justify-content: flex-start;
    margin-bottom:10px;
    align-items: center;
`;
const OptionText = styled.div`
    margin-left:10px;
   
`;
const OptionIcon = styled.div`
    width:25px;
    height:25px;
    border-radius:25px;
    background-color: var(--brand-secondary);
    overflow:hidden;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-shrink:0;
    i {
        margin:0;
        height:auto;
    }
`;

export {
    
    Container,
    Frame,
    FrameCard,
    Card,
    CornerLabel,
    CornerLabelText,
    FrameText,
    FrameOptions,
    OptionIcon,
    OptionText,
    InnerContainerFluid,
    InnerContainer

};