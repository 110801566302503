import React from 'react'
import { ChallengeGrid, ChallengeGridItem, Steps } from './styled'

export function SectionChallenge(props) {
    
    return (
        <ChallengeGrid>
            <ChallengeGridItem>
                <Steps>01</Steps>
                <p className="paragraph regular">Descarga Yogabot App, crea tu cuenta y tendrás 15 días de Yogabot premium <strong>GRATIS</strong></p>
            </ChallengeGridItem>
            <ChallengeGridItem>
                <Steps>02</Steps>
                <p className="paragraph regular">Selecciona el “Modo crecimiento” y completa <strong>el reto de tres pasos</strong></p>
            </ChallengeGridItem>
            <ChallengeGridItem>
                <Steps>03</Steps>
                <p className="paragraph regular">Al completar el “Modo de crecimiento” ganas <strong>50 YGB Token</strong> y una consulta al equipo de Advisor. GRATIS.</p>
            </ChallengeGridItem>
            <ChallengeGridItem>
                <Steps>04</Steps>
                <p className="paragraph regular">Al <strong>completar</strong> tu primera consulta, <strong>agregaremos 15 días</strong> de experiencia Premium GRATIS. Así podrás practicar tu secuencia personalizada <strong>ajustada por un profestor advisor.</strong> </p>
            </ChallengeGridItem>
        </ChallengeGrid>
    )
}