//libraries & components libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Responsive } from 'semantic-ui-react';
import I18n from '../../../../../i18n';

//components
import ErrorMessage from '../../../../components/errorMessage';
import CustomInput from '../../../../components/form/input';
import CorrectMessage from '../../../../components/correctMessage';

//styled components
import OButton from '../../../../styled/components/button';
import { RecoveryBox, RecoveryCol, RecoveryRow } from '../styled';
import { AuthCTALogin, AuthHeader, AuthIcon, AuthSeparator, AuthTitle, YogabotIcon } from '../../styled';

//models
import SecurityModel from '../../../../../data/models/security/security';

//assets
import yLogoLarge from '../../../../assets/img/logo_large.svg';

class RecoveryStep1 extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            codeError: -1,
            hasError: false,
            correct: false
        }
    }

    onRecovery = async payload => {

        try {

            const response = await SecurityModel.recoveryPassword({email: payload.email});

            if (response.status === 200) {

                this.setState({correct: true, hasError: false});
            }

        } catch (codeError) {
            
            this.setState({hasError: true, codeError: 'error-recovery'});

        }

    };

    gotoLogin = () => this.props.history.push(`/auth/login`);

    render() {

        const { codeError, hasError, correct } = this.state;

        return (
            <RecoveryRow>
                <RecoveryCol>
                    <RecoveryBox>
                        <AuthHeader column>
                            <Responsive minWidth={560}>
                                <AuthIcon w={240} h={50}>
                                    <YogabotIcon src={yLogoLarge} />
                                </AuthIcon>
                            </Responsive>
                            <Responsive maxWidth={559}>
                                <AuthIcon>
                                    <YogabotIcon />
                                </AuthIcon>
                            </Responsive>
                            <AuthTitle><span>{I18n.t('auth.recoveryYourPassword')}</span></AuthTitle>
                        </AuthHeader>
                        <AuthSeparator />
                        <AuthSeparator />
                        <p className="paragraph regular">{ I18n.t("auth.recoveryMessage") }</p>
                        <AuthSeparator />
                        <Form name="recoveryStep1Form" onSubmit={this.props.handleSubmit(this.onRecovery)}>
                            <ErrorMessage code={codeError} active={hasError} />
                            { correct && <CorrectMessage message={I18n.t('messages.instructionSend')} /> }
                            <AuthSeparator>
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t('profile.email') }
                                    name="email"
                                    fieldClasses="y-input default small"
                                    label={ I18n.t('auth.emailShort') }
                                    restrictions={ [{ trim: true }] } />
                            </AuthSeparator>
                            <AuthSeparator />
                            <OButton upper type="submit" fluid color="#FFF" primary>
                                <span>{I18n.t('auth.recoveryPassword')}</span>
                            </OButton>
                            <AuthSeparator />
                            <AuthCTALogin style={{textAlign: 'center'}}>
                                <span className="labeling regular brand-secondary c-pointer" onClick={this.gotoLogin}>{I18n.t('actions.cancel')}</span>
                            </AuthCTALogin>
                        </Form>
                    </RecoveryBox>
                </RecoveryCol>
            </RecoveryRow>
            
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');
    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errors.email = I18n.t('validations.emailInvalid');
      }

    return errors;

};

export default reduxForm({
    form: 'recoveryStep1Form',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    enableReinitialize: true,
    initialValues: {
        email: '',
    }
})(connect(null, { })(RecoveryStep1));