import styled, {css} from 'styled-components/macro';
import { motion } from "framer-motion";

const ItemWrap = styled.div`
    height: 100%;
    display:flex;
    flex-direction:column;
    padding:0.5rem;
    cursor:pointer;
`;

const ExpandedCard = styled(motion.div)`

    position: fixed;
    display: flex;
    place-content: center;
    place-items: center;
    align-items: flex-start;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2rem;
    z-index: 11;
    overflow:auto;
    
`;

const Backdrop = styled(motion.div)`

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg,var(--brand-primary),var(--brand-primary));
    z-index:10;
`;

const Item = styled(motion.div)`
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 25%);
    overflow: hidden;
    border-radius: 0.5rem;
    margin: 1rem 0;
    position:relative;
    flex:1;
    &.expanded {
        flex:0 1 30rem;
        position:relative;
        margin: auto;
    }
    .title{
        text-align:center;
    }
    ${({advisor}) => advisor ? css`
    
        &:after{
            content: "Staff";
            position: absolute;
            font-size: 0.85rem;
            font-family: var(--font-secondary);
            background-color: var(--brand-secondary);
            color: var(--brand-primary);
            padding: 2px 8px;
            top: 0;
            left: 0;
            border-bottom-right-radius: 0.5rem;
        }

    ` : css `
    
        &:after{
            content: "Advisor";
            position: absolute;
            font-size: 0.85rem;
            font-family: var(--font-secondary);
            background-color: var(--brand-secondary);
            color: var(--brand-primary);
            padding: 2px 8px;
            top: 0;
            left: 0;
            border-bottom-right-radius: 0.5rem;
        }
    `};

    @media (max-width: 350px) {
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    }
   
`;

const CloseIcon = styled.div`

    position:absolute;
    top:0;
    right:0;
    padding:1rem;
    i {
        color:var(--brand-secondary);
    }
`;

const ItemHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content:center;
`;

const ItemAuthor = styled.div`

    flex:0 0 150px;
    height:150px;
    border-radius:50%;
    overflow:hidden;
    img {
        width:100%;
        height:100%;
        object-fit:contain;
    }
    
`;

const ItemContent = styled(motion.div)`

    .name {
        margin-bottom:0;
        margin-top:2rem;
    }
    .skill{
        color:var(--dark-grey-2);
    }

`;

const SwipperContainer = styled.div`

    width:100%;
    position:relative;
    padding-bottom: 2rem;
`;

export {
    Item,
    ItemHeader,
    ItemAuthor,
    SwipperContainer,
    ItemContent,
    Backdrop,
    ExpandedCard,
    ItemWrap,
    CloseIcon
}