import Promise from 'bluebird';

const asyncForEach = async (array, callback) => {

    for (let index = 0; index < array.length; index++) {

        await callback(array[index], index, array);

    }

};

const asyncMap = async (array, callback) => {

    var arrayOfPromises = array.map(async item => {
        return await callback(item);
    });

    return Promise.all(arrayOfPromises);

};

const getAmountLiteral = (amount, currency) => {

    switch(currency) {

        case 'EUR':
            return amount + '€';

        case 'USD':
        default:
            return '$' + amount;

    }

};

const excludeDurationAndLevelFromRoutine = routine => (routine[0] === '-' ? routine.slice(2, routine.indexOf('_')) : routine.slice(1, routine.indexOf('_')));

export default { asyncForEach, asyncMap, getAmountLiteral, excludeDurationAndLevelFromRoutine };