// Third party libraries
import _ from 'lodash';
import React from 'react';
import QueryString from 'query-string';
import Moment from 'moment';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Responsive } from 'semantic-ui-react';

// Componentes
import ErrorMessage from '../../../components/errorMessage';
import CustomInput from '../../../components/form/input';
import CustomCheckBox from '../../../components/form/checkbox';

// Styled components
import OButton from '../../../styled/components/button';
import { LoginBox, LoginCol, LoginRow, LoginRemember, ForgotPassword } from './styled';
import { AuthCTARegister, AuthHeader, AuthIcon, AuthLine, AuthRRSS, AuthRRSSIcon, AuthRRSSTitle, AuthSeparator, AuthTitle, YogabotIcon } from '../styled';

// Redux
import { getAlternativePractices } from '../../../../redux-store/alternativePractices';
import { loginUser, thirdPartyloginUser } from '../../../../redux-store/auth';
import { getBiometricMatrix } from '../../../../redux-store/bioMetricMatrix';
import { getChallenges } from '../../../../redux-store/challenges';
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { getRole } from '../../../../redux-store/role';
import { getLastWeekTrainingSeconds } from '../../../../redux-store/session';
import { openGenericModal, closeGenericModal } from '../../../../redux-store/genericModal';
import { openExpiredModal } from '../../../../redux-store/expiredModal';
import { getMarketplace } from '../../../../redux-store/cart';
import { getCustomSessionStats } from '../../../../redux-store/customSessionStats';

// Utils
import { firebaseThirdPartyLogin } from '../../../../utils/firebase';
import { TrackingService } from '../../../../utils/TrackingService';

// Locales
import I18n from '../../../../i18n';

// Assets
import yLogoLarge from '../../../assets/img/logo_large.svg'
import gLogo from '../../../assets/img/g_logo.png';
import fLogo from '../../../assets/img/f_logo.png';

class Login extends React.Component {

    constructor(props) {

        super(props);

        if (window.localStorage.getItem('jwt') && Moment(JSON.parse(atob(window.localStorage.getItem('jwt').split('.')[1])).exp * 1000).diff(Moment(), 'hour')) {

            this.props.history.push('/home');

        }

        this.state = {
            hasError: false,
            codeError: -1,
            nickname: '',
            pro: ''
        };

    }

    componentDidMount() {

        this.checkExpiredSession();
        this.getData();

    }

    checkExpiredSession = () => {

        if (_.get(this.props, 'location.search', false)) {

            const UrlQueryStrings = this.props.location.search;
            const queryValues = QueryString.parse(UrlQueryStrings);

            if (queryValues.expired) {

                const { openGenericModal, closeGenericModal } = this.props;

                openGenericModal({
                    type: 'simple',
                    title:{
                        text: I18n.t('messages.alert'),
                        classes: ['heading-2']
                    },
                    description:{
                        text: I18n.t('messages.sessionExpired'),
                        classes: ['paragraph', 'regular']
                    },
                    buttons:[
                        {
                            text: I18n.t('actions.understood'),
                            callback: ()=> {

                                closeGenericModal();

                            },
                            options: {
                                primary: true,
                                color: '#fff',
                                fluid: true,
                                upper: true
                            }
                        }

                    ]
                });

            }

        }

    }

    getData = async () => {

        try {

            const { nickname, pro } = this.getQueryStringReferrer();
            this.setState({ nickname, pro });

        } catch (error) {}

    }

    getQueryStringReferrer = () =>  {

        if (localStorage.getItem('ref')) {

            return { nickname: localStorage.getItem('ref') };

        } else if (localStorage.getItem('pro')) {

            return { pro: localStorage.getItem('pro') };

        } else if (_.get(this.props, 'location.search', false)) {

            const UrlQueryStrings = this.props.location.search;
            const queryValues = QueryString.parse(UrlQueryStrings);

            if (queryValues.ref) {

                localStorage.setItem('ref', queryValues.ref);
                return { nickname: queryValues.ref };

            }

            if (queryValues.pro) {

                localStorage.setItem('pro', queryValues.pro);
                return { pro: queryValues.pro };

            }

        } else {

            return {};

        }

    }

    onThirdPartyLogin = async loginObject => {

        try {

            this.props.openLoader();
            if (window.localStorage.getItem('ref')) {

                loginObject.ref = window.localStorage.getItem('ref');

            } else if (window.localStorage.getItem('pro')) {

                loginObject.pro = window.localStorage.getItem('pro');

            } else if (window.localStorage.getItem('userIdRef')) {

                loginObject.userIdRef = window.localStorage.getItem('userIdRef');

            }

            loginObject.platform = !window.cordova ? 'Web' : window.device.platform;

            const user = await this.props.thirdPartyloginUser(loginObject);
            if (user.register) {

                TrackingService.registerEvent('Register', `sign_up_${ loginObject.provider }`);

            } else {

                TrackingService.registerEvent('Login', `login_${ loginObject.provider }`);

            }

            this.onSuccessLogin();

        } catch (codeError) {

            this.setState({ hasError: true, codeError: 'error-login' });
            this.props.closeLoader();

        }

    };

    onForgotPassword = () => this.props.history.push(`/auth/recovery/step/1`);

    onRegister = () => this.props.history.push('/auth/register');

    onNeedHelp = () => this.props.history.push('/faq');

    onLogin = async credentials => {

        const { openLoader, closeLoader, loginUser } = this.props;
        const { nickname, pro } = this.state;
        const formValues = { email: _.trim(credentials.email), password: credentials.password, remember: window.cordova ? true : credentials.remember, platform: !window.cordova ? 'Web' : window.device.platform };

        if (!_.isEmpty(nickname)) {

            formValues.ref = nickname;

        }

        if (!_.isEmpty(pro)) {

            formValues.pro = pro;

        }

        try {

            openLoader();
            await loginUser(formValues);
            TrackingService.registerEvent('Login', 'login');
            this.onSuccessLogin();

        } catch (codeError) {

            this.setState({ hasError: true, codeError: 'error-login' });
            closeLoader();

        }

    };

    onSuccessLogin = async () => {

        const { openLoader, closeLoader, history, getRole, getChallenges, getBiometricMatrix, getLastWeekTrainingSeconds, getAlternativePractices, getMarketplace, getCustomSessionStats, openExpiredModal } = this.props;

        try {

            openLoader();
            getRole();
            getChallenges();
            getBiometricMatrix();
            getLastWeekTrainingSeconds();
            getAlternativePractices();
            getMarketplace();
            getCustomSessionStats();
            openExpiredModal();
            history.push('/home');

        } catch (codeError) {

            this.setState({ hasError: true, codeError: 'error-login' });

        } finally {

            closeLoader();

            if (localStorage.getItem('ref')) {

                localStorage.removeItem('ref');

            }

            if (localStorage.getItem('pro')) {

                localStorage.removeItem('pro');

            }

        }

    };

    renderSocialBlock = () => {

        return (
            <>
                <AuthRRSS google onClick={ () => firebaseThirdPartyLogin('google', this.onThirdPartyLogin) }>
                    <AuthRRSSIcon>
                        <img src={gLogo} alt='google' />
                    </AuthRRSSIcon>
                    <AuthRRSSTitle>{I18n.t('auth.loginWithGoogle')}</AuthRRSSTitle>
                </AuthRRSS>
                <AuthRRSS facebook onClick={ () => firebaseThirdPartyLogin('facebook', this.onThirdPartyLogin) }>
                    <AuthRRSSIcon>
                        <img src={fLogo} alt='facebook'/>
                    </AuthRRSSIcon>
                    <AuthRRSSTitle>{I18n.t('auth.loginWithFacebook')}</AuthRRSSTitle>
                </AuthRRSS>
            </>
        );

    }

    render() {

        const { codeError, hasError } = this.state;

        return (
            <LoginRow className='wrapper-login'>
                <LoginCol>
                    <LoginBox>
                        <AuthHeader column>
                            <Responsive minWidth={560}>
                                <AuthIcon w={240} h={50}>
                                    <YogabotIcon src={yLogoLarge} />
                                </AuthIcon>
                            </Responsive>
                            <Responsive maxWidth={559}>
                                <AuthIcon>
                                    <YogabotIcon />
                                </AuthIcon>
                            </Responsive>
                            <AuthTitle><span>{I18n.t('auth.welcomeAgain')}</span></AuthTitle>
                        </AuthHeader>
                        <AuthSeparator />
                        <AuthSeparator />
                        {(!window.cordova || window.device.platform === 'Android') && this.renderSocialBlock() }
                        {(!window.cordova || window.device.platform === 'Android') &&
                            <AuthLine>
                                <span>{ I18n.t('auth.registerOp3') }</span>
                            </AuthLine>
                        }
                        <ErrorMessage code={codeError} active={hasError} />
                        <Form name='loginForm' onSubmit={this.props.handleSubmit(this.onLogin)} noValidate>
                            <AuthSeparator />
                            <Field
                                component={ CustomInput }
                                placeholder={ I18n.t('profile.email') }
                                name='email'
                                fieldClasses='y-input default small'
                                label={ I18n.t('auth.emailShort') }
                                restrictions={ [{ trim: true }] } />
                            <Field
                                component={ CustomInput }
                                placeholder={ '' }
                                name='password'
                                type='password'
                                fieldClasses='y-input default small'
                                label={ I18n.t('auth.password') } />
                            <LoginRemember>
                                {!window.cordova &&
                                    <div>
                                        <Field
                                            component={ CustomCheckBox }
                                            name='remember'
                                            fieldClasses='y-checkbox'
                                            label={ I18n.t('auth.remember') } />
                                    </div>
                                }
                                <ForgotPassword onClick={ this.onForgotPassword }><span className='labeling regular c-pointer'>{I18n.t('actions.forgotYourPassword')}</span></ForgotPassword>
                            </LoginRemember>
                            <AuthSeparator />
                                <OButton upper type='submit' fluid color='#FFF' primary>
                                    <span>{I18n.t('auth.login')}</span>
                                </OButton>
                            <AuthSeparator />
                            <AuthCTARegister>
                                <span className='labeling regular'>{I18n.t('actions.noAccountYet')}</span>
                                <span className='labeling regular brand-secondary c-pointer' onClick={this.onRegister}>{I18n.t('actions.registerNow')}</span>
                            </AuthCTARegister>
                            <AuthSeparator />
                            <span className='labeling regular c-pointer' onClick={ this.onNeedHelp }>{I18n.t('auth.needHelp')}</span>
                        </Form>
                    </LoginBox>
                </LoginCol>
            </LoginRow>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.password)) {

        errors.password = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');

    }

    return errors;

};

export default reduxForm({
    form: 'loginForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        email: '',
        password: '',
        remember: false
    }
})(connect(null, { getCustomSessionStats, openExpiredModal, closeLoader, getAlternativePractices, getBiometricMatrix, getChallenges, getMarketplace, getLastWeekTrainingSeconds, getRole, loginUser, openLoader, openGenericModal, closeGenericModal, thirdPartyloginUser })(Login));