import React from 'react';
import ExtraTopBar from '../../../components/navbar/extraTopBar';
import '../legal.scss';
import SubMenu from '../../../components/navbar/submenu';
import TopBar from '../../../components/navbar/topbar';
import I18n from '../../../../i18n';
import Footer from '../../../components/footer';
import GradientNav from '../../../components/navbar/gradient';
class PrivacyPolicy extends React.Component {

    gotoLogin = () => {

        this.props.history.push(`/auth/login`);
        
    };

    render() {

        return (
            <React.Fragment>
                {!localStorage.getItem('jwt') && <ExtraTopBar onClick={ this.gotoLogin } className="register" hasButton={true}/>}
                {localStorage.getItem('jwt') && <GradientNav active="" {...this.props} /> }
                <div className={"main-container " + (!localStorage.getItem('jwt') ? 'hasExtraTopBar' : '')}>
                {localStorage.getItem('jwt') && <TopBar callback={() => this.props.history.goBack()} {...this.props} text={I18n.t('menu.privacy')}/>}
                    <div className="inner-container">
                        <h1>1.Información básica sobre protección de datos</h1>
                        
                        <p><b>Responsable del tratamiento</b>: NEXOYOGA, S.L. (en adelante, “Yogabot”).
                        </p>
                        <p><b>Dirección</b>: Calle Vereda del Aire, 15, CP: 38206. La Laguna. Santa cruz de Tenerife.España
                        </p>
                        <p><b>Email</b>: nexo@nexoyoga.com
                        </p>
                        <p><b>Finalidad principal del tratamiento</b>: la finalidad será gestionar su registro en la plataforma y la prestación del servicio. 
                        </p>
                        <p><b>Base legitimadora</b>: los datos se tratarán en base a la obtención del consentimiento.
                        </p>
                        <p><b>Potenciales destinatarios de los datos:</b>
                        </p>
                        <p>- Terceras partes a las que Yogabot está obligada a transmitir información, como autoridades públicas.
                        </p>
                        <p>- Empresas del Grupo de Yogabot.
                        </p>
                        <p><b>Derechos de protección de datos</b>: usted puede revocar en cualquier momento el consentimiento otorgado para las actividades de tratamiento de datos personales por parte de Yogabot, así como ejercitar, si está interesado en ello, sus derechos de acceso, rectificación, supresión, limitación u objeción al tratamiento de datos, portabilidad de datos, así como a no ser objeto de decisiones automatizadas. Puede hacerlo contactando directamente con Yogabot en la siguiente dirección: nexo@nexoyoga.com
                        </p>
                        <h1>2.Información sobre Yogabot</h1>

                        <p>El presente documento regula la política de privacidad del sitio web y la APP de Yogabot (en adelante, el <b>“Sitio Web”</b>).</p>

                        <p>Yogabot es una sociedad mercantil cuya actividad principal es la prestación de un servicio de yoga online, a través de una plataforma interactiva que evalúa el nivel de movilidad del usuario y, en base a esta información, le ofrece una recomendación personalizada en relación con la práctica de yoga. Para su información, a continuación se recogen los datos identificativos de la sociedad:
                        </p>
                        <p>Denominación social: NEXOYOGA, S.L. (en adelante, <b>“Yogabot”</b>).<br/>
                        NIF: B-76704923<br/>
                        Dirección: Calle vereda del Aire, 15. CP: 38260<br/>
                        Correo electrónico: nexo@nexoyoga.com<br/>
                        Datos de inscripción en el Registro Mercantil: 26 Mayo del 2016. Tomo: 3442 Folio: 130</p>
                        <p><b>2.1. Aplicación de esta Política de Privacidad</b></p>
                        <p>La presente política de privacidad regula el tratamiento de datos llevado a cabo por Yogabot en relación con los usuarios del Sitio Web. Lo anterior abarcaría el tratamiento de datos personales de clientes y/o potenciales clientes que accedan o transmitan información a través del Sitio Web. Por tanto, la presente política de privacidad refleja la información relativa al tratamiento con respecto a las diferentes categorías de interesados indicadas.</p>
                        <h1>3.Datos personales tratados por Yogabot, finalidades y legitimación</h1>
                        <p>Usted queda informado de que todos los datos que Yogabot le solicite o le pueda solicitar son necesarios para las finalidades descritas en la presente política de privacidad y el hecho de no facilitarlos supondría la imposibilidad de poder contactar con usted o gestionar la solicitud que realice a Yogabot. Asimismo, Yogabot se reserva la facultad de no responder o tramitar solicitudes que no incluyan los datos que le sean solicitados.
                        </p>
                        <p>Usted garantiza la veracidad de los datos de carácter personal proporcionados a Yogabot y acepta que Yogabot podrá periódicamente solicitarle la revisión y actualización de los datos personales que sobre usted conserve. 
                        </p>
                        <p>Todos los datos personales recogidos por Yogabot se conservarán durante el tiempo que dure la relación contractual entre el cliente y Yogabot. Asimismo, cuando la relación contractual finalice, Yogabot conservará aquellos datos generados o tratados en el marco de la prestación de servicios que fueran estrictamente necesarios para cumplir con obligaciones legales o contractuales.
                        </p>
                        <p>n este sentido, a continuación se recogen los datos tratados por parte de Yogabot:
                        </p>
                        <p><b>2.1.a) Datos tratados:</b></p>
                        <p>- Nombre y apellidos.<br/>
                        - Su nombre de usuario (avatar).<br/>
                        - Correo electrónico y teléfono.<br/>
                        - Ciudad y país.<br/>
                        - Año de nacimiento.<br/>
                        - Su imagen.<br/>
                        - Sexo.<br/>
                        - Datos sobre sus perfiles en redes sociales (en caso de registro a través de login social o de contacto con YOGABOT a través de dichas redes sociales).<br/>
                        - Estatura y peso.<br/>
                        - Actividad profesional.<br/>
                        - Tipo de práctica y frecuencia.<br/>
                        - Ritmo cardíaco.<br/>
                        - Geolocalización.<br/>
                        - Calidad del sueño.<br/>
                        - Datos sobre sus hábitos alimenticios y de vida.<br/>
                        - Postura corporal.</p> 
                        <p><b>2.1.b) Finalidades:</b></p>
                        <p>Entablar y mantener la relación comercial y/o contractual con usted como cliente o potencial cliente. En este sentido, Yogabot tratará los datos señalados anteriormente, incluida su imagen y postura corporal, con la finalidad de prestar correctamente el servicio y de ofrecerle recomendaciones personalizadas para la práctica del yoga. </p>
                        <p><b>2.1.c) Base legitimadora:</b></p>
                        <p>Si usted ha accedido al Sitio Web y, durante el proceso de registro o rellenando un formulario, ha marcado la casilla aceptando el tratamiento de sus datos, éstos se tratarán de conformidad con el consentimiento explícitamente otorgado por usted al darse de alta como usuario en el Sitio Web.</p>
                        <h1>4.Terceros a los que se transmiten sus datos</h1>
                        <p>Los datos personales que usted proporcione a Yogabot podrán ser comunicados a las siguientes categorías de destinatarios:</p>
                        <p>
                        - Terceras partes a las que Yogabot está obligada a transmitir información, como autoridades públicas, con la finalidad de dar cumplimiento con los requerimientos de dichas autoridades y la normativa aplicable, en su caso.
                        <br/>
                        - Otras empresas que formen parte del Grupo NEXOYOGA, con fines administrativos, las cuales se enmarcan dentro de la prestación de servicios de la sociedad de la información en relación con la práctica deportiva (como el yoga) y el bienestar.
                        </p>
                        <h1>5.Seguridad de los datos</h1>
                        <p>Yogabot cuenta con políticas apropiadas y medidas técnicas y organizativas para salvaguardar y proteger sus datos personales contra el acceso ilegal o no autorizado, pérdida o destrucción accidental, daños, uso y divulgación ilegal o no autorizada. También tomaremos todas las precauciones razonables para garantizar que nuestro personal y los empleados que tienen acceso a sus datos personales hayan recibido la capacitación adecuada.
                        </p>
                        <p>En todo caso, se informa al usuario que cualquier transmisión de datos a través de Internet no es completamente segura y, como tal, se realiza bajo su propio riesgo. Aunque haremos nuestro mejor esfuerzo para proteger sus datos personales, Yogabot no puede garantizar la seguridad de los datos personales transmitidos a través de nuestro Sitio Web.
                        </p>
                        <h1>6.Sus derechos de protección de datos</h1>
                        <p>Usted podrá ponerse en contacto cuando así lo estime con Yogabot, pudiendo ejercitar sus derechos de acceso, rectificación, supresión, limitación, oposición, así como el derecho a la portabilidad de sus datos y a no ser objeto de decisiones automatizadas, mediante solicitud dirigida a Yogabot en la siguiente dirección nexo@nexoyoga.com</p>
                        <p>Asimismo, se le informa que usted podrá revocar los consentimientos otorgados cuando así lo desee, contactando con Yogabot, a la dirección o correo electrónico indicados anteriormente.</p>
                        <h1>7.Información sobre la Agencia Española de Protección de Datos</h1>
                        <p>Por último, en el caso que usted lo entienda necesario, puede dirigirse a la Agencia Española de Protección de Datos, con domicilio en calle Jorge Juan, 6, 28001, Madrid, con el fin de salvaguardar sus derechos. </p>
                        <h1>8.Contacte con Yogabot</h1>
                        <p>Si tiene dudas o cuestiones que transmitir a Yogabot en relación con el tratamiento de sus datos personales puede dirigirse por escrito a Yogabot a la dirección Camino Vereda del aire, 15, san Cristobal de la Laguna, 38297, Tenerife, así como por correo electrónico a la dirección: nexo@nexoyoga.com</p>
                        <p>© 2020 NEXOYOGA, S.L. Todos los derechos reservados.</p>
                    </div>
                    <Footer {...this.props} type="branded"/>
                </div>
                {localStorage.getItem('jwt') && <SubMenu active="" {...this.props}  />}
            </React.Fragment>
        );

    }

}
export default PrivacyPolicy;