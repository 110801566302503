import _ from 'lodash';
import Axios from 'axios';

import history from '../history';

Axios.interceptors.response.use( response => {

    return response;

}, error => {

    const excludes = ['/login', '/register', '/verifyemail', '/removeaccount', '/recoverypassword'];

    if (_.get(error, 'response.status', 0) === 401 && !!!_.find(excludes, exclude => error.config.url.includes(exclude))) {
            
        window.localStorage.removeItem('jwt');
        history.push('/auth/login?expired=true');
        
    } else if (!error.config.url.includes('/wallet') && _.get(error, 'response.status', 0) === 402) {

        history.push('/profile/plans');

    }

    throw error;

});

const get = (apiEndpoint, requireToken) => Axios.get(apiEndpoint, getOptions(requireToken)).catch(err => { throw err.response && err.response.status });
const getBlob = (apiEndpoint, requireToken) => Axios.get(apiEndpoint, getOptions(requireToken, true)).catch(err => { throw err.response && err.response.status });
const post = (apiEndpoint, payload, requireToken) => Axios.post(apiEndpoint, payload, getOptions(requireToken)).catch(err => { throw err.response && err.response.status });
const put = (apiEndpoint, payload, requireToken) => Axios.put(apiEndpoint, payload, getOptions(requireToken)).catch(err => { throw err.response && err.response.status });
const deleting = (apiEndpoint, requireToken) => Axios.delete(apiEndpoint, getOptions(requireToken)).catch(err => { throw err.response });
const postFile = (apiEndpoint, payload, requireToken) => Axios.post(apiEndpoint, payload, getOptions(requireToken, false, true)).catch(err => { throw err.response && err.response.status });

const getOptions = (requireToken, isBlob, isFile) => {

    let options = {};

    if(requireToken && window.localStorage.getItem('jwt')) {

        options.headers = isFile ? { 'x-access-token': window.localStorage.getItem('jwt'), 'Content-Type': 'multipart/form-data' } : { 'x-access-token': window.localStorage.getItem('jwt') };

    }

    if (isBlob) {

        options.responseType = 'blob';

    }

    return options;

}

export const AxiosService = {
    get,
    getBlob,
    post,
    put,
    deleting,
    postFile
};