import React from 'react';

import Countdown from '../../../components/countdown';
import Footer from '../../../components/footer';
import PlansBlocks from '../../../components/plansBlocks';
import SideBarProFileHoc from '../../../components/sidebar/profile';
import TopBar from '../../../components/navbar/topbar';

import { getOneYearPaymentPromotionPrice, hasOneYearPaymentPromotion } from '../../../../utils/checkPromotions';

import I18n from '../../../../i18n';

import '../profile.scss';

class UserPromotion extends React.Component {

    componentDidMount() {

        if (!hasOneYearPaymentPromotion()) {

            this.props.history.push(`/404`);

        }

    }

    gotoPayment = () => {

        const price = getOneYearPaymentPromotionPrice();
        const description = 'Producto Premium';
        const periodic = false;
        const data = window.btoa(JSON.stringify(price) + '/' + description + '/annualPayment/' + periodic);
        this.props.history.push(`/payment/${data}`);

    }

    render() {

        //TODO añadir literales: Preguntar si borrar esta página porque ya no se usa

        return (
            <div className="profile-wrapper">
                <TopBar {...this.props} menuCallback={()=> this.props.onToggle()} showProfile={false} text={I18n.t('profile.promotion')} invert={true}/>
                <div className="inner">
                    <div className="promo-wrapper">
                        <div className="promo-header">
                            <p className="promo-header__title">Disfruta Yogabot PREMIUM por todo un año, <strong>sin límites.</strong></p>
                            <p className="promo-header__subtitle">Oferta única de lanzamiento - 39,99 / Año</p>
                            <p className="promo-header__subtitle">Oferta disponible hasta el 31 de julio</p>
                        </div>
                        <div className="promo-header__countdown">
                            Esta promoción termina en:
                        </div>
                        <div className="promo-counter">
                            <Countdown timeTillDate="2020-08-01T00:00:00+00:00" timeFormat="YYYY-MM-DDHH:mm:ss" classes="fitted" onEnd={()=>{}}/>
                        </div>
                        <div className="promo-body">
                            <p>Si tu primera experiencia con Yogabot te ha gustado, entonces no puedes perderte esta <strong>oferta especial de lanzamiento.</strong> </p>
                            <p>Por un pago único de 39,99 Dólares / Euros, disfrutarás de todas las ventajas y funcionalidades de Yogabot PREMIUM por todo un año (365 días) SIN CUOTAS</p>
                            <p>Yogabot PREMIUM se convertirá en el mejor aliado para <strong>tu transformación y crecimiento personal en Yoga</strong>... Si valoras descubrir y explorar nuevos retos personales, y tienes la voluntad de superarte cada día, <strong>Yogabot PREMIUM es para ti.</strong></p>
                            <p><strong>IMPORTANTE:</strong> Con esta oferta única de lanzamiento te aseguras todas las ventajas y nuevas funcionalidades que iremos añadiendo a Yogabot PREMIUM, por tan solo 3,33 al mes (Dólares / Euros)</p>
                        </div>
                        <div className="promo-btn">
                            <span onClick={this.gotoPayment }>comprar ahora <br/>Pago único $39.99</span>
                        </div>
                        <div className="plans-wrapper">
                            <PlansBlocks />
                        </div>
                        <div className="promo-btn">
                            <span  onClick={this.gotoPayment }>házte premium <br/>Pago único $39.99</span>
                        </div>
                    </div>
                </div>
                <Footer {...this.props} type="branded"/>
            </div>
        );

    }

}

const Wrapped = SideBarProFileHoc(UserPromotion, 'promotion');

export default Wrapped;