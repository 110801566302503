import React, { Component } from 'react'
import _ from 'lodash';
import I18n from '../../../../../../i18n'
import { Responsive } from 'semantic-ui-react';

import styled from 'styled-components';
import OButton from '../../../../../styled/components/button';
import { ActionBtn, ResultBox, Content} from './styles';

const OButtonStyled = styled(OButton)`
    margin-right: 10px!important;
`;

 class ResultChallenges extends Component {
    constructor(props) {
        super(props)

        this.state = {
                 
        }

    }

    setEndChallengeMessage = (challengeId, name) => {

        let messages = {
            0: {
                message : I18n.t('challenge.finalizeMessages.challenge0', {userName: name, challengeTime: '14'})
            },
            1: {
                message : I18n.t('challenge.finalizeMessages.challenge1', {userName:  name })
            },
            2: {
                message : I18n.t('challenge.finalizeMessages.challenge2', {userName:  name })
            },
            3: {
                message : I18n.t('challenge.finalizeMessages.challenge3', {userName:  name })
            },
            4: {
                message : I18n.t('challenge.finalizeMessages.challenge4', {userName:  name })
            },
            5: {
                message : I18n.t('challenge.finalizeMessages.challenge5', {userName:  name })
            }
        }

        return _.get(messages,`[${challengeId}].message`, '');

    }

    render() {

        const { challengeId, userSession, onBackToMeasurement, onUpdateMatriz } = this.props; 

        return (
            <ResultBox>
                <Content>
                    <div className="paragraph regular" dangerouslySetInnerHTML={{__html: this.setEndChallengeMessage(challengeId, _.get(userSession,'name', '')) }}></div>
                    <p></p>
                    <p className="paragraph regular">{I18n.t('challenge.finalizeMessages.generic')}</p>
                    <ActionBtn>
                        <Responsive minWidth={420} as={React.Fragment}>
                            <OButtonStyled type="button" upper secondary onClick={onBackToMeasurement} ><span>{ I18n.t(`challenge.backToMeasurement${challengeId}`) }</span></OButtonStyled>
                            <OButton type="button" upper primary onClick={onUpdateMatriz} ><span>{  I18n.t('challenge.updateMatriz') }</span></OButton>
                        </Responsive>
                        <Responsive maxWidth={419} as={React.Fragment}>
                            <OButtonStyled type="button" upper secondary onClick={onBackToMeasurement} ><span>{ I18n.t(`challenge.backToMeasurement_short_${challengeId}`) }</span></OButtonStyled>
                            <OButton type="button" upper primary onClick={onUpdateMatriz} ><span>{  I18n.t('challenge.updateMatriz_short') }</span></OButton>
                        </Responsive>
                    </ActionBtn>
                </Content>
            </ResultBox>
        )
    }
}

export default ResultChallenges;