import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components/macro';

const FrameCheckBox = styled.div`
    padding:20px 0;
`;

const OCheckBox = styled(Checkbox)`
    &&& {
        label {
            font-size: 12px;
            font-family: var(--font-secondary);
        }
    }
`;

export {
    FrameCheckBox,
    OCheckBox
}