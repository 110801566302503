// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

// Components
import Cards from '../../components/cards';
import Footer from '../../components/footer';
import GradientNav from '../../components/navbar/gradient';
import SubMenu from '../../components/navbar/submenu';
import TopBar from '../../components/navbar/topbar';

// Styled components
import OButton from '../../styled/components/button';

// Redux Store
import { addToCart, getMarketplace } from '../../../redux-store/cart';
import { openGenericModal, closeGenericModal } from '../../../redux-store/genericModal';
import { openLoader, closeLoader } from '../../../redux-store/loader';

// Locales
import I18n from '../../../i18n';

// Styles
import './studio.scss';

class Studio extends Component {

    constructor(props) {

        super(props);

        this.state = {
            selectedNavItem: 0,
            navItemsOptions: [{
                key:0,
                value:0,
                text: I18n.t('menu.allAsanas')
            },
            {
                key:1,
                value:1,
                text: I18n.t('menu.myAsanas')
            }]
        };

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        const { openLoader, closeLoader } = this.props;

        try {

            openLoader();
            await this.props.getMarketplace();

        } catch (error) {

            console.error(error);

        } finally {

            closeLoader();
        }

    }

    addToCart = id => {

        const { addToCart, products } = this.props;

        if (window.cordova && _.size(_.filter(products, ({ added }) => added)) > 9) {

            const { openGenericModal, closeGenericModal, history } = this.props;

            openGenericModal({
                type: 'simple',
                title: {
                    text: I18n.t('messages.toast'),
                    classes: ['heading-2']
                },
                description: {
                    text: I18n.t(`${ window.device.platform }.maxNumberOfSchoolProducts`, { maxProducts: 10 }),
                    classes: ['paragraph', 'regular']
                },
                buttons: [{
                    text: I18n.t('actions.understood'),
                    callback: () => {

                        closeGenericModal();
                        history.push('/cart');

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true
                    }
                }]
            });

        } else {

            addToCart(id);

        }

    }

    selectNavItem = index => this.setState({ selectedNavItem: index });

    goTo = id => this.props.history.push(`/studio/details/${ id }`);

    render() {

        const { products, role, currency } = this.props;
        const { selectedNavItem, navItemsOptions } = this.state;
        const userProducts = _.filter(products, ({ buyed }) => buyed);

        return (
            <React.Fragment>
                <GradientNav active="studio" { ...this.props } />
                <div className="st-wrapper">
                    <TopBar { ...this.props } text="" />
                    <div className="inner">
                        <div className="st-nav">
                            <div className="verticalPad">
                                <Dropdown placeholder='State' value={ selectedNavItem } options={ navItemsOptions } onChange={ (e, { value }) => this.selectNavItem(value) }/>
                            </div>
                        </div>
                        <div className="st-flex">
                            <div className="st-aside">
                                <div className="st-aside-menu">
                                    <div className={ `item ${ selectedNavItem === 0 ? 'active' : '' }`} onClick={ () => this.selectNavItem(0) }><span>{ I18n.t('menu.allAsanas') }</span></div>
                                    <div className={ `item ${ selectedNavItem === 1 ? 'active' : '' }`} onClick={ () => this.selectNavItem(1) }><span>{ I18n.t('menu.myAsanas') }</span></div>
                                </div>
                            </div>
                            <div className="st-content">
                                <div className={ `st-view ${ selectedNavItem === 0 ? 'active' : ''}` }>
                                    <div className="st-header">
                                        <p className="st-title">{ I18n.t('studio.title') }</p>
                                        <p className="st-description" dangerouslySetInnerHTML={{ __html: I18n.t('studio.subTitle') }}></p>
                                        { window.cordova && <span style={{ fontSize: '12px', color: 'red' }}>{ I18n.t(`${ window.device.platform }.webDifferentPricesAdvise`) }&reg;</span> }
                                    </div>
                                    <div className="st-grid-wrapper">
                                        <div className="st-grid">
                                            <Cards
                                                context="grid"
                                                currency={ currency }
                                                list={ _.filter(products, ({ published }) => published) }
                                                onAddToCart={ this.addToCart }
                                                goTo={ this.goTo }
                                                role={ role }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={ `st-view ${ selectedNavItem === 1 ? 'active' : ''}` }>
                                    { userProducts.length > 0 ?
                                        <div className="st-grid">
                                            <Cards context="grid" list={ userProducts } goTo={ this.goTo } role={ role } currency={ currency } />
                                        </div>
                                        :
                                        <div className="st-empty">
                                            <p className="st-empty-message">{ I18n.t('studio.emptyAsanas') }</p>
                                                <OButton className="st-empty-btn" primary color="#fff" upper type="button" onClick={ () => this.selectNavItem(0) }>
                                                    <span>{ I18n.t('studio.allAsanas') }</span>
                                                </OButton>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer { ...this.props } type="branded"/>
                </div>
                <SubMenu active="studio" { ...this.props }/>
            </React.Fragment>
        );

    }

}

const mapStateToProps = state => {

    return {
        products: _.get(state, 'cart.products', []),
        role: state.role,
        currency: _.get(state, 'auth.userSession.config.currency', {})
    };

};

export default connect(mapStateToProps, { addToCart, getMarketplace, openLoader, closeLoader, openGenericModal, closeGenericModal })(Studio);