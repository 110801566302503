import React from 'react';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import { Steps, Step, Actions, Check, Text } from './styles';
import { FrameClose, FrameModal, ModalContent } from '../../styles/genericModal';
import OButton from '../../../../styled/components/button';
import I18n from '../../../../../i18n';


/***
 * * STEPS CHALLENGE MODAL
 * 
 * @param buttons Array Objects
 * @param completedSteps Number Array [1,2]
 * @param onClose Function
 * @params @buttons { text, callback, options = { any button props } }
 * @description This modal handle the view of the challenge step showing the info to the user. 
 */

export default function StepModal({ buttons = [], completedSteps = [], completedStep, onClose, ...restProps }) {

    return (
        <FrameModal>
            <FrameClose>
                <Icon name="close" size="large" onClick={ onClose }/>
            </FrameClose>
            <ModalContent>
                <p className="heading-2 brand-secondary" style={{margin: '20px 0 5px 0'}}>{I18n.t('modals.stepModal.title')}</p>
                <p className="heading-2">{I18n.t('modals.stepModal.subtitle', { step: completedStep })}</p>
                <Steps>
                    <Step>
                        <Check>
                            { completedSteps.indexOf(1) >-1 ? <Icon name="check circle outline" size="big" /> : <Icon name="circle outline" size="big" /> }
                        </Check>
                        <Text>
                            <p className="heading-3" style={{marginBottom: "5px"}}>{I18n.t('modals.stepModal.stepText', { step: 1 })}</p>
                            <p className="caption regular">{I18n.t('modals.stepModal.step1Description')}</p>
                        </Text>
                    </Step>
                    <Step>
                        <Check>
                            { completedSteps.indexOf(2) >-1 ? <Icon name="check circle outline" size="big" /> : <Icon name="circle outline" size="big" /> }
                        </Check>
                        <Text>
                            <p className="heading-3" style={{marginBottom: "5px"}}>{I18n.t('modals.stepModal.stepText', { step: 2 })}</p>
                            <p className="caption regular">{I18n.t('modals.stepModal.step2Description')}</p>
                        </Text>
                    </Step>
                    <Step>
                        <Check>
                            { completedSteps.indexOf(3) >-1 ? <Icon name="check circle outline" size="big" /> : <Icon name="circle outline" size="big" /> }
                        </Check>
                        <Text>
                            <p className="heading-3" style={{marginBottom: "5px"}}>{I18n.t('modals.stepModal.stepText', { step: 3 })}</p>
                            <p className="caption regular">{I18n.t('modals.stepModal.step3Description')}</p>
                        </Text>
                        
                    </Step>
                </Steps>
                <Actions>
                    {
                        _.map(buttons, ({text, callback, options }, index) => (
                            <OButton key={index} type="button" {...options} onClick={ callback }><span>{text}</span></OButton>
                        ))
                    }
                </Actions>
            </ModalContent>
            
        </FrameModal>
    )

}