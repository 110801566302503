import React from 'react';
import FaqFormPrivate from '../components/FaqFormPrivate';
import FaqFormPublic from '../components/FaqFormPublic';

class FaqQuestionHandler extends React.Component {

    render() {

        return (
            <React.Fragment>
                
                { window.localStorage.getItem('jwt') ? <FaqFormPrivate {...this.props} /> : <FaqFormPublic {...this.props} /> }

            </React.Fragment>
        );

    }

}

export default FaqQuestionHandler;
