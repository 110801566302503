import React from 'react';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import './faqBox.scss';

class FaqBox extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            active: -1
        }

    }

    onSelectItem = index => {

        this.setState({ active : index === this.state.active ? -1 : index} );

    }

    render() {

        return (
            <React.Fragment>
                <div className={"faq-box " + (this.props.type || '')}>
                    { _.get(this.props, 'faqs', []).map((faq, index) => (
                        <div className={"faq-row " + (index % 2 === 0 ? 'evenColor' : 'oddColor') + (this.state.active === index ?  ' active' :  '' )} key={faq._id}>
                            <div className="faq-header" onClick={ () => this.onSelectItem(index) }>
                                <div className="faq-title">{faq.title}</div>
                                <div className={"closure " + (this.state.active !== index ?  'rotate' :  '' )}><Icon name='times'  /></div>
                            </div>
                            <div className={"faq-body " + (this.state.active === index ?  'active' :  '' ) } dangerouslySetInnerHTML={{__html: faq.text}}></div>
                        </div>
                    ))}
                 </div>
            </React.Fragment>
        );

    }
    
};

export default FaqBox;