import styled, { css } from 'styled-components/macro';

const NavContainer = styled.div`
    height: var(--nav-height);
    background-color: var(--y-grey3);
    position: relative;
    z-index: 100;
    @media only screen and (max-width:920px) {
        display: none;
    }
`;

const NavContent = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`;

const MainLogo = styled.div`

    width: 150px;
    cursor: pointer;
    img {
        max-width: 100%;
        height:auto;
    }

`;

const NavItemsContainer = styled.div`
    height: 100%;
`;

const NavItems = styled.ul`
    display: flex;
    font-family: PoppinsRegular;
    font-size: 14px;
    align-items: stretch;
    justify-content: stretch;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
   
`;

const NavItem = styled.li`
    padding: 0 40px;
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: var(--brand-white);
    &:before {
        content: "";
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: var(--y-green1);
        opacity: 0;
        transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &:hover {
        background-color: #283445;
     }
    ${({active}) => active &&
        css`
            &:before {
                opacity: 1;
            }
    `};

    ${({hasNotify}) => hasNotify &&
        css`
            &:after {
                content: "";
                background-color: var(--red-color);
                position: absolute;
                top: 13px;
                width: 8px;
                height: 8px;
                right:-10px;
                border-radius: 50%;
                margin:0 40px;
            }
           
    `};
    
`;

const NavItemsSecondary = styled.div`

    display: flex;
    position: relative;
    height: 100%;
`;

const NavItemSecondary = styled.div`
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 10px;
    i {
        font-size: 22px;
        height: auto;
        &:before {
            color: white;
        }
    }
`;

const Budget = styled.span`
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    min-width: 15px;
    min-height: 15px;       
    padding: 0 2px;
    box-sizing: border-box;
    color: white;
    background-color: var(--y-green1);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    margin: 0;
    
    p{
        margin: 0;
    }

    ${({hasPendings}) => hasPendings && css`background-color: var(--red-color-secondary)`};

`;

export {
    NavContainer,
    NavContent,
    MainLogo,
    NavItemsContainer,
    NavItems,
    NavItem,
    NavItemsSecondary,
    NavItemSecondary,
    Budget
}