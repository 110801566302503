import React from 'react';
import { Icon } from 'semantic-ui-react';
import { FrameBody, FrameContent, FrameProgress, Progress, ProgressBar, ProgressInner } from './styles';
import { FrameClose, FrameModal } from '../../styles/genericModal';
import I18n from '../../../../../i18n';

/***
 * * RESUME PROGRESS MODAL
 * @param step1Progress Number
 * @param step2Progress Number
 * @param step3Progress Number
 * @param remainingDays Number
 * @param buttons Array Objects
 * @param onClose Function
 * @description 
 * 
 */

export default function ResumeProgressModal({  step1Progress, step2Progress, step3Progress, remainingDays, onClose, ...restProps }) {

    return (
        <FrameModal>
            <FrameBody>
                <FrameClose>
                    <Icon name="close" size="large" onClick={ onClose }/>
                </FrameClose>
                <FrameContent>
                    <p className="paragraph" style={{marginBottom: '5px'}}>{I18n.t('modals.resumeProgressModal.title')}</p>
                    <p className="paragraph regular">{I18n.t('modals.resumeProgressModal.remainingDays', { remainingDays })} </p>
                    <FrameProgress>
                        <Progress>
                            <span className="caption">{I18n.t('modals.resumeProgressModal.progress1Text')}</span>
                            <span className="caption">{step1Progress}%</span>
                        </Progress>
                        <ProgressBar>
                            <ProgressInner progress={step1Progress}></ProgressInner>
                        </ProgressBar>
                        <Progress>
                            <span className="caption">{I18n.t('modals.resumeProgressModal.progress2Text')}</span>
                            <span className="caption">{step2Progress}%</span>
                        </Progress>
                        <ProgressBar>
                            <ProgressInner progress={step2Progress}></ProgressInner>
                        </ProgressBar>
                        <Progress>
                            <span className="caption">{I18n.t('modals.resumeProgressModal.progress3Text')}</span>
                            <span className="caption">{step3Progress}</span>
                        </Progress>
                        <ProgressBar>
                            <ProgressInner progress={ Math.min(Math.round(step3Progress / 7 * 100), 100) }></ProgressInner>
                        </ProgressBar>
                    </FrameProgress>
                </FrameContent>
            </FrameBody>
        </FrameModal>
    )

}