import React from 'react';
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles

import { SwipperContainer, Item, AuthorPicture, ItemAuthor, ItemHeader, ItemContent, ItemSeparator } from './styled';

import testimonial1 from '../../../../../../assets/img/secondary-landing/testimonial_1.jpeg';
import testimonial2 from '../../../../../../assets/img/secondary-landing/testimonial_2.jpeg';
import testimonial3 from '../../../../../../assets/img/secondary-landing/testimonial_3.jpeg';
import testimonial4 from '../../../../../../assets/img/secondary-landing/testimonial_4.jpeg'; 
import testimonial5 from '../../../../../../assets/img/secondary-landing/testimonial_5.jpeg'; 

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default () => {
  return (
      <SwipperContainer>
        <Swiper
            spaceBetween={1}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            
            breakpoints={{
                // when window width is >= 640px
                720: {
                  slidesPerView: 2,
                },
                // when window width is >= 768px
                1024: {
                 
                  slidesPerView: 3,
                }

            }}
        >
        <SwiperSlide>
            <ItemSeparator>
                <Item>
                    <ItemHeader>
                        <ItemAuthor>
                            <p className="paragraph">Cristina Hernando</p>
                            <p className="caption light-grey-1 regular">Alumno verificado</p>
                        </ItemAuthor>
                        <AuthorPicture>
                            <img src={testimonial1} alt="" />
                        </AuthorPicture>
                    </ItemHeader>
                    <ItemContent>
                        <p className="labeling regular">He utilizado varias veces Yogabot y quiero decir que me ha sorprendido lo desarrollada que está. El diagnóstico permite tomar conciencia de las zonas menos desarrolladas y de los desequilibrios corporales entre zonas (derecha/izquierda o tronco superior/inferior). Esto ha supuesto un gran «insight» para mí.</p>
                        <p className="labeling regular">La personalización de las secuencias, es un enfoque muy novedoso que da mayor potencial al yoga en sí mismo. Las secuencias son variadas, lo que da interés a la práctica, que no es rutinaria. Y además son exigentes (bravo!!); me sorprendió porque en clases de yoga online (bueno, y offline…) lo que abundan son prácticas relajantes y restaurativas.</p>
                        <p className="labeling regular">Es muy fácil de usar, la interface es limpia, clara, sencilla. FELICIDADES</p>
                    </ItemContent>
                </Item>
            </ItemSeparator>
        </SwiperSlide>
        <SwiperSlide>
            <ItemSeparator>
                <Item>
                    <ItemHeader>
                        <ItemAuthor>
                            <p className="paragraph">Diana Fernandez de Cordova Carvajal</p>
                            <p className="caption light-grey-1 regular">Alumno verificado</p>
                        </ItemAuthor>
                        <AuthorPicture>
                            <img src={testimonial2} alt="" />
                        </AuthorPicture>
                    </ItemHeader>
                    <ItemContent>
                        <p className="labeling regular">Yogabot es una estúpenda herramienta. Me ha permitido superar las desigualdades en mi cuerpo a nivel de tono y elasticidad permitiéndome alcanzar el equilibrio en muy corto tiempo, a través de la práctica hecha a mi medida lograda por el sistema de autodiagnóstico que tiene el programa. Absolutamente perfecta!!!</p>
                        <p className="labeling regular">Además me ha brindado la posibilidad de autodiagnosticocceder a otras prácticas como la que esta diseñada para el dolor de cabeza. El programa en siguiente diseño y contenido son de altísimo valor.</p>
                        <p className="labeling regular">Reflejan el trabajo de auténticos profesionales. Es altamente recomendable!</p>
                    </ItemContent>
                </Item>
            </ItemSeparator>
        </SwiperSlide>
        <SwiperSlide>
            <ItemSeparator>
                <Item>
                    <ItemHeader>
                        <ItemAuthor>
                            <p className="paragraph">Mercedes Socorro Martel</p>
                            <p className="caption light-grey-1 regular">Alumno verificado</p>
                        </ItemAuthor>
                        <AuthorPicture>
                            <img src={testimonial3} alt="" />
                        </AuthorPicture>
                    </ItemHeader>
                    <ItemContent>
                        <p className="labeling regular">Yogabot es una aplicación maravillosa que nos permite "ver-nos" y conocernos un poco más, a través del test de la matriz biométrica.</p>
                        <p className="labeling regular">Te da una secuencia con la que practicar, de forma muy eficiente y al volver a realizar la matriz al cabo de una semana más o menos ves, además de tu darte cuenta de los cambios , la evolución en imágenes.</p>
                        <p className="labeling regular">Es la mejor forma de practicar yoga de forma personalizada, y el equilibrio se va manifestando en el sistema nervioso y en la profundización en la experiencia de yoga</p>
                        <p className="labeling regular">Estoy entusiasmada con esta herramienta. Muchísimas gracias a Jose y Jordys, como se nota que lo tienen super claro. Adelante</p>
                    </ItemContent>
                </Item>
            </ItemSeparator>
        </SwiperSlide>
        <SwiperSlide>
            <ItemSeparator>
                <Item>
                    <ItemHeader>
                        <ItemAuthor>
                            <p className="paragraph">Anne Stolz</p>
                            <p className="caption light-grey-1 regular">Alumno verificado</p>
                        </ItemAuthor>
                        <AuthorPicture>
                            <img src={testimonial4} alt="" />
                        </AuthorPicture>
                    </ItemHeader>
                    <ItemContent>
                    <p className="labeling regular">Yogabot me resulta una herramienta fantástica, explícita y muy bien lograda. Se puede seguir con gran facilidad y con los tiempos adecuados, previo análisis de nuestro cuerpo y capacidades.</p>
                    <p className="labeling regular">Es una de las mejores aplicaciones que he probado. La recomiendo a todo alumno que busque una clase impecablemente descrita por José Cao, fácil de seguir y con la debida alineación para no lesionarse!!!!!</p>
                    </ItemContent>
                </Item>
            </ItemSeparator>
        </SwiperSlide>
        <SwiperSlide>
            <ItemSeparator>
                <Item>
                    <ItemHeader>
                        <ItemAuthor>
                            <p className="paragraph">Raquel Pintor</p>
                            <p className="caption light-grey-1 regular">Alumno verificado</p>
                        </ItemAuthor>
                        <AuthorPicture>
                            <img src={testimonial5} alt="" />
                        </AuthorPicture>
                    </ItemHeader>
                    <ItemContent>
                        <p className="labeling regular">Recomiendo 100% Yogabot a todo practicante de yoga! Es una herramienta de Yoga que te ayuda y te guía para fortalecer tu cuerpo. Mediante una serie de retos mide tu matriz biométrica, señalando tus puntos fuertes y tus puntos débiles.</p>
                        <p className="labeling regular">Yogabot busca tu equilibrio proporcionando una secuencia de asanas según tus necesidades. Y tú a qué estás esperando!!</p>
                    </ItemContent>
                </Item>
            </ItemSeparator>
        </SwiperSlide>
        </Swiper>
    </SwipperContainer>
  );
};