// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Icon, Image, List } from 'semantic-ui-react';

// Utils
import { toHHMMSS } from '../../../utils/formatTime';

// Components
import CircleProgress from '../../components/biometricMatrix/components/circleProgress';
import FixedBanner from '../../components/fixedBanner';
import Footer from '../../components/footer';
import GradientNav from '../../components/navbar/gradient';
import { Indicator, IndicatorText, Title, Subtitle, IndicatorCircle, InfoIcon } from '../../components/biometricMatrix/components/indicators/styles';
import PracticeGridEmpty from './components/practiceGrid/empty';
import PracticeGridSimple from './components/practiceGrid/simple';
import PracticeGridTall from './components/practiceGrid/tall';
import ProModal from '../../components/proModal';
import SubMenu from '../../components/navbar/submenu';
import TopBar from '../../components/navbar/topbar';

// Styled components
import { InnerWrapper } from '../../styled/components/wrappers';
import OButton from '../../styled/components/button';

// Models
import SecurityModel from '../../../data/models/security/security';
import SessionModel from '../../../data/models/session/session';

// Redux
import { openLoader, closeLoader } from '../../../redux-store/loader';
import { openGenericModal, closeGenericModal } from '../../../redux-store/genericModal';

// Locales
import I18n from '../../../i18n';

// Styles
import './practiceIntegration.scss';

// Assets
import DemoImage from '../../assets/img/yogabot_icon.svg';
import seqShortImage from '../../assets/img/secuencia_corta.jpg';
import seqMediumImage from '../../assets/img/secuencia_media.jpg';
import seqLargeImage from '../../assets/img/secuencia_larga.jpg';

class PracticeIntegration extends React.Component {

    constructor (props) {

        super (props);

        this.ref = null;
        this.state = {
            categories: [],
            alternativePratices: [],
            comingSoonCategories: [],
            customPracticeList: [{
                practiceCover: seqShortImage,
                sequenceDuration: I18n.t('practice.sequenceShort'),
                sequenceValue: "-1",
                id: 1
            }, {
                practiceCover: seqMediumImage,
                sequenceDuration: I18n.t('practice.sequenceMedium'),
                sequenceValue: "0",
                id: 2
            }, {
                practiceCover: seqLargeImage,
                sequenceDuration: I18n.t('practice.sequenceLong'),
                sequenceValue: "1",
                id: 3
            }],
            proInfo: undefined,
            proSequences: [],
            proModalOpen: false,
            proSelected: undefined
        };

    }

    componentDidMount() {

        this.onGetData();

    }

    componentWillUnmount() {

        this.props.closeGenericModal();

    }

    applyAssignmentLayer = (userAssignments, practices, programs) => {

        _.each(practices, sequence => {

            if (sequence.public !== 1 && _.find(userAssignments, ({ sourceId }) => sourceId === sequence._id || sequence.categories.includes(sourceId))) {

                sequence.public = 1;

            }

        });

        _.each(programs, program => {

            if (program.public !== 1 && _.find(userAssignments, ({ sourceId }) => sourceId === program._id || program.categories.includes(sourceId))) {

                program.public = 1;

            }

        });

        return {
            practices: _.filter(practices, practice => practice.public !== 2),
            programs: _.filter(programs, program => program.public !== 2)
        };

    }

    onGetData = async () => {

        try {

            this.props.openLoader();
            const _categories = await SessionModel.getSequenceCategories();
            const _practices = await SessionModel.getSequences();
            const _programs = await SessionModel.getPrograms();
            const userAssignments = await SessionModel.getUserAssignments();

            const practices = [];
            const comingSoonCategories = [];

            /**
             * * buscar por cada categoría las secuencias asociadas y no vinculadas a un programa
             * * buscar por cada categoría los programas asociados
            */

            //capa de control de asignaciones

            const filteredAssignments = this.applyAssignmentLayer(userAssignments.data, _practices.data, _programs.data);
            _practices.data = filteredAssignments.practices;
            _programs.data = filteredAssignments.programs;

            _.each(_categories.data, ({ label, _id, comingSoonDisabled }, index) => {

                practices.push({ categoryId: _id, category: label, practices: _.filter(_practices.data, ap => ap.categories.includes(_id) && !ap.onlyProgram ) });
                //**si la categoria tiene programsa asociados...añadirlos al nodo practices de la categoria

                const programsByCategory = _.filter(_programs.data,  p => p.categories.includes(_id));
                if (programsByCategory.length > 0) {

                    _.each(programsByCategory, p => {

                        practices[index].practices.push(_.assign(p, { isProgram: true }));

                    });

                }

                if (!practices[index].practices.length && !comingSoonDisabled) {

                    comingSoonCategories.push({ categoryId: _id, category: label });

                }

            });

            const proInfo = await SecurityModel.getProFullInfo();
            let proSequences = [];
            if (_.size(proInfo) > 0 ) {

                proSequences = await SessionModel.getUserSequences();

            }

            this.setState({
                categories: _categories.data,
                alternativePratices: practices,
                comingSoonCategories,
                proInfo,
                proSequences
            });

        } catch (error) {

            console.error('onGetData:error', error);


        } finally {

            this.props.closeLoader();

        }

    }

    onLaunchCustomSequence = duration => {

        const { userConfig, bioMetricMatrix, history } = this.props;

        const sequence = window.btoa(duration + bioMetricMatrix.sequence + '_' + userConfig.practiceLevel + '/' + userConfig.practiceAudioLanguage + '/' + userConfig.practicePresentationType + '/' + false);

        history.push(`/practice/${ sequence }`);

    }

    onLaunchAlternativePractice = (practiceId, isProgram) => !isProgram ? this.props.history.push(`/practiceList/detail/${ practiceId }`) : this.props.history.push(`/practiceList/program/${ practiceId }`)

    onPracticeNow = ({ code }) => {

        const { userConfig, history } = this.props;
        const sequence = window.btoa(code + '/' + userConfig.practiceAudioLanguage + '/' + userConfig.practicePresentationType+ '/' + true);
        history.push(`/practice/${ sequence }`);

    }

    openPopup = () => {

        const { bioMetricMatrix, sessionTime, openGenericModal, closeGenericModal, customSessionStats } = this.props;
        const message = I18n.t('home.stats.messages.five.messagePopup', {
            totalPracticeWeekTime: toHHMMSS(sessionTime || 0),
            avgProgress: (bioMetricMatrix.progress || 0) + '%',
            weekPracticeCount: _.round(customSessionStats.lastWeekUserAlternativeSessionsCount / 2) + customSessionStats.lastWeekUserCustomSessionsCount
        });

        openGenericModal({
            type: 'simple',
            size: "tiny",
            title: {
                text: I18n.t('messages.info'),
                classes: ['heading-2']
            },
            description: {
                text: message,
                classes: ['labeling', 'regular']
            },
            buttons: [{
                text: I18n.t('actions.understood'),
                callback: closeGenericModal,
                options: {
                    primary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

    }

    setTotalPracticeTime = () => {

        const { bioMetricMatrix, customSessionStats } = this.props;
        return _.round((Math.min((customSessionStats.lastWeekUserAlternativeSessionsCount / 2) + customSessionStats.lastWeekUserCustomSessionsCount, 4) * (bioMetricMatrix.progress || 0) / 400) * 100);

    }

    getProDescriptionWithEllipsis = description => {

        const limit = 210;
        return description.length > limit ? description.substring(0, limit - 3) + '...' : description;

    };

    getDurationInMinutesFromSeconds = seconds => _.round(seconds / 60) + ' ' + I18n.t('practice.minutes');

    getInitials = text => text && text.length > 0 ? (text.substring(0, 1) + (text.includes(' ') ? text.substring(text.indexOf(' ') + 1, text.indexOf(' ') + 2) : '')).toUpperCase() : '';

    renderProSection = proInfo => {

        const { proSequences } = this.state;

        return <div key={ _.get(proInfo, '_id', Math.random()) } className='proBox'>
            <span className='proSectionTitle'>{ I18n.t('practice.yourTeacher') }</span>
            <div className='proInfoBox'>
                { proInfo ? <>
                        <span className={ `initials ${ !!!_.get(proInfo, 'pictureUrl') ? 'visible' : ''}` } >{ this.getInitials(_.get(proInfo, 'name', '') + ' ' + _.get(proInfo, 'surname', '')) }</span>
                        <Image avatar src={ _.get(proInfo, 'pictureUrl', DemoImage) } className={ !!!_.get(proInfo, 'pictureUrl') ? 'broken' : '' } onError={ e => { e.target.classList.add('broken'); e.target.src = DemoImage } } />
                        <div className='info'>
                            <span className='title'>{ _.get(proInfo, 'name', '') + ' ' + _.get(proInfo, 'surname', '') }</span>
                            <span className='description'>{ this.getProDescriptionWithEllipsis(_.get(proInfo, 'about', '')) }</span>
                            <span className='seeMore' onClick={ () => this.setState({ proModalOpen: true, proSelected: proInfo }) }>{ I18n.t('practice.readMore') }</span>
                        </div>
                    </> : <><Icon name='info circle' /> <span className='empty'>{ I18n.t('practice.emptyPro') }</span></>
                }
            </div>
            <span className='proSectionTitle'>{ I18n.t('practice.yourPracticeForToday') }</span>
            <div className='proPracticesBox'>
                { !proInfo ? <><Icon name='info circle' /> <span className='empty'>{ I18n.t('practice.emptyProSequences') }</span></> :
                    _.size(proSequences) === 0 ? <><Icon name='info circle' /> <span className='empty'>{ I18n.t('practice.emptySequences') }</span></> :
                        <List divided relaxed>
                            { _.map(proSequences, (sequence, index) =>
                                <List.Item key={ sequence._id + '-' + index }>
                                    <span className={ `initials ${ !!!sequence.thumbUrl ? 'visible' : ''}` } >{ this.getInitials(sequence.title) }</span>
                                    <Image src={ sequence.thumbUrl || DemoImage } className={ !!!sequence.thumbUrl ? 'broken' : '' } onError={ e => { e.target.classList.add('broken'); e.target.src = DemoImage } } />
                                    <List.Content>
                                        <List.Header>{ sequence.title }</List.Header>
                                        <List.Description>{ this.getDurationInMinutesFromSeconds(sequence.duration) + ' - ' + (sequence.group || I18n.t('practice.individual')) }</List.Description>
                                    </List.Content>
                                    <OButton
                                        upper
                                        primary
                                        color='#fff'
                                        type='button'
                                        onClick={ () => this.onLaunchAlternativePractice(sequence.id, false) }
                                    >
                                        <span>{I18n.t('practice.goTo')}</span>
                                    </OButton>
                                </List.Item>
                            ) }
                        </List>
                }
            </div>
            <span className='proSectionTitle'>{ I18n.t('practice.yourGroups') }</span>
            <div className='proGroupsBox'>
                { !proInfo ? <><Icon name='info circle' /> <span className='empty'>{ I18n.t('practice.emptyProGroups') }</span></> :
                    _.size(proInfo.groups) === 0 ? <><Icon name='info circle' /> <span className='empty'>{ I18n.t('practice.emptyGroups') }</span></> :
                        <List divided relaxed>
                        { _.map(proInfo.groups, group =>
                            <List.Item key={ group._id }>
                                <span className={ `initials ${ !!!group.imageUrl ? 'visible' : ''}` } >{ this.getInitials(group.name) }</span>
                                <Image src={ group.imageUrl || DemoImage } className={ !!!group.imageUrl ? 'broken' : '' } onError={ e => { e.target.classList.add('broken'); e.target.src = DemoImage } } />
                                <List.Content>
                                    <List.Header>{ group.name }</List.Header>
                                    <List.Description>{ I18n.t('practice.members', { studentCount: group.studentCount}) }</List.Description>
                                </List.Content>
                            </List.Item>
                        ) }
                    </List>
                }
            </div>
        </div>;

    }

    render() {

        const { alternativePratices, comingSoonCategories, customPracticeList, proInfo, proModalOpen, proSelected } = this.state;
        const { role } = this.props;
        const roles = _.get(role || {}, 'role', []);

        const config =  {
            role: roles,
            list: customPracticeList,
            mainAction: item => { this.onLaunchCustomSequence(item.sequenceValue) },
            footerItems: item => <span className="footer-item caption regular">{ I18n.t('practice.durationParams', {duration: item.sequenceDuration}) }</span>,
            thumbnail: { attribute: 'practiceCover' }
        };

        return (
            <React.Fragment>
                <ProModal open={ proModalOpen } onClose={ () => this.setState({ proModalOpen: false, proSelected: undefined }) } pro={ proSelected } />
                <GradientNav active="practice" { ...this.props } />
                <div className="main-wrap">
                    <TopBar { ...this.props } />
                    <FixedBanner />
                        <InnerWrapper>
                            <div className="wrapper-slick-grid">
                                <div className="block-separator shadowed" >
                                    <div className="p-header">
                                        <p className="heading-1">{ I18n.t('practice.practiceTitle') }</p>
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper-slick-grid">
                                <div className="block-separator shadowed" >
                                    <Indicator style={{ maxWidth: '490px' }}>
                                        <IndicatorCircle>
                                            <CircleProgress percent={ this.setTotalPracticeTime() } />
                                        </IndicatorCircle>
                                        <IndicatorText>
                                            <Title><p className="heading-3">{ I18n.t('practice.aproachPractice') }</p></Title>
                                            <Subtitle>
                                                <span className="paragraph regular">{ I18n.t('home.stats.messages.five.message') }</span>
                                                <InfoIcon className="f-icon-info-circled" onClick={ this.openPopup }></InfoIcon>
                                            </Subtitle>
                                        </IndicatorText>
                                    </Indicator>
                                </div>
                            </div>
                            <div className="wrapper-slick-grid">
                                <div className="block-separator shadowed" >
                                    <p className="heading-2" style={{ marginLeft: '10px', marginBottom: '15px' }}>{ I18n.t('practice.practiceCustomizeBy') } <span className="cbrand">{ I18n.t('practice.byYourTeacher') }</span></p>
                                    {
                                        _.size(proInfo) === 0 ? this.renderProSection(undefined) : _.map(proInfo, data => this.renderProSection(data))
                                    }
                                </div>
                            </div>
                            <div className="wrapper-slick-grid">
                                <div className="block-separator shadowed" >
                                    <p className="heading-2" style={{ marginLeft: '10px', marginBottom: '5px' }}>{ I18n.t('practice.yourCustomizedPractice') } <span className="cbrand">{ I18n.t('practice.byYogabot') }</span></p>
                                    <p className="paragraph regular" style={{ marginLeft: '10px' }}>{ I18n.t('practice.sequenceOptimized') }</p>
                                </div>
                            </div>
                            <div className="wrapper-slick-grid">
                                <div className="block-separator shadowed">
                                    <PracticeGridTall { ...config } />
                                </div>
                            </div>
                            <div className="wrapper-slick-grid">
                                <div className="block-separator shadowed" >
                                    { (alternativePratices || []).map(practice => (practice.practices.length > 0 &&
                                        <React.Fragment key={ practice.categoryId }>
                                            <p className="heading-2" style={{ marginLeft: '10px', marginBottom: '5px' }}>{ practice.category }</p>
                                            <PracticeGridSimple role={ roles } list={ practice.practices } onLaunchPractice={ this.onLaunchAlternativePractice } />
                                        </React.Fragment>
                                    )) }
                                </div>
                            </div>
                            <div className="wrapper-slick-grid" style={{ paddingBottom: '40px' }}>
                                { comingSoonCategories &&
                                    <React.Fragment>
                                        <p className="heading-2" style={{ marginLeft: '10px', marginBottom: '5px' }}>{ I18n.t('practice.comingSoon') }</p>
                                        <PracticeGridEmpty list={ comingSoonCategories } />
                                    </React.Fragment>
                                }
                            </div>
                        </InnerWrapper>
                    <Footer { ...this.props } type="branded"/>
                </div>
                <SubMenu active="practice" { ...this.props }/>
            </React.Fragment>
        );

    }

}

const mapStateToProps = state => {

    return {
        sessionTime: _.get(state, 'session.time', 0),
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        role: state.role,
        userConfig: _.get(state, 'auth.userSession.config'),
        customSessionStats: _.get(state, 'customSessionStats', {})
    };

};

export default connect(mapStateToProps, { openLoader, closeLoader, openGenericModal, closeGenericModal })(PracticeIntegration);