// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';

// Redux
import { closeGenericModal } from '../../../redux-store/genericModal';

// Components
import AddInjuriesModal from './components/addInjuriesModal';
import ChallengeCompletedModal from './components/challengeCompletedModal';
import ResumeProgressModal from './components/resumeProgressModal';
import ShareDataAcceptModal from './components/shareDataAcceptModal';
import StepModal from './components/stepsModal';
import SimpleModal from './components/simpleModal';
import WalletModal from './components/walletModal';

function HandleModal({ message, onClose }) {

    switch(message.type) {

        case 'addInjuriesModal':

            return <AddInjuriesModal { ...message } onClose={ onClose } />

        case 'steps':

            return <StepModal { ...message } onClose={ onClose } />

        case 'challengeCompleted':

            return <ChallengeCompletedModal { ...message } onClose={ onClose } />

        case 'shareDataAccept':

            return <ShareDataAcceptModal { ...message } onClose={ onClose } />

        case 'resumeProgress':

            return <ResumeProgressModal { ...message } onClose={ onClose } />

        case 'simple':

            return <SimpleModal { ...message } onClose={ onClose } />

        case 'wallet':

            return <WalletModal { ...message } onClose={ onClose } />

        default:

            return ''

    }

}

class GenericModal extends React.Component {

    render() {

        return this.props.open && (
            <Modal open={ this.props.open } size={ this.props.message.size || 'mini' }>
                <HandleModal { ...this.props } onClose={ this.props.closeGenericModal } />
            </Modal>
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.genericModal);

export default connect(mapStateToProps, { closeGenericModal })(GenericModal);