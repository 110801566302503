
import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const createAnswer = async payload => await AxiosService.post(Config.server.api.questionApi , { ...payload }, !!window.localStorage.getItem('jwt'));
const getCategories = async () => await AxiosService.get(Config.server.api.questionApi + 'category' , {}, !!window.localStorage.getItem('jwt'));

export default {
    createAnswer,
    getCategories
}