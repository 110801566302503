import { css } from 'styled-components/macro';

const sectionPadding = css`
    padding: 2rem 2.2rem 1rem 2.2rem;
    @media (max-width: 350px) {
        padding: 2rem 1.2rem 1rem 1.2rem;
    }
    @media (min-width: 800px) {
        padding: 4rem 3rem 1.8rem 3rem;
    }
`;

export {
    sectionPadding
}