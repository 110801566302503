import React, { Component } from 'react'
import { LandingHeader } from '../../components/header'
import { ButtonWrapper, CtaLogin, CtaRegister, InnerSection, LandingWrap, LegacyPoint, LegacyPointIcon, LegacyPoints, Section, SectionBackground, SectionGrid, SectionGridItemImage, SectionGridItemText, SectionSubHeaderCta, SectionSubHeaderLogo } from '../../styled'
import OButton from '../../../../../../styled/components/button';

import PracticesSectionImage from '../../../../../../assets/img/main_landing/practicas.png';
import PracticesAudioImage from '../../../../../../assets/img/main_landing/practiceAudio.svg';
import PracticesFollowingImage from '../../../../../../assets/img/main_landing/acompanamiento.png';
import PracticesTimeImage from '../../../../../../assets/img/main_landing/tiempo_practice.svg';

import PracticeImageCustom from '../../../../../../assets/img/main_landing/Practica-personalizada.png';

import Footer from '../../../../../../components/footer';
import { Icon } from 'semantic-ui-react';
import { ExperienceGrid, ExperienceItem, ExperienceStep } from './styled';
import { motion } from 'framer-motion';
import { CtaMarkets } from '../../components/ctaMarket';
import { motionSubtitle, motionTitle, transition, motionButton } from '../../../../../../../utils/framerAnimations';


export default class PracticesLanding extends Component {
    
    render() {
        return (
            <>
                <LandingWrap>
                    <LandingHeader active="practices" />
                    <Section>
                        <InnerSection sectionSubHeader>
                            <SectionSubHeaderLogo onClick={() => this.props.history.push("/")} />
                            <SectionSubHeaderCta>
                                    <CtaLogin to="/auth/login">Inicia sesión</CtaLogin>
                                    <CtaRegister to="/auth/register">Prueba gratis</CtaRegister>
                            </SectionSubHeaderCta>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid>
                                <SectionGridItemImage >
                                    <motion.img src={PracticesSectionImage} alt="" initial={{ y: 20, opacity: 0 }} animate = {{y: 0,opacity: 1, transition: transition}}/>
                                </SectionGridItemImage>
                                <SectionGridItemText style={{textAlign:'left'}}>
                                    <motion.div initial="initial" animate="animate" exit='exit' >
                                        <motion.h1 variants={motionTitle} className="landing-heading-1">Prácticas Profesionales</motion.h1>
                                        <motion.p variants={motionSubtitle} className="landing-heading-2 regular">100% óptimas y personalizadas para ti.</motion.p>
                                    </motion.div>
                                    <motion.div initial="initial" animate="animate" exit='exit' variants={motionButton}>
                                        <ButtonWrapper reverse>
                                            <OButton upper type="button" color="#FFF" primary size="large" onClick={() => this.props.history.push('/auth/login')}>
                                                <span className="toregular">EMPIEZA AHORA</span>
                                            </OButton>
                                        </ButtonWrapper>
                                    </motion.div>
                                </SectionGridItemText>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection SectionGrid>
                            <SectionGrid reverse>
                                <SectionGridItemText style={{textAlign:'left'}} reverse basis={50}>
                                    <p className="landing-heading-2">PRÁCTICA PERSONALIZADA</p>
                                    <p className="heading-2 regular">¿Qué secuencia de práctica deberías practicar hoy?</p>
                                    <p className="paragraph regular">Si cada persona tiene sus propias características, necesidades y objetivos personales, así como condiciones físicas que pueden variar según el día, entonces cada persona merece tener una práctica de Yoga a su Medida.</p>
                                    <p className="paragraph regular">¿Qué posturas practicar? ¿Cómo deben estar organizadas? ¿Cuánto tiempo permanecer en cada asana? ¿Cómo balancear los tiempos de permanencia? ¿Qué posturas debo evitar según mi estado? Etc.</p>
                                    <p className="paragraph regular">Las respuestas a todas estas preguntas están respondidas en tu Práctica Personalizada en Yogabot</p>
                                    <ButtonWrapper reverse>
                                        <OButton upper type="button" color="#FFF" primary size="large" onClick={() => this.props.history.push('/auth/login')}>
                                            <span className="toregular">EMPIEZA AHORA</span>
                                        </OButton>
                                    </ButtonWrapper>
                                </SectionGridItemText>
                                <SectionGridItemImage fullImage>
                                    <img src={PracticeImageCustom} alt="" />
                                </SectionGridItemImage>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection>
                            <SectionBackground>
                                <p className="landing-heading-2 white">Experimenta una secuencia de Yoga PARA TI</p>
                                <p className="heading-1 white regular">¿Qué secuencia de práctica deberías practicar hoy?</p>
                                <ExperienceGrid>
                                    <ExperienceItem>
                                        <ExperienceStep>01</ExperienceStep>
                                        <p className="paragraph">COMPLETA TU MATRIZ BIOMÉTRICA</p>
                                        <p className="paragraph regular">Una vez completado el diagnóstico corporal y la Matriz Biométrica está actualizada (ver apartado Matriz Biométrica), los algoritmos del motor de reglas de Yogabot generan una práctica de Yoga a medida del usuario.</p> 
                                    </ExperienceItem>
                                    <ExperienceItem>
                                        <ExperienceStep>02</ExperienceStep>
                                        <p className="paragraph">ES EL TURNO DE YOGABOT</p>
                                        <p className="paragraph regular">El <strong>motor de reglas de Yogabot</strong> está basado en el <strong>sistema de secuenciación</strong> de asanas de la <strong>Pedagogía NEXOYOGA®</strong>. Es decir, es una automatización que elige los criterios de secuenciación más adecuados y ordena las asanas produciendo la secuencia óptima para el usuario, según su Matriz Biométrica.</p> 
                                    </ExperienceItem>
                                </ExperienceGrid>
                            </SectionBackground>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <p className="landing-heading-2">¿Cuánto tiempo tienes para practicar?</p>
                            <SectionGrid reverse>
                                <SectionGridItemText style={{textAlign:'left'}} reverse>
                                    <p className="paragraph regular">Yogabot no solo diseña una práctica de yoga exclusiva para ti, sino que la ajusta al tiempo real del que dispones, en cada momento.</p>
                                    <p className="paragraph regular">Yogabot prioriza los elementos de la secuencia que pueden tener un mayor impacto en la práctica, manteniendo siempre el equilibrio de movimientos y ejercicios requerido por la matriz biométrica. </p>
                                    <p className="paragraph regular">Puedes disfrutar de una práctica personalizada en tres formatos.</p>
                                    <LegacyPoints>
                                        <LegacyPoint>
                                            <LegacyPointIcon>
                                                <Icon name='check' />
                                            </LegacyPointIcon>
                                            <p className="paragraph regular">Secuencia Corta: Entre 20 a 25 minutos.</p>
                                        </LegacyPoint>
                                        <LegacyPoint>
                                            <LegacyPointIcon>
                                                <Icon name='check' />
                                            </LegacyPointIcon>
                                            <p className="paragraph regular">Secuencia Media: Entre  25 a 45 minutos.</p>
                                        </LegacyPoint>
                                        <LegacyPoint>
                                            <LegacyPointIcon>
                                                <Icon name='check' />
                                            </LegacyPointIcon>
                                            <p className="paragraph regular">Secuencia Larga: Entre 45 minutos.</p>
                                        </LegacyPoint>
                                    </LegacyPoints>
                                    <p className="paragraph regular">NOTA: los tiempos de las secuencias personalizadas son estimados y pueden variar ligeramente para cada usuario…</p>
                                </SectionGridItemText>
                                <SectionGridItemImage reverse >
                                    <img src={PracticesTimeImage} alt="" />
                                </SectionGridItemImage>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid >
                                <SectionGridItemImage fullImage>
                                    <img src={PracticesFollowingImage} alt="" />
                                </SectionGridItemImage>
                                <SectionGridItemText style={{textAlign:'left'}} basis={40}>
                                    <h1 className="landing-heading-2">Acompañamiento de Práctica</h1>
                                    <p className="paragraph regular">Una de las funcionalidades más valoradas por los usuarios de Yogabot, es el Sistema de Acompañamiento de Práctica.</p>
                                    <p className="paragraph regular">Yogabot <strong>reproduce tu secuencia personal</strong>, respetando la duración, el orden y los tiempos de permanencia, con videos en <strong>alta calidad y rigor técnico</strong>, grabados por el profesor Jose Antonio Cao. No son videos hechos por animación, ni grabados por un modelo.</p>
                                    <p className="paragraph regular">El acompañamiento en VIDEO está disponible solo para las cuentas PREMIUM. Pero las cuentas LITE (gratuitas) también disfrutan del Sistema de Acompañamiento, pero en IMAGEN (fotos).</p>
                                </SectionGridItemText>
                                
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid reverse>
                                <SectionGridItemText style={{textAlign:'left'}} reverse basis={40}>
                                    <h1 className="landing-heading-2">Audios durante la práctica </h1>
                                    <p className="paragraph regular">Durante el acompañamiento de práctica, puedes elegir escuchar el nombre de las posiciones de yoga en español o en sánscrito. Por ejemplo, postura del perro cabeza abajo en español, o adhomukha svanasana en sánscrito.   </p>
                                </SectionGridItemText>
                                <SectionGridItemImage fullImage reverse >
                                    <img src={PracticesAudioImage} alt="" />
                                </SectionGridItemImage>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section noPadding>
                        <SectionBackground light>
                            <InnerSection>
                                <p className="landing-heading-2">PRÁCTICAS ALTERNATIVAS</p>
                                <LegacyPoints>
                                    <LegacyPoint>
                                        <LegacyPointIcon>
                                            <Icon name='check' />
                                        </LegacyPointIcon>
                                        <p className="paragraph regular">Todas las secuencias de práctica que se publican en Yogabot, están elaboradas siguiendo los criterios pedagógicos de NEXOYOGA. </p>
                                    </LegacyPoint>
                                    <LegacyPoint>
                                        <LegacyPointIcon>
                                            <Icon name='check' />
                                        </LegacyPointIcon>
                                        <p className="paragraph regular">Secuencias terapéuticas. Estas secuencias están diseñadas para practicantes de yoga con limitaciones derivadas de lesiones o dolencias diagnosticadas y ya tratadas y rehabilitadas por el sistema sanitario de su país o localidad. No están pensadas para molestias puntuales, como es el caso de las secuencias restaurativas, sino para quienes padecen molestias más continuadas.</p>
                                    </LegacyPoint>
                                    <LegacyPoint>
                                        <LegacyPointIcon>
                                            <Icon name='check' />
                                        </LegacyPointIcon>
                                        <p className="paragraph regular">Secuencias Restaurativas. Son prácticas recomendadas para situaciones puntuales, como la menstruación, el agotamiento, las migrañas tensionales o simples dolores de cabeza puntuales, dolores ligeros de lumbares o del cuello, entre otros. Estas prácticas son complementarias con las secuencias personalizadas de tu matriz biométrica para aquellos días en los que tu cuerpo necesite un tratamiento especial.</p>
                                    </LegacyPoint>
                                    <LegacyPoint>
                                        <LegacyPointIcon>
                                            <Icon name='check' />
                                        </LegacyPointIcon>
                                        <p className="paragraph regular">Secuencias Potenciación. Estas secuencias están diseñadas para potenciar zonas del cuerpo, o cadenas musculares específicas. Para tonificar o flexibilizar partes del cuerpo que necesitas especialmente. Las secuencias personalizadas de tu matriz biométrica siempre apuntan hacia el máximo equilibrio corporal; pero puedes complementar esto con tus objetivos concretos eligiendo alguna de las secuencias de este apartado.</p>
                                    </LegacyPoint>
                                    <LegacyPoint>
                                        <LegacyPointIcon>
                                            <Icon name='check' />
                                        </LegacyPointIcon>
                                        <p className="paragraph regular">Programas especializados (Asignación personalizada). Este apartado contiene grupos de secuencias orientadas a conseguir objetivos más complejos o más a medio plazo. No son secuencias simples sino programas que te acompañarán mientras vas desarrollando los hitos e indicadores en orden hasta llegar a completar tu meta.</p>
                                    </LegacyPoint>
                                </LegacyPoints>
                            </InnerSection>
                        </SectionBackground>
                    </Section>

                    <CtaMarkets />
                    <Footer {...this.props} type="branded" hasExtraFooter={true}/>
               </LandingWrap>
            </>
        )
    }
}
