import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import GradientNav from '../../components/navbar/gradient';
import SubMenu from '../../components/navbar/submenu';
import ExtraTopBar from '../../components/navbar/extraTopBar';
import QuestionModel from '../../../data/models/question/question';
import FaqModel from '../../../data/models/faq/faq';
import { openLoader, closeLoader } from '../../../redux-store/loader';
import arrowBackPink from '../../assets/img/arrow-pink.svg'; 
import TopBar from '../../components/navbar/topbar';
import Footer from '../../components/footer';

import I18n from '../../../i18n';
import './faq.scss';

class Faq extends React.Component {

    constructor(props){
        super(props);

        this.state = {

            categories: []
        }
    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        let categories = [];

        try {

            this.props.openLoader();
            const categoriesResponse = await QuestionModel.getCategories();
            const faqsResponse = await FaqModel.getFaqs();

            if (categoriesResponse.status === 200 && faqsResponse.status === 200) {

                _.each(categoriesResponse.data, category => {

                    if (_.findIndex(faqsResponse.data, faq => faq.category === category._id ) > -1) {

                        categories.push(category);
                    }

                });

                this.setState({categories});
                
            }
            
        } catch (error) {

            
        } finally {

            this.props.closeLoader();

        }

    }

    onSelectCategory = categoryId => this.props.history.push(`/faq/list/${categoryId}`);

    onBack = () => {

        if (window.localStorage.getItem('jwt')) {

            this.props.history.push('/home');

        } else {

            this.props.history.push('/');
        }

    }

    gotoLogin = () => {

        this.props.history.push(`/auth/login`);
        
    };

    render() {

        return (
            <React.Fragment>
              { window.localStorage.getItem('jwt') && <GradientNav active="" {...this.props} /> }
              {!localStorage.getItem('jwt') && <ExtraTopBar onClick={ this.gotoLogin } className="register" hasButton={true}/>}
              <div className={"faq-wrapper " + (!window.localStorage.getItem('jwt') ? 'hasExtraTopBar' : '')}>
                  {window.localStorage.getItem('jwt') && <TopBar {...this.props} callback={()=>this.onBack()} text={I18n.t('faq.faq')} />}
                  <div className="inner">
                      <div className="center-content">
                        <h1>{I18n.t('faq.doubtsGeneral')}</h1>
                        <div className="go-back-desktop no-padding" onClick={this.onBack}>
                            <span className="back" ><img src={arrowBackPink} alt="" /></span><span className="back-text">{I18n.t('actions.back')}</span>
                        </div>
                        {!window.localStorage.getItem('jwt') && <div className="go-back-mobile no-padding" onClick={this.onBack}>
                            <span className="back" ><img src={arrowBackPink} alt="" /></span>
                        </div>}
                        <p>{I18n.t('faq.doubtsGeneralText1')}</p>
                        <p dangerouslySetInnerHTML={{__html: I18n.t('faq.doubtsGeneralText2')}}></p>
                        <p>{I18n.t('faq.doubtsGeneralText3')}</p>
                        </div>
                        <div className="categories-box">
                            { _.get(this.state, 'categories', []).map(category => (
                                <div className="category-row" key={category._id} onClick={() => this.onSelectCategory(category._id)}>
                                    <div className="title">{category.label}</div>
                                    <div className="action-icon">
                                        <i className="f-icon-right-open-1"></i>
                                    </div>
                                </div>
                            ))}
                        </div>
                  </div>
                    <Footer { ...this.props } type="branded"/>
              </div>
              { window.localStorage.getItem('jwt') && <SubMenu active="" {...this.props}/> }

            </React.Fragment>
        );

    }

}

export default connect(null, { openLoader, closeLoader} )(Faq);
