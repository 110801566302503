import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { hotjar } from 'react-hotjar';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { getFirebaseObject } from './firebase';

import Config from '../config';

import history from '../history';

const isDevelopEnviroment = Config.server.api.securityApi.includes('dev') || Config.server.api.securityApi.includes('localhost');

const firebase = getFirebaseObject();
const analytics = getAnalytics(firebase);

const initializeTracking = () => {

    if (!isDevelopEnviroment) {

        !window.cordova && hotjar.initialize(1915030, 6);
        ReactGA.initialize('UA-160604388-1');
        ReactPixel.init('2556601417913546', {}, { debug: process.env.NODE_ENV === 'development', autoConfig: false });
        ReactPixel.pageView();

        history.listen(location => {

            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
            ReactPixel.pageView();

            setFirebaseEvent('screen_view', {
                firebase_screen: location.pathname
            });

        });

    }

}

const googleAnalyticEventWhitelist = {
    Android: ['login', 'login_google', 'login_facebook', 'sign_up', 'sign_up_google', 'sign_up_facebook', 'emailValidated', 'purchase', 'completeBiometricMatrix', 'practiceSequenceStart', 'practiceSequenceEnd', 'completeProfileForm'],
    iOS: ['login', 'login_google', 'login_facebook', 'sign_up', 'sign_up_google', 'sign_up_facebook', 'emailValidated', 'purchase', 'completeBiometricMatrix', 'practiceSequenceStart', 'practiceSequenceEnd', 'completeProfileForm'],
    default: ['login', 'login_google', 'login_facebook', 'sign_up', 'sign_up_google', 'sign_up_facebook', 'emailValidated', 'purchase', 'completeBiometricMatrix', 'practiceSequenceStart', 'practiceSequenceEnd', 'completeProfileForm']
};

const facebookEventWhitelist = {
    Android: ['login', 'login_google', 'login_facebook', 'sign_up', 'sign_up_google', 'sign_up_facebook', 'emailValidated', 'purchase', 'completeBiometricMatrix', 'practiceSequenceStart', 'practiceSequenceEnd', 'completeProfileForm'],
    iOS: ['login', 'login_google', 'login_facebook', 'sign_up', 'sign_up_google', 'sign_up_facebook', 'emailValidated', 'purchase', 'completeBiometricMatrix', 'practiceSequenceStart', 'practiceSequenceEnd', 'completeProfileForm'],
    default: ['login', 'login_google', 'login_facebook', 'sign_up', 'sign_up_google', 'sign_up_facebook', 'emailValidated', 'purchase', 'completeBiometricMatrix', 'practiceSequenceStart', 'practiceSequenceEnd', 'completeProfileForm']
};

const firebaseEventWhitelist = {
    Android: ['login', 'login_google', 'login_facebook', 'sign_up', 'sign_up_google', 'sign_up_facebook', 'emailValidated', 'purchase', 'completeBiometricMatrix', 'practiceSequenceStart', 'practiceSequenceEnd', 'completeProfileForm'],
    iOS: ['login', 'login_google', 'login_facebook', 'sign_up', 'sign_up_google', 'sign_up_facebook', 'emailValidated', 'purchase', 'completeBiometricMatrix', 'practiceSequenceStart', 'practiceSequenceEnd', 'completeProfileForm'],
    default: ['login', 'login_google', 'login_facebook', 'sign_up', 'sign_up_google', 'sign_up_facebook', 'emailValidated', 'purchase', 'completeBiometricMatrix', 'practiceSequenceStart', 'practiceSequenceEnd', 'completeProfileForm']
};

const setGAevent = (category, action) => ReactGA.event({ category, action });

const setFBevent = (action, extraData) => {

    if (window.cordova) {

        if (action === 'purchase') {

            window.facebookConnectPlugin.logPurchase(extraData.value, extraData.currency, extraData);

        } else {

            window.facebookConnectPlugin.logEvent(action, extraData);

        }

    } else {

        ReactPixel.trackCustom(action, extraData);

    }

}

const setFirebaseEvent = (action, extraData) => {

    if (window.cordova) {
        
        if (window.FirebasePlugin) {

            window.FirebasePlugin.logEvent(action, extraData);

        }

    } else {

        logEvent(analytics, action, extraData);

    }

}

const registerEvent = (category, action, extraData) => {

    try {

        const platform = window.cordova ? window.device.platform : 'default';

        if (!isDevelopEnviroment) {

            googleAnalyticEventWhitelist[platform].includes(action) && setGAevent(category, action);
            facebookEventWhitelist[platform].includes(action) && setFBevent(action);
            firebaseEventWhitelist[platform].includes(action) && setFirebaseEvent(action, extraData);

        }

    } catch (error) {

        console.error('Error while tracking', error);

    }

};

export const TrackingService = {
    initializeTracking,
    registerEvent,
    setGAevent,
    setFBevent
};