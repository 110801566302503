import React from 'react'
import OButton from '../../../../../../styled/components/button'
import { GridPrice, CardPrice, CardPriceHeader, CardPriceContent, CardPriceButtons, SwipperContainer, CardPriceFeature, CardPriceIcon, TopSeparator } from './styled';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Icon } from 'semantic-ui-react';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);


const CardPricesLite = (props) => (
    <CardPrice>
        <CardPriceHeader>
            <p className="landing-heading-2">LITE</p>
            <p className="price prices-text cbrand">Gratis</p>
        </CardPriceHeader>
        <CardPriceContent>
            <p className="paragraph price-division">DIAGNÓSTICO</p>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Diagnóstico corporal por rangos de tono y movilidad.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Actualizar diagnóstico y matriz biométrica. <strong>Cada 21 días</strong>.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular dark-grey-14">Diagnóstico por análisis automático de imágen. Mayor precisión.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular dark-grey-14">Histórico de cambio de la Matriz Biométrica. Próximamente</p>
            </CardPriceFeature>
            <TopSeparator/>
            <p className="paragraph price-division">PRÁCTICA</p>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Configurar audio con nombre de cada asana (español o sánscrito)</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Configurar el nivel de práctica (iniciación, principiante, avanzado)</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Acompañamiento <strong>estático</strong>. Con <strong>fotos</strong> de las asanas.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Acceso a prácticas alternativas por categoría, especialidad y objetivoS <strong>(LIMITADO)</strong></p>
            </CardPriceFeature>

            <CardPriceFeature>
                <CardPriceIcon><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular dark-grey-14">Acompañamiento dinámico. Con videos de las asanas (transiciones y permanencia)</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular dark-grey-14">Descargar PDF con secuencia personalizada. Próximamente.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular dark-grey-14">Acompañamiento de práctica. Correcciones en tiempo real, por voz. Próximamente.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular dark-grey-14">Diseñar y probar tus propias secuencias de prácticas. Próximamente</p>
            </CardPriceFeature>
            <TopSeparator/>

            <p className="paragraph price-division">ESTUDIO Y CONSULTAS</p>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Compra de videos técnicos de Asana (perfeccionamiento). Precio regular: $4,99</p>
            </CardPriceFeature>

            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular dark-grey-14">Se libera un video técnico de perfeccionamiento cada mes. Con la cuota mensual</p>
            </CardPriceFeature>

            <CardPriceButtons>
                <OButton upper type="button" color="#FFF" primary size="large" onClick={() => props.history.push("/auth/register")}>
                    <span className="toregular">SUSCRIBIRSE</span>
                </OButton>
            </CardPriceButtons>
        </CardPriceContent>
    </CardPrice>

);

const CardPricesPremium = (props) => (
    <CardPrice>
        <CardPriceHeader>
            <p className="landing-heading-2">PREMIUM</p>
            <p className="price prices-text cbrand">9.99€</p>
        </CardPriceHeader>
        <CardPriceContent>
            <p className="paragraph price-division">DIAGNÓSTICO</p>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Diagnóstico corporal por rangos de tono y movilidad.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Actualizar diagnóstico y matriz biométrica. <strong>Cada 21 días</strong>.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Diagnóstico por análisis automático de imágen. Mayor precisión.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Histórico de cambio de la Matriz Biométrica. Próximamente</p>
            </CardPriceFeature>
            <TopSeparator/>
            <p className="paragraph price-division">PRÁCTICA</p>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Configurar audio con nombre de cada asana (español o sánscrito)</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Configurar el nivel de práctica (iniciación, principiante, avanzado)</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Acompañamiento <strong>estático</strong>. Con <strong>fotos</strong> de las asanas.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Acceso a prácticas alternativas por categoría, especialidad y objetivoS <strong>(LIMITADO)</strong></p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Acompañamiento dinámico. Con videos de las asanas (transiciones y permanencia)</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Descargar PDF con secuencia personalizada. Próximamente.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Acompañamiento de práctica. Correcciones en tiempo real, por voz. Próximamente.</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Diseñar y probar tus propias secuencias de prácticas. Próximamente</p>
            </CardPriceFeature>
            <TopSeparator/>
            <p className="paragraph price-division">ESTUDIO Y CONSULTAS</p>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Compra de videos técnicos de Asana (perfeccionamiento). Precio regular: $4,99</p>
            </CardPriceFeature>
            <CardPriceFeature>
                <CardPriceIcon active><Icon name="check" /></CardPriceIcon>
                <p className="labeling regular">Se libera un video técnico de perfeccionamiento cada mes. Con la cuota mensual</p>
            </CardPriceFeature>
            <CardPriceButtons>
                <OButton upper type="button" color="#FFF" primary size="large" onClick={() => props.history.push("/auth/register")} >
                    <span className="toregular">SUSCRIBIRSE</span>
                </OButton>
            </CardPriceButtons>
        </CardPriceContent>
    </CardPrice>

);

export function SectionPrices(props) {
    
    return (
        <>
            <SwipperContainer>
                <Swiper
                    spaceBetween={1}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}>
                    <SwiperSlide>
                        <CardPricesLite {...props} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <CardPricesPremium {...props} />
                    </SwiperSlide>
                </Swiper>
            </SwipperContainer>
            <GridPrice>
                <CardPricesLite {...props} />
                <CardPricesPremium {...props} />
            </GridPrice>
        </>
    )
}
