import _ from 'lodash';
import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const getSequence = async sequenceId => _.get(await AxiosService.get(Config.server.api.sessionApi + `sequence/${ sequenceId }`, true), 'data', {});
const getSequenceWithTranslation = async payload => await AxiosService.get(Config.server.api.sessionApi + `sequence/${payload.sequence}`, true);
const getSequences = async () => await AxiosService.get(Config.server.api.sessionApi + `sequence/`, true);
const getUserSession = async () => await AxiosService.get(Config.server.api.sessionApi + 'user', true);
const startSession = async payload => await AxiosService.post(Config.server.api.sessionApi + `user/start/${payload.sequence}`, {}, true);
const onAsanaFinish = async payload => await AxiosService.put(Config.server.api.sessionApi + `user/${payload.userSessionId}/progress/${payload.timeElapsed} `, {}, true);
const userSessionCompleted = async payload => await AxiosService.post(Config.server.api.sessionApi + `user/${payload.userSessionId}/completed `, {}, true);
const getLastWeekTrainingSeconds = async () => await AxiosService.get(Config.server.api.sessionApi + `user/lastWeekTrainingSeconds`, {}, true);
const getSequenceCategories = async () => await AxiosService.get(Config.server.api.sessionApi + 'sequenceCategories', {}, true);

const getProgram = async programId => await AxiosService.get(Config.server.api.sessionApi + `program/${programId}`, {}, true);
const getPrograms = async () => await AxiosService.get(Config.server.api.sessionApi + `program`, {}, true);
const getProgramSequences = async programId => await AxiosService.get(Config.server.api.sessionApi + `program/${programId}/sequence`, {}, true);
const getCustomSessionStats = async () => await AxiosService.get(Config.server.api.sessionApi + `user/customSessionStats`, {}, true);

const getUserAssignments = async () => await AxiosService.get(Config.server.api.sessionApi + `user/assignment`, {}, true);
const getUserSequences = async () => _.get(await AxiosService.get(Config.server.api.sessionApi + `user/sequence`, {}, true), 'data', []);

export default {
    getUserAssignments,
    getUserSequences,
    getSequence,
    getSequenceWithTranslation,
    getSequences,
    getUserSession,
    startSession,
    onAsanaFinish,
    userSessionCompleted,
    getLastWeekTrainingSeconds,
    getSequenceCategories,
    getPrograms,
    getProgram,
    getProgramSequences,
    getCustomSessionStats
}