//libraries & libraries components
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import I18n from '../../../../i18n';
import { Trans } from 'react-i18next';

//components
import GradientNav from '../../../components/navbar/gradient';
import SubMenu from '../../../components/navbar/submenu';
import Footer from '../../../components/footer';
import Notification from '../../../components/notificaction';
import TopBar from '../../../components/navbar/topbar';
import FixedBanner from '../../../components/fixedBanner';
import ChallengeBackground from '../../../components/diagnosis/components/challenges/header';
import Grid from '../../../components/grid';

//styled components
import { FrameBlock, Container, InnerContainer, FrameBackGround } from './sharedStyles';


class DiagnosisChallenge extends React.Component {

    componentDidMount() {

        if ( _.get(this.props, `challenges[${this.props.match.params.challengeId}].locked`, true) ) {

            this.props.history.push('/home');
            
        }

    }

    componentDidUpdate() {

        if ( _.get(this.props, `challenges[${this.props.match.params.challengeId}].locked`, true) ) {

            this.props.history.push('/home');
            
        }

    }

    selectAsana = asanaId => {

        this.props.history.push(`/diagnosis/challenge/${this.props.match.params.challengeId}/asana/${asanaId}`);

    }

    render() {

        const { challenges, match, history} = this.props;

        return _.get(challenges,`[${match.params.challengeId}]`, false) ? (
            <React.Fragment>
                <GradientNav active="diagnosis" {...this.props}/>
                <Container>
                    <TopBar callback={() => history.push('/home')} {...this.props} text={challenges[match.params.challengeId].title}/>
                    <FixedBanner />
                    <InnerContainer>
                        <FrameBackGround>
                            <ChallengeBackground challenges={challenges} currentIndex={match.params.challengeId}/>
                        </FrameBackGround>
                        <FrameBlock>
                            <p className="heading-2">{I18n.t('diagnosis.target')}</p>
                            <p className="paragraph regular">{challenges[match.params.challengeId].targetText}</p>
                            <p className="paragraph regular">
                            <Trans i18nKey="diagnosis.extraMessage">
                                <b></b>&#174;<b></b><i></i><b></b><a className="brand-secondary" target="_blank" rel="noopener noreferrer" href="https://academianexoyoga.com/p/yoga-a-tu-medida/"></a>
                            </Trans>
                        </p>
                        </FrameBlock>
                        <FrameBlock>
                            <Grid mainAction={(index)=>this.selectAsana(index)} type="asana" {...this.props}/>
                        </FrameBlock>
                    </InnerContainer>
                    <Footer {...this.props} type="branded"/>
                </Container>
                <SubMenu active="diagnosis" {...this.props}/>
                <Notification type="fixered" />
            </React.Fragment>
        ) : '';

    }

}

const mapStateToProps = state => {

    return {
        
        challenges: _.get(state,'challenges.challenges', [])
        
    };
};

export default connect(mapStateToProps, {})(DiagnosisChallenge);
