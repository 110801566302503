import styled from 'styled-components/macro';

const Container = styled.div`
    min-height: 100%;
    display:flex;
    flex-direction:column;
`;

const InnerContainer = styled.div`
    width:100%;
    max-width:1440px;
    margin:0 auto;
    flex-grow:1;
    flex-shrink:0;
    display:flex;
    flex-direction:column;
`;

const Frame = styled.div`

    display:flex;
    align-items:center;
    justify-content:center;
`;

export {
    Container, 
    InnerContainer,
    Frame
}