import { Form, Dropdown } from 'semantic-ui-react';

const Select = props => {

    return (
        <Form.Field className={ props.fieldClasses }>
            <label>{ props.label }</label>
            <Dropdown
                value={ props.input.value }
                placeholder={ props.placeholder }
                selection
                search={ props.search || false }
                options={ props.options }
                className='fluid'
                onChange={ (e, { value }) => props.input.onChange(value) }
            />
        </Form.Field>
    );

};

export default Select;