import styled from 'styled-components/macro';

const Container = styled.div`
    min-height: 100%;

`;

const InnerContainer = styled.div`
    width:100%;
    max-width:1440px;
    margin:0 auto;
`;

const Frame = styled.div`

    padding:0 32px;
    @media (max-width: 420px) {
        padding:0 20px;
    }
`;
export {
    Container, 
    InnerContainer,
    Frame
}