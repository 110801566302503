// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

// Components
import GradientNav from '../../../components/navbar/gradient';
import SubMenu from '../../../components/navbar/submenu';
import Footer from '../../../components/footer';
import TopBar from '../../../components/navbar/topbar';

// Styled components
import OButton from '../../../styled/components/button';

// Redux
import { openGenericModal, closeGenericModal } from '../../../../redux-store/genericModal';
import { getRole } from '../../../../redux-store/role';
import { getCustomSessionStats } from '../../../../redux-store/customSessionStats';

// Models
import SecurityModel from '../../../../data/models/security/security';

// Utils
import { toHHMMSS } from '../../.././../utils/formatTime';
import { TrackingService } from '../../../../utils/TrackingService';

// Config
import Config from '../../../../config';

// Locales
import I18n from '../../../../i18n';

// Styles
import './practiceResult.scss';

// Assets
import CorrectImage from '../../../assets/img/correct.svg';

class ResultPractice extends React.Component {

    async componentDidMount() {

        TrackingService.registerEvent('Practice', 'practiceSequenceEnd');

        try {

            await this.props.getCustomSessionStats();

        } catch (error) {

        }

    }

    componentWillUnmount(){

        this.props.closeGenericModal();

    }

    openPopup = () => {

        const { openGenericModal, closeGenericModal, sessionTime, bioMetricMatrix, customSessionStats } = this.props;

        const message = I18n.t('home.stats.messages.five.messagePopup', {
            totalPracticeWeekTime: toHHMMSS(sessionTime || 0),
            avgProgress: (bioMetricMatrix.progress || 0) + '%',
            weekPracticeCount: _.round(customSessionStats.lastWeekUserAlternativeSessionsCount / 2) + customSessionStats.lastWeekUserCustomSessionsCount
        });

        openGenericModal({
            type: 'simple',
            size: "tiny",
            title:{
                text: I18n.t('messages.info'),
                classes: ['heading-2']
            },
            description:{
                text: message,
                classes: ['labeling', 'regular']
            },
            buttons:[
                {
                    text: I18n.t('actions.understood'),
                    callback: ()=> {

                        closeGenericModal();

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true
                    }
                }

            ]
        });

    }

    completeGrowChallenges = async () => {

        try {

            await SecurityModel.updateGrowChallengeStatus('finished');
            await this.props.getRole();

        } catch (error) {

        } finally {

            this.props.history.push('/home');

        }

    }

    onContinue = () => {

        const { role, userSession, bioMetricMatrix, history, customSessionStats} = this.props;
        const { completeGrowChallenges } = this;

        if (_.get(role, 'attributes.growChallenge', '')  === 'explorer') {

            if (customSessionStats.userCustomSessionsCount >= Config.growChallenge.customPractices) {

                const completedGrowChallenges = [];
                //paso 3 terminado por defecto
                completedGrowChallenges[2] = 3;
                //paso 1 completado?
                (userSession.name && userSession.surname) ? completedGrowChallenges[0] = 1 : completedGrowChallenges[1] = 0;
                //paso 2 completado?
                (bioMetricMatrix.progress || 0) >= 100 ? completedGrowChallenges[1] = 2 : completedGrowChallenges[1] = 0;

                const nextStepToCompleteIndex = completedGrowChallenges.indexOf(0);

                if (nextStepToCompleteIndex > -1) {

                    history.push(`/practiceList`);

                } else {

                    //Los retos están completados
                    completeGrowChallenges();

                }

            }

         } else {

            history.push('/practiceList');

         }

    }

    render() {

        return (
            <React.Fragment>
                <GradientNav active="practice" {...this.props} />
                <div className="wrapper-practice-result">
                    <TopBar {...this.props} callback={()=> this.props.history.push('/practiceList') } text={I18n.t('practice.practiceCompleted')}/>
                    <div className="inner">
                        <div>
                           <img src={CorrectImage} alt="" />
                           <p className="paragraph regular">{I18n.t('practice.result.title')} <i onClick={this.openPopup} className="f-icon-info-circled"></i></p>
                           <p className="paragraph regular">{I18n.t('practice.result.subTitle')}</p>
                           <OButton type="button" upper color="#fff" onClick={ this.onContinue } primary ><span>{I18n.t('actions.continue')}</span></OButton>
                        </div>
                    </div>
                    <Footer {...this.props} type="branded"/>
                </div>
                <SubMenu active="practice" {...this.props} />
            </React.Fragment>
        );

    }

}

const mapStateToProps = state => {

    return {
        sessionTime: _.get(state,'session.time', 0),
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        customSessionStats: _.get(state, 'customSessionStats', {}),
        role: _.cloneDeep(_.get(state,'role', {})),

    };
};

export default connect(mapStateToProps, { getCustomSessionStats, getRole, openGenericModal, closeGenericModal })(ResultPractice);
