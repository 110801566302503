import styled from 'styled-components/macro';


const FrameBackGround = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url(${props => props.src});
    background-size:cover;
    background-position:center;
    &:after {
        position: absolute;
        content: " ";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-color: rgba(var(--brand-secondary-rgb), 0.7);
      }
`;

const Content = styled.div`
        position:absolute;
        z-index:1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding:10px;
`;

export {
    FrameBackGround,
    Content
}