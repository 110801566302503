import React from 'react';
import { connect } from 'react-redux';
import SessionModel from '../../../../../data/models/session/session';
import { openLoader, closeLoader } from '../../../../../redux-store/loader';
import TopBar from '../../../../components/navbar/topbar';
import I18n from '../../../../../i18n';
import Footer from '../../../../components/footer';
import './practicePreview.scss';
import MenuPractice from '../../../../components/menu/practice';
import OButton from '../../../../styled/components/button';
import { Icon } from 'semantic-ui-react';

class PracticePreview extends React.Component {

    constructor(props){
        super(props);

        this.splitDecodeSequence = "";

        this.state = {

            asanas: []

        }

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        this.splitDecodeSequence = window.atob(this.props.match.params.sequence).split('/');
        this.props.openLoader();

        try {

            const response = await SessionModel.getSequenceWithTranslation({sequence: this.splitDecodeSequence[0] + '/' + this.splitDecodeSequence[1] });

            if (response.status === 200) {

                this.setState({asanas: response.data});

            }

        } catch (error) {

            console.error('getData:error', error)

        } finally {

            this.props.closeLoader();

        }

    }

    render() {

        const { asanas } = this.state;

        return (
            <React.Fragment>
                <div className="practice-preview-wrapper">
                     <TopBar {...this.props} callback={()=>this.props.history.push(`/practice/${this.props.match.params.sequence}`)} text={""} invert={false} />
                    <div className="inner">
                        <div className="pr-nav">
                            <div className="pr-item" onClick={() =>{ this.props.history.push(`/practiceDiscover/targets/${this.props.match.params.sequence}`)} }>Objetivos</div>
                            <div className="pr-item active" onClick={() =>{ this.props.history.push(`/practiceDiscover/preview/${this.props.match.params.sequence}`)} }>Tu secuencia</div>
                        </div>
                        <div className="pr-flex">
                            <div className="pr-aside">
                                <MenuPractice active="preview" {...this.props} />
                            </div>
                            <div className="pr-content">
                            <div className="i-back" onClick={()=>this.props.history.push(`/practice/${this.props.match.params.sequence}`)}><Icon name="angle left" /><span>{I18n.t('actions.back')}</span></div>
                                <div className="practice-preview-header">
                                    <p className="practice-preview-header-title">{I18n.t('practice.preview')}</p>
                                    <p className="practice-preview-header-subtitle" dangerouslySetInnerHTML={{__html: I18n.t('practice.previewSubtitle')}}></p>
                                </div>
                                <div className="preview-grid">
                                    {(asanas.routine || []).map(({ _id, image, name, duration }, index) => (
                                        <div className="item" key={_id+index}>
                                            <div className="item-counter">
                                                {index+1}
                                            </div>
                                            <div className="item-time">
                                                <span>
                                                    <i className="f-icon-icono_17"></i>
                                                </span>
                                                <span>{duration}</span>
                                            </div>
                                            <div className="item-cover">
                                                <div className="item-expanded">
                                                    <img src={image} alt={name} />
                                                </div>
                                            </div>
                                            <div className="item-footer">
                                                <div className="title">{name}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="btn-practice-wrapper">
                                    <OButton primary type="button" color="#FFF" upper onClick={()=> this.props.history.push(`/practice/${this.props.match.params.sequence}`)}>
                                        <span>{I18n.t('auth.practiceNow')}</span>
                                    </OButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer { ...this.props } type="branded"/>
                </div>
            </React.Fragment>
        );

    }

}


export default connect(null, { openLoader, closeLoader })(PracticePreview);
