import styled from 'styled-components/macro';

const Container = styled.div`
    min-height: 100%;
    display:flex;
    flex-direction:column;
`;

const InnerContainer = styled.div`
    width:100%;
    max-width:1440px;
    margin:0 auto;
    flex-grow:1;
    flex-shrink:0;
    display:flex;
    flex-direction:column;
`;

const Frame = styled.div`

    flex-grow:1;
    display:flex;
    justify-content:center;
    align-items:center;

`;

const ContentBox = styled.div`
    text-align: center;
    padding: 30px 20px;
    img {
        max-width: 100%;
        padding-bottom: 50px;
        margin: 0 auto;
    }

    @media (max-width:420px) {
        padding:20px;
    }
`;


export {
    Container, 
    InnerContainer,
    Frame,
    ContentBox
}