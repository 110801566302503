import styled from "styled-components/macro";

const FrameVideo = styled.div`
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
`;

const Video = styled.video`
    width: 100%;
    height: auto;
    object-fit: contain;
`;

const FrameOverlay = styled.div`
    position: absolute;
    background: rgba(0, 0, 0, 0.50);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100px;
        height: 100px;
        @media (max-width: 420px) {
            width: 75px;
            height: 75px;
        }
    }

`;

export {
    FrameVideo,
    Video,
    FrameOverlay
}
