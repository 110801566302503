// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, List } from 'semantic-ui-react';

// Components
import Topbar from '../../../../components/navbar/topbar';
import { NumericFormat } from '../../../../components/numericFormat/NumericFormat';
import InAppPurchaseButton from '../../../../components/payments/inAppPurchase';
import WalletCard from '../../../../components/wallet/components/cards';

// Redux store
import { openLoader, closeLoader } from '../../../../../redux-store/loader';
import { openGenericModal, closeGenericModal } from '../../../../../redux-store/genericModal';

// Styled Components
import OButton from '../../../../styled/components/button';
import { WrapperSteps, BuySteps, CircleStep, WrapperStepsContent } from './styled';
import { Separator } from '../../../../styled/components/wrappers';

// Models
import PaymentModel from '../../../../../data/models/payment/payment';
import StatsModel from '../../../../../data/models/stats/stats';

// Config
import Config from '../../../../../config';

// Utils
import { TrackingService } from '../../../../../utils/TrackingService';

// Styles
import '../../profile_new.scss';

// Locales
import I18n from '../../../../../i18n';

const packages = [{
    id: 1,
    priceEUR: 25
}, {
    id: 2,
    priceEUR: 50
}, {
    id: 3,
    priceEUR: 75
}, {
    id: 4,
    priceEUR: 100
}];

class WalletPackages extends Component {

    constructor(props) {

        super(props);

        this.state = {
            ygbCurrentValue: 0,
            exchangeUSD: 0,
            forceClick: false
        };

    }

    componentDidMount() {

        this.getData();

    }

    setSelected = index => {

        if (window.cordova) {

            this.setState({ forceClick: true });

        } else {

            packages[index].tokens = Math.round(packages[index].priceEUR / this.state.ygbCurrentValue);
            window.localStorage.setItem('pk', JSON.stringify(packages[index]));
            this.props.history.push('/profile/wallet/checkout');

        }

    }

    onInAppPurchaseSuccess = async (index, receipt, transactionId, signature) => {

        try {

            await PaymentModel.createYgbInvestment({
                amount: this.getCordovaPackPrice(index),
                currency: 'EUR',
                platform: window.device.platform,
                platformReceipt: receipt,
                orderId: transactionId,
                tokens: this.getCordovaPackTokenAmount(index),
                signature
            });

            const affiliation = window.device.platform === 'Android' ? 'Google Play' : 'Apple Store';

            TrackingService.registerEvent('Purchase', 'purchase', {
                affiliation,
                currency: 'EUR',
                value: this.getCordovaPackTokenAmount(index),
                item_list_name: 'Compra de paquete YGB'
            });

            this.props.closeLoader();
            this.onSuccess();

        } catch (error) {

            console.error(error);
            this.onError();

        }

    }

    onError = () => {

        const { openGenericModal, closeGenericModal, closeLoader } = this.props;

        closeLoader();

        openGenericModal({
            type: 'simple',
            title: {
                text: I18n.t('messages.alert'),
                classes: ['heading-2']
            },
            description: {
                text: I18n.t('auth.paymentErrorSubtitle'),
                classes: ['paragraph', 'regular']
            },
            buttons: [{
                text: I18n.t('actions.understood'),
                callback: () => {

                    closeGenericModal();

                },
                options: {
                    primary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

    }

    onSuccess = () => {

        const { openGenericModal, closeGenericModal } = this.props;

        openGenericModal({
            type: 'simple',
            title: {
                text: I18n.t('messages.toast'),
                classes: ['heading-2']
            },
            description: {
                text: I18n.t('checkout.correctPayment'),
                classes: ['paragraph', 'regular']
            },
            buttons: [{
                text: I18n.t('actions.understood'),
                callback: ()=> {

                    closeGenericModal();
                    this.props.history.push('/profile/wallet');

                },
                options: {
                    primary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

    }

    getData = async () => {

        try {

            const ygbCurrentValue = (await PaymentModel.getStagesInfo()).data.price;
            const exchangeInUSD = (await StatsModel.getExchange('USD')).data.value;
            this.setState({ ygbCurrentValue, exchangeInUSD });

        } catch (error) {

            console.error('error', error);

        }

    }

    getCordovaPackPrice = index => Config.inapppurchase.ygbPacks[packages[index].priceEUR].price[this.props.currency][window.device.platform];

    getCordovaPackTokenAmount = index => _.round(this.getCordovaPackPrice(index) / (this.props.currency === 'USD' ? this.state.exchangeInUSD : 1) / this.state.ygbCurrentValue);

    render() {

        const { ygbCurrentValue, forceClick } = this.state;
        const { userSession } = this.props;

        return (
            <div className="p-wrapper">
                <Topbar { ...this.props } showProfile={ false } text={ "Comprar YGB" } invert={ true } callback={ () => this.props.history.push(`/profile/wallet`) }/>
                <WrapperSteps>
                    <WrapperStepsContent>
                        <BuySteps active={ true }>
                            <CircleStep>
                                <Icon name="check" />
                            </CircleStep>
                            <span className="labeling">{ I18n.t('wallet.step1') }</span>
                        </BuySteps>
                        <BuySteps active={ false }>
                            <CircleStep>
                                <Icon name="check" />
                            </CircleStep>
                            <span className="labeling">{ I18n.t('wallet.step2') }</span>
                        </BuySteps>
                    </WrapperStepsContent>
                </WrapperSteps>
                <div className="inner">
                    <div className="spacing"/>
                    <div className="i-back" style={{ padding: '15px 30px' }} onClick={ () => this.props.history.push('/profile/wallet') }><Icon name="angle left" /><span>{ I18n.t('actions.back') }</span></div>
                    <div className="w-container">

                        <p className="heading-3" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section1.title')}}></p>
                        <List bulleted>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section1.txt1')}}></p></List.Item>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section1.txt2')}}></p></List.Item>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section1.txt3')}}></p></List.Item>
                        </List>
                        <Separator />
                        <p className="paragraph">{I18n.t('wallet.packages.header')}</p>

                        { window.cordova && <span style={{ fontSize: '12px', color: 'red' }}>{ I18n.t(`${ window.device.platform }.webDifferentPricesAdvise`) }&reg;</span> }
                        <WalletCard.WalletCardWrapper>
                        { packages.map(({ priceEUR, id }, index) => (
                            <WalletCard withBg key={ id } onClick={ () => this.setSelected(index) } maxW="24rem">
                                <WalletCard.Content>
                                    <WalletCard.Column>
                                        <p className="paragraph white">
                                            { window.cordova ?
                                                <NumericFormat value={ this.getCordovaPackTokenAmount(index) } type="token" suffix="YGB"/>
                                                : <NumericFormat value={ (priceEUR / ygbCurrentValue).toFixed(0) } type="token" suffix="YGB"/>
                                            }
                                        </p>
                                        <p className="paragraph white regular">
                                            { ygbCurrentValue } €/YGB
                                        </p>
                                    </WalletCard.Column>
                                    <WalletCard.Column isFlex flexDirection="column" alignItems="center" justifyContent="center">
                                        <p className="paragraph white">
                                            { window.cordova ?
                                                this.getCordovaPackPrice(index) + '€'
                                                : <NumericFormat value={ priceEUR } type="eur" />
                                            }
                                        </p>
                                        { window.cordova ?
                                            <InAppPurchaseButton
                                                text={ I18n.t('wallet.packages.buy') }
                                                product={ Config.inapppurchase.ygbPacks[packages[index].priceEUR].id }
                                                payer={ _.get(userSession, 'email', '') }
                                                forceClick={ forceClick }
                                                onSuccess={ (receipt, transactionId, signature) => this.onInAppPurchaseSuccess(index, receipt, transactionId, signature) }
                                                onError={ this.onError }
                                            /> :
                                            <OButton wallet upper type="button" size="small"><span>{ I18n.t('wallet.packages.buy') }</span></OButton>
                                        }
                                    </WalletCard.Column>
                                </WalletCard.Content>
                            </WalletCard>
                        )) }
                        </WalletCard.WalletCardWrapper>
                        <Separator xL />
                        <p className="heading-3" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section2.title')}}></p>
                        <List bulleted>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section2.txt1')}}></p></List.Item>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section2.txt2')}}></p></List.Item>
                        </List>
                        <Separator />
                        <p className="heading-3" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section3.title')}}></p>
                        <List bulleted>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section3.txt1')}}></p></List.Item>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section3.txt2')}}></p></List.Item>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section3.txt3')}}></p></List.Item>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section3.txt4')}}></p></List.Item>
                        </List>
                        <Separator />
                        <p className="heading-3" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section4.title')}}></p>
                        <List bulleted>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section4.txt1')}}></p></List.Item>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section4.txt2')}}></p></List.Item>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section4.txt3')}}></p></List.Item>
                            <List.Item><p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('wallet.packages.section4.txt4')}}></p></List.Item>
                        </List>
                        <Separator />
                    </div>
                </div>
            </div>
        );

    }

}

const mapStateToProps = state => {

    return {
        currency: _.get(state, 'auth.userSession.config.currency', {}),
        userSession: _.get(state, 'auth.userSession', {})
    };

};

export default connect(mapStateToProps, { openLoader, closeLoader, openGenericModal, closeGenericModal })(WalletPackages);