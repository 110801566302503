
import React from 'react';
import ColoredCard from './components/colored';
import SimpleCard from './components/simple';

  function HandleType(props) {
    
    switch(props.type) {

        case 'simple':

            return <SimpleCard { ...props } />
            
        case 'colored':

            return <ColoredCard { ...props } />

        default:

            return ''

    }

}

class GenericCard extends React.Component {

    render() {

        return (
            <React.Fragment>
                <HandleType {...this.props }  />
            </React.Fragment>

        );

    }

}

export default GenericCard;
