import styled, { css } from 'styled-components/macro';

const CardItem = styled.div`
    
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    border: solid 2px var(--border-color);
    margin-bottom: 20px;
    
    ${({context}) => context === 'grid' &&
        css`
            width: calc((100% / 2) - 20px);
            @media (min-width: 620px) {
                width: calc((100% / 3) - 20px);
            }

            @media (min-width: 1024px) {
                width: calc((100% / 4) - 20px);
            }

            @media (min-width: 1366px) {
                width: calc((100% / 5) - 20px);
            }
            
    `};

    ${({context}) => context === 'slider' &&
        css`
            height: 100%;
            
    `};
    
`;

const CardItemWrappImage = styled.div`
    width: 100%;
    padding-top: 68%;
    position: relative;
    overflow: hidden;
    background-color: #f2f2f2;
`;

const CardItemImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
`;

const CardItemContent = styled.div`
    padding: 15px 10px 0 10px;
    background-color: white;
`;

const CardItemTitle = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 5px 0;
`;

const CardItemTime = styled.div`
    color: var(--brand-primary-extra-light);
    i {
        &:before {
            color: var(--brand-primary-extra-light);
        }
    }
`;

const CardItemPrice = styled.div`
    margin-bottom: 20px;
`;

const CardItemBuyed = styled.div`
    display: flex;
    width: 100%;
    padding: 7px 10px;
    color: var(--y-green1);
    justify-content: center;
    align-items: center;
    font-family: PoppinsSemiBold;
    i {
        margin-right: 10px;
        &:before {
            color: var(--y-green1);
        }
    }
    @media (max-width: 720px) {
        font-size: 12px;
        }
    @media (min-width: 721px) {
        font-size: 14px;
    }
`;

const CardItemWrapperButton = styled.div`
    margin-top: auto;
    padding: 10px;
`;

export {
    
    CardItem,
    CardItemWrappImage,
    CardItemImage,
    CardItemContent,
    CardItemTitle,
    CardItemTime,
    CardItemPrice,
    CardItemBuyed,
    CardItemWrapperButton

};
