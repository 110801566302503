import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const createStripeCustomer = async () => await AxiosService.post(Config.server.api.paymentApi + 'stripe/customer', {}, true);
const createStripePaymentIntent = async data => await AxiosService.post(Config.server.api.paymentApi + 'stripe/intent', data, true);
const createStripePaymentSubscription = async data => await AxiosService.post(Config.server.api.paymentApi + 'stripe/subscription', data, true);
const createSubscription = async data => await AxiosService.post(Config.server.api.paymentApi + 'subscription', data, true);
const createYgbInvestment = async data => await AxiosService.post(Config.server.api.paymentApi + 'wallet/investments', data, true);
const createUserPayment = async (data) => await AxiosService.post(Config.server.api.paymentApi + 'user', data, true);
const getUserPayments = async () => await AxiosService.get(Config.server.api.paymentApi + 'user', {} , true);
const getUserInvestmentInvoice = async investmentId => await AxiosService.getBlob(Config.server.api.paymentApi + `wallet/invoice/${ investmentId }`, true);
const getUserPaymentInvoice = async paymentId => await AxiosService.getBlob(Config.server.api.paymentApi + `invoice/${ paymentId }`, true);
const removeSuscription = async () => await AxiosService.deleting(Config.server.api.paymentApi + `subscription`, true);
const getSchoolProductCredit = async () => await AxiosService.get(Config.server.api.paymentApi + 'commission/school-product-credit', {} , true);
const updateSchoolProductCredit = async creditIds => await AxiosService.put(Config.server.api.paymentApi + `commission`, creditIds, true);

const getStagesInfo = async () => await AxiosService.get(Config.server.api.paymentApi + 'io/stages', false);
const getUserWalletBalance = async () => await AxiosService.get(Config.server.api.paymentApi + 'wallet/balance', true);
const sendOrRecieveYGB = async (type, payload) => await AxiosService.post(Config.server.api.paymentApi + `wallet/balance/request/${type}`, payload , true);

//Para aceptar/denegar operación:
const acceptOrDeclineRequestYGB = async (requestId, action) => await AxiosService.post(Config.server.api.paymentApi + `wallet/balance/request/${requestId}/${action}`, {}, true);
const getTransactionsMovements = async () => await AxiosService.get(Config.server.api.paymentApi + 'wallet/balance/movements', true);

export default {
    createStripeCustomer,
    createStripePaymentIntent,
    createStripePaymentSubscription,
    createSubscription,
    createUserPayment,
    createYgbInvestment,
    getUserInvestmentInvoice,
    getUserPaymentInvoice,
    getUserPayments,
    getSchoolProductCredit,
    removeSuscription,
    updateSchoolProductCredit,
    getStagesInfo,
    getUserWalletBalance,
    sendOrRecieveYGB,
    acceptOrDeclineRequestYGB,
    getTransactionsMovements
}