import React from 'react';
import I18n from '../../../../../i18n';
import '../payment.scss';
import ErrorImage from '../../../../assets/img/error_payment_image.png';
import OButton from '../../../../styled/components/button';

class PaymentIncorrect extends React.Component {

    render() {

        return (
           <React.Fragment>
               <div className="main-background"></div>
               <div className="wrapper-payment">
                    <div className="first-col">
                        <div className="title">
                            <p>{I18n.t('auth.paymentErrorTitle')}</p>
                        </div>
                        <div className="subtitle">
                            <p>{I18n.t('auth.paymentErrorSubtitle')}</p>
                        </div>
                        <div className="btn-wrap">
                            <OButton type="button" upper primary onClick={() => this.props.history.goBack()}><span>{ I18n.t('actions.comeBack') }</span></OButton>
                        </div>
                    </div>
                    <div className="second-col">
                        <img src={ErrorImage} alt="" />
                    </div>
               </div>
           </React.Fragment>
        );

    }

}

export default PaymentIncorrect