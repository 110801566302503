import React, { Component } from 'react';
import _ from 'lodash';
import ChallengeHeader from '../components/header';
import { Frame, FrameGrid, FrameMatrix } from './styles';
import I18n from '../../../../i18n';
import StepsNav from '../components/stepsNav';
import ProgressIndicator from '../../../components/progressIndicator';
import { connect } from 'react-redux';
import GenericCard from '../../../components/genericCard';
import { FooterItem } from '../../../components/genericCard/components/simple/styles';
import { getChallenges } from '../../../../redux-store/challenges';
import Countdown from '../../../components/countdown';
import { InnerContainerFluid, Container } from '../styles';
import BiometricMatrix from '../../../components/biometricMatrix';
import { Icon, Message } from 'semantic-ui-react';

const HeaderMessage = (props) => {

    let availableChallengeIndex = _.findIndex(_.get(props,'challenges', []), challenge => !challenge.locked);
    if (availableChallengeIndex > -1) {

        if (props.challenges[availableChallengeIndex].asanas[0].selectedValue < 1) {

            //reto disponible y primer ejercicio pristine
            return(
                <React.Fragment>
                    <div className="right">
                        {!props.redoing && <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.firstTimeMessage.challenge${availableChallengeIndex}`, { userName:  _.get(props,'name', ''), time: props.challenges[availableChallengeIndex].time} ) }}></p>}
                        {props.redoing && <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.redoMessage.challenge${availableChallengeIndex}`, { userName:  _.get(props,'name', ''), time: props.challenges[availableChallengeIndex].time} ) }}></p>}
                    </div>
                </React.Fragment>
            );
            

        } else {

            //reto disponible y primer ejercicio no pristine
            return(
                <React.Fragment>
                    <div className="right">
                        {!props.redoing && <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.continueMessage.challenge${availableChallengeIndex}`, { userName:  _.get(props,'name', '')}) }}></p>}
                        {props.redoing && <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.redoMessage.challenge${availableChallengeIndex}`, { userName:  _.get(props,'name', ''), time: props.challenges[availableChallengeIndex].time} ) }}></p>}
                    </div>
                </React.Fragment>
            );
            
        }

    } else {

        return (
            <React.Fragment>
                <div className="right">
                    {props.role.indexOf('premium') > -1 ? <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.bloquePremiumFirst`, { userName:  _.get(props,'name', '')}) }}></p> : <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.bloqueSuscripterFirst`, { userName:  _.get(props,'name', '')}) }}></p>}
                    {props.role.indexOf('premium') > -1 ? <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t(`challenge.bloquePremiumSecond`) }}></p> : <p className="paragraph regular">¿Has valorado pasarte a Premium? Además de poder realizar tu autodiagóstico cada 7 días, cuentas con otras muchas ventajas y funcionalidades. Vale la pena.</p>}
                    <p className="paragraph regular">El sistema de autodiagnóstico quedará disponible en:</p>
                    {props.nextUnlockDate && <Countdown timeTillDate={props.nextUnlockDate} timeFormat="YYYY-MM-DDHH:mm:ss" classes="fitted" onEnd={props.onEnd}/>}
                </div>
            </React.Fragment>
        );

    }

}

class Step2 extends Component {


    constructor(props){
        super(props);
        this.state = {
            toggled: true
        }

    }

    onToggleMatrix = toggled => this.setState({toggled});

    onEnd = () => this.onGetChallenges();

    onGetChallenges = async () => {
        
        try {

            await this.props.getChallenges();
            
        } catch (error) { } 
        
    }

    selectChallenge = challengeId => this.props.history.push(`/mode/grow/step/2/challenge/${challengeId}`);

    render() {

        const { bioMetricMatrix, challenges, userSession, role } = this.props;
        const { toggled } = this.state;
        const nextChallengeToGo = _.findIndex(_.get(challenges, 'challenges'), challenge => !challenge.locked);

        const configCard = {
            type:'simple',
            mode: 'slider',
            list: challenges.challenges,
            mainAction: (item, index) => { this.selectChallenge(index) },
            isAllowed: item => !item.locked,
            renderTitle: item => item.description,
            renderDescription: item => item.subtitle,
            renderIndicator: item => item.title,
            renderFooterItems: item => <FooterItem className="caption regular">{ `${I18n.t('challenge.meassures')} ${item.positionCount} | ${item.time} Min ${I18n.t('challenge.aproxDuration')}` }</FooterItem>,
            thumbnail: { attribute : 'cover', ratio: '56.25%' },
            sliderToGo: nextChallengeToGo
        }

        return (
            <Container>
                <InnerContainerFluid>
                    <ChallengeHeader {...this.props} />
                    <StepsNav activedStep={2} {...this.props} />
                    <Frame>
                        <ProgressIndicator initialState={toggled} callback={this.onToggleMatrix} progress={ bioMetricMatrix.progress || 0 } title={ I18n.t('steps.step2.title') } progressText={ I18n.t('steps.step2.progress.matrix', { progress: bioMetricMatrix.progress || 0 }) } />
                    </Frame>
                    { toggled && 
                        <FrameMatrix>
                            <BiometricMatrix />
                        </FrameMatrix>
                    }
                     <Frame>
                        <Message className="c-pointer" size='tiny' onClick={() => this.props.history.push('/mode/grow/guide')} compact><Icon name="question circle outline" size="large" /><span className="caption">{I18n.t('messages.whatIsBiometricMatrix')}</span></Message>
                    </Frame>
                    <FrameGrid>
                        <Frame>
                            <p className="heading-3">{I18n.t('steps.step2.text')}</p>
                            <Frame>
                                <HeaderMessage name={ _.get(userSession,'name', '') } redoing={_.get(challenges, 'redoing', false)} role={_.get(role, 'role', '')} nextUnlockDate={_.get(challenges,'nextUnlockDate', '')} challenges={_.get(challenges,'challenges', [])} onEnd={this.onEnd} />
                            </Frame>
                            <GenericCard {...configCard} />
                        </Frame>
                    </FrameGrid>
                </InnerContainerFluid>
            </Container>
        );
    }
}

const mapStateToProps = state => {

    return {
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        challenges: _.get(state, 'challenges', []),
        userSession: _.get(state, 'auth.userSession', {}),
        role: _.get(state, 'role', {}),
    };
};

export default connect(mapStateToProps, { getChallenges  })(Step2);