import styled, { css } from 'styled-components/macro';

const ContentBody = styled.div``;

const ContentTitle = styled.p`
    font-family: PoppinsSemiBold;
    font-size: 14px;
    color: var(--brand-primary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 12px 8px;
    margin: 0;

`;

const ContentFooter = styled.div`
    border-top: solid 1px var(--border-color);
    padding: 10px 8px;
`;

const FooterItem = styled.span`
    background-color: var(--dark-grey-4);
    display: inline-block;
    border-radius: 5px;
    padding: 2px 10px;
    margin: 5px;
`;

const FrameLocked = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index:${({zIndex}) => zIndex || '1'};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: rgba(0,0,0,.45);
    text-align: center;
    visibility: hidden;
    i {
        font-size: 100px;
        &::before {
            color: #fff;
        }
    }

    span {
        font-family: PoppinsBold;
        font-size: 16px;
        color: #fff !important;
        text-transform: capitalize;
    }

    ${({visibled}) => visibled &&
        css`
            visibility: visible;
               
    `};

`;


export {
    
    ContentBody,
    ContentTitle,
    ContentFooter,
    FooterItem,
    FrameLocked
    
}
