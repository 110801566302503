import styled from 'styled-components/macro';
import staff1 from '../../../../assets/img/nexo_stuff.jpg';
import staff2 from '../../../../assets/img/nexo_stuff_2.jpg';
import staff3 from '../../../../assets/img/nexo_stuff_3.jpg';

import { AlignCenter, flexbox, flexWrap, justifySpaceBetween, XYCenter } from '../../styled/helpers';
import { AuthBox } from '../../styled';

const randomBackgrounds = (images, ln) => images[Math.floor(Math.random() * ln) ];
const randomBackground = randomBackgrounds([staff1, staff2, staff3], 3) ;

const LoginRow = styled.div`
    ${flexbox};
    width: 100%;
    min-height: 100%;
    background-image: url(${randomBackground});
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    position:relative;
    z-index:1;
    &:after {
        content:"";
        position:absolute;
        background: rgba(20, 10, 10, 0.29);
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index:2;
    }
    @media (max-width:559px) {
        background-image:none;
    }

`;

const LoginBox = styled(AuthBox)`
    @media (max-width:559px) {
        border-radius: 0px;
        box-shadow: none;
    }
`;

const LoginCol = styled.div`
    ${flexbox};
    ${XYCenter};
    position:relative;
    z-index:3;
    flex-grow:0;
    flex-shrink:1;
    flex-basis:100%;
    min-width:0;
    @media (max-width:559px) {
        background:var(--brand-white);
    }

`;

const LoginRemember = styled.div`

    ${flexbox};
    ${flexWrap};
    ${justifySpaceBetween};
    ${AlignCenter};
`;

const ForgotPassword = styled.div`
    span {
        cursor:pointer;
        padding-left:10px;
    }

`;

export {
    LoginRow,
    LoginCol,
    LoginBox,
    LoginRemember,
    ForgotPassword
}