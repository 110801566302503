import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Matriz from '../matriz';
import Indicators from './components/indicators';
import { Responsive } from 'semantic-ui-react';

import { openGenericModal, closeGenericModal } from '../../../redux-store/genericModal';

import { FrameContainer, FrameIndicator, MatrixBodies } from './styles';
import './styles/styles.scss';

class BiometricMatrix extends Component {

    componentWillUnmount() {

        this.props.closeGenericModal();

    }

    render() {

        const { bioMetricMatrixData, userSession } = this.props;

        return (
            <FrameContainer>
                <FrameIndicator>
                    {!_.isEmpty(bioMetricMatrixData) && 
                        <>  
                            <Responsive maxWidth={420}>
                                <Indicators {...this.props} radius={25} />
                            </Responsive>
                            <Responsive minWidth={421}>
                                <Indicators {...this.props} radius={43} />
                            </Responsive>
                        </>
                    }
                </FrameIndicator>
                <MatrixBodies>
                    <Matriz gender={userSession.gender === 1 ? 'male' : 'female'} matrix={bioMetricMatrixData} />
                </MatrixBodies>
            </FrameContainer>
        )
    }
}

const mapStateToProps = state => {

    return {
        sessionTime: _.get(state,'session.time', 0),
        userSession: _.get(state.auth, 'userSession', {}),
        bioMetricMatrixData: _.get(state, 'bioMetricMatrix.bioMetricMatrixData', {})
    };
};

export default connect(mapStateToProps, { openGenericModal, closeGenericModal })(BiometricMatrix);