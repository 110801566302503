import styled, { css } from 'styled-components/macro';

const SubMenuContainer = styled.div`
    height: 65px;
    box-shadow: 0px -3px -3px rgba(0, 0, 0, 0.3);
    display: flex;
    width: 100%;
    padding: 10px 40px;
    background-color: white;
    z-index: 10;
    position: relative;
    border-top: 1px solid #cdcdcd;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,.05);
    @media only screen and (min-width:921px) {
        display: none;
        
    }

    @media only screen and (max-width:420px) {
        padding: 10px 20px;
        
    }
`;

const Content = styled.div`
    flex: 1;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    i {
        font-size: 24px;
        &:before{
            color: #a6a6a6;
        }
        @media only screen and (max-width:420px) {
            font-size: 20px;
            
        }
        
    }

    span {
        font-family: PoppinsRegular;
        color: #a6a6a6;
        font-size: 12px;
        text-align: center;
        text-transform: capitalize;
        @media only screen and (max-width:420px) {
            font-size: 10px;
            
        }
    }

    ${({active}) => active &&
            css`
                i {
                    &:before{
                        color: var(--brand-secondary)!important;
                    }
                }
                span {
                    font-family: PoppinsBold;
                    color: var(--y-grey3)!important;
                }
               
        `};

    ${({hasNotify}) => hasNotify &&
        css`
            &:before {
                content: "";
                background-color: var(--red-color);
                position: absolute;
                top: 0;
                right: 7px;
                width: 33%;
                padding-top: calc(1/1 * 33%);
                border-radius: 50%;
            }
           
    `};

`;

export {
    SubMenuContainer,
    Content,
    Item
}