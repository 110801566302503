import React from 'react'
import I18n from '../../../../i18n';

import { GuideMatrixGrid, TextCol, ImageCol, ImageLayout, IndicatorPoints } from './styles';

import circleMatrizImage from '../../../assets/img/circle_matriz.svg';

function GuideOptionMatrixElement(props) {
    
    return (
        <>
           <GuideMatrixGrid>
                <TextCol>
                    <p className="heading-2 white" style={{margin: '32px 0'}}>{I18n.t('guide.howToCompleBiometricMatrix')}</p>
                    <div >
                        <p className="paragraph regular white">La Matriz Biométrica de YOGABOT se compone por diferentes zonas corporales especialmente segmentadas para facilitar el proceso de medición de tono y elasticidad de una forma <b>compensada y segura</b>, a través de cada reto.</p>
                        <p className="paragraph regular white">El objetivo final de este trabajo, es alcanzar y mantener un Nivel de Equilibrio Óptimo entre el <b>tono</b> y la <b>elasticidad</b> muscular y articular de cada zona corporal, y la <b>armonía entre todas ellas</b>. Cada zona corporal se irá dibujando con diferentes tonos de colores, cuyo significado podrás ver en el apartado:</p>
                    </div>
                    <p className="heading-2 white" style={{margin: '32px 0'}}>Códigos de Color</p>
                    <div>
                        <p className="paragraph regular white">Para medir el nivel de tono y elasticidad de cada zona corporal, hemos diseñado los ejercicios de medición que aparecen en MIS RETOS. Cada ejercicio tiene un video explicativo de como realizarlo y un formulario interactivo de autodiagnóstico que deberás ir completando.</p>
                        <p className="paragraph regular white">La correspondencia entre las zonas corporales y cada uno de los retos, es la siguiente:</p>
                    </div>
                </TextCol>
                <ImageCol>
                    <ImageLayout>
                        <IndicatorPoints t1>Mide las zonas frontal y posterior de las piernas.</IndicatorPoints>
                        <IndicatorPoints t2>Mide las zonas laterales, interna y externa, de las piernas.</IndicatorPoints>
                        <IndicatorPoints t3>Mide las zonas frontal y posterior de la pelvis y el torso.</IndicatorPoints>
                        <IndicatorPoints t4>Mide los laterales del torso, izquierdo y derecho.</IndicatorPoints>
                        <IndicatorPoints t5>Mide las zonas laterales de los brazos, interno y externo, así como la cara frontal y posterior del cinturón escapular.</IndicatorPoints>
                        <IndicatorPoints t6>Mide las zonas frontal y posterior de los brazos.</IndicatorPoints>
                        <img src={circleMatrizImage} alt="" />
                    </ImageLayout>
                </ImageCol>
            </GuideMatrixGrid>
        </>
    )
}

export default GuideOptionMatrixElement;