import styled from 'styled-components/macro';

const FrameHeader = styled.div`
    background-color: var(--blue-link);
    padding: 40px 30px 0px 30px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

`;
const FrameTriangle = styled.div`
    background-color: var(--blue-link);
    padding-bottom: 40px;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    position:relative;
    
`;
const FrameContent = styled.div`
    display: flex;
    flex-direction:column;
    align-items:center;
    i {
        &:before {
            color:#fff;
        }
    } 
`;

const FrameBody = styled.div`
    padding: 32px 20px 20px 20px;
`;
const FrameAwards = styled.div`
    padding-bottom:20px;
    display:flex;
    align-items:flex-start;
    flex-direction:column;
`;

const Awards = styled.div`
    display:flex;
    padding-bottom: 15px;
    align-items: center;
`;

const AwardText = styled.div`
    margin-left:5px;
`;

const AwardIcon = styled.div`
    i {
        &:before {
            color:var(--brand-alarm);
        }
    }
`;
export {
    FrameHeader,
    FrameContent,
    FrameBody,
    FrameTriangle,
    FrameAwards,
    Awards,
    AwardIcon,
    AwardText

};