import styled from 'styled-components/macro';
import OButton from '../../../../../../../styled/components/button';

const SwipperContainer = styled.div`
    width:100%;
    @media (min-width: 800px) {
        display:none;
    }
    
`;

const GridPrice = styled.div`

    display:none;
    max-width:65rem;
    flex-wrap:wrap;
    margin:0 auto;
    @media (min-width: 800px) {
        display:flex;
        flex-wrap:nowrap;
    }
`;


const CardPrice = styled.div`

    background-color:#fff;
    border-radius:10px;
    overflow:hidden;
    flex:1 1 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
        margin:1rem;
    .price {
        margin-left:auto;
    }

    p {
        margin-bottom:0;
    }

    @media (max-width: 350px) {
        margin:0.5rem;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    }
`;

const CardPriceHeader = styled.header`
    padding:3rem 2rem 2rem 2rem;
    display:flex;
    align-items:center;
    border-bottom:solid 1px #888585;
`;

const CardPriceContent = styled.div`
    padding:2rem;
    flex-grow:1;
    display: flex;
    flex-direction: column;
    .price-division {
        margin-bottom:1.5rem;
    }
`;

const TopSeparator = styled.div`
    padding-top:2rem;
`;

const CardPriceButtons = styled.div`
    display:flex;
    padding: 2.5rem;
    margin-top: auto;
    justify-content:center;
    ${OButton} {
        flex: 0 1 22.7rem;
    }
`;

const CardPriceFeature = styled.div`
    display:flex;
    align-items:center;
    min-width:0;
    overflow-wrap:break-word;
    margin-bottom:0.5rem;

`;

const CardPriceIcon = styled.div`

    flex:0 0 2.5rem;
    height:3.5rem;
    display:flex;
    align-items:center;
    justify-content: flex-start;
    i {
        height:auto;
        margin:0;
        color: ${({active}) => active ? 'var(--brand-secondary)' : 'var(--y-grey14)'};
    }

`;

export {
    GridPrice,
    CardPrice,
    CardPriceHeader,
    CardPriceContent,
    CardPriceButtons,
    SwipperContainer,
    CardPriceFeature,
    CardPriceIcon,
    TopSeparator
}