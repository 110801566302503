// Types
const OPEN_WELCOME_MODAL = 'OPEN_WELCOME_MODAL';
const CLOSE_WELCOME_MODAL = 'CLOSE_WELCOME_MODAL';

// Actions
export const openWelcomeModal = () => { return { type: OPEN_WELCOME_MODAL, payload: { open: true } } };
export const closeWelcomeModal = () => { return { type: CLOSE_WELCOME_MODAL, payload: { open: false } } };

// Reducer
const INITIAL_STATE = {
    open : false
};

function reducer (state = INITIAL_STATE, action) {

    switch (action.type) {

        case OPEN_WELCOME_MODAL:
        case CLOSE_WELCOME_MODAL:

            return { ...action.payload };

        default:

            return state;

    }

};

export default reducer; 