// Third party libraries
import { Icon } from 'semantic-ui-react';

// Styled Components
import { Content, SubMenuContainer, Item } from './styles';

// Locales
import I18n from '../../../../i18n';

// Styles
import './subMenu.scss';

const SubMenu = props => {

    const { active, history } = props;

    return <SubMenuContainer>
        <Content>
            <Item active={ active === 'home' } onClick={ () => history.push(`/home`) }><Icon name="heartbeat" /><span>{ I18n.t('menu.myMatrix') }</span></Item>
            <Item active={ active === 'practice' } onClick={ () => history.push(`/practiceList`) }><Icon name="male" /><span>{ I18n.t('menu.practice') }</span></Item>
            <Item active={ active === 'studio' } onClick={ () => history.push(`/studio`) }><Icon name="book" /><span>{ I18n.t('navigation.studio') }</span></Item>
        </Content>
    </SubMenuContainer>;

};

export default SubMenu;