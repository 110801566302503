import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import CustomInput from '../../../components/form/input';
import I18n from '../../../../i18n';
import '../profile.scss';
import OButton from '../../../styled/components/button';

class FormChangeEmail extends React.Component {

    render() {

        return (
           <React.Fragment>
               <Form  name="profileAccountForm" noValidate onSubmit={this.props.handleSubmit(this.props.onSaveEmail)}>
                    <div className="verticalPad">
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('profile.email') }
                            name="email"
                            fieldClasses="label-primary"
                            label={ '' } />
                            <OButton upper type="submit" color="#FFF" primary>
                                <span>{I18n.t('actions.modifyEmail')}</span>
                            </OButton>
                    </div>
                </Form>
                    
           </React.Fragment>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');
        
    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');
        
    }

    return errors;

};

export default reduxForm({
    form: 'formChangeEmail',
    touchOnBlur: true,
    touchOnChange: false,
    enableReinitialize: true,
    validate,
})(connect(null, { })(FormChangeEmail));

