import React from 'react'
import { InnerSection, MarketsLogos, Section, SectionBackground } from '../../styled'
import GooglePlayIcon from '../../../../../../assets/img/m_google.png';
import AppleStoreIcon from '../../../../../../assets/img/m_apple.png';
import { motion } from 'framer-motion';

export function CtaMarkets(props) {

    return (
        <Section noPadding>
            <SectionBackground withBg>
                <InnerSection sectionSubFooter>
                    <div>
                        <p className="landing-heading-2 regular">DESCARGA LA APP</p>
                        <p className="landing-heading-2 regular">Tu transformación comienza ahora</p>
                    </div>
                    <MarketsLogos>
                        <a href="https://play.google.com/store/apps/details?id=app.yogabot" target="_blank" rel="noopener noreferrer">
                            <motion.img src={GooglePlayIcon} alt="google play" whileHover={{ scale: 1.1 }} />
                        </a>
                        <a href="https://apps.apple.com/us/app/id1521225922" target="_blank" rel="noopener noreferrer">
                            <motion.img src={AppleStoreIcon} alt="apple store" whileHover={{ scale: 1.1 }} />
                        </a>
                    </MarketsLogos>
                </InnerSection>
            </SectionBackground>
        </Section>
    )
}
