import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import GradientNav from '../../../components/navbar/gradient';
import SubMenu from '../../../components/navbar/submenu';
import FaqModel from '../../../../data/models/faq/faq';
import ExtraTopBar from '../../../components/navbar/extraTopBar';
import FaqBox from '../../../components/faqBox';
import arrowBackPink from '../../../assets/img/arrow-pink.svg'; 
import TopBar from '../../../components/navbar/topbar';
import { openLoader, closeLoader } from '../../../../redux-store/loader';
import Footer from '../../../components/footer';
import I18n from '../../../../i18n';

import '../faq.scss';

class FaqList extends React.Component {

    constructor(props){
        super(props);

        this.state = {

            faqs: []
        }
    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        let faqs = [];

        try {

            this.props.openLoader();
            const faqsResponse = await FaqModel.getFaqs();

            if (faqsResponse.status === 200) {

                _.each(faqsResponse.data, faq => {

                    if (this.props.match.params.categoryId === faq.category) {

                        faqs.push(faq);
                    }

                });

                this.setState({faqs});
                
            }
            
        } catch (error) {

            
        } finally {

            this.props.closeLoader();

        }

    }

    gotoLogin = () => {

        this.props.history.push(`/auth/login`);
        
    };

    render() {

        return (
            <React.Fragment>
              { window.localStorage.getItem('jwt') && <GradientNav active="" {...this.props} /> }
              {!localStorage.getItem('jwt') && <ExtraTopBar onClick={ this.gotoLogin } className="register" hasButton={true}/>}
              <div className={"faq-wrapper " + (!window.localStorage.getItem('jwt') ? 'hasExtraTopBar' : '')}>
              {window.localStorage.getItem('jwt') && <TopBar {...this.props} callback={()=>this.props.history.push('/faq')}/>}
                  <div className="inner">
                        <div className="center-content">
                            <h1>{I18n.t("auth.faq")}</h1>
                            <div className="go-back-desktop no-padding" onClick={()=>this.props.history.push('/faq')}>
                                <span className="back" ><img src={arrowBackPink} alt="" /></span><span className="back-text">{I18n.t('actions.back')}</span>
                            </div>
                            {!window.localStorage.getItem('jwt') && <div className="go-back-mobile no-padding" onClick={()=>this.props.history.push('/faq')}>
                                <span className="back" ><img src={arrowBackPink} alt="" /></span>
                            </div>}
                            <p dangerouslySetInnerHTML={{__html: I18n.t('faq.faqList.text1')}}></p>
                            <p>{I18n.t('faq.faqList.text2')}</p>
                            <p>{I18n.t('faq.faqList.text3')}</p>
                        </div>
                        <FaqBox faqs={ this.state.faqs }/>
                        <div className="center-content">
                            <p>
                                <Trans i18nKey="faq.faqList.link">
                                    <Link to={`/faq/question/${this.props.match.params.categoryId}`}></Link>
                                </Trans>
                            </p>
                        </div>
                  </div>
                  <Footer { ...this.props } type="branded"/>
              </div>
              { window.localStorage.getItem('jwt') && <SubMenu active="" {...this.props}/> }
            </React.Fragment>
        );

    }

}

export default connect(null, { openLoader, closeLoader} )(FaqList);
