import React from 'react'
import I18n from '../../../../../../i18n';
import { ChallengeFrame, HeaderContent, HeaderLabel, HeaderTitle, HeaderStats, BodyStats } from "./styles";

function ChallengeBackground({ challenges, currentIndex }) {
    
    return (
        <>
            <ChallengeFrame src={challenges[currentIndex].coverLarge}>
                <HeaderContent>
                    <p className="heading-1 white upper" style={{margin: '0'}}>{challenges[currentIndex].title}</p>
                    <HeaderLabel className="heading-2 white">{ I18n.t('status.available')}</HeaderLabel>
                </HeaderContent>
                <HeaderTitle>
                    <p className="heading-1 regular white">{challenges[currentIndex].description}</p>
                </HeaderTitle>
                <HeaderStats>
                    <span>{I18n.t('challenge.meassures')}</span><span>{I18n.t('challenge.elasticity')}</span><span>{I18n.t('challenge.tone')}</span><span>{I18n.t('challenge.aproxDurationLarge')}</span>
                </HeaderStats>
                <BodyStats>
                    <span>{challenges[currentIndex].positionCount}</span>
                    <span>{challenges[currentIndex].elasticity}</span>
                    <span>{challenges[currentIndex].tone}</span>
                    <span>{challenges[currentIndex].time}min</span>
                </BodyStats>
            </ChallengeFrame>
        </>
    )
}

export default ChallengeBackground;