import React, { useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react';
import { FrameContent, Progress, ProgressBar, ProgressInner, FrameArrow} from './styles';

/***
 * * PROGRESS INDICATOR
 * 
 * @param title String translated
 * @param progress Number 0 => 100
 * @param progressText String translated
 * @param callback Function optional, If the callback is defined then the caret icon box appear and call the callback with true/false params
 * @description  
 */

export default function ProgressIndicator ({ title = "", progress = 0, progressText = "", callback = null, initialState = false }) {

    const [toggle, onChangeToggle] = useState(initialState);

    const onToggle = () => {

        onChangeToggle(!toggle);

    }

    useEffect(() => {

        if (typeof callback === 'function') {

            callback(toggle);
            
        }
        
      }, [toggle, callback]);


    return (
        <FrameContent>
            <p className="heading-1" style={{ marginBottom: '5px' }}>{title}</p>
            { typeof callback === 'function' && 
                <FrameArrow onClick={ onToggle }>
                    <Icon name={`caret ${!toggle ? 'down' : 'up'}`} size="big" />
                </FrameArrow>
            }
            <Progress>
                <ProgressBar>
                    <ProgressInner progress={progress}/>
                </ProgressBar>
            </Progress>
            <span className="paragraph regular">{progressText}</span>
        </FrameContent>
    )
}
