import React, { Component } from 'react'
import _ from 'lodash';
import './optionsDetail.scss';
import I18n from '../../../../../../i18n';

class OptionsDetails extends Component {

    render() {

        const { selectedProduct } = this.props;

        return !_.isEmpty(selectedProduct) && (
            <div className="std-options">
                <div className="std-options-header">
                    <p>{I18n.t('studio.options.title', {title: selectedProduct.title})}</p>
                </div>
                <div className="std-options-body">
                    <div className="std-options-item">
                        <p className="std-options-title upper">{I18n.t('studio.options.flexoExtension')}</p>
                        {selectedProduct.flexoextension !== 'noImplies' && <p className="std-options-description">{I18n.t('studio.options.stimulateColumn', {literal: I18n.t(`studio.${selectedProduct.flexoextension}`)})}.</p>}
                        {selectedProduct.flexoextension === 'noImplies' && <p className="std-options-description">{I18n.t(`studio.${selectedProduct.flexoextension}`)}.</p>}
                    </div>
                    <div className="std-options-item">
                        <p className="std-options-title upper">{I18n.t('studio.options.laterization')}</p>
                        <p className="std-options-description">{I18n.t(`studio.${selectedProduct.lateralization}`)} {I18n.t('studio.options.laterizationWork')}</p>
                    </div>
                    <div className="std-options-item">
                        <p className="std-options-title upper">{I18n.t('studio.options.abduction')}</p>
                        {selectedProduct.abduction !== 'noImplies' && <p className="std-options-description">{I18n.t('studio.options.abductionPowered', {literal: I18n.t(`studio.${selectedProduct.abduction}`)})}.</p>}
                        {selectedProduct.abduction === 'noImplies' && <p className="std-options-description">{I18n.t(`studio.${selectedProduct.abduction}`)}.</p>}
                    </div>
                    <div className="std-options-item">
                        <p className="std-options-title upper">{I18n.t('studio.options.muscularTrain')}</p>
                        {
                            selectedProduct.muscleTrain === 'balanced' && <p className="std-options-description">{I18n.t('studio.options.muscleTrainPower', {literal: I18n.t(`studio.${selectedProduct.muscleTrain}`)})}.</p>
                        }
                        {
                            (selectedProduct.muscleTrain !== 'balanced' && selectedProduct.muscleTrain !== 'noImplies') && <p className="std-options-description">{I18n.t('studio.options.muscleTrainImpact', {literal: I18n.t(`studio.${selectedProduct.muscleTrain}`)})}.</p>
                        }
                        {
                            (selectedProduct.muscleTrain === 'noImplies') && <p className="std-options-description">{I18n.t(`studio.${selectedProduct.muscleTrain}`)}.</p>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default OptionsDetails
