import React from 'react'
import { connect } from 'react-redux';
import _ from 'lodash'
import { updateWindowSize } from '../../../redux-store/windowMeassures';

 class MeassureListener extends React.Component {

    onResize = () => {

        const { updateWindowSize } = this.props;

        updateWindowSize({
            height: window.innerHeight,
            width: window.innerWidth
        });

    }
    
    componentDidMount() {

        window.addEventListener("resize", _.debounce(() => this.onResize(), 200));

    }

    componentWillUnmount() {
        
        window.removeEventListener("resize", this.onResize);

    }

    render() {
        return null
    }
}

export default connect(null, {updateWindowSize})(MeassureListener);