import React from 'react';
import './correctMessage.scss';

class CorrectMessage extends React.Component {

    render() {

        return (
            <React.Fragment>
                {<div className="correct-message-box verticalPad">
                    {this.props.message}
                </div>}
            </React.Fragment>
        );

    }
    
};

export default CorrectMessage;