import React from 'react';
import '../legal.scss';
import ExtraTopBar from '../../../components/navbar/extraTopBar';
import SubMenu from '../../../components/navbar/submenu';
import TopBar from '../../../components/navbar/topbar';
import I18n from '../../../../i18n';
import Footer from '../../../components/footer';
import GradientNav from '../../../components/navbar/gradient';

class CookiesPolicy extends React.Component {

    gotoLogin = () => {

        this.props.history.push(`/auth/login`);
        
    };

    render() {

        return (
            <React.Fragment>
                {!localStorage.getItem('jwt') && <ExtraTopBar onClick={ this.gotoLogin } className="register" hasButton={true}/>}
                {localStorage.getItem('jwt') && <GradientNav active="" {...this.props} /> }
                <div className={"main-container " + (!localStorage.getItem('jwt') ? 'hasExtraTopBar' : '')}>
                {localStorage.getItem('jwt') && <TopBar callback={() => this.props.history.goBack()} {...this.props} text={I18n.t('menu.cookies')}/>}
                    <div className="inner-container">
                        <h1>Política de cookies</h1>
                        <h3>Tiempo de lectura: 2 minutos</h3>
                        <p>Desde www.yogabot.app deseamos informarte que nuestra web utiliza cookies para medir y analizar la navegación de nuestros usuarios.</p>
                        <p>Las cookies son pequeños ficheros de información que nos permiten comparar y entender cómo navegas a través de nuestra página web. Gracias a ellas, recordamos tus preferencias para que cuando nos visites de nuevo, obtengas una mejor experiencia online. En ningún caso las cookies almacenan dato personal alguno, ni ningún tipo de información que pueda identificarte.</p>
                        <p>Si aceptas expresamente nuestra política de cookies en la fase de registro y/o continúas en la Web navegando por distintas páginas, utilizando la barra de desplazamiento, o accediendo a diferentes áreas y menús, de esa acción se inferirá que aceptas la instalación y uso de las cookies.</p>
                        <p><b>1</b>. Este Sitio Web utiliza los siguientes tipos de cookies:</p>
                        <p><b>Cookies de Sesión</b>: Son necesarias para el correcto uso de la página web, como para la sesión de usuarios, o para navegar de forma ininterrumpida recordando opciones de idioma o país. Se utilizan para identificar al usuario, si fuese necesario acceder a un área de registro. Sin estas cookies, muchos de los servicios disponibles no estarían operativos. Dichas cookies caducan cuando se cierra el navegador o <b>pasado un mes.</b></p>
                        <p><b>Cookies Técnicas</b>: Éstas tratan información genérica y anónima, donde no se incluyen datos personales, sólo recuerdan la página de la que proviene el usuario. Su objetivo principal es la mejora del funcionamiento de la web.</p>
                        <p><b>Cookies de "plug-in" de redes sociales</b>: Permitir al usuario compartir contenidos con miembros de una determinada red social.</p>
                        <p><b>Cookies de Terceros (Google Analytics)</b>: Conocer el nivel de recurrencia de nuestros visitantes y los contenidos que resultan más interesantes para concentrar nuestros esfuerzos en mejorar las áreas más visitadas y hacer que el usuario encuentre más fácilmente lo que busca. Este dato se consigue a través de cálculos estadísticos sobre datos anónimos. Para más detalles, consulte en el siguiente enlace la política de privacidad. <a href="https://www.google.com/intl/es/policies/privacy/">https://www.google.com/intl/es/policies/privacy/</a></p>
                        <p><b>2</b>. Cómo deshabilitar las Cookies y los Web Bugs en los principales navegadores:</p>
                        <p>Para permitir, conocer, bloquear o eliminar las cookies instaladas en tu equipo puedes hacerlo mediante la configuración de las opciones del navegador instalado en su ordenador.</p>
                        <p>Puedes encontrar información sobre cómo hacerlo en el caso que uses como navegador:</p>
                        <p>– Firefox desde <a href="https://support.mozilla.org/es/products/firefox/cookies">https://support.mozilla.org/es/products/firefox/cookies</a></p>
                        <p>– Chrome desde <a href="https://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">https://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a></p>
                        <p>– Explorer desde <a href="https://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9">https://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</a></p>
                        <p>– Safari desde <a href="https://support.apple.com/kb/ph5042">https://support.apple.com/kb/ph5042</a></p>
                    </div>
                    <Footer {...this.props} type="branded"/>
                </div>
                {localStorage.getItem('jwt') && <SubMenu active="" {...this.props}  />}
            </React.Fragment>
        );

    }

}
export default CookiesPolicy;