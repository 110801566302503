// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import Slider from "react-slick";
import { connect } from 'react-redux';
import { Icon, Responsive } from 'semantic-ui-react';

// Models
import ProductModel from '../../../../data/models/product/product';

// Redux Store
import { addToCart } from '../../../../redux-store/cart';
import { openGenericModal, closeGenericModal } from '../../../../redux-store/genericModal';
import { openLoader, closeLoader } from '../../../../redux-store/loader';

// Config
import Config from '../../../../config';

// Components
import DiscountElement from '../../../components/cards/components/discountElement';
import Card from '../../../components/cards/components/card';
import Footer from '../../../components/footer';
import GradientNav from '../../../components/navbar/gradient';
import SubMenu from '../../../components/navbar/submenu';
import TopBar from '../../../components/navbar/topbar';
import OptionsDetails from './components/options';

// Styled components
import OButton from '../../../styled/components/button';

// Utils
import { toMMSS } from '../../../../utils/formatTime';

// Locales
import I18n from '../../../../i18n';

// Styles
import './details.scss';

function PrevArrow(props) {

    const { onClick, className } = props;

    return (
        <button type="button" className={"slick-custom-btn-primary prev-a " + className} onClick={onClick}>
            <svg alt="" color="white" role="img" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" style={{ height: '40px', width: '40px', zIndex: 'auto' }}>
            <path fill="#c1c1c1" d="M22.324 28.008c.537.577.355 1.433-.326 1.809a1.44 1.44 0 0 1-.72.183c-.398 0-.786-.151-1.048-.438L10.06 18.588a1.126 1.126 0 0 1 0-1.555L20.233 6.09c.438-.468 1.198-.564 1.767-.25.681.377.863 1.23.325 1.808l-9.446 10.164 9.446 10.196zM11.112 17.615a.31.31 0 0 1 0 .391l.182-.195-.182-.196zM21.308 7.094c-.01-.006-.053 0-.029-.027a.07.07 0 0 0 .029.027zm-.025 21.499a.95.95 0 0 1-.006-.008l.006.008z"></path></svg>
        </button>
    );

}

function NextArrow(props) {

    const { onClick, className } = props;

    return (
        <button type="button" className={"slick-custom-btn-primary next-a " + className} onClick={onClick}>
            <svg alt="" color="white" role="img" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" style={{ height: '40px', width: '40px', zIndex: 'auto' }}>
            <path fill="#c1c1c1" d="M13.065 7.65c-.538-.578-.355-1.433.325-1.81a1.44 1.44 0 0 1 .72-.182c.398 0 .786.15 1.048.437L25.327 17.07a1.126 1.126 0 0 1 0 1.555L15.155 29.568c-.438.468-1.198.563-1.767.25-.681-.377-.863-1.23-.325-1.809l9.446-10.164L13.065 7.65zm11.211 10.393a.31.31 0 0 1 0-.391l-.181.194.181.197zM14.081 28.564c.01.006.053 0 .028.027a.07.07 0 0 0-.028-.027zm.024-21.5a.95.95 0 0 1 .007.008l-.007-.007z" ></path></svg>
        </button>
    );

}

const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    arrows: true,
    slidesToScroll: 1,
    mobileFirst: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: false,
    infinite: false,
    responsive: [{
        breakpoint: 1110,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 860,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 720,
        settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 560,
        settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 1
        }
    }]

};

class StudioDetails extends Component {

    constructor(props) {

        super(props);

        this.state = {
            swiped: false,
            loading: false,
            selectedProduct: {}
        };

    }

    componentDidMount() {

        this.onGetData();

    }

    onGetData = async () => {

        this.setState({loading: true})

        try {

            this.props.openLoader();
            const response = await ProductModel.getProduct(this.props.match.params.asanaId);
            this.setState({ selectedProduct: response.data });

        } catch (error) {

            console.error('error', error)

        } finally {

            this.props.closeLoader();
            this.setState({ loading: false })

        }

    }

    componentDidUpdate(prevProps) {

        /**
         ** If a related asana is selected or if the user goes back or forward through navigation, call getData with the new param id due to componentDidMount is called once.
        */
        if (prevProps.match.params.asanaId !== this.props.match.params.asanaId) {

            this.onGetData();

        }

    }

    onAddToCart = (e, added, id) => {

        if (this.state.swiped) {

            e.stopPropagation();
            e.preventDefault();
            this.setState({swiped: false});

        } else if (!added) {

            this.addToCart(id);

        }

    }

    addToCart = id => {

        const { addToCart, products } = this.props;

        if (window.cordova && _.size(_.filter(products, ({ added }) => added)) > 9) {

            const { openGenericModal, closeGenericModal, history } = this.props;

            openGenericModal({
                type: 'simple',
                title: {
                    text: I18n.t('messages.toast'),
                    classes: ['heading-2']
                },
                description: {
                    text: I18n.t(`${ window.device.platform }.maxNumberOfSchoolProducts`, { maxProducts: 10 }),
                    classes: ['paragraph', 'regular']
                },
                buttons: [{
                    text: I18n.t('actions.understood'),
                    callback: () => {

                        closeGenericModal();
                        history.push('/cart');

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true
                    }
                }]
            });

        } else {

            addToCart(id);

        }

    }

    goTo = (e, id) => {

        if (this.state.swiped) {

            e.stopPropagation();
            e.preventDefault();
            this.setState({swiped: false});

        } else {

            (e.target.nodeName !== 'BUTTON' && e.target.nodeName !== 'SPAN') && this.props.history.push(`/studio/details/${id}`);

        }

    }

    handleSwiped = () => {

        let that = this;
        this.setState({ swiped:true }, () => {

            clearInterval(that.timeout);
            that.timeout = setTimeout(()=> {

                that.setState({ swiped: false });

            }, 500);

        });

    }

    setCurrencyView = (currency, price) => currency === 'USD' ? <span>${ price[currency].toFixed(2) }</span> : <span>{ price[currency].toFixed(2) }€</span>;

    render() {

        const { products, currency } = this.props;
        const { selectedProduct } = this.state;

        /**
         * !BUG: merge mutate the state redux making a bug
         **/
        const selectedProductToMerge = _.merge(_.find(_.cloneDeep(products), ({_id}) => _id === this.props.match.params.asanaId) || {}, selectedProduct);
        const relatedProducts = _.filter(products, ({_id, published, buyed}) => !buyed && published && _.findIndex(selectedProduct.relatedProducts, el => el === _id)>-1 ) || [];

        return (
            <React.Fragment>
                <GradientNav active="studio" {...this.props} />
                <div className="std-wrapper">
                    <TopBar {...this.props} showProfile={true} text={''} hasBasket={true} />
                    <div className="inner">
                        <div className="std-flex">
                            <div className="std-aside">
                                <div className="std-breadcrumb">
                                    <span><span className="cbrand c-pointer" onClick={()=>this.props.history.push('/studio')}>{I18n.t('menu.studio')}</span> &gt; {I18n.t('menu.allAsanas')}</span>
                                </div>
                                <p className="std-title upper">{!_.isEmpty(selectedProduct) && selectedProduct.title}</p>
                                <p className="std-subtitle">{!_.isEmpty(selectedProduct) && selectedProduct.subtitle} <i className="f-icon-icono_17"></i>{toMMSS(selectedProduct.duration)}</p>
                                { (!_.isEmpty(selectedProductToMerge) && !selectedProductToMerge.buyed) &&
                                    <div className="std-actions">
                                        { _.isEmpty(selectedProductToMerge.discountedPrices) &&
                                            <p className="std-prices">{this.setCurrencyView(currency, selectedProductToMerge.prices)}</p>
                                        }
                                        { !_.isEmpty(selectedProductToMerge.discountedPrices) &&
                                            <DiscountElement style={{marginBottom: '10px'}}>
                                                <span className="heading-3 upper">{selectedProductToMerge.discount}% DTO</span>
                                            </DiscountElement>
                                        }
                                        { !_.isEmpty(selectedProductToMerge.discountedPrices) &&
                                            <DiscountElement.DiscountPrice style={{marginBottom: '10px'}}>
                                                <p className="paragraph regular brand-primary-extra-light" style={{margin: 0}}>{I18n.t('studio.previousPrice')}{this.setCurrencyView(currency, selectedProductToMerge.prices)}</p>
                                                <p className="heading-3" style={{margin: 0}}>{I18n.t('studio.currentPrice')} {this.setCurrencyView( currency, selectedProductToMerge.discountedPrices )}</p>
                                            </DiscountElement.DiscountPrice>
                                        }
                                        { !selectedProductToMerge.added &&
                                            <OButton fluid className="std-btn" primary color="#fff" upper type="button" onClick={()=> this.addToCart(selectedProductToMerge._id)}>
                                                <span>{I18n.t('studio.addTocart')}</span>
                                            </OButton>
                                        }
                                        { selectedProductToMerge.added &&
                                            <OButton fluid icon labelPosition='left' className="std-btn" selected color="#1bb89f" upper type="button">
                                                <Icon name="check"/> <span>{I18n.t('studio.added')}</span>
                                            </OButton>
                                        }
                                    </div>
                                }
                                <Responsive maxWidth={920}>
                                    { (!_.isEmpty(selectedProductToMerge) && !selectedProductToMerge.buyed) &&
                                        <div className="std-wrapper-image">
                                            <div className="std-wrapper-image-layout">
                                                <img src={selectedProductToMerge.imageUrl} alt=""/>
                                            </div>
                                        </div>
                                    }
                                    { (!_.isEmpty(selectedProductToMerge) && selectedProductToMerge.buyed) &&
                                        <div className="std-wrapper-video">
                                            <div className="std-wrapper-video-layout">
                                                <iframe title="vimeo" src={Config.player.path + window.btoa(selectedProductToMerge.videoUrl)} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                                            </div>
                                        </div>
                                    }
                                </Responsive>
                                <OptionsDetails selectedProduct={selectedProductToMerge} />
                            </div>
                            <div className="std-content">
                                <Responsive minWidth={921}>
                                    { (!_.isEmpty(selectedProductToMerge) && !selectedProductToMerge.buyed) &&
                                        <div className="std-wrapper-image">
                                            <div className="std-wrapper-image-layout">
                                                <img src={selectedProductToMerge.imageUrl} alt=""/>
                                            </div>
                                        </div>
                                    }
                                    { (!_.isEmpty(selectedProductToMerge) && selectedProductToMerge.buyed) &&
                                        <div className="std-wrapper-video">
                                            <div className="std-wrapper-video-layout">
                                                <iframe title="vimeo" src={Config.player.path + window.btoa(selectedProductToMerge.videoUrl)} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                                            </div>
                                        </div>
                                    }
                                </Responsive>
                            </div>
                        </div>
                        { !_.isEmpty(relatedProducts) ?
                            <div className="rp-wrapper">
                                <div className="rp-header">
                                    <p className="rp-title">{I18n.t('studio.reminderRelated', {title: selectedProduct.title})}</p>
                                </div>
                                <div className="rp-slider">
                                    <Slider {...settings} onSwipe={this.handleSwiped}>
                                        { _.map(_.filter(relatedProducts, ({published}) => published), (item, index) => (
                                            <Card
                                                {...item }
                                                key={item._id + index }
                                                context="slider"
                                                currency={currency}
                                                mainAction={this.goTo}
                                                onAddToCart={this.onAddToCart}
                                            />
                                        )) }
                                    </Slider>
                                </div>
                            </div>
                        : '' }
                    </div>
                    <Footer { ...this.props } type="branded"/>
                </div>
                <SubMenu active="studio" { ...this.props }/>
            </React.Fragment>
        );

    }

}

const mapStateToProps = state => {

    return {
        products: _.get(state, 'cart.products', []),
        role: state.role,
        currency: _.get(state, 'auth.userSession.config.currency', {})
    };

};

export default connect(mapStateToProps, { addToCart, openLoader, closeLoader, openGenericModal, closeGenericModal })(StudioDetails)