import React from 'react';
import { Route } from 'react-router-dom';
import GradientNav from '../../components/navbar/gradient';
import SubMenu from '../../components/navbar/submenu';
import PracticeTargets from './components/practiceTargets';
import PracticePreview from './components/practicePreview';

class PracticeDiscover extends React.Component {

    render() {

        return (
            <React.Fragment>
                <GradientNav active="practice" {...this.props} />
                    <Route path="/practiceDiscover/targets/:sequence" exact component={ PracticeTargets } />
                    <Route path="/practiceDiscover/preview/:sequence" exact component={ PracticePreview } />
                <SubMenu active="practice" {...this.props}/>
            </React.Fragment>
        );

    }

}

export default PracticeDiscover;
