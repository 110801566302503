import SecurityModel from '../data/models/security/security';

//actions
const GET_ROLE =  'GET_ROLE';

export const getRole = () => async dispatch => {

    try {

        const response = await SecurityModel.checkJwt();

        let payload = {
            role: response.data.user.role,
            premiumExpirationDate: response.data.user.premiumExpireDate,
            hasActiveSubscription: response.data.user.hasActiveSubscription,
            attributes: response.data.user.attributes
        };

        dispatch ({ type: GET_ROLE, payload: payload });

    } catch (errorCode) {

        dispatch ({ type: GET_ROLE, payload: {} });
        throw errorCode;

    }

};

const INITIAL_STATE = {
    role: [],
    premiumExpirationDate: '',
    hasActiveSubscription: false,
    attributes: {}
};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case GET_ROLE:

            return { ...state, ...action.payload };

        default:

            return state;

    }

};

export default reducer;