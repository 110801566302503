
//libraries and components libraries
import React, { useMemo } from 'react'
import _ from 'lodash'
import I18n from '../../../../i18n'

//styled components
import OButton from '../../../styled/components/button'
import { CartDetail, CartDetailWrap, DetailAmount } from './styles'

//use meomization to avoid recreate geTotal and getTotalOfProductsWithCreditApplied functions on each re-render.
export function CartDetails({ desktop = false, mobile= false, cb, showSubTotal = false, currency, addedProducts = [], productsWithCreditApplied = []}) {

    const getTotal = useMemo(() => {

        return () => {

            let total = 0;

            _.each(addedProducts, el => {

                total += !_.isEmpty(el.discountedPrices) ? +el.discountedPrices[currency]: +el.prices[currency]

            });

            const getRawTotal = () => _.round(total, 2);

            const getTotalWithCurrency = () => currency === 'USD' ? `$${_.round(total, 2)}` :  `${_.round(total, 2)}€`;

            return {
                getTotalWithCurrency,
                getRawTotal
            }

        }

    }, [addedProducts, currency]);

    const getTotalOfProductsWithCreditApplied = useMemo(() => {

        return () => {
      
            let total = 0;

            if (productsWithCreditApplied) {

                _.each(productsWithCreditApplied, ({productId}) => {

                    let addedProductIndex = _.findIndex(addedProducts, (addedProduct) => addedProduct._id === productId);

                    if (addedProductIndex > -1 ) {

                        total += !_.isEmpty(addedProducts[addedProductIndex].discountedPrices) ? +addedProducts[addedProductIndex].discountedPrices[currency]: +addedProducts[addedProductIndex].prices[currency]

                    }
        
                });

            }

            const getRawTotal = () => _.round(total, 2);

            const getTotalWithCurrency = () => currency === 'USD' ? `$${_.round(total, 2)}` :  `${_.round(total, 2)}€`;

            return {
                getTotalWithCurrency,
                getRawTotal
            }

        } 

    }, [addedProducts, currency, productsWithCreditApplied]);
    
    return (
        <>
            <CartDetailWrap desktop={desktop} mobile={mobile}>
                <CartDetail>

                    {
                        showSubTotal && 
                            <>
                                <DetailAmount>{I18n.t('studio.subtotal')} ({addedProducts.length > 1 ? I18n.t('studio.products', {products: addedProducts.length}) : I18n.t('studio.product', {products: addedProducts.length})} ): {getTotal().getTotalWithCurrency()}</DetailAmount>
                                <DetailAmount>{I18n.t('studio.freeAsanasMonthly')}: -{getTotalOfProductsWithCreditApplied().getTotalWithCurrency()} </DetailAmount>
                            </>
                    }

                    {
                        showSubTotal ? 
                        <DetailAmount>{I18n.t('studio.total')} ({addedProducts.length > 1 ? I18n.t('studio.products', {products: addedProducts.length}) : I18n.t('studio.product', {products: addedProducts.length})}): { currency === 'USD' ? `$${_.round(getTotal().getRawTotal() - getTotalOfProductsWithCreditApplied().getRawTotal(),2)}`:`${_.round(getTotal().getRawTotal() - getTotalOfProductsWithCreditApplied().getRawTotal(),2)}€`} </DetailAmount> :
                        <DetailAmount>{I18n.t('studio.total')} ({addedProducts.length > 1 ? I18n.t('studio.products', {products: addedProducts.length}) : I18n.t('studio.product', {products: addedProducts.length})}): {getTotal().getTotalWithCurrency()}</DetailAmount>
                    }

                <OButton fluid className="s-cart-btn" primary color="#fff" upper type="button" onClick={cb} disabled={addedProducts.length === 0}>
                    <span>{I18n.t('studio.finalizeBuy')}</span>
                </OButton>
                </CartDetail>
            </CartDetailWrap>
        </>
    )

}
