import React, { useState } from 'react';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import { FrameCheckBox, OCheckBox } from './styles';
import OButton from '../../../../styled/components/button';
import { FrameClose, FrameModal, ModalContent, Actions } from '../../styles/genericModal';
import I18n from '../../../../../i18n';


/***
 * * SHARED DATA ACCEPT MODAL
 * 
 * @param buttons Array Objects
 * @param onClose Function
 * @params @buttons { text, callback, options = { any button props } }
 * @description Modal to ask to the user to accept the biometric matrix data required for the adivsor
 */

export default function ShareDataAcceptModal({ buttons = [], onClose, ...restProps }) {

    const [accepted, acceptReadGrant] = useState(false);

    return (
        <FrameModal>
            <FrameClose>
                <Icon name="close" size="large" onClick={ onClose }/>
            </FrameClose>
            <ModalContent>
                <p className="heading-2">{I18n.t('modals.sharedDataModal.title')}</p>
                <p className="caption regular" dangerouslySetInnerHTML={{__html: I18n.t('modals.sharedDataModal.description')}}></p>
                <FrameCheckBox>
                    <OCheckBox 
                        label={I18n.t('modals.sharedDataModal.checkboxText')}
                        onChange={(e, v) => acceptReadGrant(v.checked)}
                        checked={accepted}
                        />
                </FrameCheckBox>
                <Actions>
                    {
                        _.map(buttons, ({text, callback, options }, index) => (
                            <OButton disabled={!accepted} key={index} type="button" {...options} onClick={ callback }><span>{text}</span></OButton>
                        ))
                    }
                </Actions>
            </ModalContent>
        </FrameModal>
    )

}