import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Field, reduxForm  } from 'redux-form';
import { Form } from 'semantic-ui-react';
import SecurityModel from '../../../../data/models/security/security';
import sideBarProFileHoc from '../../../components/sidebar/profile';
import Footer from '../../../components/footer';
import CustomInput from '../../../components/form/input';
import I18n from '../../../../i18n';
import { openToast } from '../../../../redux-store/toast';
import '../profile_new.scss';
import TopBar from '../../../components/navbar/topbar';
import MenuProfile from '../../../components/menu/profile';
import OButton from '../../../styled/components/button';

class UserSecurity extends React.Component {

    onChangePassword = async credentials => {
        
        try {
            
            const response = await SecurityModel.changePassword({ currentPassword: credentials.currentPassword, newPassword: credentials.newPassword });

            if (response.status === 200) {

                openToast({message: I18n.t('messages.passwordChanged'), type: 'success'});

            }

        } catch (errorCode) {
            
            openToast({message: I18n.t(`status.code.${errorCode}`), type: 'error'});

        }

    }

    render() {

        return (
            <React.Fragment>

                <div className="p-wrapper">
                    <TopBar {...this.props} menuCallback={()=> this.props.onToggle()} showProfile={false} text={I18n.t('profile.securityEscaped')} invert={true}/>
                    <div className="inner">
                        <div className="p-flex">
                            <div className="p-aside">
                                <MenuProfile active="security" {...this.props} />
                            </div>
                            <div className="p-content">
                                <div className="a-wrapper">
                                    <div className="a-f-wrapper">
                                        <Form name="profileSecurityForm" noValidate onSubmit={this.props.handleSubmit(this.onChangePassword)}>
                                            <div className="verticalPad">
                                                <Field
                                                    component={ CustomInput }
                                                    placeholder={ I18n.t('profile.currentPassword') }
                                                    name="currentPassword"
                                                    fieldClasses="y-input default small"
                                                    label={ I18n.t('profile.currentPassword').toUpperCase() }
                                                    type="password" />
                                            </div>
                                            <div className="verticalPad">
                                                <Field
                                                    component={ CustomInput }
                                                    placeholder={ I18n.t('profile.newPassword') }
                                                    name="newPassword"
                                                    fieldClasses="y-input default small"
                                                    label={ I18n.t('profile.newPassword').toUpperCase() }
                                                    type="password" />
                                            </div>
                                            <div className="verticalPad">
                                                <Field
                                                    component={ CustomInput }
                                                    placeholder={ I18n.t('profile.confirmPassword') }
                                                    name="verifyPassword"
                                                    fieldClasses="y-input default small"
                                                    label={ I18n.t('profile.confirmPassword').toUpperCase() }
                                                    type="password" />
                                            </div>
                                            <div className="verticalPad">
                                                <OButton upper type="submit" color="#FFF" primary>
                                                    <span>{I18n.t('actions.save')}</span>
                                                </OButton>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer {...this.props} type="branded"/>
                </div>
            </React.Fragment>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.currentPassword)) {

        errors.currentPassword = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.verifyPassword)) {

        errors.verifyPassword = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.newPassword)) {

        errors.newPassword = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.verifyPassword) && !_.isEmpty(formValues.newPassword) && !_.isEqual(formValues.verifyPassword, formValues.newPassword) ) {

        errors.verifyPassword = I18n.t('validations.passwordNotMatch');

    }

    return errors;

};

const Wrapped = sideBarProFileHoc(UserSecurity, 'security');

export default reduxForm({
    form: 'profileSecurityForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    enableReinitialize: true,
    initialValues: {
        currentPassword: '',
        newPassword: '',
        verifyPassword: ''
        
    }
})(connect(null, { })(Wrapped));