import React, { Component } from 'react';
import Slider from "react-slick";
import { Responsive } from 'semantic-ui-react';

import Slider1_bg_2 from '../../../../assets/img/onboard/slide_1_bg_2.png';
import Slider1_bg_2_tablet from '../../../../assets/img/onboard/slide_1_bg_2_tablet.png';
import Slider1_bg_3 from '../../../../assets/img/onboard/slide_1_bg_3.png';

import Slider2_bg_3 from '../../../../assets/img/onboard/slide_2_bg_3.png';
import Slider2_bg_3_tablet from '../../../../assets/img/onboard/slide_2_bg_3_tablet.png';

import Slider3_bg_3 from '../../../../assets/img/onboard/slide_3_bg_3.png';
import Slider3_bg_3_tablet from '../../../../assets/img/onboard/slide_3_bg_3_tablet.png';

import Slider4_bg_3 from '../../../../assets/img/onboard/slide_4_bg_3.png';
import Slider4_bg_3_tablet from '../../../../assets/img/onboard/slide_4_bg_3_tablet.png';

import Slider5_bg_3 from '../../../../assets/img/onboard/slide_5_bg_3.png';
import Slider5_bg_3_tablet from '../../../../assets/img/onboard/slide_5_bg_3_tablet.png';

import I18n from '../../../../../i18n';

import './onboarding.scss';

function PrevArrow(props) {

    const { onClick, className } = props;

    return (
        <button type="button" className={"onboard-slick-custom-btn prev-a " + className} onClick={onClick}>
            <svg alt="" color="black" role="img" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" style={{ height: '40px', width: '40px', zIndex: 'auto' }}>
                <path fill="black" d="M22.324 28.008c.537.577.355 1.433-.326 1.809a1.44 1.44 0 0 1-.72.183c-.398 0-.786-.151-1.048-.438L10.06 18.588a1.126 1.126 0 0 1 0-1.555L20.233 6.09c.438-.468 1.198-.564 1.767-.25.681.377.863 1.23.325 1.808l-9.446 10.164 9.446 10.196zM11.112 17.615a.31.31 0 0 1 0 .391l.182-.195-.182-.196zM21.308 7.094c-.01-.006-.053 0-.029-.027a.07.07 0 0 0 .029.027zm-.025 21.499a.95.95 0 0 1-.006-.008l.006.008z"></path>
            </svg>
        </button>
    );

}

function NextArrow(props) {

    const { onClick, className, currentSlide } = props;

    return (
        <button type="button" className={"onboard-slick-custom-btn next-a " + className + " " + (currentSlide === 0 ? 'hide-arrow' : '')} onClick={onClick}>
            <svg alt="" color="black" role="img" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" style={{ height: '40px', width: '40px', zIndex: 'auto' }}>
                <path fill="black" d="M13.065 7.65c-.538-.578-.355-1.433.325-1.81a1.44 1.44 0 0 1 .72-.182c.398 0 .786.15 1.048.437L25.327 17.07a1.126 1.126 0 0 1 0 1.555L15.155 29.568c-.438.468-1.198.563-1.767.25-.681-.377-.863-1.23-.325-1.809l9.446-10.164L13.065 7.65zm11.211 10.393a.31.31 0 0 1 0-.391l-.181.194.181.197zM14.081 28.564c.01.006.053 0 .028.027a.07.07 0 0 0-.028-.027zm.024-21.5a.95.95 0 0 1 .007.008l-.007-.007z" ></path>
            </svg>
        </button>
    );

}

class Onboarding extends Component {

    constructor(props) {

        super(props);
        this.slider = null;
        this.state = {
            currentSlide: 0
        }

    }

    onSkip = () => {

        window.localStorage.setItem('onboarding', 'viewed');
        this.props.history.push('/auth/register');

    }

    render() {

        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            centerMode: false,
            dotsClass: 'dots-list',
            beforeChange: (prev, next) => this.setState({ currentSlide: next }),
            appendDots: dots => <ul style={{ display: (this.state.currentSlide === 0 ? 'none' : 'flex') }}> {dots} </ul>,
            customPaging: i => <div>{i}</div>
        };

        const { currentSlide } = this.state;

        return (

            <div className={`wrapper-slick-slider bg-slide-${currentSlide}`}>
                <div className="skip-btn" onClick={this.onSkip}>{I18n.t('actions.ommit')}</div>
                <Slider {...settings} ref={slider => (this.slider = slider)}>
                    <div className="onboard-layer ">
                        <div className="onboard-wrapper">
                            <div className="onboard-header long">
                                <Responsive maxWidth={1023} as={React.Fragment}><img src={Slider1_bg_2} alt="" /></Responsive>
                                <Responsive minWidth={1024} as={React.Fragment}><img className="slide-tablet" src={Slider1_bg_2_tablet} alt="" /></Responsive>
                                <img className="slider-bg-secondary" src={Slider1_bg_3} alt="" />
                            </div>
                            <div className="onboard-body">
                                <div className="onboard-title">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.title1') }}></p>
                                </div>
                                <div className="onboard-description">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.description1') }}></p>
                                </div>
                                <span className="normal-btn" onClick={() => this.slider.slickGoTo(1)}>{I18n.t('actions.begin')}</span>
                                <div className="onboard-login">
                                    <p>{I18n.t('onboard.isMember')} <span onClick={() => this.props.history.push('/auth/login')}>{I18n.t('onboard.logIn')}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="onboard-layer">
                        <div className="onboard-wrapper">
                            <div className="onboard-header long">
                                <Responsive maxWidth={1023} as={React.Fragment}><img src={Slider2_bg_3} alt="" /></Responsive>
                                <Responsive minWidth={1024} as={React.Fragment}><img className="slide-tablet" src={Slider2_bg_3_tablet} alt="" /></Responsive>
                            </div>
                            <div className="onboard-body">
                                <div className="onboard-title short">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.title2') }}></p>
                                </div>
                                <div className="onboard-subtitle">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.subtitle1') }}></p>
                                </div>
                                <div className="onboard-description">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.description2') }}></p>
                                </div>
                                <span className="body-nxt-btn" onClick={() => this.slider.slickGoTo(2)}>{ I18n.t('actions.next') }</span>
                            </div>
                        </div>
                    </div>
                    <div className="onboard-layer">
                        <div className="onboard-wrapper">
                            <div className="onboard-header long">
                                <Responsive maxWidth={1023} as={React.Fragment}><img src={Slider4_bg_3} alt="" /></Responsive>
                                <Responsive minWidth={1024} as={React.Fragment}><img className="slide-tablet" src={Slider4_bg_3_tablet} alt="" /></Responsive>
                            </div>
                            <div className="onboard-body">
                                <div className="onboard-title short">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.title4') }}></p>
                                </div>
                                <div className="onboard-subtitle">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.subtitle3') }}></p>
                                </div>
                                <div className="onboard-description">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.description4') }}></p>
                                </div>
                                <span className="body-nxt-btn" onClick={() => this.slider.slickGoTo(3)}>{ I18n.t('actions.next') }</span>
                            </div>
                        </div>
                    </div>
                    <div className="onboard-layer">
                        <div className="onboard-wrapper">
                            <div className="onboard-header long">
                                <Responsive maxWidth={1023} as={React.Fragment}><img src={Slider5_bg_3} alt="" /></Responsive>
                                <Responsive minWidth={1024} as={React.Fragment}><img className="slide-tablet" src={Slider5_bg_3_tablet} alt="" /></Responsive>
                            </div>
                            <div className="onboard-body">
                                <div className="onboard-title short">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.title5') }}></p>
                                </div>
                                <div className="onboard-subtitle">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.subtitle5') }}></p>
                                </div>
                                <div className="onboard-description">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.description5') }}></p>
                                </div>
                                <span className="body-nxt-btn" onClick={() => this.slider.slickGoTo(4)}>{ I18n.t('actions.next') }</span>
                            </div>
                        </div>
                    </div>
                    <div className="onboard-layer">
                        <div className="onboard-wrapper">
                            <div className="onboard-header long">
                                <Responsive maxWidth={1023} as={React.Fragment}><img src={Slider3_bg_3} alt="" /></Responsive>
                                <Responsive minWidth={1024} as={React.Fragment}><img className="slide-tablet" src={Slider3_bg_3_tablet} alt="" /></Responsive>
                            </div>
                            <div className="onboard-body">
                                <div className="onboard-title short">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.title3') }}></p>
                                </div>
                                <div className="onboard-subtitle">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.subtitle2') }}></p>
                                </div>
                                <div className="onboard-description">
                                    <p dangerouslySetInnerHTML={{ __html: I18n.t('onboard.description3') }}></p>
                                </div>
                                <span className="normal-btn" onClick={this.onSkip}>{I18n.t('actions.weStart')}</span>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        );

    }

}

export default Onboarding;