import React from 'react';
import _ from 'lodash';
import ReCAPTCHA from "react-google-recaptcha";
import GradientNav from '../../../components/navbar/gradient';
import ExtraTopBar from '../../../components/navbar/extraTopBar';

import { Form, Responsive } from 'semantic-ui-react';
import { Field, reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';
import CustomInput from '../../../components/form/input';

import CustomSelect from '../../../components/form/select';
import CustomTextArea from '../../../components/form/textarea';

import { openToast } from '../../../../redux-store/toast';
import { openLoader, closeLoader } from '../../../../redux-store/loader';
import QuestionModel from '../../../../data/models/question/question';
import arrowBackPink from '../../../assets/img/arrow-pink.svg'; 

import { countries }  from '../../../../config/constants/countries';

import '../faq.scss';
import I18n from '../../../../i18n';
import OButton from '../../../styled/components/button';

const recaptchaRef = React.createRef();

class FaqFormPublic extends React.Component {

    _isMounted = false;

    constructor(props){
        super(props);
        
        this.state = {

            activeForm: false,
            categories : []
        }
    }

    componentDidMount() {

        this._isMounted = true;
        this.getData();
       
    }

    componentWillUnmount() {

        this._isMounted = false;

    }

    getData = async () => {

        try {

            this.props.openLoader();
            const categoriesResponse = await QuestionModel.getCategories();

            if (categoriesResponse.status === 200) {

                let categories = _.map(categoriesResponse.data || [] , category => {

                    return  {
                        key: category._id,
                        text: category.label,
                        value: category._id
                    }

                });

                if (this._isMounted) {

                    this.setState({categories});

                    this.props.dispatch(initialize(
                        'faqFormPublic',
                        {   
                            name: '',
                            userId: '',
                            title: '',
                            text: '',
                            category: this.props.match.params.categoryId || _.get(categories, `[0].value`, ''),
                            country: 'es'
                        }
                    ));

                }
                
            }
            
        } catch (error) {

            openToast({message: I18n.t(`status.code.401`), type: 'error'});
            
        } finally {

            this.props.closeLoader();

        }

    }

    handleSubmit = async formFields => {

        const recaptchaValue = recaptchaRef.current.getValue();

        if (recaptchaValue) {

            try {

                const response = await QuestionModel.createAnswer(formFields);
    
                if (response.status === 200) {
    
                    openToast({message: I18n.t('messages.messageSent'), type: 'success'});
    
                }
                
            } catch (error) {
    
                openToast({message: I18n.t(`status.code.401`), type: 'error'});
                
            } finally {
    
                this.props.reset('faqFormPublic');
    
            }
    
        }
       
    }

    onChangeCaptcha = value => {

        this.setState({activeForm: !!value});

    }

    gotoLogin = () => {

        this.props.history.push(`/auth/login`);
        
    };

    render() {

        return (
            <React.Fragment>
                { window.localStorage.getItem('jwt') && <GradientNav active="" {...this.props} /> }
                {!localStorage.getItem('jwt') && <ExtraTopBar onClick={ this.gotoLogin } className="register" hasButton={true}/>}
                    <div className={"faq-wrapper " + (!window.localStorage.getItem('jwt') ? 'hasExtraTopBar' : '')}>
                        <div className="inner">
                            <div className="center-content">
                                <h1>{I18n.t('faq.howToHelpYou')}</h1>
                                <div className="go-back-desktop no-padding" onClick={()=>this.props.history.goBack()}>
                                    <span className="back" ><img src={arrowBackPink} alt="" /></span><span className="back-text">{I18n.t('actions.back')}</span>
                                </div>
                                <div className="go-back-mobile no-padding" onClick={()=> this.props.history.goBack()}>
                                    <span className="back" ><img src={arrowBackPink} alt="" /></span>
                                </div>
                                <p dangerouslySetInnerHTML={{__html: I18n.t("faq.title")}}></p>
                            </div>
                            
                            <Form name="faqFormPublic" onSubmit={this.props.handleSubmit(this.handleSubmit)} >
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomInput }
                                        name='userId'
                                        fieldClasses="y-input default small"
                                        label={ I18n.t("auth.email") } />
                                </div>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomInput }
                                        name='name'
                                        fieldClasses="y-input default small"
                                        label={ I18n.t("auth.name") } />
                                </div>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomSelect }
                                        name='country'
                                        fieldClasses="y-select"
                                        options={countries}
                                        search={true}
                                        label={ I18n.t("auth.country") } />
                                </div>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomSelect }
                                        name='category'
                                        fieldClasses="y-select"
                                        options={this.state.categories}
                                        label={ I18n.t("faq.type") } />
                                </div>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomInput }
                                        name='title'
                                        fieldClasses="y-input default small"
                                        label={ I18n.t("faq.subject") } />
                                </div>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomTextArea }
                                        name='text'
                                        fieldClasses="y-input default small"
                                        label={ I18n.t("faq.description") } />
                                </div>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LeuyvIUAAAAAH10pcg0jS9x8YHTBasE9sV3rcYo"
                                    onChange={this.onChangeCaptcha}
                                    className="captcha"
                                />
                                <div className="verticalPad t-center">
                                    <Responsive minWidth={421}>
                                        <OButton type="submit" disabled={!this.state.activeForm} primary upper size="huge"><p>{I18n.t('auth.send')}</p></OButton>
                                    </Responsive>
                                    <Responsive maxWidth={420}>
                                        <OButton type="submit" disabled={!this.state.activeForm} primary upper ><p>{I18n.t('auth.send')}</p></OButton>
                                    </Responsive>
                                </div>
                            </Form>
                        </div>
                    </div>
            </React.Fragment>
        );

    }

}


const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.name)) {

        errors.name = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.userId)) {

        errors.userId = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.title)) {

        errors.title = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.text)) {

        errors.text = I18n.t('validations.required');

    }

    return errors;

};

export default reduxForm({
    form: 'faqFormPublic',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        name: '',
        userId: '',
        title: '',
        question: '',
        category: 0,
        country: 'es'
    }

})(connect(null, { openToast, openLoader, closeLoader  })(FaqFormPublic));