import styled from 'styled-components/macro';

const ChallengeFrame = styled.div`

    z-index: 5;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    padding: 50px 0;
    overflow: hidden;
    background-image: url(${props => props.src});
    div {
        position: relative;
        z-index: 10;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: -moz-linear-gradient(left, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(left, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to right, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
    }

    @media (max-width: 920px) {
        --challenge-margin-left:50px;
    }
    @media (max-width: 760px) {
        --challenge-margin-left:20px;
    }

    @media (max-width: 420px) {
        display: flex;
        flex-direction: column;
        padding: 20px 0 ;
    }

`;

const HeaderContent = styled.div`
        margin-left: var(--challenge-margin-left);

`;

const HeaderLabel = styled.span`
    
    margin-left: 0;
    background-color: var(--y-green1);
    margin-top: 15px;
    padding: 10px 15px;
    border-radius:5px;
    display:inline-block;
`;

const HeaderTitle = styled.div`
    padding: 10px 20px 10px var(--challenge-margin-left);
    color:white;
    display: inline-block;
    line-height: 38px;
    max-width: 640px;
    margin-top: 60px;
    @media only screen and (max-width: 760px) {
        max-width: 100%;
        display: block;
        padding: 20px 20px 20px var(--challenge-margin-left);
    }

    
`;
const HeaderStats = styled.div`
    margin-top: 60px;
    margin-left: var(--challenge-margin-left);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 638px;
    font-family: PoppinsSemiBold;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.015em;
    color: var(--grey6);
    position: relative;
    z-index: 10;
    span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (max-width: 760px) {
        font-size: 16px;
        line-height: 22px;
    }
    @media (max-width: 662px) {
        font-size: 12px;
        line-height: 12px;
        margin-right: 20px;
    }
`;

const BodyStats = styled.div`
    margin-left: var(--challenge-margin-left);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 638px;
    font-family: PoppinsSemiBold;
    font-size: 48px;
    line-height: 48px;
    color: var(--grey6);
    border: 3px solid var(--grey6);
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 5px;
    span:not(:last-child){
        border-right: solid 3px var(--grey6);
        
    }
    span{
        padding: 5px 20px;
        text-align: center;
        @media (max-width: 760px) {
            padding: 10px;
        }
        @media (max-width: 420px) {
            font-size: 24px;
            padding: 5px;
        }
    }

    @media (max-width: 760px) {
        font-size: 34px;
        line-height: 34px;
        margin-right: 20px;
    }
`;

export {
    ChallengeFrame,
    HeaderContent,
    HeaderLabel,
    HeaderTitle,
    HeaderStats,
    BodyStats
}