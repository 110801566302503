import _ from 'lodash';

import ProductModel from '../data/models/product/product';

import Config from '../config';

// Types
const ADD_TO_CART = 'ADD_CART';
const GET_CART_ITEMS = 'GET_CART_ITEMS';
const REMOVE_CART_ITEMS = 'REMOVE_CART_ITEMS';

// Actions

export const removeToCart = itemId => async (dispatch, getState) => {

    let itemsAdded = [];
    let products = _.cloneDeep(getState().cart.products || []);
    let selectedIndex = _.findIndex(products, ({_id}) => _id === itemId);

    if (selectedIndex > -1) {

        products[selectedIndex].added = false;

        if (window.localStorage.getItem('itemsAdded')) {

            itemsAdded = JSON.parse(localStorage.getItem('itemsAdded'));

        }

        let selectedIndexStorage = _.findIndex(itemsAdded, id => id === itemId);
        itemsAdded.splice(selectedIndexStorage, 1);
        window.localStorage.setItem('itemsAdded', JSON.stringify(_.uniq(itemsAdded)));

    }

    dispatch ({ type: ADD_TO_CART, payload: products })

};

export const addToCart = itemId => async (dispatch, getState) => {

    let itemsAdded = [];
    let products = _.cloneDeep(getState().cart.products || []);
    let selectedIndex = _.findIndex(products, ({_id}) => _id === itemId);

    if (selectedIndex>-1) {

        products[selectedIndex].added = true;

        if (window.localStorage.getItem('itemsAdded')) {

            itemsAdded = JSON.parse(localStorage.getItem('itemsAdded'));

        }

        itemsAdded.push(itemId);
        window.localStorage.setItem('itemsAdded', JSON.stringify(_.uniq(itemsAdded)));

    }

    dispatch ({ type: ADD_TO_CART, payload: products })

};

export const getMarketplace = () => async dispatch => {

    let itemsAdded = [];
    if (window.localStorage.getItem('itemsAdded')) {

        itemsAdded = JSON.parse(localStorage.getItem('itemsAdded'));

    }

    let userProducts = []
    let products = [];

    try {

        const _products = await ProductModel.getProducts();
        const _userProducts = await ProductModel.getUserProducts();

        products = _products.data || [];
        userProducts = _userProducts.data || [];

        _.each(products, ({ _id }, index)=> {

            products[index].buyed = _.findIndex(userProducts, ({ productId }) => productId === _id )>-1;
            const idx = _.findIndex(itemsAdded, id => id === _id);

            if (products[index].buyed && idx >-1) {

                itemsAdded.splice(idx, 1);
                window.localStorage.setItem('itemsAdded', JSON.stringify(itemsAdded));

            }

            products[index].added = itemsAdded.includes(_id);

            if (window.cordova) {

                delete products[index].discount;
                delete products[index].discountedPrices;
                products[index].prices = { USD: Config.inapppurchase.schoolProducts['1'].price.USD[window.device.platform], EUR: Config.inapppurchase.schoolProducts['1'].price.EUR[window.device.platform] };

            }

        });

        dispatch({ type: GET_CART_ITEMS, payload: products });

    } catch (error) {

        dispatch({ type: GET_CART_ITEMS, payload: [] });

    }

};

// Reducer
const INITIAL_STATE = {
    products: []
};

function reducer (state = INITIAL_STATE, action) {

    switch (action.type) {

        case ADD_TO_CART:
        case GET_CART_ITEMS:
        case REMOVE_CART_ITEMS:
            return { ...state, products: [...action.payload] };

        default:

            return state;

    }

};

export default reducer;