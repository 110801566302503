import _ from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Modal, Form, Radio} from 'semantic-ui-react';
import Moment from 'moment';

import { closeExpiredModal } from '../../../redux-store/expiredModal';

import Utils from '../../../utils';
import { getOneYearPaymentPromotionPrice, getSubscriptionPrices, hasOneYearPaymentPromotion, hasMonthSubscriptionPromotion } from '../../../utils/checkPromotions';

import history from '../../../history';

import './expiredModal.scss';

//TODO literales

const expiredTime = 1000 * 60 * 60 * 24;

class ExpiredModal extends Component {

    constructor(props) {

        super(props);

        this.state = {
            quote: 'annualPayment',
            expired: true
        };

    }

    handleChange = (e, { value }) => this.setState({ quote: value });

    moreInfo = isExpired => {

        this.setExpiredTime(isExpired);
        this.props.closeExpiredModal();
        hasOneYearPaymentPromotion() ? history.push('/profile/promotion') : history.push('/profile/plans');

    }

    onAcceptPremium = isExpired => {

        this.setExpiredTime(isExpired);

        const price = this.state.quote === 'annualPayment' && hasOneYearPaymentPromotion() ? getOneYearPaymentPromotionPrice() : getSubscriptionPrices()[this.state.quote];
        const periodic = !hasOneYearPaymentPromotion();
        const description = 'Producto Premium';
        const data = window.btoa(JSON.stringify(price) + '/' + description + '/' + this.state.quote + '/' + periodic);

        this.props.closeExpiredModal();
        history.push(`/payment/${data}`);

    }

    setExpiredTime = isExpired => {

        let key = 'oneWeekEnd';
        let ttl = expiredTime * 8;

        if (isExpired) {

            key = 'expiredTime';
            ttl = expiredTime;

        }

        const now = new Date();
        const item = {
            expiry: now.getTime() + ttl
        }

        window.localStorage.setItem(key, JSON.stringify(item));

    }

    getExpiredTime = key => {

        const itemStr = window.localStorage.getItem(key);

        if (!itemStr) {

            return null;

        }

        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiry) {

            window.localStorage.removeItem(key);
            return null;

        }

        return true;

    }

    onCancel = isExpired => {

        this.setExpiredTime(isExpired);
        this.props.closeExpiredModal();

    }

    render() {

        const { quote } = this.state;
        const { role, auth, currency } = this.props;
        const roles = _.get(role || {}, 'role', []);

        const remainingDays = 15 - Moment().diff(Moment(auth.userSession.createdAt), 'days');
        const premiumExpiredOrNextTo = !_.isEmpty(roles) && (!roles.includes('premium') || (role.premiumExpirationDate !== 'NOT_EXPIRE' && Moment(role.premiumExpirationDate).startOf('day').diff(Moment().startOf('day'), 'days') < 30));
        const isExpired = premiumExpiredOrNextTo && remainingDays === 0 && !this.getExpiredTime('expiredTime');
        const isMoreOneWeek = premiumExpiredOrNextTo && remainingDays < 8 && remainingDays > 0 && !this.getExpiredTime('oneWeekEnd');

        const annualPaymentWithoutDiscount = { USD: 9.99 * 12, EUR: 8.99 * 12 };

        return !role.hasActiveSubscription && (isExpired || isMoreOneWeek) && (
            <Modal open={ this.props.modal.open || false } className="expired-modal" size="small">
                <Modal.Header className="top-header-block">{!isExpired && <i className="f-icon-cancel" onClick={()=> this.onCancel(isExpired)}></i>}</Modal.Header>
                <Modal.Content>
                    <Modal.Description className="description-block">
                        { isExpired &&
                        <><p className="description-title">TU CUENTA HA EXPIRADO</p>
                        <p className="description-title">Para continuar</p>
                        </>}
                        {!isExpired && isMoreOneWeek &&
                        <>
                            {Math.floor(remainingDays) === 1 ? <p className="description-title">SOLO TE QUEDA {Math.floor(remainingDays)} DÍA <br/>DE PREMIUM GRATIS</p> : <p className="description-title">SOLO TE QUEDAN {Math.floor(remainingDays)} DÍAS <br/>DE PREMIUM GRATIS</p>}
                        </>}
                        <p className="description-title">Elige tu plan:</p>
                    </Modal.Description>
                    <div className="expired-cards">
                       <div className="expired-cards__item">
                            <div className="expired-cards__header">
                                <div className="expired-cards__title">
                                    <span>premium</span>
                                </div>
                                {(hasOneYearPaymentPromotion() || hasMonthSubscriptionPromotion()) && <div className="expired-cards__corner">
                                    <div className="expired-cards__corner-text">
                                        PROMO
                                    </div>
                                </div>}
                            </div>
                            <div className="expired-cards__body">
                                <div className="expired-cards__description">
                                    <span>Disfruta todas las ventajas y privilegios Yogabot PREMIUM, <b>sin límites</b></span>
                                </div>
                            </div>
                            <div className="expired-cards__details">
                                <ul>
                                    <li>Actualiza tu Matriz Biométrica y tu práctica cada <b>7 días</b></li>
                                    <li>Secuencias en videos. <b>Configuración total</b></li>
                                    <li>Nuevas contenidos y programas cada mes. <b>Acceso completo</b></li>
                                </ul>
                            </div>
                            <div className="expired-cards__description expired-cards__description--grey">
                                <p>Elige como quieres pagarlo:</p>
                            </div>
                            <div className="expired-cards__options">
                            <Form>
                                <Form.Field className="border-bottom super-vertical">
                                    <Radio
                                        label=''
                                        name='radio-options'
                                        value='monthlyPayment'
                                        checked={ quote === 'monthlyPayment' }
                                        onChange={ this.handleChange }
                                    />
                                    { (hasOneYearPaymentPromotion() && !hasMonthSubscriptionPromotion()) && <div className="expired-cards__option">
                                        <div>Mensual:</div>
                                        <span>{Utils.getAmountLiteral(getSubscriptionPrices().monthlyPayment[currency], currency)} &nbsp;<b>{ Utils.getAmountLiteral(annualPaymentWithoutDiscount[currency], currency) } al año</b></span>
                                    </div>}
                                    { (!hasOneYearPaymentPromotion()) && <div className="expired-cards__option">
                                        <div>Mensual:</div>
                                        <span><b>{Utils.getAmountLiteral(getSubscriptionPrices().monthlyPayment[currency], currency)} al mes</b></span>
                                    </div>}
                                </Form.Field>
                                <Form.Field >
                                    <Radio className="super-vertical"
                                        label=''
                                        name='radio-options'
                                        value='annualPayment'
                                        checked={ quote === 'annualPayment'}
                                    />
                                    {hasOneYearPaymentPromotion() && <div className="expired-cards__option">
                                        <div>Anual: &nbsp;<i className="cbrand">Ahorra { Utils.getAmountLiteral(_.round(annualPaymentWithoutDiscount[currency] - getOneYearPaymentPromotionPrice()[currency]), currency) }</i></div>
                                        <span className="strikethrough">{ Utils.getAmountLiteral(annualPaymentWithoutDiscount[currency], currency) } </span>&nbsp;<b>{ Utils.getAmountLiteral(getOneYearPaymentPromotionPrice()[currency], currency) } al año</b>
                                    </div>}
                                    {!hasOneYearPaymentPromotion() && <div className="expired-cards__option">
                                        <div>Anual: &nbsp;<i className="cbrand">Ahorra { 100 - _.round(getSubscriptionPrices().annualPayment[currency] / annualPaymentWithoutDiscount[currency] * 100) }%</i></div>
                                        <span><b>{Utils.getAmountLiteral(getSubscriptionPrices().annualPayment[currency], currency)} al año</b></span>
                                    </div>}
                                </Form.Field>
                            </Form>
                            </div>
                            <div className="expired-cards__footer">
                                <span onClick={()=> this.onAcceptPremium(isExpired)}>ELIJO PREMIUM</span>
                            </div>
                            <div className="expired-cards__info" onClick={()=> this.moreInfo(isExpired)}>Más información</div>
                       </div>

                       <div className="expired-cards__item">
                            <div className="expired-cards__header">
                                <div className="expired-cards__title">
                                    <span>BÁSICO</span>
                                </div>
                            </div>
                            <div className="expired-cards__body">
                                <div className="expired-cards__description">
                                    <span>Gratis con acceso limitado</span>
                                </div>
                            </div>
                            <div className="expired-cards__details">
                                <ul>
                                    <li>Actualiza tu Matriz Biométrica y tu práctica cada <b>21 días</b></li>
                                    <li>Secuencias en <span className="strikethrough">videos</span> imágenes</li>
                                    <li>Nuevas contenidos y programas cada mes. <b>Acceso limitado</b></li>
                                    <li>Con anuncios</li>
                                </ul>

                            </div>
                            <div className="expired-cards__footer">
                                <span onClick={()=> this.onCancel(isExpired)}>ELIJO BÁSICO</span>
                            </div>
                            <div className="expired-cards__info">&nbsp;</div>
                       </div>
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}

const mapStateToProps = state =>  {
    return {
        modal: state.expiredModal,
        auth: state.auth,
        role: _.get(state,'role', {}),
        currency: _.get(state, 'auth.userSession.config.currency', {})
    };
}

export default connect(mapStateToProps, { closeExpiredModal })(ExpiredModal);
