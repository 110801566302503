
import React from 'react';
import { Button } from 'semantic-ui-react';

const CustomButton = props => {

    return <Button
        disabled={ props.disabled || false }
        className={ 'custom-btn ' + props.classes }
        type={ props.type || 'button' }
        onClick={ props.onClick }
    >{ props.firstIcon || '' } { props.text || '' } { props.icon || '' }</Button>;

}

export default CustomButton;