//libraries & components libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Responsive } from 'semantic-ui-react';
import I18n from '../../../../../i18n';

//components
import ErrorMessage from '../../../../components/errorMessage';
import CustomInput from '../../../../components/form/input';

//styled components
import OButton from '../../../../styled/components/button';
import { RecoveryBox, RecoveryCol, RecoveryRow } from '../styled';
import { AuthHeader, AuthIcon, AuthSeparator, AuthTitle, YogabotIcon } from '../../styled';

//models
import SecurityModel from '../../../../../data/models/security/security';

//actions
import { closeLoader, openLoader } from '../../../../../redux-store/loader';

//assets
import yLogoLarge from '../../../../assets/img/logo_large.svg';


class RecoveryStep2 extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            codeError: -1,
            hasError: false

        }

    }

     onRecovery = async payload => {

        const { closeLoader, openLoader, match, history} = this.props;

        let hasError = false;

        try {

            openLoader();
            await SecurityModel.setRecoveryPassword({newPassword: payload.newPassword, token: _.get(match,'params.token', '') });

        } catch (errorCode) {

            hasError = true;
            console.error(errorCode);
            
        } finally {

            closeLoader();

            if (hasError) {

                this.setState({hasError: true, codeError: 'error-recovery'});

            } else {

                history.push(`/auth/recovery/step/3`);
                
            }

        }

    };

    render() {

        const { codeError, hasError } = this.state;

        return (

            <RecoveryRow>
                <RecoveryCol>
                    <RecoveryBox>
                        <AuthHeader column>
                            <Responsive minWidth={560}>
                                <AuthIcon w={240} h={50}>
                                    <YogabotIcon src={yLogoLarge} />
                                </AuthIcon>
                            </Responsive>
                            <Responsive maxWidth={559}>
                                <AuthIcon>
                                    <YogabotIcon />
                                </AuthIcon>
                            </Responsive>
                            <AuthTitle><span>{I18n.t('auth.restoreYourPassword')}</span></AuthTitle>
                        </AuthHeader>
                        <AuthSeparator />
                        <AuthSeparator />
                        <p className="paragraph regular">{ I18n.t("auth.restoreMessage") }</p>
                        <AuthSeparator />
                        <Form name="recoveryStep2Form" onSubmit={this.props.handleSubmit(this.onRecovery)}>
                            <ErrorMessage code={codeError} active={hasError} />
                            <AuthSeparator>
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t("auth.newPassword") }
                                    name="verifyPassword"
                                    fieldClasses="y-input default small"
                                    label={ I18n.t("auth.newPassword") }
                                    type="password" />
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t("auth.verifyPassword") }
                                    name="newPassword"
                                    fieldClasses="y-input default small"
                                    label={ I18n.t("auth.verifyPassword") }
                                    type="password" />
                            </AuthSeparator>
                            <AuthSeparator />
                            <OButton upper type="submit" fluid color="#FFF" primary>
                                <span>{I18n.t('auth.restorePassword')}</span>
                            </OButton>
                        </Form>
                    </RecoveryBox>
                </RecoveryCol>
            </RecoveryRow>

        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.verifyPassword)) {

        errors.verifyPassword = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.newPassword)) {

        errors.newPassword = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.verifyPassword) && !_.isEmpty(formValues.newPassword) && !_.isEqual(formValues.verifyPassword, formValues.newPassword) ) {

        errors.newPassword = I18n.t('validations.passwordNotMatch');

    }

    return errors;

};

export default reduxForm({
    form: 'recoveryStep2Form',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    enableReinitialize: true,
    initialValues: {
        verifyPassword : '',
        newPassword: ''
    }
})(connect(null, { closeLoader, openLoader })(RecoveryStep2));