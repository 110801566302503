// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';

// Components
import Topbar from '../../../../components/navbar/topbar';
import { NumericFormat } from '../../../../components/numericFormat/NumericFormat';
import PaypalButton from '../../../../components/payments/paypal';
import StripeCheckoutForm from '../../../../components/payments/stripe';

// Styled Components
import { WrapperSteps, WrapperStepsContent, BuySteps, CircleStep } from './styled';

// Redux Store
import { openGenericModal, closeGenericModal } from '../../../../../redux-store/genericModal';

// Config
import config from '../../../../../config';

// Utils
import { TrackingService } from '../../../../../utils/TrackingService';

// Locales
import I18n from '../../../../../i18n';

// Styles
import '../../profile_new.scss';
import './styled/checkoutForm.scss';

const stripePromise = loadStripe(config.stripe.publicKey);

class WalletCheckout extends Component {

    constructor(props) {

        super(props);

        this.state = {
            selectedPackage: {}
        };

    }

    componentWillUnmount() {

        const { closeGenericModal } = this.props;

        closeGenericModal();

    }

    componentDidMount() {

        //obtener del localstorage los valores del paquete seleccionado en el anterior paso
        if (window.localStorage.getItem('pk')) {

            this.setState({ selectedPackage: JSON.parse(window.localStorage.getItem('pk')) });

        } else {

            //si no hay nada en el pk mandarlo al paso anterior
           this.showPopup();

        }

    }

    showPopup = () => {

        const { openGenericModal, closeGenericModal } = this.props;

        openGenericModal({
            type: 'simple',
            title: {
                text: I18n.t('messages.alert'),
                classes: ['heading-2']
            },
            description: {
                text: I18n.t('messages.unselectedPackage'),
                classes: ['paragraph', 'regular']
            },
            buttons: [{
                text: I18n.t('actions.understood'),
                callback: ()=> {

                    closeGenericModal();
                    this.props.history.push('/profile/wallet/packages');

                },
                options: {
                    primary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

    }

    onCancel = data => {}

    onError = error => {

        const { openGenericModal, closeGenericModal } = this.props;

        openGenericModal({
            type: 'simple',
            title: {
                text: I18n.t('messages.alert'),
                classes: ['heading-2']
            },
            description: {
                text: I18n.t('auth.paymentErrorSubtitle'),
                classes: ['paragraph', 'regular']
            },
            buttons: [{
                text: I18n.t('actions.understood'),
                callback: () => {

                    closeGenericModal();

                },
                options: {
                    primary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

    }

    onSuccess = affiliation => {

        const { openGenericModal, closeGenericModal } = this.props;

        TrackingService.registerEvent('Purchase', 'purchase', {
            affiliation,
            currency: 'EUR',
            value: this.state.selectedPackage.priceEUR,
            item_list_name: 'Compra de paquete YGB'
        });

        openGenericModal({
            type: 'simple',
            title: {
                text: I18n.t('messages.toast'),
                classes: ['heading-2']
            },
            description: {
                text: I18n.t('checkout.correctPayment'),
                classes: ['paragraph', 'regular']
            },
            buttons: [{
                text: I18n.t('actions.understood'),
                callback: ()=> {

                    closeGenericModal();
                    this.props.history.push('/profile/wallet');

                },
                options: {
                    primary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

        window.localStorage.removeItem('pk');

    }

    render() {

        const { userSession } = this.props;
        const { selectedPackage } = this.state;

        return (
            <div className="p-wrapper">
                <Topbar { ...this.props } showProfile={ false } text={"Comprar YGB"} invert={true} callback={()=> this.props.history.push(`/profile/wallet/packages`)} />
                <WrapperSteps>
                    <WrapperStepsContent>
                        <BuySteps active={false}>
                            <CircleStep>
                                <Icon name="check" />
                            </CircleStep>
                            <span className="labeling">{I18n.t('wallet.step1')}</span>
                        </BuySteps>
                        <BuySteps active={true}>
                            <CircleStep>
                                <Icon name="check" />
                            </CircleStep>
                            <span className="labeling">{I18n.t('wallet.step2')}</span>
                        </BuySteps>
                    </WrapperStepsContent>
                </WrapperSteps>
                <div className="inner wallet-checkout">
                    <div className="spacing"/>
                    <div className="i-back" style={{padding: '15px 30px'}} onClick={()=>this.props.history.push('/profile/wallet/packages')}><Icon name="angle left" /><span>{I18n.t('actions.back')}</span></div>
                    <React.Fragment>
                        <h1>{ I18n.t('payment.choosePaymentOption') }</h1>
                        <div className="stripe-block">
                            <div className="infoPayMent">
                                <div className="icon-pay">
                                    <i className="f-icon-credit-card"></i>
                                </div>
                                <div className="pay-info">
                                    <span className="title-price">{ I18n.t('payment.total') }</span>
                                    <div className="info-price" style={{textAlign: 'right'}}><NumericFormat value={selectedPackage.priceEUR} type="eur" /></div>
                                    <div className="caption regular" style={{textAlign: 'right'}}><NumericFormat value={selectedPackage.tokens} type="token" suffix="YGB"/></div>
                                </div>
                            </div>
                            <div className="blocks-separator">
                                <span className="title">{ I18n.t('payment.payCreditOrDebit') }</span>
                                <hr className="hr-separator"></hr>
                                <Elements stripe={ stripePromise }>
                                    <ElementsConsumer>
                                        { ({ stripe, elements }) => (
                                            <StripeCheckoutForm
                                                stripe={ stripe }
                                                elements={ elements }
                                                amount={ selectedPackage.priceEUR }
                                                currency={ 'EUR' }
                                                periodic={ false }
                                                isYgbPayment= { true }
                                                tokens= {selectedPackage.tokens}
                                                type= ""
                                                description={ `Compra de tarjeta regalo Yogabot: ${selectedPackage.tokens}YGB_${selectedPackage.priceEUR}EUR` }
                                                onSuccess={ () => this.onSuccess('Stripe') }
                                                onError={ this.onError }
                                                onCancel={ this.onCancel }
                                            />
                                        ) }
                                    </ElementsConsumer>
                                </Elements>
                            </div>
                            <div className="blocks-separator">
                                <span className="title">{ I18n.t('payment.payWithPaypal') }</span>
                                <hr className="hr-separator"></hr>
                                <div className="payapal-box">
                                    <PaypalButton
                                        payer={{ name: _.get(userSession, 'surname', '') + ',' + _.get(userSession,'name', ''), email_address: _.get(userSession, 'email', '') }}
                                        intent={ 'CAPTURE' } // 'CAPTURE' = inmediate pay, 'AUTHORIZE' = reserve money
                                        currency={ 'EUR' }
                                        amount={ selectedPackage.priceEUR}
                                        description={ `Compra de tarjeta regalo Yogabot: ${selectedPackage.tokens}YGB_${selectedPackage.priceEUR}EUR` }
                                        periodic={ false }
                                        type= ""
                                        isYgbPayment= { true }
                                        tokens= {selectedPackage.tokens}
                                        onSuccess={ () => this.onSuccess('Paypal') }
                                        onError={ this.onError }
                                        onCancel={ this.onCancel }
                                    />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                </div>
            </div>
        );

    }

}

const mapStateToProps = state => {

    return {
        userSession: _.get(state, 'auth.userSession', {})
    };

};

export default connect(mapStateToProps, {openGenericModal, closeGenericModal})(WalletCheckout);