import SessionModel from '../data/models/session/session';

//actions
const GET_CUSTOM_SESSION_STATS =  'GET_CUSTOM_SESSION_STATS';

const INITIAL_STATE = {
    userAlternativeSessionsCount: 0,
    alternativeSessionsTimeElapsedInSeconds: 0,
    userCustomSessionsCount: 0,
    timeElapsedInSeconds: 0,
    lastWeekUserAlternativeSessionsCount: 0,
    lastWeekAlternativeSessionsTimeElapsedInSeconds: 0,
    lastWeekUserCustomSessionsCount: 0,
    lastWeekTimeElapsedInSeconds: 0
};

export const getCustomSessionStats = () => async dispatch => {

    try {

        const response = await SessionModel.getCustomSessionStats();
        dispatch ({ type: GET_CUSTOM_SESSION_STATS, payload: response.data });

    } catch (errorCode) {

        dispatch ({ type: GET_CUSTOM_SESSION_STATS, payload: { ...INITIAL_STATE } });
        throw errorCode;

    }

};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case GET_CUSTOM_SESSION_STATS:

            return { ...action.payload };

        default:

            return state;

    }

};

export default reducer;