import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import I18n from '../../../i18n';
import './notification.scss';

class Notification extends React.Component {

    renderNotificationByType = () => {

        switch(this.props.type) {

            case 'fixered' : 
                return <div className="y-notification fixered" ><p>{ I18n.t('notifications.incompletedProfile') }&nbsp;<Link to="/profile/account"><b>{ I18n.t('notifications.updateHere')}</b></Link></p></div>
            default:
                return <div className="y-notification" dangerouslySetInnerHTML={{__html: I18n.t('notifications.incompletedProfileBasic')}}></div>
        }
    }

    render() {

        return (!this.props.userSession.name || !this.props.userSession.surname) && (
           <React.Fragment>{this.renderNotificationByType()}</React.Fragment>
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.auth)

export default connect(mapStateToProps)(Notification);