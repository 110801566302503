import React, { Component } from 'react'
import { LandingHeader } from '../../components/header'
import { ButtonWrapper, CtaLogin, CtaRegister, InnerSection, LandingWrap, Section, SectionGrid, SectionGridItemImage, SectionGridItemText, SectionSubHeaderCta, SectionSubHeaderLogo } from '../../styled'
import OButton from '../../../../../../styled/components/button';
import { motion } from 'framer-motion';
import FundamentalsImage from '../../../../../../assets/img/main_landing/matriz_fundamentals.jpg';
import MatrixSectionImage from '../../../../../../assets/img/main_landing/matriz.svg';
import MatrixSystemImage from '../../../../../../assets/img/main_landing/sistema_matrix.png';
import ChallengesImage from '../../../../../../assets/img/main_landing/Retos.png';
import Challenges2Image from '../../../../../../assets/img/main_landing/Retos_2.png';
import AnalyticImage from '../../../../../../assets/img/main_landing/analisis.svg';

import { IframeWrapper, LayoutVideo, SectionContent, SectionContentBlock, SectionLMatrixImage } from './styled';
import Footer from '../../../../../../components/footer';
import { TopSeparator } from '../../components/prices/styled';
import { CtaMarkets } from '../../components/ctaMarket';
import { motionSubtitle, motionTitle, transition, motionButton } from '../../../../../../../utils/framerAnimations';

export default class MatrixLanding extends Component {
    
    render() {
        return (
            <>
                <LandingWrap>
                    <LandingHeader active="matrix" />
                    <Section>
                        <InnerSection sectionSubHeader>
                            <SectionSubHeaderLogo onClick={() => this.props.history.push("/")} />
                            <SectionSubHeaderCta>
                                    <CtaLogin to="/auth/login">Inicia sesión</CtaLogin>
                                    <CtaRegister to="/auth/register">Prueba gratis</CtaRegister>
                            </SectionSubHeaderCta>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid>
                                <SectionGridItemImage  >
                                    <motion.img src={MatrixSectionImage} alt="" initial={{ y: 20, opacity: 0 }} animate = {{y: 0,opacity: 1, transition: transition}}/>
                                </SectionGridItemImage>
                                <SectionGridItemText style={{textAlign:'left'}}>
                                    <motion.div initial="initial" animate="animate" exit='exit' >
                                        <motion.h1 variants={motionTitle} className="landing-heading-1">Matriz Biométrica</motion.h1>
                                        <motion.p variants={motionSubtitle} className="landing-heading-2 regular">La pedagogía detrás de Yogabot.</motion.p>
                                    </motion.div>
                                    <motion.div initial="initial" animate="animate" exit='exit' variants={motionButton}>
                                        <ButtonWrapper reverse>
                                            <OButton upper type="button" color="#FFF" primary size="large" onClick={() => this.props.history.push('/auth/login')}>
                                                <span className="toregular">EMPIEZA AHORA</span>
                                            </OButton>
                                        </ButtonWrapper>
                                    </motion.div>
                                </SectionGridItemText>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection>
                            <p className="paragraph regular chars-readable">Yogabot es proyecto tecnológico desarrollado sobre la pedagogía NEXOYOGA.</p>
                            <p className="paragraph regular chars-readable">NEXOYOGA es una pedagogía de enseñanza de Yoga basada en asana, pranayama y la práctica profunda. Creada por el profesor Jose Antonio Cao en Tenerife, Islas Canarias, España,.</p>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection>
                            <p className="landing-heading-2">La Pedagogía NEXOYOGA tiene dos objetivos fundamentales:</p>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionLegacy withLine>
                            <SectionLMatrixImage>
                                <img src={FundamentalsImage} alt="" />
                            </SectionLMatrixImage>
                            <SectionContent>
                                <SectionContentBlock>
                                    <p className="paragraph regular"><strong>Servir de base a profesores de cualquier metodología de yoga que emplee asanas</strong>, para complementar su conocimiento, poner en perspectiva el método que se esté enseñando con respecto a los demás, comprender el resto de <strong>aproximaciones a la resolución de problemas específicos</strong> en la enseñanza del yoga; y en general, mantener una formación y crecimiento continuo, interactivo y actual, incorporando herramientas digitales de última generación que faciliten su crecimiento y gestión profesional.</p>
                                </SectionContentBlock>
                                <SectionContentBlock>
                                    <p className="paragraph regular"><strong>Acortar y facilitar</strong> el camino de cada practicante de yoga según su estructura y sus verdaderas necesidades personales. <strong>Optimizar</strong> el crecimiento en yoga de cada practicante, es el pilar fundamental de la pedagogía Nexoyoga.</p>
                                </SectionContentBlock>
                            </SectionContent>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <p className="landing-heading-2">Sistema de Matriz Biométrica®</p>
                            <SectionGrid reverse>
                                <SectionGridItemText reverse>
                                        <p className="paragraph regular">Para sistematizar el proceso de personalización y optimización de práctica de yoga a un alumno, NEXOYOGA creó el concepto de Matriz Biométrica.</p>
                                        <p className="paragraph regular">NEXOYOGA Matriz Biométrica® es una estructura de datos que guarda valores biométricos del practicante, los cuales obtiene mediante un proceso de autodiagnóstico corporal.</p>
                                        <p className="paragraph regular">Este conjunto de datos biométricos se trabajan junto al Sistema de Secuenciación de la Pedagogía NEXOYOGA, permitiendo ajustar y priorizar la práctica de Yoga de cada usuario a sus necesidades y condiciones corporales, en cada momento.</p>
                                        <p className="paragraph regular">Este ajuste personalizado optimiza la práctica de Yoga hasta triplicar su efectividad y sus resultados, en el mismo tiempo. </p>
                                </SectionGridItemText>
                                <SectionGridItemImage reverse >
                                    <img src={MatrixSystemImage} alt="" />
                                </SectionGridItemImage>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <p className="landing-heading-2">La Tecnología al servicio de la Pedagogía</p>
                            <SectionGrid >
                                <SectionGridItemImage >
                                    <LayoutVideo>
                                        <IframeWrapper>
                                            <iframe title="yogabot iframe" src="https://player.vimeo.com/video/368604733?color=E0684B&title=0&byline=0&portrait=0" width="100%" height="100%" frameBorder="0"  allow="autoplay; fullscreen" allowFullScreen="" />
                                        </IframeWrapper>
                                    </LayoutVideo>
                                </SectionGridItemImage>
                                <SectionGridItemText style={{textAlign:'left'}} >
                                        <p className="paragraph regular">Yogabot no es solo una aplicación creada por especialistas informáticos, es un proyecto tecnológico que implementa, automatiza y optimiza, mediante procesos de <strong>Machine Learning e Inteligencia Artificial</strong>, los sistemas de <strong>Diagnóstico, Matriz Biométrica y Secuenciación</strong> de la Pedagogía NEXOYOGA</p>
                                        <p className="paragraph regular">Estos sistemas han sido <strong>validados y mejorados</strong> desde 2016, dentro de las diferentes formaciones y entrenamientos con practicantes y profesores de Yoga de diferentes países.</p>
                                </SectionGridItemText>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <p className="landing-heading-2">¿Cómo completar y actualizar tu Matriz Biométrica?</p>
                            <p className="heading-1 regular">Diagnóstico corporal (6 retos).</p>
                            <SectionGrid reverse>
                                <SectionGridItemText  basis={45} reverse>
                                        <p className="paragraph regular">Para completar la Matriz Biométrica de forma fácil y ordenada, Yogabot incorpora 6 grupos de <strong>posturas de medición biométrica</strong>, organizadas por zonas corporales (los 6 retos). </p>
                                        <p className="paragraph regular">Cada vez que se completa un reto, <strong>la matriz biométrica se actualiza</strong>. Esta actualización se muestra en la figura humana de la pantalla de inicio, apareciendo las zonas del cuerpo correspondientes al reto terminado, coloreadas de rojo, azul o gris.</p>
                                </SectionGridItemText>
                                <SectionGridItemImage reverse fullImage>
                                    <img src={ChallengesImage} alt="" />
                                </SectionGridItemImage>
                                
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid >
                                <SectionGridItemImage fullImage>
                                    <img src={Challenges2Image} alt="" />
                                </SectionGridItemImage>
                                <SectionGridItemText  basis={45} style={{textAlign:'left'}}>
                                        <p className="paragraph regular">En cada reto se explica la información del área corporal a medir, cuántos ejercicios contiene, cuántos miden elasticidad y cuántos el tono muscular.</p>
                                        <p className="paragraph regular">Al terminar todo el Diagnóstico Corporal (6 retos) los <strong>algoritmos matemáticos de Yogabot</strong> analizan y cruzan los datos globales del cuerpo y representan en un código de colores el estado biométrico del usuario, priorizando la importancia de cada área por separado.</p>
                                </SectionGridItemText>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                 
                    <Section>
                        <InnerSection sectionGrid>
                            <p className="landing-heading-2">Análisis Automático de Imagen (próximamente)</p>
                            <SectionGrid reverse>
                                
                                <SectionGridItemText basis={45} reverse>
                                        <p className="paragraph regular">Próximamente,Yogabot incorporará <strong>procesamiento automático de imagen y sonido</strong>, para tomar automáticamente las imágenes de las asanas de medición biométrica durante el proceso de los retos, haciendo todo el autodiagnóstico mucho más <strong>fluido y preciso</strong>, eliminando el error apreciativo del usuario. </p>
                                        <p className="paragraph regular">Todo el proceso de toma de datos biométricos está dentro del marco legal de protección de datos de Europa. Las imágenes de cada usuario son <strong>anonimizadas y usadas únicamente para los fines de cálculo</strong> y recomendación automática personales y colectivas (a través de metadatos) de la propia app de yogabot.</p>
                                </SectionGridItemText>
                                <SectionGridItemImage reverse fullImage>
                                    <img src={AnalyticImage} alt="" />
                                </SectionGridItemImage>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <TopSeparator/>
                    <CtaMarkets />
                    <Footer {...this.props} type="branded" hasExtraFooter={true}/>
               </LandingWrap>
            </>
        )
    }
}
