import styled from 'styled-components/macro';


const InnerContainer = styled.div`

    width: 100%;
    margin: 20px auto 0px auto;
    min-height: 100%;
    display:flex;
    flex-direction: column

`;

const FrameGrid = styled.div`
    flex-grow:1;
    flex-shrink:0;
    flex-basis:auto;
    background-color:#1fd5b91a;
    width:100%;
    margin-top: 50px;
    padding-bottom: 50px;
    min-width: 0;
    overflow: hidden;
`;

const FramePadded = styled.div`

    padding:20px 32px;
    @media only screen and (max-width:420px) {
        padding:20px 20px; 
    }

`;

const FrameBlock = styled.div`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    flex:none;
`;

const Wrapper = styled.div`

    height: calc(100% - var(--nav-height));
    overflow-y: auto;
    position: relative;

`;

export {

    InnerContainer,
    FrameGrid,
    FrameBlock,
    FramePadded,
    Wrapper
}