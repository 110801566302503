import styled from 'styled-components/macro';

const FrameContent = styled.div`
    position:relative;
    padding-right:40px;
    flex:none;
    
`;

const Progress = styled.div`
    max-width:170px;
`;

const ProgressBar = styled.div`

    width:100%;
    overflow:hidden;
    background-color:var(--light-grey-2);
    height:10px;
    margin-bottom:10px;

`;

const ProgressInner = styled.div`

    background-color:var(--brand-secondary);
    height:100%;
    width:${({progress}) => `${progress}%` };

`;

const FrameArrow = styled.div`
    position:absolute;
    top:0;
    right:0;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:2px 0;
    border-radius:5px;
    border:solid 2px var(--brand-primary-extra-light);
    i {
        margin:0;
    }
`;

export {
    FrameContent,
    Progress,
    ProgressBar,
    ProgressInner,
    FrameArrow
}