// Third partiy libraries
import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react';

// Components
import { NumericFormat } from '../../../../components/numericFormat/NumericFormat';

// Styled Components
import OButton from '../../../../styled/components/button';
import { WrapperSteps, WrapperStepsContent, BuySteps, CircleStep, WalletResultWrapper, WalletResultBox, WalletResultBoxHeader, WalletResultBoxBody } from './styled';
import { AuthSeparator } from '../../../auth/styled';

// Locales
import I18n from '../../../../../i18n';

// Styles
import '../../profile_new.scss';

class WalletCheckoutResult extends Component {

    constructor(props) {

        super(props)

        this.state = {
            selectedPackage: {},
            status: this.props.match.params.status
        };

    }

    componentDidMount() {

        if (window.localStorage.getItem('pk')) {

            this.setState({ selectedPackage: JSON.parse(window.localStorage.getItem('pk')) });

        }

    }

    componentWillUnmount() {

        if (window.localStorage.getItem('pk')) {

            window.localStorage.removeItem('pk');

        }

    }

    onAccept = () => {

        const { status } = this.state;

        window.localStorage.removeItem('pk');
        status === 'correct' ? this.props.history.push(`/profile/wallet`) : this.props.history.push(`/profile/wallet/packages`);

    }

    render() {

        const { status, selectedPackage } = this.state;
        return (
            <div className="p-wrapper">
                <WrapperSteps>
                    <WrapperStepsContent>
                        <BuySteps active={false}>
                            <CircleStep>
                                <Icon name="check" />
                            </CircleStep>
                            <span className="labeling">{I18n.t('wallet.step1')}</span>
                        </BuySteps>
                        <BuySteps active={false}>
                            <CircleStep>
                                <Icon name="check" />
                            </CircleStep>
                            <span className="labeling">{I18n.t('wallet.step2')}</span>
                        </BuySteps>
                    </WrapperStepsContent>
                </WrapperSteps>
                <div className="inner">
                    <WalletResultWrapper>
                        <WalletResultBox>
                            <WalletResultBoxHeader>
                                {status === 'correct' ?
                                    <>
                                        <Icon name="check circle" className="brand-secondary" size="large" />
                                        <span className="paragraph">{I18n.t('checkout.correctPurchase')}</span>
                                    </>
                                :
                                    <>
                                        <Icon name="times circle" className="brand-secondary" size="large" />
                                        <span className="paragraph">{I18n.t('checkout.inCorrectPayment')}</span>
                                    </>
                                }
                            </WalletResultBoxHeader>
                            <AuthSeparator />
                            <WalletResultBoxBody>
                                {status === 'correct' &&
                                    <>
                                        <p className="paragraph regular">{ I18n.t("checkout.resumePayment") }</p>
                                        <p className="paragraph"><NumericFormat value={selectedPackage?.priceEUR} type="eur" /> - <NumericFormat value={selectedPackage?.tokens} type="token" suffix="YGB"/></p>
                                    </>
                                }
                            </WalletResultBoxBody>
                            <AuthSeparator />
                            <AuthSeparator />
                            <OButton upper type="button" fluid color="#FFF" primary onClick={this.onAccept}>
                                <span>{status === 'correct' ? I18n.t('actions.back') : I18n.t('actions.tryAgain')}</span>
                            </OButton>
                        </WalletResultBox>
                    </WalletResultWrapper>
                </div>
            </div>
        );

    }

}

export default WalletCheckoutResult;