// Third party libraries
import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';

// Locales
import I18n from '../../../../i18n';

const Preambule = ({ visible, startTimePreambule, callback = () => {} }) => {

    const intervalLocation = 'yogabotIntervalsPracticePreambule';
    const [preambuleElapsedTime, setPreambuleElapsedTime] = useState(Math.max(7 - Math.floor((Date.now() - startTimePreambule) / 1000), 0));
    const song = new Audio('https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/asanas/sonidos/bell.mp3');
    if (visible && !window[intervalLocation]) {

        window[intervalLocation] = setInterval(() => showPreambule(), 500);

        const showPreambule = () => {

            setPreambuleElapsedTime(Math.max(7 - Math.floor((Date.now() - startTimePreambule) / 1000), 0));

            if (Math.max(7 - Math.floor((Date.now() - startTimePreambule) / 1000), 0) < 1) {

                setTimeout(() => {

                    if (window[intervalLocation]) {

                        song.play();
                        setPreambuleElapsedTime(0);
                        callback();
                        clearInterval(window[intervalLocation]);
                        delete window[intervalLocation];

                    }

                }, 1200);

            }

        }

    }

    return (
        <Fade when={ visible }>
            <div className='preambule-layout'>
                <div>
                    <p>{ I18n.t('practice.startIn') } { preambuleElapsedTime }</p>
                </div>
            </div>
        </Fade>
    );

}

export default React.memo(Preambule);