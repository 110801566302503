import React from 'react';
import { Form, TextArea as TextAreaInput, Label } from 'semantic-ui-react';

class CustomTextArea extends React.Component {

    render() {

        return (
            <Form.Field className={ this.props.fieldClasses } error={ this.props.meta.touched && this.props.meta.invalid }>
                <label>{ this.props.label }</label>
                <TextAreaInput
                    disabled={this.props.disabled || false}
                    { ...this.props.input }
                    placeholder={ this.props.placeholder }
                    onChange={ e => this.props.input.onChange(e) }
                    />
                { this.props.meta.touched && this.props.meta.invalid && <Label basic color={ this.props.colorLabel || 'red' } pointing>{ this.props.meta.error }</Label> }
            </Form.Field>
        );

    }

}

export default CustomTextArea;