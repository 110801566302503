import styled, {css} from 'styled-components/macro';


const AsanaContainer = styled.div`
   
    padding: 0 20px;
    margin-bottom: 60px;
`; 

const Title = styled.div`
    padding: 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    i{
        font-size: 24px;
        &:before{
            color: var(--primary-brand);
        }
        @media only screen and (max-width: 460px) {
            display: none;
        }
    }
`;

const FrameProgress = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto 42px auto;

`;

const Counter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

`;

const CounterButton = styled.div`
    padding: 10px;
    background-color: var(--y-green1);
    margin: 10px auto;
    cursor: pointer;
    display: flex;
    i {
        &:before{
            color: white;
        }
    }
    &.clock{
        font-size: 32px;
    }
`;

const Progress = styled.div`

    background: #FDFEFF;
    border: 2px solid #DCE0DF;
    box-sizing: border-box;
    border-radius: 20px;
    display: inline-block;
    height: 15px;
    width: 100%;
    overflow: hidden;
`;

const InnerProgress = styled.div`
    background: var(--brand-secondary);
    height:100%;
    width:${({width})=> (width && `${width}%`) || 0 };
    background-color:${({bgColor})=> (bgColor && `${bgColor}`) || 0 };
`;

const StartMessage = styled.div`
    text-align: center;
    margin-top: 5px;
    opacity:${({opacity})=> (opacity && `${opacity}`) || 0 };
`;

const Subtitle = styled.div`
    text-align:center;
    padding-bottom: 30px;
`;

const AsanasGrid = styled.div`
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    max-width:1024px;
    margin: 0 auto;
    padding:0px 0px 50px 0px;
    @media only screen and (max-width: 620px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Asana = styled.div`
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    background: white;
    position: relative;
    overflow: hidden;
    cursor:pointer;
    ${({selected}) => selected &&
        css`
            border:solid 3px var(--brand-secondary);
            ${SelectedLayer} {
                display: flex;
            }
    `};
`;

const SelectedLayer = styled.div`
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 5;
    i {
        font-size: 64px;
        &::before{
            color: var(--brand-secondary);

        }
    }
`;

const handleColorType = index => {
    switch (index) {
      case 0:
        return "#e20d0d";
      case 1:
        return "#ffcf00";
      default:
        return "#1fd5b9";
    }
  };

const Label = styled.div`

    position: absolute;
    top: 0;
    left:0;
    height: 40px;
    border-bottom-right-radius: 6px;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: white;
    letter-spacing: 0.5px;
    background-color: ${({index})=> handleColorType(index) }
`;

const FrameImageRatio = styled.div`
    padding-top: 75%;
    overflow: hidden;
    position: relative;
`;

const FrameImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 4;
    padding: 10px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const AsanaTitle = styled.div`
    text-align: center;
    padding: 0 15px 15px 15px;
    background-color: white;

`;

const FrameButtons = styled.div`
    text-align: right;
    max-width: 1024px;
    margin: 0 auto;
    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column-reverse;
    }
    
`;

const Separator = styled.span`
    height: 30px;
    width: 3px;
    padding: 0 2px;
    background-color: var(--y-grey13);
    display: inline-block;
    border-left: solid 10px white;
    border-right: solid 10px white;
    position: relative;
    top: 11px;
    @media (max-width: 600px) {
        display: none;
    }
`;
export {
    AsanaContainer,
    AsanasGrid,
    Asana,
    Title,
    Subtitle,
    FrameProgress,
    Counter,
    CounterButton,
    Progress,
    InnerProgress,
    StartMessage,
    SelectedLayer,
    Label,
    FrameImageRatio,
    FrameImage,
    Image,
    AsanaTitle,
    FrameButtons,
    Separator

}