// Types
const UPDATE_MEASSURES = 'UPDATE_MEASSURES';

// Actions
export const updateWindowSize = (payload) => dispatch => {

    dispatch ({ type: UPDATE_MEASSURES, payload: payload });

};

// Reducer
const INITIAL_STATE = {
    height: window.innerHeight,
    width: window.innerWidth
};

function reducer (state = INITIAL_STATE, action) {

    switch (action.type) {

        case UPDATE_MEASSURES:

            return { ...action.payload };

        default:

            return state;

    }

};

export default reducer; 