//libraries & components libraries
import React from 'react';
import I18n from '../../../../../i18n';
import { Responsive } from 'semantic-ui-react';

//styled components
import OButton from '../../../../styled/components/button';
import { RecoveryBox, RecoveryCol, RecoveryRow } from '../styled';
import { AuthHeader, AuthIcon, AuthSeparator, AuthTitle, YogabotIcon } from '../../styled';

//assets
import yLogoLarge from '../../../../assets/img/logo_large.svg';

class RecoveryStep3 extends React.Component {

    render() {

        return (

                <RecoveryRow>
                    <RecoveryCol>
                        <RecoveryBox>
                            <AuthHeader column>
                                <Responsive minWidth={560}>
                                    <AuthIcon w={240} h={50}>
                                        <YogabotIcon src={yLogoLarge} />
                                    </AuthIcon>
                                </Responsive>
                                <Responsive maxWidth={559}>
                                    <AuthIcon>
                                        <YogabotIcon />
                                    </AuthIcon>
                                </Responsive>
                                <AuthTitle><span>{I18n.t('auth.restoreCorrectMessageFirst')}</span></AuthTitle>
                            </AuthHeader>
                            <AuthSeparator />
                            <AuthSeparator />
                            <p className="paragraph regular">{ I18n.t("auth.restoreCorrectMessageSecond") }</p>
                            <AuthSeparator />
                            <AuthSeparator />
                            <OButton upper type="button" fluid color="#FFF" primary onClick={()=> this.props.history.push(`/auth/login`)}>
                                <span>{I18n.t('auth.finalize')}</span>
                            </OButton>
                        </RecoveryBox>
                    </RecoveryCol>
                </RecoveryRow>

        );

    }

}

export default RecoveryStep3;