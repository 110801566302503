import React from 'react';
import './cookiesPopup.scss';
import { Icon } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';
import I18n from '../../../i18n';

class CookiesPopup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }
    }

    componentDidMount() {

        const hasCookies = window.localStorage.getItem('cookiesAccepted') === 'true';

        if (!hasCookies) {

            this.setState({isOpen: true});

        }

    }

    close = () => {

        this.setState({isOpen: false});

        window.localStorage.setItem('cookiesAccepted', true);

    }

    gotoInfoCookies = () => {

        this.props.history.push('/legal/cookies');

    }

    render() {

        return (
            <React.Fragment>
                <Fade bottom>
                    {this.state.isOpen && <div className={"cookies-section " + this.props.type || ""}>
                        <div className="cookies-text">
                            <p className="cookies-message">{I18n.t('cookies.firstMessage')}<br/>{I18n.t('cookies.secondMessage')} <span onClick={this.gotoInfoCookies} className="legal-cookies-link"><b>{I18n.t('cookies.cookiesPolicyHere')}</b></span></p>
                        </div>
                        <div className="cookies-closure" onClick={this.close}>
                            <Icon name='times' />
                        </div>
                    </div>}
                </Fade>
            </React.Fragment>
        );

    }
    
};

export default CookiesPopup;