import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion'
import React, {useState, useEffect} from 'react'
import { Icon } from 'semantic-ui-react';
import { CloseIcon } from '../staffSlider/styled';
import { AccountTypeGrid, AccountTypeItem, Expanded } from './styled'
const transition = {
    duration: 0.15,
    ease: () => 1,
}

export function AccountType(props) {

    const [showMore, setShowMore] = useState(null);

    useEffect(() => {

        showMore ? document.body.classList.add('disabled-scroll-all') : document.body.classList.remove('disabled-scroll-all');

        return () =>  document.body.classList.remove('disabled-scroll-all')
 
     }, [showMore]);

    return (
        <>
        <AnimateSharedLayout type="crossfade">
            <AccountTypeGrid>
                <AccountTypeItem layoutId={'0'}>
                    <h2 className="landing-heading-2">Lite</h2>
                    <p className="paragraph regular">Es una cuenta <strong>totalmente gratuita</strong> que te permitirá acceder a algunas de las principales funcionalidades de Yogabot, aunque con limitaciones.</p>
                </AccountTypeItem>
                <AccountTypeItem layoutId={'1'}>
                    <h2 className="landing-heading-2">Premium</h2>
                    <p className="paragraph regular">Te ofrece el mayor y <strong>mejor nivel experiencia</strong>, con acceso a todas las funcionalidades por un precio realmente módico:</p>
                    <p onClick={()=> setShowMore(true)} className="paragraph upper brand-secondary c-pointer">ver mas</p>
                </AccountTypeItem>
                <AnimatePresence>
                    {showMore && 
                        <Expanded initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={transition} style={{
                            ...overlay}}>
                            <div style={singleImageContainer}>
                                <AccountTypeItem className="expanded" layoutId={'1'} id="parent">
                                    <CloseIcon>
                                        <Icon name="close" size="large" className="c-pointer" onClick={()=> setShowMore(false)} />
                                    </CloseIcon> 
                                    <motion.h2 animate={{color: '#1fd5b9', transition: {  ease: [0.6, 0.01, -0.05, 0.9] , delay: 0.2}}} className="landing-heading-2">Premium</motion.h2>
                                    <p className="paragraph regular">Te ofrece el mayor y <strong>mejor nivel experiencia</strong>, con acceso a todas las funcionalidades por un precio realmente módico:</p>
                                    <motion.p className="paragraph regular" initial={{ opacity: 0, x:-100}} animate= {{opacity: 1, transition: {  ease: [0.6, 0.01, -0.05, 0.9], duration: 1, delay: 0.2, }, x:0}} exit={{ opacity: 0 }}><strong>Cuota Mensual</strong>: $9,99 dólares americanos. Con <strong>cada cuota</strong> se incluye GRATIS un video de ejecución técnica. Puedes seleccionar el video de la postura que quieras <strong>estudiar y perfeccionar</strong>.</motion.p>
                                    <motion.p className="paragraph regular" initial={{ opacity: 0, y:100 }} animate= {{opacity: 1, transition: {  ease: [0.6, 0.01, -0.05, 0.9], duration: 1, delay: 0.2, }, y:0}}  exit={{ opacity: 0 }}><strong>Cuota Anual</strong>: $59,99 dólares americanos. Disfruta toda la experiencia PREMIUM a <strong>mitad de precio</strong>, con un pago único al año ($4,99 / mes). <strong>No incluye</strong> el video técnico.</motion.p>
                                </AccountTypeItem>
                            </div>
                        </Expanded>
                    }          
                </AnimatePresence>
            </AccountTypeGrid>
        </AnimateSharedLayout>
        </>
    )
}

const singleImageContainer = {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

const overlay = {
    background: "rgba(48,66,90,0.6)",
    position: "fixed",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
}