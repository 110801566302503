import styled from 'styled-components/macro';

import staff1 from '../../../../assets/img/nexo_stuff.jpg';
import staff2 from '../../../../assets/img/nexo_stuff_2.jpg';
import staff3 from '../../../../assets/img/nexo_stuff_3.jpg';

import { flexbox, XYCenter, ObjFit } from '../../styled/helpers';
import { AuthBox, AuthTitle } from '../../styled';

const randomBackgrounds = (images, ln) => images[Math.floor(Math.random() * ln) ];
const randomBackground = randomBackgrounds([staff1, staff2, staff3], 3);
const RegisterBox = styled(AuthBox)`
    @media (max-width:559px) {
        border-radius: 0px;
        box-shadow: none;
    }
`;

const RegisterWrapper = styled.div`
    ${flexbox};
    width: 100%;
    min-height: 100%;
    background-image: url(${randomBackground});
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    position:relative;
    z-index:1;
    &:after {
        content:"";
        position:absolute;
        background: rgba(20, 10, 10, 0.29);
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index:2;
    }
    @media (max-width:559px) {
        background-image:none;
    }

`;

const RCols = styled.div`
    ${flexbox};
    ${XYCenter};
    position:relative;
    z-index:3;
    flex-grow:0;
    flex-shrink:1;
    flex-basis:40%;
    min-width:0;
    @media (max-width:1024px) {
        flex-basis:100%;
    }
    @media (max-width:559px) {
        background:var(--brand-white);
    }
    & + & {

        margin-left: 100px;
        flex-basis:60%;
        width:100%;
        justify-content: flex-start;
        @media (max-width:1024px) {
            display:none;
        }

    }

`;

const ReferedTitle = styled(AuthTitle)`margin-top:0;`;

const AuthRefered = styled.div`
    ${flexbox};
    ${XYCenter};
    width:100%;
`;

const ReferedPicture = styled.div`
    
    width:96px;
    height:96px;
    flex-shrink:0;
    min-width:0;
    margin-right:20px;
    border-radius:50px;
    overflow:hidden;
    img {
        ${ObjFit};
    }
    @media (max-width:720px) {
        width:76px;
        height:76px;
    }
`;

export {
    ReferedTitle,
    AuthRefered,
    RegisterWrapper,
    RCols,
    RegisterBox,
    ReferedPicture
}