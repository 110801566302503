import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import './grid.scss';
import I18n from '../../../i18n';
import playImage from '../../assets/img/play_blanco.png';

class Grid extends React.Component {

    renderChallengesGrid = () => {

        return(

            this.props.challenges && this.props.challenges.map((item, index) => (
                <div className="item" key={index} onClick={()=> !item.locked && this.props.mainAction(index)}>
                    {item.locked && <div className="disabled-layout">
                        <div className="centered-layout">
                            <div><i className="f-icon-candado-cerrado"></i></div>
                        </div>
                    </div>}
                    <div className="item-label">
                        {item.title}
                    </div>
                    <div className="item-cover">
                        <div className="wrapper-image">
                            <img src={item.cover} alt="" />
                        </div>
                    </div>
                    <div className="item-footer">
                        <div className="title">
                            {item.description}
                        </div>
                        <div className="subtitle">
                            {item.subtitle}
                        </div>
                        <div className="stats">
                            <span>{I18n.t('challenge.meassures')} <span>{item.positionCount}</span> | <i className="f-icon-icono_17"></i><span>{item.time} Min {I18n.t('challenge.aproxDuration')}. </span></span>
                        </div>
                    </div>
                 </div>
            ))

        )
        
    }

    renderAsanasGrid = () => {

        return(

            this.props.challenges[this.props.match.params.challengeId].asanas && this.props.challenges[this.props.match.params.challengeId].asanas.map((item, index) => (
                <div className="item" key={index} onClick={()=> !item.locked && this.props.mainAction(index)}>
                <div className="icon-practice-type">
                    {item.type === 'tone' ? <i className="f-icon-tono"></i> : <i className="f-icon-elasticity"></i>}
                </div>
                {item.locked && <div className="disabled-layout">
                    <div className="centered-layout">
                        <div><i className="f-icon-candado-cerrado"></i></div>
                    </div>
                    
                </div>}
                 <div className="item-cover">
                     <div className="wrapper-image asana">
                         <img src={item.cover} alt="" className="challenge"/>
                         <div className="a-overlay">
                            {!item.locked && <img src={playImage} alt="" className="play-image"/>}
                         </div>
                     </div>
                 </div>
                 <div className="item-header" data-index={index + 1}>
                     <div className="description">
                         <h1>{item.description}</h1>
                     </div>
                     <div className="subtitle">
                         <p>{item.subtitle}</p>
                     </div>
                 </div>
             </div>
            ))

        )
    }


    renderGrid = () => {

        switch(this.props.type) {

            case 'challenge':
                return this.renderChallengesGrid()
            case 'asana':
                return this.renderAsanasGrid()
            default:
                return ''
                
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="diagnosis-grid">
                    {this.renderGrid()}
                </div>
            </React.Fragment>
        );

    }
    
};

const mapStateToProps = state => _.cloneDeep(state.challenges);

export default connect(mapStateToProps, {})(Grid);