import React from "react";
import PaypalBtn from 'react-paypal-checkout';

import Config from '../../../config';

import PaymentModel from '../../../data/models/payment/payment';

class PaypalButton extends React.Component {

    onSuccess = async data => {

        const { amount, currency, isYgbPayment, tokens } = this.props;

        if (isYgbPayment) {

            await PaymentModel.createYgbInvestment({
                amount,
                currency,
                platform: 'paypal',
                payerId: data.payerID,
                orderId: data.paymentID,
                tokens
            });

        } else {

            await PaymentModel.createUserPayment({
                amount: this.props.amount,
                currency: this.props.currency,
                description: this.props.description,
                type: this.props.type,
                platform: 'paypal',
                payerId: data.payerID,
                orderId: data.paymentID,
                products: this.props.products
            });

        }

        this.props.onSuccess();

    };

    render() {

        const env = Config.paypal.clientId === 'AbTLKWhCEvX-zlsgCdHs9_xlP_MG-yCzxY6HJV-AV84KDtiZ3cYXA4Vai7BASRHdwHOGCIzDSscQY19r' ? 'sandbox' : 'production';
        const client = {
            sandbox: Config.paypal.clientId,
            production: Config.paypal.clientId,
        };
        const { currency, amount } = this.props;

        const style = {
            label: 'paypal',
            tagline: false,
            size: 'responsive',
            shape: 'rect',
            color: 'gold'
        };

        return (
            <PaypalBtn
                env={ env }
                client={ client }
                currency={ currency }
                total={ amount }
                style={ style }
                onError={ error => console.error('Paypal Error: ', error) }
                onSuccess={ this.onSuccess }
                onCancel={ data => console.error('Paypal Cancel: ', data) } />
        );

    }

}

export default PaypalButton;