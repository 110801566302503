import { useEffect } from 'react';
import SecurityModel from '../../../data/models/security/security';

const KeepAlive = () => {

    const doCheck = async () => {

      try {

        const response = await SecurityModel.checkJwt();

        if (response.status === 200) {

            window.localStorage.setItem('jwt', response.data.jwt);

        }
        
      } catch (error) {
        
          console.error('error:KeepAlive')
          
      }

    }

    useEffect(()=> {

      const interval = setInterval(() => {
        
        if (window.localStorage.getItem('jwt')) {

            doCheck();

        }

      }, 1000 * 60 * 30);

      return () => clearInterval(interval);

    }, []);

    return null

}

export default KeepAlive;