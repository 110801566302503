import React from 'react';
import { FrameVideo, Video, FrameOverlay } from "./styles";
import Poster from '../../../../../assets/img/poster.jpg';

const VideoInfo = React.forwardRef(( {src, controls, callback, playImage}, ref )=> {

    return (
        <FrameVideo>
            <Video poster={Poster} ref={ ref } playsInline controls={ controls } preload="auto" >
                <source  src={src} type="video/mp4" />
            </Video>
            <FrameOverlay id="overlay" onClick={ callback }>
                <img src={ playImage } alt="" />
            </FrameOverlay>
        </FrameVideo>
    )

})

export default VideoInfo;