import React from 'react';
import { ContainerDiscount, DiscountPriceElement } from './styles/discountElement';

export default function DiscountElement({children, ...restProps}) {
    
    return <ContainerDiscount { ...restProps }>{ children }</ContainerDiscount>;

}

DiscountElement.DiscountPrice = function DiscountPrice({ children, ...restProps }) {

    return <DiscountPriceElement { ...restProps }>{ children }</DiscountPriceElement>;

};