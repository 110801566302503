import _ from 'lodash';
import React, { useState } from 'react';

//? https://es.reactjs.org/docs/uncontrolled-components.html
//? En React, un <input type="file" /> siempre es un componente no controlado porque su valor solo puede ser establecido por un usuario, y no mediante programación.
//? Se puede acceder a su valor mediante el uso de ref con this.fileInput.current.files[0].name
//? Es recomendable resetear el valor del input

 /**
  * @desc Cuando se añade un fichero se guarda en memoria un tempFile ref y un raw, el raw es en base64 y se usa sólo para mostrar la imagen de forma temporal en el front.
  *       En el redux se guarda un string temporal para forzar a hacer un render y que se vea la imagen cuando termine en el evento onload
  *       Al hacer submit se obtiene en memoria el ref del tempFile que es el objeto que se necesita enviar a la api. 
  *       Se ha seguido este patrón para no subir una imágen en cada llamada al handleChange sino sólo cuando se envie los datos a la api en el submit del formulario
  *       El setResetKey se usa para limpiar el valor intrinsíco del atributo value del input file ya que el evento onChange no se lanza si intentamos subir seguidamente el mismo fichero.  
  * */

 const InputFile = ({ input } )  =>  {

    const { value, ...inputProps } = input;

    const [resetKey, setResetKey] = useState(Math.random().toString(36));

	const handleChange = async e => {

        if (_.get(e, 'target.files[0]', false)) {

            var reader = new FileReader();

            window['tempFile'] = { ref: e.target.files[0] }

            reader.onload = function(event) {
                
                _.set(window, 'tempFile.raw', event.target.result);
                input.onChange('TEMPORAL_UNTIL_PRESS_SAVE');
    
            };
    
            reader.readAsDataURL(window['tempFile'].ref);

            setResetKey(Math.random().toString(36));

        }

    }
            
    return (
        <input {...inputProps} key={resetKey} type="file" onChange={handleChange} onBlur={()=>{}} className="hide-input" accept="image/*"/>
    );

};

export default InputFile;