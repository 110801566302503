import styled from 'styled-components/macro';

const ResultBox = styled.div`

    max-width: 900px;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    margin: 0 auto;
    width:100%;
`;

const ActionBtn = styled.div`
    margin-top:20px;
    @media only screen and (max-width: 720px) {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        button{
            flex: 1;
            width: 100%;
            margin-bottom: 10px!important;
        }
    }
`;

const Content = styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    @media (max-width:420px){
        padding: 20px;
    }
`;


export {
    ResultBox,
    ActionBtn,
    Content
}