import styled from 'styled-components/macro';

const FrameContent = styled.div`
    padding: 0 32px 32px; 
    @media only screen and (max-width:420px) {
        padding:0 20px 20px; 
    }
`;

const Actions = styled.div`
    margin-top:30px;
    @media (max-width: 420px) {
        display:flex;
        flex-direction:column;
    }
`;

const FrameForm = styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
`;

const Frame = styled.div`
    flex-shrink:0;
    flex-grow:0;
    flex-basis:47%;
    &:first-child{
        padding-bottom:1em;
    }
    @media (max-width: 920px) {
        flex-basis:100%;
    }
`;

export {
    FrameContent,
    Actions,
    FrameForm,
    Frame
};