import styled from 'styled-components/macro';

const SwipperContainer = styled.div`

    width:100%;
    
`;

const ItemSeparator = styled.div`
    padding: 1rem;
    height:100%;
    display:flex;
    flex-direction:column;
`;

const Item = styled.div`

    padding: 2rem;
    background-color:#fff;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    overflow:hidden;
    border-radius:0.5rem;
    margin: 1rem 0 ;
    flex:1;
`;

const ItemHeader = styled.header`

    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    flex-direction:row-reverse;
    justify-content: flex-end;
    gap: 1rem;
    p {
        margin-bottom:0;
    }

`;

const AuthorPicture = styled.div`
    height: 3.5rem;
    width: 3.5rem;
    min-width:0;
    flex-shrink:0;
    overflow:hidden;
    border-radius:50%;
    position:relative;
    img {
        width:100%;
        height:100%;
        object-fit:cover;
    }

    &:after{
        content: "";
        border: 2px solid var(--brand-primary);
        position: absolute;
        pointer-events: none;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: inherit;
    }
`;

const ItemAuthor = styled.div``;

const ItemContent = styled.div`
        p {
            line-height:1.9;
        }
`;

export {
    SwipperContainer,
    ItemAuthor,
    ItemContent,
    AuthorPicture,
    Item,
    ItemHeader,
    ItemSeparator

}