import styled, {css} from 'styled-components/macro';


const GuideColorGrid = styled.div`

    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;

    @media (max-width:820px) {
        grid-template-columns: 1fr;
        margin-top: 20px;
    }
    @media (max-width:381px) {
        margin-top: 30px;
    }

`;

const InfosColorsCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

`;

const InfosImageCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        max-width: 334px;
        width: 100%;
        max-height: 726px;
        @media (max-width:500px) {
            max-width: 300px;
        }

        @media (max-width:460px) {
            max-width: 180px;
        }
    }
`;

const InfoColorItem = styled.div`
    display: inline-flex;
    width: 420px;
    height: 77px;
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.25);
    margin-bottom: 50px;
    border-top-right-radius: 77px; ;
    border-bottom-right-radius: 77px; ;
    border-top-left-radius: 24px; ;
    border-bottom-left-radius: 24px; ;
    position: relative;
    background-color: white;
    
    &:before{
        content: "";
        top:50%;
        right: -15px;
        transform: translateY(-50%);
        width: 110px;
        height: 110px;
        z-index: -1;
        border-radius: 50%;
        position: absolute;
       
        @media (max-width:381px) {
            top:-50px;
            left: 50%;
            transform: translateY(0%) translateX(-50%);
        }
    }

    ${({c1}) => c1 && css`

        ${InfoColorLabel} {
            background-color: #950000;
        }
        &:before{
            background-color: #950000;
        }
        
    `};

    ${({c2}) => c2 && css`

        ${InfoColorLabel} {
            background-color: #FF0000;
        }
        &:before{
            background-color: #FF0000;
        }
        
    `};

    ${({c3}) => c3 && css`

        ${InfoColorLabel} {
            background-color: #FB7961;
        }
        &:before{
            background-color: #FB7961;
        }
        
    `};

    ${({c4}) => c4 && css`

        ${InfoColorLabel} {
            background-color: #CDCDCD;
        }
        &:before{
            background-color: #CDCDCD;
        }
        
    `};

    ${({c5}) => c5 && css`

        ${InfoColorLabel} {
            background-color: #6EA6E8;
        }
        &:before{
            background-color: #6EA6E8;
        }
        
    `};

    ${({c6}) => c6 && css`

        ${InfoColorLabel} {
            background-color: #0600FC;
        }
        &:before{
            background-color: #0600FC;
        }
        
    `};

    ${({c7}) => c7 && css`

        ${InfoColorLabel} {
            background-color: #0600FC;
        }
        &:before{
            background-color: #0600FC;
        }
        
    `};

    ${({c8}) => c8 && css`

        ${InfoColorLabel} {
            background-color: #fff;
            border: solid 1px var(--y-grey14);
        }
        &:before{
            background-color: #fff;
            border: solid 1px var(--y-grey14);
        }

        border: solid 1px var(--y-grey14);

    `};

    ${({c9}) => c9 && css`

        ${InfoColorLabel} {
            background-color: #02005f;
        }
        &:before{
            background-color: #02005f;
        }
        
    `};

    @media (max-width:500px) {
        width: 100%;
        height: 90px;
    }

    @media (max-width:381px) {
        border-radius: 12px!important;
        margin-bottom: 80px;
    }

`;

const InfoColorLabel = styled.div`
    height: 100%;
    width: 36px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
`;

const InfoColorContent = styled.div`
    padding: 5px 10px;
    width: 284px;
    p{
        margin: 0;
    }

    @media (max-width:500px) {
        width: 100%;
    }
`;

const InfoColorCircleIcon = styled.div`
    display: inline-flex;
    width: 83px;
    height: 100%;
    background: white;
    border-radius: 68px;
    margin-left: 21px;
    box-shadow: 3px 3px 13px rgba(204, 204, 204, 0.7);
    justify-content: center;
    align-items: center;
    font-size: 48px;
    i {
        &:before{
            color:#000;
        }
    }

    @media (max-width:500px) {
        width: 141px;
         margin-left: 0;
    }

    @media (max-width:381px) {
        display: none;
    }

`;


const InfoColorCircleIconMobile = styled.div`
    width: 83px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border-radius: 68px;
    justify-content: center;
    align-items: flex-start;
    font-size: 42px;
    top: -40px;
    height: 83px;
    z-index: -1;
    display: none;
    @media (max-width:381px) {
        display: inline-flex;
    }

`;

export {
    GuideColorGrid,
    InfosColorsCol,
    InfoColorItem,
    InfoColorLabel,
    InfoColorContent,
    InfoColorCircleIcon,
    InfoColorCircleIconMobile,
    InfosImageCol
}