import styled from 'styled-components/macro';


const ChallengeGrid = styled.div`

    display:flex;
    flex-wrap:wrap;
`;

const ChallengeGridItem = styled.div`

    padding: 2.5rem;
    margin:0.75rem;
    background-color:#fff;
    border-radius:23px;
    flex: 1 0 calc(25% - 1.5rem);
    overflow:hidden;
    display:flex;
    flex-direction:column;
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 8px;
    transition: all .3s;
    @media (max-width: 970px) {
        flex: 1 0 calc(33% - 1.5rem);
    }

    @media (max-width: 790px) {
        flex: 1 0 calc(50% - 1.5rem);
    }

    @media (max-width: 420px) {
        flex: 1 0 100%;
        margin:0.5rem 0;
    }

    &:hover{
        transform:scale(1.03);
        box-shadow: 20px 30px 40px -20px rgb(0 0 0 / 5%);
        cursor:pointer;
    }
    
`;

const Steps = styled.span`

    color:var(--brand-secondary);
    font-family: var(--font-primary);
    font-size: 2rem;
    line-height:1;
    display:inline-block;
    margin-bottom:1rem;
    @media (min-width: 800px) {
        font-size: 4rem;
        margin-bottom:2rem;
    }
`;



export {
    ChallengeGrid,
    ChallengeGridItem,
    Steps
}