import _ from 'lodash';
import React, { Component } from 'react'
import Card from './components/card';
import './cards.scss';

class Cards extends Component {

    onAddToCart = (e, added, _id) => {

        e.stopPropagation();

        if (!added) {

            this.props.onAddToCart(_id);

        } else {

            this.props.goTo(_id);

        }  

    }

    mainAction = (e, _id) => {

        e.stopPropagation();
        this.props.goTo(_id);

    }
    
    render() {

        const { currency, list = [] } = this.props;

        return (
                
            _.map(list, (item, index) => (

                <Card {...item } key={item._id + index } context={this.props.context} currency={currency} mainAction={this.mainAction} onAddToCart={this.onAddToCart} />
                
            ))

        )
    }
}

export default Cards