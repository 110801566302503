import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import React from 'react';

const Header = styled.header`

    position:sticky;
    top:0;
    z-index: 2;
    padding: 1.42rem 1.5rem;
    background:var(--brand-secondary);
    z-index:10;

    ${({active}) => active &&

    css`
        @media (max-width: 799px) {
            background:#fff;
            ${ToogleButtonBar} {

                &.bar-1 {
                    transform: translate(0, 0.5rem) rotate(45deg);
                    background-color: white;
                }

                &.bar-2 {
                    opacity:0;
                }

                &.bar-3 {
                    transform: translate(0, -0.56rem) rotate(-45deg);
                    background-color: white;
                }
            }

            ${Nav} {

                display:flex;
                flex-direction:column;
                width: 100%;
                height: 100vh;
                opacity:1;
                transition: opacity .5s;
                pointer-events:initial;
            }

        }
    `};

`;

const Nav = styled.nav`

    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    padding: 5rem 4rem;
    padding-bottom: 6rem;
    background-color: rgba(37, 43, 70, 0.95);
    backdrop-filter: blur(2px);
    opacity: 0;
    transition: all 0s 1s, opacity 1s;
    overflow: auto;
    pointer-events:none;

    @media (min-width: 800px) {
        display: initial;
        position: initial;
        width: 100%;
        height: initial;
        padding: 0;
        background-color: initial;
        backdrop-filter: initial;
        opacity: initial;
        transition: none;
        overflow: initial;
        pointer-events:initial;
    }

`;

const List = styled.ul`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    list-style: none;
    padding: 0;
    @media (min-width: 800px) {
        flex-direction:row;
        max-width: 62.4rem;
        margin: 0 auto;
        justify-content: space-between;
        .init{
            display:none;
        }
    }
`;

const ListItem = styled.li`

    width: 100%;
    max-width: 40rem;
    border-top: 1px solid #9194a1;
    padding: 1.5rem 0;
    
    @media (min-width: 800px) {
        border: none;
        padding: 0;
        width: auto;
        margin-right:1rem;
    }
`;

const NavLink = styled(({activeLink, ...rest})=> <Link {...rest} />)`

    color: white;
    font-size: 1.14rem;
    letter-spacing: 1.5px;
    text-decoration: none;
    text-transform: uppercase;
    font-family:PoppinsRegular;
    transition: color .3s;
    
    @media (max-width: 799px) {
        ${({activeLink}) => activeLink && css`color:var(--brand-secondary);`};
        &:hover{
            color:var(--brand-secondary);
        }
        &:focus{
            color:var(--brand-secondary);
        }
    }
    @media (min-width: 800px) {
        ${({activeLink}) => activeLink && css`color:var(--brand-primary);`};
        &:hover{
            color:var(--brand-primary);
            
        }
        &:focus{
            color:var(--brand-primary);
        }
    }
`;

const Content = styled.div`

    max-width:62rem;
    margin: 0 auto;
    display:flex;
    align-items:center;

`;

const ToogleButton = styled.button`

    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    height: 1.4rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    position: relative;
    z-index: 10;
    margin-left:auto;

    @media (min-width: 800px) {
        display:none;
    }
`;

const ToogleButtonBar = styled.span`

    display: inline-block;
    width: 1.20rem;
    height: 0.21rem;
    background-color: #fff;
    transition: all .5s;

`;


export {
    Header,
    Content,
    Nav,
    ToogleButton,
    ToogleButtonBar,
    List,
    ListItem,
    NavLink
}