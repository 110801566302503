import React, { Component } from 'react'
import I18n from '../../../../i18n';
import Topbar from '../../../components/navbar/topbar';
import sideBarProFileHoc from '../../../components/sidebar/profile';
import MenuProfile from '../../../components/menu/profile';
//styles
import '../profile_new.scss';
import WalletCard from '../../../components/wallet/components/cards';
import OButton from '../../../styled/components/button';
import { Icon, Message } from 'semantic-ui-react';
import { CardActionable, CircleItem, IconLink, WalletLinks, WrapperCard } from './styles';
import PaymentModel from '../../../../data/models/payment/payment';
import { NumericFormat } from '../../../components/numericFormat/NumericFormat';

class Userwallet extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userWallet: {}
        }

    }

    componentDidMount() {

        this.onGetData();

    }

    onGetData = async () => {

        try {

            const { data } = await PaymentModel.getUserWalletBalance();
            this.setState({userWallet: data});

        } catch (error) {

            console.error(error);

        }
    }

    render() {

        const { userWallet } = this.state;
        const { pendingOperations } = this.props;

        return (
            <div className="p-wrapper">
                <div className="inner">
                    <Topbar {...this.props} menuCallback={()=> this.props.onToggle()} showProfile={false} text={"Wallet"} invert={true} />
                    <div className="p-flex">
                        <div className="p-aside">
                            <MenuProfile active="wallet" {...this.props} />
                        </div>
                        <div className="p-content">
                            <WalletCard withBg maxW={'100%'}>
                                <WalletCard.Content>
                                    <WalletCard.Column>
                                        <p className="labeling white regular">{I18n.t('wallet.availableBalance')}</p>
                                        <p className="landing-heading-3 white ellipsis"><NumericFormat value={userWallet.balance} type="token" suffix="YGB"/></p>
                                        <p className="labeling white regular">({I18n.t('wallet.blockedBalance')} {<NumericFormat value={userWallet.blocked} type="token" suffix="YGB" />})</p>
                                    </WalletCard.Column>
                                    <WalletCard.Column isFlex flexDirection="column" alignItems="center" justifyContent="center">
                                        <OButton wallet upper type="button" onClick={() => this.props.history.push('/profile/wallet/packages')}><span>comprar ygb</span></OButton>
                                    </WalletCard.Column>
                                </WalletCard.Content>
                            </WalletCard>
                            <div className="spacing"/>
                            <WrapperCard>
                                <CardActionable role="button" tabIndex="0" onClick={() => this.props.history.push('/profile/wallet/receive')}>
                                    <CircleItem bg={'brand-terciary'}>
                                        <Icon name="long arrow alternate down" size="big" />
                                    </CircleItem>
                                    <p className="landing-heading-3 regular">{I18n.t('wallet.receiveYGB')}</p>
                                </CardActionable>
                                <CardActionable  role="button" tabIndex="0" onClick={() => this.props.history.push('/profile/wallet/send') }>
                                    <CircleItem bg={'brand-secondary'}>
                                        <Icon name="long arrow alternate up" size="big" />
                                    </CircleItem>
                                    <p className="landing-heading-3 regular">{I18n.t('wallet.sendYGB')}</p>
                                </CardActionable>
                            </WrapperCard>
                            <div className="spacing"/>
                            <WalletLinks onClick={() => this.props.history.push('/profile/wallet/reflink')} className="c-pointer">
                                <IconLink>
                                    <Icon name="linkify" />
                                </IconLink>
                                <p className="paragraph regular">{I18n.t('wallet.linkRef')}</p>
                            </WalletLinks>
                            <WalletLinks onClick={() => this.props.history.push('/profile/wallet/history')} className="c-pointer">
                                <IconLink>
                                    <Icon name="file alternate outline" />
                                </IconLink>
                                <p className="paragraph regular">{I18n.t('wallet.seeHistoryTransactions')}</p>
                            </WalletLinks>
                            {pendingOperations && <Message negative compact size='tiny' icon='warning circle'  header={<p className="paragraph">{I18n.t('messages.pendingOperations')}</p>} content={<p className="caption regular">{I18n.t('wallet.seePendingTransactions')}</p>} />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Wrapped = sideBarProFileHoc(Userwallet, 'wallet');

export default Wrapped;