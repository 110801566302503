import styled, {css} from 'styled-components/macro';

const ModalContent = styled.div`
    padding:0 20px 20px 20px;
`;
const FrameModal = styled.div``;
const FrameClose = styled.div`
    display:flex;
    justify-content:flex-end;
    padding: 10px 10px;
    position: relative;
    i {
        cursor:pointer;
        &:before{
            color:var(--brand-primary);
        }
    }
`;

const Actions = styled.div`
    margin-top:30px;
`;

const Content = styled.div`
    padding: 0 20px 20px 20px;
    margin: 0 -20px -20px -20px;
    ${p => p.scroll && ToScrolling};
`;

const ToScrolling = css`

    max-height: 70vh;
    overflow: auto;


`;

export {
    Actions,
    Content,
    ModalContent,
    FrameClose,
    FrameModal
}