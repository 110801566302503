// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux';

// Components
import ResultChallenges from '../../../../../components/diagnosis/components/challenges/result';
import StepsNav from '../../../components/stepsNav';
import ChallengeHeader from '../../../components/header';

// Redux
import { updateChallengeAvailibility } from '../../../../../../redux-store/challenges';
import { getBiometricMatrix } from '../../../../.../../../../redux-store/bioMetricMatrix';
import { openLoader, closeLoader } from '../../../../../../redux-store/loader';
import { getChallenges } from '../../../../../../redux-store/challenges';
import { getRole } from '../../../../../../redux-store/role';
import { openGenericModal, closeGenericModal } from '../../../../../../redux-store/genericModal';

// Models
import SecurityModel from '../../../../../../data/models/security/security';

// Utils
import { TrackingService } from '../../../.././../../utils/TrackingService';

// Config
import Config from '../../../../../../config';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import { Container, InnerContainer, Frame } from './styles';

class GrowChallengeResult extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }

    }

    componentWillUnmount() {

        this.props.closeGenericModal();

    }

    completeGrowChallenges = async () => {

        try {

            await SecurityModel.updateGrowChallengeStatus('finished');
            await this.props.getRole();

        } catch (error) {

        } finally {

            //mostrar popup de retos completados
            this.showCompleteChallengesModal();

        }

    }

    showCompleteChallengesModal = () => {

        this.props.openGenericModal({
            type: 'challengeCompleted',
            buttons:[
                {
                    text: I18n.t('modals.challengeCompleteModal.buttonText2'),
                    callback: ()=> {

                        this.props.closeGenericModal();
                        this.props.history.push('/home');

                    },
                    options: {
                        selected: true,
                        color: '#1bb89f',
                        fluid: true,
                        upper: true,
                    }
                }
            ]
        });

    }

    backToMeasurement = () => this.props.history.push(`/mode/grow/step/2/challenge/${this.props.match.params.challengeId}`);

    updateMatriz = async () => {

        const { userSession, role, customSessionStats, openLoader, updateChallengeAvailibility, getBiometricMatrix, match, getChallenges, history , closeLoader} = this.props;

        try {

            openLoader();
            await updateChallengeAvailibility({challengeId: match.params.challengeId});
            await getBiometricMatrix();

            if (match.params.challengeId === '5') {

                await getChallenges();

            }

        } catch (error) {

            closeLoader();

        } finally {

            setTimeout(() => {

                closeLoader();
                if (match.params.challengeId === '5') {

                    TrackingService.registerEvent('BiometricMatrix', 'completeBiometricMatrix');

                    if (_.get(role, 'attributes.growChallenge', false) && role.attributes.growChallenge === 'grow') {

                        const completedGrowChallenges = [];
                        //paso 2 terminado por defecto, siempre y cuando estemos en modo grow y no se haya finalizado en otro paso ya sea del modo grow o explorer
                        completedGrowChallenges[1] = 2;

                        //paso 2 completado
                        (userSession.name && userSession.surname) ? completedGrowChallenges[0] = 1 : completedGrowChallenges[0] = 0;

                        //comprobar si tiene el paso 3 terminado
                        (customSessionStats.userCustomSessionsCount || 0) >= Config.growChallenge.customPractices ? completedGrowChallenges[2] = 3 : completedGrowChallenges[2] = 0;

                        //si hay 0, es que le falta algun paso
                        const nextStepToCompleteIndex = completedGrowChallenges.indexOf(0);

                        if (nextStepToCompleteIndex > -1) {

                            history.push(`/mode/grow/step/${ nextStepToCompleteIndex + 1 }`);

                        } else {

                            //los retos están completados marcamos como finalizados
                            this.completeGrowChallenges();

                        }

                    } else {

                        history.push('/mode/grow/step/2');

                    }

                } else {

                    history.push('/mode/grow/step/2');

                }

            }, 2500);

        }

    }

    render() {

        const { match, userSession } = this.props;

        return (
            <Container>
                <InnerContainer>
                    <ChallengeHeader {...this.props} />
                    <StepsNav activedStep={2} {...this.props} />
                    <Frame>
                        <ResultChallenges userSession={userSession} challengeId={match.params.challengeId} onBackToMeasurement={this.backToMeasurement} onUpdateMatriz={this.updateMatriz} />
                    </Frame>
                </InnerContainer>
            </Container>
        )
    }
}

const mapStateToProps = state => {

    return {
        userSession: _.get(state,'auth.userSession', {}),
        role: _.cloneDeep(_.get(state,'role', {})),
        customSessionStats: _.get(state, 'customSessionStats', {})

    };
};

export default connect(mapStateToProps, {openGenericModal, closeGenericModal, getRole, updateChallengeAvailibility, getBiometricMatrix, openLoader, closeLoader, getChallenges })(GrowChallengeResult);

