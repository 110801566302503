// Third party libraries
import Fade from 'react-reveal/Fade';

// Locales
import I18n from '../../../../i18n';

const NextSlide = ({ visible, currentSlideIndex, routine }) => {

    const getNextSlideImage = () => {

        if (currentSlideIndex + 1 < routine.length) {

            return routine[currentSlideIndex + 1].image;

        }

        return false;

    }

    return (
        <Fade right when={ visible }>
            <div className='next-image'>
                <p>{ I18n.t('practice.nextAsana') }</p>
                { getNextSlideImage() && <img src={ getNextSlideImage() } alt='' /> }
            </div>
        </Fade>
    );

}

export default NextSlide;