import styled, {css} from 'styled-components';
import CardBg from '../../../../../assets/img/wallet_card.png';

const Card = styled.div`
    border-radius: 10px;
    overflow:hidden;
    max-width:${({maxW}) => maxW ? maxW : '35rem'};
    ${({withBg}) => withBg && defaultBg};
`;

const WrapperCard = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin: 0 -5px;
    & {
        ${Card} {
            flex:1 1 auto;
            margin: 5px;
            min-width:22.8rem;
            @media (max-width: 420px) {
                min-width:auto;
             }
        }
    }

`;

const CardContent = styled.div`
    display:flex;
`;

const CardColumn = styled.div`
    flex:1;
    padding: 2rem;
    min-width:0;
    ${({isFlex}) => isFlex && StyleFlex};
    @media (max-width: 420px) {
        padding: 1rem;
     }
`;

const StyleFlex = css`
    display:flex;
    flex-direction:${({flexDirection}) => flexDirection};
    align-items:${({alignItems}) => alignItems};
    justify-content:${({justifyContent}) => justifyContent};
`;

const defaultBg = css`
    background-image:url(${CardBg});
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
`;

// const defaultShadow = css`box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);`;


export {
    Card,
    CardContent,
    CardColumn,
    WrapperCard

}