import styled from 'styled-components/macro';
import { SectionLegacyImage } from '../../../styled';

const SectionContent = styled.div`
    text-align:start;
    flex: 1;
    max-width:40rem;
    margin-top:2rem;
    @media (min-width: 800px) {
        transform: translateX(-5rem);
        flex: 0 1 50%;
        margin-top:0;
    }
`;

const SectionContentBlock = styled.div`
    padding:1.5rem;
    margin-bottom:1rem;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    @media (min-width: 800px) {
        padding:3rem;
        background-color:#fff;
        border-radius:10px;
        overflow:hidden;
    }
`;

const SectionLMatrixImage = styled(SectionLegacyImage)`
    
        width: auto;
        height: auto;
        
    }
    @media (min-width: 800px) {
        flex: 0 0 51%;
        img {
            border-radius: 0 150px;
        }
    }
`;

const LayoutVideo = styled.div`

    width:100%;
    overflow:hidden;
    padding-top:56.25%;
    height:0;
    position:relative;
`;

const IframeWrapper = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
`;

export {
    SectionContent,
    SectionContentBlock,
    SectionLMatrixImage,
    LayoutVideo,
    IframeWrapper
}