import styled from 'styled-components/macro';


const Frame = styled.div`
    padding:32px; 
    width:100%;
    max-width:1440px;
    margin: 0 auto;
    @media only screen and (max-width: 420px) {
        padding:20px; 
    }
`;

const FrameGrid = styled.div`
    flex-grow:1;
    flex-shrink:0;
    flex-basis:auto;
    background-color:rgba(var(--brand-secondary-rgb),0.1);
    width:100%;
`;

const FooterResume = styled.div`
    flex:none;
    background-color:var(--brand-secondary);
    

`;

const FooterResumeInner = styled.div`
    padding: 20px 32px;
    max-width:1440px;
    width:100%;
    margin:0 auto;
`;

export {
    Frame,
    FrameGrid,
    FooterResume,
    FooterResumeInner
}