import React from 'react'
import I18n from '../../../i18n';
import { GuideCol, GuideOptionsGrid } from './styles'
import codeColorImage from '../../assets/img/color_code.png';
import completeMatrizImage from '../../assets/img/guia_matriz_image.png';
import OButton from '../../styled/components/button';

function GuideOptionsElement({ option1Route, option2Route }) {
    
    return (
        <>
           <GuideOptionsGrid>
               <GuideCol textBlock>
                    <p className="heading-2">{I18n.t('guide.howToCompleMatrix')}</p>
                    <p className="heading-3 regular">{I18n.t('guide.howToCompleMatrixText')}</p>
                    <OButton type="button" selected upper color="#1bb89f" onClick={option1Route}>
                        <span>{I18n.t('actions.seeMore')}</span>
                    </OButton>
               </GuideCol>
               <GuideCol style={{padding: '0'}}>
                    <img src={completeMatrizImage} alt="" />
               </GuideCol>
               <GuideCol>
                    <img src={codeColorImage} alt="r" />
               </GuideCol>
               <GuideCol toRight textBlock>
                    <p className="heading-2">{I18n.t('guide.codeColor')}</p>
                    <p className="heading-3 regular">{I18n.t('guide.codeColorText')}</p>
                    <OButton type="button" selected upper color="#1bb89f" onClick={option2Route}>
                        <span>{I18n.t('actions.seeMore')}</span>
                    </OButton>
               </GuideCol>
            </GuideOptionsGrid> 
        </>
    )
}

export default GuideOptionsElement;