import styled from 'styled-components/macro';


const Frame = styled.div`
    padding:32px; 
    width:100%;
    max-width:1440px;
    margin: 0 auto;
    @media only screen and (max-width:420px) {
        padding:20px; 
    }
`;
const FrameMatrix = styled(Frame)`
    padding:0 32px 32px; 
    @media only screen and (max-width:420px) {
        padding:0 20px; 
    }
   
`;
const FrameGrid = styled.div`
    flex-grow:1;
    flex-shrink:0;
    flex-basis:auto;
    background-color:rgba(var(--brand-secondary-rgb),0.1);
    width:100%;
`;

const FrameInfo = styled.div`
    padding: 0 32px 32px 32px;
    width:100%;
    max-width:1440px;
    margin: 0 auto;
    @media only screen and (max-width:420px) {
        padding:0 20px 20px; 
    }
`;

export {
    Frame,
    FrameInfo,
    FrameGrid,
    FrameMatrix
    
}