import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ChallengeBackground from '../../../../components/diagnosis/components/challenges/header';
import { Container, InnerContainer } from '../../styles';
import I18n from '../../../../../i18n';
import { Frame, FrameBackGround } from './styles';
import Grid from '../../../../components/grid';
import StepsNav from '../../components/stepsNav';
import ChallengeHeader from '../../components/header';
import ProgressIndicator from '../../../../components/progressIndicator';
import SecurityModel from '../../../../../data/models/security/security';
import { getRole } from '../../../../../redux-store/role';
import { Trans } from 'react-i18next';

 class Challenge extends Component {
    constructor(props) {
        super(props)

        this.state = {
                 
        }

    }

    componentDidMount() {
        
        this.checkGrowChallengeStatus();

    }

    checkGrowChallengeStatus = async () => {

        const { role, getRole } = this.props;

        if (_.get(role, 'attributes.growChallenge', false) && role.attributes.growChallenge === 'explorer') {

            try {
                
                await SecurityModel.updateGrowChallengeStatus('grow');
                await getRole();

            } catch (error) {

                
            }

        }

    }

    selectAsana = asanaId => this.props.history.push(`/mode/grow/step/2/challenge/${this.props.match.params.challengeId}/asana/${asanaId}`);

    render() {

        const { challenges, match, bioMetricMatrix} = this.props;

        return (
            <Container>
                <InnerContainer>
                    <ChallengeHeader {...this.props} />
                    <StepsNav activedStep={2} {...this.props} />
                    <Frame>
                        <ProgressIndicator progress={ bioMetricMatrix.progress || 0 } title={ I18n.t('steps.step2.title') } progressText={ I18n.t('steps.step2.progress.matrix', { progress: bioMetricMatrix.progress || 0 }) } />
                    </Frame>
                    <FrameBackGround>
                        <ChallengeBackground challenges={challenges} currentIndex={match.params.challengeId}/>
                    </FrameBackGround>
                    <Frame>
                        <p className="heading-2">{I18n.t('diagnosis.target')}</p>
                        <p className="paragraph regular">{challenges[match.params.challengeId].targetText}</p>
                        <p className="paragraph regular">
                            <Trans i18nKey="diagnosis.extraMessage">
                                <b></b>&#174;<b></b><i></i><b></b><a className="brand-secondary" target="_blank" rel="noopener noreferrer" href="https://academianexoyoga.com/p/yoga-a-tu-medida/"></a>
                            </Trans>
                        </p>
                    </Frame>
                    <Frame>
                        <Grid mainAction={(index)=>this.selectAsana(index)} type="asana" {...this.props}/>
                    </Frame>
                </InnerContainer>
            </Container>
        )
    }

}

const mapStateToProps = state => {

    return {
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        challenges: _.get(state,'challenges.challenges', []),
        auth: state.auth,
        role: _.cloneDeep(_.get(state,'role', {})),
        
    };
};

export default connect(mapStateToProps, {getRole})(Challenge);