import styled from 'styled-components/macro';

const Header = styled.div`
    padding:20px 32px;
    display:flex;
    justify-content:space-between;
    flex:none;
    width:100%;
    max-width:1440px;
    margin: 0 auto;
    @media only screen and (max-width:420px) {
        padding:20px; 
    }
`;

export {
    Header
};