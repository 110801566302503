// Third party libraries
import React from 'react';

// Components
import OButton from '../../styled/components/button';

// Config
import Config from '../../../config';

// Locales
import I18n from '../../../i18n';

// Styles
import { FrameBody, FrameContent, FrameProgress, Progress, ProgressBar, ProgressInner } from './styles';

/***
 * * RESUME PROGRESS MODAL
 * @param step1Progress Number
 * @param step2Progress Number
 * @param step3Progress Number
 * @param remainingDays Number
 * @param buttons Array Objects
 * @description
 *
 */

export default function ResumeProgress({  step1Progress, step2Progress, step3Progress, remainingDays, button,  ...restProps }) {

    return (
        <FrameBody>
            <FrameContent>
                <p className="paragraph" style={{marginBottom: '5px'}}>{I18n.t('modals.resumeProgressModal.title')}</p>
                <p className="paragraph regular">{I18n.t('modals.resumeProgressModal.remainingDays', { remainingDays })} </p>
                <FrameProgress>
                    <Progress>
                        <span className="caption">{I18n.t('modals.resumeProgressModal.progress1Text')}</span>
                        <span className="caption">{step1Progress}%</span>
                    </Progress>
                    <ProgressBar>
                        <ProgressInner progress={step1Progress}></ProgressInner>
                    </ProgressBar>
                    <Progress>
                        <span className="caption">{I18n.t('modals.resumeProgressModal.progress2Text')}</span>
                        <span className="caption">{step2Progress}%</span>
                    </Progress>
                    <ProgressBar>
                        <ProgressInner progress={step2Progress}></ProgressInner>
                    </ProgressBar>
                    <Progress>
                        <span className="caption">{I18n.t('modals.resumeProgressModal.progress3Text')}</span>
                        <span className="caption">{step3Progress}</span>
                    </Progress>
                    <ProgressBar>
                        <ProgressInner progress={ Math.min(Math.round(step3Progress / Config.growChallenge.customPractices * 100), 100) }></ProgressInner>
                    </ProgressBar>
                </FrameProgress>
                <OButton type="button" {...button.options} onClick={ button.callback }><span>{button.text}</span></OButton>
            </FrameContent>
        </FrameBody>
    )

}