import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import I18n from '../../../i18n';
import { Trans } from 'react-i18next';

import { closeWelcomeModal } from '../../../redux-store/welcomeModal';

import CountDownSimple from '../../components/countDownSimple';

import OButton from '../../styled/components/button';

import Utils from '../../../utils';
import { getOneYearPaymentPromotionPrice, hasOneYearPaymentPromotion } from '../../../utils/checkPromotions';

import history from '../../../history';

import './welcomeModal.scss';

class WelcomeModal extends Component {
    
    onAccept = () => {

        this.props.closeWelcomeModal();
        hasOneYearPaymentPromotion() ? history.push('/profile/promotion') : history.push('/profile/plans')
        
    }

    promoCard = () => {

        const { currency } = this.props;

        //TODO, el valor de count da NaN
        
        return (
            <div className="welcome-card">
                <div className="welcome-card__header">
                    <div className="welcome-card__title">
                        <span>{I18n.t('modals.welcomeModal.promo.title')}</span>
                    </div>
                </div>
                <div className="welcome-card__body">
                    <div className="welcome-card__description">
                        <span>
                            <Trans i18nKey="modals.welcomeModal.promo.bodyText1" count={Utils.getAmountLiteral(_.round(getOneYearPaymentPromotionPrice()[currency] / 12, 2), currency)}>
                                <strong></strong>
                            </Trans>
                        </span>
                    </div>
                </div>
                <div className="welcome-card__description welcome-card__description--grey">
                    <p>{I18n.t('modals.welcomeModal.promo.bodyText2')}</p>
                </div>
                <div className="welcome-card__counter">
                    <CountDownSimple endDate="2020-08-01T00:00:00+00:00" />
                </div>
                <div className="welcome-card__footer">
                    <span onClick={this.onAccept}>{I18n.t('modals.welcomeModal.promo.footerText')}</span>
                </div>
            </div>
        );

    }

    noPromoCard = () => {

        return ( 
            <div className="welcome-card">
                <div className="welcome-card__header">
                    <div className="welcome-card__title">
                        <span>{I18n.t('modals.welcomeModal.noPromo.title').toLocaleUpperCase()}</span>
                    </div>
                </div>
                <div className="welcome-card__body">
                    <div className="welcome-card__description">
                        <span>
                            <Trans i18nKey="modals.welcomeModal.noPromo.bodyText1">
                                <b></b>
                            </Trans>
                        </span>
                    </div>
                </div>
                <div className="welcome-card__details">
                    <ul>
                        <li dangerouslySetInnerHTML={{__html: I18n.t('modals.welcomeModal.noPromo.listText1')}}></li>
                        <li dangerouslySetInnerHTML={{__html: I18n.t('modals.welcomeModal.noPromo.listText2')}}></li>
                        <li dangerouslySetInnerHTML={{__html: I18n.t('modals.welcomeModal.noPromo.listText3')}}></li>
                    </ul>
                </div>
                <div className="welcome-card__footer">
                    <OButton primary type="button" fluid upper color="#fff" onClick={this.onAccept}>
                        <span>{I18n.t('modals.welcomeModal.noPromo.footerText')}</span>
                    </OButton>
                </div>
            </div>
        );

    }

    render() {

        return (
            <Modal open={ this.props.welcomeModal.open || false } className="welcome-modal" size="tiny">
                <Modal.Header className="top-header-block"><i className="f-icon-cancel" onClick={this.props.closeWelcomeModal}></i></Modal.Header>
                <Modal.Content>
                    <Modal.Description className="description-block">
                        <p className="description-title">{I18n.t('modals.welcomeModal.main.title')}</p>
                        <p className="description-title">{I18n.t('modals.welcomeModal.main.subtitle')}</p>
                        <p className="description-title">{I18n.t('modals.welcomeModal.main.description')}</p>
                    </Modal.Description>
                    { hasOneYearPaymentPromotion() ? this.promoCard() : this.noPromoCard() }
                    <div className="wrapper-info-btn">
                        <OButton terciary type="button" fluid upper onClick={this.props.closeWelcomeModal}>
                            <span>{I18n.t('modals.welcomeModal.main.action')}</span>
                        </OButton>
                    </div>
                </Modal.Content>
            </Modal>
        );

    }

}

const mapStateToProps = state => {

    return {
        currency: _.get(state, 'auth.userSession.config.currency', {}),
        welcomeModal: _.cloneDeep(state.welcomeModal)
    };

};

export default connect(mapStateToProps, { closeWelcomeModal })(WelcomeModal);