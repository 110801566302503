import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';

import Footer from '../../../../components/footer';
import GradientNav from '../../../../components/navbar/gradient';
import SubMenu from '../../../../components/navbar/submenu';
import TopBar from '../../../../components/navbar/topbar';
import FixedBanner from '../../../../components/fixedBanner';
import Notification from '../../../../components/notificaction';
import UseNoSleep from '../../../../../utils/noSleep';

import { updateAsana } from '../../../../../redux-store/challenges';
import I18n from '../../../../../i18n';
import playImage from '../../../../assets/img/play.svg';
import { Container, FrameBlock, InnerContainer } from '../sharedStyles';
import VideoInfo from '../../../../components/diagnosis/components/challenges/videoInfo';
import AsanaForm from '../../../../components/diagnosis/components/challenges/asanaForm';

class DiagnosisChallengeAsana extends React.Component {

    interval = 0;
    intervalCountDown = 0;
    startDate = null;

    constructor(props) {

        super(props);

        this.state = {
            showControls: false,
            indexAsana: this.props.match.params.asanaId,
            indexChallenge: this.props.match.params.challengeId,
            challengeTitle: '',
            currentAsana: {},
            secondsElapsed: 0,
            showStartMessage: false,
            countDown: 3
        }

        this.videoRef = React.createRef();
        
    }

    componentWillUnmount() {

        clearInterval(this.interval);
        clearInterval(this.intervalCountDown);

    }

    componentDidMount() {

        if (_.get(this.props, `challenges[${this.props.match.params.challengeId}].locked`, true)) {

            this.props.history.push('/home');
           
        }

        let currentAsana = _.cloneDeep(this.props.challenges[this.state.indexChallenge].asanas[this.state.indexAsana]);
        this.setState({
            currentAsana: currentAsana, 
            challengeTitle: this.props.challenges[this.state.indexChallenge].description, 
            indexAsana: this.props.match.params.asanaId
        });

        this.videoRef.current.setAttribute('webkit-playsinline', true);
        
    }

    componentDidUpdate (prevProps, prevState) {

        if (_.get(this.props, `challenges[${this.props.match.params.challengeId}].locked`, true)) {

            this.props.history.push('/home');
           
        }

        if (prevState.indexAsana !== this.state.indexAsana) {

            let currentAsana = _.cloneDeep(this.props.challenges[this.state.indexChallenge].asanas[this.state.indexAsana]);
            this.setState({
                currentAsana, 
                challengeTitle: this.props.challenges[this.state.indexChallenge].description, 
                secondsElapsed: 0, 
                countDown: 3, 
                showStartMessage: false
            });
            clearInterval(this.interval);
            clearInterval(this.intervalCountDown);
            $('video')[0].load();

        }

    }

    onSelectAsana = async value => {

        let currentAsana = {...this.state.currentAsana};
        currentAsana.selectedValue = value;
        this.setState({currentAsana});

        try {

            await this.props.updateAsana({
                challengeId: this.state.indexChallenge, 
                asanaId: this.state.indexAsana, 
                selectedValue: value
            });

        } catch (errorCode) {
            
            console.error('error: onSelectAsana', errorCode);

        }
        
    }

    onNextMeasurement = () => {

        let indexAsana = this.state.indexAsana;
        indexAsana++;
        this.setState({ indexAsana });
        this.props.history.push(`/diagnosis/challenge/${this.state.indexChallenge}/asana/${indexAsana}`);
        //$('.wrapper-diagnosis').scrollTop(0);
        $('#overlay').show();

    }

    onPreviousMeasurement = () => {

        let indexAsana = this.state.indexAsana;
        indexAsana--;
        this.setState({indexAsana: indexAsana});
        this.props.history.push(`/diagnosis/challenge/${this.state.indexChallenge}/asana/${indexAsana}`);
        //$('.wrapper-diagnosis').scrollTop(0);
        $('#overlay').show();

    }

    onFinalizeMeasurement = () => {

        this.props.history.push(`/diagnosis/challenge/${this.props.match.params.challengeId}/result`);
        
    }

    playVideo = () => {

        $('video')[0].play();
        $('#overlay').hide();
        this.setState({ showControls: true });

    }

    tickWatchClock = () => {

        if (this.state.secondsElapsed >= this.state.currentAsana.ranges.max ) {

            clearInterval(this.interval);

        }

        this.setState({
            secondsElapsed: Math.round((Date.now() - this.startDate) / 1000)
        });

    }


    tickCountDown = () => {

        this.setState({
            countDown: Math.max(this.state.countDown - 1, 0)
        });

        if (this.state.countDown <= 0) {
            this.setState({showStartMessage: false}, () => {

                clearInterval(this.intervalCountDown);
                this.startWatchClock();

            });
            
        }

    }

    startCountDown = () => {

        clearInterval(this.intervalCountDown);
        clearInterval(this.interval);
        this.setState({
            showStartMessage: true, 
            countDown: 3, 
            secondsElapsed: 0
        });
        this.intervalCountDown = setInterval(this.tickCountDown, 1000);
        
    }
    
    startWatchClock = () => {

        this.startDate = Date.now();
        this.interval = setInterval(this.tickWatchClock, 500);

    }

    render() {

        const { showControls, secondsElapsed, showStartMessage, countDown, indexAsana, indexChallenge, currentAsana, challengeTitle } = this.state;
        const { match, history, challenges } = this.props;
        const _currentChallenge = +match.params.challengeId + 1;
        const _currentAsana = +match.params.asanaId + 1;

        return (
            <React.Fragment>
                <UseNoSleep />
                <GradientNav active="diagnosis" {...this.props}/>
                <Container>
                    <TopBar {...this.props} callback={()=>history.push(`/diagnosis/challenge/${match.params.challengeId}`)} text={I18n.t('practice.exercisePath', {challenge: _currentChallenge, asana: _currentAsana})}/>
                    <FixedBanner />
                    <InnerContainer>
                        <FrameBlock>
                            <p className="heading-2" style={{margin: '0'}}>{challengeTitle}</p>
                            <p className="paragraph">{currentAsana.subtitle}</p>
                        </FrameBlock>
                        <FrameBlock>
                            <VideoInfo playImage={ playImage } callback={this.playVideo} src={ currentAsana.video } ref={ this.videoRef } controls={ showControls } key={ currentAsana } />
                        </FrameBlock>
                        <FrameBlock>
                            <AsanaForm onPreviousMeasurement={this.onPreviousMeasurement} onNextMeasurement={this.onNextMeasurement} onFinalizeMeasurement={this.onFinalizeMeasurement} indexChallenge={indexChallenge} indexAsana={indexAsana} challenges={challenges} countDown={countDown} currentAsana={currentAsana} secondsElapsed={secondsElapsed} onStartCountDown={ this.startCountDown } showStartMessage={showStartMessage} onSelectAsana={this.onSelectAsana}/>
                        </FrameBlock>
                    </InnerContainer>
                    <div className="footer-separator"></div>
                    <Footer {...this.props} type="branded"/>
                </Container>
                <SubMenu active="diagnosis" {...this.props}/>
                <Notification  type="fixered" />
            </React.Fragment>
        );

    }

}

const mapStateToProps = state => {

    return {
        challenges: _.get(state,'challenges.challenges', [])
        
    };
};

export default connect(mapStateToProps, { updateAsana })(DiagnosisChallengeAsana);