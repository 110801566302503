// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import I18n from '../../../../i18n';
import { connect } from 'react-redux';
import { Field, initialize, reduxForm, autofill } from 'redux-form';


// Components
import ChallengeHeader from '../components/header';
import StepsNav from '../components/stepsNav';
import CustomInput from '../../../components/form/input';
import CustomSelect from '../../../components/form/select';
import InputFile from '../../../components/form/inputFile';

// Styled components
import { FrameContent, Actions, FrameForm, Frame } from './styles';
import OButton from '../../../styled/components/button';
import { LabelForm, Picture, PictureContent } from '../../profile/components/styles';
import { InnerContainer, Container } from '../styles';

// Redux
import { updateProfile } from '../../../../redux-store/auth';
import { openToast } from '../../../../redux-store/toast';
import { getCustomSessionStats } from '../../../../redux-store/customSessionStats';
import { getRole } from '../../../../redux-store/role';
import { openGenericModal, closeGenericModal } from '../../../../redux-store/genericModal';

// Models
import SecurityModel from '../../../../data/models/security/security';
import FilesModel from '../../../../data/models/files';

// Constants
import { countries }  from '../../../../config/constants/countries';
import Config from '../../../../config';

// Assets
import DemoImage from '../../../assets/img/yogabot_icon.svg';

class Step1 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            codeError: -1,
            hasError: false,
            genderOptions : [
                {
                    key: 1,
                    value: 1,
                    text: I18n.t('profile.male')
                },
                {
                    key: 2,
                    value: 2,
                    text: I18n.t('profile.female')
                }
            ],
            optionsCurrency : [
                {
                    key: 1,
                    value: 'EUR',
                    text: I18n.t('profile.euro')
                },
                {
                    key: 2,
                    value: 'USD',
                    text: I18n.t('profile.dollar')
                }
            ],
            optionsAudio : [
                {
                    key: 1,
                    value: 'NES',
                    text: I18n.t('profile.spanish')
                },
                {
                    key: 2,
                    value: 'SES',
                    text: I18n.t('profile.sanscrito')
                }
            ],
            optionsLevel : [
                {
                    key: 1,
                    value: '01',
                    text: I18n.t('profile.rookie')
                },
                {
                    key: 2,
                    value: '00',
                    text: I18n.t('profile.advanced')
                }
            ],

            countries : _.cloneDeep(countries)
        }
    }

    componentWillUnmount() {

        this.props.closeGenericModal();

    }

    componentDidMount() {

        const initialValues = _.merge({pictureUrl: '',country: 'es', gender: 2, config : { currency : 'USD', practiceAudioLanguage: 'NES', practiceLevel: '01',  practicePresentationType: 'image'}}, this.props.userSession);
        this.props.dispatch(initialize(
            'step1Form',
            initialValues
        ));

        //obtener los últimos valores de la practica personaliza..paso 3
        this.getCustomChallengeStats();

    }

    getCustomChallengeStats = async () => {

        try {

            await this.props.getCustomSessionStats();

        } catch (error) {

        }

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.userSession, this.props.userSession)) {

            const initialValues = _.merge({pictureUrl: '',country: 'es', gender: 2, config : { currency : 'USD', practiceAudioLanguage: 'NES', practiceLevel: '01',  practicePresentationType: 'image'}}, this.props.userSession);
            this.props.dispatch(initialize(
                'step1Form',
                initialValues
            ));

        }

    }

    completeGrowChallenges = async () => {

        try {

            await SecurityModel.updateGrowChallengeStatus('finished');
            await this.props.getRole();

        } catch (error) {

        } finally {

            //mostrar popup de reto completados
            this.showCompleteChallengesModal();

        }

    }

    showCompleteChallengesModal = () => {

        this.props.openGenericModal({
            type: 'challengeCompleted',
            buttons:[
                {
                    text: I18n.t('modals.challengeCompleteModal.buttonText2'),
                    callback: ()=> {

                        this.props.closeGenericModal();
                        this.props.history.push('/home');

                    },
                    options: {
                        selected: true,
                        color: '#1bb89f',
                        fluid: true,
                        upper: true,
                    }
                }
            ]
        });

    }

    onUpdateProfile = async data => {

        if (!_.isEmpty(window['tempFile']) ) {

            let formData = new FormData();
            let tempData = _.cloneDeep(data);
            formData.append('avatar', window['tempFile'].ref);

            try {

                const response = await FilesModel.uploadImage(formData);

                if (_.get(response,'data.data.url', false)) {

                    tempData.pictureUrl = response.data.data.url;

                }

            } catch (error) {

                delete tempData.pictureUrl;

            } finally {

                this.updateProfile(tempData);

            }

        } else {

            this.updateProfile(data);

        }

    }

    updateProfile = async credentials => {

        const { role, bioMetricMatrix, customSessionStats, history, updateProfile } = this.props;
        const { completeGrowChallenges } = this;

        try {

            await updateProfile({

                name: credentials.name,
                surname: credentials.surname,
                country: credentials.country,
                gender: credentials.gender,
                pictureUrl: credentials.pictureUrl || '',
                config: {...credentials.config}

             });

             //comprobar que paso tiene terminado
             if (_.get(role, 'attributes.growChallenge', false) && role.attributes.growChallenge === 'grow') {

                const completedGrowChallenges = [];
                //paso 1 terminado por defecto, siempre y cuando estemos en modo grow y no se haya finalizado en otro paso ya sea del modo grow o explorer
                completedGrowChallenges[0] = 1;
                //paso 2 completado?
                (bioMetricMatrix.progress || 0) >= 100 ? completedGrowChallenges[1] = 2 : completedGrowChallenges[1] = 0;
                //comprobar si tiene el paso 3 terminado
                (customSessionStats.userCustomSessionsCount || 0) >= Config.growChallenge.customPractices ? completedGrowChallenges[2] = 3 : completedGrowChallenges[2] = 0;

                const nextStepToCompleteIndex = completedGrowChallenges.indexOf(0);

                //si hay algun 0, significa que falta algun reto
                if (nextStepToCompleteIndex > -1) {

                    history.push(`/mode/grow/step/${ nextStepToCompleteIndex + 1 }`);

                } else {

                    //los retos están completados marcamos como finalizados
                    completeGrowChallenges();

                }

             } else {

                    history.push(`/mode/grow/step/2`);

             }

        } catch (codeError) {

            openToast({message: I18n.t(`status.code.${codeError}`), type: 'error'});

        } finally {

            //TODO, ver si queremos añadir tracking

        }

    };

    onRemove = path => {

        this.props.dispatch(autofill('step1Form', path, ''));
        window['tempFile'] = undefined;

    }

    render() {

        const { role, invalid } = this.props;
        const roles = _.get(role || {}, 'role', []);

        const optionsType =  [{
            key: 1,
            value: 'image',
            text: I18n.t('profile.image')
        }, {
            key: 2,
            value: 'video',
            text: I18n.t('profile.video'),
            disabled: !roles.includes('premium')
        }];

        const  { genderOptions, optionsCurrency, optionsAudio, optionsLevel } = this.state;

        const { reduxFormState } = this.props;

        const pictureSrc = !_.isEmpty(_.get(window,'tempFile.raw',[])) ? window['tempFile'].raw : !_.isEmpty(reduxFormState.pictureUrl) ? reduxFormState.pictureUrl : DemoImage;

        return (
            <Container>
                <InnerContainer>
                    <ChallengeHeader {...this.props} />
                    <StepsNav activedStep={1} {...this.props} />
                    <FrameContent>
                        <Form name="step1Form" noValidate onSubmit={this.props.handleSubmit(this.onUpdateProfile)}>
                            <p className="heading-2" style={{marginTop: '20px', marginBottom: '15px'}}>{I18n.t('steps.step1.title')}</p>
                            <div className="verticalPad" style={{paddingTop: '0'}}>
                            <LabelForm>{I18n.t('profile.profilePicture')}</LabelForm>
                            <PictureContent>
                                <Picture>
                                    <img src={ pictureSrc } alt="" onError={(e)=> e.target.src = DemoImage } />
                                </Picture>
                                <OButton primary type="button" color="#fff" size="small" style={{overflow: 'hidden'}}>
                                    <span>{_.isEmpty(reduxFormState.pictureUrl) ? I18n.t('actions.addImage') : I18n.t('actions.editImage')}</span>
                                    <Field
                                        className="hide-input"
                                        component={ InputFile }
                                        name="pictureUrl"
                                    />
                                </OButton>
                                { !_.isEmpty(reduxFormState.pictureUrl) &&
                                    <OButton  selected type="button" color="#FF6767" size="small" onClick={ ()=> this.onRemove('pictureUrl')}>
                                        <span>{I18n.t('actions.remove')}</span>
                                    </OButton>
                                }
                            </PictureContent>
                        </div>
                            <FrameForm>
                                <Frame>
                                    <p className="heading-2 regular" style={{marginBottom: '0'}}>{I18n.t('steps.step1.text1')}</p>

                                    <div className="verticalPad">
                                        <Field
                                            component={ CustomInput }
                                            placeholder={ I18n.t('profile.name') }
                                            name="name"
                                            fieldClasses="label-primary"
                                            label={ I18n.t('profile.name') } />
                                    </div>
                                    <div className="verticalPad">
                                        <Field
                                            component={ CustomInput }
                                            placeholder={ I18n.t('profile.surname') }
                                            name="surname"
                                            label={ I18n.t('profile.surname') }
                                            fieldClasses="label-primary" />
                                    </div>
                                    <div className="verticalPad">
                                        <Field
                                            component={ CustomSelect }
                                            name='country'
                                            options={countries}
                                            search={true}
                                            fieldClasses="label-primary"
                                            label={ I18n.t("auth.country") } />
                                    </div>
                                    <div className="verticalPad">
                                        <Field
                                            component={ CustomSelect }
                                            name='gender'
                                            options={genderOptions}
                                            fieldClasses="label-primary"
                                            label={ I18n.t("auth.gender") } />
                                    </div>
                                </Frame>
                                <Frame>
                                    <p className="heading-2 regular" style={{marginBottom: '0'}}>{I18n.t('steps.step1.text2')}</p>
                                    <div className="verticalPad">
                                            <Field
                                                component={ CustomSelect }
                                                name='config.currency'
                                                options={optionsCurrency}
                                                fieldClasses="label-primary"
                                                label={ I18n.t("auth.currency") } />
                                        </div>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomSelect }
                                                name='config.practiceAudioLanguage'
                                                options={optionsAudio}
                                                label={ I18n.t("auth.practiceAudioLanguage") }
                                                fieldClasses="label-primary" />
                                        </div>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomSelect }
                                                name='config.practiceLevel'
                                                options={optionsLevel}
                                                label={ I18n.t("auth.practiceLevel") }
                                                fieldClasses="label-primary" />
                                        </div>
                                        <div className="verticalPad">
                                            <Field
                                                component={ CustomSelect }
                                                name='config.practicePresentationType'
                                                options={optionsType}
                                                label={ I18n.t("auth.practicePresentationType") }
                                                fieldClasses="label-primary" />
                                        </div>
                                </Frame>
                            </FrameForm>
                            <Actions>
                                <OButton disabled={invalid} primary upper type="submit" color="#fff"><span>{I18n.t('actions.continue')}</span></OButton>
                            </Actions>
                        </Form>
                    </FrameContent>
                </InnerContainer>
            </Container>
        );
    }
}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.name)) {

        errors.name = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.surname)) {

        errors.surname = I18n.t('validations.required');

    }

    return errors;

};

const mapStateToProps = state => {

    return {
        userSession: _.get(state,'auth.userSession', {}),
        role: _.cloneDeep(_.get(state,'role', {})),
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        customSessionStats: _.get(state, 'customSessionStats', {}),
        reduxFormState: _.get(state, 'form.step1Form.values', {})
    };
};

export default reduxForm({
    form: 'step1Form',
    touchOnBlur: true,
    touchOnChange: false,
    validate
})(connect(mapStateToProps, { getCustomSessionStats, openGenericModal, closeGenericModal, getRole, updateProfile, openToast })(Step1));
