import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const getProducts = async () => await AxiosService.get(Config.server.api.schoolApi + 'product', {}, true);
const getProduct = async productId => await AxiosService.get(Config.server.api.schoolApi + `product/${productId}`, {}, true);
const getUserProducts = async () => await AxiosService.get(Config.server.api.schoolApi + 'user/product', {}, true);

export default {
    getProducts,
    getProduct,
    getUserProducts
}