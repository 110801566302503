// Third party libraries
import { Responsive } from 'semantic-ui-react';

// Utils
import { toMMSS } from '../../../../utils/formatTime';

const PlayerTimer = ({ visible, slideElapsedTime }) => {

    return (visible &&
        <>
            <Responsive minWidth={ 620 } className='counter-practice'>
                <div className='right-block'>
                    <p className='countdown'>{ toMMSS(slideElapsedTime) }</p>
                </div>
            </Responsive>
            <Responsive maxWidth={ 619 } className='counter-practice'>
                <div className='right-block'>
                    <p className='countdown'>{ toMMSS(slideElapsedTime) }</p>
                </div>
            </Responsive>
        </>
    );

}

export default PlayerTimer;