import styled, { css } from 'styled-components/macro';


const FrameContent = styled.div`
    padding: 0 32px 32px; 
`;

const IconFrame = styled.div`
    margin-bottom: 20px;
    i {
        &:before{
            color: var(--brand-primary-extra-light);
        }
    }
`;

const Actions = styled.div`
    margin-top:30px;
    @media (max-width: 420px) {
        display:flex;
        flex-direction:column;
    }
`;

const Steps = styled.div`
    margin-top:20px;
`;

const Step = styled.div`
    border-bottom:solid 1px lightgray;
    padding:0 10px 10px 0;
    margin-top:10px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
`;

const Check = styled.div`
    i {
        &:before {
            color:var(--brand-secondary);
        }
    }

    ${({firstStep}) => firstStep &&
        css`
           min-width:28px;
           min-height:28px;
           background:var(--brand-secondary);
           border-radius:28px;
           display:flex;
           justify-content:center;
           align-items:center;
           margin-right: 9px;
           i {
            font-size:1.1em!important;
            margin:0!important;
            &:before {
                color:var(--brand-white);
            }
        }
        `};
`;

const Text = styled.div`
    margin-left:15px;
`;


export {
    
    IconFrame,
    FrameContent,
    Actions,
    Steps,
    Step,
    Check,
    Text

};