import React, { Component } from 'react';
import ChallengeHeader from '../components/header';
import { FrameContent, IconFrame, Actions, Steps, Step, Check, Text } from './styles';
import { InnerContainer, Container } from '../styles';

import { Icon } from 'semantic-ui-react';
import OButton from '../../../styled/components/button';
import I18n from '../../../../i18n';

class Step0 extends Component {
    render() {
        return (
            <Container>
                <InnerContainer>
                    <ChallengeHeader {...this.props} showResume={false}/>
                    <FrameContent>
                        <IconFrame>
                            <Icon name="trophy" size="huge" />
                        </IconFrame>
                        <p className="heading-2 brand-secondary" style={{marginBottom: '5px'}} dangerouslySetInnerHTML={{__html: I18n.t('growMode.step0.title')}}></p>
                        <p className="heading-2 regular">{I18n.t('growMode.step0.subtitle')}</p>
                        <Steps>
                            <Step>
                                <Check firstStep>
                                    { <Icon name="trophy" size="large" /> }
                                </Check>
                                <Text>
                                    <p className="heading-3" style={{marginBottom: "5px"}}>{I18n.t('growMode.step0.step1')}</p>
                                    <p className="caption regular" dangerouslySetInnerHTML={{__html: I18n.t('growMode.step0.step1Description')}}></p>
                                </Text>
                            </Step>
                            <Step>
                                <Check>
                                    {  <Icon name="circle outline" size="big" /> }
                                </Check>
                                <Text>
                                    <p className="heading-3" style={{marginBottom: "5px"}}>{I18n.t('growMode.step0.step2')}</p>
                                    <p className="caption regular" dangerouslySetInnerHTML={{__html: I18n.t('growMode.step0.step2Description')}}></p>
                                </Text>
                            </Step>
                            <Step>
                                <Check>
                                    { <Icon name="circle outline" size="big" /> }
                                </Check>
                                <Text>
                                    <p className="heading-3" style={{marginBottom: "5px"}}>{I18n.t('growMode.step0.step3')}</p>
                                    <p className="caption regular" dangerouslySetInnerHTML={{__html: I18n.t('growMode.step0.step3Description')}}></p>
                                </Text>
                                
                            </Step>
                        </Steps>
                        <Actions>
                            <OButton type="button" upper primary color="#fff" onClick={ ()=> this.props.history.push('/mode/grow/step/1')}>
                                <span>{I18n.t('growMode.step0.buttonText')}</span>
                            </OButton>
                        </Actions>
                    </FrameContent>
                </InnerContainer>
            </Container>
        );
    }
}

export default Step0;