import _ from 'lodash';
import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const changeEmail = async payload => await AxiosService.post(Config.server.api.securityApi + `changeemail`, payload , true);
const changePassword = async payload => await AxiosService.post(Config.server.api.securityApi + 'changepassword', payload, true);
const checkJwt = async () => await AxiosService.get(Config.server.api.securityApi + 'check', true);
const getAdvisorInfo = async advisorId => await AxiosService.get(Config.server.api.securityApi + `advisor/user/${advisorId}/advisorInfo`, {}, true);
const getAdvisorInfoByNickName = async nickname => await AxiosService.get(Config.server.api.securityApi + `advisor/info/${nickname}`, {}, false);
const getListData = async listId => _.get(await AxiosService.get(Config.server.api.securityApi + `core/list/${ listId }`, {}, false), 'data', []);
const getProFullInfo = async () => _.get(await AxiosService.get(Config.server.api.securityApi + `pro`, {}, false), 'data', []);
const getProInfo = async proUserId => await AxiosService.get(Config.server.api.securityApi + `pro/info/${ proUserId }`, {}, false);
const getProfile = async () => await AxiosService.get(Config.server.api.securityApi + 'getprofile', true);
const insertUserNotificationToken = async payload => await AxiosService.put(Config.server.api.securityApi + 'user/notification/token', payload, true);
const login = async payload => await AxiosService.post(Config.server.api.securityApi + 'login', payload, false);
const recoveryPassword = async payload =>  await AxiosService.post(Config.server.api.securityApi + `recoverypassword/${payload.email}`, {}, false);//step1
const register = async payload => await AxiosService.post(Config.server.api.securityApi + 'register', payload, false);
const removeAccount = async () => await AxiosService.post(Config.server.api.securityApi + `removeaccount`, {}, true);
const removeAccountConfirmation = async token => await AxiosService.post(Config.server.api.securityApi + `removeaccount/${token}`, {}, false);
const setRecoveryPassword = async payload => await AxiosService.post(Config.server.api.securityApi + `recoverypassword/${payload.token}/set`, {newPassword: payload.newPassword }, false);//step2
const thirdPartyLogin = async payload => await AxiosService.post(Config.server.api.securityApi + 'thirdparty/login', payload, false);
const updateGrowChallengeStatus = async status => await AxiosService.put(Config.server.api.securityApi + `growChallenge/${status}`, {}, true);
const updateProfile = async payload => await AxiosService.post(Config.server.api.securityApi + 'saveprofile', payload, true);
const verifyEmail = async token => await AxiosService.post(Config.server.api.securityApi + `verifyemail/${token}`, {}, false);

export default {
    changeEmail,
    changePassword,
    checkJwt,
    getAdvisorInfo,
    getAdvisorInfoByNickName,
    getListData,
    getProFullInfo,
    getProInfo,
    getProfile,
    insertUserNotificationToken,
    login,
    recoveryPassword,
    register,
    removeAccount,
    removeAccountConfirmation,
    setRecoveryPassword,
    thirdPartyLogin,
    updateGrowChallengeStatus,
    updateProfile,
    verifyEmail
}