import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Responsive } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import CustomButton from '../../../../ui/components/form/button';
import CustomSearchInput from '../../../../ui/components/form/inputSearch';
import Footer from '../../../../ui/components/footer';
import CookiesPopup from '../../../components/cookiesPopup';
import './landing.scss';
import Logo from '../../../assets/img/logo_white.png';
import y1 from '../../../assets/img/yoga_1.png';
import y2 from '../../../assets/img/superheroe_2.png';
import tablet from '../../../assets/img/tablet_landing.png';

import g1 from '../../../assets/img/practica_alternativas.svg';
import g2 from '../../../assets/img/descargar_secuencias.svg';
import g3 from '../../../assets/img/acompanamiento_video.svg';

import p1 from '../../../assets/img/jacao.png';
import p2 from '../../../assets/img/nexo.png';
import p3 from '../../../assets/img/support.png';

import d1 from '../../../assets/img/diagnostico_corporal.svg';
import d2 from '../../../assets/img/transformación_continua.svg'
import d3 from '../../../assets/img/practica_personalizada.svg';
import d4 from '../../../assets/img/tu_matriz_biometrica.svg';
import mb from '../../../assets/img/matriz_landing.png';

import I18n from '../../../../i18n';

import { setRegisterEmail } from '../../../../redux-store/registerEmail';

class TestC extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            active: -1,
            faqs:[
                {
                    title : "¿Qué es Yogabot?",
                    text: "<p>Es un Sistema de Recomendación Automática de Práctica de Yoga a Medida, basado en la Pedagógica NEXOYOGA de Matriz Biométrica®</p><p>Una <b>aplicación web</b> que te permite practicar yoga desde casa de una <b>forma totalmente personalizada</b> y que evoluciona con tus propios avances.</p>"
                },
                {
                    title : "¿Puedo probar Yogabot gratis y sin compromiso ?",
                    text: "<p>Absolutamente. Al crear tu cuenta tienes acceso a Yogabot PREMIUM durante 15 días totalmente gratis. Es decir, que puedes hacer uso de todas las funcionalidades de Yogabot PREMIUM, sin límites.</p><p>Tras el período de prueba, en caso que decidas <b>no contratar</b> Yogabot PREMIUM, seguirás teniendo acceso a tu cuenta, pero con la versión básica (LITE) que tiene unos <b>servicios y funcionalidades más reducidos</b></p>"
                },
                {
                    title : "Una vez terminado el período de prueba de 15 días, ¿qué pasa con mi cuenta?",
                    text: "<p>Terminado el período de prueba, puedes seguir disfrutando de Yogabot totalmente GRATIS, manteniendo todos los datos de tu cuenta se mantienen. Es decir, que no pierdes tu matriz biométrica actualizada, ni tu secuencia de práctica personalizada.</p><p>Podrás seguir disfrutando de Yogabot GRATIS en su versión básica (LITE) cuyos servicios <b>y funcionalidades son limitados</b></p>"
                },
                {
                    title : "¿Puedo cancelar cuando quiera?",
                    text: "<p>Por supuesto. En el apartado de tu cuenta tienes instrucciones fáciles y claras para cancelar tu suscripción PREMIUM en cualquier momento, o cancelar toda tu cuenta. Sin problemas </p>"
                },
                {
                    title : "¿Puedo utilizar Yogabot con mi Sistema Operativo / dispositivo?",
                    text: "<p>Yogabot es una APP Web que puedes utilizar independientemente de tu sistema operativo (IOs, Android o Microsotf, etc.) o dispositivo (móvil, tablet o PC)</p>"
                },
                {
                    title : "Yogabot en tres frases:",
                    text: `<ul>
                    <li><p>Con Yogabot puedes hacer una práctica de yoga <b>personalizada y optimizada para ti</b>, según tu estado corporal actual, lo que te permite potenciar tu cuerpo, en equilibrio.</p></li>
                    <li><p>Yogabot es una herramienta de <b>Transformación Personal</b> que se adapta a ti. Te inspira, guía y acompaña en tu Proceso de Crecimiento como Practicante de Yoga.</p></li>
                    <li><p>Yogabot nace para hacer <b>útil y accesible el Yoga en el Siglo XXI</b>, sin perder el rigor y la esencia del Yoga. Para ayudar a transformar a la sociedad, mediante la transformación personal.</p></li>
                    </ul>`
                },{
                    title: "¿Por qué Yogabot es diferente?",
                    text: `<p>Nadie mejor que tu para responder a esta pregunta. Te animamos a probarlo y experimentar las diferencias.</p>
                    <p>Sin embargo, resumimos algunos puntos que marcan la diferencia entre Yogabot y otras aplicaciones de Yoga del mercado:</p>
                    <p>1) Es la primera aplicación que incluye un <b>Sistema de Autodiagnóstico</b> que mide tu Estado Corporal Actual (Matriz Biométrica®) con los principales niveles de desequilibrio corporal.</p>
                    <p>2) Es el primer Sistema que te recomienda una <b>Práctica de Yoga 100% Personalizada y Óptima</b>, tomando como referencia tu Matriz Biométrica Actual</p>
                    <p>3) Es un sistema de Práctica Personal que se <b>adapta a ti y crece contigo</b>.</p>
                    <p>4) No es una Aplicación creada por programadores informáticos que han visto en el Yoga una oportunidad de ingresos. Todo el sistema de Matriz Biométrica® y el <b>Algoritmo Matemático de Recomendación y Secuenciación</b>, está basado en la Pedagogía NEXOYOGA, la misma con la que formamos y certificamos a Profesores de Yoga en todo el mundo.</p>
                    <p>5) No usamos modelos o animación. El profesor que te acompañará en tu práctica personal, no es un modelo ni un avatar, es un profesor experimentado (creador del sistema) que te mostrará una práctica precisa, segura y rigurosa.</p>
                    <p>6) Además de todo esto, Yogabot te ofrecerá diferentes prácticas alternativas a tu Secuencia Personal, tal y como ofrecen otras aplicaciones, PERO son prácticas ajustadas a la pedagogía de enseñanza y práctica de NEXOYOGA</p>`
                }
            ]
        }

    }

    gotoSubscriptionFromButton = () => {

        this.props.history.push('/auth/register');

    }

    gotoSubscriptionFromForm = credentials => {

        this.props.setRegisterEmail(credentials.email);
        this.props.history.push('/auth/register');

    }

    onSelectItem = index => {

        this.setState({ active : index === this.state.active ? -1 : index} );

    }

    render() {

        const { faqs } = this.state;

        return (
            <React.Fragment>
            <CookiesPopup type="alternative" {...this.props}/>

            <section className="landing-hero">
                <div className="logo-float">
                    <img src={Logo} alt="yogabot"></img>
                </div>
                <div className="icon-landing"></div>
                <div className="wraper-header-btns">
                    <CustomButton classes="brand invert small" text={ I18n.t('auth.login') } onClick={ ()=> this.props.history.push('/auth/login') }></CustomButton>
                    <CustomButton classes="brand small" text={ I18n.t('auth.tryFree') } onClick={ this.gotoSubscriptionFromButton }></CustomButton>
                </div>


                <div className="hero-content">

                    <p className="title">PRACTICA UN YOGA <span>ASANAS</span></p>
                    <p className="huge-title">a tu medida</p>
                    <p className="medium-title">desde cualquier lugar</p>
                    <p className="medium-title">o dispositivo</p>
                    <div className="block-adver">
                        <p>disfruta <b>yogabot premium</b></p>
                    </div>
                    <p className="title-description">100% gratis en fase de <b>pre-lanzamiento</b></p>

                    <div className="box-btn landing">
                        <Form name="landingForm" noValidate className="landing-form" onSubmit={this.props.handleSubmit(this.gotoSubscriptionFromForm)}>
                            <Field
                                component={ CustomSearchInput }
                                placeholder={ I18n.t('auth.email') }
                                name="email"
                                fieldClasses="box-desktop override-main"
                                action={<CustomButton type="submit" text='Probar ahora' icon={<Icon name='chevron right' size="large" />} classes="brand high"  />} />
                                <Responsive maxWidth={950}>
                                    <div style={{textAlign: 'center'}} className="f-center">
                                        <CustomButton type="submit" text='Probar ahora' icon={<Icon name='chevron right' size="large" />} classes="brand high" />
                                    </div>
                                </Responsive>
                        </Form>
                    </div>
                    <p className="title-sub">
                        Crea tu cuenta <b>GRATIS</b> y comienza a disfrutar del <b>Yoga a Tu Medida…</b> ¡Ten la Experiencia!
                    </p>

                </div>
            </section>
            <section className="landing-section content-one">
                    <div className="left">
                        <p className="title">
                            Si eres Practicante de Yoga… ¡ENHORABUENA!
                        </p>
                        <p className="description">
                            ¡POR FIN! Un <b>Sistema Profesional</b> para Practicar Yoga en Casa de Forma Totalmente Segura y Personalizada con <b>Secuencias de Asanas Optimizadas para ti</b>
                        </p>
                        <div className="rule"></div>
                        <img src={y1} alt="" />
                    </div>
                    <div className="right">
                        <p className="title"> Si Eres una persona...</p>
                        <p className="description">Que valora descubrir y <b>explorar nuevos retos</b>, y tienes la <b>voluntad de crecer</b> y superarte cada día, Yogabot te muestra <b>Tu Camino Óptimo en Yoga…</b> </p>
                        <p className="sub-text-image">¡EMPODÉRATE!</p>
                        <img src={y2} alt="" className="righted"/>

                    </div>
            </section>
            <section className="landing-section content-two">
                   <p className="title">¿Aceptas el <span>Desafío?</span></p>
                   <p className="descriptions"><b>¡Abre tu mente!</b> Deja a un lado cualquier creencia que puedas albergar sobre cómo es o debe ser la práctica de Yoga (ASANA) y simplemente, <b>ten la experiencia.</b></p>
                   <p className="descriptions">Realiza tu Autodiagnóstico (60 minutos) y completa tu primera <b>Matriz Biométrica.</b> Practica tu <b>Secuencia Personal Optimizada</b> durante 15 días y valora los resultados</p>
                    <div className="f-image">
                        <img src={tablet} alt="yogabot" />
                    </div>

                    <div className="box-btn bordering">
                        <p className="box-btn-title">comienza hoy mismo</p>
                        <p className="comma">“</p>
                        <p className="box-btn-subtitle">Diseña tu práctica de yoga y mejora tu mundo</p>
                        <p className="box-btn-description">¡Crea tu cuenta <b>GRATIS</b> y ten la <b>Experiencia!</b></p>
                        <Form name="landingForm" noValidate className="landing-form" onSubmit={this.props.handleSubmit(this.gotoSubscriptionFromForm)}>
                            <Field
                                component={ CustomSearchInput }
                                placeholder={ I18n.t('auth.email') }
                                name="email"
                                fieldClasses="box-desktop override"
                                action={<CustomButton type="submit" text='Probar ahora' icon={<Icon name='chevron right' size="large" />} classes="brand high"  />} />
                                <Responsive maxWidth={950}>
                                    <div style={{textAlign: 'center'}} className="f-center">
                                        <CustomButton type="submit" text='Probar ahora' icon={<Icon name='chevron right' size="large" />} classes="brand high" />
                                    </div>
                                </Responsive>
                        </Form>
                    </div>
            </section>
            <div className="landing-separator">
                <p className="title">OPORTUNIDAD ESPECIAL DE PRE-LANZAMIENTO</p>
                <p className="description">Sin duda, el mejor momento para probar Yogabot</p>
            </div>

            {/* <div className="count-wrapper">
                <div className="title-header">
                    <p>Termina el 12 de Junio</p>
                </div>
                <div className="clear"></div>
                <div className="inner-wrap">
                    <Countdown timeTillDate="06 12 2020" timeFormat="MM DD YYYY, h:mm a" />
                </div>
            </div> */}
            <section className="landing-section content-three">
                <div className="left">
                    <p className="title">PRUÉBALO <br/><span className="cwhite">TOTALMENTE GRATIS</span></p>
                    <p className="description">Disfrutar de Yogabot <b>PREMIUM</b> totalmente GRATIS y sin compromiso, durante la fase de <b>PRE-LANZAMIENTO</b></p>
                    <p className="description cwhite"><b>NOTA:</b> Durante esta fase podrás disfrutar <b>sin límites</b> de las nuevas funcionalidades y mejoras que iremos añadiendo al plan PREMIUM.</p>
                </div>
                <div className="right">
                    <p className="title">OFERTA ESPECIAL  <br/><span className="cbrand">(365 DIAS)</span></p>
                    <p className="description">Solo por probar Yogabot en el <b>pre-lanzamiento</b>, tendrás la oportunidad de contratar el Plan PREMIUM durante el lanzamiento, con todas las mejoras que sumaremos a lo largo de 2020 y 2021, por tan solo 39,99 Dólares POR TODO UN AÑO</p>
                    <p className="description">Es decir, que cuando lancemos oficialmente Yogabot, podrás tener tu “Profesor en Casa” por tan solo 3,33 Dólares al mes.</p>
                </div>
            </section>
            <section className="landing-section content-four">
                <p className="title">¿Qué Puedes Hacer en <span className="cbrand">Yogabot?</span></p>
                <p className="description">Podrás practicar Yoga en Casa de forma totalmente Segura y Efectiva, con secuencias de Asanas OPTIMIZADAS para ti…</p>
                <div className="scaled">
                    <div className="desktop-v">
                        <div className="first">
                            <img src={d1} alt="" />
                            <p className="title">diagnóstico corporal</p>
                            <p className="desc">Realizarás un Proceso de Autodiagnóstico mediante 6 Retos, para calcular más de 40 parámetros biométricos</p>
                        </div>
                        <div className="second">
                            <img src={d3} alt="" />
                            <p className="title">PRACTICA PERSONALIZADA</p>
                            <p className="desc">Teniendo en cuenta tu Matriz Biométrica, Yogabot diseñará una <b>secuencia de Yoga óptima y 100% personalizada para ti</b>.</p>
                        </div>
                        <div className="third">
                            <img src={d4} alt="" />
                            <p className="title">TU MATRIZ BIOMÉTRICA</p>
                            <p className="desc">Contarás con un MAPA que representará tus principales niveles de desequilibrio, por zonas corporales. </p>
                        </div>
                        <div className="fourth">
                            <img src={d2} alt="" />
                            <p className="title">TRANSFORMACIÓN CONTINUA</p>
                            <p className="desc">Una vez que hayas acumulado unas 25 – 30 horas de práctica con TU SECUENCIA PERSONALIZADA, actualiza tu Matriz y obtén una nueva secuencia</p>
                        </div>
                        <div className="matrix-b">
                            <img src={mb} alt="" />
                        </div>
                    </div>
                </div>
                <div className="grid-section">
                    <div className="item">
                        <img src={d1} alt="" />
                        <p className="title">diagnóstico corporal</p>
                        <p className="description">Realizarás un Proceso de Autodiagnóstico mediante 6 Retos, para calcular más de 40 parámetros biométricos</p>
                    </div>
                    <div className="item">
                        <img src={d3} alt="" />
                        <p className="title">PRACTICA PERSONALIZADA</p>
                        <p className="description">Teniendo en cuenta tu Matriz Biométrica, Yogabot diseñará una <b>secuencia de Yoga óptima y 100% personalizada para ti</b>.</p>
                    </div>
                    <div className="item">
                        <img src={d4} alt="" />
                        <p className="title">TU MATRIZ BIOMÉTRICA</p>
                        <p className="description">Contarás con un MAPA que representará tus principales niveles de desequilibrio, por zonas corporales. </p>
                    </div>
                    <div className="item">
                        <img src={d2} alt="" />
                        <p className="title">TRANSFORMACIÓN CONTINUA</p>
                        <p className="description">Una vez que hayas acumulado unas 25 – 30 horas de práctica con TU SECUENCIA PERSONALIZADA, actualiza tu Matriz y obtén una nueva secuencia</p>
                    </div>
                    <div className="item">
                        <img src={mb} alt="" style={{maxWidth: '300px', width: '100%'}}/>

                    </div>
                </div>
                <div className="block-description">
                    <p><b>Descúbrete</b>, Practica TU <span className="cbrand">YOGA</span> y Transfórmate</p>
                </div>
            </section>
            <section className="landing-section content-six">
                <p className="title">PRÓXIMAS NOVEDADES</p>
                <p className="description">¿Qué más incluiremos en <span className="cbrand">Yogabot PREMIUM?</span></p>
                <div className="grid-section">
                    <div className="item">
                        <img src={g1} alt="" />
                        <p className="title"><span className="cbrand">practica</span> alternativa</p>
                        <p className="description">Además de <b>Tu Práctica Personalizada,</b> podrás disfrutar del servicio de acompañamientos para otras prácticas, ya sean de <b>otros Métodos de Yoga,</b> como practicas orientadas a <b>objetivos específicos.</b></p>
                    </div>
                    <div className="item">
                        <img src={g2} alt="" />
                        <p className="title"><span className="cbrand">descargar</span> secuencias</p>
                        <p className="description">Además de realizar tu práctica en Yogabot, como PREMIUM tendrás la posibilidad de <b>Descargar tu Secuencia Personalizada</b> – 100% Optimizada para ti – en PDF.</p>
                    </div>
                    <div className="item">
                        <img src={g3} alt="" />
                        <p className="title"><span className="cbrand">acompañamiento</span> en video</p>
                        <p className="description">Y lo mejor… Podrás disfrutar del acompañamiento de práctica en <b>VIDEO</b>. Tanto de tu Práctica Personalizada, como de las prácticas alternativas. Es como tener un <b>Curso de Yoga a Tu Medida</b>, SIEMPRE</p>

                    </div>
                </div>
            </section>
            <section className="landing-section content-seven">
                <div className="inner-wrap">
                    <div className="bio-header">
                        <p className="title">¿QUIÉN ESTÁ DETRÁS DE YOGABOT?</p>
                        <p className="description">PROFESIONALIDAD <span className="cbrand">Y RIGOR</span></p>
                    </div>
                    <div className="grid-section">
                        <div className="item">
                            <div className="header-grid">
                                <div className="picture">
                                    <img src={p1} alt="" />
                                </div>
                            </div>
                            <div className="author-block">
                                <div className="author-name">jose a. cao</div>
                                <div className="author-subtitle">EL PROFESOR</div>
                            </div>
                            <div className="body-grid">
                                <p className="description">Quien te acompañará en tu practica personal, <b>no es un modelo</b>, sino un <b>profesor Experimentado</b> con una práctica impecable. Certificado en el Metodo Iyengar. Creador de la Pedagogía NEXOYOGA y <b>formador internacional de profesores</b>.</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="header-grid">
                                <div className="picture">
                                    <img src={p2} alt="" />
                                </div>
                            </div>
                            <div className="author-block">
                                <div className="author-name">NEXOYOGA</div>
                                <div className="author-subtitle">la empresa</div>
                            </div>
                            <div className="body-grid">
                                <p className="description">Yogabot se alimenta de la <b>Pedagogía NEXOYOGA®</b> y del Sistema <b>Matriz Biométrica®</b>. Estos sistemas de Práctica y Enseñanza han sido ampliamente probados y compartidos en los Cursos Online de la Academia NEXOYOGA, así como en varios eventos internacionales</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="header-grid">
                                <div className="picture">
                                    <img src={p3} alt="" />
                                </div>
                            </div>
                            <div className="author-block">
                                <div className="author-name">soporte</div>
                                <div className="author-subtitle">profesores en formación</div>
                            </div>
                            <div className="body-grid">
                                <p className="description">Detrás de Yogabot no hay solo <b>un gran equipo técnico e informatico</b>, también hay un excelente grupo de <b>Profesores de Yoga</b>, de varios países, que se están Formando y Certificando en la Pedagogía NEXOYOGA y el Sistema Matriz Biométrica®</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="landing-section content-eight">
                <div className="inner-wrap">
                    <p className="title">¿Tienes alguna duda?</p>
                    <p className="subtitle">Preguntas frecuentes</p>
                    <div className={"faq-box"}>
                    { faqs.map((faq, index) => (
                        <div className={"faq-row " + (index % 2 === 0 ? 'evenColor' : 'oddColor') + (this.state.active === index ?  ' active' :  '' )} key={index}>
                            <div className="faq-header" onClick={ () => this.onSelectItem(index) }>
                                <div className="faq-title">{faq.title}</div>
                                <div className={"closure " + (this.state.active !== index ?  'rotate' :  '' )}><Icon name='times' /></div>
                            </div>
                            <div className={"faq-body " + (this.state.active === index ?  'active' :  '' ) }>
                                <div dangerouslySetInnerHTML={{__html: faq.text}}></div>
                            </div>
                        </div>
                    ))}
                 </div>
                </div>
            </section>
            <Footer {...this.props} type="branded" hasExtraFooter={true}/>
            </React.Fragment>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');

    }


    return errors;

};

export default reduxForm({
    form: 'landingForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        email: ''
    }
})(connect(null, {setRegisterEmail })(TestC));
