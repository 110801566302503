// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, Message, Responsive } from 'semantic-ui-react';

// Components
import FixedBanner from '../../../../components/fixedBanner';
import Footer from '../../../../components/footer';
import GradientNav from '../../../../components/navbar/gradient';
import SubMenu from '../../../../components/navbar/submenu';
import TopBar from '../../../../components/navbar/topbar';

// Styled components
import OButton from '../../../../styled/components/button';
import { InnerWrapper, Wrapper } from '../../../../styled/components/wrappers';

// Models
import SessionModel from '../../../../../data/models/session/session';

// Config
import Config from '../../../../../config';

// Locales
import I18n from '../../../../../i18n';

// Styles
import '../masterDetail.scss';

class PracticeDetail extends React.Component {

    constructor(props) {

        super(props);

        this.videoRef = React.createRef();
        this.state = {
            activeVideoPresentation: false,
            sequence: {}
        };

    }

    componentDidMount() {

        this.videoRef.current && this.videoRef.current.setAttribute('webkit-playsinline', true);
        this.getData();

    }

    getData = async () => {

        const sequence = await SessionModel.getSequence(this.props.match.params.practiceId);
        this.setState({ sequence });

    }

    setMetaItems = (types, level, duration, asanasCount) => {

        const result = [ I18n.t('practice.level') + ' ' + I18n.t(`practice.#${ level }`), I18n.t('practice.asanas') + ' ' + asanasCount ];

        if (duration) {

            result.push(I18n.t(`practice.l#${ duration }`));

        }

        if (_.size(types)) {

            _.each(_.pull(types, 'none'), p => result.push(I18n.t(`practice.${ p }`)));

        }

        return _.map(result, (item, index) => <div key={ index } className='related-item'>{ item }</div>);

    }

    onTogglePreview = () => this.setState(prevState => ({ activeVideoPresentation: !prevState.activeVideoPresentation }));

    practiceNow = () => {

        const { userConfig } = this.props;
        const { sequence } = this.state;

        const sequenceData = window.btoa(sequence.code + '/' + userConfig.practiceAudioLanguage + '/' + userConfig.practicePresentationType + '/' + true);
        this.props.history.push(`/practice/${ sequenceData }`);

    };

    getTargetDescription = target => {

        if (target.includes('.')) {

            const targetGroup = target.substring(0, target.indexOf('.'));
            const targetSubGroup = target.substring(target.indexOf('.') + 1);

            if (['makeMoreFlexible', 'toneUp'].includes(targetGroup)) {

                return I18n.t(`profile.objectivesDescriptions.${ targetGroup }.label`) + ' - ' + I18n.t(`profile.objectivesDescriptions.${ targetGroup }.childs.${ targetSubGroup }`);

            } else {

                if (targetSubGroup.includes('.')) {

                    const injuryType = targetSubGroup.substring(0, targetSubGroup.indexOf('.'));
                    let injurySubtype = targetSubGroup.substring(targetSubGroup.indexOf('.') + 1);
                    return I18n.t('profile.objectivesDescriptions.improveComplaints.label') + ': ' + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.label`) + ' - ' + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.childs.${ injuryType }.label`) + ' - ' + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.childs.${ injuryType }.childs.${ injurySubtype }`);

                } else {

                    return I18n.t('profile.objectivesDescriptions.improveComplaints.label') + ': ' + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.label`) + ' - ' + I18n.t(`profile.injuriesDescriptions.${ targetGroup }.childs.${ targetSubGroup }.label`);

                }

            }

        } else {

            return target;

        }

    }

    render() {

        const { sequence } = this.state;
        const { title, targets, description, types, level, duration, asanasCount, videoUrl, imageUrl } = sequence;
        const { activeVideoPresentation } = this.state;

        return _.isEmpty(sequence) ? '' : (
            <>
                <GradientNav active='practice' { ...this.props } />
                <Wrapper>
                    <TopBar { ...this.props } callback={ () => this.props.history.push('/practiceList') } text={ title.ES } />
                    <FixedBanner />
                    <InnerWrapper>
                        <div className='hero-section'>
                            <div className='background-wrapper'>
                                <img src={ imageUrl } alt='' />
                            </div>
                        </div>
                        <div className='spacing'></div>
                        { (activeVideoPresentation && videoUrl.length > 0) &&
                            <div className='preview-iframe'>
                                <Icon name='close' className='close-preview' size='big' onClickCapture={ this.onTogglePreview }></Icon>
                                <div className='preview-expanded-16-9' >
                                    <iframe title='vimeo' src={ Config.player.path + window.btoa(videoUrl) } width='100%' height='100%' frameBorder='0' allow='autoplay; fullscreen' allowFullScreen></iframe>
                                </div>
                            </div>
                        }
                        <div className='master-content'>
                            <div className='i-back' style={{ padding: '15px 15px 30px 0' }} onClick={ () => this.props.history.push('/practiceList') }>
                                <Icon name='angle left' />
                                <span>{ I18n.t('actions.back') }</span>
                            </div>
                            <div className='master-crump'>
                                <span className='display-subtitle-4'>{ I18n.t('practice.practice') } &gt; { I18n.t('practice.sequenceSingle') }</span>
                            </div>
                            <div className='master-title'>
                                <p className='display-2'>{ title['ES'] }</p>
                            </div>
                            <div className='master-ctas'>
                                <Responsive minWidth='720'>
                                    <OButton upper size='huge' primary type='button' color='#fff' onClickCapture={ this.practiceNow }>
                                        <span>{ 'empezar secuencia' }</span>
                                    </OButton>
                                    <OButton upper size='huge' icon labelPosition='left' terciary type='button' onClick={ this.onTogglePreview } style={{ margin: '0 0 0 15px' }}>
                                        <Icon name='play' /> <span>{ 'ver presentación' }</span>
                                    </OButton>
                                </Responsive>
                                <Responsive maxWidth='719'>
                                    <OButton fluid upper size='large' primary type='button' color='#fff' style={{ margin: '0 0 10px 0' }} onClickCapture={ this.practiceNow }>
                                        <span>{ 'empezar secuencia' }</span>
                                    </OButton>
                                    <OButton fluid upper size='large' icon labelPosition='left' terciary type='button' onClick={ this.onTogglePreview } >
                                        <Icon name='play' />
                                        <span>{ 'ver presentación' }</span>
                                    </OButton>
                                </Responsive>
                            </div>
                            <div className='master-meta'>
                                <div className='master-description'>
                                    <p className='display-subtitle-2 upper'>{ I18n.t('practice.sequenceDescription') }</p>
                                    <p className='display-subtitle-1' dangerouslySetInnerHTML={{ __html: description['ES'] }}></p>
                                </div>
                                <div className='master-related'>
                                    <p className='display-subtitle-2 upper'>{ I18n.t('practice.relatedContent') }</p>
                                    <div className='related-items'>
                                        { this.setMetaItems(types, level, duration, asanasCount) }
                                    </div>
                                </div>
                            </div>
                            { _.isEmpty(targets) ? '' :
                                <div className='master-targets'>
                                    <p className='display-subtitle-2 upper'>{ I18n.t('practice.sequenceTargets') }</p>
                                    <div className='targets-wrapper'>
                                        <div className='targets-items'>
                                        { (targets || []).map((target, index) => (
                                            <div className='target-item' key={ index }>
                                                <p className='display-subtitle-1'>{ this.getTargetDescription(target) }</p>
                                            </div>
                                        )) }
                                        </div>
                                        <div className='target-spacer'></div>
                                    </div>
                                </div>
                            }
                            <div className='y-message'>
                                <Message icon='exclamation circle' header={ I18n.t('practice.wantConfigurePractice') } content={ <p>Para cambiar la configuración de la reproducción de la práctica (idioma del audio, imagen/video, nivel), vaya a la sección de datos avanzados en mi perfil o <Link to='/profile/account'>pinche aquí</Link></p> } />
                            </div>
                        </div>
                    </InnerWrapper>
                    <div className='footer-spacer'></div>
                    <Footer { ...this.props } type='branded' style={{ zIndex: 2 }}/>
                </Wrapper>
                <SubMenu active='practice' { ...this.props }/>
            </>
        );

    }

}

const mapStateToProps = state => {

    return {
        userConfig: _.get(state,'auth.userSession.config')
    };

};

export default connect(mapStateToProps)(PracticeDetail);