import React from 'react';
import _ from 'lodash';
import {Form, Responsive} from 'semantic-ui-react';
import { Field, reduxForm, initialize } from 'redux-form';
import {connect} from 'react-redux';
import GradientNav from '../../../components/navbar/gradient';
import SubMenu from '../../../components/navbar/submenu';
import CustomInput from '../../../components/form/input';

import CustomSelect from '../../../components/form/select';
import CustomTextArea from '../../../components/form/textarea';
import { openToast } from '../../../../redux-store/toast';
import { openLoader, closeLoader } from '../../../../redux-store/loader';
import Footer from '../../../components/footer';
import QuestionModel from '../../../../data/models/question/question';
import arrowBackPink from '../../../assets/img/arrow-pink.svg'; 
import TopBar from '../../../components/navbar/topbar';
import '../faq.scss';
import I18n from '../../../../i18n';
import OButton from '../../../styled/components/button';

class FaqFormPrivate extends React.Component {

    constructor(props){
        super(props);

        this.state = {

            categories: []
        }
    }

     componentDidMount() {

        this.getData();
       
    }

    getData = async () => {

        try {

            this.props.openLoader();
            const categoriesResponse = await QuestionModel.getCategories();

            if (categoriesResponse.status === 200 ) {

                let categories = _.map(categoriesResponse.data || [] , category => {

                    return  {
                        key: category._id,
                        text: category.label,
                        value: category._id
                    }

                });

                this.setState({categories});

                this.props.dispatch(initialize(
                    'faqFormPrivate',
                    {
                        title: '',
                        text: '',
                        category: this.props.match.params.categoryId || _.get(categories, `[0].value`, '')
                    }
                ));
                
            }
            
        } catch (error) {

            openToast({message: I18n.t(`status.code.401`), type: 'error'});
            
        } finally {

            this.props.closeLoader();

        }

    }

    handleSubmit =  async formFields => {

        try {

            const response = await QuestionModel.createAnswer(formFields);

            if (response.status === 200) {

                openToast({message: I18n.t('messages.messageSent'), type: 'success'});

            }
            
        } catch (error) {

            openToast({message: I18n.t(`status.code.401`), type: 'error'});
            
        } finally {

            this.props.reset('faqFormPrivate');

        }

    }

    render() {

        return (
            <React.Fragment>
                <GradientNav active="" {...this.props} />
                    <div className="faq-wrapper">
                        <TopBar {...this.props} callback={()=>this.props.history.goBack()}/>
                        <div className="inner">
                            <div className="center-content">
                                <h1>{I18n.t('faq.howToHelpYou')}</h1>
                                <div className="go-back-desktop no-padding" onClick={()=>this.props.history.goBack()}>
                                    <span className="back" ><img src={arrowBackPink} alt="" /></span><span className="back-text">{I18n.t('actions.back')}</span>
                                </div>
                                <div className="go-back-mobile no-padding" onClick={()=> this.props.history.goBack()}>
                                    <span className="back" ><img src={arrowBackPink} alt="" /></span>
                                </div>
                                <p dangerouslySetInnerHTML={{__html: I18n.t("faq.title")}}></p>
                            </div>
                            <Form name="faqFormPrivate" onSubmit={this.props.handleSubmit(this.handleSubmit)} >
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomSelect }
                                        name='category'
                                        fieldClasses="y-select"
                                        options={this.state.categories}
                                        label={ I18n.t("faq.type") } />
                                </div>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomInput }
                                        name='title'
                                        fieldClasses="y-input default small"
                                        label={ I18n.t("faq.subject") } />
                                </div>
                                <div className="verticalPad">
                                    <Field
                                        component={ CustomTextArea }
                                        name='text'
                                        fieldClasses="y-input default small"
                                        label={ I18n.t("faq.description") } />
                                </div>
                                <div className="verticalPad t-center">
                                    <Responsive minWidth={421}>
                                        <OButton type="submit" primary upper size="huge"><p>{I18n.t('auth.send')}</p></OButton>
                                    </Responsive>
                                    <Responsive maxWidth={420}>
                                        <OButton type="submit" primary upper ><p>{I18n.t('auth.send')}</p></OButton>
                                    </Responsive>
                                    
                                </div>
                            </Form>
                        </div>
                        <div className="footer-separator"></div>
                        <Footer {...this.props} type="branded"/>
                    </div>
                <SubMenu active="" {...this.props}/>
            </React.Fragment>
        );

    }

}


const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.title)) {

        errors.title = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.title) && formValues.title.length > 100) {

        errors.title = I18n.t('validations.maxL100');

    }

    if (_.isEmpty(formValues.text)) {

        errors.text = I18n.t('validations.required');

    }

    return errors;

};

export default reduxForm({
    form: 'faqFormPrivate',
    touchOnBlur: true,
    touchOnChange: false,
    validate

})(connect(null, { openToast, openLoader, closeLoader })(FaqFormPrivate));