import React from 'react';

import GradientNav from '../../../components/navbar/gradient';
import SubMenu from '../../../components/navbar/submenu';
import I18n from '../../../../i18n';
import './guide.scss';
import Footer from '../../../components/footer';
import TopBar from '../../../components/navbar/topbar';
import { Icon } from 'semantic-ui-react';
import GuideOptionsElement from '../../../components/guide';

class MatrixGuide extends React.Component {

    render() {

        return (
            <React.Fragment>
                    <GradientNav active="home" {...this.props} />
                    <div className="wrapper-guide">
                        <TopBar {...this.props} callback={()=> this.props.history.push(`/home`)} text={I18n.t('guide.guide')}/>
                        <div className="inner">
                            <div className="i-back" style={{margin: '40px 0 40px 0'}} onClick={() => this.props.history.push(`/home`)}><Icon name="angle left" /><span>{I18n.t('actions.back')}</span></div>
                            <GuideOptionsElement option1Route={ () => this.props.history.push('/home/guide/option/1') } option2Route={ () => this.props.history.push('/home/guide/option/2') } />
                        </div>
                        <div className="footer-separator"></div>
                    <Footer {...this.props} type="branded"/>
                </div>
                <SubMenu active="home" {...this.props}  />
            </React.Fragment>

        );

    }

}

export default MatrixGuide;