import React, { Component } from 'react'
import I18n from '../../../../../i18n';
import Topbar from '../../../../components/navbar/topbar';
import _ from 'lodash';

//styles
import '../../profile_new.scss';
import OButton from '../../../../styled/components/button';
import { Icon, Form } from 'semantic-ui-react';
import PaymentModel from '../../../../../data/models/payment/payment';
import { InnerWrapper, Separator } from '../../../../styled/components/wrappers';
import { Field, reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';
import CustomInput from '../../../../components/form/input';
import { WalletResultBoxHeader, WalletSendWrapper } from './styled';
import WalletCard from '../../../../components/wallet/components/cards';
import { NumericFormat } from '../../../../components/numericFormat/NumericFormat';
import { openGenericModal, closeGenericModal } from '../../../../../redux-store/genericModal';
import CustomTextArea from '../../../../components/form/textarea';


class WalletRecieve extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userWallet: {}
        }

    }

    onRecieveTokens = async dataForm => {

        try {

            await PaymentModel.sendOrRecieveYGB('receive', {email: dataForm.email, amount: +dataForm.amount, concept: dataForm.concept });
            this.showEndMessage(dataForm, 'correct');

        } catch (error) {

            this.showEndMessage(dataForm, 'incorrect');
            console.error('error onRecieveTokens');

        }

    }

    componentDidMount() {

        this.onGetData();

    }

    onGetData = async () => {

        try {

            const {data} = await PaymentModel.getUserWalletBalance();

            const initialValues = _.merge({ email: '', amount: '', concept: ''});
            this.props.dispatch(initialize(
                'walletFormRecieve',
                initialValues
            ));

            this.setState({userWallet : data});

        } catch (error) {

            console.error(error);

        }

    }

    showEndMessage = ({ email, amount }, status) => {

        const { openGenericModal, closeGenericModal } = this.props;

        const correctMessageTitle = (
            <>
                <Separator key={'a'}/>
                <WalletResultBoxHeader key={'ba'}>
                    <Icon  key={'c'} name="check circle" className="brand-secondary" size="large" />
                    <span  key={'d'} className="paragraph">{I18n.t('messages.sentRequestCorrect')}</span>
                </WalletResultBoxHeader>
                <Separator xL key={'e'}/>
            </>
        )
        const genericDescription = (
            <>
                <p key={0} className="caption regular">{I18n.t('wallet.from')}</p>
                <p key={1} className="paragraph">{I18n.t('wallet.walletYGB')}</p>
                <p key={2} className="caption regular">{I18n.t('wallet.sentTo')}</p>
                <p key={3} className="paragraph">{email}</p>
                <p key={4} className="caption regular">{I18n.t('wallet.amount')}</p>
                <p key={5} className="paragraph">{<NumericFormat value={amount} type="token" suffix="YGB"/>}</p>
            </>
        )

        const incorrectMessageTitle = (
            <>
                <Separator key={'a'}/>
                <WalletResultBoxHeader key={'ba'}>
                    <Icon  key={'c'} name="times circle" className="brand-secondary" size="large" />
                    <span  key={'d'} className="paragraph">{I18n.t('messages.sentRequestIncorrect')}</span>
                </WalletResultBoxHeader>
                <Separator xL key={'e'}/>
            </>
        )

        const message = {

            correct : {
                title : correctMessageTitle,
                description : genericDescription,
                buttons:[
                    {
                        text: I18n.t('actions.backToWallet'),
                        callback: ()=> {

                            closeGenericModal();
                            this.props.history.push('/profile/wallet');

                        },
                        options: {
                            primary: true,
                            color: '#fff',
                            fluid: true,
                            upper: true
                        }
                    }
                ]
            },
            incorrect : {
                title : incorrectMessageTitle,
                description : genericDescription,
                buttons:[
                    {
                        text: I18n.t('actions.tryAgain'),
                        callback: ()=> {

                            closeGenericModal();

                        },
                        options: {
                            primary: true,
                            color: '#fff',
                            fluid: true,
                            upper: true
                        }
                    }
                ]
            }

        }

        openGenericModal({
            type: 'wallet',
            title:{
                text: message[status].title
            },
            description:{
                text: message[status].description
            },
            buttons: message[status].buttons
        });

    }

    render() {

        const { userWallet } = this.state;
        const { invalid } = this.props;

        return (
            <div className="p-wrapper">
                <Topbar {...this.props} showProfile={false} text={I18n.t('wallet.receiveYGB')} invert={true} callback={()=> this.props.history.push(`/profile/wallet`)}/>
                <InnerWrapper>
                    <Separator xL/>
                    <div className="i-back" onClick={()=>this.props.history.push('/profile/wallet')}><Icon name="angle left" /><span>{I18n.t('actions.back')}</span></div>
                    <Separator/>

                    <WalletCard withBg maxW={'100%'}>
                        <WalletCard.Content>
                            <WalletCard.Column>
                                <p className="labeling white regular">{I18n.t('wallet.availableBalance')}</p>
                                <p className="landing-heading-3 white ellipsis"><NumericFormat value={userWallet.balance} type="token" suffix="YGB"/></p>
                                <p className="labeling white regular">({I18n.t('wallet.blockedBalance')} {<NumericFormat value={userWallet.blocked} type="token" suffix="YGB" />})</p>
                            </WalletCard.Column>
                        </WalletCard.Content>
                    </WalletCard>
                    <Separator xL/>
                    <p className="paragraph">{I18n.t('wallet.fillDataToRecieve')}</p>
                    <WalletSendWrapper>
                        <Form name="walletFormRecieve" noValidate onSubmit={this.props.handleSubmit(this.onRecieveTokens)}>
                            <div className="verticalPad">
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t('auth.emailShort') }
                                    name="email"
                                    fieldClasses="label-primary"
                                    label={ I18n.t('wallet.recieveFrom') } />
                            </div>
                            <div className="verticalPad">
                                <Field
                                    restrictions={ [{ numeric: true, canBeFloat: false }] }
                                    component={ CustomInput }
                                    placeholder={ I18n.t('wallet.amount') }
                                    name="amount"
                                    fieldClasses="label-primary"
                                    label={ I18n.t('wallet.amount') } />
                            </div>
                            <div className="verticalPad">
                                <Field
                                    component={ CustomTextArea }
                                    placeholder={ I18n.t('wallet.concept') }
                                    name="concept"
                                    fieldClasses="label-primary"
                                    label={ I18n.t('wallet.concept') } />
                            </div>
                            <OButton disabled={invalid} primary upper color="#fff" type="submit"><span>{I18n.t('auth.accept')}</span></OButton>
                        </Form>
                    </WalletSendWrapper>
                </InnerWrapper>
            </div>
        )
    }
}

const validate = (formValues) => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');

    }

    if (_.isEmpty(formValues.amount)) {

        errors.amount = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.amount) && formValues.amount < 1) {

        errors.amount = I18n.t('validations.notZero');

    }

    return errors;

};

export default reduxForm({
    form: 'walletFormRecieve',
    touchOnBlur: true,
    touchOnChange: false,
    validate
})(connect(null, { openGenericModal, closeGenericModal })(WalletRecieve));