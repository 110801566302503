import React from 'react'
import { GuideColorGrid, InfosColorsCol, InfoColorItem, InfoColorLabel, InfoColorContent, InfoColorCircleIcon,  InfoColorCircleIconMobile, InfosImageCol } from './styles';

import I18n from '../../../../i18n';
import guideMatrix from '../../../assets/img/hombre_frontal_matriz.svg';

function GuideOptionColorElement(props) {
    
    return (
        <>
           <GuideColorGrid>
                <InfosColorsCol>

                    <InfoColorItem c1>
                        <InfoColorLabel />
                        <InfoColorContent>
                            <p className="paragraph upper">{I18n.t('guide.levels.high')}</p>
                            <p className="caption regular">{I18n.t('guide.levels.texts.one')}</p>
                        </InfoColorContent>
                        <InfoColorCircleIcon>
                            <i className="f-icon-balance_4 mirror"></i>
                        </InfoColorCircleIcon>
                        <InfoColorCircleIconMobile>
                            <i className="f-icon-balance_4 mirror"></i>
                        </InfoColorCircleIconMobile>
                    </InfoColorItem>

                    <InfoColorItem c2>
                        <InfoColorLabel />
                        <InfoColorContent>
                            <p className="paragraph upper">{I18n.t('guide.levels.low')}</p>
                            <p className="caption regular">{I18n.t('guide.levels.texts.three')}</p>
                        </InfoColorContent>
                        <InfoColorCircleIcon>
                            <i className="f-icon-balance_3"></i>
                        </InfoColorCircleIcon>
                        <InfoColorCircleIconMobile>
                            <i className="f-icon-balance_3"></i>
                        </InfoColorCircleIconMobile>
                    </InfoColorItem>

                    <InfoColorItem c4>
                        <InfoColorLabel />
                        <InfoColorContent>
                            <p className="paragraph upper">{I18n.t('guide.levels.excelent')}</p>
                            <p className="caption regular">{I18n.t('guide.levels.texts.four')}</p>
                        </InfoColorContent>
                        <InfoColorCircleIcon>
                            <i className="f-icon-balance_1"></i>
                        </InfoColorCircleIcon>
                        <InfoColorCircleIconMobile>
                            <i className="f-icon-balance_1"></i>
                        </InfoColorCircleIconMobile>
                    </InfoColorItem>

                    <InfoColorItem c7>
                        <InfoColorLabel />
                        <InfoColorContent>
                            <p className="paragraph upper">{I18n.t('guide.levels.low')}</p>
                            <p className="caption regular">{I18n.t('guide.levels.texts.five')}</p>
                        </InfoColorContent>
                        <InfoColorCircleIcon>
                            <i className="f-icon-balance_3 mirror"></i>
                        </InfoColorCircleIcon>
                        <InfoColorCircleIconMobile>
                            <i className="f-icon-balance_3 mirror"></i>
                        </InfoColorCircleIconMobile>
                    </InfoColorItem>

                    <InfoColorItem c9>
                        <InfoColorLabel />
                        <InfoColorContent>
                            <p className="paragraph upper">{I18n.t('guide.levels.high')}</p>
                            <p className="caption regular">{I18n.t('guide.levels.texts.seven')}</p>
                        </InfoColorContent>
                        <InfoColorCircleIcon>
                            <i className="f-icon-balance_4"></i>
                        </InfoColorCircleIcon>
                        <InfoColorCircleIconMobile>
                            <i className="f-icon-balance_4"></i>
                        </InfoColorCircleIconMobile>
                    </InfoColorItem>

                    <InfoColorItem c8>
                        <InfoColorLabel />
                        <InfoColorContent>
                            <p className="paragraph upper"></p>
                            <p className="caption regular">{I18n.t('guide.levels.texts.eight')}</p>
                        </InfoColorContent>
                        <InfoColorCircleIcon>
                            <i className="f-icon-balance_6"></i>
                        </InfoColorCircleIcon>
                        <InfoColorCircleIconMobile>
                            <i className="f-icon-balance_6"></i>
                        </InfoColorCircleIconMobile>
                    </InfoColorItem>

                </InfosColorsCol>
                <InfosImageCol>
                    <img src={guideMatrix} alt="" />
                </InfosImageCol>
            </GuideColorGrid>
        </>
    )
}

export default GuideOptionColorElement;