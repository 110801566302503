import React, { Component } from 'react'
import Footer from '../../../../../../components/footer';
import GradientNav from '../../../../../../components/navbar/gradient';
import SubMenu from '../../../../../../components/navbar/submenu';
import TopBar from '../../../../../../components/navbar/topbar';
import I18n from '../../../../../../../i18n';
import '../../../masterDetail.scss';
import _ from 'lodash'
import { Link } from 'react-router-dom';
import OButton from '../../../../../../styled/components/button';
import { InnerWrapper, Wrapper } from '../../../../../../styled/components/wrappers';
import { Icon, Message, Responsive } from 'semantic-ui-react';
import FixedBanner from '../../../../../../components/fixedBanner';
import config from '../../../../../../../config';

export default class ProgramDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
         activeVideoPresentation: false
        }
    }

    setMetaItems = (types, level) => {

        let result = [`${I18n.t('practice.level')} ` +  I18n.t(`practice.#${level}`)];

        if (types.length>0) {

            _.each(_.pull(types, 'none'), p => result.push(I18n.t(`practice.${p}`)))

        }

        return (
            _.map(result, (item, index) => (
                <div key={index} className="related-item">
                    { item }
                </div>
            ))   
        )

    }

    practiceNow = sequenceId => {

        const { userConfig, sequences } = this.props;
        let selectedPractice = sequences[_.findIndex(sequences, ap => ap._id === sequenceId)];
        let sequence = window.btoa(selectedPractice.code + '/' + userConfig.practiceAudioLanguage + '/' + userConfig.practicePresentationType + '/' + true);
        this.props.history.push(`/practice/${sequence}`);

    };

    onTogglePreview = ()=> this.setState(prevState => ({ activeVideoPresentation: !prevState.activeVideoPresentation}))
    
    onMoreInfo = (e, practiceId) => {
        
        e.stopPropagation();
        this.props.history.push(`/practiceList/detail/${practiceId}`);
    
    }

    render() {

        const { program, sequences } = this.props;
        const { activeVideoPresentation } = this.state;

        return !_.isEmpty(program) && (

            <React.Fragment>
                <GradientNav active="practice" {...this.props} />
                    <Wrapper>
                        
                        <TopBar {...this.props} callback={()=> this.props.history.push('/practiceList')} text={program.title['ES']}/>
                        <FixedBanner />
                        
                        <InnerWrapper>
                            <div className="hero-section">
                                <div className="background-wrapper">
                                    <img src={program.imageUrl} alt="" />
                                </div>
                            </div>
                            <div className="spacing"></div>
                            {(activeVideoPresentation && !_.isEmpty(program.videoUrl) && program.videoUrl.includes('vimeo')) && 
                                <div className="preview-iframe">
                                    <Icon name="close" className="close-preview" size="big" onClickCapture={this.onTogglePreview}></Icon>
                                    <div className="preview-expanded-16-9" >
                                        <iframe title="vimeo" src={config.player.path + window.btoa(program.videoUrl)} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                    </div>
                            </div>}
                            <div className="master-content">
                                <div className="i-back" style={{padding: '15px 15px 30px 0'}} onClick={()=>this.props.history.push('/practiceList')}><Icon name="angle left" /><span>{I18n.t('actions.back')}</span></div>
                                <div className="master-crump">
                                    <span className="display-subtitle-4">{I18n.t('practice.practice')} &gt; {I18n.t('practice.program')}</span>
                                </div>
                                <div className="master-title">
                                    <p className="display-2">{program.title['ES']}</p>
                                </div>
                                <div className="master-ctas">
                                    <Responsive minWidth="720">
                                        <OButton upper icon size="huge" labelPosition="left" primary type="button" color="#fff" onClickCapture={(e)=> this.practiceNow(sequences[0]._id)}>
                                                <Icon name="play" /><span>{I18n.t('actions.startProgram')}</span>
                                        </OButton>
                                        {!_.isEmpty(program.videoUrl) && <OButton upper icon size="huge" labelPosition="left" terciary type="button" onClick={this.onTogglePreview} style={{margin: "0 0 0 15px"}}>
                                            <Icon name="play" /><span>{I18n.t('actions.startPreview')}</span>
                                        </OButton>}
                                    </Responsive>
                                    <Responsive maxWidth="719">
                                        <OButton upper fluid size="large" icon primary labelPosition="left" type="button" color="#fff" style={{margin: "0 0 5px 0"}} onClickCapture={(e)=> this.practiceNow(sequences[0]._id)}>
                                                <Icon name="play" /><span>{I18n.t('actions.startProgram')}</span>
                                        </OButton>
                                        {!_.isEmpty(program.videoUrl) && <OButton upper icon size="large" fluid terciary labelPosition="left" type="button" onClick={this.onTogglePreview} >
                                            <Icon name="play" /><span>{I18n.t('actions.startPreview')}</span>
                                        </OButton>}
                                    </Responsive>
                                </div>
                                <div className="master-meta">
                                    <div className="master-description">
                                        <p className="display-subtitle-2 upper">{I18n.t('practice.programDescription')}</p>
                                        <p className="display-subtitle-1" dangerouslySetInnerHTML={{__html: program.description['ES']}}></p>
                                    </div>
                                    <div className="master-related">
                                        <p className="display-subtitle-2 upper">{I18n.t('practice.relatedContent')}</p>
                                        <div className="related-items">
                                            {this.setMetaItems(program.types, program.level)}
                                        </div>
                                    </div>
                                </div>
                                <div className="master-targets">
                                    <p className="display-subtitle-2 upper">{I18n.t('practice.programTargets')}</p>
                                    <div className="targets-wrapper">
                                        <div className="targets-items">
                                        {
                                            (program.targets || []).map((target, index) => (

                                                <div className="target-item" key={index}>
                                                    <p className="display-subtitle-1">{target}</p>
                                                </div>

                                            ))
                                        }
                                        </div>
                                        <div className="target-spacer"></div>
                                    </div>
                                </div>
                                <div className="y-message">
                                    <Message icon='exclamation circle' header={I18n.t('practice.wantConfigurePractice')} content={<p>Para cambiar la configuración de la reproducción de la práctica (idioma del audio, imagen/video, nivel), vaya a la sección de datos avanzados en mi perfil o <Link to="/profile/account">pinche aquí</Link></p>} />
                                </div>
                                <div className="master-grid-wrapper">
                                    <p className="display-title">{I18n.t('practice.programSequences')}</p>
                                    <div className="master-grid">
                                        {
                                            _.map(_.orderBy(sequences, ['order'], ['asc']), (sequence, index) => (
                                                <div className="master-grid-item" onClickCapture={(e)=> this.practiceNow(sequence._id)} key={sequence._id}>
                                                    <div className="item-number">
                                                        <p className="display-1">{index + 1}</p>
                                                    </div>
                                                    <div className="item-cover"><img src={sequence.thumbUrl} alt="" /></div>
                                                    <div className="item-content">
                                                        <div className="display-subtitle-3 item-title" dangerouslySetInnerHTML={{ __html: sequence.title['ES'] }}></div>
                                                        <div className="display-subtitle-4" dangerouslySetInnerHTML={{ __html: sequence.description['ES'] }}></div>
                                                        <OButton upper size="small" terciary type="button" onClickCapture={(e)=>{ this.onMoreInfo(e,sequence._id)}} style={{margin: "20px 0"}}>
                                                            <Icon name="plus"></Icon> <span>{'Más info'}</span>
                                                    </OButton>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                            
                                    </div>
                                </div>
                            </div>
                        </InnerWrapper>
                        <Footer { ...this.props } type="branded"/>
                    </Wrapper>
                <SubMenu active="practice" {...this.props}/>
            </React.Fragment>
        )
    }
}