// Third party libraries
import React from 'react';
import { Responsive } from 'semantic-ui-react';

// Models
import SecurityModel from '../../../../../data/models/security/security';

// Styled components
import OButton from '../../../../styled/components/button';
import { RecoveryBox, RecoveryCol, RecoveryRow } from '../styled';
import { AuthHeader, AuthIcon, AuthSeparator, AuthTitle, YogabotIcon } from '../../styled';

// Utils
import { TrackingService } from '../../../../../utils/TrackingService';

// Locales
import I18n from '../../../../../i18n';

// Assets
import yLogoLarge from '../../../../assets/img/logo_large.svg';

class verifyEmail extends React.Component {


    constructor(props){
        super(props);

        this.state =  {
            token : this.props.match.params.token,
            validated: false,
            loaded: false,
            emailAlreadyInUse: false
        }
    }

    async componentDidMount() {

        try {

            const response = await SecurityModel.verifyEmail(this.state.token);

            if (response.status === 200) {

                this.setState({ validated: true, loaded: true });
                TrackingService.registerEvent('Profile', 'emailValidated');

            }

        } catch (errorCode) {

            this.setState({ validated: false, loaded: true, emailAlreadyInUse: errorCode === 409 });

        }

    }

    render() {

        const { loaded, validated, emailAlreadyInUse } = this.state;

        return (

            <RecoveryRow>
                <RecoveryCol>
                    <RecoveryBox>
                        <AuthHeader column>
                            <Responsive minWidth={560}>
                                <AuthIcon w={240} h={50}>
                                    <YogabotIcon src={yLogoLarge} />
                                </AuthIcon>
                            </Responsive>
                            <Responsive maxWidth={559}>
                                <AuthIcon>
                                    <YogabotIcon />
                                </AuthIcon>
                            </Responsive>
                            <AuthTitle>
                                {loaded && validated && <span style={{lineHeight: '1.3'}}>{ I18n.t('auth.verifyEmail')}</span>}
                                {loaded && !validated && <span style={{lineHeight: '1.3'}}>{ I18n.t('auth.verifyEmailError')}</span>}
                            </AuthTitle>
                        </AuthHeader>
                        <AuthSeparator />
                        <AuthSeparator />
                        {loaded && validated && <p className="paragraph regular">{ I18n.t('auth.restoreCorrectMessageSecond')}</p>}
                        {loaded && !validated && !emailAlreadyInUse && <p className="paragraph regular">{ I18n.t('auth.verifyEmailErrorTryAgain')}</p>}
                        {loaded && !validated && emailAlreadyInUse && <p className="paragraph regular">{ I18n.t('status.code.409')}</p>}
                        <AuthSeparator />
                        <AuthSeparator />
                        <OButton upper type="button" fluid color="#FFF" primary onClick={()=> this.props.history.push(`/auth/login`)}>
                            <span>{I18n.t('auth.finalize')}</span>
                        </OButton>
                    </RecoveryBox>
                </RecoveryCol>
            </RecoveryRow>

        );

    }

}

export default verifyEmail;