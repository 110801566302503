import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

const AccountTypeGrid = styled.div`

    display:flex;
    position:relative;
    flex-wrap:wrap;
`;

const AccountTypeItem = styled(motion.div)`
    flex:0 0 100%;
    padding:2rem;
    background:#fff;
    margin: 0 0 1.5rem 0;
    overflow:hidden;
    border-radius:10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    &.expanded {
        flex:0 1 40rem;
        max-width:40rem;
        position:relative;
        padding:2.5rem;
        margin: 1.5rem; 
    }

    @media (min-width: 800px) {

        flex:1;
        margin: 1.5rem; 

    }
`;

const Expanded = styled(motion.div)`

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2rem;
    z-index: 11;
    background: linear-gradient(135deg,var(--brand-primary),var(--brand-primary));
    
`;

export {
    AccountTypeGrid,
    AccountTypeItem,
    Expanded
}