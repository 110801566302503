import _ from 'lodash';
import { Icon, Responsive } from 'semantic-ui-react';

// Styled components
import OButton from '../../../styled/components/button';

// Locales
import I18n from '../../../../i18n';

const PlayerButtons = ({ visible, status, firstStart, onStart, onRestart, onPause, onResume }) => {

    const renderButtonByStatus = (size, isMobile) => {

        switch (status) {

            case 'idle':
            case 'playing':
            case 'resume':

                return (
                    <OButton primary
                        color='#fff'
                        size={ size }
                        icon
                        style={{ marginBottom:'5px' }}
                        labelPosition={ !isMobile && 'left' }
                        type='button'
                        upper
                        onClick={ _.debounce(status === 'idle' ? onStart : status === 'playing' ? onPause : onResume, 1000) }
                    >
                        <Icon name={ status === 'playing' ? 'pause': 'play' } />
                        { !isMobile && <span>{ I18n.t(status === 'idle' ? 'practice.start' : status === 'playing' ? 'practice.pause' : 'practice.resume') }</span> }
                    </OButton>
                );

            default:

                return '';

        }

    }

    return (visible &&
        <>
            <Responsive minWidth={ 860 } className='wrapper-actions-buttons'>
                { renderButtonByStatus('huge', false) }
                { firstStart &&
                    <OButton terciary icon size='huge' labelPosition='left' type='button' upper onClick={ onRestart }>
                        <Icon name='redo' />
                        <span>{ I18n.t('practice.restart') }</span>
                    </OButton>
                }
            </Responsive>
            <Responsive maxWidth={ 859 } className='wrapper-actions-buttons'>
                { renderButtonByStatus('medium', true) }
                { firstStart &&
                    <OButton terciary icon size='medium' type='button' onClick={ onRestart }>
                        <Icon name='redo' />
                    </OButton>
                }
            </Responsive>
        </>
    );

}

export default PlayerButtons;