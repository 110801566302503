
import styled, {css} from 'styled-components/macro';


const GuideOptionsGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 820px) {
        grid-template-columns:1fr;
    }
`;

const GuideCol = styled.div`
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    ${({textBlock}) => textBlock && css`
        background-color: var(--y-grey10);
        
        @media (max-width: 520px) {
            padding: 20px;
        }
        
    `};

    ${({toRight}) => toRight && css`
        text-align: right;
        @media (max-width: 520px) {
            text-align: left;
        }
    `};

    @media (max-width: 820px) {
        &:nth-child(3){
            order: 4;
        }
        &:nth-child(4){
            order: 3;
        }
    }

    @media (max-width: 520px) {
        margin-bottom: 20px;
       
    }
`;

export {
    GuideOptionsGrid,
    GuideCol
}