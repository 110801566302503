import styled, {css} from 'styled-components/macro';

const GuideMatrixGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 920px) {
        grid-template-columns: 1fr;
        
    } 
    padding-bottom:32px; 
`;

const TextCol = styled.div`
    background-color: var(--y-green1);
    padding: 32px 5vw 32px 7vw;
    color: white;
    position: relative;
    &:before  {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 10vw;
        height: 8px;
        border: solid 12px white;
        box-sizing: content-box;
    }
    &:after  {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        height: 10vw;
        width: 8px;
        border: solid 12px white;
        box-sizing: content-box;
        @media only screen and (max-width: 920px) {
            display: none;
            
        } 
    }
`;

const ImageCol = styled.div`
    padding: 32px;
    position: relative;
    @media (max-width: 920px) {
        height: 700px;
        
    }
    @media (max-width: 560px) {
        height: 600px;
        
    }
    @media (max-width: 420px) {
        height: 500px;
        
    } 
    @media (max-width: 369px) {
        height: 400px;
        
    }   
`;

const ImageLayout =  styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    @media (max-width: 999px) {
        transform: translate(-50%, -50%) scale(0.8);
        
    }
    @media (max-width: 920px) {
        transform: translate(-50%, -50%) scale(1);
        
    }
    @media (max-width: 560px) {
        transform: translate(-50%, -50%) scale(0.8);
        
    } 
    @media (max-width: 420px) {
        transform: translate(-50%, -50%) scale(0.7);
        
    }
    @media (max-width: 369px) {
        transform: translate(-50%, -50%) scale(0.6);
        
    }
    
    @media (max-width: 320px) {
        transform: translate(-50%, -50%) scale(0.5);
        
    }
`;

const IndicatorPoints = styled.div`
    font-family: PoppinsRegular;
    color: var(--y-grey6);
    font-size: 14px;
    position: absolute;

    ${({t1}) => t1 && css`
        width: 186px;
        left: 30px;
        top: -37px;
        
    `};

    ${({t2}) => t2 && css`
        width: 186px;
        left: 157px;
        top: 201px;
        text-align: center;
        
    `};

    ${({t3}) => t3 && css`
        right: 12px;
        width: 201px;
        text-align: center;
        top: -37px;
        
    `};

    ${({t4}) => t4 && css`
        bottom: 200px;
        width: 200px;
        text-align: center;
        left: 15px;
        
    `};

    ${({t5}) => t5 && css`
        bottom: -84px;
        width: 200px;
        text-align: center;
        left: 149px;
        
    `};

    ${({t6}) => t6 && css`
        bottom: 200px;
        width: 200px;
        text-align: center;
        left: 289px;
        
    `};

`;

export {
    GuideMatrixGrid,
    TextCol,
    ImageCol,
    ImageLayout,
    IndicatorPoints
}
