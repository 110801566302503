import React, { PureComponent } from 'react'
import './menuPractice.scss';

class MenuPractice extends PureComponent {

    render() {
        
        const { active } = this.props;

        return (
            <div className="pr-menu-items">
                <div className={"item " + (active === 'targets' ? 'active': '')} onClick={() =>{ this.props.history.push(`/practiceDiscover/targets/${this.props.match.params.sequence}`)} }><span>Objetivos</span></div>
                <div className={"item " + (active === 'preview' ? 'active': '')} onClick={() => { this.props.history.push(`/practiceDiscover/preview/${this.props.match.params.sequence}`)} }><span>Tu secuencia</span></div>
            </div>
        )
    }
}

export default MenuPractice