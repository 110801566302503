// Locales
import I18n from '../../../../i18n';

const ProgressBar = ({ globalProgress, globalSequenceTime }) => {

    return (
        <div className='progress-section'>
            <div className='heading-3 upper text' dangerouslySetInnerHTML={{ __html: I18n.t('practice.progressLevel') }}></div>
            <div className='progress-bar'>
                <div className='progress-inner' style={{ width: (globalProgress / globalSequenceTime ) * 100 + '%' }}></div>
            </div>
            <div className='heading-3 upper percent'>
                { Math.round((globalProgress / globalSequenceTime) * 100) }%
            </div>
        </div>
    );

}

export default ProgressBar;