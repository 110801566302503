import _ from 'lodash';

import PaymentModel from '../data/models/payment/payment';

//actions
const GET_TRANSACTIONS_MOVEMENTS = 'GET_TRANSACTIONS_MOVEMENTS';

export const getTransactionMovements = () => async dispatch => {

    try {

        const response = await PaymentModel.getTransactionsMovements();

        if (response?.data) {

            const hasPendingOperations = _.some(response.data, movement => movement.type.includes('pending'));

            dispatch ({ type: GET_TRANSACTIONS_MOVEMENTS, payload: { movements: response.data, pendingOperations: hasPendingOperations }});

            return response.data;

        }

    } catch (errorCode) {

        dispatch ({ type: GET_TRANSACTIONS_MOVEMENTS, payload: { movements: [], pendingOperations: false} });
        throw errorCode;

    }

};

const INITIAL_STATE = {
    movements: [],
    pendingOperations: false
};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case GET_TRANSACTIONS_MOVEMENTS:

            return { ...state, ...action.payload };

        default:

            return state;

    }

};

export default reducer;