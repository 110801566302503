
const transition = { duration: 0.75 ,ease: [0.6, 0.01, -0.05, 0.9] };

const motionTitle = {
    initial: {
      opacity: 0,
      y:-100,
      scale:1.2
    },
    animate: {
        opacity: 1,
        scale:1,
        transition: {  ...transition, duration: 1.5 },
        y:0
    },
  };

  const motionSubtitle = {
    initial: {
      opacity: 0,
      y:100
    },
    animate: {
        opacity: 1,
        transition: {  ...transition, duration: 2.5 },
        y:0
    },
  };

  const motionButton = {
    initial: {
      opacity: 0,
      x:-100
    },
    animate: {
        opacity: 1,
        transition: {  ...transition, duration: 1, delay: 1.2, },
        x:0
    },
  };


  export {
        transition,
        motionTitle,
        motionSubtitle,
        motionButton
  }