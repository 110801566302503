import React, { Component } from 'react';
import SessionModel from '../../../../../data/models/session/session';
import ProgramDetail from './components/detail';
import { openLoader, closeLoader } from '../../../../../redux-store/loader';
import { connect } from 'react-redux';
import _ from 'lodash';

class ProgramDetailContainer extends Component {

    constructor(props){
        super(props);

        this.state = {

            sequences: [],
            program: {}

        }

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {


        try {
            
            this.props.openLoader()
            const program = await SessionModel.getProgram(this.props.match.params.programId);
            const sequences = await SessionModel.getProgramSequences(this.props.match.params.programId);
            this.setState({ program: program.data, sequences: sequences.data});

            
        } catch (error) {
            
        } finally {

            this.props.closeLoader();

        }

    }

    render() {

        const { program, sequences } = this.state;

        return (
            <ProgramDetail 
                program={program}
                sequences={sequences}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = state => {

    return {
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        role: state.role,
        userConfig: _.get(state,'auth.userSession.config')
    };
};

export default connect(mapStateToProps, { openLoader, closeLoader })(ProgramDetailContainer);