import styled from "styled-components/macro";

const Frame = styled.div`
    padding:32px;
    @media (max-width: 420px) {
        padding:20px;
    }
`;

const FrameSecondary = styled.div`
    padding:0 32px;
    @media (max-width: 420px) {
        padding:0 20px;
    }
`;


export {
    Frame,
    FrameSecondary
}