import React from 'react';
import { Header } from './styles';
import { openGenericModal } from '../../../.././../redux-store/genericModal';
import { connect } from 'react-redux';
import _ from 'lodash';
import I18n from '../../../../../i18n';
import moment from 'moment';
import SecurityModel from '../../../../../data/models/security/security';
import { getRole } from '../../../../../redux-store/role';

function ChallengeHeader ({ openGenericModal, history, showResume = true, bioMetricMatrix, userSession, customSessionStats, ...restProps }) {

    const remainingDays = 15 - moment().diff(moment(userSession.createdAt), 'days');

    const openChallengesResume = () => {

        openGenericModal({

            type: 'resumeProgress',
            step1Progress: (userSession.name && userSession.surname) ? 100 : 0,
            step2Progress: bioMetricMatrix.progress || 0,
            step3Progress: customSessionStats.userCustomSessionsCount || 0,
	        remainingDays

        })

    }

    const onExit = async () => {

        try {

            await SecurityModel.updateGrowChallengeStatus('explorer');
            await restProps.getRole();
            history.push('/home');

        } catch (error) {}

    } 
    
    return (
        <Header>
            <div>
               { showResume && 
                    <React.Fragment>
                        <p className="caption regular" style={{marginBottom: '0'}}>{I18n.t('growMode.header.text', { remainingDays, days: remainingDays !== 1 ? I18n.t('growMode.header.days') : I18n.t('growMode.header.day') })}</p>
                        <p className="caption regular blue-link c-pointer" onClick={ openChallengesResume }>{I18n.t('growMode.header.seeResume')}</p>
                    </React.Fragment>
                }
            </div>
            <div>
                <span className="caption regular blue-link c-pointer" onClick={ onExit }>{I18n.t('growMode.header.exit')}</span>
            </div>
        </Header>

    )
}

const mapStateToProps = state => {

    return {
        userSession: _.get(state.auth, 'userSession', { }),
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        customSessionStats: _.get(state, 'customSessionStats', {})
    };
};


export default connect(mapStateToProps, { getRole, openGenericModal })(ChallengeHeader);