import NoSleep from "nosleep.js";
import React, { useEffect, useMemo, useRef, useCallback } from "react";

//Use react memo to avoid rerender the component itself if there are renders from the parent
const UseNoSleep = React.memo(({initialValue = false}) => {

    const noSleep = useMemo(() => new NoSleep(), []);
    const isEnabled = useRef(initialValue);

    const onHandle = useCallback(() => {

        if (!isEnabled.current) {

            noSleep.enable();
            isEnabled.current = !isEnabled.current;

        }

    }, [noSleep]);

    const onClean = useCallback(() => {

        document.removeEventListener('click', onHandle);
        noSleep.disable();
        
    },[noSleep, onHandle]);

    useEffect(() => {

        document.addEventListener('click', onHandle, false);

        return () => onClean()

    }, [noSleep, onHandle, onClean]);

    return null;

});

export default UseNoSleep;