
import React from 'react';
import _ from 'lodash';
import { FrameClose, FrameModal, ModalContent, Actions, Content } from '../../styles/genericModal';
import OButton from '../../../../styled/components/button';
import { Icon } from 'semantic-ui-react';

/***
 * * SHARED DATA ACCEPT MODAL
 * 
 * @param buttons Array Objects
 * @param onClose Function
 * @param title String
 * @param subtitle String
 * @param description String 
 * @params @buttons { text, callback, options = { any button props } }
 * @description Modal to ask to the user to accept the biometric matrix data required for the adivsor
 */
export default function WalletModal({ title = {}, description = {}, buttons = [], scrolling = false, onClose, ...restProps }) {

    return (
        <FrameModal>
            <FrameClose>
                <Icon name="close" size="large" onClick={ onClose }/>
            </FrameClose>
            <ModalContent>
                <Content scroll={scrolling || false}>
                    {!_.isEmpty(title) && <>{title.text}</>}
                    {!_.isEmpty(description) && <>{description.text}</>}
                </Content>
                <Actions>
                    {
                        _.map(buttons, ({text, callback, options }, index) => (
                            <OButton key={index} type="button" {...options} onClick={ callback }><span>{text}</span></OButton>
                        ))
                    }
                </Actions>
            </ModalContent>
        </FrameModal>
    )

}
