import React, { Component } from 'react'
import { LandingHeader } from '../../components/header'
import {  ButtonWrapper, CtaLogin, CtaRegister, InnerSection, LandingWrap, Section, SectionGrid, SectionGridItemImage, SectionGridItemText, SectionSubHeaderCta, SectionSubHeaderLogo } from '../../styled'
import OButton from '../../../../../../styled/components/button';

import HeroImage from '../../../../../../assets/img/main_landing/precios.svg';
import Footer from '../../../../../../components/footer';
import { SectionPrices } from '../../components/prices';
import { CtaMarkets } from '../../components/ctaMarket';
import { TopSeparator } from '../../components/prices/styled';
import { AccountType } from '../../components/accountType';
import { motion } from 'framer-motion';
import { motionButton, motionSubtitle, motionTitle, transition } from '../../../../../../../utils/framerAnimations';

export default class PlansLanding extends Component {
    
    render() {
        return (
            <>
                <LandingWrap>
                    <LandingHeader active="plans" />
                    <Section>
                        <InnerSection sectionSubHeader>
                            <SectionSubHeaderLogo onClick={() => this.props.history.push("/")} />
                            <SectionSubHeaderCta>
                                <CtaLogin to="/auth/login">Inicia sesión</CtaLogin>
                                <CtaRegister to="/auth/register">Prueba gratis</CtaRegister>
                            </SectionSubHeaderCta>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid>
                                <SectionGridItemImage>
                                    <motion.img src={HeroImage} alt="" initial={{ y: 20, opacity: 0 }} animate = {{y: 0,opacity: 1, transition: transition}} />
                                </SectionGridItemImage>
                                <SectionGridItemText style={{textAlign:'left'}}>
                                    <motion.div initial="initial" animate="animate" exit='exit'>
                                        <motion.h1 variants={motionTitle} className="landing-heading-1">Precios</motion.h1>
                                        <motion.p variants={motionSubtitle} className="landing-heading-2 regular">Disfruta de la versión Yogabot LITE 100% Gratis.</motion.p>
                                    </motion.div>
                                    <motion.div initial="initial" animate="animate" exit='exit' variants={motionButton}>
                                        <ButtonWrapper reverse>
                                            <OButton upper type="button" color="#FFF" primary size="large" onClick={() => this.props.history.push('/auth/login')}>
                                                <span className="toregular">EMPIEZA AHORA</span>
                                            </OButton>
                                        </ButtonWrapper>
                                    </motion.div>
                                </SectionGridItemText>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionPricesAlternative className="section-prices">
                            <p className="landing-heading-2 title regular">En Yogabot APP tiene dos tipos de cuenta o licencia.</p>
                            <AccountType />
                            <p className="paragraph subtitle regular">Cuando te registras en Yogabot por primera vez, te ofrecemos 15 días de cuenta PREMIUM totalmente gratis, para que puedas tener una experiencia completa. Pasados los 15 días, tu cuenta pasa automáticamente a LITE.</p>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionPrices className="section-prices">
                            <p className="landing-heading-2 title">Comparativa de funcionalidades</p>
                            <SectionPrices {...this.props}/>
                        </InnerSection>
                    </Section>
                    <TopSeparator/>
                    <CtaMarkets />
                    <Footer {...this.props} type="branded" hasExtraFooter={true}/>
               </LandingWrap>
            </>
        )
    }
}
