// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

// Redux
import { openGenericModal, closeGenericModal } from '../../../../redux-store/genericModal';
import { openLoader, closeLoader } from '../../../../redux-store/loader';
import { getLastWeekTrainingSeconds } from '../../../../redux-store/session';

// Models
import SessionModel from '../../../../data/models/session/session';

// Components
import PracticeImage from './practiceImage';
import PracticeVideo from './practiceVideo';

// Styles
import './sharedStyles/styles.scss';

class HandleGrowPractice extends React.Component {

    constructor(props) {

        super(props);

        this.splitDecodeSequence = "";

        this.state = {
            data: {}
        }

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        this.splitDecodeSequence = window.atob(this.props.match.params.sequence).split('/');
        this.props.openLoader();

        try {

            const response = await SessionModel.getSequenceWithTranslation({ sequence: this.splitDecodeSequence[0] + '/' + this.splitDecodeSequence[1] });
            this.setState({ data: response.data });

        } catch (error) {

        } finally {

            this.props.closeLoader();

        }

    }

    renderPracticeType = () => {

        if (this.splitDecodeSequence[2] === 'video') {

            return <PracticeVideo { ...this.props } data={ this.state.data } sequence={ this.splitDecodeSequence[0] } language={ this.splitDecodeSequence[1] } isAlternative={ JSON.parse(this.splitDecodeSequence[3]) } />;

        } else {

            return <PracticeImage { ...this.props } data={ this.state.data } sequence={ this.splitDecodeSequence[0] } language={ this.splitDecodeSequence[1] } isAlternative={ JSON.parse(this.splitDecodeSequence[3]) } />;

        }

    }

    render() {

        return _.size(this.state.data) > 0 && this.renderPracticeType();

    }

}

const mapStateToProps = state => {

    return {
        sessionTime: _.get(state, 'session.time', 0),
        userName: _.get(state.auth, 'userSession.name', ''),
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        windowMeassures: state.windowMeassures
    };

};

export default connect(mapStateToProps, { openLoader, closeLoader, getLastWeekTrainingSeconds, openGenericModal, closeGenericModal })(HandleGrowPractice);