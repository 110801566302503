import BiometricModel from '../data/models/biometric/biometric';

// Types
const SET_BIOMETRIC_MATRIX = 'SET_BIOMETRIC_MATRIX';

// Actions

export const getBiometricMatrix = () => async dispatch => {
 
  try {

    const response = await BiometricModel.getBiometricMatrix();

    if (response.status === 200) {

        dispatch({ type: SET_BIOMETRIC_MATRIX, payload: {...response.data } } );

    }
    
  } catch (errorCode) {
    
      dispatch({ type: SET_BIOMETRIC_MATRIX, payload: {} } )
      throw errorCode;

  }
  
};

// Reducer
const INITIAL_STATE = {

    bioMetricMatrixData : {}
    
};

function reducer (state = INITIAL_STATE, action) {

    switch (action.type) {

        case SET_BIOMETRIC_MATRIX:
            
            return { ...state, bioMetricMatrixData: action.payload };

        default:

            return state;

    }

};

export default reducer; 