import _ from 'lodash';
import React from 'react';
import { Trans } from 'react-i18next';
import I18n from '../../../i18n';

import OButton from '../../styled/components/button';
import { getSubscriptionPrices, getOneYearPaymentPromotionPrice, hasOneYearPaymentPromotion } from '../../../utils/checkPromotions';

import history from '../../../history';

import Utils from '../../../utils';

import './promoBlock.scss';

class PromoBlock extends React.Component {

    onAccept = () => hasOneYearPaymentPromotion() ? history.push('/profile/promotion') : history.push('/profile/plans');

    renderRegularBlock = () => {

        const { currency } = this.props;
        const annualPaymentWithoutDiscount = { USD: 9.99 * 12, EUR: 8.99 * 12 };

        return (
            <div className="promo-block">
                <div className="promo-block__header">
                    <div className="promo-block__banner">
                        {I18n.t('promotion.block.banner', {percent: 100 - _.round(getSubscriptionPrices().annualPayment[currency] / annualPaymentWithoutDiscount[currency] * 100) })}
                    </div>
                    <div className="promo-block__meta-block">
                        <div className="promo-block__title">
                            <Trans i18nKey="promotion.block.bodyText2">
                                <strong></strong><span className="cbrand"></span>
                            </Trans>
                            </div>
                        <div className="promo-block__price promo-block__price--green">{ Utils.getAmountLiteral(getSubscriptionPrices().annualPayment[currency], currency) } <span className="year">{I18n.t('promotion.block.year')}</span></div>
                        <div className="promo-block__price promo-block__price--sub">{ Utils.getAmountLiteral(_.round(getSubscriptionPrices().annualPayment[currency] / 12), currency) } {I18n.t('promotion.block.billingInfo')}</div>
                    </div>
                </div>
                <div className="promo-block__body">
                    <p>
                        <Trans i18nKey="promotion.block.bodyText1">
                            <strong></strong>
                        </Trans>
                    </p>
                </div>
                <div className="promo-block__footer">
                    <OButton primary type="button" color="#FFF" fluid upper onClick={this.onAccept}>
                        <span>{I18n.t('promotion.block.action')}</span>
                    </OButton>
                </div>
            </div>
        );

    }

    renderPromoBlock = () => {

        const { currency } = this.props;
        
        return (
            <div className="promo-block">
                <div className="promo-block__header">
                    <div className="promo-block__banner">
                        {I18n.t('promotion.block.withPromo.banner')}
                    </div>
                    <div className="promo-block__meta-block">
                        <div className="promo-block__title">
                            <Trans i18nKey="promotion.block.withPromo.bodyText1">
                                <span className="cbrand"></span>
                            </Trans>
                        </div>
                        <div className="promo-block__price promo-block__price--green">{ Utils.getAmountLiteral(getOneYearPaymentPromotionPrice()[currency], currency) } <span className="year">{I18n.t('promotion.block.withPromo.year')}</span></div>
                        <div className="promo-block__price promo-block__price--sub">{I18n.t('promotion.block.withPromo.regularPrice', {price: 9.99})}</div>
                    </div>
                </div>
                <div className="promo-block__body">
                    <p>
                        <Trans i18nKey="promotion.block.withPromo.bodyText2" count={Utils.getAmountLiteral(_.round(getOneYearPaymentPromotionPrice()[currency] / 12, 2), currency)}>
                            <strong></strong><strong></strong><strong></strong>
                        </Trans>
                    </p>
                </div>
                <div className="promo-block__footer">
                    <span className="promo-block__btn" onClick={this.onAccept}>{I18n.t('promotion.block.withPromo.action')}</span>
                </div>
            </div>
        );

    }

    render() {

        return hasOneYearPaymentPromotion() ? this.renderPromoBlock() : this.renderRegularBlock();

    }

}

export default PromoBlock;