import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import I18n from '../../../../../../i18n';
import { toMMSS } from '../../../../../../utils/formatTime';
import OButton from '../../../../../styled/components/button';
import { Separator, FrameButtons, AsanaTitle, AsanaContainer, AsanasGrid, Asana, Title, Subtitle, Label, FrameProgress, Counter, CounterButton, Progress, Image, FrameImageRatio, FrameImage, InnerProgress, StartMessage, SelectedLayer  } from './styles';

const OButtonStyled = styled(OButton)`
    @media (max-width: 600px) {
        margin-bottom:10px!important;
    }
`;

function AsanaForm({ challenges, indexChallenge, currentAsana, indexAsana, onStartCountDown, secondsElapsed, showStartMessage, countDown, onSelectAsana, onPreviousMeasurement, onNextMeasurement, onFinalizeMeasurement }) {

    const getProgressColor = percent => {

        if (percent >= currentAsana.ranges.min && percent < currentAsana.ranges.max) {

            return '#ffcf00';

        } else if (percent >= currentAsana.ranges.max) {

            return '#1FD5B9';

        } else {

            return '#e20d0dd';
            
        }

    }
    
    return (
        <AsanaContainer>
            <Title>
                {currentAsana.type === 'tone' && <React.Fragment><i className="f-icon-tono"></i><p className="heading-2 brand-secondary" style={{margin: '0'}}>{currentAsana.blockText}</p><i className="f-icon-tono"></i></React.Fragment>}
                {currentAsana.type !== 'tone' && <React.Fragment><i className="f-icon-elasticity"></i><p className="heading-2 brand-secondary" style={{margin: '0'}}>{currentAsana.blockText}</p><i className="f-icon-elasticity"></i></React.Fragment>}
            </Title>
            <Subtitle>
                { currentAsana.underBlockText && <p className="paragraph regular">{currentAsana.underBlockText}</p>}
            </Subtitle>
            { currentAsana.type === 'tone' && 
                <FrameProgress>
                    <Counter>
                        <CounterButton onClick={onStartCountDown}>
                            <span className="paragraph white upper">{I18n.t('actions.startCounter')}</span>
                            <i className="f-icon-icono_11"></i>
                        </CounterButton>
                    </Counter>
                    <Progress>
                        <InnerProgress 
                            width={((secondsElapsed/currentAsana.ranges.max) * 100)}
                            bgColor={getProgressColor(secondsElapsed)}
                            />
                    </Progress>
                    <CounterButton className="clock">
                        <span className="paragraph white upper">{toMMSS(secondsElapsed)}</span>
                    </CounterButton>
                    <StartMessage opacity={showStartMessage ? '1' : '0'} >
                        <span className="caption regular">{I18n.t('challenge.startIn')} {countDown}</span>
                    </StartMessage>
                </FrameProgress>
            }
            <AsanasGrid>
                { (currentAsana && currentAsana.blocks) && currentAsana.blocks.map((asana, index) => (

                        <Asana key={index} selected={ currentAsana.selectedValue === asana.value } onClick={() => onSelectAsana(asana.value)}>
                            <SelectedLayer>
                                <i className="f-icon-icono_4"></i>
                            </SelectedLayer>
                            {currentAsana.type === 'tone' && 
                                <Label index={index}>
                                    <span className="caption regular white upper">{I18n.t(`challenge.range${index}`)}</span>
                                </Label> 
                            }
                            <FrameImageRatio>
                                <FrameImage>
                                    <Image src={asana.image} alt="" />
                                </FrameImage>
                            </FrameImageRatio>
                            <AsanaTitle>
                                <p className="paragraph upper brand-secondary">{asana.title}</p>
                            </AsanaTitle>
                        </Asana>
    
                    ))

                }
            </AsanasGrid>
            <FrameButtons>
                {indexAsana > 0 && <OButton type="button" secondary upper icon onClick={onPreviousMeasurement} labelPosition="left"><span>{I18n.t('challenge.previousMeasurement')}</span><Icon name="angle left" /></OButton>}
                {indexAsana > 0 && <Separator/>}
                {indexAsana < challenges[indexChallenge].asanas.length - 1 && <OButtonStyled primary upper type="button" icon labelPosition="right" disabled={currentAsana.selectedValue < 1 } onClick={onNextMeasurement}><Icon name="angle right" /><span>{ I18n.t('challenge.nextMeasurement')}</span></OButtonStyled>}
                {/*do not remove double comparation expression due to indexAsana is string and length is numeric*/}
                {indexAsana == challenges[indexChallenge].asanas.length - 1 && <OButtonStyled primary upper type="button" disabled={currentAsana.selectedValue < 1 } onClick={onFinalizeMeasurement} ><span>{I18n.t('challenge.finalizeMeasurement')}</span></OButtonStyled>}
            </FrameButtons>
        </AsanaContainer>

    )
}

export default AsanaForm;