import styled from 'styled-components/macro';

const FrameBody = styled.div`
    background-color: var(--brand-alarm);
    width:100%;
`;
const FrameContent = styled.div`
    padding: 10px 20px;

`;

const FrameProgress = styled.div`
    margin:20px 0 20px 0;
`;

const Progress = styled.div`
    display:flex;
    justify-content:space-between;
    margin-bottom:10px;
`;

const ProgressBar = styled.div`

    width:100%;
    overflow:hidden;
    background:#fff;
    height:4px;
    border-radius:5px;
    margin-bottom:20px;

`;

const ProgressInner = styled.div`

    background-color:var(--brand-primary);
    height:100%;
    width:${({progress}) => `${progress}%` };

`;

ProgressInner.defaultProps = {
    progress: 0
}

export {
    FrameBody,
    FrameContent,
    FrameProgress,
    Progress,
    ProgressBar,
    ProgressInner
}