// Third party libraries
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';

// Components
import InAppFirebaseNotification from '../../components/inAppFirebaseNotification/InAppFirebaseNotification';

// Styled components
import OButton from '../../styled/components/button';

// Models
import SecurityModel from '../../../data/models/security/security';

// Redux
import { getRole } from '../../../redux-store/role';

// Utils
import { askForNotificationsPermission } from '../../../utils/firebase';

// Locales
import I18n from '../../../i18n';

// Styles
import { Container, Frame, FrameCard, Card, CornerLabel, CornerLabelText, FrameText, FrameOptions, OptionText, OptionIcon } from './styles';

class ModeSelection extends Component {

    componentDidMount() {

        askForNotificationsPermission([]);

    }

    onSelectMode = async status => {

        if (status === 'grow') {

            try {

                await SecurityModel.updateGrowChallengeStatus(status);
                await this.props.getRole();
                this.props.history.push('/mode/grow/step/0');

            } catch (error) {}

        } else {

            try {

                await SecurityModel.updateGrowChallengeStatus(status);
                await this.props.getRole();
                this.props.history.push('/profile/account');

            } catch (error) {}

        }

    }

    render() {
        return (
            <Container>
                <Frame>
                    <p className="heading-2 brand-secondary" style={{marginBottom: '5px'}}>{I18n.t('steps.modeSelection.title')}</p>
                    <p className="heading-2">{I18n.t('steps.modeSelection.subtitle')}</p>
                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('steps.modeSelection.description')}}></p>
                    <FrameCard>
                        <Card>
                            <CornerLabel>
                                <CornerLabelText><span className="caption white upper">{I18n.t('steps.modeSelection.popular')}</span></CornerLabelText>
                            </CornerLabel>
                            <FrameText>
                                <p className="heading-3">{I18n.t('steps.modeSelection.growMode')}</p>
                                <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('steps.modeSelection.growModeText1')}}></p>
                                <FrameOptions>
                                    <OptionIcon>
                                        <Icon name="database" className="brand-white"/>
                                    </OptionIcon>
                                    <OptionText className="paragraph regular">
                                        {I18n.t('modals.challengeCompleteModal.firstAward')}
                                    </OptionText>
                                </FrameOptions>
                                <FrameOptions>
                                    <OptionIcon>
                                        <Icon name="question" className="brand-white" />
                                    </OptionIcon>
                                    <OptionText className="paragraph regular">
                                        {I18n.t('steps.modeSelection.growModeText2')}
                                    </OptionText>
                                </FrameOptions>
                                <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('steps.modeSelection.growModeText3')}}></p>
                            </FrameText>
                            <OButton type="button" style={{marginTop : "auto"}} onClick={ ()=>{ this.onSelectMode('grow') } } primary upper color="#FFF"><span>{I18n.t('actions.select')}</span></OButton>
                        </Card>
                        <Card>
                        <FrameText>
                            <p className="heading-3">{I18n.t('steps.modeSelection.explorerMode')}</p>
                            <p className="paragraph regular">{I18n.t('steps.modeSelection.explorerModeText1')}</p>
                        </FrameText>
                        <OButton type="button" style={{marginTop : "auto"}} onClick={ ()=>{ this.onSelectMode('explorer') } } primary upper color="#FFF" ><span>{I18n.t('actions.select')}</span></OButton>
                        </Card>
                    </FrameCard>
                </Frame>
                { window.cordova && <InAppFirebaseNotification notificationTokens={ [] } /> }
            </Container>
        );
    }
}

export default connect(null, { getRole })(ModeSelection);