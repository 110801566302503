import styled, {css} from 'styled-components/macro';

const FrameGrid = styled.div`

    position: relative;
    padding: 0 24px;
    @media only screen and (max-width:420px) {
        padding: 0;
    }

`;

const FrameGridFixed = styled.div`

    position: relative;
    @media only screen and (max-width:820px) {
        padding: 0 24px;
    }
    @media only screen and (max-width:420px) {
        padding: 0;
    }

`;

const FrameSlide = styled.div`
    padding: 10px;
    cursor: pointer;
    height: 100%;
    
`;

const FrameSlideFixedWidth = styled.div`
    
    padding: 10px;
    cursor: pointer;
    height: 100%;
    width:${({width}) => (width && `${width}!important`) || '250px!important'};
    

`;

const SlideContent = styled.div`

    border-radius: 5px;
    overflow: hidden;
    background-color: white;
    height: 100%;
    position:relative;
    &::after {
        content:"";
        position:absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: solid 1px var(--border-color);
        pointer-events:none;
        border-radius: 5px;
        z-index:1;
    }
    &:hover {
        background-color: #f8f8f8;
    }
   
`;

const FrameImageRatio = styled.div`
    width: 100%;
    padding-top: ${({ratio}) => ratio || '56.25%'};
    position: relative;
    overflow: hidden;

`;

const FrameImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const Image = styled.img`
    bottom: 0px;
    display: block;
    height: 100%;
    left: 0px;
    object-fit: ${({bsize}) => bsize || 'cover'};
    opacity: 1;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
`;


const SliderArrows = styled.button`

    cursor: pointer;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    opacity: 1;
    position: absolute;
    top: 0px;
    transform: translateX(-100%);
    width: 34px;
    z-index: 1;
    background: transparent;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    padding: 0px;
    transition: opacity 0.2s ease 0s;
    align-items: center;

    &.prev-a {
        left: 0;
    }
    &.next-a {
        right: 0;
        transform: translateX(100%);
    }

    &.slick-disabled {
        display: none;
    }

    @media (max-width:420px) {
        display:none;
    }

`; 

const FrameIndicator = styled.div`

    position: relative;
    background-color: #fff;
    padding: 5px;
    z-index:${({zIndex}) => zIndex || '1'};
    display:inline-block;
    border-radius:5px;

    ${({floating}) => floating &&
    css`
        position: absolute;
        top: 8px;
        left: 8px;
        
`};

`;


export {
    Image,
    FrameGrid,
    SliderArrows,
    FrameIndicator,
    FrameImageRatio,
    FrameImage,
    FrameSlide,
    SlideContent,
    FrameSlideFixedWidth,
    FrameGridFixed
}