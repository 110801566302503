import React from 'react';
import { Icon } from 'semantic-ui-react';

import SubMenu from '../../../../components/navbar/submenu';
import GradientNav from '../../../../components/navbar/gradient';
import Footer from '../../../../components/footer';
import TopBar from '../../../../components/navbar/topbar';
import GuideOptionColorElement from '../../../../components/guide/color';

import I18n from '../../../../../i18n';

import '../guide.scss';

class MatrixGuideOption2 extends React.Component {

    render() {

        return (
            <React.Fragment>
                <GradientNav active="home" {...this.props} />
                <div className="wrapper-guide">
                    <TopBar {...this.props} callback={()=> this.props.history.push(`/home/guide`)} text={I18n.t('guide.codeColor')}/>
                    <div className="inner full">
                        <div className="background"></div>
                        <div className="i-back" style={{margin: '40px 0 40px 0'}} onClick={()=> this.props.history.push(`/home/guide`)}><Icon name="angle left" /><span>{I18n.t('actions.back')}</span></div>
                        <GuideOptionColorElement />
                    </div>
                    <div className="footer-separator"></div>
                    <Footer {...this.props} type="branded"/>

                </div>
                <SubMenu active="home" {...this.props}  />
                </React.Fragment>

        );

    }

}

export default MatrixGuideOption2;