// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';

// Utils
import UseNoSleep from '../../../../../utils/noSleep';
import { TrackingService } from '../../../../../utils/TrackingService';
import { changeScreenOrientation, completeSession, showHealthAdvise, onRestart } from '../../../../../utils/practice';

// Models
import SessionModel from '../../../../../data/models/session/session';

// Styled components
import { Container, InnerContainer } from '../sharedStyles';

// Components
import ChallengeHeader from '../../components/header';
import StepsNav from '../../components/stepsNav';
import PlayerButtons from '../../../practice/components/playerButtons';
import PlayerTimer from '../../../practice/components/playerTimer';
import NextSlide from '../../../practice/components/nextSlide';
import ProgressBar from '../../../practice/components/progressBar';
import Preambule from '../../../practice/components/preambule';

// Assets
import Poster from '../../../../assets/img/poster.jpg';

class PracticeVideo extends Component {

    videoPlayersCounter = 5;
    intervalCountDown = 0;
    song = null;
    videoWidthSufix = Math.max(window.innerHeight, window.innerWidth) > 1280 ? '' : '_720';
    videos = [];
    currentVideo = 0;
    videoplayers = [];
    language = window.atob(this.props.match.params.sequence).split('/')[1];
    startOnPortrait = false;

    constructor(props) {

        super(props);

        let globalSequenceTime = 0;
        _.each(this.props.data.routine, routine => {

            globalSequenceTime += routine.duration;

        });

        _.each(this.props.data.routine, (asana, index) => {

            this.videos.push({
                mov: asana.video.substring(0, asana.video.indexOf('.mov')) + this.videoWidthSufix + '.mov',
                mp4: asana.video.substring(0, asana.video.indexOf('.mov')) + this.videoWidthSufix + '.mp4',
                webm: asana.video.substring(0, asana.video.indexOf('.mov')) + this.videoWidthSufix + '.webm',
                loop: true,
                duration: asana.duration,
                currentAsanaIndex: index
            });

            _.each(asana.transitionVideos, transitionVideo => {

                this.videos.push({
                    mov: transitionVideo.substring(0, transitionVideo.indexOf('.mov')) + this.videoWidthSufix + '.mov',
                    mp4: transitionVideo.substring(0, transitionVideo.indexOf('.mov')) + this.videoWidthSufix + '.mp4',
                    webm: transitionVideo.substring(0, transitionVideo.indexOf('.mov')) + this.videoWidthSufix + '.webm',
                    loop: false
                });

            });

        });

        this.song = new Audio(this.props.data.routine[0].nameAudio);

        const videoPlayerSources = [];
        for (let i = 0; i < this.videoPlayersCounter; i++) {

            videoPlayerSources.push(this.videos[i]);

        }

        this.state = {
            isFull: false,
            data: this.props.data,
            elapsedTime: 0,
            startTime: 0,
            firstStart: false,
            globalProgress: 0,
            globalSequenceTime,
            showPreambule: false,
            showNextSlide: false,
            status: 'idle',
            startTimePreambule: 0,
            userSessionId: '',
            indicators: {
                flexoextensionIndicator: this.props.bioMetricMatrix.flexoextensionIndicator || 0,
                lateralizationIndicator: this.props.bioMetricMatrix.lateralizationIndicator || 0,
                abductionAductionIndicator: this.props.bioMetricMatrix.abductionAductionIndicator || 0,
                muscularTrainIndicator: this.props.bioMetricMatrix.muscularTrainIndicator || 0
            },
            currentPlayer: 0,
            videoPlayerSources,
            isOnTransitionVideos: false
        };

        this.videoRefs = [];
        for (let i = 0; i < this.videoPlayersCounter; i++) {

            this.videoRefs[i] = React.createRef();

        }

    }

    componentWillUnmount() {

        clearInterval(this.intervalCountDown);
        clearInterval(window['yogabotIntervalsPracticePreambule']);
        delete window['yogabotIntervalsPracticePreambule'];
        this.props.closeGenericModal();
        this.startOnPortrait = changeScreenOrientation('portrait', this.startOnPortrait);

    }

    componentDidMount() {

        this.preloadVideos();

        showHealthAdvise(this.props.sessionTime, this.props.openGenericModal, this.props.closeGenericModal);

        for (let i = 0; i < this.videoPlayersCounter; i++) {

            this.videoRefs[i].current.setAttribute('webkit-playsinline', true);

        }

    }

    onPlayVideo = () => {

        if (this.videos[this.currentVideo].loop) {

            if (this.currentVideo > 0) {

                this.song.src = 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/asanas/sonidos/bell.mp3';
                this.song.play();

            }

            const elapsedTime = this.state.elapsedTime ? this.state.elapsedTime : this.videos[this.currentVideo].duration;
            const startTime = this.state.elapsedTime ? Math.floor(Date.now() - ((this.videos[this.currentVideo].duration - 8 - elapsedTime) * 1000)) : Date.now();

            this.setState({ status: 'playing', elapsedTime, startTime, isOnTransitionVideos: false });

            clearInterval(this.intervalCountDown);
            this.intervalCountDown = setInterval(() => {

                this.setState({ elapsedTime: this.videos[this.currentVideo].duration - (Math.floor((Date.now() - this.state.startTime) / 1000)) });

                if (this.videos[this.currentVideo].duration - (Math.floor((Date.now() - this.state.startTime) / 1000)) <= 0) {

                    clearInterval(this.intervalCountDown);
                    this.videoplayers[this.state.currentPlayer].pause();
                    this.onVideoEnded();

                } else if (!this.state.showNextSlide
                    && _.get(this.state.data.routine, '[' + (this.videos[this.currentVideo].currentAsanaIndex + 1) + '].duration', false)
                    && this.videos[this.currentVideo].duration - (Math.floor((Date.now() - this.state.startTime) / 1000)) <= 10) {

                        if (this.videos[this.currentVideo].duration > 10 ) {

                            this.song.src = 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/asanas/sonidos/ES/next.mp3';
                            this.song.addEventListener('ended', () => {

                                this.song = this.song.cloneNode(true);
                                this.song.src = this.state.data.routine[this.videos[this.currentVideo].currentAsanaIndex + 1].nameAudio;
                                this.song.play();

                            }, false);

                            this.song.play();

                        } else if (this.videos[this.currentVideo].duration >=5) {

                            this.song.src = this.state.data.routine[this.videos[this.currentVideo].currentAsanaIndex + 1].nameAudio;
                            this.song.play();

                        }

                        this.setState({ showNextSlide: true });

                }

            }, 500);

        } else {

            this.setState({ isOnTransitionVideos: true });

        }

    }

    onVideoEnded = () => {

        if (this.currentVideo < this.videos.length - 1) {

            this.currentVideo++;
            let endedPlayer = this.state.currentPlayer;
            let playerToGo = endedPlayer === this.videoPlayersCounter - 1 ? 0 : endedPlayer + 1;

            let nextVideoToLoad = this.currentVideo + this.videoPlayersCounter - 1 < this.videos.length ? this.currentVideo + this.videoPlayersCounter - 1 : 0;
            const videoPlayerSources = _.cloneDeep(this.state.videoPlayerSources);
            videoPlayerSources[endedPlayer] = this.videos[nextVideoToLoad];
            this.downloadVideo(nextVideoToLoad, endedPlayer);

            const globalProgress = this.videos[this.currentVideo - 1].loop ? this.state.globalProgress + this.state.data.routine[this.videos[this.currentVideo - 1].currentAsanaIndex].duration : this.state.globalProgress;

            this.setState({
                currentPlayer: playerToGo,
                videoPlayerSources,
                globalProgress,
                showNextSlide: false,
                elapsedTime: 0
            }, () => this.videoplayers[endedPlayer].load());

            this.videoplayers[playerToGo].play();

            if (this.videos[this.currentVideo - 1].loop) {

                try {

                    SessionModel.onAsanaFinish({
                        userSessionId: this.state.userSessionId,
                        timeElapsed: this.state.data.routine[this.videos[this.currentVideo - 1].currentAsanaIndex].duration
                    }).then(() => {

                        this.props.getLastWeekTrainingSeconds();

                    });

                } catch (error) {

                    console.error('Error:onAsanaFinish: ', error);

                }

            }

        } else {

            this.setState({
                globalProgress: this.state.globalSequenceTime,
                showNextSlide: false,
                elapsedTime: 0,
                status: 'idle',
                showPreambule: false
            });
            completeSession(this.state.userSessionId, this.props.history, this.language, '/mode/grow/result/sequence', this.props.getLastWeekTrainingSeconds);

        }

    }

    preloadVideos = () => {

        for (let i = 0; i < this.videoPlayersCounter; i++) {

            this.videoplayers.push(document.getElementById('video_' + i));
            this.videoplayers[i].addEventListener('play', this.onPlayVideo);
            this.videoplayers[i].addEventListener('ended', this.onVideoEnded);
            this.downloadVideo(i, i);

        }

    }

    downloadVideo = (videoIndex, playerIndex) => {

        const video = this.videos[videoIndex];
        let url = video.mov;
        if (window.Modernizr.video && (window.Modernizr.video.webm === 'probably' || (window.Modernizr.video.webm === 'maybe' && window.Modernizr.video.h264 !== 'probably'))) {

            url = video.webm;

        } else if (window.Modernizr.video && ['probably', 'maybe'].indexOf(window.Modernizr.video.h264)) {

            url = video.mp4;

        }
        const request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.responseType = "blob";
        const that = this;
        request.onload = function () {

            if (this.status === 200) {

                if (that.currentVideo < videoIndex || (videoIndex === 0 && !that.intervalCountDown && !that.state.showPreambule)) {

                    that.videoplayers[playerIndex].src = URL.createObjectURL(this.response);
                    that.videoplayers[playerIndex].load();

                }

            }

        }

        request.send();

    }

    restart = () => {

        this.setState({ firstStart: false, globalProgress: 0, currentAsanaIndex: 0, showNextSlide: false, elapsedTime: 0, status: 'idle', showPreambule: false, isFull: false });
        this.preloadVideos();
        clearInterval(this.intervalCountDown);

    }

    onStart = async () => {

        TrackingService.registerEvent('Practice', 'practiceSequenceStart');

        try {

            this.song = new Audio(this.props.data.routine[0].nameAudio);
            this.song.play();
            this.startOnPortrait = changeScreenOrientation('landscape', this.startOnPortrait);

            const response = await SessionModel.startSession({ sequence: this.props.sequence });

            this.setState({ userSessionId: response.data._id, firstStart: true, isFull: true, showPreambule: true, startTimePreambule: Date.now() });
            this.videos[this.currentVideo].duration += 8;
            this.videoplayers[this.state.currentPlayer].play();

        } catch (error) {

            console.error('Error:onStart: ', error);

        }

    }

    onPause = () => {

        clearInterval(this.intervalCountDown);
        this.videoplayers[this.state.currentPlayer].pause();
        this.setState({ status: 'resume', isFull: false });
        this.startOnPortrait = changeScreenOrientation('portrait', this.startOnPortrait);

    }

    onResume = () => {

        this.setState({ isFull: true, showPreambule: true, startTimePreambule: Date.now() });
        this.videoplayers[this.state.currentPlayer].play();
        this.startOnPortrait = changeScreenOrientation('landscape', this.startOnPortrait);

    }

    renderVideoPlayers = () => {

        const playersRenderization = [];
        for (let i = 0; i < this.videoPlayersCounter; i++) {

            playersRenderization.push(i);

        }

        return playersRenderization.map(i =>

            <video poster={Poster} ref={ this.videoRefs[i] } playsInline key={ 'video_' + i } style={{ display: this.state.currentPlayer === i ? 'block' : 'none' }} muted preload="auto" id={ 'video_' + i } loop={ this.state.videoPlayerSources[i].loop }>
                <source src={ this.state.videoPlayerSources[i].webm } type="video/webm" />
                <source src={ this.state.videoPlayerSources[i].mp4 } type="video/mp4" />
                <source src={ this.state.videoPlayerSources[i].mov } />
                Your browser does not support the video tag.
            </video>

        );

    }


    render() {

        const { isFull } = this.state;

        const { windowMeassures : { height } } = this.props;

        return (
            <Container>
                <InnerContainer>
                    <UseNoSleep />
                    <ChallengeHeader { ...this.props } />
                    <StepsNav activedStep={ 3 } { ...this.props } />
                    { this.state.data &&
                    <div className={ isFull ? 'fullscreen-background' : '' }>
                        <div className={ 'wrapper-slides ' + (isFull ? 'fullscreen-enabled' : '') } style={{ width:'100%', maxWidth: isFull ? `${height * (16 / 9)}px` : '100%' }}>
                            <div className="wrapper-slide">
                                <Preambule
                                    visible={ this.state.showPreambule }
                                    startTimePreambule={ this.state.startTimePreambule }
                                    callback={ () => this.setState({ showPreambule: false }) }
                                />
                                <div className={ "content " + (this.state.showPreambule ? 'blurred': '') }>
                                    <PlayerButtons
                                        visible={ !this.state.showPreambule && !this.state.isOnTransitionVideos }
                                        status={ this.state.status }
                                        firstStart={ this.state.firstStart }
                                        onStart={ this.onStart }
                                        onRestart={ () => onRestart(this.props.openGenericModal, this.props.closeGenericModal, this.onPause, this.restart, this.onResume) }
                                        onPause={ this.onPause }
                                        onResume={ this.onResume }
                                    />
                                    <PlayerTimer
                                        visible={ !this.state.showPreambule && !this.state.isOnTransitionVideos }
                                        slideElapsedTime={ this.state.elapsedTime }
                                    />
                                    { this.renderVideoPlayers() }
                                    <NextSlide
                                        visible={ this.state.showNextSlide }
                                        currentSlideIndex={ this.videos[this.currentVideo].currentAsanaIndex }
                                        routine={ this.state.data.routine }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    <ProgressBar
                        globalProgress={ this.state.globalProgress }
                        globalSequenceTime={ this.state.globalSequenceTime }
                    />
                </InnerContainer>
            </Container>
        )
    }
}

export default PracticeVideo;