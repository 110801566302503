import styled, {css} from 'styled-components/macro';

const CartDetailWrap = styled.div`

    flex-grow: 0.3;
    flex-basis: 0%;
    flex-shrink: 1;
    .s-cart-btn {
            
        @media only screen and (max-width: 720px) {
            font-size: 12px;
         }
         @media only screen and (min-width: 721px) {
            font-size: 14px;
         }
    }

    ${({desktop}) => desktop &&
        css`
            @media only screen and (max-width: 719px) {
                display: none;
            }
    `};

    ${({mobile}) => mobile &&
        css`
            flex-grow: 1;
            @media only screen and (min-width: 720px) {
                display: none;
            }
    `};

`;

const CartDetail = styled.div`

    padding: 40px 20px;
    background-color: #f2f2f2;
    font-size: 14px;
    position: sticky;
    top: 20px;
`;

const DetailAmount = styled.p`
    font-family: PoppinsSemiBold;
    color: var(--y-grey3);
    font-size: 0.95em;
`;

export {
    CartDetailWrap,
    CartDetail,
    DetailAmount
}