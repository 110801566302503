// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';

// Redux
import { closeSession } from '../../../../redux-store/auth';

// Styled Components
import { NavContainer, NavContent, MainLogo, NavItemsContainer, NavItems, NavItem, NavItemsSecondary, NavItemSecondary, Budget } from './styles';

// Locales
import I18n from '../../../../i18n';

// Assets
import Logo from '../../../assets/img/logo_large.svg';

class GradientNav extends Component {

    render() {

        const { products, pendingOperations } = this.props;
        const itemsLength = _.filter(products, ({ added }) => added).length;

        return (
            <NavContainer>
                <NavContent>
                    <MainLogo>
                        <img src={ Logo } alt='Yogabot' onClick={ () => this.props.history.push(`/home`) } />
                    </MainLogo>
                    <NavItemsContainer>
                        <NavItems>
                            <NavItem active={ this.props.active === 'home' } onClick={ () => this.props.history.push(`/home`) } >{ I18n.t('menu.myMatrix') }</NavItem>
                            <NavItem active={ this.props.active === 'practice' } onClick={ () => this.props.history.push(`/practiceList`) } >{ I18n.t('menu.practice') }</NavItem>
                            <NavItem active={ this.props.active === 'studio' } onClick={ () => this.props.history.push(`/studio`) } >{ I18n.t('menu.studio') }</NavItem>
                        </NavItems>
                    </NavItemsContainer>
                    <NavItemsSecondary>
                        <NavItemSecondary onClick={ ()=> this.props.history.push('/profile/account') } >
                            <Icon name='user circle'></Icon>{ pendingOperations && <Budget hasPendings /> }
                        </NavItemSecondary>
                        { itemsLength === 0 ?
                            <Popup
                                trigger={ <NavItemSecondary><Icon name='cart'></Icon></NavItemSecondary> }
                                content={ <div className='basket-empty'>{ I18n.t('menu.emptyCart') }</div> }
                                position='bottom right'
                                on='click'
                                hideOnScroll
                            /> :
                            <NavItemSecondary onClick={ () => this.props.history.push('/cart') }>
                                <Icon name='cart'></Icon><Budget><p>{ itemsLength }</p></Budget>
                            </NavItemSecondary>
                        }
                    </NavItemsSecondary>
                </NavContent>
            </NavContainer>
        );

    }

}

const mapStateToProps = state => {

    return {
        products: _.get(state,'cart.products', []),
        pendingOperations: state?.walletTransactions?.pendingOperations
    };

};

export default connect(mapStateToProps, { closeSession })(GradientNav);