import _ from 'lodash';
import React from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Form, Input } from 'semantic-ui-react';

import OButton from '../../styled/components/button';

import PaymentModel from '../../../data/models/payment/payment';

import { getStripeCouponPrices, hasStripeCouponPromotion } from '../../../utils/checkPromotions';

import I18n from '../../../i18n';

class StripeCheckoutForm extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            formProcess: false,
            couponCode: ''
        };

        this.formProcess = false;

    }

    handleSubmit = async event => {

        event.preventDefault();
        const { stripe, elements, amount, description, periodic, currency, tokens, type, products, isYgbPayment } = this.props;
        const { couponCode } = this.state;

        if (!this.formProcess) {

            this.formProcess = true;
            this.setState({ formProcess: true });

            try {

                const { paymentMethod, error } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement('cardNumber'),
                });

                if (error) {

                    this.formProcess = false;
                    this.setState({formProcess: false});
                    throw error.message;

                }

                if (periodic) {

                    const stripePaymentSuscriptionData = {
                        type,
                        paymentMethodId: paymentMethod.id,
                        amount: amount * 100,
                        currency: currency
                    };

                    if (this.isValidCoupon(couponCode)) {

                        stripePaymentSuscriptionData.coupon = couponCode;

                    }

                    const subscription = await PaymentModel.createStripePaymentSubscription(stripePaymentSuscriptionData);

                    const userSubscription = await PaymentModel.createUserPayment({
                        amount: amount,
                        currency: currency,
                        description,
                        type,
                        platform: 'stripe',
                        brand: paymentMethod.card.brand,
                        last4: paymentMethod.card.last4,
                        orderId: subscription.data.code
                    });

                    this.props.onSuccess(userSubscription);

                } else {

                    const paymentIntent = await PaymentModel.createStripePaymentIntent({
                        amount: amount * 100,
                        currency: currency,
                        periodic,
                        description,
                        paymentMethod: paymentMethod.id
                    });

                    stripe.confirmCardPayment(paymentIntent.data.client_secret, {
                        payment_method: paymentMethod.id,
                        receipt_email: paymentIntent.receipt_email,
                        intent: paymentIntent.id
                    }).then(async result => {

                        if (result.error) {

                            this.formProcess = false;
                            this.setState({formProcess: false});
                            throw result.error.message;

                        } else if (result.paymentIntent.status === 'succeeded') {

                            if (isYgbPayment) {

                                await PaymentModel.createYgbInvestment({
                                    amount,
                                    currency,
                                    platform: 'stripe',
                                    brand: paymentMethod.card.brand,
                                    last4: paymentMethod.card.last4,
                                    orderId: result.paymentIntent.id,
                                    tokens
                                });

                            } else {

                                await PaymentModel.createUserPayment({
                                    amount,
                                    currency,
                                    description,
                                    type,
                                    platform: 'stripe',
                                    brand: paymentMethod.card.brand,
                                    last4: paymentMethod.card.last4,
                                    orderId: result.paymentIntent.id,
                                    products
                                });

                            }

                            this.props.onSuccess();

                        }

                    });

                }

            } catch (error) {

                this.formProcess = false;
                this.setState({formProcess: false});
                this.props.onError(error);

            }

        }

    };

    handleCouponValueChange = couponCode => {

        const { type, currency, onSuccesDiscountCode } = this.props;

        this.setState({ couponCode });

        onSuccesDiscountCode(_.get(getStripeCouponPrices(), [type, couponCode, currency], 0));

    };

    isValidCoupon = couponCode => hasStripeCouponPromotion(this.props.type, couponCode) && !_.isEmpty(couponCode) && _.get(getStripeCouponPrices(), [this.props.type, couponCode]);

    render() {

        const { stripe } = this.props;
        const { formProcess, couponCode } = this.state;

        const CARD_ELEMENT_OPTIONS = {
            style: {
                base: {
                    fontSmoothing: 'antialiased',
                    fontSize: '1em'
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            },
            hidePostalCode: true
        };

        return (
            <Form onSubmit={ this.handleSubmit }>
                <Form.Field>
                    <label>{ I18n.t('payment.cardNumber') }</label>
                    <CardNumberElement id="card_number" options={ CARD_ELEMENT_OPTIONS }/>
                </Form.Field>
                <Form.Group>
                    <div className="cl-12">
                        <Form.Field>
                            <label>{ I18n.t('payment.expirationDate') }</label>
                            <CardExpiryElement id="card_expiry" options={ CARD_ELEMENT_OPTIONS }/>
                        </Form.Field>
                    </div>
                    <div className="cl-4">
                        <Form.Field>
                            <label>CVC</label>
                            <CardCvcElement id="card_cvc" options={ CARD_ELEMENT_OPTIONS }/>
                        </Form.Field>
                    </div>
                </Form.Group>
                <Form.Field>
                    <label>{ I18n.t('payment.coupon') }</label>
                    <Input
                        icon={ this.isValidCoupon(couponCode) ? 'check' : undefined }
                        style={{ color: this.isValidCoupon(couponCode) ? '#1fd5b9' : undefined }}
                        value={ this.state.couponCode }
                        placeholder={ I18n.t('payment.couponPlaceholder') }
                        onChange={ (event, input) => this.handleCouponValueChange(input.value) }
                        className='stripe-coupon-code'
                    />
                </Form.Field>
                { formProcess ?
                    <OButton type="button" disabled={ true } primary color="#fff" upper>{ I18n.t('payment.process') }</OButton>:
                    <OButton type="submit" disabled={ !stripe } primary color="#fff" upper>{ I18n.t('payment.makePayment') }</OButton>
                }
            </Form>
        );

    }

}

export default StripeCheckoutForm;