//libraries
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import I18n from '../../../i18n';
import { Trans } from 'react-i18next';

import './errorMessage.scss';

const ErrorMessage = (props) => {

    const [message, setMessage] = useState('');

    useEffect(()=> {

        switch(props.code) {

            case 'error-login':
                setMessage(
                    <>
                        <p>
                            <Trans i18nKey="messages.errorLogin">
                                <Link to="/auth/recovery/step/1"></Link>
                            </Trans>
                        </p>
                    </>
                );
                break;
            case 'error-recovery':
                setMessage(
                    <>
                        <p>{I18n.t('messages.errorRecovery')}</p>
                    </>
                );
                break;
            case 'register-repeat-email':
                setMessage(
                    <>
                        <p>{I18n.t('messages.errorAlreadyExistEmail')}</p>
                    </>
                )
                break;
            case 409:
                setMessage(<p>{I18n.t(`status.code.${props.code}`)}</p>)
                break;
            default:
                setMessage(<p>{I18n.t(`messages.errorWithoutCode`)}</p>)

        }

    },[props.code]);

    return (
        <React.Fragment>
            {props.active && <div className={"error-message-box" + (props.classes || '')}>
                {message}
            </div>}
        </React.Fragment>
    );

}

export default ErrorMessage;