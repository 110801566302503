// Types
const OPEN_GENERIC_MODAL = 'OPEN_GENERIC_MODAL';
const CLOSE_GENERIC_MODAL = 'CLOSE_GENERIC_MODAL';

// Actions
export const openGenericModal = (message, open = true) => { return { type: OPEN_GENERIC_MODAL, payload: { open, message} } };
export const closeGenericModal = () => { return { type: CLOSE_GENERIC_MODAL, payload: { open: false, message: {} } } };

// Reducer
const INITIAL_STATE = {
    open : false
};

function reducer (state = INITIAL_STATE, action) {

    switch (action.type) {

        case OPEN_GENERIC_MODAL:
        case CLOSE_GENERIC_MODAL:

            return { ...action.payload };

        default:

            return state;

    }

};

export default reducer; 