import React from 'react';
import * as S from './styled';

function WalletCard ({children, ...rest}) {
    return <S.Card {...rest}>{children}</S.Card>
}

WalletCard.Content = function({children, ...rest}) {
    return <S.CardContent {...rest}>{children}</S.CardContent>
}

WalletCard.Column = function({children, ...rest}) {
    return <S.CardColumn {...rest}>{children}</S.CardColumn>
}

WalletCard.WalletCardWrapper = function({children, ...rest}) {
    return <S.WrapperCard {...rest}>{children}</S.WrapperCard>
}


export default WalletCard;