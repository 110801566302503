import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const getProgress = async () => await AxiosService.get(Config.server.api.progressApi + 'current', true);
const updateAsanaValue = async payload => await AxiosService.post(Config.server.api.progressApi + `${payload.challengeId}/items/${payload.asanaId}/completed/${payload.selectedValue}`, {}, true);
const updateChallengeStatus = async payload => await AxiosService.post(Config.server.api.progressApi + `${payload.challengeId}/completed`, {}, true);

export default {
    getProgress,
    updateAsanaValue,
    updateChallengeStatus
}