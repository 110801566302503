import styled from 'styled-components/macro';
import OButton from '../../../../styled/components/button';

const LabelForm = styled.label`
    &&&&& {
        display: block;
        margin: 0 0 .28571429rem 0;
        color: var(--brand-primary);
        font-weight: 400;
        text-transform: none;
        font-family: PoppinsBold;
        font-size:14px;
    }
    
`;

const PictureContent = styled.div`
    margin-top:20px;
    display:flex;
    align-items:center;
    @media (max-width:360px) {
        flex-direction:column;
        align-items:flex-start;
        & ${OButton} {
            margin-bottom:10px;
        }
    }
`;

const Picture = styled.div`
    width:55px;
    height:55px;
    display:inline-block;
    border-radius:10px;
    overflow:hidden;
    background:#fff;
    margin-right:10px;
    flex-shrink: 0;
    img {
        width:100%;
        height:100%;
        object-fit:cover;
    }
    @media (max-width:360px) {
        margin-bottom:10px;
    }
`;


const WrapperCard = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin: 0 -5px;
`;

const CardActionable = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    padding:2rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow:hidden;
    align-items:center;
    margin: 0 5px;
    cursor:pointer;
`;

const CircleItem = styled.div`

    width:45px;
    height:45px;
    border-radius:50%;
    flex-shrink:0;
    background: ${({bg}) => bg ?  `var(--${bg})` : '#fff'};
    margin-bottom:1.5rem;
    display:flex;
    align-items:center;
    justify-content:center;

    i {
        color:#fff;
        width:auto;
        height:auto;
        margin:0;
    }
`;

const WalletLinks = styled.div`
    display:flex;
    margin:1.5rem 0;
`;

const IconLink = styled.div`

    width:25px;
    height:25px;
    border-radius:50%;
    flex-shrink:0;
    background-color:var(--brand-secondary);
    cursor:pointer;
    i {
        color:#fff;
        width:auto;
        height:auto;
        margin:0;
    }
    margin-right:1rem;
    display:flex;
    align-items:center;
    justify-content:center;
`;



export {
    LabelForm,
    PictureContent,
    Picture,
    WrapperCard,
    CardActionable,
    CircleItem,
    WalletLinks,
    IconLink
    
}