// Third party libraries
import _ from 'lodash';
import React from 'react';
import Fade from 'react-reveal/Fade';
import { connect } from 'react-redux';
import Moment from 'moment';

// Components
import CountDownSimple from '../../components/countDownSimple';

// Utils
import Utils from '../../../utils';
import { getOneYearPaymentPromotionPrice, getSubscriptionPrices, hasOneYearPaymentPromotion } from '../../../utils/checkPromotions';

// History
import history from '../../../history';

// Locales
import I18n from '../../../i18n';

// Styles
import './premiumPopup.scss';

// Assets
import Logo from '../../assets/img/yogabot_icon.svg';

const expiredTime = 1000 * 60 * 60 * 12;

class PremiumPopup extends React.Component {

    constructor(props) {

        super(props);

        this.exludeRoutes = ['auth', 'profile', 'cv1', 'faq', 'landing', 'legal', 'guide', 'payment'];
        this.listen = null;
        this.state = {
            isOpen: false,
            route: ''
        };

    }

    componentDidMount() {

        if (!this.getExpiredTime() && !this.state.isOpen) {

            this.setState({ isOpen: true });

        }

        this.setState({ route: history.location.pathname });

        this.listen = history.listen((location) => {

            this.setState({ route: location.pathname });

        });

    }

    componentDidUpdate() {

        if (!this.getExpiredTime() && !this.state.isOpen) {

            this.setState({ isOpen: true });

        }

    }

    componentWillUnmount() {

        this.listen();

    }

    setExpiredTime = ttl => {

        const now = new Date();
        const item = {
            expiry: now.getTime() + ttl
        }

        window.localStorage.setItem('xpt', JSON.stringify(item));

    }

    getExpiredTime = () => {

        const itemStr = window.localStorage.getItem('xpt');

        if (!itemStr) {

            return null;

        }

        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiry) {

            window.localStorage.removeItem('xpt');
            return null;

        }

        return true;

    }

    onAccept = () => {

        this.setExpiredTime(expiredTime);
        this.setState({ isOpen: false });
        hasOneYearPaymentPromotion() ? history.push('/profile/promotion') : history.push('/profile/plans');

    }

    onDismiss = () => {

        this.setExpiredTime(expiredTime);
        this.setState({ isOpen: false });

    }

    render() {

        const { isOpen, route } = this.state;
        const { role, auth, currency } = this.props;
        const roles = _.get(role || {}, 'role', []);
        const allowdRoute = _.findIndex(this.exludeRoutes, partialRoute => route.includes(partialRoute)) > -1 || route === '/';
        const canShowPopup = () => {

            const remainingDays = 16 - Moment().diff(Moment(auth.userSession.createdAt), 'days');

            if (!_.isEmpty(roles) && !role.hasActiveSubscription && (!roles.includes('premium') || (role.premiumExpirationDate !== 'NOT_EXPIRE' && Moment(role.premiumExpirationDate).startOf('day').diff(Moment().startOf('day'), 'days') < 30)) && remainingDays < 0) {

                return true;

            }

            return false;

        }

        return (
            <React.Fragment>
                <Fade left when={isOpen && canShowPopup() && !allowdRoute }>
                    {!hasOneYearPaymentPromotion() && <div className={"pop-section " + (isOpen && canShowPopup() && !allowdRoute ? '' : 'no-pointer')}>
                        <div className="pop-section__header">
                            <div className="pop-section__image">
                                <img src={Logo} alt="" />
                            </div>
                            <div className="pop-section__title">
                                <span>{I18n.t('modals.premium.title')}</span>
                            </div>
                            <span className="pop-section__close" onClick={this.onDismiss}><i className="f-icon-cancel"></i></span>
                        </div>
                        <div className="pop-section__body">
                            <div className="pop-section__description">
                                <span dangerouslySetInnerHTML={{__html: I18n.t('modals.premium.description', {amount: Utils.getAmountLiteral(_.round(getSubscriptionPrices().annualPayment[currency] / 12, 2), currency)})}}></span>
                            </div>
                            <div className="pop-section__description--grey">
                                <span dangerouslySetInnerHTML={{__html: I18n.t('modals.premium.descriptionExtra') }}></span>
                            </div>
                        </div>
                        <div className="pop-section__footer">
                            <span onClick={this.onAccept}>{I18n.t('modals.premium.footerText')}</span>
                        </div>
                    </div>}
                </Fade>
                <Fade bottom left when={isOpen && canShowPopup() && !allowdRoute}>
                    {hasOneYearPaymentPromotion() && <div className={"pop-section-promotion " + (isOpen && canShowPopup() && !allowdRoute ? '' : 'no-pointer')}>
                        <div className="pop-section-promotion__header">
                            <div className="pop-section-promotion__title">
                                <span>{I18n.t('modals.premium.hasPromotion.title')}</span>
                            </div>
                            <span className="pop-section-promotion__close" onClick={this.onDismiss}><i className="f-icon-cancel"></i></span>
                        </div>
                        <div className="pop-section-promotion__body">
                            <div className="pop-section-promotion__description">
                                <span dangerouslySetInnerHTML={{__html: I18n.t('modals.premium.hasPromotion.description', {amount: Utils.getAmountLiteral(_.round(getOneYearPaymentPromotionPrice()[currency] / 12, 2), currency)})}}></span>
                            </div>
                        </div>
                        <div className="pop-section-promotion__description pop-section-promotion__description--grey">
                            <p>{I18n.t('modals.premium.hasPromotion.descriptionExtra')}</p>
                        </div>
                        <div className="pop-section-promotion__counter">
                            <CountDownSimple endDate="2020-08-01T00:00:00+00:00" />
                        </div>
                        <div className="pop-section-promotion__footer">
                            <span onClick={this.onAccept}>{I18n.t('modals.premium.hasPromotion.footerText')}</span>
                        </div>
                    </div>}
                </Fade>
            </React.Fragment>
        );

    }

};

const mapStateToProps = state =>  {

    return {
        auth: state.auth,
        role: _.get(state,'role',{}),
        currency: _.get(state, 'auth.userSession.config.currency', {})
    };

};

export default connect(mapStateToProps, { })(PremiumPopup);