import React from 'react'
import I18n from '../../../../../i18n';
import CircleProgress from '../circleProgress';
import { Indicator, IndicatorCircle, IndicatorContent, IndicatorText, Title, Subtitle, InfoIcon } from './styles';

function Indicators({bioMetricMatrixData, slide = false, radius = 45, ...restProps }) {

    const messages = {
        first : {
            'firstQuarter' : I18n.t('home.stats.messages.first.firstQuarter'),
            'firstQuarterPopup': I18n.t('home.stats.messages.first.firstQuarterPopup'),
            'secondQuarter' : I18n.t('home.stats.messages.first.secondQuarter'),
            'secondQuarterPopup': I18n.t('home.stats.messages.first.secondQuarterPopup'),
            'thirdQuarter' : I18n.t('home.stats.messages.first.thirdQuarter'),
            'thirdQuarterPopup': I18n.t('home.stats.messages.first.thirdQuarterPopup'),
            'fourthQuarter' : I18n.t('home.stats.messages.first.fourthQuarter'),
            'fourthQuarterPopup': I18n.t('home.stats.messages.first.fourthQuarterPopup'),
        },
        second : {
            'firstQuarter' : I18n.t('home.stats.messages.second.firstQuarter'),
            'firstQuarterPopup': I18n.t('home.stats.messages.second.firstQuarterPopup'),
            'secondQuarter' : I18n.t('home.stats.messages.second.secondQuarter'),
            'secondQuarterPopup': I18n.t('home.stats.messages.second.secondQuarterPopup'),
            'thirdQuarter' : I18n.t('home.stats.messages.second.thirdQuarter'),
            'thirdQuarterPopup': I18n.t('home.stats.messages.second.thirdQuarterPopup'),
            'fourthQuarter' : I18n.t('home.stats.messages.second.fourthQuarter'),
            'fourthQuarterPopup': I18n.t('home.stats.messages.second.fourthQuarterPopup'),
        },
        third : {
            'firstQuarter' : I18n.t('home.stats.messages.third.firstQuarter'),
            'firstQuarterPopup': I18n.t('home.stats.messages.third.firstQuarterPopup'),
            'secondQuarter' : I18n.t('home.stats.messages.third.secondQuarter'),
            'secondQuarterPopup': I18n.t('home.stats.messages.third.secondQuarterPopup'),
            'thirdQuarter' : I18n.t('home.stats.messages.third.thirdQuarter'),
            'thirdQuarterPopup': I18n.t('home.stats.messages.third.thirdQuarterPopup'),
            'fourthQuarter' : I18n.t('home.stats.messages.third.fourthQuarter'),
            'fourthQuarterPopup': I18n.t('home.stats.messages.third.fourthQuarterPopup'),
        },
        fourth : {
            'firstQuarter' : I18n.t('home.stats.messages.fourth.firstQuarter'),
            'firstQuarterPopup': I18n.t('home.stats.messages.fourth.firstQuarterPopup'),
            'secondQuarter' : I18n.t('home.stats.messages.fourth.secondQuarter'),
            'secondQuarterPopup': I18n.t('home.stats.messages.fourth.secondQuarterPopup'),
            'thirdQuarter' : I18n.t('home.stats.messages.fourth.thirdQuarter'),
            'thirdQuarterPopup': I18n.t('home.stats.messages.fourth.thirdQuarterPopup'),
            'fourthQuarter' : I18n.t('home.stats.messages.fourth.fourthQuarter'),
            'fourthQuarterPopup': I18n.t('home.stats.messages.fourth.fourthQuarterPopup'),
        }
    }

    const getMessageStats = (position, percent) => {

        switch(position) {

            case 'first':

                switch(true) {

                    case percent < 50:
                        return {
                            message: messages[position].firstQuarter,
                            popup: messages[position].firstQuarterPopup
                        }
                    case percent >= 50 && percent < 75:
                        return {
                            message: messages[position].secondQuarter,
                            popup: messages[position].secondQuarterPopup
                        }
                    case percent >= 75 && percent < 90:
                        return {
                            message: messages[position].thirdQuarter,
                            popup: messages[position].thirdQuarterPopup
                        }
                    case percent >= 90:
                        return {
                            message: messages[position].fourthQuarter,
                            popup: messages[position].fourthQuarterPopup
                        }
                    default:
                        return ''
                }

            case 'second':

                switch(true) {

                    case percent < 50:
                        return {
                            message: messages[position].firstQuarter,
                            popup: messages[position].firstQuarterPopup
                        }
                    case percent >= 50 && percent < 75:
                        return {
                            message: messages[position].secondQuarter,
                            popup: messages[position].secondQuarterPopup
                        }
                    case percent >= 75 && percent < 90:
                        return {
                            message: messages[position].thirdQuarter,
                            popup: messages[position].thirdQuarterPopup
                        }
                    case percent >= 90:
                        return {
                            message: messages[position].fourthQuarter,
                            popup: messages[position].fourthQuarterPopup
                        }
                    default:
                        return ''
                }
            case 'third':

                switch(true) {

                    case percent < 50:
                        return {
                            message: messages[position].firstQuarter,
                            popup: messages[position].firstQuarterPopup
                        }
                    case percent >= 50 && percent < 75:
                        return {
                            message: messages[position].secondQuarter,
                            popup: messages[position].secondQuarterPopup
                        }
                    case percent >= 75 && percent < 90:
                        return {
                            message: messages[position].thirdQuarter,
                            popup: messages[position].thirdQuarterPopup
                        }
                    case percent >= 90:
                        return {
                            message: messages[position].fourthQuarter,
                            popup: messages[position].fourthQuarterPopup
                        }
                    default:
                        return ''
                }
            case 'fourth':

                switch(true) {

                    case percent < 50:
                        return {
                            message: messages[position].firstQuarter,
                            popup: messages[position].firstQuarterPopup
                        }
                    case percent >= 50 && percent < 75:
                        return {
                            message: messages[position].secondQuarter,
                            popup: messages[position].secondQuarterPopup
                        }
                    case percent >= 75 && percent < 90:
                        return {
                            message: messages[position].thirdQuarter,
                            popup: messages[position].thirdQuarterPopup
                        }
                    case percent >= 90:
                        return {
                            message: messages[position].fourthQuarter,
                            popup: messages[position].fourthQuarterPopup
                        }
                    default:
                        return ''
                }
            default:
                return ''

        }

    }

    const setHomeMessagePopup = (position, percent) => {

        const { openGenericModal, closeGenericModal } = restProps;

        let message = getMessageStats(position, percent).popup;

        openGenericModal({
            type: 'simple',
            size: 'tiny',
            title:{
                text: I18n.t('messages.info'),
                classes: ['heading-2']
            },
            description:{
                text: message,
                classes: ['paragraph', 'regular']
            },
            buttons:[
                {
                    text: I18n.t('actions.understood'),
                    callback: ()=> {

                        closeGenericModal();

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true
                    }
                }

            ]
        });

    }

    return (

        <IndicatorContent>
            <Indicator>
                <IndicatorCircle>
                    <CircleProgress percent={bioMetricMatrixData.elasticityAverage || 0} radius={radius} />
                </IndicatorCircle>
                <IndicatorText>
                    <Title><p className='heading-3' >{I18n.t('home.stats.1')}</p></Title>
                    <Subtitle>
                        <span className='paragraph-to-caption regular' dangerouslySetInnerHTML={{__html: getMessageStats('first', bioMetricMatrixData.elasticityAverage || 0).message }}></span>
                        <InfoIcon className='f-icon-info-circled' onClick={()=> setHomeMessagePopup('first', bioMetricMatrixData.elasticityAverage || 0)}></InfoIcon>
                    </Subtitle>
                </IndicatorText>
            </Indicator>
            <Indicator>
                <IndicatorCircle>
                    <CircleProgress percent={bioMetricMatrixData.toneAverage || 0} radius={radius} />
                </IndicatorCircle>
                <IndicatorText>
                    <Title><p className='heading-3' >{I18n.t('home.stats.2')}</p></Title>
                    <Subtitle>
                        <span className='paragraph-to-caption regular' dangerouslySetInnerHTML={{__html: getMessageStats('second', bioMetricMatrixData.toneAverage || 0).message }}></span>
                        <InfoIcon className='f-icon-info-circled' onClick={()=> setHomeMessagePopup('second', bioMetricMatrixData.toneAverage || 0)}></InfoIcon>
                    </Subtitle>
                </IndicatorText>
            </Indicator>
            <Indicator>
                <IndicatorCircle>
                    <CircleProgress percent={bioMetricMatrixData.bodyBalanceLevel || 0} radius={radius} />
                </IndicatorCircle>
                <IndicatorText>
                    <Title><p className='heading-3' >{I18n.t('home.stats.3')}</p></Title>
                    <Subtitle>
                        <span className='paragraph-to-caption regular' dangerouslySetInnerHTML={{__html: getMessageStats('third', bioMetricMatrixData.bodyBalanceLevel || 0).message }}></span>
                        <InfoIcon className='f-icon-info-circled' onClick={()=> setHomeMessagePopup('third', bioMetricMatrixData.bodyBalanceLevel || 0)}></InfoIcon>
                    </Subtitle>
                </IndicatorText>
            </Indicator>
            <Indicator>
                <IndicatorCircle>
                    <CircleProgress percent={bioMetricMatrixData.bodyPowerLevel || 0} radius={radius} />
                </IndicatorCircle>
                <IndicatorText>
                    <Title><p className='heading-3' >{I18n.t('home.stats.4')}</p></Title>
                    <Subtitle>
                        <span className='paragraph-to-caption regular' dangerouslySetInnerHTML={{__html: getMessageStats('fourth', bioMetricMatrixData.bodyPowerLevel || 0).message }}></span>
                        <InfoIcon className='f-icon-info-circled' onClick={()=> setHomeMessagePopup('fourth', bioMetricMatrixData.bodyPowerLevel || 0)}></InfoIcon>
                    </Subtitle>
                </IndicatorText>
            </Indicator>
        </IndicatorContent>
    )
}

export default Indicators;