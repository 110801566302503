import styled from "styled-components/macro";

const Frame = styled.div`
    padding:32px;
    @media (max-width: 420px) {
        padding:20px;
    }
`;

const FrameBackGround = styled.div`
    padding:32px;
    @media (max-width: 1472px) {
        padding:32px 0;
    }
    @media (max-width: 420px) {
        padding:0;
    }
`;

export {
    Frame,
    FrameBackGround
}