// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import StepsNav from '../../components/stepsNav';
import ChallengeHeader from '../../components/header';

// Styled components
import OButton from '../../../../styled/components/button';

// Redux
import { openGenericModal, closeGenericModal } from '../../../../../redux-store/genericModal';
import { getCustomSessionStats } from '../../../../../redux-store/customSessionStats';
import { getRole } from '../../../../../redux-store/role';

// Models
import SecurityModel from '../../../../../data/models/security/security';

// Utils
import { TrackingService } from '../../../../../utils/TrackingService';
import { toHHMMSS } from '../../../../../utils/formatTime';

// Config
import Config from '../../../../../config';

// Locales
import I18n from '../../../../../i18n';

// Styles
import { Container, InnerContainer, Frame, ContentBox } from './styles';

// Assets
import CorrectImage from '../../../../assets/img/correct.svg';

class ResultGrowPractice extends Component {

    async componentDidMount() {

        TrackingService.registerEvent('Practice', 'practiceSequenceEnd');

        try {

            await this.props.getCustomSessionStats();

        } catch (error) {

        }

    }

    componentWillUnmount(){

        this.props.closeGenericModal();

    }

    openPopup = () => {

        const { openGenericModal, closeGenericModal, sessionTime, bioMetricMatrix, customSessionStats } = this.props;

        const message = I18n.t('home.stats.messages.five.messagePopup', {
            totalPracticeWeekTime: toHHMMSS(sessionTime || 0),
            avgProgress: (bioMetricMatrix.progress || 0) + '%',
            weekPracticeCount: _.round(customSessionStats.lastWeekUserAlternativeSessionsCount / 2) + customSessionStats.lastWeekUserCustomSessionsCount
        });

        openGenericModal({
            type: 'simple',
            size: "tiny",
            title:{
                text: I18n.t('messages.info'),
                classes: ['heading-2']
            },
            description:{
                text: message,
                classes: ['labeling', 'regular']
            },
            buttons:[
                {
                    text: I18n.t('actions.understood'),
                    callback: ()=> {

                        closeGenericModal();

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true
                    }
                }

            ]
        });

    }

    completeGrowChallenges = async () => {

        try {

            await SecurityModel.updateGrowChallengeStatus('finished');
            await this.props.getRole();

        } catch (error) {

        } finally {

            this.showCompleteChallengesModal();

        }

    }

    showCompleteChallengesModal = () => {

        const { openGenericModal, closeGenericModal, history } = this.props;

        openGenericModal({
            type: 'challengeCompleted',
            buttons:[
                {
                    text: I18n.t('modals.challengeCompleteModal.buttonText2'),
                    callback: ()=> {

                        closeGenericModal();
                        history.push('/home');

                    },
                    options: {
                        selected: true,
                        color: '#1bb89f',
                        fluid: true,
                        upper: true,
                    }
                }
            ]
        });

    }

    onContinue = () => {

        const { role, userSession, bioMetricMatrix, history, customSessionStats} = this.props;
        const { completeGrowChallenges } = this;

        if (_.get(role, 'attributes.growChallenge', '') === 'grow') {

            if (customSessionStats.userCustomSessionsCount >= Config.growChallenge.customPractices) {

                const completedGrowChallenges = [];
                //paso 3 terminado por defecto
                completedGrowChallenges[2] = 3;

                //paso 1 completado?
                (userSession.name && userSession.surname) ? completedGrowChallenges[0] = 1 : completedGrowChallenges[1] = 0;
                //paso 2 completado?
                (bioMetricMatrix.progress || 0) >= 100 ? completedGrowChallenges[1] = 2 : completedGrowChallenges[1] = 0;

                const nextStepToCompleteIndex = completedGrowChallenges.indexOf(0);

                if (nextStepToCompleteIndex > -1) {

                    history.push(`/mode/grow/step/${ nextStepToCompleteIndex + 1 }`);

                } else {

                    //los retos están completados marcamos como finalizados
                    completeGrowChallenges();

                }

            } else {

                history.push(`/mode/grow/step/3`);

            }

        }

    }

    render() {



        return (
            <Container>
                <InnerContainer>
                    <ChallengeHeader {...this.props} />
                    <StepsNav activedStep={3} {...this.props} />
                    <Frame>
                    <ContentBox>
                        <img src={CorrectImage} alt="" />
                        <p className="paragraph regular">{I18n.t('practice.result.title')} <i onClick={this.openPopup} className="f-icon-info-circled"></i></p>
                        <p className="paragraph regular">{I18n.t('practice.result.subTitle')}</p>
                        <OButton type="button" upper color="#fff" onClick={ this.onContinue } primary ><span>{I18n.t('actions.continue')}</span></OButton>
                    </ContentBox>
                    </Frame>
                </InnerContainer>
            </Container>
        )
    }
}


const mapStateToProps = state => {

    return {
        role: _.cloneDeep(_.get(state,'role', {})),
        userSession: _.get(state,'auth.userSession', {}),
        sessionTime: _.get(state,'session.time', 0),
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        customSessionStats: _.get(state, 'customSessionStats', {})
    };
};

export default connect(mapStateToProps, { openGenericModal, closeGenericModal, getRole, getCustomSessionStats })(ResultGrowPractice);