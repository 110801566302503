import styled from 'styled-components/macro';
import { LegacyPoints } from '../../../styled';

const InstructionsWrapper = styled.div`

    margin: 0 auto;
    max-width:67rem;
    width:100%;
    ${LegacyPoints} {
        max-width:57rem;
        margin: 0 auto;
    }
    @media (min-width: 800px) {
        .title {
            text-align:center;
        }

    }
`;

export{
    InstructionsWrapper
}