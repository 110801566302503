import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import challenges from './challenges';
import loader from './loader';
import registerEmail from './registerEmail';
import bioMetricMatrix from './bioMetricMatrix';
import auth from './auth';
import session from './session';
import role from './role';
import alternativePractices from './alternativePractices';
import welcomeModal from './welcomeModal';
import expiredModal from './expiredModal';
import cart from './cart';
import genericModal from './genericModal';
import customSessionStats from './customSessionStats';
import windowMeassures from './windowMeassures';
import walletTransactions from './walletTransactions';

export default combineReducers({
    form,
    alternativePractices,
    auth,
    cart,
    challenges,
    expiredModal,
    loader,
    role,
    registerEmail,
    bioMetricMatrix,
    session,
    welcomeModal,
    genericModal,
    customSessionStats,
    windowMeassures,
    walletTransactions
});