import SessionModel from '../data/models/session/session';

//actions
const GET_LAST_WEEK_TRAINING_SECONDS = 'GET_LAST_WEEK_TRAINING_SECONDS';

const INITIAL_STATE = {
    time: 0
};

export const getLastWeekTrainingSeconds = () => async dispatch => {

    try {

        const response = await SessionModel.getLastWeekTrainingSeconds();

        if (response.status === 200) {

            dispatch ({ type: GET_LAST_WEEK_TRAINING_SECONDS, payload: response.data });

        }


    } catch (errorCode) {

        dispatch ({ type: GET_LAST_WEEK_TRAINING_SECONDS, payload: INITIAL_STATE });
        throw errorCode;

    }

};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case GET_LAST_WEEK_TRAINING_SECONDS:

            return { ...state, time: action.payload };

        default:

            return state;

    }

};

export default reducer;