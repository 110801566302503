import styled from 'styled-components/macro';

const Steps = styled.div`
    margin-top:20px;
`;

const Step = styled.div`
    border-bottom:solid 1px lightgray;
    padding:0 10px 10px 0;
    margin-top:10px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
`;

const Actions = styled.div`
    margin-top:30px;
`;

const Check = styled.div`
    i {
        &:before {
            color:var(--brand-secondary);
        }
    }
`;

const Text = styled.div`
    margin-left:15px;
`;

export {
    Actions,
    Steps,
    Step,
    Check,
    Text
};