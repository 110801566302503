import React from 'react';
import Slider from "react-slick";
import _ from 'lodash';
import $ from 'jquery';

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	dotsClass: "button__bar",
  };

class Matriz extends React.Component {

    componentDidMount() {

		if (!_.isEmpty(this.props.matrix.biometricMatrix)) {

			this.printMatrix(this.props.matrix.biometricMatrix)

		}
		
	}

	componentDidUpdate() {

		if (!_.isEmpty(this.props.matrix.biometricMatrix)) {

			this.printMatrix(this.props.matrix.biometricMatrix);

		}

	}

	printMatrix = matrix => {

		var keysAnterior = Object.keys(matrix.front);
		var keysPosterior = Object.keys(matrix.rear);

		for (var keyA of keysAnterior) {
		
			$('[id="front_' + keyA + '"] path').css({'fill': matrix.front[keyA]});

		}

		for (var keyP of keysPosterior) {
			
			$('[id="rear_' + keyP + '"] path').css({'fill': matrix.rear[keyP]});

		}

	}
	
	renderFemaleMatriz = () => {

		return (
			<React.Fragment>
				<div className="shape not-slider female-front">
					<svg version="1.1" id="front_layer_female" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
						viewBox="0 0 332.9 906.1" enableBackground="new 0 0 332.9 906.1">
							<text textDecoration="underline" fontFamily="PoppinsBold" x="25%" y="120" fill="black" fontSize="15px">Derecha</text>
								<text textDecoration="underline" fontFamily="PoppinsBold" x="77%" y="120" fill="black" fontSize="15px">Izquierda</text>
					<g id="cuerpo_x2F_sombras_x2F_pelo_x2F_pantalon">
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M187.8,120.9
							v13.6c0,0,0.6,13.8-12.2,19l-45.2,18.4c0,0-32.3-1.4-32.3,43.1c0,44.5-14.7,87.4-17.3,122.4c-0.6,7.8-7.4,14.3-12.3,25.1
							c-12.7,27.5-20.6,73.3-28.1,80.4C33.9,449,19.4,456.1,4.7,468c-1.1,0.9-2.7,1.3-3.4,1.7c-2.3,1.2,0.5,6.8,5.4,5.2s9.3-6.7,12.4-6
							c1,0.3,2,0.5,3,0.8c-0.6,1.3-1.7,7.3-3.1,14.8c-1.6,8.4-3,16.8-5.7,25.1c-2.5,7.8,4.5,8.2,5.8,6.2c1.6-2.5,8.1-30,8.1-30
							s-6.7,24.9-7.7,31.2c-1,6.3,5.5,6.9,6.8,2.8c2.1-6.5,8.2-31.9,8.2-31.9s-4.8,22.2-6.6,29.4c-1.6,6.5,5.7,6.9,6.7,2.5
							c1.3-5.6,8.4-31,8.4-31s-4.8,16.6-6.4,24.1c-0.6,2.7,5,4.4,6.6,0.8c0.8-1.8,10.3-29.2,11.2-35.5c1.1-7.2,3.7-14.9,6.6-21.7
							c3.7-8.8,7.8-16,9.3-19c13.1-26.2,29.2-46.4,37.9-73c4.7-14.4,5.4-16.3,12.5-38.2c4.4-13.6,12.6-41.2,11.8-61.8l6.7,14
							c15.4,26.8,2.5,74.8,0.9,79c-4.8,12.5-12.9,38.6-17.8,82.6c-7.3,65.8,15.9,145.7,7.4,208.4s-12,72.2-9.6,110.8
							c2.3,36.8-1.5,91.6-1.8,96.1c0,0-3.5,13.1-2.3,18.8c1.2,5.7-6.9,16.1-13.6,21.9c-6.7,5.7-0.9,7.3,1.9,7.9
							c2.2,0.5,34.5,6.5,37.4-6.5c0,0,0.8-2.2,1.2-15.7c0.3-7.8,2.6-8.1,1.1-14.8c0,0-2.2-8.2-2.2-11.2c0,0,14.1-83.1,21.6-108.7
							c4.8-16.6,7.1-23.7,7.7-76.7c0.3-20.8,8.3-19.7,8.3-54.9c0-23.3,21.6-88.5,21.6-141.2c0,0-0.4-7.6,6.8-7.6h0c7.1,0,6.8,7.6,6.8,7.6
							c0,52.8,21.6,115.8,21.6,141.2c0,35.2,8,34.1,8.3,54.9c0.7,53,2.9,60.1,7.7,76.7c7.2,24.6,20.5,102.9,21.5,108.4
							c0.2,2.6-2.2,11.5-2.2,11.5c-1.5,6.7,0.8,7,1.1,14.8c0.5,13.5,1.2,15.7,1.2,15.7c2.9,13,35.2,7,37.4,6.5c2.8-0.6,8.6-2.2,1.8-7.9
							S298,879.7,299.2,874c1.1-5.3-1.8-17-2.2-18.6c-0.3-3.2-4.2-59-1.8-96.3c2.4-38.6-1.1-48.2-9.6-110.8
							c-8.5-62.6,14.7-142.5,7.4-208.4c-4.9-44-13-70.2-17.8-82.6c-2.4-6.2-14-53.1,0.9-79l6.6-12.9c-0.7,20.6,6,48.9,7,63.2
							c1.8,25,5.4,37.7,9,52.4c6.4,25.8,12.3,70.3,14.4,78.9c2.1,8.5-7.7,30.9-5.9,37.3c1.8,6.4,1.4,25.8,1.6,31.2
							c0.3,5.3,6.4,2.7,6.4,2.7c0.3,3.5-8.5,17.3-8,20.5c0.5,3.2,4.5,3.7,5.6,0c1.1-3.7,13.6-22.1,15.5-25.8c1.9-3.7,1.3-10.9,3.5-16.8
							c2.1-5.9-0.5-44.8-0.5-44.8s-1.7-12.1-2-29.3c-0.3-19.6-1-45.8-2.1-67.9c-0.6-11.8,0.4-22.4-0.2-30.2c-2.5-35-9.9-77.4-9.9-121.9
							c0-44.5-32.3-43.1-32.3-43.1l-45.2-18.4c-12.8-5.2-12.2-19-12.2-19v-13.6h0"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M207.5,18.8
							c-2.7-0.1-28.5-0.5-35.4,26.4c-1.8,7.1-2.3,16.1-0.7,27.5c7.9,56.5,31,60.7,36.1,60.7c5.1-0.1,28.2-4.2,36.1-60.7
							C251.2,18.1,210.8,18.7,207.5,18.8z"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M171.8,75.5
							L171.8,75.5c0,0-21.6-0.8,7.1,28.6v0C176.1,96.6,173.6,87.2,171.8,75.5z"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M243.1,75.5
							L243.1,75.5c0,0,21.6-0.8-7.1,28.6v0C238.9,96.6,241.3,87.2,243.1,75.5z"/>
						<polygon fill="#EFD3D3" points="282.5,171.1 250.8,182.4 275.2,168.1 	"/>
						<polygon fill="#EFD3D3" points="133.2,171.4 167.5,183.1 141,167.6 	"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M317.9,501.1
							c-1,15-2.8,29.9-2.8,29.9"/>
						<g>
							<path fill="#DDC7BF" d="M327.9,502.7c0,0-0.2,2.3-0.5,5.8c-0.2,1.7-0.4,3.8-0.6,5.9c-0.3,2.2-0.5,4.5-1,6.8
								c-0.2,1.2-0.7,2.3-1.1,3.3c-0.4,1.1-0.8,2.1-1.1,3.2c-0.3,1-0.7,2-1,3c-0.3,0.9-0.5,1.9-0.8,2.7c-0.2,0.8-0.4,1.6-0.6,2.3
								c-0.2,0.7-0.3,1.3-0.4,1.8c-0.2,1-0.4,1.5-0.4,1.5l0,0c-0.1,0.3-0.3,0.4-0.6,0.4c-0.3-0.1-0.4-0.3-0.4-0.6c0,0,0,0,0,0
								c0,0,0.1-0.6,0.4-1.6c0.1-0.5,0.3-1.1,0.5-1.8c0.2-0.7,0.4-1.5,0.7-2.3c0.3-0.8,0.5-1.7,0.9-2.7c0.3-0.9,0.7-1.9,1-3
								c0.4-1,0.8-2.1,1.2-3.1c0.4-1.1,0.9-2.1,1.1-3.2c0.5-2.2,0.8-4.5,1.1-6.7c0.3-2.2,0.6-4.2,0.8-5.9
								C327.6,505,327.9,502.7,327.9,502.7z"/>
						</g>
						<g>
							<path fill="#DDC7BF" d="M22.2,469.2c0,0,1.1,0.4,3,0.6c0.9,0.1,2,0.2,3.2,0.2c0.6,0,1.2-0.1,1.8-0.1c0.6-0.1,1.2-0.2,1.8-0.3
								l0.9-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.6-0.2,1.1-0.5,1.6-0.7c0.5-0.2,1-0.6,1.4-0.9c0.2-0.2,0.4-0.3,0.6-0.4
								c0.2-0.2,0.4-0.3,0.6-0.5c0.4-0.3,0.7-0.6,1-0.8c0.3-0.3,0.5-0.5,0.7-0.7c0.4-0.4,0.6-0.6,0.6-0.6s-0.2,0.2-0.6,0.7
								c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2,0.3-0.6,0.6-0.9,0.9c-0.2,0.2-0.4,0.3-0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.5c-0.4,0.3-0.9,0.7-1.4,0.9
								c-0.5,0.3-1.1,0.6-1.6,0.8c-0.3,0.1-0.6,0.2-0.9,0.4l-0.9,0.3c-0.6,0.2-1.2,0.3-1.9,0.4c-0.6,0.1-1.2,0.2-1.8,0.2
								c-1.2,0.1-2.3,0.1-3.3,0c-1-0.1-1.8-0.2-2.3-0.3c-0.3-0.1-0.5-0.1-0.7-0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.3-0.1-0.4-0.3-0.4-0.6
								C21.6,469.2,21.9,469.1,22.2,469.2C22.2,469.2,22.2,469.2,22.2,469.2z"/>
						</g>
						<path fill="#DDC7BF" d="M294.2,344.7c0,0,7.6,2.3,18.5,1.9"/>
						<path fill="#DDC7BF" d="M80.6,340.9c0,0,5.2,2.1,13.8,2.1"/>
						<g>
							<path fill="#DDC7BF" d="M283,171.9L247.7,183l35-12c0.3-0.1,0.5,0,0.6,0.3S283.2,171.8,283,171.9C283,171.9,283,171.9,283,171.9z"
								/>
						</g>
						<g>
							<path fill="#DDC7BF" d="M132.6,171.1l34.9,12L132.3,172c-0.3-0.1-0.4-0.4-0.3-0.6S132.3,171,132.6,171.1
								C132.6,171.1,132.6,171.1,132.6,171.1z"/>
						</g>
						<g>
							<path fill="#E5CAC6" d="M273.9,277c-0.6,0.6-1.3,1.2-1.9,1.8l-2.1,1.6c-0.7,0.6-1.4,1-2.2,1.4c-0.8,0.4-1.5,0.9-2.3,1.2
								c-0.8,0.3-1.6,0.7-2.3,1c-0.8,0.3-1.6,0.5-2.4,0.8l-1.2,0.4l-1.2,0.3c-0.8,0.2-1.6,0.4-2.4,0.5c-1.6,0.1-3.2,0.4-4.8,0.3
								c-0.8,0-1.6,0-2.3-0.1c-0.7-0.1-1.3-0.2-2-0.2c6.7,2.1,21.5,7.5,22.5,15.4c0,0,1.5-9.8,3.4-14.8c2.7-7.2,3.8-8.8,8-18.1
								c-0.1,0.2-0.2,0.4-0.3,0.5C278.5,271.8,276.4,274.6,273.9,277z"/>
						</g>
						<g>
							<path fill="#DDC7BF" d="M189.2,273.9c-0.3,0.3-0.6,0.7-1,1.1c-0.2,0.2-0.4,0.4-0.6,0.7c-0.2,0.2-0.5,0.5-0.8,0.7
								c-0.6,0.5-1.2,1.1-1.8,1.7c-0.7,0.5-1.5,1.1-2.4,1.7c-1.7,1.1-3.8,2.3-6.2,3.2c-0.6,0.3-1.2,0.4-1.8,0.7c-0.6,0.2-1.3,0.4-1.9,0.6
								c-0.7,0.2-1.4,0.3-2.1,0.4c-0.7,0.1-1.4,0.3-2.1,0.4c-0.7,0.1-1.5,0.1-2.2,0.2c-0.7,0.1-1.5,0-2.3,0c-1.5,0.1-3.1-0.2-4.7-0.3
								c-0.8-0.1-1.6-0.3-2.4-0.5l-1.2-0.3l-1.2-0.4c-0.8-0.3-1.6-0.5-2.3-0.8c-0.8-0.3-1.5-0.7-2.3-1c-0.8-0.3-1.5-0.8-2.2-1.2
								c-0.7-0.4-1.5-0.8-2.1-1.4l-2-1.5c-0.6-0.6-1.2-1.2-1.9-1.8c-2.5-2.3-4.6-5-6.3-7.9c-1.7-2.8-3.1-5.9-4-8.9
								c-0.8-3.1-1.3-6.1-1.2-9c0-1.5,0.3-2.8,0.5-4.2c0.1-0.7,0.3-1.3,0.5-2c0.2-0.6,0.3-1.3,0.6-1.9c0.8-2.4,1.7-4.6,2.5-6.6
								c0.4-1,0.7-1.9,1-2.7c0.2-0.4,0.3-0.8,0.4-1.2c0.1-0.4,0.2-0.8,0.3-1.1c0.2-0.7,0.4-1.4,0.5-1.9c0.1-0.6,0.2-1.1,0.3-1.5
								c0.1-0.8,0.2-1.2,0.2-1.2s-0.1,0.4-0.3,1.2c-0.1,0.4-0.2,0.9-0.3,1.4c-0.1,0.6-0.4,1.2-0.6,1.9c-0.1,0.4-0.2,0.7-0.4,1.1
								c-0.2,0.4-0.3,0.8-0.5,1.2c-0.3,0.8-0.7,1.7-1.1,2.7c-0.8,1.9-1.8,4.1-2.7,6.5c-0.2,0.6-0.4,1.2-0.6,1.9c-0.2,0.7-0.4,1.3-0.6,2
								c-0.2,1.4-0.6,2.8-0.6,4.3c-0.2,3,0.2,6.2,1,9.3c0.9,3.1,2.3,6.2,4,9.2c0.4,0.7,0.8,1.3,1.3,2c1.5,2.2,3.2,4.3,5.2,6.2
								c0.6,0.6,1.2,1.2,1.9,1.8l2.1,1.6c0.7,0.6,1.5,1,2.2,1.4c0.8,0.4,1.5,0.9,2.3,1.2c0.8,0.3,1.6,0.7,2.3,1c0.8,0.3,1.6,0.5,2.4,0.8
								l1.2,0.4l1.2,0.3c0.8,0.2,1.6,0.4,2.4,0.5c1.6,0.1,3.2,0.4,4.8,0.3c0.8,0,1.6,0,2.3-0.1c0.6-0.1,1.2-0.1,1.8-0.2
								c0.1,0,0.3,0,0.4,0c0.7-0.1,1.5-0.2,2.2-0.4c0.7-0.2,1.4-0.3,2.1-0.5c0.7-0.2,1.3-0.4,2-0.7c0.6-0.2,1.3-0.4,1.9-0.7
								c2.4-1,4.5-2.2,6.2-3.5c0.8-0.6,1.6-1.2,2.3-1.8c0.7-0.6,1.3-1.2,1.8-1.7c0.3-0.3,0.5-0.5,0.8-0.7c0.2-0.3,0.4-0.5,0.6-0.7
								C188.7,274.7,189,274.3,189.2,273.9c0.5-0.6,0.8-0.9,0.8-0.9S189.7,273.3,189.2,273.9z"/>
						</g>
						<path fill="#EFD3D3" d="M203.1,467.9c0,0-7.4,1.6-21.2-5.6c0,0,15.2,29,7.4,96c0,0,11.4-56,11.4-85.2"/>
						<path fill="#EFD3D3" d="M212.5,468c0,0,5.8,0.4,20.3-5.7c0,0-15.2,29-7.4,96c0,0-11.4-56-11.4-85.2"/>
						<path fill="#DDC7BF" d="M285.4,250.4c0-0.4-0.1-0.7-0.1-1.1l0-0.3l0-0.1l0-0.1l0-0.1l-0.1-0.5c-0.1-0.7-0.3-1.4-0.4-2.1
							c-0.2-0.7-0.4-1.3-0.6-2c-0.2-0.7-0.4-1.3-0.6-1.9c-0.9-2.4-1.9-4.6-2.7-6.5c-0.4-1-0.8-1.9-1.1-2.7c-0.2-0.4-0.3-0.8-0.5-1.2
							c-0.1-0.4-0.3-0.8-0.4-1.1c-0.2-0.7-0.5-1.3-0.6-1.9c-0.1-0.6-0.2-1.1-0.3-1.4c-0.2-0.8-0.3-1.2-0.3-1.2s0.1,0.4,0.2,1.2
							c0.1,0.4,0.2,0.9,0.3,1.5c0.1,0.6,0.3,1.2,0.5,1.9c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.4,0.3,0.8,0.4,1.2c0.3,0.9,0.6,1.8,1,2.7
							c0.8,2,1.7,4.1,2.5,6.6c0.2,0.6,0.4,1.2,0.6,1.9c0.2,0.6,0.4,1.3,0.5,2c0.1,0.7,0.2,1.4,0.3,2.1l0.1,0.5l0,0.1l0,0c0,0,0,0,0,0
							l0,0.1l0,0.3c0,0.4,0,0.7,0.1,1.1c0.1,2.9-0.3,6-1.2,9c-0.9,3.1-2.2,6.1-4,8.9c-1.8,2.8-3.8,5.6-6.3,7.9c-0.6,0.6-1.2,1.2-1.8,1.7
							l-2,1.5c-0.6,0.5-1.4,0.9-2.1,1.4c-0.7,0.4-1.4,0.9-2.2,1.2c-0.8,0.3-1.5,0.7-2.3,1c-0.8,0.3-1.6,0.5-2.3,0.8l-1.2,0.4l-1.2,0.3
							c-0.8,0.2-1.6,0.4-2.4,0.5c-1.6,0.1-3.1,0.4-4.7,0.3c-0.8,0-1.5,0.1-2.3,0c-0.7-0.1-1.5-0.1-2.2-0.2c-0.7,0-1.4-0.2-2.1-0.4
							c-0.7-0.1-1.4-0.3-2.1-0.4c-0.7-0.2-1.3-0.4-1.9-0.6c-0.6-0.2-1.3-0.4-1.8-0.7c-2.4-1-4.4-2.1-6.2-3.2c-0.8-0.6-1.6-1.2-2.4-1.7
							c-0.7-0.6-1.3-1.2-1.8-1.7c-0.3-0.3-0.5-0.5-0.8-0.7c-0.2-0.2-0.4-0.5-0.6-0.7c-0.4-0.4-0.7-0.8-1-1.1c-0.5-0.6-0.8-0.9-0.8-0.9
							s0.3,0.3,0.8,1c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.2,0.4,0.5,0.6,0.7c0.2,0.2,0.5,0.5,0.8,0.7c0.5,0.5,1.1,1.1,1.8,1.7
							c0.7,0.6,1.5,1.2,2.3,1.8c1.7,1.2,3.8,2.4,6.2,3.5c0.6,0.3,1.2,0.5,1.9,0.7c0.6,0.2,1.3,0.4,2,0.7c0.7,0.2,1.4,0.3,2.1,0.5
							c0.7,0.2,1.4,0.3,2.2,0.4c0.1,0,0.2,0,0.3,0c0.7,0.1,1.3,0.2,2,0.2c0.8,0.1,1.5,0,2.3,0.1c1.6,0.1,3.2-0.2,4.8-0.3
							c0.8-0.1,1.6-0.3,2.4-0.5l1.2-0.3l1.2-0.4c0.8-0.3,1.6-0.5,2.4-0.8c0.8-0.3,1.6-0.7,2.3-1c0.8-0.3,1.5-0.8,2.3-1.2
							c0.7-0.4,1.5-0.8,2.2-1.4l2.1-1.6c0.7-0.6,1.3-1.2,1.9-1.8c2.5-2.4,4.6-5.2,6.4-8.1c0.1-0.2,0.2-0.4,0.3-0.5
							c1.6-2.8,2.9-5.7,3.7-8.6C285.2,256.5,285.6,253.4,285.4,250.4z"/>
						<path fill="#E5CAC6" d="M164,286.2c-1.6,0.1-3.2-0.2-4.8-0.3c-0.8-0.1-1.6-0.3-2.4-0.5l-1.2-0.3l-1.2-0.4c-0.8-0.3-1.6-0.5-2.4-0.8
							c-0.8-0.3-1.6-0.7-2.3-1c-0.8-0.3-1.5-0.8-2.3-1.2c-0.7-0.4-1.5-0.8-2.2-1.4l-2.1-1.6c-0.7-0.6-1.3-1.2-1.9-1.8
							c-2-1.9-3.7-4-5.2-6.2c-1.2-1.6-1.8-2.8-1.8-2.8c4.3,9.5,5.4,11,8.1,18.4c1.9,5,3.4,14.8,3.4,14.8c0.9-7.8,15.7-13.3,22.4-15.3
							c-0.6,0.1-1.2,0.1-1.8,0.2C165.6,286.2,164.8,286.1,164,286.2z"/>
						<path fill="#EFD3D3" d="M187.5,140.5c7.9,2.1,24.8,5.4,39.4-9.2v-9.1c-8.4,10.6-16.8,11.6-19.4,11.7c0,0,0,0,0,0s0,0,0,0
							c-2.6,0-10.8-1-19.1-11.4v11.9C188.3,134.5,188.4,137.2,187.5,140.5z"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M187.8,120.9v13.6
							c0,0,0.6,13.8-12.2,19l-45.2,18.4c0,0-32.3-1.4-32.3,43.1c0,44.5-14.7,87.4-17.3,122.4c-0.6,7.8-7.4,14.3-12.3,25.1
							c-12.7,27.5-20.6,73.3-28.1,80.4C33.9,449,19.4,456.1,4.7,468c-1.1,0.9-2.7,1.3-3.4,1.7c-2.3,1.2,0.5,6.8,5.4,5.2s9.3-6.7,12.4-6
							c1,0.3,2,0.5,3,0.8c-0.6,1.3-1.7,7.3-3.1,14.8c-1.6,8.4-3,16.8-5.7,25.1c-2.5,7.8,4.5,8.2,5.8,6.2c1.6-2.5,8.1-30,8.1-30
							s-6.7,24.9-7.7,31.2c-1,6.3,5.5,6.9,6.8,2.8c2.1-6.5,8.2-31.9,8.2-31.9s-4.8,22.2-6.6,29.4c-1.6,6.5,5.7,6.9,6.7,2.5
							c1.3-5.6,8.4-31,8.4-31s-4.8,16.6-6.4,24.1c-0.6,2.7,5,4.4,6.6,0.8c0.8-1.8,10.3-29.2,11.2-35.5c1.1-7.2,3.7-14.9,6.6-21.7
							c3.7-8.8,7.8-16,9.3-19c13.1-26.2,29.2-46.4,37.9-73c4.7-14.4,5.4-16.3,12.5-38.2c4.4-13.6,12.6-41.2,11.8-61.8l6.7,14
							c15.4,26.8,2.5,74.8,0.9,79c-4.8,12.5-12.9,38.6-17.8,82.6c-7.3,65.8,15.9,145.7,7.4,208.4s-12,72.2-9.6,110.8
							c2.3,36.8-1.5,91.6-1.8,96.1c0,0-3.5,13.1-2.3,18.8c1.2,5.7-6.9,16.1-13.6,21.9c-6.7,5.7-0.9,7.3,1.9,7.9
							c2.2,0.5,34.5,6.5,37.4-6.5c0,0,0.8-2.2,1.2-15.7c0.3-7.8,2.6-8.1,1.1-14.8c0,0-2.2-8.2-2.2-11.2c0,0,14.1-83.1,21.6-108.7
							c4.8-16.6,7.1-23.7,7.7-76.7c0.3-20.8,8.3-19.7,8.3-54.9c0-23.3,21.6-88.5,21.6-141.2c0,0-0.4-7.6,6.8-7.6h0c7.1,0,6.8,7.6,6.8,7.6
							c0,52.8,21.6,115.8,21.6,141.2c0,35.2,8,34.1,8.3,54.9c0.7,53,2.9,60.1,7.7,76.7c7.2,24.6,20.5,102.9,21.5,108.4
							c0.2,2.6-2.2,11.5-2.2,11.5c-1.5,6.7,0.8,7,1.1,14.8c0.5,13.5,1.2,15.7,1.2,15.7c2.9,13,35.2,7,37.4,6.5c2.8-0.6,8.6-2.2,1.8-7.9
							S298,879.7,299.2,874c1.1-5.3-1.8-17-2.2-18.6c-0.3-3.2-4.2-59-1.8-96.3c2.4-38.6-1.1-48.2-9.6-110.8
							c-8.5-62.6,14.7-142.5,7.4-208.4c-4.9-44-13-70.2-17.8-82.6c-2.4-6.2-14-53.1,0.9-79l6.6-12.9c-0.7,20.6,6,48.9,7,63.2
							c1.8,25,5.4,37.7,9,52.4c6.4,25.8,12.3,70.3,14.4,78.9c2.1,8.5-7.7,30.9-5.9,37.3c1.8,6.4,1.4,25.8,1.6,31.2
							c0.3,5.3,6.4,2.7,6.4,2.7c0.3,3.5-8.5,17.3-8,20.5c0.5,3.2,4.5,3.7,5.6,0c1.1-3.7,13.6-22.1,15.5-25.8c1.9-3.7,1.3-10.9,3.5-16.8
							c2.1-5.9-0.5-44.8-0.5-44.8s-1.7-12.1-2-29.3c-0.3-19.6-1-45.8-2.1-67.9c-0.6-11.8,0.4-22.4-0.2-30.2c-2.5-35-9.9-77.4-9.9-121.9
							c0-44.5-32.3-43.1-32.3-43.1l-45.2-18.4c-12.8-5.2-12.2-19-12.2-19v-13.6h0"/>
						<path fill="#EFD3D3" d="M287,306.7c0.1,0.5,0.2,1,0.2,1.6c1.3,8.1,2.4,15.2,2.8,20.4c0.7,9.8,1.7,17.7,2.8,24.5
							c0.6,3.1,1.1,6.1,1.7,8.9c1.1,5.3,2.3,10.1,3.6,15.1c0.3,1.3,0.6,2.5,1,3.8c1.8,7.1,3.5,15.7,5.1,24.5c-1.8-18.2-2.9-44.1-2.7-52.7
							c0.2-9.4-13.7-55.3-17.6-68c0,0.1,0,0.2,0,0.3c0.2,1.4,0.3,2.8,0.5,4.2c0.5,3.8,1,7.6,1.6,11.2C286.4,302.6,286.8,304.7,287,306.7z
							"/>
						<path fill="#EFD3D3" d="M85.1,409.8c8.8-14.9,17.2-29,22.6-45.7l3.1-9.5c2.4-7.3,4.1-12.6,9.3-28.6c7.8-24.1,11.9-44.4,11.9-59.1
							c-2,13.4-9.2,53.6-26.7,89.5c-19.4,39.6-32.9,73.7-36,81.7c0.2-0.3,0.3-0.7,0.5-0.9C74.6,427.4,79.9,418.5,85.1,409.8z"/>
						<path fill="none" d="M188.7,182.4c-0.5-0.1-1-0.1-1.6-0.2C187.7,182.3,188.2,182.4,188.7,182.4z"/>
						
							
						<path opacity="0.9" fill="url(#SVGID_1_)" enableBackground="new    " d="M189.2,182.5c-0.1,0-0.3,0-0.4-0.1
							C188.9,182.5,189,182.5,189.2,182.5z"/>
						
							
						<path opacity="0.9" fill="url(#SVGID_2_)" enableBackground="new    " d="M189.2,182.5c-0.1,0-0.3,0-0.4-0.1
							C188.9,182.5,189,182.5,189.2,182.5z"/>
						<g>
							<path fill="#39414C" d="M233,23.9c0,2.9-50.9,7.1-50.9,2.7c0-3.3,0.4-9.7,1.6-12.5C187.2,5.4,197.7,0,207.8,0
								c9.6,0,17.4,3.5,21.3,11.5C230.6,14.7,233,20.2,233,23.9z"/>
							<g>
								<path fill="none" d="M190.2,5.9"/>
							</g>
							<g>
								<path fill="#4E5663" d="M197,2.5c0,0,0.8,0.3,2.1,0.9c1.3,0.6,3.2,1.5,5.4,2.7c1.1,0.6,2.3,1.3,3.5,2c1.2,0.7,2.5,1.6,3.8,2.5
									c1.3,0.9,2.6,1.9,3.9,2.9c1.3,1,2.6,2.1,3.9,3.2c1.2,1.1,2.4,2.4,3.5,3.6c0.6,0.6,1.1,1.3,1.6,1.9c0.5,0.7,1,1.3,1.4,2
									c0.9,1.3,1.6,2.7,2.2,4c0.1,0.3,0.3,0.7,0.4,1c0.1,0.3,0.2,0.7,0.3,1c0.1,0.3,0.2,0.6,0.3,1c0.1,0.3,0.1,0.6,0.2,1
									c0.2,0.6,0.2,1.2,0.3,1.8c0,0.3,0,0.6,0.1,0.8c0,0.3,0,0.5,0,0.8c0,1-0.2,1.9-0.3,2.6c-0.1,0.7-0.4,1.3-0.5,1.6
									c-0.1,0.4-0.2,0.6-0.2,0.6s0-0.2,0.1-0.6c0.1-0.4,0.2-0.9,0.3-1.7c0.1-0.7,0.2-1.6,0.1-2.6c0-0.3,0-0.5,0-0.8
									c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.6-0.1-1.1-0.3-1.7c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.2-0.6-0.3-1
									c-0.1-0.3-0.3-0.6-0.4-0.9c-0.6-1.3-1.4-2.6-2.2-3.8c-0.4-0.6-0.9-1.3-1.4-1.9c-0.5-0.6-1-1.2-1.6-1.8c-1.1-1.2-2.3-2.4-3.5-3.5
									c-1.2-1.1-2.5-2.2-3.8-3.2c-1.3-1-2.6-2-3.9-2.9c-1.3-0.9-2.5-1.8-3.7-2.5c-1.2-0.8-2.4-1.5-3.4-2.2c-2.1-1.3-4-2.4-5.2-3
									C197.8,2.9,197,2.5,197,2.5z"/>
							</g>
						</g>
						<g>
							<path fill="#4E5663" d="M183.6,115.6c-1.8-3.2-3.6-6.9-5.2-11.3c-3.1-3.1-5.6-6-7.8-8.7C171.7,99.8,175.4,110.8,183.6,115.6z"/>
						</g>
						<g>
							<path fill="#4E5663" d="M236.5,104.3c-1.6,4.3-3.4,8-5.1,11.2c7.9-4.8,11.5-15.3,12.7-19.6C242,98.5,239.5,101.3,236.5,104.3z"/>
						</g>
						<g>
							<path fill="#4E5663" d="M251.2,36.8c-2.9-7.4-11.7-26.5-37-29.2c-2.4-0.2-4.7-0.3-6.7-0.3c-0.1,0-0.1,0-0.2,0v0
								c-0.1,0-0.2,0-0.4,0c-30,0-40,21.5-43.2,29.5c-6.8,17.1-0.5,38.8,0.4,41.7c1.7-3.3,6.3-3.6,7.4-3.6c-0.3-2-1.4-8.2-0.8-8.5
								c21.8-12.4,33.9-33.6,33.9-33.6c-6.7,21.8-23.2,29.9-25.8,31.1c18-7.4,25.6-22.3,28.4-28.5c0,0,1.3-3,1.5-2.7
								c1,2.5,2.5,4.7,4.2,6.8c1.8,2.2,3.9,4.3,6.2,6.2c10.7,8.9,24.5,14.1,25.3,19.1c0.1,0.6-0.4,6.1-0.9,10c0,0,0,0,0,0.1
								c1.3,0,5.7,0.4,7.3,3.6c0.2-0.5,0.5-1.7,0.9-3.3c1-3.9,2.3-10.5,2.5-18C254.7,50,251.7,38,251.2,36.8z"/>
						</g>
					</g>
					<g id="front_layer13">
						<path fill="#FFFFFF" d="M292.9,824.5c-3.8-18.5-8.6-41.6-8.5-61.4c0-1.9,0.1-3.8,0.2-5.6c0.5-7.7,0.7-14,0.7-19.8
							c0.1-17.5-1.8-30.7-5.6-56.8l-0.1-0.6c-1.3-9.2-2.8-19.5-4.5-32.2c-1.3-9.7-2-20.1-2-31.9c0-24.2,2.7-49.8,5.3-74.6
							c2.4-22.4,4.8-45.5,5.3-67.5l0-0.9l0-0.2l-0.2-22c0.8,0,1.7,0.1,2.6,0.2c2.2,0.2,4.2,0.4,6.1,0.8c0.3,5.8,0.5,12,0.4,18.8
							c-0.1,23.8-2.8,48.8-5.4,73.1c-2.5,23.4-5.1,47.6-5.3,70c-0.1,13.2,0.5,24.6,1.9,34.7c1.7,12.8,3.3,23.3,4.6,32.5l0,0.2
							c4.2,28.8,6.1,42,5.6,62.3c-0.1,4.3-0.3,9-0.7,14.3c-0.2,3.8-0.4,8.1-0.5,12.6c-0.6,19.5,0.1,41.8,0.8,58.3
							C293.5,827.3,293.2,825.9,292.9,824.5z"/>
					</g>
					<g id="front_layer21">
						<g>
							<g>
								<path fill="#FFFFFF" d="M295.9,841.7l-0.7-8.6c0.2,3.1,0.3,6,0.4,8.6H295.9z"/>
								<path fill="#FFFFFF" d="M295.9,841.7l-0.7-8.6c0.2,3.1,0.3,6,0.4,8.6H295.9z"/>
								<path fill="#FFFFFF" d="M224.8,466.5c-0.7,0.4-1.4,0.7-2,1.1C223.4,467.3,224.1,466.9,224.8,466.5z"/>
								<path fill="#FFFFFF" d="M224.8,466.5c-0.7,0.4-1.4,0.7-2,1.1C223.4,467.3,224.1,466.9,224.8,466.5z"/>
								<path fill="#FFFFFF" d="M272.6,840.6c-0.2-2.6-0.5-5.7-0.7-9.5c-0.4-5.4-0.7-11.5-1.1-18c-1.4-25.7-3.2-57.7-7.5-72.4l-0.1-0.5
									c-4.4-15.2-6.5-22.2-7.1-73c-0.1-10.7-2.1-16.9-3.9-22.8c-2.1-6.8-4.3-13.7-4.3-31.9c0-10.6-3.5-26.7-7.8-47
									c-4.9-22.9-11.1-51.4-13.1-77.5l0-0.2c-0.3-3.5-0.4-6.6-0.5-9.5l-0.5-11c2.4-1.3,4.8-2.5,7.2-3.6c12.1-5.7,30.3-12.6,47.9-12.6
									c0.1,0,0.2,0,0.2,0l0.2,22l0,0.2l0,0.9c-0.4,21.9-2.9,45-5.3,67.3c-2.6,24.8-5.4,50.5-5.4,74.8c0,11.9,0.7,22.4,2,32.2
									c1.7,12.7,3.2,23.1,4.5,32.3l0.1,0.6c3.8,26,5.6,39.1,5.6,56.5c0,5.8-0.2,12-0.7,19.7c-0.1,1.8-0.2,3.7-0.2,5.7
									c-0.1,20,4.7,43.2,8.6,61.8c1.2,6,2.3,11.2,3.1,15.7H272.6z"/>
							</g>
							<path opacity="5.000000e-02" enableBackground="new    " d="M224.8,466.8c2.4-1.2,5.1-2.5,8-3.8h0c-1,2.3-4.5,10.6-6.9,25.7
								c-0.3-3.5-0.5-7-0.6-10.5L224.8,466.8z"/>
						</g>
					</g>
					<g id="front_layer14">
						<g>
							<path fill="#FFFFFF" d="M269,820.8c-6.9-37.6-12-62.6-15.5-74.3l-0.1-0.5c-4.7-16.2-6.9-23.6-7.5-75.8c-0.1-9.2-1.7-14.1-3.4-19.8
								c-2.3-7.2-4.8-15.3-4.8-35.1c0-10.6-3.7-27.6-7.9-47.3c-1.4-6.3-2.7-12.5-4-18.8l0-0.2c-0.6-3-1.2-6-1.8-9.1l-0.1-0.3
								c-0.3-1.4-0.6-2.9-0.8-4.3l-0.1-0.4c-0.3-1.4-0.5-2.8-0.8-4.2l-0.2-0.9c-0.2-1.3-0.5-2.5-0.7-3.8l-0.3-1.7c-0.2-1-0.3-2-0.5-3.1
								l-0.3-1.7c-0.2-1-0.3-2-0.5-3l-0.3-2.3c-0.1-0.8-0.2-1.7-0.4-2.5l-0.3-2.4c-0.1-0.8-0.2-1.6-0.3-2.4c0-0.4-0.3-2.7-0.3-2.7
								c-0.1-0.7-0.2-1.4-0.2-2.1c-0.1-0.5-0.1-1-0.2-1.5l-0.2-1.6c-0.1-0.5-0.1-1.1-0.2-1.6c0-0.5-0.1-0.9-0.1-1.4l-0.1-1.6
								c-0.1-0.6-0.1-1.2-0.2-1.7c0-0.5-0.1-1.1-0.1-1.6l-0.1-1.5c0-0.5-0.1-1.1-0.1-1.6c0-0.6-0.1-1.3-0.1-1.9l-0.1-1.5
								c0-0.4,0-0.8-0.1-1.3c0-0.7,0-1.3-0.1-2l0-1.4c0-0.4,0-0.8,0-1.2c0-1.5-0.1-3.1-0.1-4.6c0-0.1,0-0.6-0.1-1.2
								c1-0.7,3.3-2.1,7.7-4.5l0.4,9.9c0.1,2.9,0.3,6,0.5,9.5l0,0.2c2,26.4,8.2,55,13.1,78c4.4,20.2,7.8,36.2,7.8,46.5
								c0,18.5,2.2,25.7,4.4,32.6c1.8,5.8,3.7,11.8,3.8,22.2c0.6,51.1,2.7,58.2,7.2,73.6l0.1,0.5c4.2,14.4,6,46.3,7.4,71.9
								C268.7,815.8,268.8,818.3,269,820.8z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M225.8,487.8c-0.2-3.2-0.4-6.4-0.5-9.5l-0.5-11.7
								c-5.5,3-8.7,4.9-10,5.8c0.1,0.8,0.1,1.7,0.1,1.8c0,1.5,0,3.1,0.1,4.6c0,0.6,0,1.3,0.1,2c0,0.9,0.1,1.8,0.1,2.7
								c0,0.7,0.1,1.4,0.1,2.1c0,0.9,0.1,1.7,0.1,2.6c0,0.8,0.1,1.6,0.2,2.4c0.1,0.8,0.1,1.6,0.2,2.4c0.1,0.8,0.2,1.7,0.2,2.5
								c0.1,0.7,0.1,1.5,0.2,2.2c0.1,0.8,0.2,1.6,0.3,2.4c0.1,0.8,0.2,1.6,0.2,2.3c0.1,0.9,0.2,1.9,0.3,2.8c0.1,0.7,0.2,1.3,0.2,2
								c0.1,1,0.3,2,0.4,3c0.1,0.6,0.2,1.2,0.2,1.8c0.1,1.1,0.3,2.1,0.5,3.2c0.1,0.5,0.2,1.1,0.2,1.6c0.2,1.1,0.3,2.2,0.5,3.3
								c0.1,0.5,0.1,1,0.2,1.4c0.2,1.1,0.4,2.3,0.6,3.4c0.1,0.4,0.1,0.9,0.2,1.3c0.2,1.3,0.4,2.6,0.7,3.8c0.1,0.3,0.1,0.6,0.2,0.9
								c0.3,1.4,0.5,2.8,0.8,4.2c0,0.1,0.1,0.3,0.1,0.4c0.3,1.5,0.6,2.9,0.8,4.3c0,0.1,0,0.2,0.1,0.3c0.6,3.1,1.2,6.1,1.8,9.1v0
								c0,0,0,0.1,0,0.1C222.1,522.2,223.5,501.9,225.8,487.8z"/>
						</g>
					</g>
					<g id="front_layer18">
						<path fill="#FFFFFF" d="M145.9,821.9c0.2-2.8,0.3-5.8,0.5-8.8c1.4-25.6,3.2-57.4,7.4-71.9l0.1-0.5c4.5-15.4,6.6-22.5,7.2-73.6
							c0.1-10.4,2-16.4,3.8-22.2c2.2-6.9,4.4-14.1,4.4-32.6c0-10.4,3.4-26.3,7.8-46.5c4.9-22.8,11-51.1,13-77.2c0.3-3.7,0.5-7.2,0.6-10.5
							l0.5-9.6c0.3,0.2,0.6,0.3,0.9,0.5c1.9,1.1,3.4,1.9,4.6,2.6c1.1,0.6,1.9,1,2.4,1.4c-0.1,0.6,0,1.1,0,1.1c0,1.5,0,3.1-0.1,5.2l0,1.1
							c0,1.6-0.1,3.2-0.2,4.8c0,0.6-0.1,1.3-0.1,1.9c-0.1,1.2-0.1,2.3-0.2,3.5c-0.1,1.2-0.2,2.4-0.3,3.6l-0.2,1.8
							c-0.2,2.1-0.4,4.3-0.7,6.4c-1,8-2.3,17-3.9,26.7c-1,5.8-2.2,12-3.6,18.9l0,0.2c-0.4,2-0.8,3.9-1.2,5.8l2.2,0.3l-2.2-0.2
							c-1.2,5.6-2.3,11-3.5,16.2c-4.1,19-7.6,35.3-7.6,44.8c0,15.8-1.7,24.1-3.1,29.5c-0.3,1-0.6,2-0.9,2.9c-0.6,1.8-1.1,3.4-1.5,5.2
							c-1.3,4.2-2.6,9.1-2.7,17.2c-0.6,46.6-2.3,57.4-6.1,71l-0.8,2.6c-0.2,0.6-0.3,1.1-0.5,1.7l-0.3,0.9
							C157.3,761.3,150.7,796,145.9,821.9z"/>
						<path opacity="5.000000e-02" enableBackground="new    " d="M192.7,468.1c-0.8-0.4-1.6-0.8-2.5-1.3l-0.5,11.4
							c-0.1,3.5-0.3,7-0.6,10.5c2.4,15,3.7,36.6,0.6,65.8c0.4-2,0.8-3.9,1.2-5.9c0-0.1,0-0.1,0-0.2c1.2-6.2,2.5-12.5,3.6-18.9
							c0,0,0,0,0,0c1.4-8.3,2.8-17.6,3.9-26.7c0.2-2.2,0.5-4.3,0.7-6.5c0.1-0.6,0.1-1.2,0.2-1.8c0.1-1.2,0.2-2.4,0.3-3.6
							c0.1-1.2,0.2-2.4,0.2-3.5c0-0.6,0.1-1.3,0.1-1.9c0.1-1.6,0.2-3.2,0.2-4.8c0-0.4,0-0.8,0-1.1c0-1.8,0.1-3.6,0.1-5.3
							c0-0.1,0-0.7,0.1-1.5c0,0,0,0,0,0C199.5,471.8,197.1,470.6,192.7,468.1z"/>
					</g>
					<g id="front_layer22">
						<path fill="#FFFFFF" d="M120.8,842.9l0.2-1c0.8-4.7,2-10.4,3.3-16.9c4.1-19.6,9.7-46.5,8.4-67.6c-1.8-29.4-0.1-41,4.9-75.6l0.1-0.5
							c1.3-9.2,2.8-19.6,4.6-32.3c4.5-33.3,0.5-71-3.3-107.6c-2.4-22.3-4.8-45.4-5.3-67.3l0.2-22l1.8,0c0.3,0,1,0,1.4,0
							c16.7,0.6,33.6,6.8,44.9,11.9c2.7,1.2,5.2,2.4,7.3,3.5l-0.5,10.7c-0.1,3.3-0.3,6.7-0.6,10.4c-2,26-8.1,54.2-13,76.9
							c-4.4,20.3-7.8,36.4-7.8,47c0,18.2-2.2,25.2-4.3,31.9c-1.9,5.9-3.8,12.1-3.9,22.8c-0.6,50.8-2.7,57.8-7.1,73l-0.1,0.5
							c-4.3,14.7-6.1,46.7-7.5,72.4c-0.4,6.5-0.7,12.7-1.1,18c-0.3,5.1-0.6,8.8-1,11.8L120.8,842.9L120.8,842.9z"/>
					</g>
					<g id="front_layer12">
						<path fill="#FFFFFF" d="M121.4,827.5c0.9-20.3,1.6-47.1,0.3-68.4c-1.9-30.5-0.2-42.4,5-77.8c1.3-9.3,2.9-19.7,4.6-32.7
							c4.4-32,0.4-69.1-3.4-105c-3.2-30.3-6.5-61.6-5-90.4c1.9-0.3,3.8-0.6,5.9-0.8c1-0.1,1.8-0.1,2.6-0.2l-0.2,21.9
							c0.4,22,2.9,45.2,5.3,67.5c3.9,36.4,7.8,74,3.4,107c-1.7,12.7-3.2,23.1-4.6,32.3l-0.1,0.5c-5,34.8-6.7,46.5-4.9,76.1
							c1.3,20.8-4.3,47.5-8.3,67C121.8,825.5,121.6,826.5,121.4,827.5z"/>
					</g>
					<g id="front_layer16">
						<path opacity="5.000000e-02" enableBackground="new    " d="M182,462.3c0,0,0.1,0.2,0.3,0.7h0c4,1.8,7.6,3.6,10.4,5.1
							c4.1,2.2,6.9,3.9,7.7,4.4c0.2-1.2,0.6-2.8,1.7-4.1c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0.1-0.1C200.2,468.2,193.2,468.2,182,462.3z"/>
						<path opacity="5.000000e-02" enableBackground="new    " d="M212.9,468c1.2,1.3,1.7,3,1.9,4.3c1.3-0.8,4.8-3,10-5.8
							c2.3-1.2,5-2.6,7.9-4c0.1-0.1,0.1-0.2,0.1-0.2C220.4,467.5,214.4,468,212.9,468z"/>
						<path fill="#FFFFFF" d="M199.6,470.7c-1.4-0.8-3.6-2.1-6.4-3.6c-2.7-1.5-6.2-3.2-10.5-5.2c-11.5-5.2-28.7-11.5-45.8-12.1
							c-0.4,0-1.1,0-1.4,0l-1.5,0l-0.5,0c-0.2,0-1.1,0-1.1,0c-0.3,0-0.6,0-0.9,0c-0.2,0-1.1,0.1-1.1,0.1c-0.3,0-0.6,0-0.9,0
							c-0.2,0-0.5,0-0.7,0.1l-0.5,0c-0.2,0-0.5,0-0.7,0.1c-0.3,0-0.6,0.1-0.8,0.1c0,0-0.9,0.1-1.1,0.1c-0.4,0-0.7,0.1-1.1,0.2
							c0,0-0.7,0.1-0.9,0.1c-0.2,0-0.4,0.1-0.6,0.1c0.2-3.7,0.5-7.2,0.9-10.7c2.4-21.8,5.9-41.5,10.3-58.6c0,0,0.5,0.3,0.7,0.4l0.4,0.2
							c0.3,0.1,0.5,0.3,0.8,0.4l0.5,0.2c0.3,0.1,0.6,0.3,1,0.4l0.5,0.2c0.4,0.1,0.7,0.3,1.1,0.5l0.5,0.2c0.4,0.1,0.8,0.3,1.1,0.4
							c5.5,1.9,14.3,4.2,25.4,4.2h82.7c0.6,0,1.2,0,1.8,0l0.5,0c0.5,0,0.9,0,1.2,0c0,0,1.4-0.1,1.7-0.1c0,0,1.4-0.1,1.7-0.1
							c0,0,1.3-0.1,1.6-0.2c0,0,1.3-0.1,1.6-0.2c0,0,1.2-0.2,1.5-0.2c0,0,1.2-0.2,1.5-0.2c0,0,1.1-0.2,1.4-0.2l1.7-0.3
							c0.3-0.1,0.7-0.1,1-0.2l1.6-0.4c0.3-0.1,1.2-0.3,1.2-0.3c0.3-0.1,1.1-0.3,1.1-0.3c0.4-0.1,1.2-0.3,1.2-0.3c0.3-0.1,1.1-0.3,1.1-0.3
							c0.3-0.1,1-0.3,1-0.3l1.8-0.6l0,0c0.6-0.2,1.1-0.4,1.7-0.6l0.9-0.4l0.7-0.3l1.1-0.5l1-0.5l0.3-0.2c4.4,17.2,7.9,36.9,10.3,58.7
							c0.3,2.9,0.6,6,0.8,9.5c-3.1-0.5-6.3-0.9-9.7-0.9c-0.5,0-0.9,0-1.4,0c-18,0-36.5,7-48.9,12.8c-2.7,1.3-5.3,2.6-7.9,4
							c-3.9,2.1-6.9,3.9-8.7,5c-0.3-1.1-0.9-2.3-1.8-3.3c0,0-0.1-0.1-0.1-0.1c-1.5-1.5-3.5-2.3-6-2.3c-0.5,0-0.9,0-1.4,0.1
							c-0.2,0-0.4,0.1-0.6,0.1l-0.2,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.2,0.1-0.3,0.1
							c-0.3,0.2-0.6,0.3-0.9,0.5l-0.1,0.1c-0.2,0.2-0.5,0.3-0.7,0.6c-0.1,0.1-0.2,0.2-0.3,0.3l-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
							C200.4,468.6,199.9,469.7,199.6,470.7z"/>
					</g>
					<g id="front_layer7">
						<path fill="#FFFFFF" d="M327.4,430c-2.7-17.9-5.9-40.3-8.2-58.8c-1.9-15.9-2.1-28.9-2.2-33.2c0-0.8,0-1.3,0-1.4
							c-0.9-11.8-2.3-24.6-3.8-38c-2.8-25-6-53.2-6.1-82.3c2.6-0.8,4.8-2,6.5-3.6c0.1-0.1,0.3-0.3,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3
							c0.4-0.4,0.7-0.7,1-1.1c0.1,1.3,0.1,2.7,0.1,4c0,29.3,3.2,58.1,6.1,83.5c1.5,13.6,3,26.4,3.8,38.5c0.3,3.7,0.2,7.9,0.1,12.9
							c-0.1,5.2-0.2,11.1,0.1,17.3C326.4,387.7,327,411.9,327.4,430z"/>
					</g>
					<g id="front_layer23">
						<path fill="#FFFFFF" d="M314.4,456.4c0.6-9.8,2.4-46.1-5-77.1c-2.3-9.5-4.1-20-5.4-31c-1.1-9.1-1.6-16.5-1.9-20.9l0-0.6
							c-0.4-5.5-1.5-12.6-2.7-20.8l0-0.1c-1.9-12.1-8-75-9.2-87.5l-0.1-0.7c5.1-0.1,8.5-0.1,10.2-0.2c1.6-0.1,3.2-0.3,4.6-0.6
							c0.2,29,3.3,57.1,6.1,81.9c1.5,13.6,2.9,26.3,3.8,38c0,0.2,0,0.6,0,1.3c0.1,4.3,0.3,17.5,2.2,33.5c3.1,25.9,8.2,59.2,11,77.3
							L314.4,456.4z"/>
					</g>
					<g id="front_layer9">
						<g>
							<path fill="#FFFFFF" d="M312.5,448.7c-0.5-3-1.1-6.5-1.7-10.3c-1.6-9.7-3.5-21.7-5.6-33.1c-1.8-10.1-3.5-18.1-5.1-24.6l-1-3.8
								c-1.3-5-2.4-9.7-3.5-15c-0.6-2.9-1.1-5.9-1.7-8.9c-1.2-7.5-2.2-15.5-2.8-24.4c-0.4-5.2-1.5-12.3-2.8-20.4l-0.3-1.6
								c-0.2-1.8-0.5-3.8-1-6.2c-0.7-4.3-1.2-7.8-1.6-11.1l-0.1-0.7c-0.1-1.2-0.3-2.3-0.4-3.5l-0.1-0.8c-0.5-4.8-0.9-9-1-12.9
								c-0.1-2.7-0.1-5,0-7.2c0-0.1,0-0.3,0-0.4l0-0.5c3.4-7.4,5-18,5.6-27.2c2.3,23.6,6.1,61,7.5,70.1l0,0.1c1.2,8.1,2.3,15.2,2.7,20.6
								l0,0.6c0.3,4.4,0.8,11.9,1.9,21c1.4,11.2,3.2,21.7,5.5,31.3C313.2,405,313.1,433.8,312.5,448.7z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M287,306.7c0.1,0.5,0.2,1,0.2,1.6c1.3,8.1,2.4,15.2,2.8,20.4
								c0.7,9.8,1.7,17.7,2.8,24.5c0.6,3.1,1.1,6.1,1.7,8.9c1.1,5.3,2.3,10.1,3.6,15.1c0.3,1.3,0.6,2.5,1,3.8c1.8,7.1,3.5,15.7,5.1,24.5
								c-1.8-18.2-2.9-44.1-2.7-52.7c0.2-9.4-13.7-55.3-17.6-68c0,0.1,0,0.2,0,0.3c0.2,1.4,0.3,2.8,0.5,4.2c0.5,3.8,1,7.6,1.6,11.2
								C286.4,302.6,286.8,304.7,287,306.7z"/>
						</g>
					</g>
					<g id="front_layer11">
						<g>
							<g>
								<path fill="#FFFFFF" d="M251.1,285.4c1.5,0.1,3.1-0.2,4.7-0.3c0.8-0.1,1.6-0.3,2.4-0.5l1.2-0.3l1.2-0.4c0.8-0.3,1.6-0.5,2.3-0.8
									c0.8-0.3,1.5-0.7,2.3-1c0.8-0.3,1.5-0.8,2.2-1.2c0.7-0.4,1.5-0.8,2.1-1.4l0.6-0.5c-6.6,4.9-14.7,7-22.6,6.2
									c0.5,0,0.9,0.1,1.4,0.1C249.5,285.4,250.3,285.3,251.1,285.4z"/>
								<path fill="#FFFFFF" d="M270.2,278.9l1.1-0.9c0.3-0.3,0.6-0.6,0.9-0.8C271.6,277.7,270.9,278.3,270.2,278.9z"/>
								<path fill="#FFFFFF" d="M247.2,285.2c-0.5,0-0.9-0.1-1.4-0.2c0,0,0,0,0,0c0.3,0,0.5,0.1,0.8,0.1
									C246.8,285.1,247,285.1,247.2,285.2z"/>
								<path fill="#FFFFFF" d="M277.7,271.1c-1,1.3-2,2.6-3.1,3.7C275.7,273.7,276.7,272.4,277.7,271.1z"/>
								<path fill="#FFFFFF" d="M284.7,250.4c0-0.5,0-0.9-0.1-1.4C284.6,249.5,284.7,250,284.7,250.4
									C284.7,250.5,284.7,250.4,284.7,250.4z"/>
								<path fill="#FFFFFF" d="M278.8,269.4c0.2-0.4,0.5-0.7,0.7-1.1c0.5-0.9,1-1.8,1.5-2.7l0-0.1C280.4,266.9,279.7,268.2,278.8,269.4z
									"/>
								<path fill="#FFFFFF" d="M283.5,259.5C283.5,259.5,283.5,259.5,283.5,259.5c0.1-0.2,0.1-0.3,0.1-0.4
									C283.6,259.3,283.5,259.4,283.5,259.5z"/>
								<path fill="#FFFFFF" d="M239.9,386.2l0-0.2c2.2-12,3.5-19.2,6.1-70.3c0.1-2.2,0.4-12.6,0.8-28.6l1.7,0.2c0.6,0.1,1.1,0.1,1.6,0.1
									c0.3,0,0.5,0,0.8,0c0.2,0,0.5,0,0.8,0c1,0,2-0.1,2.9-0.2c0.4,0,0.8-0.1,1.3-0.1c0.6-0.1,1.2-0.2,1.7-0.3c0.3-0.1,0.6-0.1,0.8-0.2
									l1.3-0.3l1.3-0.4c0.3-0.1,0.6-0.2,0.8-0.3c0.5-0.2,1.1-0.3,1.6-0.6l2.4-1.1c0.6-0.2,1.1-0.5,1.6-0.9c0.2-0.1,0.5-0.3,0.7-0.4
									l0.5-0.3c0.6-0.4,1.2-0.7,1.8-1.2l2.1-1.5c0.5-0.4,0.9-0.8,1.4-1.3l0,0c-0.5,0.9-0.9,1.8-1.3,2.7c-12.6,27.3-1.3,71.5,0.8,76.8
									c1.6,4.2,4.1,11.3,6.8,21.3l-1.2,0.6l-1.1,0.5l-0.5,0.2l-0.8,0.3l-0.7,0.3l-0.8,0.3l-0.7,0.3c-0.3,0.1-1,0.4-1,0.4
									c-0.3,0.1-0.9,0.3-0.9,0.3c-0.3,0.1-0.6,0.2-0.9,0.3l-1.2,0.4c-0.3,0.1-1.1,0.3-1.1,0.3c-0.3,0.1-1.2,0.3-1.2,0.3
									c-0.3,0.1-1.2,0.3-1.2,0.3c-0.3,0.1-1.3,0.3-1.3,0.3c-0.3,0.1-1.3,0.3-1.3,0.3c-0.3,0.1-1.4,0.3-1.4,0.3c-0.3,0.1-0.7,0.1-1,0.2
									l-0.4,0.1c-0.3,0.1-1.4,0.2-1.4,0.2c-0.3,0-1.5,0.2-1.5,0.2c-0.3,0-1.5,0.2-1.5,0.2c-0.3,0-1.6,0.1-1.6,0.1c-0.3,0-0.7,0-1,0.1
									l-0.6,0c-0.3,0-0.6,0-1,0.1l-0.7,0c-0.3,0-0.7,0-1,0l-0.7,0c-0.6,0-1.2,0-1.8,0L239.9,386.2L239.9,386.2z"/>
								<path fill="#FFFFFF" d="M286,246.7c-0.1-0.3-0.1-0.6-0.1-0.8c-0.1-0.5-0.3-1-0.4-1.4c-0.1-0.2-0.1-0.4-0.2-0.7
									c-0.2-0.7-0.4-1.4-0.7-2c-0.7-1.9-1.4-3.6-2.1-5.1c-0.2-0.5-0.4-0.9-0.6-1.4c-0.4-0.9-0.7-1.8-1.1-2.6l-0.5-1.3l-0.3-1.1
									c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.4-0.2-0.7-0.3-1l-0.6-2.6l-0.1,0c-0.1-1.7,0-3,0-3.1l-2.3-0.3c0,0.1-0.5,2.3-2.6,3.4
									c-1.8,0.9-6.1,1.3-12.8,1.3c-3.2,0-6.5-0.1-9.4-0.2c-1.2,0-2.4-0.1-3.4-0.1c0.1-4.4,0.1-7.5,0.2-9.3l39.2,0
									C287.8,221,288,234.3,286,246.7z"/>
							</g>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M246.9,285.9c0.6,0.1,1.2,0.1,1.8,0.2
								c0.8,0.1,1.5,0,2.3,0.1c1.6,0.1,3.2-0.2,4.8-0.3c0.8-0.1,1.6-0.3,2.4-0.5l1.2-0.3l1.2-0.4c0.8-0.3,1.6-0.5,2.4-0.8
								c0.8-0.3,1.6-0.7,2.3-1c0.8-0.3,1.5-0.8,2.3-1.2c0.7-0.4,1.5-0.8,2.2-1.4l2.1-1.6c0.7-0.6,1.3-1.2,1.9-1.8
								c1.8-1.7,3.4-3.7,4.8-5.7l-3.3,6.9c-3.5,6.2-5.6,13.5-6.6,21.2C266,292.5,253.1,287.8,246.9,285.9z"/>
						</g>
						<path opacity="0.1" fill="#020202" enableBackground="new    " d="M226.9,275c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.5,0.5,0.8,0.7
							c0.6,0.5,1.2,1.1,1.8,1.7c0.7,0.5,1.5,1.1,2.4,1.7c1.7,1.1,3.8,2.3,6.2,3.2c0.6,0.3,1.2,0.4,1.8,0.7c0.6,0.2,1.3,0.4,1.9,0.6
							c0.7,0.2,1.4,0.3,2.1,0.4c0.7,0.1,1.4,0.3,2.1,0.4c0.7,0.1,1.5,0.1,2.2,0.2c0.7,0.1,1.5,0,2.3,0c1.5,0.1,3.1-0.2,4.7-0.3
							c0.8-0.1,1.6-0.3,2.4-0.5l1.2-0.3l1.2-0.4c0.8-0.3,1.6-0.5,2.3-0.8c0.8-0.3,1.5-0.7,2.3-1c0.8-0.3,1.5-0.8,2.2-1.2
							c0.7-0.4,1.5-0.8,2.1-1.4l2-1.5c0.6-0.6,1.2-1.2,1.9-1.8c2.5-2.3,4.6-5,6.3-7.9c1.7-2.8,3.1-5.9,4-8.9c0.8-3.1,1.3-6.1,1.2-9
							c0-1.5-0.3-2.8-0.5-4.2c-0.1-0.7-0.3-1.3-0.5-2c-0.2-0.6-0.3-1.3-0.6-1.9c-0.8-2.4-1.7-4.6-2.5-6.6c-0.4-1-0.7-1.9-1-2.7
							c-0.2-0.4-0.3-0.8-0.4-1.2c-0.1-0.4-0.2-0.8-0.3-1.1c-0.2-0.7-0.4-1.4-0.5-1.9c-0.1-0.6-0.2-1.1-0.3-1.5c-0.1-0.8-0.2-1.2-0.2-1.2
							s0.1,0.4,0.3,1.2c0.1,0.4,0.2,0.9,0.3,1.4c0.1,0.6,0.4,1.2,0.6,1.9c0.1,0.4,0.2,0.7,0.4,1.1c0.2,0.4,0.3,0.8,0.5,1.2
							c0.3,0.8,0.7,1.7,1.1,2.7c0.8,1.9,1.8,4.1,2.7,6.5c0.2,0.6,0.4,1.2,0.6,1.9c0.2,0.7,0.4,1.3,0.6,2c0.2,1.4,0.6,2.8,0.6,4.3
							c0.2,3-0.2,6.2-1,9.3c-0.9,3.1-2.3,6.2-4,9.2c-0.4,0.7-0.8,1.3-1.3,2c-1.5,2.2-3.2,4.3-5.2,6.2c-0.6,0.6-1.2,1.2-1.9,1.8l-2.1,1.6
							c-0.7,0.6-1.5,1-2.2,1.4c-0.8,0.4-1.5,0.9-2.3,1.2c-0.8,0.3-1.6,0.7-2.3,1c-0.8,0.3-1.6,0.5-2.4,0.8l-1.2,0.4l-1.2,0.3
							c-0.8,0.2-1.6,0.4-2.4,0.5c-1.6,0.1-3.2,0.4-4.8,0.3c-0.8,0-1.6,0-2.3-0.1c-0.6-0.1-1.2-0.1-1.8-0.2c-0.1,0-0.3,0-0.4,0
							c-0.7-0.1-1.5-0.2-2.2-0.4c-0.7-0.2-1.4-0.3-2.1-0.5c-0.7-0.2-1.3-0.4-2-0.7c-0.6-0.2-1.3-0.4-1.9-0.7c-2.4-1-4.5-2.2-6.2-3.5
							c-0.8-0.6-1.6-1.2-2.3-1.8c-0.7-0.6-1.3-1.2-1.8-1.7c-0.3-0.3-0.5-0.5-0.8-0.7C227.2,275.6,227,275.3,226.9,275
							c-0.5-0.4-0.8-0.8-1-1.1c-0.5-0.6-0.8-1-0.8-1s0.3,0.3,0.8,0.9C226.1,274.2,226.5,274.6,226.9,275z"/>
					</g>
					<g id="front_layer20">
						<path fill="#FFFFFF" d="M177.4,386.2c-0.8-4.4-4.2-23.6-4.7-43.2c-1.2-20.6-2.8-51.4-2.5-56.3c12.5-3,20.3-12.5,20.7-12.9
							c0.1-0.1,6.7-8.9,6.5-24c-0.3-19.4-23.1-22.3-28.4-22.7l-0.1-9.1h77.3l0.1,9.1c-5.4,0.4-28.1,3.3-28.4,22.7
							c-0.2,14.2,5.7,22.9,6.4,23.9c0.5,0.8,5.7,9.1,20.3,13c-0.2,6.1-1.2,41.5-2.4,58c-1.3,17.1-2.6,34.3-4.5,41.6L177.4,386.2z"/>
					</g>
					<g id="front_layer10">
						<g>
							<g>
								<path fill="#FFFFFF" d="M145.7,279.5c0.6,0.5,1.4,0.9,2.1,1.4c0.7,0.4,1.4,0.9,2.2,1.2c0.8,0.3,1.5,0.7,2.3,1
									c0.8,0.3,1.6,0.5,2.3,0.8l1.2,0.4l1.2,0.3c0.8,0.2,1.6,0.4,2.4,0.5c1.6,0.1,3.1,0.4,4.7,0.3c0.8,0,1.5,0.1,2.3,0
									c0.5,0,0.9-0.1,1.4-0.1c-7.8,0.8-15.9-1.2-22.6-6.2L145.7,279.5z"/>
								<path fill="#FFFFFF" d="M144.9,278.9c-0.7-0.5-1.4-1.1-2.1-1.7c0.3,0.3,0.6,0.6,0.9,0.8L144.9,278.9z"/>
								<path fill="#FFFFFF" d="M169.2,285C169.2,285,169.2,285,169.2,285c-0.4,0-0.9,0.1-1.3,0.2c0.2,0,0.4,0,0.6-0.1
									C168.7,285.1,169,285.1,169.2,285z"/>
								<path fill="#FFFFFF" d="M140.5,274.8c-1.1-1.2-2.1-2.4-3.1-3.7C138.3,272.4,139.4,273.7,140.5,274.8z"/>
								<path fill="#FFFFFF" d="M135.5,268.4c0.2,0.4,0.5,0.7,0.7,1.1c-0.9-1.4-1.7-2.8-2.4-4.2C134.3,266.3,134.9,267.3,135.5,268.4z"/>
								<path fill="#FFFFFF" d="M130.4,250.4C130.4,250.4,130.4,250.5,130.4,250.4c0-0.4,0.1-0.9,0.1-1.4
									C130.4,249.5,130.4,249.9,130.4,250.4z"/>
								<path fill="#FFFFFF" d="M131.5,259.2c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1C131.5,259.4,131.5,259.3,131.5,259.2z"/>
								<path fill="#FFFFFF" d="M166.2,386.2c-17.1,0-28.1-5.1-31.4-6.9c2.7-10,5.2-17.1,6.8-21.3c2.2-5.8,14.1-52.6-0.5-79.4
									c0.4,0.4,0.8,0.7,1.2,1.1l2.2,1.6c0.6,0.5,1.2,0.8,1.8,1.2l0.5,0.3c0.3,0.2,0.5,0.3,0.7,0.4c0.5,0.3,1.1,0.6,1.7,0.9l2.3,1
									c0.6,0.2,1.1,0.4,1.7,0.6c0.3,0.1,0.5,0.2,0.8,0.3l1.3,0.4l1.3,0.3c0.3,0.1,0.6,0.1,0.9,0.2c0.5,0.1,1.1,0.2,1.6,0.3
									c0.5,0,0.9,0.1,1.3,0.1c0.9,0.1,1.9,0.2,2.9,0.2c0.3,0,0.5,0,0.8,0c0.2,0,0.5,0,0.8,0c0.5,0,1.1,0,1.6-0.1l1.7-0.2
									c0.4,15.7,0.7,26.4,0.8,28.6c2.6,51.1,3.9,58.3,6.1,70.3l0,0.2L166.2,386.2L166.2,386.2z"/>
								<path fill="#FFFFFF" d="M129.1,246.2c-1.9-12.1-1.9-24.9-1.9-28.6l39.3,0.3c0,1.8,0.1,4.9,0.1,9.1c-1,0-2.1,0.1-3.3,0.1
									c-2.9,0.1-6.1,0.2-9.4,0.2c-6.7,0-11-0.4-12.8-1.3c-2.2-1.1-2.6-3.4-2.6-3.4l-2.3,0.3c0,0,0.1,1.4,0,3.1l-0.1,0l-0.6,2.7
									c-0.1,0.3-0.2,0.6-0.3,1c-0.1,0.3-0.2,0.5-0.3,0.8l-0.4,1.1l-0.5,1.3c-0.3,0.8-0.6,1.6-1,2.5c-0.2,0.5-0.4,0.9-0.6,1.4
									c-0.7,1.5-1.4,3.3-2.1,5.2c-0.2,0.6-0.4,1.3-0.6,1.9c-0.1,0.3-0.1,0.5-0.2,0.7c-0.1,0.5-0.3,0.9-0.4,1.4
									C129.1,246,129.1,246.1,129.1,246.2z"/>
							</g>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M168.2,285.9c-0.6,0.1-1.2,0.1-1.8,0.2
								c-0.8,0.1-1.5,0-2.3,0.1c-1.6,0.1-3.2-0.2-4.8-0.3c-0.8-0.1-1.6-0.3-2.4-0.5l-1.2-0.3l-1.2-0.4c-0.8-0.3-1.6-0.5-2.4-0.8
								c-0.8-0.3-1.6-0.7-2.3-1c-0.8-0.3-1.5-0.8-2.3-1.2c-0.7-0.4-1.5-0.8-2.2-1.4l-2.1-1.6c-0.7-0.6-1.3-1.2-1.9-1.8
								c-1.8-1.7-3.4-3.7-4.8-5.7l3.3,6.9c3.5,6.2,5.6,13.5,6.6,21.2C149.1,292.5,162,287.8,168.2,285.9z"/>
						</g>
						<path opacity="0.1" fill="#020202" enableBackground="new    " d="M188.2,275c-0.2,0.2-0.4,0.4-0.6,0.7c-0.2,0.2-0.5,0.5-0.8,0.7
							c-0.6,0.5-1.2,1.1-1.8,1.7c-0.7,0.5-1.5,1.1-2.4,1.7c-1.7,1.1-3.8,2.3-6.2,3.2c-0.6,0.3-1.2,0.4-1.8,0.7c-0.6,0.2-1.3,0.4-1.9,0.6
							c-0.7,0.2-1.4,0.3-2.1,0.4c-0.7,0.1-1.4,0.3-2.1,0.4c-0.7,0.1-1.5,0.1-2.2,0.2c-0.7,0.1-1.5,0-2.3,0c-1.5,0.1-3.1-0.2-4.7-0.3
							c-0.8-0.1-1.6-0.3-2.4-0.5l-1.2-0.3l-1.2-0.4c-0.8-0.3-1.6-0.5-2.3-0.8c-0.8-0.3-1.5-0.7-2.3-1c-0.8-0.3-1.5-0.8-2.2-1.2
							c-0.7-0.4-1.5-0.8-2.1-1.4l-2-1.5c-0.6-0.6-1.2-1.2-1.9-1.8c-2.5-2.3-4.6-5-6.3-7.9c-1.7-2.8-3.1-5.9-4-8.9c-0.8-3.1-1.3-6.1-1.2-9
							c0-1.5,0.3-2.8,0.5-4.2c0.1-0.7,0.3-1.3,0.5-2c0.2-0.6,0.3-1.3,0.6-1.9c0.8-2.4,1.7-4.6,2.5-6.6c0.4-1,0.7-1.9,1-2.7
							c0.2-0.4,0.3-0.8,0.4-1.2c0.1-0.4,0.2-0.8,0.3-1.1c0.2-0.7,0.4-1.4,0.5-1.9c0.1-0.6,0.2-1.1,0.3-1.5c0.1-0.8,0.2-1.2,0.2-1.2
							s-0.1,0.4-0.3,1.2c-0.1,0.4-0.2,0.9-0.3,1.4c-0.1,0.6-0.4,1.2-0.6,1.9c-0.1,0.4-0.2,0.7-0.4,1.1c-0.2,0.4-0.3,0.8-0.5,1.2
							c-0.3,0.8-0.7,1.7-1.1,2.7c-0.8,1.9-1.8,4.1-2.7,6.5c-0.2,0.6-0.4,1.2-0.6,1.9c-0.2,0.7-0.4,1.3-0.6,2c-0.2,1.4-0.6,2.8-0.6,4.3
							c-0.2,3,0.2,6.2,1,9.3c0.9,3.1,2.3,6.2,4,9.2c0.4,0.7,0.8,1.3,1.3,2c1.5,2.2,3.2,4.3,5.2,6.2c0.6,0.6,1.2,1.2,1.9,1.8l2.1,1.6
							c0.7,0.6,1.5,1,2.2,1.4c0.8,0.4,1.5,0.9,2.3,1.2c0.8,0.3,1.6,0.7,2.3,1c0.8,0.3,1.6,0.5,2.4,0.8l1.2,0.4l1.2,0.3
							c0.8,0.2,1.6,0.4,2.4,0.5c1.6,0.1,3.2,0.4,4.8,0.3c0.8,0,1.6,0,2.3-0.1c0.6-0.1,1.2-0.1,1.8-0.2c0.1,0,0.3,0,0.4,0
							c0.7-0.1,1.5-0.2,2.2-0.4c0.7-0.2,1.4-0.3,2.1-0.5c0.7-0.2,1.3-0.4,2-0.7c0.6-0.2,1.3-0.4,1.9-0.7c2.4-1,4.5-2.2,6.2-3.5
							c0.8-0.6,1.6-1.2,2.3-1.8c0.7-0.6,1.3-1.2,1.8-1.7c0.3-0.3,0.5-0.5,0.8-0.7C187.9,275.6,188.1,275.3,188.2,275
							c0.5-0.4,0.8-0.8,1-1.1c0.5-0.6,0.8-1,0.8-1s-0.3,0.3-0.8,0.9C188.9,274.2,188.6,274.6,188.2,275z"/>
					</g>
					<g id="front_layer8">
						<g>
							<path fill="#FFFFFF" d="M60.8,441.9c4.3-8.8,33.1-67.1,37.9-81.7l3.1-9.5c0.8-2.3,1.4-4.5,2.1-6.8c1.6-5.1,3.4-11,7.2-21.8
								c7.7-21.7,12.4-63.1,14.6-87c0.8,9.6,2.3,20.9,5.5,28.2c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.8,0,1.7,0,2.6c0,14.5-4.1,34.8-11.8,58.7
								c-5.2,16-6.9,21.3-9.3,28.6l-3.1,9.5c-5.4,16.6-13.7,30.6-22.5,45.4c-5.1,8.7-10.5,17.6-15.3,27.4c-0.1,0.3-0.3,0.6-0.5,0.9
								c-0.3,0.5-0.6,1.1-0.9,1.8c-0.7,1.3-1.4,2.8-2.3,4.4L60.8,441.9z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M85.1,409.8c8.8-14.9,17.2-29,22.6-45.7l3.1-9.5
								c2.4-7.3,4.1-12.6,9.3-28.6c7.8-24.1,11.9-44.4,11.9-59.1c-2,13.4-9.2,53.6-26.7,89.5c-19.4,39.6-32.9,73.7-36,81.7
								c0.2-0.3,0.3-0.7,0.5-0.9C74.6,427.4,79.9,418.5,85.1,409.8z"/>
						</g>
					</g>
					<g id="front_layer19">
						<path fill="#FFFFFF" d="M52.2,438.3c1.9-5.5,11.7-32.8,27.7-71.7c1.2-2.9,2.8-5.5,4.4-7.9c0.3-0.5,0.7-1.1,1-1.6
							c3.7-5.9,7.5-12,8-19.8c1-13.7,4.1-29.2,7.3-45.5c4.5-22.7,9.6-48.5,10-74.8c1.3,0.2,2.6,0.4,4,0.5c2,0.1,7.7,0.2,10.3,0.2
							c-0.7,9.6-5.6,74.7-15.9,103.7c-3.8,10.9-5.7,17-7.2,21.9c-0.7,2.3-1.4,4.5-2.1,6.8l-3.1,9.5c-4.8,14.6-33.8,73.4-37.8,81.6
							L52.2,438.3z"/>
					</g>
					<g id="front_layer6">
						<path fill="#FFFFFF" d="M46.2,435.7c2.6-6.6,5.4-16.2,8.3-26.4c4.4-15.5,9.4-33,15.6-46.3c1.9-4.1,4.1-7.6,6-10.7
							c3.3-5.2,6.1-9.7,6.4-14.9c1-14.4,4.2-30.1,7.5-46.8c4.8-24.3,9.8-49.4,9.8-75.7c0-1.3,0-2.6,0.1-3.9c1.6,1.9,4.2,4,8.4,5.2
							c-0.3,26.4-5.4,52.2-9.9,75c-3.3,16.4-6.3,31.9-7.3,45.8c-0.5,7.2-4.2,13.1-7.7,18.7c-0.3,0.5-0.7,1-1,1.6
							c-1.6,2.6-3.3,5.2-4.6,8.3c-15.9,38.7-25.7,65.9-27.7,71.7L46.2,435.7z"/>
					</g>
					<g id="front_layer5">
						<g>
							<path fill="#FFFFFF" d="M207.3,215.7c-13.6,0-27.2,0-39.6-0.1c-29.6-0.1-48.4-0.3-53-0.5c-1.5-0.1-3-0.3-4.3-0.5l-0.7-0.2
								c-6.2-1.6-8.6-5-9.6-7.1c1.1-11.3,4.7-20,10.8-25.8c7.8-7.5,17.3-7.9,19.1-7.9c0,0,0.2,0,0.2,0c0.3,0,0.5,0,0.7-0.1l4-1.6
								c3,0,7.8,0.5,14.5,2.1c3.7,0.9,7.6,2.2,11.5,3.6c6.6,2.5,15.8,4.6,25.9,5.8c0.4,0.1,2,0.2,2,0.2c6.2,0.7,12.4,1.1,18.3,1.1
								c7.8,0,16.1-0.6,24.1-1.9c8.5-1.3,16.3-3.1,22-5.3c5.5-2.1,10.7-3.6,15.6-4.6c5-1,8.7-1.2,10.9-1.2l0.2,0l4,1.6
								c0.2,0.1,0.4,0.1,0.6,0.1l0.3,0c1.8,0,11.3,0.4,19.1,7.9c5.7,5.5,9.3,13.5,10.6,23.9c0.1,0.6,0.1,1.2,0.2,1.9
								c-0.4,0.9-1.1,2.1-2.3,3.3c-0.1,0.1-0.5,0.5-0.5,0.5c-2.8,2.5-6.9,3.8-12,4.1c-4.5,0.2-23.3,0.4-52.8,0.5
								C234.8,215.6,221,215.7,207.3,215.7z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M280.2,170.6L280.2,170.6c-2.4,0-6.2,0.2-11.4,1.3l-18.1,10.6
								l31.2-11.1L280.2,170.6L280.2,170.6z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M167.5,183.1l-17.6-10.3c-6.8-1.7-11.8-2.1-15-2.2l-1.3,0.5l-0.4,0.2
								L167.5,183.1z"/>
						</g>
					</g>
					<g id="front_layer4">
						<g>
							<path fill="#FFFFFF" d="M221.5,129.3c1.4-1.2,2.8-2.5,4.2-4v9.1c0,0.1-0.5,15,13.2,20.6l36.1,14.7c-2.1,0.2-4.2,0.5-6.4,1
								c-4.9,1-10.1,2.5-15.4,4.5c-5.8,2.2-13.7,4.1-22.2,5.4c-2.2,0.3-4.5,0.6-6.7,0.9c-2.5,0.3-5,0.5-7.4,0.6L221.5,129.3z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M280.2,170.6l-5.5-2.2l-5.9,3.4C273.8,170.9,277.6,170.6,280.2,170.6
								z"/>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M226.8,122.3c-2.2,2.7-4.3,4.9-6.4,6.5l-0.7,8.3
								c2.4-1.5,4.8-3.4,7.1-5.7V122.3z"/>
						</g>
					</g>
					<g id="front_layer17">
						<g>
							<path fill="none" d="M221.8,190.8v-7.9c-4.7,0.4-9.5,0.7-14.4,0.7c-4.8,0-9.6-0.3-14.3-0.7v7.9L221.8,190.8L221.8,190.8z"/>
						</g>
						<g>
							<path fill="#FFFFFF" d="M207.4,182.4c-1.7,0-4.4-0.1-7.6-0.2l-4.5-51.5c0.1,0.1,0.7,0.5,0.7,0.5c0.2,0.1,0.3,0.2,0.5,0.3
								c0.3,0.2,0.5,0.3,0.7,0.4c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.5,0.3,0.7,0.4l0.5,0.2c0.6,0.3,1.2,0.5,1.7,0.7
								c0.3,0.1,0.5,0.2,0.7,0.3l0.5,0.2c0.2,0.1,0.4,0.1,0.6,0.2l0.4,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0,0.3,0.1,0.4,0.1
								c0.3,0.1,0.6,0.1,0.8,0.2c0.2,0,0.4,0.1,0.5,0.1c0,0,0.7,0.1,0.8,0.1l0.3,0c0.1,0,0.6,0,0.6,0c0.2,0,0.4,0,0.5,0
								c0.2,0,0.4,0,0.6,0c0,0,0.5,0,0.7-0.1l0.2,0c0.2,0,0.5-0.1,0.8-0.1l0.1,0c0.2,0,0.5-0.1,0.8-0.1l0.2,0c0.3-0.1,0.5-0.1,0.8-0.2
								l0.3-0.1c0.3-0.1,0.7-0.2,1-0.3l0.1,0c0.4-0.1,0.7-0.2,1-0.4l0.4-0.1c0.3-0.1,0.6-0.2,0.9-0.4l0.3-0.1c0.4-0.2,0.8-0.4,1.3-0.6
								l0.2-0.1c0.4-0.2,0.7-0.4,1.1-0.6l0.4-0.2c0.2-0.1,0.4-0.3,0.6-0.4l-4.4,51.1C213.4,182.3,210.7,182.4,207.4,182.4L207.4,182.4
								L207.4,182.4z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M219.2,129.7c-0.1,0.1-0.2,0.1-0.2,0.2c-0.4,0.3-0.7,0.5-1.1,0.7
								c-0.1,0.1-0.2,0.1-0.4,0.2c-0.4,0.2-0.7,0.4-1,0.6c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.2-0.8,0.4-1.2,0.6c-0.1,0-0.2,0.1-0.3,0.1
								c-0.3,0.1-0.6,0.3-0.9,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.1,0-0.2,0.1c-0.3,0.1-0.7,0.2-1,0.3
								c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1,0-0.2,0-0.3,0.1c-0.2,0-0.5,0.1-0.7,0.1c0,0-0.1,0-0.1,0
								c-0.3,0-0.5,0.1-0.7,0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5,0l0,0c0,0,0,0,0,0l0,0
								c0,0,0,0,0,0l0,0c0,0,0,0,0,0c-0.1,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4-0.1
								c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.4-0.1-0.5-0.1
								c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.1-0.4-0.2-0.7-0.2c0,0,0,0-0.1,0
								c-0.5-0.2-1.1-0.4-1.6-0.7c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.5-0.3-0.7-0.4
								c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.5-0.3-0.7-0.5c-0.4-0.3-0.8-0.6-1.2-0.9c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.1-0.4-0.3-0.5-0.4
								c0.2,0.1,0.3,0.3,0.5,0.4l1.2,13.8c6.9,0.9,15.8,0.4,24.6-5l0.7-8.3C220,129.1,219.6,129.4,219.2,129.7z"/>
						</g>
					</g>
					<g id="front_layer3">
						<g>
							<path fill="#FFFFFF" d="M196.1,182c-0.6,0-1.3-0.1-1.9-0.2c-0.5,0-1-0.1-1.5-0.1l-1.3-0.1c-0.7-0.1-1.4-0.1-2-0.2
								c0,0-1.5-0.2-2-0.2c-9.9-1.3-19.4-3.4-26-5.9c-3.9-1.5-7.6-2.6-11.3-3.6c-3.5-0.9-6.9-1.5-10.1-1.9l36.3-14.8
								c7.9-3.2,11.1-9.5,12.3-14.2c1-3.6,0.9-6.4,0.9-6.4l0-8.8c1.1,1.2,2.2,2.3,3.4,3.2l4.7,53.2C197,182.1,196.5,182,196.1,182z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M134.9,170.6L134.9,170.6c3.3,0,8.2,0.4,14.9,2.1l-8.2-4.8
								L134.9,170.6L134.9,170.6z"/>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M188.3,122.6v11.9c0,0.1,0.1,2.7-0.8,6.1
								c2,0.5,4.6,1.2,7.6,1.5l-1.2-13.8C192.1,126.8,190.2,125,188.3,122.6z"/>
						</g>
					</g>
					</svg>
				</div>
				<div className="shape not-slider female-back">
					<svg version="1.1" id="rear_layer_female" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
						viewBox="0 0 333.5 906.2" enableBackground="new 0 0 333.5 906.2">
							<text textDecoration="underline" fontFamily="PoppinsBold"  x="0" y="120" fill="black" fontSize="15px">Izquierda</text>
							<text textDecoration="underline" fontFamily="PoppinsBold"  x="52%" y="120" fill="black" fontSize="15px">Derecha</text>
					<g id="cuerpo_x2F_sombras_x2F_pelo_x2F_pantalon">
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeMiterlimit="10" d="M332.2,469.2c-0.7-0.4-2.3-0.8-3.4-1.7
							c-14.7-11.9-29.2-19-35.8-25.2c-7.5-7.1-15.4-53-28.2-80.5c-5-10.7-11.8-17.3-12.3-25.1c-2.5-35-17.3-78-17.3-122.6
							c0-44.6-32.3-43.1-32.3-43.1l-45.2-18.4c-12.8-5.2-12.2-19.1-12.2-19.1v-12.6c6.5-8.7,12.9-23.5,16.4-48.3c1-7,1.2-13.2,0.8-18.5
							c-0.3-3.3-0.8-6.3-1.5-9c-1.9-7.6-5.3-13-9.3-16.9c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.6-0.6-1.2-1.1-1.9-1.6
							c-0.5-0.4-1.1-0.8-1.6-1.2c-0.4-0.3-0.8-0.5-1.2-0.8c-0.6-0.4-1.2-0.7-1.8-1.1c-0.4-0.2-0.8-0.5-1.3-0.7c-1.7-0.9-3.4-1.5-5-2.1
							c-1.4-0.5-2.8-0.8-4-1.1c-1.3-0.3-2.5-0.5-3.6-0.6c-2.9-0.4-5-0.3-5.7-0.3c-0.7,0-3.3-0.1-6.6,0.4c-10.7,1.6-30.1,8.8-30.5,39.6
							c-0.1,4.2,0.2,8.9,1,14c2.7,19.5,7.3,32.7,12.2,41.8c0.7,1.3,1.5,2.5,2.2,3.7c0.6,1,1.3,1.9,1.9,2.8v12.6c0,0,0.6,13.9-12.2,19.1
							l-45.2,18.4c0,0-32.3-1.4-32.3,43.1s-7.4,87.1-9.9,122.1c-0.6,7.8,0.4,18.4-0.2,30.3c-1.1,22.1-1.7,48.3-2.1,68
							c-0.3,17.2-2,29.3-2,29.3s-2.7,39-0.5,44.8c2.1,5.9,1.6,13.1,3.5,16.8c1.9,3.7,14.4,22.1,15.5,25.9c1.1,3.7,5.1,3.2,5.6,0
							s-8.3-17.1-8-20.5c0,0,6.1,2.7,6.4-2.7c0.3-5.3-0.2-24.8,1.6-31.2c1.8-6.4-8-28.8-5.9-37.4c2.1-8.5,8-53.1,14.4-79
							c3.6-14.7,7.3-27.5,9-52.5c1-14.3,7.7-44.1,6.9-64.7l6.7,14.3c14.9,25.9,3.3,72.9,0.9,79.1c-4.8,12.5-12.9,38.7-17.8,82.8
							c-7.3,65.9,15.9,146,7.4,208.7c-8.5,62.7-12,72.3-9.6,111c2.3,37.4-1.6,93.2-1.9,96.4c-0.4,1.6-3.3,13.3-2.2,18.6
							c1.2,5.7-6.9,16.2-13.6,21.9c-6.7,5.7-0.9,7.3,1.9,7.9c2.2,0.5,44.6,6.2,40.6-6.5c-1.2-3.8-2.4-2.2-2-15.7
							c0.3-7.8,2.6-8.1,1.1-14.8c0,0-2.4-8.9-2.2-11.5c1-5.4,14.4-83.9,21.6-108.5c4.9-16.6,7.1-23.8,7.7-76.9c0.3-20.8,8.3-19.7,8.3-55
							c0-25,20.7-85.9,21.6-138c0.3-0.1,0.6-0.3,0.9-0.4c0.8-0.4,1.5-0.8,2.3-1.2c0.7-0.5,1.5-0.9,2.1-1.5c0.5-0.4,1-0.9,1.5-1.3
							c0.5,0.5,1,0.9,1.5,1.3c0.7,0.6,1.4,1,2.1,1.5c0.7,0.4,1.5,0.9,2.3,1.2c0.3,0.1,0.6,0.3,0.9,0.4c0.9,52.2,21.6,115.2,21.6,138
							c0,35.2,8,34.2,8.3,55c0.7,53.1,2.9,60.2,7.7,76.9c7.5,25.6,21.6,108.9,21.6,108.9c0,3-2.2,11.2-2.2,11.2c-1.5,6.7,0.9,7,1.1,14.8
							c0.5,13.5-0.8,12-2,15.7c-4,12.7,38.4,7,40.6,6.5c2.8-0.6,8.6-2.2,1.9-7.9c-6.7-5.7-14.8-16.2-13.6-21.9c1.2-5.7-2.3-18.8-2.3-18.8
							c-0.4-4.5-4.1-59.4-1.8-96.2c2.4-38.7-1.1-48.3-9.6-111c-8.5-62.7,14.7-142.8,7.4-208.7c-4.9-44.1-13-70.3-17.8-82.8
							c-1.6-4.2-14.5-52.3,0.9-79.1l6.7-14.1c-0.7,20.6,7.4,48.3,11.9,61.9c7.1,22,7.8,23.8,12.5,38.2c8.7,26.6,24.9,46.9,38,73.1
							c1.5,3,5.6,10.3,9.3,19c2.9,6.8,5.6,14.5,6.6,21.7c0.9,6.3,10.4,33.8,11.2,35.6c1.6,3.6,7.1,1.9,6.6-0.8c-0.3-1.7-0.8-3.7-1.4-6
							c1.5,5.5,2.9,10.8,3.4,13c1.1,4.5,8.4,4,6.7-2.5c-1.8-7.2-6.6-29.5-6.6-29.5s6.1,25.5,8.2,32c1.3,4.1,7.8,3.4,6.8-2.8
							c-0.2-1.3-0.7-3.5-1.3-6.1c0.7,2.5,1.4,4.4,1.7,4.9c1.3,2,8.3,1.6,5.8-6.2c-2.7-8.4-4.1-16.7-5.7-25.2c-1.5-7.6-2.5-13.5-3.1-14.9
							c1-0.2,1.9-0.5,3-0.8c3.1-0.7,7.4,4.5,12.4,6.1C331.7,476.1,334.4,470.5,332.2,469.2z"/>
						<path fill="#EFD3D3" d="M145.4,481c-0.6-0.1-1.1-0.3-1.7-0.4c0,0,0,0,0,0l0,0c-1.4-0.4-2.7,0.2-3.9-0.3c-2-0.4-4-1.9-5.9-2.6
							c-0.5-0.2-1-0.4-1.5-0.6c0.9,30,11.4,81.4,11.4,81.4C139.5,521.7,142.2,496.4,145.4,481z"/>
						<path fill="#EFD3D3" d="M119.1,476.8c-0.6,0.3-1.2,0.6-1.9,0.8c-1.9,0.7-3.9,2.2-5.9,2.6c-1.3,0.5-2.6-0.1-3.9,0.3l0,0c0,0,0,0,0,0
							c-0.4,0.1-0.9,0.2-1.3,0.3c3.2,15.4,5.9,40.7,1.6,77.4C107.7,558.3,118.2,506.8,119.1,476.8z"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M161.3,75.4
							L161.3,75.4c0,0,21.7-0.8-7.1,28.7v0C157,96.5,159.5,87.1,161.3,75.4z"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M89.8,75.4
							L89.8,75.4c0,0-21.7-0.8,7.1,28.7v0C94.1,96.5,91.6,87.1,89.8,75.4z"/>
						<path fill="#DDC7BF" d="M28.1,341.2c0,0,6.4,0.3,9.4-8.2"/>
						<path fill="#DDC7BF" d="M125.6,161.8c0,0,0.1,1.1,0.2,3.2c0.1,2,0.2,4.9,0.3,8.3c0.1,3.5,0.2,7.5,0.2,11.8c0,4.3,0.1,8.9,0.1,13.5
							c0,4.6-0.1,9.2-0.1,13.5c0,4.3-0.2,8.3-0.2,11.8c-0.1,3.5-0.2,6.3-0.3,8.3c-0.1,2-0.2,3.2-0.2,3.2s-0.1-1.2-0.2-3.2
							c-0.1-2-0.2-4.9-0.3-8.3c-0.1-3.5-0.2-7.5-0.2-11.8c0-4.3-0.1-8.9-0.1-13.5c0-4.6,0.1-9.2,0.1-13.5c0-4.3,0.2-8.3,0.2-11.8
							c0.1-3.5,0.2-6.3,0.3-8.3C125.6,162.9,125.6,161.8,125.6,161.8"/>
						<path fill="#DDC7BF" d="M125.6,289.2c0,0,0.1,0.6,0.2,1.6c0.1,1,0.2,2.5,0.3,4.3c0.2,3.6,0.3,8.4,0.3,13.2c0,4.8-0.1,9.6-0.3,13.2
							c-0.1,1.8-0.2,3.3-0.3,4.3c-0.1,1-0.2,1.6-0.2,1.6s-0.1-0.6-0.2-1.6c-0.1-1-0.2-2.5-0.3-4.3c-0.2-3.6-0.3-8.4-0.3-13.2
							c0-4.8,0.1-9.6,0.3-13.2c0.1-1.8,0.2-3.3,0.3-4.3C125.6,289.8,125.6,289.2,125.6,289.2"/>
						<path fill="#DDC7BF" d="M116,133c0,0,0.5-0.1,1.3,0.1c0.4,0.1,0.8,0.3,1.2,0.5c0.4,0.2,0.8,0.6,1.2,0.9c0.4,0.4,0.7,0.8,0.9,1.2
							c0.2,0.4,0.4,0.8,0.5,1.2c0.2,0.7,0.2,1.3,0.2,1.3s-0.5-0.3-1-0.8c-0.2-0.3-0.6-0.5-0.8-0.8c-0.3-0.3-0.6-0.6-0.9-0.9
							c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.6-0.6-0.8-0.9C116.3,133.5,116,133,116,133"/>
						<path fill="#DDC7BF" d="M135.1,133c0,0-0.3,0.4-0.8,0.9c-0.2,0.3-0.5,0.5-0.8,0.9c-0.3,0.3-0.6,0.6-0.9,0.9
							c-0.3,0.3-0.6,0.6-0.9,0.9c-0.3,0.3-0.6,0.6-0.8,0.8c-0.5,0.5-1,0.8-1,0.8s0-0.5,0.2-1.3c0.1-0.4,0.3-0.8,0.5-1.2
							c0.2-0.4,0.5-0.8,0.9-1.2c0.3-0.4,0.8-0.7,1.2-0.9c0.4-0.3,0.8-0.4,1.2-0.5C134.5,133,135.1,133,135.1,133"/>
						<path fill="#DDC7BF" d="M111.3,365.6c0,0,0.6,0.1,1.2,0.5c0.3,0.2,0.6,0.4,0.9,0.7c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,2,3,2,3
							c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0-1.5-1.1-2.9-2.3c-0.4-0.3-0.7-0.6-1-0.8c-0.3-0.3-0.5-0.7-0.6-1c-0.3-0.6-0.4-1.2-0.4-1.2
							C111.2,365.7,111.2,365.6,111.3,365.6C111.3,365.6,111.3,365.6,111.3,365.6"/>
						<path fill="#DDC7BF" d="M139.6,365.7c0,0,0,0.6-0.4,1.2c-0.1,0.3-0.4,0.7-0.6,1c-0.3,0.3-0.7,0.6-1,0.8c-1.4,1.2-2.9,2.3-2.9,2.3
							c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0,0,1-1.6,2-3c0.3-0.4,0.5-0.8,0.8-1.1c0.3-0.3,0.6-0.5,0.9-0.7c0.6-0.4,1.2-0.5,1.2-0.5
							C139.5,365.6,139.6,365.6,139.6,365.7C139.6,365.7,139.6,365.7,139.6,365.7"/>
						<path fill="#DDC7BF" d="M79.9,643.7c0,0-0.3,1.2-0.8,2.9c-0.2,0.9-0.5,1.9-0.7,2.9c-0.3,1.1-0.6,2.2-0.8,3.4
							c-0.3,1.1-0.5,2.3-0.8,3.4c-0.2,1.1-0.5,2.1-0.7,3c-0.4,1.8-0.7,2.9-0.7,2.9s-0.2-1.2-0.2-3c0-0.9,0-2,0.2-3.1
							c0.1-0.6,0.1-1.2,0.2-1.7c0.1-0.6,0.2-1.2,0.4-1.8c0.3-1.2,0.6-2.4,1-3.4c0.4-1.1,0.8-2,1.2-2.9c0.4-0.8,0.8-1.5,1.1-1.9
							C79.7,644,79.9,643.7,79.9,643.7"/>
						<path fill="#DDC7BF" d="M171.4,643.7c0,0,0.2,0.2,0.5,0.7c0.3,0.4,0.7,1.1,1.1,1.9c0.4,0.8,0.9,1.8,1.2,2.9c0.4,1.1,0.7,2.2,1,3.4
							c0.1,0.6,0.2,1.2,0.4,1.8c0.1,0.6,0.1,1.2,0.2,1.7c0.1,1.1,0.2,2.2,0.2,3.1c0,1.8-0.2,3-0.2,3s-0.4-1.2-0.7-2.9
							c-0.2-0.9-0.4-1.9-0.7-3c-0.2-1.1-0.5-2.2-0.8-3.4c-0.2-1.2-0.5-2.3-0.8-3.4c-0.2-1.1-0.5-2.1-0.7-2.9
							C171.7,644.9,171.4,643.7,171.4,643.7"/>
						<path fill="#EFD3D3" d="M222.8,353.6c-4.4-14.1-14.2-34.4-19.3-62.7c-2.7-14.7-2.3-19.6-2.6-26.9c0,0,7.6,51.9,29.6,96.9
							c22.7,46.4,37.5,83.6,37.5,83.6s-1.2-2.2-3.3-6.2c-3.6-7.1-10.3-19.7-20.3-35.6c-2.6-4.1-4.8-8.4-7.1-12.6
							C228.6,374.1,224.1,357.9,222.8,353.6"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M50.4,263.2
							c0,0-1.8-4.2-3.7-11.8"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M200.9,263.2
							c0,0,2.7-4.5,3.7-11.8"/>
						
							<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="295.5" y1="506.4" x2="290.5" y2="488.8"/>
						
							<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="306.3" y1="485.9" x2="312.6" y2="510.8"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M15.8,530.7
							c0,0-2.2-18.7-1.8-19.9"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M7.5,529.8
							c0,0,5.4,7.9,9.8,13.2c2.1,2.6,7.1,1.5-2.7-18.5"/>
						<path fill="#DDC7BF" d="M14,506.7c0,0-0.2,0.2-1.1,1.9c-0.2,0.4-0.4,0.9-0.6,1.4c-0.2,0.5-0.4,1.1-0.5,1.7
							c-0.2,0.6-0.2,1.2-0.3,1.8c0,0.6,0,1.3,0,1.9c0.1,0.6,0.1,1.3,0.3,1.9c0.2,0.6,0.2,1.2,0.5,1.8c0.2,0.6,0.4,1.1,0.6,1.6
							c0.2,0.5,0.5,1,0.7,1.4c0.4,0.9,0.9,1.5,1.1,2c0.3,0.5,0.5,0.7,0.5,0.7l0,0.1c0.2,0.4,0.1,0.9-0.3,1.1c-0.4,0.2-0.9,0.1-1.1-0.3
							c0,0,0,0,0-0.1c0,0-0.2-0.3-0.4-0.8c-0.2-0.5-0.7-1.2-1-2.2c-0.2-0.5-0.4-1-0.6-1.5c-0.2-0.5-0.3-1.1-0.5-1.7
							c-0.2-0.6-0.2-1.3-0.4-1.9c-0.1-0.6-0.1-1.3-0.1-2c0-0.7,0.1-1.3,0.1-2c0.1-0.6,0.2-1.3,0.4-1.9c0.2-0.6,0.4-1.2,0.6-1.7
							c0.2-0.5,0.5-1,0.8-1.4c1.1-1.7,1.1-1.7,1.1-1.7"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M17.7,465.1
							c0,0,2.4,12.2-0.8,28.2c-3.2,16-3.1,16.7-2.6,24"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M219.3,887.4
							c0,0-2-5.7-2-11.4"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M31.9,887.4
							c0,0,2-5.7,2-11.4"/>
						<path fill="#DDC7BF" d="M23,903.8c0,0,7.2-1.8,9.8-4.1c0,0,0.8,5.1,8.7,5.8C41.6,905.5,24.8,904.7,23,903.8"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M42.7,905.5
							c-10.8-1-9.9-6.8-9.9-6.8"/>
						<path fill="#DDC7BF" d="M229.3,903.8c0,0-7.2-1.8-9.8-4.1c0,0-0.8,5.1-8.7,5.8C210.7,905.5,227.5,904.7,229.3,903.8"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M209.6,905.6
							c10.8-1,9.9-6.8,9.9-6.8"/>
						<path fill="#DDC7BF" d="M211.8,461.2c0.2-0.8,0.3-1.7,0.3-2.6c0-2.6-0.3-7.8-0.3-7.8c-0.2-3.9-0.5-7.7-0.9-11.5
							c-2.8-25.6-6.7-45.1-10.4-59.5h-0.3c0,0-0.1,0-0.2,0.1c3.7,14.2,7.6,33.8,10.5,59.4C211.3,446.5,211.7,453.8,211.8,461.2z"/>
						<path fill="#DDC7BF" d="M218,342c0,0,1.7,6.2,9.9,6.2"/>
						<line fill="none" stroke="#DDC7BF" strokeMiterlimit="10" x1="125.6" y1="438.3" x2="125.6" y2="472.5"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M119.1,476.8
							c0,0-7.5,5-21,4.5"/>
						<path fill="none" stroke="#DDC7BF" strokeMiterlimit="10" d="M332.2,469.2c-0.7-0.4-2.3-0.8-3.4-1.7c-14.7-11.9-29.2-19-35.8-25.2
							c-7.5-7.1-15.4-53-28.2-80.5c-5-10.7-11.8-17.3-12.3-25.1c-2.5-35-17.3-78-17.3-122.6c0-44.6-32.3-43.1-32.3-43.1l-45.2-18.4
							c-12.8-5.2-12.2-19.1-12.2-19.1v-12.6c6.5-8.7,12.9-23.5,16.4-48.3c1-7,1.2-13.2,0.8-18.5c-0.3-3.3-0.8-6.3-1.5-9
							c-1.9-7.6-5.3-13-9.3-16.9c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.6-0.6-1.2-1.1-1.9-1.6c-0.5-0.4-1.1-0.8-1.6-1.2
							c-0.4-0.3-0.8-0.5-1.2-0.8c-0.6-0.4-1.2-0.7-1.8-1.1c-0.4-0.2-0.8-0.5-1.3-0.7c-1.7-0.9-3.4-1.5-5-2.1c-1.4-0.5-2.8-0.8-4-1.1
							c-1.3-0.3-2.5-0.5-3.6-0.6c-2.9-0.4-5-0.3-5.7-0.3c-0.7,0-3.3-0.1-6.6,0.4c-10.7,1.6-30.1,8.8-30.5,39.6c-0.1,4.2,0.2,8.9,1,14
							c2.7,19.5,7.3,32.7,12.2,41.8c0.7,1.3,1.5,2.5,2.2,3.7c0.6,1,1.3,1.9,1.9,2.8v12.6c0,0,0.6,13.9-12.2,19.1l-45.2,18.4
							c0,0-32.3-1.4-32.3,43.1s-7.4,87.1-9.9,122.1c-0.6,7.8,0.4,18.4-0.2,30.3c-1.1,22.1-1.7,48.3-2.1,68c-0.3,17.2-2,29.3-2,29.3
							s-2.7,39-0.5,44.8c2.1,5.9,1.6,13.1,3.5,16.8c1.9,3.7,14.4,22.1,15.5,25.9c1.1,3.7,5.1,3.2,5.6,0s-8.3-17.1-8-20.5
							c0,0,6.1,2.7,6.4-2.7c0.3-5.3-0.2-24.8,1.6-31.2c1.8-6.4-8-28.8-5.9-37.4c2.1-8.5,8-53.1,14.4-79c3.6-14.7,7.3-27.5,9-52.5
							c1-14.3,7.7-44.1,6.9-64.7l6.7,14.3c14.9,25.9,3.3,72.9,0.9,79.1c-4.8,12.5-12.9,38.7-17.8,82.8c-7.3,65.9,15.9,146,7.4,208.7
							c-8.5,62.7-12,72.3-9.6,111c2.3,37.4-1.6,93.2-1.9,96.4c-0.4,1.6-3.3,13.3-2.2,18.6c1.2,5.7-6.9,16.2-13.6,21.9
							c-6.7,5.7-0.9,7.3,1.9,7.9c2.2,0.5,44.6,6.2,40.6-6.5c-1.2-3.8-2.4-2.2-2-15.7c0.3-7.8,2.6-8.1,1.1-14.8c0,0-2.4-8.9-2.2-11.5
							c1-5.4,14.4-83.9,21.6-108.5c4.9-16.6,7.1-23.8,7.7-76.9c0.3-20.8,8.3-19.7,8.3-55c0-25,20.7-85.9,21.6-138
							c0.3-0.1,0.6-0.3,0.9-0.4c0.8-0.4,1.5-0.8,2.3-1.2c0.7-0.5,1.5-0.9,2.1-1.5c0.5-0.4,1-0.9,1.5-1.3c0.5,0.5,1,0.9,1.5,1.3
							c0.7,0.6,1.4,1,2.1,1.5c0.7,0.4,1.5,0.9,2.3,1.2c0.3,0.1,0.6,0.3,0.9,0.4c0.9,52.2,21.6,115.2,21.6,138c0,35.2,8,34.2,8.3,55
							c0.7,53.1,2.9,60.2,7.7,76.9c7.5,25.6,21.6,108.9,21.6,108.9c0,3-2.2,11.2-2.2,11.2c-1.5,6.7,0.9,7,1.1,14.8
							c0.5,13.5-0.8,12-2,15.7c-4,12.7,38.4,7,40.6,6.5c2.8-0.6,8.6-2.2,1.9-7.9c-6.7-5.7-14.8-16.2-13.6-21.9c1.2-5.7-2.3-18.8-2.3-18.8
							c-0.4-4.5-4.1-59.4-1.8-96.2c2.4-38.7-1.1-48.3-9.6-111c-8.5-62.7,14.7-142.8,7.4-208.7c-4.9-44.1-13-70.3-17.8-82.8
							c-1.6-4.2-14.5-52.3,0.9-79.1l6.7-14.1c-0.7,20.6,7.4,48.3,11.9,61.9c7.1,22,7.8,23.8,12.5,38.2c8.7,26.6,24.9,46.9,38,73.1
							c1.5,3,5.6,10.3,9.3,19c2.9,6.8,5.6,14.5,6.6,21.7c0.9,6.3,10.4,33.8,11.2,35.6c1.6,3.6,7.1,1.9,6.6-0.8c-0.3-1.7-0.8-3.7-1.4-6
							c1.5,5.5,2.9,10.8,3.4,13c1.1,4.5,8.4,4,6.7-2.5c-1.8-7.2-6.6-29.5-6.6-29.5s6.1,25.5,8.2,32c1.3,4.1,7.8,3.4,6.8-2.8
							c-0.2-1.3-0.7-3.5-1.3-6.1c0.7,2.5,1.4,4.4,1.7,4.9c1.3,2,8.3,1.6,5.8-6.2c-2.7-8.4-4.1-16.7-5.7-25.2c-1.5-7.6-2.5-13.5-3.1-14.9
							c1-0.2,1.9-0.5,3-0.8c3.1-0.7,7.4,4.5,12.4,6.1C331.7,476.1,334.4,470.5,332.2,469.2z"/>
						<path fill="#4E5663" d="M170.8,40.1c-0.4-1.2-0.8-2.5-1.2-3.7c-2.9-7.4-11.7-26.5-37.1-29.2c-2-0.2-4-0.3-6.1-0.3
							c-0.2,0-0.4,0-0.6,0c-0.1,0-0.1,0-0.2,0l0,0c-0.1,0-0.2,0-0.4,0C95.1,6.9,85.2,28.5,82,36.5c-7.3,18.4,0.5,42.1,0.5,42.1
							s4.2,13.7,8.2,22c2,5.1,6.4,10.8,11.8,15.1c0.2,0.2,0.6,0.4,0.9,0.7c0.8,0.6,2,1.2,3.8,1.7c0,0,0,0,0,0s0,0,0.1,0
							c0.6,0.2,1.3,0.3,2.1,0.4c1.3,0.3,3,0.7,4.9,1.4c1.9,0.6,3.9,1.5,5.7,2.6c2.9,1.8,5.2,5.7,5.8,5.8c0.6-0.1,2.9-4,5.8-5.8
							c1.6-1,3.4-1.8,5.1-2.4c2.2-0.8,4.2-1.3,5.6-1.6c0.5-0.1,0.9-0.2,1.3-0.3c0.4-0.1,0.7-0.1,0.6-0.2c1.7-0.5,2.8-1.1,3.5-1.7
							c0.3-0.2,0.5-0.4,0.7-0.6c5-5.6,10.4-11,12.3-16.3c4.8-9.9,8.4-20.9,8.4-20.9s0.4-1.3,1-3.6c1-3.9,2.3-10.5,2.5-18
							C172.8,51.6,172.4,45.7,170.8,40.1z"/>
						<g>
							<path fill="#39414C" d="M151.7,23.9c0,2.9-1,10.2-2,12.8c-3.4,9.2-13.1,11.5-23.5,11.5c-7.8,0-15.3-2.1-19.8-7.9
								c-3.4-4.4-5.7-9.4-5.7-13.8c0-3.3,0.4-9.7,1.6-12.5C105.9,5.4,116.4,0,126.4,0c9.6,0,17.4,3.5,21.3,11.5
								C149.3,14.7,151.7,20.2,151.7,23.9z"/>
							<g>
								<path fill="#4E5663" d="M108.8,5.9c0,0,0,0.7-0.1,2c-0.1,1.3-0.1,3.1-0.2,5.3c0,2.2,0,4.8,0.2,7.5c0.1,1.4,0.2,2.8,0.3,4.2
									c0.2,1.4,0.4,2.9,0.6,4.3c0.2,1.4,0.6,2.9,0.9,4.2c0.4,1.4,0.8,2.7,1.4,4c0.3,0.6,0.5,1.2,0.8,1.8c0.1,0.3,0.3,0.6,0.4,0.9
									c0.2,0.3,0.3,0.5,0.5,0.8c0.6,1.1,1.3,2,2.1,2.8c0.3,0.4,0.7,0.8,1.1,1.1c0.3,0.4,0.7,0.7,1.1,0.9c0.7,0.6,1.4,0.9,1.9,1.3
									c0.6,0.3,1,0.5,1.3,0.7c0.3,0.1,0.5,0.2,0.5,0.2s-0.2-0.1-0.5-0.2c-0.3-0.1-0.8-0.3-1.4-0.5c-0.6-0.3-1.3-0.6-2-1.2
									c-0.4-0.2-0.8-0.5-1.2-0.9c-0.4-0.3-0.8-0.7-1.2-1.1c-0.8-0.8-1.6-1.7-2.3-2.8c-0.2-0.3-0.4-0.5-0.5-0.8
									c-0.2-0.3-0.3-0.6-0.5-0.9c-0.3-0.6-0.6-1.2-0.9-1.9c-0.6-1.3-1-2.6-1.5-4.1c-0.4-1.4-0.8-2.9-1-4.3c-0.3-1.5-0.4-2.9-0.6-4.4
									c-0.1-1.5-0.2-2.9-0.3-4.3c-0.1-2.8,0-5.4,0.2-7.6c0.1-2.2,0.4-4,0.6-5.3C108.7,6.6,108.8,5.9,108.8,5.9z"/>
							</g>
							<g>
								<path fill="#4E5663" d="M115.7,2.5c0,0,0.8,0.3,2.1,0.9c1.3,0.6,3.2,1.5,5.4,2.7c1.1,0.6,2.3,1.3,3.5,2c1.2,0.7,2.5,1.6,3.8,2.5
									c1.3,0.9,2.6,1.9,3.9,2.9c1.3,1,2.6,2.1,3.9,3.2c1.2,1.1,2.4,2.4,3.5,3.6c0.6,0.6,1.1,1.3,1.6,1.9c0.5,0.7,1,1.3,1.4,2
									c0.9,1.3,1.6,2.7,2.2,4c0.1,0.3,0.3,0.7,0.4,1c0.1,0.3,0.2,0.7,0.3,1c0.1,0.3,0.2,0.6,0.3,1c0.1,0.3,0.1,0.6,0.2,1
									c0.2,0.6,0.2,1.2,0.3,1.8c0,0.3,0,0.6,0.1,0.8c0,0.3,0,0.5,0,0.8c0,1-0.2,1.9-0.3,2.6c-0.1,0.7-0.4,1.3-0.5,1.6
									c-0.1,0.4-0.2,0.6-0.2,0.6s0-0.2,0.1-0.6c0.1-0.4,0.2-0.9,0.3-1.7c0.1-0.7,0.2-1.6,0.1-2.6c0-0.3,0-0.5,0-0.8
									c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.6-0.1-1.1-0.3-1.7c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.2-0.6-0.3-1
									c-0.1-0.3-0.3-0.6-0.4-0.9c-0.6-1.3-1.4-2.6-2.2-3.8c-0.4-0.6-0.9-1.3-1.4-1.9c-0.5-0.6-1-1.2-1.6-1.8c-1.1-1.2-2.3-2.4-3.5-3.5
									c-1.2-1.1-2.5-2.2-3.8-3.2c-1.3-1-2.6-2-3.9-2.9c-1.3-0.9-2.5-1.8-3.7-2.5c-1.2-0.8-2.4-1.5-3.4-2.2c-2.1-1.3-4-2.4-5.2-3
									C116.4,2.9,115.7,2.5,115.7,2.5z"/>
							</g>
						</g>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M132.4,477
							c0,0,7.5,5,21,4.5"/>
					</g>
					<g id="rear_layer15">
						<path fill="#FFFFFF" d="M211.5,825.6c-4.1-19.5-9.6-46.3-8.3-67.1c1.8-29.7,0.1-41.4-4.9-76.2l-0.1-0.5c-1.3-9.2-2.8-19.6-4.6-32.3
							c-4.5-33-0.5-70.7,3.4-107.2c2.4-22.2,4.8-45.1,5.3-67c3.8-2.5,6.7-5.3,8.5-8.3c0.1,24.8-2.7,50.9-5.4,76.2
							c-3.8,36-7.8,73.2-3.4,105.2c1.8,13,3.3,23.5,4.6,32.7c5.1,35.5,6.9,47.4,5,78c-1.3,21.3-0.7,48,0.2,68.2
							C211.7,826.7,211.6,826.2,211.5,825.6z"/>
					</g>
					<g id="rear_layer22">
						<g>
							<path fill="#FFFFFF" d="M191.1,843.2c-0.6-6.9-1.2-16.3-1.8-27.3c-1.4-25.8-3.2-57.8-7.5-72.5l-0.1-0.5
								c-4.4-15.2-6.5-22.2-7.1-73.1c-0.1-10.8-2.1-16.9-3.9-22.9c-2.1-6.8-4.3-13.8-4.3-32c0-10.6-3.5-26.7-7.9-47.1
								c-5.1-23.6-11.4-53-13.2-79.8c-0.2-2.5-0.2-3.4-0.2-5.3c5.7,1.2,13.4,1.6,20.5,1.8c0.7,0,1.5,0,2.2,0c11.9,0,23.5-2.9,32.2-7.9
								c-0.6,21.3-2.9,43.7-5.2,65.3c-3.9,36.6-7.9,74.4-3.4,107.8c1.7,12.7,3.2,23.1,4.6,32.3l0.1,0.5c5,34.6,6.7,46.3,4.9,75.8
								c-1.3,21.1,4.3,48.1,8.4,67.7c1.3,6.1,2.4,11.4,3.2,15.9L191.1,843.2z"/>
						</g>
					</g>
					<g id="rear_layer14">
						<g>
							<path fill="#FFFFFF" d="M187,816c-1.4-25.6-3.2-57.5-7.4-72l-0.1-0.5c-4.5-15.4-6.6-22.5-7.2-73.7c-0.1-10.4-2-16.4-3.8-22.2
								c-2.2-6.9-4.4-14.1-4.4-32.6c0-10.4-3.4-26.4-7.8-46.6c-4.6-21.3-10.6-52.4-13-77c-2,15.4-2.6,29.3-1.4,49.5
								c1.3,6.4,4.4,20.1,5.8,26.8c4.3,19.7,8,36.7,8,47.3c0,19.8,2.6,28,4.8,35.2c1.8,5.7,3.3,10.6,3.5,19.8c0.7,52.4,2.8,59.8,7.5,76
								l0.1,0.5c3.5,12,8.8,37.8,16,77C187.3,821,187.2,818.6,187,816z"/>
							<path fill="#FFFFFF" d="M141.8,540.9c-0.5-7-0.8-16.5-0.6-25.2c0.3-9.9,1.3-19,1.9-24.3c-0.1-1.4-0.5-7.8-0.6-9.2
								c-3.8-0.7-6.6-1.7-8.5-2.5c0,0.9,0.1,1.7,0.1,2.6l0.1,1.6c0.1,1.2,0.1,2.5,0.2,3.7c0,0,0.1,1.2,0.1,1.7c0.1,1.4,0.2,2.7,0.3,4.1
								l0.1,1.2c0.2,1.7,0.3,3.5,0.5,5.2l0.2,1.6c0.1,1.3,0.3,2.5,0.4,3.8l0.2,2c0.1,1.2,0.3,2.3,0.5,3.5l0.3,1.9c0.2,1.7,0.5,3.3,0.7,5
								l0.3,1.8c0.2,1.1,0.4,2.3,0.5,3.4c0.1,0.8,0.3,1.7,0.4,2.5c0.1,0.9,0.3,1.8,0.5,2.6c0.2,0.9,0.3,1.8,0.5,2.7L141.8,540.9z"/>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M141.8,540.9c-0.5-7-0.8-16.5-0.6-25.2
								c0.3-9.9,1.3-19,1.9-24.3c-0.1-1.4-0.5-7.8-0.6-9.2c-3.8-0.7-6.6-1.7-8.5-2.5c0,0.9,0.1,1.7,0.1,2.6l0.1,1.6
								c0.1,1.2,0.1,2.5,0.2,3.7c0,0,0.1,1.2,0.1,1.7c0.1,1.4,0.2,2.7,0.3,4.1l0.1,1.2c0.2,1.7,0.3,3.5,0.5,5.2l0.2,1.6
								c0.1,1.3,0.3,2.5,0.4,3.8l0.2,2c0.1,1.2,0.3,2.3,0.5,3.5l0.3,1.9c0.2,1.7,0.5,3.3,0.7,5l0.3,1.8c0.2,1.1,0.4,2.3,0.5,3.4
								c0.1,0.8,0.3,1.7,0.4,2.5c0.1,0.9,0.3,1.8,0.5,2.6c0.2,0.9,0.3,1.8,0.5,2.7L141.8,540.9z"/>
						</g>
					</g>
					<g id="rear_layer13">
						<g>
							<path fill="#FFFFFF" d="M64,816c1.4-25.6,3.2-57.5,7.4-72l0.1-0.5c4.5-15.4,6.6-22.5,7.2-73.7c0.1-10.4,2-16.4,3.8-22.2
								c2.2-6.9,4.4-14.1,4.4-32.6c0-10.4,3.4-26.4,7.8-46.6c4.6-21.3,10.6-52.4,13-77c2,15.4,2.6,29.3,1.4,49.5
								c-1.3,6.4-4.4,20.1-5.8,26.8c-4.3,19.7-8,36.7-8,47.3c0,19.8-2.6,28-4.8,35.2c-1.8,5.7-3.3,10.6-3.5,19.8
								c-0.7,52.4-2.8,59.8-7.5,76l-0.1,0.5c-3.5,12-8.8,37.8-16,77C63.7,821,63.9,818.6,64,816z"/>
							<path fill="#FFFFFF" d="M109.3,540.9c0.5-7,0.8-16.5,0.6-25.2c-0.3-9.9-1.3-19-1.9-24.3c0.1-1.4,0.5-7.8,0.6-9.2
								c3.8-0.7,6.6-1.7,8.5-2.5c0,0.9-0.1,1.7-0.1,2.6l-0.1,1.6c-0.1,1.2-0.1,2.5-0.2,3.7c0,0-0.1,1.2-0.1,1.7c-0.1,1.4-0.2,2.7-0.3,4.1
								l-0.1,1.2c-0.2,1.7-0.3,3.5-0.5,5.2l-0.2,1.6c-0.1,1.3-0.3,2.5-0.4,3.8l-0.2,2c-0.1,1.2-0.3,2.3-0.5,3.5l-0.3,1.9
								c-0.2,1.7-0.5,3.3-0.7,5l-0.3,1.8c-0.2,1.1-0.4,2.3-0.5,3.4c-0.1,0.8-0.3,1.7-0.4,2.5c-0.1,0.9-0.3,1.8-0.5,2.6
								c-0.2,0.9-0.3,1.8-0.5,2.7L109.3,540.9z"/>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M109.3,540.9c0.5-7,0.8-16.5,0.6-25.2
								c-0.3-9.9-1.3-19-1.9-24.3c0.1-1.4,0.5-7.8,0.6-9.2c3.8-0.7,6.6-1.7,8.5-2.5c0,0.9-0.1,1.7-0.1,2.6l-0.1,1.6
								c-0.1,1.2-0.1,2.5-0.2,3.7c0,0-0.1,1.2-0.1,1.7c-0.1,1.4-0.2,2.7-0.3,4.1l-0.1,1.2c-0.2,1.7-0.3,3.5-0.5,5.2l-0.2,1.6
								c-0.1,1.3-0.3,2.5-0.4,3.8l-0.2,2c-0.1,1.2-0.3,2.3-0.5,3.5l-0.3,1.9c-0.2,1.7-0.5,3.3-0.7,5l-0.3,1.8c-0.2,1.1-0.4,2.3-0.5,3.4
								c-0.1,0.8-0.3,1.7-0.4,2.5c-0.1,0.9-0.3,1.8-0.5,2.6c-0.2,0.9-0.3,1.8-0.5,2.7L109.3,540.9z"/>
						</g>
					</g>
					<g id="rear_layer23">
						<path fill="#FFFFFF" d="M38.7,842c0.8-4.5,1.9-9.7,3.1-15.7c4.1-19.7,9.7-46.6,8.4-67.7c-1.8-29.4-0.1-41.1,4.9-75.7l0.1-0.5
							c1.3-9.2,2.8-19.6,4.6-32.3c4.5-33.3,0.5-71.2-3.4-107.8c-2.3-21.5-4.6-43.8-5.2-65.1c8.6,5,20.1,7.9,32,7.9c1,0,2,0,3.1-0.1
							c7-0.3,14-1.5,18.2-2.2c0,0,1.8-0.3,1.9-0.3c-0.2,2.2-0.2,2.2-0.4,3.9c-1.7,27.3-8.1,57.3-13.3,81.5c-4.4,20.4-7.9,36.5-7.9,47.1
							c0,18.2-2.2,25.2-4.3,32c-1.9,6-3.8,12.1-3.9,22.9c-0.6,50.9-2.7,57.9-7.1,73.1l-0.1,0.5c-4.3,14.7-6.1,46.8-7.5,72.5
							c-0.6,10.9-1.1,20.4-1.8,27.3L38.7,842z"/>
					</g>
					<g id="rear_layer12">
						<path fill="#FFFFFF" d="M39.3,827.3c0.9-20.1,1.6-46.8,0.2-68.1c-1.9-30.6-0.2-42.5,5-78c1.4-9.3,2.9-19.8,4.6-32.7
							c4.4-32,0.4-69.2-3.4-105.2c-2.7-25.4-5.5-51.5-5.4-76.4c1.7,2.9,4.3,5.6,8.5,8.5c0.5,21.9,2.9,44.8,5.3,67
							c3.9,36.5,7.9,74.2,3.4,107.2c-1.7,12.7-3.2,23.1-4.6,32.3l-0.1,0.5c-5,34.8-6.7,46.5-4.9,76.2c1.3,20.8-4.3,47.6-8.3,67.1
							C39.5,826.3,39.4,826.8,39.3,827.3z"/>
					</g>
					<g id="rear_layer8">
						<path fill="none" stroke="#FFFFFF" strokeMiterlimit="10" d="M98,481.3c13.5,0.5,21-4.5,21-4.5s4.3-2,6.5-4.3c0,0,1.8,2.1,6.9,4.5
							c0,0,7.5,5,21,4.5"/>
						<path fill="#FFFFFF" d="M124.7,473.2c-7,6.7-20.7,8.8-38.6,9.5c-1,0-2,0.1-3,0.1c-15.7,0-30.6-5.2-38.1-13.2
							c-1.2-1.3-2.2-2.7-2.9-4.1c-0.2-0.3-0.3-0.6-0.4-0.8l-0.1-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
							c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.5-0.5-1.6-0.8-2.9c0-7.3,0.4-12.7,1.3-20.1c2.4-22,5.8-41.4,9.9-57.9c1,0.6,2.5,1.3,4.6,2.1
							c0.4,0.1,0.8,0.3,1.2,0.4c0.3,0.1,0.7,0.2,1,0.4c0.5,0.2,1.1,0.4,1.7,0.5l0.5,0.2c0.6,0.2,1.2,0.4,1.9,0.5l0.4,0.1
							c1.9,0.5,6,1.2,10.7,1.9c6.3,1,14.2,2.3,16.4,3.3c9.5,3.9,12.4,12.1,12.7,13l0,0.1l0.1,0.1c0.5,1,1.1,2.1,1.6,3.2
							c3.8,7.7,8.1,16.5,21.3,17.2l0.1,0l0.1,0c13.1-0.8,17.3-9.6,20.9-17.3c0.5-1.1,1.1-2.2,1.6-3.3l0-0.1l0-0.1
							c0.3-0.9,3.1-9.1,12.6-13.2c2.2-0.9,10.1-2.3,16.4-3.4c4.7-0.8,8.8-1.5,10.6-2l0.4-0.1c0.6-0.2,1.3-0.4,1.9-0.6l0.5-0.2
							c0.6-0.2,1.1-0.4,1.7-0.6c0.3-0.1,0.7-0.2,1-0.4c0.4-0.1,0.8-0.3,1.2-0.4c2.1-0.8,3.7-1.6,4.8-2.2c4.3,17,7.7,36.4,10.1,57.8
							c0.7,6.6,1.2,13.7,1.3,21.5c-0.1,0.5-0.2,1-0.4,1.5l-0.1,0.2c-0.1,0.2-0.2,0.5-0.3,0.7l-0.1,0.2c-0.1,0.3-0.2,0.5-0.4,0.8
							c-0.7,1.5-1.7,2.9-2.9,4.2c-7.6,8.3-22.8,13.6-38.8,13.6c0,0,0,0,0,0c-0.7,0-1.4,0-2.2,0c-28-0.3-35-5.3-39.2-9.1l-0.9-0.7"/>
						<path opacity="5.000000e-02" fill="none" stroke="#020202" strokeMiterlimit="10" enableBackground="new    " d="M98,481.3
							c13.5,0.5,21-4.5,21-4.5s4.3-2,6.5-4.3c0,0,1.8,2.1,6.9,4.5c0,0,7.5,5,21,4.5"/>
					</g>
					<g id="rear_layer11">
						<path fill="#FFFFFF" d="M283.4,437.9c-1.8-5-9.2-25.8-21.3-56c-1-2.5-2-5.1-3.1-7.7l-1.6-4c-0.5-1.3-1.1-2.7-1.7-4.1
							c-0.5-1.3-1.2-2.6-1.9-4c-0.2-0.4-0.5-0.8-0.7-1.2c-0.5-0.8-1-1.6-1.5-2.4l-0.4-0.7c-0.3-0.5-0.7-1.1-1-1.6
							c-0.9-1.4-1.8-2.9-2.7-4.4c-0.6-1-1.1-2-1.6-3c-0.3-0.5-0.5-1-0.7-1.5c-0.8-1.6-1.3-3.2-1.8-4.8c-0.4-1.3-0.6-2.6-0.8-3.8
							c-0.1-0.4-0.1-0.9-0.1-1.3c-0.1-1.3-0.2-2.6-0.3-4c-0.4-3.8-0.9-7.8-1.6-12.3c-0.5-3.5-1.2-7.2-1.9-11.5c-0.8-4.3-1.6-8.6-2.3-11.9
							c-0.4-2-0.8-4.1-1.2-6.1c-0.8-4.1-1.6-8.3-2.4-12.5c-0.9-4.8-1.7-9-2.4-13c-0.5-3-1-6-1.5-8.9c-0.6-4.2-1.2-8.2-1.7-12
							c-0.4-3.3-0.8-6.6-1-9.8c-0.1-1.5-0.3-3-0.4-4.4c-0.1-1.2-0.2-2.3-0.2-3.5c3.9-1,6.5-2.6,8.2-4.3c1,23.1,5.4,45.3,9.6,66.7
							c3.3,16.7,6.4,32.5,7.5,46.9c0.4,5.2,3.2,9.7,6.4,14.9c2,3.1,4.2,6.7,6.1,10.8c6.1,13.3,11.2,30.8,15.6,46.3
							c3,10.7,5.9,20.8,8.7,27.3L283.4,437.9z"/>
					</g>
					<g id="rear_layer21">
						<path fill="#FFFFFF" d="M274.8,441.6c-4-8.1-33-66.9-37.8-81.6l-3.1-9.5c-0.8-2.3-1.4-4.5-2.1-6.7c-1.5-4.9-3.4-11-7.2-21.9
							c-8.5-24-13.3-72.6-15.1-93.7c1,0,2,0,2.9,0c1.2,0,3.3-0.1,3.3-0.1l3.2-0.1c0.3,0,0.6,0,0.8,0c1.2-0.1,2.4-0.2,3.6-0.4l0,0l0.1,0
							c1.3,22.8,5.7,45.1,9.6,64.7c3.2,16.4,6.3,31.8,7.3,45.5c0.6,7.8,4.4,13.9,8,19.8c0.3,0.5,0.7,1.1,1,1.6c1.6,2.5,3.2,5.1,4.4,7.9
							c16,39,25.8,66.3,27.7,71.7L274.8,441.6z"/>
					</g>
					<g id="rear_layer10">
						<path fill="#FFFFFF" d="M268.7,444.1c-1.1-2.2-2.1-4-2.8-5.3c-0.6-1.1-1-2-1.4-2.7c-4.9-9.7-10.2-18.7-15.3-27.4
							c-8.8-14.9-17.2-28.9-22.6-45.5l-3.1-9.5c-2.4-7.4-4.2-12.7-9.4-28.8c-8.2-25.4-12.3-46.7-11.8-61.4c0-0.3-0.1-0.6-0.3-1
							c2-4.6,3.6-10.9,4.5-18.1c0.4-2.7,0.7-5.5,1-8.4c2.2,23.2,6.8,64,14.4,85.8l0.2,0.5c0.5,1.5,1,2.9,1.5,4.2l0.4,1.3
							c0.3,0.8,1.2,3.6,1.2,3.6c0.4,1.1,0.7,2.2,1,3.2l0.9,2.8c0.3,0.9,0.5,1.7,0.8,2.5l0.5,1.5l0.8,2.6c0.4,1.3,0.7,2.4,1.1,3.6
							c0.3,1.1,0.7,2.2,1.1,3.4l3.1,9.6c0.3,0.8,0.6,1.8,1,2.8c0.4,1.1,0.9,2.2,1.4,3.5c0.3,0.7,0.6,1.5,1,2.3c1.1,2.7,2.5,5.8,4.1,9.3
							c0.7,1.5,1.4,3.1,2.2,4.8c0,0,1.8,3.9,2.3,5c1.1,2.3,2.1,4.6,3.2,6.9c1.1,2.3,2.2,4.7,3.3,7.1l1.7,3.5c2.2,4.7,4.4,9.3,6.5,13.6
							c1,2.1,2,4.2,3,6.2l2.7,5.5l3.3,6.7l2.3,4.6L268.7,444.1z"/>
					</g>
					<g id="rear_layer19">
						<path fill="#FFFFFF" d="M152.6,386.6L152.6,386.6c0-2.1,0.1-5.1,0.4-8.9c0.1-1.8,0.3-3.8,0.4-6l0-0.5l0,0v-0.1
							c0.1-1.2,0.2-2.5,0.3-3.9c2.3-29.7,7.4-79.7,13.6-124.2c0.7-4.7,1.3-9.5,2-14.2c15.2-0.1,27.8-0.1,36.6-0.2
							c-0.2,4.7-0.7,9.8-1.5,15.6c-0.8,5.8-2.1,13.2-4.9,18.6l-3.2,6.3l-3.7,7.8c-15.3,26.7-3.2,74.5-1,80.4c1.7,4.4,4.3,11.8,7,22.3
							c0,0-0.6,0.3-0.6,0.3l-0.7,0.4l-0.9,0.4l-0.5,0.2l-1.1,0.5l-0.5,0.2c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.2-0.9,0.4l-0.2,0.1
							c-0.3,0.1-0.6,0.2-1,0.4c-0.5,0.2-0.9,0.3-1.4,0.5c-0.4,0.1-0.9,0.3-1.3,0.4l-0.2,0c-0.7,0.2-1.4,0.4-2.2,0.6
							c-2,0.5-5.7,1.1-9.6,1.8c-7.1,1.2-14.4,2.5-16.9,3.6c-2.9,1.3-5.5,3-7.8,5.2L152.6,386.6z"/>
					</g>
					<g id="rear_layer24">
						<path fill="#FFFFFF" d="M125.6,423.3c-11.8-0.6-15.6-8.4-19.3-15.9c-0.5-1.1-1-2.1-1.6-3.1c-0.3-0.9-1.3-3.5-3.6-6.5
							c-0.5-5.7-0.8-10.1-0.8-10.1l0-1l0-1.7l-0.1-2.1l-0.1-2.3l-0.1-1.2l-0.2-2.5l-0.2-2.8l-0.1-1.6c-1.9-26.8-7.9-89.2-16-143.6
							c11,0,23,0.1,35.1,0.1l7,0c14.3,0,28.6,0,41.4-0.1c-0.7,4.6-1.4,9.3-2,13.9c-6.2,44.6-11.3,94.6-13.6,124.3
							c-0.1,1.4-0.2,2.6-0.3,3.9l0,0v0.1l0,0.4c-0.2,2.2-0.3,4.2-0.4,6c-0.3,4.7-0.4,8.1-0.4,10.2l0,0.8l-0.4,8.9c-2.4,3-3.4,5.8-3.7,6.6
							c-0.5,1.1-1.1,2.2-1.6,3.3C141,414.8,137.3,422.6,125.6,423.3z"/>
					</g>
					<g id="rear_layer18">
						<path fill="#FFFFFF" d="M98.7,395c-2.3-2.2-4.9-3.9-7.9-5.1c-2.5-1-9.8-2.2-16.9-3.4c-4.5-0.7-8.7-1.4-10.4-1.9l-0.4-0.1
							c-0.6-0.2-1.2-0.3-1.8-0.5l-0.5-0.2c-0.6-0.2-1.1-0.3-1.6-0.5c-0.3-0.1-0.7-0.2-1-0.3c-0.4-0.1-0.8-0.3-1.1-0.4
							c-2.4-0.9-4-1.8-4.9-2.3c1.1-4.3,2.3-8.4,3.5-12.3c1.2-3.9,2.5-7.5,3.7-10.7c0.4-1.1,1.3-3.9,2.2-8c2.6-11.1,10.1-49.2-3.2-72.4
							l-7.7-16.5l-0.4,0.1c-2.3-5.9-3.8-14.1-4.6-24.5c-0.2-2.4-0.3-4.9-0.4-7.4c8.7,0.1,21,0.2,35.8,0.2c8.1,54.4,14.1,116.9,16,143.8
							l0.3,4.9l0.1,2l0.1,2.5l0.1,2.5l0.1,1.9l0,0.8l0,0.7C98.1,387.8,98.3,390.8,98.7,395z"/>
					</g>
					<g id="rear_layer7">
						<path fill="#FFFFFF" d="M20.5,447.8c-0.2-4.4-0.3-9.9-0.2-16l0-2.1c0-1,0-2.1,0.1-3.1l0.1-2.3c0-1,0.1-2.1,0.1-3.2l0.1-1.6l0.1-1.8
							l0.1-1.7l0.1-1.7l0.1-1.7l0.1-1.7l0.1-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.3-1.7l0.3-1.7
							l0.3-1.7l0.3-1.7l0.3-1.7l0.3-1.7l0.4-1.7l0.4-1.7c0.1-0.6,0.3-1.1,0.4-1.7c0.3-1.4,0.6-2.8,0.9-4.1c0.3-1.4,0.6-2.8,0.9-4.1l0-0.1
							c0.3-1.3,0.5-2.7,0.8-4c0.2-1.1,0.4-2.1,0.5-3.1c0,0,0.4-2.3,0.5-3l0.2-1.1c0.1-0.7,0.2-1.3,0.3-2c0.1-0.6,0.3-2,0.3-2l0.2-1.3
							l0.4-2.6l0.3-2.1l0.2-1.8l0.2-1.9c0.2-1.7,0.4-3.3,0.5-4.8l0-0.2c0.2-1.6,0.3-3.1,0.4-4.5c0.1-0.9,0.2-1.8,0.2-2.6l0-0.3
							c0.1-1.2,0.2-2.3,0.3-3.4l0.3-4c0.4-5.5,1.5-12.5,2.7-20.7l0-0.1c1.4-9.2,5.2-47.4,7.5-70.3c0.8,11.3,2.6,20.3,5.3,26.5
							c-0.1,0.2-0.1,0.4-0.1,0.7c0.5,13.1-2.2,30.5-4.3,44.4c-1.2,8-2.3,15-2.6,20.1c-1.6,22.7-4.7,35.2-8,48.4l-1,3.9
							c-4.1,16.4-7.9,40.3-10.7,57.8C21.5,441.5,21,444.9,20.5,447.8z"/>
					</g>
					<g id="rear_layer20">
						<path fill="#FFFFFF" d="M5.2,449.7c2.8-18.1,7.9-51.5,11-77.3c1.9-16,2.2-29.1,2.2-33.5c0-0.7,0-1.2,0-1.3
							c0.9-11.8,2.3-24.5,3.8-38c2.4-21.8,5.2-46.3,5.9-71.7c0.3,0,0.5,0.1,0.8,0.1c0.9,0.1,1.7,0.2,2.5,0.2c1.7,0.1,5.3,0.2,10.7,0.2
							c-2.1,21-6.7,67.9-8.3,78.2c-1.3,8.3-2.3,15.4-2.7,20.9l0,0.6c-0.3,4.4-0.8,11.8-1.9,20.9c-1.3,11.1-3.2,21.5-5.4,31
							c-7.4,30.9-5.7,67.3-5,77.1L5.2,449.7z"/>
					</g>
					<g id="rear_layer6">
						<path fill="#FFFFFF" d="M5.5,431.6c0.3-18.5,0.9-43.8,2-65.1c0.3-6.2,0.2-12.1,0.1-17.3c-0.1-5-0.2-9.2,0.1-12.9
							c0.9-12,2.3-24.9,3.8-38.5c2.5-22.7,5.4-48.4,6-74.6c1.8,1.6,4.4,3.3,8.3,4.2c-0.8,25.2-3.5,49.7-5.9,71.3
							c-1.5,13.5-2.9,26.3-3.8,38.2c0,0.2,0,0.7,0,1.4c-0.1,4.3-0.3,17.4-2.2,33.3c-0.2,1.6-0.4,3.3-0.6,5l-0.6,5
							c-0.4,3.2-0.8,6.5-1.3,9.8c-0.4,3.1-0.9,6.2-1.3,9.3l-0.9,6.1L8.7,410c-0.4,3-0.9,6-1.3,8.9l-0.9,5.8
							C6.2,427.1,5.9,429.4,5.5,431.6z"/>
					</g>
					<g id="rear_layer5">
						<path fill="#FFFFFF" d="M118.7,226.6c-12.6,0-25,0-36.4-0.1c-16-0.1-29.1-0.1-38-0.2l-1,0c-6.1-0.1-10-0.2-11.8-0.3
							c-0.8,0-1.5-0.1-2.4-0.2c-0.7-0.1-1.3-0.2-2-0.3c-5.4-1.1-8.2-3.6-9.7-5.5c0-1.9,0-3.9,0-5.8c0-40.3,27.2-41.5,30.3-41.5
							c0.2,0,0.3,0,0.3,0c0.2,0,0.4,0,0.7-0.1l1.4-0.6c0.5,0,1.1,0,1.8,0c4.2,0,13.2,0.8,26.6,5.8c11.5,4.3,29.9,7.2,46.8,7.2
							c16.9,0,35.3-2.8,46.9-7.2c13.4-5,22.4-5.8,26.6-5.8c0.7,0,1.3,0,1.8,0l1.4,0.6c0.2,0.1,0.4,0.1,0.6,0.1h0.5l0,0
							c1.8,0,9.2,0.4,16.2,5.5c9.3,6.8,14,18.9,14,36c0,1.8,0,3.6,0.1,5.8c-1.4,2-4.2,4.4-9.5,5.5c-1.4,0.3-2.9,0.5-4.5,0.6l-1.7,0.1
							l-2.4,0.1c-0.9,0-1.9,0-3,0.1c-1.2,0-5.4,0.1-5.4,0.1c-8.9,0.1-22.3,0.2-38.7,0.2h-0.1c-13.1,0-27.8,0.1-42.5,0.1L118.7,226.6z"/>
					</g>
					<g id="rear_layer4">
						<path fill="#FFFFFF" d="M137.9,120.8c1.9-0.6,3.5-1,4.7-1.3c0.4-0.1,0.9-0.2,1.4-0.3c0.2,0,0.4-0.1,0.7-0.1
							c-0.2,0.3-0.4,0.5-0.5,0.8c-0.2,0.3-0.3,0.6-0.3,1v12.6c0,0.1-0.5,15,13.3,20.6l38.6,15.7l-1,0l-0.1,0c-6.9,0.6-14.7,2.5-23.1,5.7
							c-7.1,2.7-17.4,4.9-28.3,6L137.9,120.8z"/>
					</g>
					<g id="rear_layer17">
						<path fill="#FFFFFF" d="M125.5,182.5c-5,0-10.2-0.2-15.4-0.7l5.2-60.4c1.5,0.6,2.9,1.3,4.1,2c1.7,1,3.2,2.9,4.2,4.2
							c0.9,1.1,1.3,1.6,1.9,1.8l0.3,0.1l0.3-0.1c0.6-0.1,1-0.7,1.9-1.8c1-1.3,2.5-3.2,4.2-4.2c1-0.6,2.2-1.2,3.5-1.8l5.2,60.1
							C135.6,182.3,130.4,182.5,125.5,182.5L125.5,182.5z"/>
					</g>
					<g id="rear_layer16">
						<path fill="#FFFFFF" d="M107.8,181.6c-11-1.2-21.2-3.4-28.3-6c-8.4-3.2-16.2-5.1-23.1-5.7l-0.1,0l-0.8,0L94.1,154
							c13.8-5.6,13.3-20.5,13.3-20.6l0-12.6c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.4-0.5-0.5-0.8l0.5,0.1c0.7,0.2,1.4,0.3,2.2,0.4
							c1.2,0.3,2.6,0.6,3.8,1L107.8,181.6z"/>
					</g>
					</svg>

				</div>
				<Slider {...settings}>
					<div className="shape slider female-front">
					<svg version="1.1" id="front_layer_female_slider" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
						viewBox="0 0 332.9 906.1" enableBackground="new 0 0 332.9 906.1">
							<text textDecoration="underline" fontFamily="PoppinsBold" x="25%" y="120" fill="black" fontSize="15px">Derecha</text>
								<text textDecoration="underline" fontFamily="PoppinsBold" x="77%" y="120" fill="black" fontSize="15px">Izquierda</text>
					<g id="cuerpo_x2F_sombras_x2F_pelo_x2F_pantalon">
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M187.8,120.9
							v13.6c0,0,0.6,13.8-12.2,19l-45.2,18.4c0,0-32.3-1.4-32.3,43.1c0,44.5-14.7,87.4-17.3,122.4c-0.6,7.8-7.4,14.3-12.3,25.1
							c-12.7,27.5-20.6,73.3-28.1,80.4C33.9,449,19.4,456.1,4.7,468c-1.1,0.9-2.7,1.3-3.4,1.7c-2.3,1.2,0.5,6.8,5.4,5.2s9.3-6.7,12.4-6
							c1,0.3,2,0.5,3,0.8c-0.6,1.3-1.7,7.3-3.1,14.8c-1.6,8.4-3,16.8-5.7,25.1c-2.5,7.8,4.5,8.2,5.8,6.2c1.6-2.5,8.1-30,8.1-30
							s-6.7,24.9-7.7,31.2c-1,6.3,5.5,6.9,6.8,2.8c2.1-6.5,8.2-31.9,8.2-31.9s-4.8,22.2-6.6,29.4c-1.6,6.5,5.7,6.9,6.7,2.5
							c1.3-5.6,8.4-31,8.4-31s-4.8,16.6-6.4,24.1c-0.6,2.7,5,4.4,6.6,0.8c0.8-1.8,10.3-29.2,11.2-35.5c1.1-7.2,3.7-14.9,6.6-21.7
							c3.7-8.8,7.8-16,9.3-19c13.1-26.2,29.2-46.4,37.9-73c4.7-14.4,5.4-16.3,12.5-38.2c4.4-13.6,12.6-41.2,11.8-61.8l6.7,14
							c15.4,26.8,2.5,74.8,0.9,79c-4.8,12.5-12.9,38.6-17.8,82.6c-7.3,65.8,15.9,145.7,7.4,208.4s-12,72.2-9.6,110.8
							c2.3,36.8-1.5,91.6-1.8,96.1c0,0-3.5,13.1-2.3,18.8c1.2,5.7-6.9,16.1-13.6,21.9c-6.7,5.7-0.9,7.3,1.9,7.9
							c2.2,0.5,34.5,6.5,37.4-6.5c0,0,0.8-2.2,1.2-15.7c0.3-7.8,2.6-8.1,1.1-14.8c0,0-2.2-8.2-2.2-11.2c0,0,14.1-83.1,21.6-108.7
							c4.8-16.6,7.1-23.7,7.7-76.7c0.3-20.8,8.3-19.7,8.3-54.9c0-23.3,21.6-88.5,21.6-141.2c0,0-0.4-7.6,6.8-7.6h0c7.1,0,6.8,7.6,6.8,7.6
							c0,52.8,21.6,115.8,21.6,141.2c0,35.2,8,34.1,8.3,54.9c0.7,53,2.9,60.1,7.7,76.7c7.2,24.6,20.5,102.9,21.5,108.4
							c0.2,2.6-2.2,11.5-2.2,11.5c-1.5,6.7,0.8,7,1.1,14.8c0.5,13.5,1.2,15.7,1.2,15.7c2.9,13,35.2,7,37.4,6.5c2.8-0.6,8.6-2.2,1.8-7.9
							S298,879.7,299.2,874c1.1-5.3-1.8-17-2.2-18.6c-0.3-3.2-4.2-59-1.8-96.3c2.4-38.6-1.1-48.2-9.6-110.8
							c-8.5-62.6,14.7-142.5,7.4-208.4c-4.9-44-13-70.2-17.8-82.6c-2.4-6.2-14-53.1,0.9-79l6.6-12.9c-0.7,20.6,6,48.9,7,63.2
							c1.8,25,5.4,37.7,9,52.4c6.4,25.8,12.3,70.3,14.4,78.9c2.1,8.5-7.7,30.9-5.9,37.3c1.8,6.4,1.4,25.8,1.6,31.2
							c0.3,5.3,6.4,2.7,6.4,2.7c0.3,3.5-8.5,17.3-8,20.5c0.5,3.2,4.5,3.7,5.6,0c1.1-3.7,13.6-22.1,15.5-25.8c1.9-3.7,1.3-10.9,3.5-16.8
							c2.1-5.9-0.5-44.8-0.5-44.8s-1.7-12.1-2-29.3c-0.3-19.6-1-45.8-2.1-67.9c-0.6-11.8,0.4-22.4-0.2-30.2c-2.5-35-9.9-77.4-9.9-121.9
							c0-44.5-32.3-43.1-32.3-43.1l-45.2-18.4c-12.8-5.2-12.2-19-12.2-19v-13.6h0"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M207.5,18.8
							c-2.7-0.1-28.5-0.5-35.4,26.4c-1.8,7.1-2.3,16.1-0.7,27.5c7.9,56.5,31,60.7,36.1,60.7c5.1-0.1,28.2-4.2,36.1-60.7
							C251.2,18.1,210.8,18.7,207.5,18.8z"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M171.8,75.5
							L171.8,75.5c0,0-21.6-0.8,7.1,28.6v0C176.1,96.6,173.6,87.2,171.8,75.5z"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M243.1,75.5
							L243.1,75.5c0,0,21.6-0.8-7.1,28.6v0C238.9,96.6,241.3,87.2,243.1,75.5z"/>
						<polygon fill="#EFD3D3" points="282.5,171.1 250.8,182.4 275.2,168.1 	"/>
						<polygon fill="#EFD3D3" points="133.2,171.4 167.5,183.1 141,167.6 	"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M317.9,501.1
							c-1,15-2.8,29.9-2.8,29.9"/>
						<g>
							<path fill="#DDC7BF" d="M327.9,502.7c0,0-0.2,2.3-0.5,5.8c-0.2,1.7-0.4,3.8-0.6,5.9c-0.3,2.2-0.5,4.5-1,6.8
								c-0.2,1.2-0.7,2.3-1.1,3.3c-0.4,1.1-0.8,2.1-1.1,3.2c-0.3,1-0.7,2-1,3c-0.3,0.9-0.5,1.9-0.8,2.7c-0.2,0.8-0.4,1.6-0.6,2.3
								c-0.2,0.7-0.3,1.3-0.4,1.8c-0.2,1-0.4,1.5-0.4,1.5l0,0c-0.1,0.3-0.3,0.4-0.6,0.4c-0.3-0.1-0.4-0.3-0.4-0.6c0,0,0,0,0,0
								c0,0,0.1-0.6,0.4-1.6c0.1-0.5,0.3-1.1,0.5-1.8c0.2-0.7,0.4-1.5,0.7-2.3c0.3-0.8,0.5-1.7,0.9-2.7c0.3-0.9,0.7-1.9,1-3
								c0.4-1,0.8-2.1,1.2-3.1c0.4-1.1,0.9-2.1,1.1-3.2c0.5-2.2,0.8-4.5,1.1-6.7c0.3-2.2,0.6-4.2,0.8-5.9
								C327.6,505,327.9,502.7,327.9,502.7z"/>
						</g>
						<g>
							<path fill="#DDC7BF" d="M22.2,469.2c0,0,1.1,0.4,3,0.6c0.9,0.1,2,0.2,3.2,0.2c0.6,0,1.2-0.1,1.8-0.1c0.6-0.1,1.2-0.2,1.8-0.3
								l0.9-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.6-0.2,1.1-0.5,1.6-0.7c0.5-0.2,1-0.6,1.4-0.9c0.2-0.2,0.4-0.3,0.6-0.4
								c0.2-0.2,0.4-0.3,0.6-0.5c0.4-0.3,0.7-0.6,1-0.8c0.3-0.3,0.5-0.5,0.7-0.7c0.4-0.4,0.6-0.6,0.6-0.6s-0.2,0.2-0.6,0.7
								c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2,0.3-0.6,0.6-0.9,0.9c-0.2,0.2-0.4,0.3-0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.5c-0.4,0.3-0.9,0.7-1.4,0.9
								c-0.5,0.3-1.1,0.6-1.6,0.8c-0.3,0.1-0.6,0.2-0.9,0.4l-0.9,0.3c-0.6,0.2-1.2,0.3-1.9,0.4c-0.6,0.1-1.2,0.2-1.8,0.2
								c-1.2,0.1-2.3,0.1-3.3,0c-1-0.1-1.8-0.2-2.3-0.3c-0.3-0.1-0.5-0.1-0.7-0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.3-0.1-0.4-0.3-0.4-0.6
								C21.6,469.2,21.9,469.1,22.2,469.2C22.2,469.2,22.2,469.2,22.2,469.2z"/>
						</g>
						<path fill="#DDC7BF" d="M294.2,344.7c0,0,7.6,2.3,18.5,1.9"/>
						<path fill="#DDC7BF" d="M80.6,340.9c0,0,5.2,2.1,13.8,2.1"/>
						<g>
							<path fill="#DDC7BF" d="M283,171.9L247.7,183l35-12c0.3-0.1,0.5,0,0.6,0.3S283.2,171.8,283,171.9C283,171.9,283,171.9,283,171.9z"
								/>
						</g>
						<g>
							<path fill="#DDC7BF" d="M132.6,171.1l34.9,12L132.3,172c-0.3-0.1-0.4-0.4-0.3-0.6S132.3,171,132.6,171.1
								C132.6,171.1,132.6,171.1,132.6,171.1z"/>
						</g>
						<g>
							<path fill="#E5CAC6" d="M273.9,277c-0.6,0.6-1.3,1.2-1.9,1.8l-2.1,1.6c-0.7,0.6-1.4,1-2.2,1.4c-0.8,0.4-1.5,0.9-2.3,1.2
								c-0.8,0.3-1.6,0.7-2.3,1c-0.8,0.3-1.6,0.5-2.4,0.8l-1.2,0.4l-1.2,0.3c-0.8,0.2-1.6,0.4-2.4,0.5c-1.6,0.1-3.2,0.4-4.8,0.3
								c-0.8,0-1.6,0-2.3-0.1c-0.7-0.1-1.3-0.2-2-0.2c6.7,2.1,21.5,7.5,22.5,15.4c0,0,1.5-9.8,3.4-14.8c2.7-7.2,3.8-8.8,8-18.1
								c-0.1,0.2-0.2,0.4-0.3,0.5C278.5,271.8,276.4,274.6,273.9,277z"/>
						</g>
						<g>
							<path fill="#DDC7BF" d="M189.2,273.9c-0.3,0.3-0.6,0.7-1,1.1c-0.2,0.2-0.4,0.4-0.6,0.7c-0.2,0.2-0.5,0.5-0.8,0.7
								c-0.6,0.5-1.2,1.1-1.8,1.7c-0.7,0.5-1.5,1.1-2.4,1.7c-1.7,1.1-3.8,2.3-6.2,3.2c-0.6,0.3-1.2,0.4-1.8,0.7c-0.6,0.2-1.3,0.4-1.9,0.6
								c-0.7,0.2-1.4,0.3-2.1,0.4c-0.7,0.1-1.4,0.3-2.1,0.4c-0.7,0.1-1.5,0.1-2.2,0.2c-0.7,0.1-1.5,0-2.3,0c-1.5,0.1-3.1-0.2-4.7-0.3
								c-0.8-0.1-1.6-0.3-2.4-0.5l-1.2-0.3l-1.2-0.4c-0.8-0.3-1.6-0.5-2.3-0.8c-0.8-0.3-1.5-0.7-2.3-1c-0.8-0.3-1.5-0.8-2.2-1.2
								c-0.7-0.4-1.5-0.8-2.1-1.4l-2-1.5c-0.6-0.6-1.2-1.2-1.9-1.8c-2.5-2.3-4.6-5-6.3-7.9c-1.7-2.8-3.1-5.9-4-8.9
								c-0.8-3.1-1.3-6.1-1.2-9c0-1.5,0.3-2.8,0.5-4.2c0.1-0.7,0.3-1.3,0.5-2c0.2-0.6,0.3-1.3,0.6-1.9c0.8-2.4,1.7-4.6,2.5-6.6
								c0.4-1,0.7-1.9,1-2.7c0.2-0.4,0.3-0.8,0.4-1.2c0.1-0.4,0.2-0.8,0.3-1.1c0.2-0.7,0.4-1.4,0.5-1.9c0.1-0.6,0.2-1.1,0.3-1.5
								c0.1-0.8,0.2-1.2,0.2-1.2s-0.1,0.4-0.3,1.2c-0.1,0.4-0.2,0.9-0.3,1.4c-0.1,0.6-0.4,1.2-0.6,1.9c-0.1,0.4-0.2,0.7-0.4,1.1
								c-0.2,0.4-0.3,0.8-0.5,1.2c-0.3,0.8-0.7,1.7-1.1,2.7c-0.8,1.9-1.8,4.1-2.7,6.5c-0.2,0.6-0.4,1.2-0.6,1.9c-0.2,0.7-0.4,1.3-0.6,2
								c-0.2,1.4-0.6,2.8-0.6,4.3c-0.2,3,0.2,6.2,1,9.3c0.9,3.1,2.3,6.2,4,9.2c0.4,0.7,0.8,1.3,1.3,2c1.5,2.2,3.2,4.3,5.2,6.2
								c0.6,0.6,1.2,1.2,1.9,1.8l2.1,1.6c0.7,0.6,1.5,1,2.2,1.4c0.8,0.4,1.5,0.9,2.3,1.2c0.8,0.3,1.6,0.7,2.3,1c0.8,0.3,1.6,0.5,2.4,0.8
								l1.2,0.4l1.2,0.3c0.8,0.2,1.6,0.4,2.4,0.5c1.6,0.1,3.2,0.4,4.8,0.3c0.8,0,1.6,0,2.3-0.1c0.6-0.1,1.2-0.1,1.8-0.2
								c0.1,0,0.3,0,0.4,0c0.7-0.1,1.5-0.2,2.2-0.4c0.7-0.2,1.4-0.3,2.1-0.5c0.7-0.2,1.3-0.4,2-0.7c0.6-0.2,1.3-0.4,1.9-0.7
								c2.4-1,4.5-2.2,6.2-3.5c0.8-0.6,1.6-1.2,2.3-1.8c0.7-0.6,1.3-1.2,1.8-1.7c0.3-0.3,0.5-0.5,0.8-0.7c0.2-0.3,0.4-0.5,0.6-0.7
								C188.7,274.7,189,274.3,189.2,273.9c0.5-0.6,0.8-0.9,0.8-0.9S189.7,273.3,189.2,273.9z"/>
						</g>
						<path fill="#EFD3D3" d="M203.1,467.9c0,0-7.4,1.6-21.2-5.6c0,0,15.2,29,7.4,96c0,0,11.4-56,11.4-85.2"/>
						<path fill="#EFD3D3" d="M212.5,468c0,0,5.8,0.4,20.3-5.7c0,0-15.2,29-7.4,96c0,0-11.4-56-11.4-85.2"/>
						<path fill="#DDC7BF" d="M285.4,250.4c0-0.4-0.1-0.7-0.1-1.1l0-0.3l0-0.1l0-0.1l0-0.1l-0.1-0.5c-0.1-0.7-0.3-1.4-0.4-2.1
							c-0.2-0.7-0.4-1.3-0.6-2c-0.2-0.7-0.4-1.3-0.6-1.9c-0.9-2.4-1.9-4.6-2.7-6.5c-0.4-1-0.8-1.9-1.1-2.7c-0.2-0.4-0.3-0.8-0.5-1.2
							c-0.1-0.4-0.3-0.8-0.4-1.1c-0.2-0.7-0.5-1.3-0.6-1.9c-0.1-0.6-0.2-1.1-0.3-1.4c-0.2-0.8-0.3-1.2-0.3-1.2s0.1,0.4,0.2,1.2
							c0.1,0.4,0.2,0.9,0.3,1.5c0.1,0.6,0.3,1.2,0.5,1.9c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.4,0.3,0.8,0.4,1.2c0.3,0.9,0.6,1.8,1,2.7
							c0.8,2,1.7,4.1,2.5,6.6c0.2,0.6,0.4,1.2,0.6,1.9c0.2,0.6,0.4,1.3,0.5,2c0.1,0.7,0.2,1.4,0.3,2.1l0.1,0.5l0,0.1l0,0c0,0,0,0,0,0
							l0,0.1l0,0.3c0,0.4,0,0.7,0.1,1.1c0.1,2.9-0.3,6-1.2,9c-0.9,3.1-2.2,6.1-4,8.9c-1.8,2.8-3.8,5.6-6.3,7.9c-0.6,0.6-1.2,1.2-1.8,1.7
							l-2,1.5c-0.6,0.5-1.4,0.9-2.1,1.4c-0.7,0.4-1.4,0.9-2.2,1.2c-0.8,0.3-1.5,0.7-2.3,1c-0.8,0.3-1.6,0.5-2.3,0.8l-1.2,0.4l-1.2,0.3
							c-0.8,0.2-1.6,0.4-2.4,0.5c-1.6,0.1-3.1,0.4-4.7,0.3c-0.8,0-1.5,0.1-2.3,0c-0.7-0.1-1.5-0.1-2.2-0.2c-0.7,0-1.4-0.2-2.1-0.4
							c-0.7-0.1-1.4-0.3-2.1-0.4c-0.7-0.2-1.3-0.4-1.9-0.6c-0.6-0.2-1.3-0.4-1.8-0.7c-2.4-1-4.4-2.1-6.2-3.2c-0.8-0.6-1.6-1.2-2.4-1.7
							c-0.7-0.6-1.3-1.2-1.8-1.7c-0.3-0.3-0.5-0.5-0.8-0.7c-0.2-0.2-0.4-0.5-0.6-0.7c-0.4-0.4-0.7-0.8-1-1.1c-0.5-0.6-0.8-0.9-0.8-0.9
							s0.3,0.3,0.8,1c0.3,0.3,0.6,0.7,0.9,1.1c0.2,0.2,0.4,0.5,0.6,0.7c0.2,0.2,0.5,0.5,0.8,0.7c0.5,0.5,1.1,1.1,1.8,1.7
							c0.7,0.6,1.5,1.2,2.3,1.8c1.7,1.2,3.8,2.4,6.2,3.5c0.6,0.3,1.2,0.5,1.9,0.7c0.6,0.2,1.3,0.4,2,0.7c0.7,0.2,1.4,0.3,2.1,0.5
							c0.7,0.2,1.4,0.3,2.2,0.4c0.1,0,0.2,0,0.3,0c0.7,0.1,1.3,0.2,2,0.2c0.8,0.1,1.5,0,2.3,0.1c1.6,0.1,3.2-0.2,4.8-0.3
							c0.8-0.1,1.6-0.3,2.4-0.5l1.2-0.3l1.2-0.4c0.8-0.3,1.6-0.5,2.4-0.8c0.8-0.3,1.6-0.7,2.3-1c0.8-0.3,1.5-0.8,2.3-1.2
							c0.7-0.4,1.5-0.8,2.2-1.4l2.1-1.6c0.7-0.6,1.3-1.2,1.9-1.8c2.5-2.4,4.6-5.2,6.4-8.1c0.1-0.2,0.2-0.4,0.3-0.5
							c1.6-2.8,2.9-5.7,3.7-8.6C285.2,256.5,285.6,253.4,285.4,250.4z"/>
						<path fill="#E5CAC6" d="M164,286.2c-1.6,0.1-3.2-0.2-4.8-0.3c-0.8-0.1-1.6-0.3-2.4-0.5l-1.2-0.3l-1.2-0.4c-0.8-0.3-1.6-0.5-2.4-0.8
							c-0.8-0.3-1.6-0.7-2.3-1c-0.8-0.3-1.5-0.8-2.3-1.2c-0.7-0.4-1.5-0.8-2.2-1.4l-2.1-1.6c-0.7-0.6-1.3-1.2-1.9-1.8
							c-2-1.9-3.7-4-5.2-6.2c-1.2-1.6-1.8-2.8-1.8-2.8c4.3,9.5,5.4,11,8.1,18.4c1.9,5,3.4,14.8,3.4,14.8c0.9-7.8,15.7-13.3,22.4-15.3
							c-0.6,0.1-1.2,0.1-1.8,0.2C165.6,286.2,164.8,286.1,164,286.2z"/>
						<path fill="#EFD3D3" d="M187.5,140.5c7.9,2.1,24.8,5.4,39.4-9.2v-9.1c-8.4,10.6-16.8,11.6-19.4,11.7c0,0,0,0,0,0s0,0,0,0
							c-2.6,0-10.8-1-19.1-11.4v11.9C188.3,134.5,188.4,137.2,187.5,140.5z"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M187.8,120.9v13.6
							c0,0,0.6,13.8-12.2,19l-45.2,18.4c0,0-32.3-1.4-32.3,43.1c0,44.5-14.7,87.4-17.3,122.4c-0.6,7.8-7.4,14.3-12.3,25.1
							c-12.7,27.5-20.6,73.3-28.1,80.4C33.9,449,19.4,456.1,4.7,468c-1.1,0.9-2.7,1.3-3.4,1.7c-2.3,1.2,0.5,6.8,5.4,5.2s9.3-6.7,12.4-6
							c1,0.3,2,0.5,3,0.8c-0.6,1.3-1.7,7.3-3.1,14.8c-1.6,8.4-3,16.8-5.7,25.1c-2.5,7.8,4.5,8.2,5.8,6.2c1.6-2.5,8.1-30,8.1-30
							s-6.7,24.9-7.7,31.2c-1,6.3,5.5,6.9,6.8,2.8c2.1-6.5,8.2-31.9,8.2-31.9s-4.8,22.2-6.6,29.4c-1.6,6.5,5.7,6.9,6.7,2.5
							c1.3-5.6,8.4-31,8.4-31s-4.8,16.6-6.4,24.1c-0.6,2.7,5,4.4,6.6,0.8c0.8-1.8,10.3-29.2,11.2-35.5c1.1-7.2,3.7-14.9,6.6-21.7
							c3.7-8.8,7.8-16,9.3-19c13.1-26.2,29.2-46.4,37.9-73c4.7-14.4,5.4-16.3,12.5-38.2c4.4-13.6,12.6-41.2,11.8-61.8l6.7,14
							c15.4,26.8,2.5,74.8,0.9,79c-4.8,12.5-12.9,38.6-17.8,82.6c-7.3,65.8,15.9,145.7,7.4,208.4s-12,72.2-9.6,110.8
							c2.3,36.8-1.5,91.6-1.8,96.1c0,0-3.5,13.1-2.3,18.8c1.2,5.7-6.9,16.1-13.6,21.9c-6.7,5.7-0.9,7.3,1.9,7.9
							c2.2,0.5,34.5,6.5,37.4-6.5c0,0,0.8-2.2,1.2-15.7c0.3-7.8,2.6-8.1,1.1-14.8c0,0-2.2-8.2-2.2-11.2c0,0,14.1-83.1,21.6-108.7
							c4.8-16.6,7.1-23.7,7.7-76.7c0.3-20.8,8.3-19.7,8.3-54.9c0-23.3,21.6-88.5,21.6-141.2c0,0-0.4-7.6,6.8-7.6h0c7.1,0,6.8,7.6,6.8,7.6
							c0,52.8,21.6,115.8,21.6,141.2c0,35.2,8,34.1,8.3,54.9c0.7,53,2.9,60.1,7.7,76.7c7.2,24.6,20.5,102.9,21.5,108.4
							c0.2,2.6-2.2,11.5-2.2,11.5c-1.5,6.7,0.8,7,1.1,14.8c0.5,13.5,1.2,15.7,1.2,15.7c2.9,13,35.2,7,37.4,6.5c2.8-0.6,8.6-2.2,1.8-7.9
							S298,879.7,299.2,874c1.1-5.3-1.8-17-2.2-18.6c-0.3-3.2-4.2-59-1.8-96.3c2.4-38.6-1.1-48.2-9.6-110.8
							c-8.5-62.6,14.7-142.5,7.4-208.4c-4.9-44-13-70.2-17.8-82.6c-2.4-6.2-14-53.1,0.9-79l6.6-12.9c-0.7,20.6,6,48.9,7,63.2
							c1.8,25,5.4,37.7,9,52.4c6.4,25.8,12.3,70.3,14.4,78.9c2.1,8.5-7.7,30.9-5.9,37.3c1.8,6.4,1.4,25.8,1.6,31.2
							c0.3,5.3,6.4,2.7,6.4,2.7c0.3,3.5-8.5,17.3-8,20.5c0.5,3.2,4.5,3.7,5.6,0c1.1-3.7,13.6-22.1,15.5-25.8c1.9-3.7,1.3-10.9,3.5-16.8
							c2.1-5.9-0.5-44.8-0.5-44.8s-1.7-12.1-2-29.3c-0.3-19.6-1-45.8-2.1-67.9c-0.6-11.8,0.4-22.4-0.2-30.2c-2.5-35-9.9-77.4-9.9-121.9
							c0-44.5-32.3-43.1-32.3-43.1l-45.2-18.4c-12.8-5.2-12.2-19-12.2-19v-13.6h0"/>
						<path fill="#EFD3D3" d="M287,306.7c0.1,0.5,0.2,1,0.2,1.6c1.3,8.1,2.4,15.2,2.8,20.4c0.7,9.8,1.7,17.7,2.8,24.5
							c0.6,3.1,1.1,6.1,1.7,8.9c1.1,5.3,2.3,10.1,3.6,15.1c0.3,1.3,0.6,2.5,1,3.8c1.8,7.1,3.5,15.7,5.1,24.5c-1.8-18.2-2.9-44.1-2.7-52.7
							c0.2-9.4-13.7-55.3-17.6-68c0,0.1,0,0.2,0,0.3c0.2,1.4,0.3,2.8,0.5,4.2c0.5,3.8,1,7.6,1.6,11.2C286.4,302.6,286.8,304.7,287,306.7z
							"/>
						<path fill="#EFD3D3" d="M85.1,409.8c8.8-14.9,17.2-29,22.6-45.7l3.1-9.5c2.4-7.3,4.1-12.6,9.3-28.6c7.8-24.1,11.9-44.4,11.9-59.1
							c-2,13.4-9.2,53.6-26.7,89.5c-19.4,39.6-32.9,73.7-36,81.7c0.2-0.3,0.3-0.7,0.5-0.9C74.6,427.4,79.9,418.5,85.1,409.8z"/>
						<path fill="none" d="M188.7,182.4c-0.5-0.1-1-0.1-1.6-0.2C187.7,182.3,188.2,182.4,188.7,182.4z"/>
						
							
						<path opacity="0.9" fill="url(#SVGID_1_)" enableBackground="new    " d="M189.2,182.5c-0.1,0-0.3,0-0.4-0.1
							C188.9,182.5,189,182.5,189.2,182.5z"/>
						
							
						<path opacity="0.9" fill="url(#SVGID_2_)" enableBackground="new    " d="M189.2,182.5c-0.1,0-0.3,0-0.4-0.1
							C188.9,182.5,189,182.5,189.2,182.5z"/>
						<g>
							<path fill="#39414C" d="M233,23.9c0,2.9-50.9,7.1-50.9,2.7c0-3.3,0.4-9.7,1.6-12.5C187.2,5.4,197.7,0,207.8,0
								c9.6,0,17.4,3.5,21.3,11.5C230.6,14.7,233,20.2,233,23.9z"/>
							<g>
								<path fill="none" d="M190.2,5.9"/>
							</g>
							<g>
								<path fill="#4E5663" d="M197,2.5c0,0,0.8,0.3,2.1,0.9c1.3,0.6,3.2,1.5,5.4,2.7c1.1,0.6,2.3,1.3,3.5,2c1.2,0.7,2.5,1.6,3.8,2.5
									c1.3,0.9,2.6,1.9,3.9,2.9c1.3,1,2.6,2.1,3.9,3.2c1.2,1.1,2.4,2.4,3.5,3.6c0.6,0.6,1.1,1.3,1.6,1.9c0.5,0.7,1,1.3,1.4,2
									c0.9,1.3,1.6,2.7,2.2,4c0.1,0.3,0.3,0.7,0.4,1c0.1,0.3,0.2,0.7,0.3,1c0.1,0.3,0.2,0.6,0.3,1c0.1,0.3,0.1,0.6,0.2,1
									c0.2,0.6,0.2,1.2,0.3,1.8c0,0.3,0,0.6,0.1,0.8c0,0.3,0,0.5,0,0.8c0,1-0.2,1.9-0.3,2.6c-0.1,0.7-0.4,1.3-0.5,1.6
									c-0.1,0.4-0.2,0.6-0.2,0.6s0-0.2,0.1-0.6c0.1-0.4,0.2-0.9,0.3-1.7c0.1-0.7,0.2-1.6,0.1-2.6c0-0.3,0-0.5,0-0.8
									c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.6-0.1-1.1-0.3-1.7c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.2-0.6-0.3-1
									c-0.1-0.3-0.3-0.6-0.4-0.9c-0.6-1.3-1.4-2.6-2.2-3.8c-0.4-0.6-0.9-1.3-1.4-1.9c-0.5-0.6-1-1.2-1.6-1.8c-1.1-1.2-2.3-2.4-3.5-3.5
									c-1.2-1.1-2.5-2.2-3.8-3.2c-1.3-1-2.6-2-3.9-2.9c-1.3-0.9-2.5-1.8-3.7-2.5c-1.2-0.8-2.4-1.5-3.4-2.2c-2.1-1.3-4-2.4-5.2-3
									C197.8,2.9,197,2.5,197,2.5z"/>
							</g>
						</g>
						<g>
							<path fill="#4E5663" d="M183.6,115.6c-1.8-3.2-3.6-6.9-5.2-11.3c-3.1-3.1-5.6-6-7.8-8.7C171.7,99.8,175.4,110.8,183.6,115.6z"/>
						</g>
						<g>
							<path fill="#4E5663" d="M236.5,104.3c-1.6,4.3-3.4,8-5.1,11.2c7.9-4.8,11.5-15.3,12.7-19.6C242,98.5,239.5,101.3,236.5,104.3z"/>
						</g>
						<g>
							<path fill="#4E5663" d="M251.2,36.8c-2.9-7.4-11.7-26.5-37-29.2c-2.4-0.2-4.7-0.3-6.7-0.3c-0.1,0-0.1,0-0.2,0v0
								c-0.1,0-0.2,0-0.4,0c-30,0-40,21.5-43.2,29.5c-6.8,17.1-0.5,38.8,0.4,41.7c1.7-3.3,6.3-3.6,7.4-3.6c-0.3-2-1.4-8.2-0.8-8.5
								c21.8-12.4,33.9-33.6,33.9-33.6c-6.7,21.8-23.2,29.9-25.8,31.1c18-7.4,25.6-22.3,28.4-28.5c0,0,1.3-3,1.5-2.7
								c1,2.5,2.5,4.7,4.2,6.8c1.8,2.2,3.9,4.3,6.2,6.2c10.7,8.9,24.5,14.1,25.3,19.1c0.1,0.6-0.4,6.1-0.9,10c0,0,0,0,0,0.1
								c1.3,0,5.7,0.4,7.3,3.6c0.2-0.5,0.5-1.7,0.9-3.3c1-3.9,2.3-10.5,2.5-18C254.7,50,251.7,38,251.2,36.8z"/>
						</g>
					</g>
					<g id="front_layer13">
						<path fill="#FFFFFF" d="M292.9,824.5c-3.8-18.5-8.6-41.6-8.5-61.4c0-1.9,0.1-3.8,0.2-5.6c0.5-7.7,0.7-14,0.7-19.8
							c0.1-17.5-1.8-30.7-5.6-56.8l-0.1-0.6c-1.3-9.2-2.8-19.5-4.5-32.2c-1.3-9.7-2-20.1-2-31.9c0-24.2,2.7-49.8,5.3-74.6
							c2.4-22.4,4.8-45.5,5.3-67.5l0-0.9l0-0.2l-0.2-22c0.8,0,1.7,0.1,2.6,0.2c2.2,0.2,4.2,0.4,6.1,0.8c0.3,5.8,0.5,12,0.4,18.8
							c-0.1,23.8-2.8,48.8-5.4,73.1c-2.5,23.4-5.1,47.6-5.3,70c-0.1,13.2,0.5,24.6,1.9,34.7c1.7,12.8,3.3,23.3,4.6,32.5l0,0.2
							c4.2,28.8,6.1,42,5.6,62.3c-0.1,4.3-0.3,9-0.7,14.3c-0.2,3.8-0.4,8.1-0.5,12.6c-0.6,19.5,0.1,41.8,0.8,58.3
							C293.5,827.3,293.2,825.9,292.9,824.5z"/>
					</g>
					<g id="front_layer21">
						<g>
							<g>
								<path fill="#FFFFFF" d="M295.9,841.7l-0.7-8.6c0.2,3.1,0.3,6,0.4,8.6H295.9z"/>
								<path fill="#FFFFFF" d="M295.9,841.7l-0.7-8.6c0.2,3.1,0.3,6,0.4,8.6H295.9z"/>
								<path fill="#FFFFFF" d="M224.8,466.5c-0.7,0.4-1.4,0.7-2,1.1C223.4,467.3,224.1,466.9,224.8,466.5z"/>
								<path fill="#FFFFFF" d="M224.8,466.5c-0.7,0.4-1.4,0.7-2,1.1C223.4,467.3,224.1,466.9,224.8,466.5z"/>
								<path fill="#FFFFFF" d="M272.6,840.6c-0.2-2.6-0.5-5.7-0.7-9.5c-0.4-5.4-0.7-11.5-1.1-18c-1.4-25.7-3.2-57.7-7.5-72.4l-0.1-0.5
									c-4.4-15.2-6.5-22.2-7.1-73c-0.1-10.7-2.1-16.9-3.9-22.8c-2.1-6.8-4.3-13.7-4.3-31.9c0-10.6-3.5-26.7-7.8-47
									c-4.9-22.9-11.1-51.4-13.1-77.5l0-0.2c-0.3-3.5-0.4-6.6-0.5-9.5l-0.5-11c2.4-1.3,4.8-2.5,7.2-3.6c12.1-5.7,30.3-12.6,47.9-12.6
									c0.1,0,0.2,0,0.2,0l0.2,22l0,0.2l0,0.9c-0.4,21.9-2.9,45-5.3,67.3c-2.6,24.8-5.4,50.5-5.4,74.8c0,11.9,0.7,22.4,2,32.2
									c1.7,12.7,3.2,23.1,4.5,32.3l0.1,0.6c3.8,26,5.6,39.1,5.6,56.5c0,5.8-0.2,12-0.7,19.7c-0.1,1.8-0.2,3.7-0.2,5.7
									c-0.1,20,4.7,43.2,8.6,61.8c1.2,6,2.3,11.2,3.1,15.7H272.6z"/>
							</g>
							<path opacity="5.000000e-02" enableBackground="new    " d="M224.8,466.8c2.4-1.2,5.1-2.5,8-3.8h0c-1,2.3-4.5,10.6-6.9,25.7
								c-0.3-3.5-0.5-7-0.6-10.5L224.8,466.8z"/>
						</g>
					</g>
					<g id="front_layer14">
						<g>
							<path fill="#FFFFFF" d="M269,820.8c-6.9-37.6-12-62.6-15.5-74.3l-0.1-0.5c-4.7-16.2-6.9-23.6-7.5-75.8c-0.1-9.2-1.7-14.1-3.4-19.8
								c-2.3-7.2-4.8-15.3-4.8-35.1c0-10.6-3.7-27.6-7.9-47.3c-1.4-6.3-2.7-12.5-4-18.8l0-0.2c-0.6-3-1.2-6-1.8-9.1l-0.1-0.3
								c-0.3-1.4-0.6-2.9-0.8-4.3l-0.1-0.4c-0.3-1.4-0.5-2.8-0.8-4.2l-0.2-0.9c-0.2-1.3-0.5-2.5-0.7-3.8l-0.3-1.7c-0.2-1-0.3-2-0.5-3.1
								l-0.3-1.7c-0.2-1-0.3-2-0.5-3l-0.3-2.3c-0.1-0.8-0.2-1.7-0.4-2.5l-0.3-2.4c-0.1-0.8-0.2-1.6-0.3-2.4c0-0.4-0.3-2.7-0.3-2.7
								c-0.1-0.7-0.2-1.4-0.2-2.1c-0.1-0.5-0.1-1-0.2-1.5l-0.2-1.6c-0.1-0.5-0.1-1.1-0.2-1.6c0-0.5-0.1-0.9-0.1-1.4l-0.1-1.6
								c-0.1-0.6-0.1-1.2-0.2-1.7c0-0.5-0.1-1.1-0.1-1.6l-0.1-1.5c0-0.5-0.1-1.1-0.1-1.6c0-0.6-0.1-1.3-0.1-1.9l-0.1-1.5
								c0-0.4,0-0.8-0.1-1.3c0-0.7,0-1.3-0.1-2l0-1.4c0-0.4,0-0.8,0-1.2c0-1.5-0.1-3.1-0.1-4.6c0-0.1,0-0.6-0.1-1.2
								c1-0.7,3.3-2.1,7.7-4.5l0.4,9.9c0.1,2.9,0.3,6,0.5,9.5l0,0.2c2,26.4,8.2,55,13.1,78c4.4,20.2,7.8,36.2,7.8,46.5
								c0,18.5,2.2,25.7,4.4,32.6c1.8,5.8,3.7,11.8,3.8,22.2c0.6,51.1,2.7,58.2,7.2,73.6l0.1,0.5c4.2,14.4,6,46.3,7.4,71.9
								C268.7,815.8,268.8,818.3,269,820.8z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M225.8,487.8c-0.2-3.2-0.4-6.4-0.5-9.5l-0.5-11.7
								c-5.5,3-8.7,4.9-10,5.8c0.1,0.8,0.1,1.7,0.1,1.8c0,1.5,0,3.1,0.1,4.6c0,0.6,0,1.3,0.1,2c0,0.9,0.1,1.8,0.1,2.7
								c0,0.7,0.1,1.4,0.1,2.1c0,0.9,0.1,1.7,0.1,2.6c0,0.8,0.1,1.6,0.2,2.4c0.1,0.8,0.1,1.6,0.2,2.4c0.1,0.8,0.2,1.7,0.2,2.5
								c0.1,0.7,0.1,1.5,0.2,2.2c0.1,0.8,0.2,1.6,0.3,2.4c0.1,0.8,0.2,1.6,0.2,2.3c0.1,0.9,0.2,1.9,0.3,2.8c0.1,0.7,0.2,1.3,0.2,2
								c0.1,1,0.3,2,0.4,3c0.1,0.6,0.2,1.2,0.2,1.8c0.1,1.1,0.3,2.1,0.5,3.2c0.1,0.5,0.2,1.1,0.2,1.6c0.2,1.1,0.3,2.2,0.5,3.3
								c0.1,0.5,0.1,1,0.2,1.4c0.2,1.1,0.4,2.3,0.6,3.4c0.1,0.4,0.1,0.9,0.2,1.3c0.2,1.3,0.4,2.6,0.7,3.8c0.1,0.3,0.1,0.6,0.2,0.9
								c0.3,1.4,0.5,2.8,0.8,4.2c0,0.1,0.1,0.3,0.1,0.4c0.3,1.5,0.6,2.9,0.8,4.3c0,0.1,0,0.2,0.1,0.3c0.6,3.1,1.2,6.1,1.8,9.1v0
								c0,0,0,0.1,0,0.1C222.1,522.2,223.5,501.9,225.8,487.8z"/>
						</g>
					</g>
					<g id="front_layer18">
						<path fill="#FFFFFF" d="M145.9,821.9c0.2-2.8,0.3-5.8,0.5-8.8c1.4-25.6,3.2-57.4,7.4-71.9l0.1-0.5c4.5-15.4,6.6-22.5,7.2-73.6
							c0.1-10.4,2-16.4,3.8-22.2c2.2-6.9,4.4-14.1,4.4-32.6c0-10.4,3.4-26.3,7.8-46.5c4.9-22.8,11-51.1,13-77.2c0.3-3.7,0.5-7.2,0.6-10.5
							l0.5-9.6c0.3,0.2,0.6,0.3,0.9,0.5c1.9,1.1,3.4,1.9,4.6,2.6c1.1,0.6,1.9,1,2.4,1.4c-0.1,0.6,0,1.1,0,1.1c0,1.5,0,3.1-0.1,5.2l0,1.1
							c0,1.6-0.1,3.2-0.2,4.8c0,0.6-0.1,1.3-0.1,1.9c-0.1,1.2-0.1,2.3-0.2,3.5c-0.1,1.2-0.2,2.4-0.3,3.6l-0.2,1.8
							c-0.2,2.1-0.4,4.3-0.7,6.4c-1,8-2.3,17-3.9,26.7c-1,5.8-2.2,12-3.6,18.9l0,0.2c-0.4,2-0.8,3.9-1.2,5.8l2.2,0.3l-2.2-0.2
							c-1.2,5.6-2.3,11-3.5,16.2c-4.1,19-7.6,35.3-7.6,44.8c0,15.8-1.7,24.1-3.1,29.5c-0.3,1-0.6,2-0.9,2.9c-0.6,1.8-1.1,3.4-1.5,5.2
							c-1.3,4.2-2.6,9.1-2.7,17.2c-0.6,46.6-2.3,57.4-6.1,71l-0.8,2.6c-0.2,0.6-0.3,1.1-0.5,1.7l-0.3,0.9
							C157.3,761.3,150.7,796,145.9,821.9z"/>
						<path opacity="5.000000e-02" enableBackground="new    " d="M192.7,468.1c-0.8-0.4-1.6-0.8-2.5-1.3l-0.5,11.4
							c-0.1,3.5-0.3,7-0.6,10.5c2.4,15,3.7,36.6,0.6,65.8c0.4-2,0.8-3.9,1.2-5.9c0-0.1,0-0.1,0-0.2c1.2-6.2,2.5-12.5,3.6-18.9
							c0,0,0,0,0,0c1.4-8.3,2.8-17.6,3.9-26.7c0.2-2.2,0.5-4.3,0.7-6.5c0.1-0.6,0.1-1.2,0.2-1.8c0.1-1.2,0.2-2.4,0.3-3.6
							c0.1-1.2,0.2-2.4,0.2-3.5c0-0.6,0.1-1.3,0.1-1.9c0.1-1.6,0.2-3.2,0.2-4.8c0-0.4,0-0.8,0-1.1c0-1.8,0.1-3.6,0.1-5.3
							c0-0.1,0-0.7,0.1-1.5c0,0,0,0,0,0C199.5,471.8,197.1,470.6,192.7,468.1z"/>
					</g>
					<g id="front_layer22">
						<path fill="#FFFFFF" d="M120.8,842.9l0.2-1c0.8-4.7,2-10.4,3.3-16.9c4.1-19.6,9.7-46.5,8.4-67.6c-1.8-29.4-0.1-41,4.9-75.6l0.1-0.5
							c1.3-9.2,2.8-19.6,4.6-32.3c4.5-33.3,0.5-71-3.3-107.6c-2.4-22.3-4.8-45.4-5.3-67.3l0.2-22l1.8,0c0.3,0,1,0,1.4,0
							c16.7,0.6,33.6,6.8,44.9,11.9c2.7,1.2,5.2,2.4,7.3,3.5l-0.5,10.7c-0.1,3.3-0.3,6.7-0.6,10.4c-2,26-8.1,54.2-13,76.9
							c-4.4,20.3-7.8,36.4-7.8,47c0,18.2-2.2,25.2-4.3,31.9c-1.9,5.9-3.8,12.1-3.9,22.8c-0.6,50.8-2.7,57.8-7.1,73l-0.1,0.5
							c-4.3,14.7-6.1,46.7-7.5,72.4c-0.4,6.5-0.7,12.7-1.1,18c-0.3,5.1-0.6,8.8-1,11.8L120.8,842.9L120.8,842.9z"/>
					</g>
					<g id="front_layer12">
						<path fill="#FFFFFF" d="M121.4,827.5c0.9-20.3,1.6-47.1,0.3-68.4c-1.9-30.5-0.2-42.4,5-77.8c1.3-9.3,2.9-19.7,4.6-32.7
							c4.4-32,0.4-69.1-3.4-105c-3.2-30.3-6.5-61.6-5-90.4c1.9-0.3,3.8-0.6,5.9-0.8c1-0.1,1.8-0.1,2.6-0.2l-0.2,21.9
							c0.4,22,2.9,45.2,5.3,67.5c3.9,36.4,7.8,74,3.4,107c-1.7,12.7-3.2,23.1-4.6,32.3l-0.1,0.5c-5,34.8-6.7,46.5-4.9,76.1
							c1.3,20.8-4.3,47.5-8.3,67C121.8,825.5,121.6,826.5,121.4,827.5z"/>
					</g>
					<g id="front_layer16">
						<path opacity="5.000000e-02" enableBackground="new    " d="M182,462.3c0,0,0.1,0.2,0.3,0.7h0c4,1.8,7.6,3.6,10.4,5.1
							c4.1,2.2,6.9,3.9,7.7,4.4c0.2-1.2,0.6-2.8,1.7-4.1c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0.1-0.1C200.2,468.2,193.2,468.2,182,462.3z"/>
						<path opacity="5.000000e-02" enableBackground="new    " d="M212.9,468c1.2,1.3,1.7,3,1.9,4.3c1.3-0.8,4.8-3,10-5.8
							c2.3-1.2,5-2.6,7.9-4c0.1-0.1,0.1-0.2,0.1-0.2C220.4,467.5,214.4,468,212.9,468z"/>
						<path fill="#FFFFFF" d="M199.6,470.7c-1.4-0.8-3.6-2.1-6.4-3.6c-2.7-1.5-6.2-3.2-10.5-5.2c-11.5-5.2-28.7-11.5-45.8-12.1
							c-0.4,0-1.1,0-1.4,0l-1.5,0l-0.5,0c-0.2,0-1.1,0-1.1,0c-0.3,0-0.6,0-0.9,0c-0.2,0-1.1,0.1-1.1,0.1c-0.3,0-0.6,0-0.9,0
							c-0.2,0-0.5,0-0.7,0.1l-0.5,0c-0.2,0-0.5,0-0.7,0.1c-0.3,0-0.6,0.1-0.8,0.1c0,0-0.9,0.1-1.1,0.1c-0.4,0-0.7,0.1-1.1,0.2
							c0,0-0.7,0.1-0.9,0.1c-0.2,0-0.4,0.1-0.6,0.1c0.2-3.7,0.5-7.2,0.9-10.7c2.4-21.8,5.9-41.5,10.3-58.6c0,0,0.5,0.3,0.7,0.4l0.4,0.2
							c0.3,0.1,0.5,0.3,0.8,0.4l0.5,0.2c0.3,0.1,0.6,0.3,1,0.4l0.5,0.2c0.4,0.1,0.7,0.3,1.1,0.5l0.5,0.2c0.4,0.1,0.8,0.3,1.1,0.4
							c5.5,1.9,14.3,4.2,25.4,4.2h82.7c0.6,0,1.2,0,1.8,0l0.5,0c0.5,0,0.9,0,1.2,0c0,0,1.4-0.1,1.7-0.1c0,0,1.4-0.1,1.7-0.1
							c0,0,1.3-0.1,1.6-0.2c0,0,1.3-0.1,1.6-0.2c0,0,1.2-0.2,1.5-0.2c0,0,1.2-0.2,1.5-0.2c0,0,1.1-0.2,1.4-0.2l1.7-0.3
							c0.3-0.1,0.7-0.1,1-0.2l1.6-0.4c0.3-0.1,1.2-0.3,1.2-0.3c0.3-0.1,1.1-0.3,1.1-0.3c0.4-0.1,1.2-0.3,1.2-0.3c0.3-0.1,1.1-0.3,1.1-0.3
							c0.3-0.1,1-0.3,1-0.3l1.8-0.6l0,0c0.6-0.2,1.1-0.4,1.7-0.6l0.9-0.4l0.7-0.3l1.1-0.5l1-0.5l0.3-0.2c4.4,17.2,7.9,36.9,10.3,58.7
							c0.3,2.9,0.6,6,0.8,9.5c-3.1-0.5-6.3-0.9-9.7-0.9c-0.5,0-0.9,0-1.4,0c-18,0-36.5,7-48.9,12.8c-2.7,1.3-5.3,2.6-7.9,4
							c-3.9,2.1-6.9,3.9-8.7,5c-0.3-1.1-0.9-2.3-1.8-3.3c0,0-0.1-0.1-0.1-0.1c-1.5-1.5-3.5-2.3-6-2.3c-0.5,0-0.9,0-1.4,0.1
							c-0.2,0-0.4,0.1-0.6,0.1l-0.2,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.2,0.1-0.3,0.1
							c-0.3,0.2-0.6,0.3-0.9,0.5l-0.1,0.1c-0.2,0.2-0.5,0.3-0.7,0.6c-0.1,0.1-0.2,0.2-0.3,0.3l-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
							C200.4,468.6,199.9,469.7,199.6,470.7z"/>
					</g>
					<g id="front_layer7">
						<path fill="#FFFFFF" d="M327.4,430c-2.7-17.9-5.9-40.3-8.2-58.8c-1.9-15.9-2.1-28.9-2.2-33.2c0-0.8,0-1.3,0-1.4
							c-0.9-11.8-2.3-24.6-3.8-38c-2.8-25-6-53.2-6.1-82.3c2.6-0.8,4.8-2,6.5-3.6c0.1-0.1,0.3-0.3,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3
							c0.4-0.4,0.7-0.7,1-1.1c0.1,1.3,0.1,2.7,0.1,4c0,29.3,3.2,58.1,6.1,83.5c1.5,13.6,3,26.4,3.8,38.5c0.3,3.7,0.2,7.9,0.1,12.9
							c-0.1,5.2-0.2,11.1,0.1,17.3C326.4,387.7,327,411.9,327.4,430z"/>
					</g>
					<g id="front_layer23">
						<path fill="#FFFFFF" d="M314.4,456.4c0.6-9.8,2.4-46.1-5-77.1c-2.3-9.5-4.1-20-5.4-31c-1.1-9.1-1.6-16.5-1.9-20.9l0-0.6
							c-0.4-5.5-1.5-12.6-2.7-20.8l0-0.1c-1.9-12.1-8-75-9.2-87.5l-0.1-0.7c5.1-0.1,8.5-0.1,10.2-0.2c1.6-0.1,3.2-0.3,4.6-0.6
							c0.2,29,3.3,57.1,6.1,81.9c1.5,13.6,2.9,26.3,3.8,38c0,0.2,0,0.6,0,1.3c0.1,4.3,0.3,17.5,2.2,33.5c3.1,25.9,8.2,59.2,11,77.3
							L314.4,456.4z"/>
					</g>
					<g id="front_layer9">
						<g>
							<path fill="#FFFFFF" d="M312.5,448.7c-0.5-3-1.1-6.5-1.7-10.3c-1.6-9.7-3.5-21.7-5.6-33.1c-1.8-10.1-3.5-18.1-5.1-24.6l-1-3.8
								c-1.3-5-2.4-9.7-3.5-15c-0.6-2.9-1.1-5.9-1.7-8.9c-1.2-7.5-2.2-15.5-2.8-24.4c-0.4-5.2-1.5-12.3-2.8-20.4l-0.3-1.6
								c-0.2-1.8-0.5-3.8-1-6.2c-0.7-4.3-1.2-7.8-1.6-11.1l-0.1-0.7c-0.1-1.2-0.3-2.3-0.4-3.5l-0.1-0.8c-0.5-4.8-0.9-9-1-12.9
								c-0.1-2.7-0.1-5,0-7.2c0-0.1,0-0.3,0-0.4l0-0.5c3.4-7.4,5-18,5.6-27.2c2.3,23.6,6.1,61,7.5,70.1l0,0.1c1.2,8.1,2.3,15.2,2.7,20.6
								l0,0.6c0.3,4.4,0.8,11.9,1.9,21c1.4,11.2,3.2,21.7,5.5,31.3C313.2,405,313.1,433.8,312.5,448.7z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M287,306.7c0.1,0.5,0.2,1,0.2,1.6c1.3,8.1,2.4,15.2,2.8,20.4
								c0.7,9.8,1.7,17.7,2.8,24.5c0.6,3.1,1.1,6.1,1.7,8.9c1.1,5.3,2.3,10.1,3.6,15.1c0.3,1.3,0.6,2.5,1,3.8c1.8,7.1,3.5,15.7,5.1,24.5
								c-1.8-18.2-2.9-44.1-2.7-52.7c0.2-9.4-13.7-55.3-17.6-68c0,0.1,0,0.2,0,0.3c0.2,1.4,0.3,2.8,0.5,4.2c0.5,3.8,1,7.6,1.6,11.2
								C286.4,302.6,286.8,304.7,287,306.7z"/>
						</g>
					</g>
					<g id="front_layer11">
						<g>
							<g>
								<path fill="#FFFFFF" d="M251.1,285.4c1.5,0.1,3.1-0.2,4.7-0.3c0.8-0.1,1.6-0.3,2.4-0.5l1.2-0.3l1.2-0.4c0.8-0.3,1.6-0.5,2.3-0.8
									c0.8-0.3,1.5-0.7,2.3-1c0.8-0.3,1.5-0.8,2.2-1.2c0.7-0.4,1.5-0.8,2.1-1.4l0.6-0.5c-6.6,4.9-14.7,7-22.6,6.2
									c0.5,0,0.9,0.1,1.4,0.1C249.5,285.4,250.3,285.3,251.1,285.4z"/>
								<path fill="#FFFFFF" d="M270.2,278.9l1.1-0.9c0.3-0.3,0.6-0.6,0.9-0.8C271.6,277.7,270.9,278.3,270.2,278.9z"/>
								<path fill="#FFFFFF" d="M247.2,285.2c-0.5,0-0.9-0.1-1.4-0.2c0,0,0,0,0,0c0.3,0,0.5,0.1,0.8,0.1
									C246.8,285.1,247,285.1,247.2,285.2z"/>
								<path fill="#FFFFFF" d="M277.7,271.1c-1,1.3-2,2.6-3.1,3.7C275.7,273.7,276.7,272.4,277.7,271.1z"/>
								<path fill="#FFFFFF" d="M284.7,250.4c0-0.5,0-0.9-0.1-1.4C284.6,249.5,284.7,250,284.7,250.4
									C284.7,250.5,284.7,250.4,284.7,250.4z"/>
								<path fill="#FFFFFF" d="M278.8,269.4c0.2-0.4,0.5-0.7,0.7-1.1c0.5-0.9,1-1.8,1.5-2.7l0-0.1C280.4,266.9,279.7,268.2,278.8,269.4z
									"/>
								<path fill="#FFFFFF" d="M283.5,259.5C283.5,259.5,283.5,259.5,283.5,259.5c0.1-0.2,0.1-0.3,0.1-0.4
									C283.6,259.3,283.5,259.4,283.5,259.5z"/>
								<path fill="#FFFFFF" d="M239.9,386.2l0-0.2c2.2-12,3.5-19.2,6.1-70.3c0.1-2.2,0.4-12.6,0.8-28.6l1.7,0.2c0.6,0.1,1.1,0.1,1.6,0.1
									c0.3,0,0.5,0,0.8,0c0.2,0,0.5,0,0.8,0c1,0,2-0.1,2.9-0.2c0.4,0,0.8-0.1,1.3-0.1c0.6-0.1,1.2-0.2,1.7-0.3c0.3-0.1,0.6-0.1,0.8-0.2
									l1.3-0.3l1.3-0.4c0.3-0.1,0.6-0.2,0.8-0.3c0.5-0.2,1.1-0.3,1.6-0.6l2.4-1.1c0.6-0.2,1.1-0.5,1.6-0.9c0.2-0.1,0.5-0.3,0.7-0.4
									l0.5-0.3c0.6-0.4,1.2-0.7,1.8-1.2l2.1-1.5c0.5-0.4,0.9-0.8,1.4-1.3l0,0c-0.5,0.9-0.9,1.8-1.3,2.7c-12.6,27.3-1.3,71.5,0.8,76.8
									c1.6,4.2,4.1,11.3,6.8,21.3l-1.2,0.6l-1.1,0.5l-0.5,0.2l-0.8,0.3l-0.7,0.3l-0.8,0.3l-0.7,0.3c-0.3,0.1-1,0.4-1,0.4
									c-0.3,0.1-0.9,0.3-0.9,0.3c-0.3,0.1-0.6,0.2-0.9,0.3l-1.2,0.4c-0.3,0.1-1.1,0.3-1.1,0.3c-0.3,0.1-1.2,0.3-1.2,0.3
									c-0.3,0.1-1.2,0.3-1.2,0.3c-0.3,0.1-1.3,0.3-1.3,0.3c-0.3,0.1-1.3,0.3-1.3,0.3c-0.3,0.1-1.4,0.3-1.4,0.3c-0.3,0.1-0.7,0.1-1,0.2
									l-0.4,0.1c-0.3,0.1-1.4,0.2-1.4,0.2c-0.3,0-1.5,0.2-1.5,0.2c-0.3,0-1.5,0.2-1.5,0.2c-0.3,0-1.6,0.1-1.6,0.1c-0.3,0-0.7,0-1,0.1
									l-0.6,0c-0.3,0-0.6,0-1,0.1l-0.7,0c-0.3,0-0.7,0-1,0l-0.7,0c-0.6,0-1.2,0-1.8,0L239.9,386.2L239.9,386.2z"/>
								<path fill="#FFFFFF" d="M286,246.7c-0.1-0.3-0.1-0.6-0.1-0.8c-0.1-0.5-0.3-1-0.4-1.4c-0.1-0.2-0.1-0.4-0.2-0.7
									c-0.2-0.7-0.4-1.4-0.7-2c-0.7-1.9-1.4-3.6-2.1-5.1c-0.2-0.5-0.4-0.9-0.6-1.4c-0.4-0.9-0.7-1.8-1.1-2.6l-0.5-1.3l-0.3-1.1
									c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.4-0.2-0.7-0.3-1l-0.6-2.6l-0.1,0c-0.1-1.7,0-3,0-3.1l-2.3-0.3c0,0.1-0.5,2.3-2.6,3.4
									c-1.8,0.9-6.1,1.3-12.8,1.3c-3.2,0-6.5-0.1-9.4-0.2c-1.2,0-2.4-0.1-3.4-0.1c0.1-4.4,0.1-7.5,0.2-9.3l39.2,0
									C287.8,221,288,234.3,286,246.7z"/>
							</g>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M246.9,285.9c0.6,0.1,1.2,0.1,1.8,0.2
								c0.8,0.1,1.5,0,2.3,0.1c1.6,0.1,3.2-0.2,4.8-0.3c0.8-0.1,1.6-0.3,2.4-0.5l1.2-0.3l1.2-0.4c0.8-0.3,1.6-0.5,2.4-0.8
								c0.8-0.3,1.6-0.7,2.3-1c0.8-0.3,1.5-0.8,2.3-1.2c0.7-0.4,1.5-0.8,2.2-1.4l2.1-1.6c0.7-0.6,1.3-1.2,1.9-1.8
								c1.8-1.7,3.4-3.7,4.8-5.7l-3.3,6.9c-3.5,6.2-5.6,13.5-6.6,21.2C266,292.5,253.1,287.8,246.9,285.9z"/>
						</g>
						<path opacity="0.1" fill="#020202" enableBackground="new    " d="M226.9,275c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.5,0.5,0.8,0.7
							c0.6,0.5,1.2,1.1,1.8,1.7c0.7,0.5,1.5,1.1,2.4,1.7c1.7,1.1,3.8,2.3,6.2,3.2c0.6,0.3,1.2,0.4,1.8,0.7c0.6,0.2,1.3,0.4,1.9,0.6
							c0.7,0.2,1.4,0.3,2.1,0.4c0.7,0.1,1.4,0.3,2.1,0.4c0.7,0.1,1.5,0.1,2.2,0.2c0.7,0.1,1.5,0,2.3,0c1.5,0.1,3.1-0.2,4.7-0.3
							c0.8-0.1,1.6-0.3,2.4-0.5l1.2-0.3l1.2-0.4c0.8-0.3,1.6-0.5,2.3-0.8c0.8-0.3,1.5-0.7,2.3-1c0.8-0.3,1.5-0.8,2.2-1.2
							c0.7-0.4,1.5-0.8,2.1-1.4l2-1.5c0.6-0.6,1.2-1.2,1.9-1.8c2.5-2.3,4.6-5,6.3-7.9c1.7-2.8,3.1-5.9,4-8.9c0.8-3.1,1.3-6.1,1.2-9
							c0-1.5-0.3-2.8-0.5-4.2c-0.1-0.7-0.3-1.3-0.5-2c-0.2-0.6-0.3-1.3-0.6-1.9c-0.8-2.4-1.7-4.6-2.5-6.6c-0.4-1-0.7-1.9-1-2.7
							c-0.2-0.4-0.3-0.8-0.4-1.2c-0.1-0.4-0.2-0.8-0.3-1.1c-0.2-0.7-0.4-1.4-0.5-1.9c-0.1-0.6-0.2-1.1-0.3-1.5c-0.1-0.8-0.2-1.2-0.2-1.2
							s0.1,0.4,0.3,1.2c0.1,0.4,0.2,0.9,0.3,1.4c0.1,0.6,0.4,1.2,0.6,1.9c0.1,0.4,0.2,0.7,0.4,1.1c0.2,0.4,0.3,0.8,0.5,1.2
							c0.3,0.8,0.7,1.7,1.1,2.7c0.8,1.9,1.8,4.1,2.7,6.5c0.2,0.6,0.4,1.2,0.6,1.9c0.2,0.7,0.4,1.3,0.6,2c0.2,1.4,0.6,2.8,0.6,4.3
							c0.2,3-0.2,6.2-1,9.3c-0.9,3.1-2.3,6.2-4,9.2c-0.4,0.7-0.8,1.3-1.3,2c-1.5,2.2-3.2,4.3-5.2,6.2c-0.6,0.6-1.2,1.2-1.9,1.8l-2.1,1.6
							c-0.7,0.6-1.5,1-2.2,1.4c-0.8,0.4-1.5,0.9-2.3,1.2c-0.8,0.3-1.6,0.7-2.3,1c-0.8,0.3-1.6,0.5-2.4,0.8l-1.2,0.4l-1.2,0.3
							c-0.8,0.2-1.6,0.4-2.4,0.5c-1.6,0.1-3.2,0.4-4.8,0.3c-0.8,0-1.6,0-2.3-0.1c-0.6-0.1-1.2-0.1-1.8-0.2c-0.1,0-0.3,0-0.4,0
							c-0.7-0.1-1.5-0.2-2.2-0.4c-0.7-0.2-1.4-0.3-2.1-0.5c-0.7-0.2-1.3-0.4-2-0.7c-0.6-0.2-1.3-0.4-1.9-0.7c-2.4-1-4.5-2.2-6.2-3.5
							c-0.8-0.6-1.6-1.2-2.3-1.8c-0.7-0.6-1.3-1.2-1.8-1.7c-0.3-0.3-0.5-0.5-0.8-0.7C227.2,275.6,227,275.3,226.9,275
							c-0.5-0.4-0.8-0.8-1-1.1c-0.5-0.6-0.8-1-0.8-1s0.3,0.3,0.8,0.9C226.1,274.2,226.5,274.6,226.9,275z"/>
					</g>
					<g id="front_layer20">
						<path fill="#FFFFFF" d="M177.4,386.2c-0.8-4.4-4.2-23.6-4.7-43.2c-1.2-20.6-2.8-51.4-2.5-56.3c12.5-3,20.3-12.5,20.7-12.9
							c0.1-0.1,6.7-8.9,6.5-24c-0.3-19.4-23.1-22.3-28.4-22.7l-0.1-9.1h77.3l0.1,9.1c-5.4,0.4-28.1,3.3-28.4,22.7
							c-0.2,14.2,5.7,22.9,6.4,23.9c0.5,0.8,5.7,9.1,20.3,13c-0.2,6.1-1.2,41.5-2.4,58c-1.3,17.1-2.6,34.3-4.5,41.6L177.4,386.2z"/>
					</g>
					<g id="front_layer10">
						<g>
							<g>
								<path fill="#FFFFFF" d="M145.7,279.5c0.6,0.5,1.4,0.9,2.1,1.4c0.7,0.4,1.4,0.9,2.2,1.2c0.8,0.3,1.5,0.7,2.3,1
									c0.8,0.3,1.6,0.5,2.3,0.8l1.2,0.4l1.2,0.3c0.8,0.2,1.6,0.4,2.4,0.5c1.6,0.1,3.1,0.4,4.7,0.3c0.8,0,1.5,0.1,2.3,0
									c0.5,0,0.9-0.1,1.4-0.1c-7.8,0.8-15.9-1.2-22.6-6.2L145.7,279.5z"/>
								<path fill="#FFFFFF" d="M144.9,278.9c-0.7-0.5-1.4-1.1-2.1-1.7c0.3,0.3,0.6,0.6,0.9,0.8L144.9,278.9z"/>
								<path fill="#FFFFFF" d="M169.2,285C169.2,285,169.2,285,169.2,285c-0.4,0-0.9,0.1-1.3,0.2c0.2,0,0.4,0,0.6-0.1
									C168.7,285.1,169,285.1,169.2,285z"/>
								<path fill="#FFFFFF" d="M140.5,274.8c-1.1-1.2-2.1-2.4-3.1-3.7C138.3,272.4,139.4,273.7,140.5,274.8z"/>
								<path fill="#FFFFFF" d="M135.5,268.4c0.2,0.4,0.5,0.7,0.7,1.1c-0.9-1.4-1.7-2.8-2.4-4.2C134.3,266.3,134.9,267.3,135.5,268.4z"/>
								<path fill="#FFFFFF" d="M130.4,250.4C130.4,250.4,130.4,250.5,130.4,250.4c0-0.4,0.1-0.9,0.1-1.4
									C130.4,249.5,130.4,249.9,130.4,250.4z"/>
								<path fill="#FFFFFF" d="M131.5,259.2c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1C131.5,259.4,131.5,259.3,131.5,259.2z"/>
								<path fill="#FFFFFF" d="M166.2,386.2c-17.1,0-28.1-5.1-31.4-6.9c2.7-10,5.2-17.1,6.8-21.3c2.2-5.8,14.1-52.6-0.5-79.4
									c0.4,0.4,0.8,0.7,1.2,1.1l2.2,1.6c0.6,0.5,1.2,0.8,1.8,1.2l0.5,0.3c0.3,0.2,0.5,0.3,0.7,0.4c0.5,0.3,1.1,0.6,1.7,0.9l2.3,1
									c0.6,0.2,1.1,0.4,1.7,0.6c0.3,0.1,0.5,0.2,0.8,0.3l1.3,0.4l1.3,0.3c0.3,0.1,0.6,0.1,0.9,0.2c0.5,0.1,1.1,0.2,1.6,0.3
									c0.5,0,0.9,0.1,1.3,0.1c0.9,0.1,1.9,0.2,2.9,0.2c0.3,0,0.5,0,0.8,0c0.2,0,0.5,0,0.8,0c0.5,0,1.1,0,1.6-0.1l1.7-0.2
									c0.4,15.7,0.7,26.4,0.8,28.6c2.6,51.1,3.9,58.3,6.1,70.3l0,0.2L166.2,386.2L166.2,386.2z"/>
								<path fill="#FFFFFF" d="M129.1,246.2c-1.9-12.1-1.9-24.9-1.9-28.6l39.3,0.3c0,1.8,0.1,4.9,0.1,9.1c-1,0-2.1,0.1-3.3,0.1
									c-2.9,0.1-6.1,0.2-9.4,0.2c-6.7,0-11-0.4-12.8-1.3c-2.2-1.1-2.6-3.4-2.6-3.4l-2.3,0.3c0,0,0.1,1.4,0,3.1l-0.1,0l-0.6,2.7
									c-0.1,0.3-0.2,0.6-0.3,1c-0.1,0.3-0.2,0.5-0.3,0.8l-0.4,1.1l-0.5,1.3c-0.3,0.8-0.6,1.6-1,2.5c-0.2,0.5-0.4,0.9-0.6,1.4
									c-0.7,1.5-1.4,3.3-2.1,5.2c-0.2,0.6-0.4,1.3-0.6,1.9c-0.1,0.3-0.1,0.5-0.2,0.7c-0.1,0.5-0.3,0.9-0.4,1.4
									C129.1,246,129.1,246.1,129.1,246.2z"/>
							</g>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M168.2,285.9c-0.6,0.1-1.2,0.1-1.8,0.2
								c-0.8,0.1-1.5,0-2.3,0.1c-1.6,0.1-3.2-0.2-4.8-0.3c-0.8-0.1-1.6-0.3-2.4-0.5l-1.2-0.3l-1.2-0.4c-0.8-0.3-1.6-0.5-2.4-0.8
								c-0.8-0.3-1.6-0.7-2.3-1c-0.8-0.3-1.5-0.8-2.3-1.2c-0.7-0.4-1.5-0.8-2.2-1.4l-2.1-1.6c-0.7-0.6-1.3-1.2-1.9-1.8
								c-1.8-1.7-3.4-3.7-4.8-5.7l3.3,6.9c3.5,6.2,5.6,13.5,6.6,21.2C149.1,292.5,162,287.8,168.2,285.9z"/>
						</g>
						<path opacity="0.1" fill="#020202" enableBackground="new    " d="M188.2,275c-0.2,0.2-0.4,0.4-0.6,0.7c-0.2,0.2-0.5,0.5-0.8,0.7
							c-0.6,0.5-1.2,1.1-1.8,1.7c-0.7,0.5-1.5,1.1-2.4,1.7c-1.7,1.1-3.8,2.3-6.2,3.2c-0.6,0.3-1.2,0.4-1.8,0.7c-0.6,0.2-1.3,0.4-1.9,0.6
							c-0.7,0.2-1.4,0.3-2.1,0.4c-0.7,0.1-1.4,0.3-2.1,0.4c-0.7,0.1-1.5,0.1-2.2,0.2c-0.7,0.1-1.5,0-2.3,0c-1.5,0.1-3.1-0.2-4.7-0.3
							c-0.8-0.1-1.6-0.3-2.4-0.5l-1.2-0.3l-1.2-0.4c-0.8-0.3-1.6-0.5-2.3-0.8c-0.8-0.3-1.5-0.7-2.3-1c-0.8-0.3-1.5-0.8-2.2-1.2
							c-0.7-0.4-1.5-0.8-2.1-1.4l-2-1.5c-0.6-0.6-1.2-1.2-1.9-1.8c-2.5-2.3-4.6-5-6.3-7.9c-1.7-2.8-3.1-5.9-4-8.9c-0.8-3.1-1.3-6.1-1.2-9
							c0-1.5,0.3-2.8,0.5-4.2c0.1-0.7,0.3-1.3,0.5-2c0.2-0.6,0.3-1.3,0.6-1.9c0.8-2.4,1.7-4.6,2.5-6.6c0.4-1,0.7-1.9,1-2.7
							c0.2-0.4,0.3-0.8,0.4-1.2c0.1-0.4,0.2-0.8,0.3-1.1c0.2-0.7,0.4-1.4,0.5-1.9c0.1-0.6,0.2-1.1,0.3-1.5c0.1-0.8,0.2-1.2,0.2-1.2
							s-0.1,0.4-0.3,1.2c-0.1,0.4-0.2,0.9-0.3,1.4c-0.1,0.6-0.4,1.2-0.6,1.9c-0.1,0.4-0.2,0.7-0.4,1.1c-0.2,0.4-0.3,0.8-0.5,1.2
							c-0.3,0.8-0.7,1.7-1.1,2.7c-0.8,1.9-1.8,4.1-2.7,6.5c-0.2,0.6-0.4,1.2-0.6,1.9c-0.2,0.7-0.4,1.3-0.6,2c-0.2,1.4-0.6,2.8-0.6,4.3
							c-0.2,3,0.2,6.2,1,9.3c0.9,3.1,2.3,6.2,4,9.2c0.4,0.7,0.8,1.3,1.3,2c1.5,2.2,3.2,4.3,5.2,6.2c0.6,0.6,1.2,1.2,1.9,1.8l2.1,1.6
							c0.7,0.6,1.5,1,2.2,1.4c0.8,0.4,1.5,0.9,2.3,1.2c0.8,0.3,1.6,0.7,2.3,1c0.8,0.3,1.6,0.5,2.4,0.8l1.2,0.4l1.2,0.3
							c0.8,0.2,1.6,0.4,2.4,0.5c1.6,0.1,3.2,0.4,4.8,0.3c0.8,0,1.6,0,2.3-0.1c0.6-0.1,1.2-0.1,1.8-0.2c0.1,0,0.3,0,0.4,0
							c0.7-0.1,1.5-0.2,2.2-0.4c0.7-0.2,1.4-0.3,2.1-0.5c0.7-0.2,1.3-0.4,2-0.7c0.6-0.2,1.3-0.4,1.9-0.7c2.4-1,4.5-2.2,6.2-3.5
							c0.8-0.6,1.6-1.2,2.3-1.8c0.7-0.6,1.3-1.2,1.8-1.7c0.3-0.3,0.5-0.5,0.8-0.7C187.9,275.6,188.1,275.3,188.2,275
							c0.5-0.4,0.8-0.8,1-1.1c0.5-0.6,0.8-1,0.8-1s-0.3,0.3-0.8,0.9C188.9,274.2,188.6,274.6,188.2,275z"/>
					</g>
					<g id="front_layer8">
						<g>
							<path fill="#FFFFFF" d="M60.8,441.9c4.3-8.8,33.1-67.1,37.9-81.7l3.1-9.5c0.8-2.3,1.4-4.5,2.1-6.8c1.6-5.1,3.4-11,7.2-21.8
								c7.7-21.7,12.4-63.1,14.6-87c0.8,9.6,2.3,20.9,5.5,28.2c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.8,0,1.7,0,2.6c0,14.5-4.1,34.8-11.8,58.7
								c-5.2,16-6.9,21.3-9.3,28.6l-3.1,9.5c-5.4,16.6-13.7,30.6-22.5,45.4c-5.1,8.7-10.5,17.6-15.3,27.4c-0.1,0.3-0.3,0.6-0.5,0.9
								c-0.3,0.5-0.6,1.1-0.9,1.8c-0.7,1.3-1.4,2.8-2.3,4.4L60.8,441.9z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M85.1,409.8c8.8-14.9,17.2-29,22.6-45.7l3.1-9.5
								c2.4-7.3,4.1-12.6,9.3-28.6c7.8-24.1,11.9-44.4,11.9-59.1c-2,13.4-9.2,53.6-26.7,89.5c-19.4,39.6-32.9,73.7-36,81.7
								c0.2-0.3,0.3-0.7,0.5-0.9C74.6,427.4,79.9,418.5,85.1,409.8z"/>
						</g>
					</g>
					<g id="front_layer19">
						<path fill="#FFFFFF" d="M52.2,438.3c1.9-5.5,11.7-32.8,27.7-71.7c1.2-2.9,2.8-5.5,4.4-7.9c0.3-0.5,0.7-1.1,1-1.6
							c3.7-5.9,7.5-12,8-19.8c1-13.7,4.1-29.2,7.3-45.5c4.5-22.7,9.6-48.5,10-74.8c1.3,0.2,2.6,0.4,4,0.5c2,0.1,7.7,0.2,10.3,0.2
							c-0.7,9.6-5.6,74.7-15.9,103.7c-3.8,10.9-5.7,17-7.2,21.9c-0.7,2.3-1.4,4.5-2.1,6.8l-3.1,9.5c-4.8,14.6-33.8,73.4-37.8,81.6
							L52.2,438.3z"/>
					</g>
					<g id="front_layer6">
						<path fill="#FFFFFF" d="M46.2,435.7c2.6-6.6,5.4-16.2,8.3-26.4c4.4-15.5,9.4-33,15.6-46.3c1.9-4.1,4.1-7.6,6-10.7
							c3.3-5.2,6.1-9.7,6.4-14.9c1-14.4,4.2-30.1,7.5-46.8c4.8-24.3,9.8-49.4,9.8-75.7c0-1.3,0-2.6,0.1-3.9c1.6,1.9,4.2,4,8.4,5.2
							c-0.3,26.4-5.4,52.2-9.9,75c-3.3,16.4-6.3,31.9-7.3,45.8c-0.5,7.2-4.2,13.1-7.7,18.7c-0.3,0.5-0.7,1-1,1.6
							c-1.6,2.6-3.3,5.2-4.6,8.3c-15.9,38.7-25.7,65.9-27.7,71.7L46.2,435.7z"/>
					</g>
					<g id="front_layer5">
						<g>
							<path fill="#FFFFFF" d="M207.3,215.7c-13.6,0-27.2,0-39.6-0.1c-29.6-0.1-48.4-0.3-53-0.5c-1.5-0.1-3-0.3-4.3-0.5l-0.7-0.2
								c-6.2-1.6-8.6-5-9.6-7.1c1.1-11.3,4.7-20,10.8-25.8c7.8-7.5,17.3-7.9,19.1-7.9c0,0,0.2,0,0.2,0c0.3,0,0.5,0,0.7-0.1l4-1.6
								c3,0,7.8,0.5,14.5,2.1c3.7,0.9,7.6,2.2,11.5,3.6c6.6,2.5,15.8,4.6,25.9,5.8c0.4,0.1,2,0.2,2,0.2c6.2,0.7,12.4,1.1,18.3,1.1
								c7.8,0,16.1-0.6,24.1-1.9c8.5-1.3,16.3-3.1,22-5.3c5.5-2.1,10.7-3.6,15.6-4.6c5-1,8.7-1.2,10.9-1.2l0.2,0l4,1.6
								c0.2,0.1,0.4,0.1,0.6,0.1l0.3,0c1.8,0,11.3,0.4,19.1,7.9c5.7,5.5,9.3,13.5,10.6,23.9c0.1,0.6,0.1,1.2,0.2,1.9
								c-0.4,0.9-1.1,2.1-2.3,3.3c-0.1,0.1-0.5,0.5-0.5,0.5c-2.8,2.5-6.9,3.8-12,4.1c-4.5,0.2-23.3,0.4-52.8,0.5
								C234.8,215.6,221,215.7,207.3,215.7z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M280.2,170.6L280.2,170.6c-2.4,0-6.2,0.2-11.4,1.3l-18.1,10.6
								l31.2-11.1L280.2,170.6L280.2,170.6z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M167.5,183.1l-17.6-10.3c-6.8-1.7-11.8-2.1-15-2.2l-1.3,0.5l-0.4,0.2
								L167.5,183.1z"/>
						</g>
					</g>
					<g id="front_layer4">
						<g>
							<path fill="#FFFFFF" d="M221.5,129.3c1.4-1.2,2.8-2.5,4.2-4v9.1c0,0.1-0.5,15,13.2,20.6l36.1,14.7c-2.1,0.2-4.2,0.5-6.4,1
								c-4.9,1-10.1,2.5-15.4,4.5c-5.8,2.2-13.7,4.1-22.2,5.4c-2.2,0.3-4.5,0.6-6.7,0.9c-2.5,0.3-5,0.5-7.4,0.6L221.5,129.3z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M280.2,170.6l-5.5-2.2l-5.9,3.4C273.8,170.9,277.6,170.6,280.2,170.6
								z"/>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M226.8,122.3c-2.2,2.7-4.3,4.9-6.4,6.5l-0.7,8.3
								c2.4-1.5,4.8-3.4,7.1-5.7V122.3z"/>
						</g>
					</g>
					<g id="front_layer17">
						<g>
							<path fill="none" d="M221.8,190.8v-7.9c-4.7,0.4-9.5,0.7-14.4,0.7c-4.8,0-9.6-0.3-14.3-0.7v7.9L221.8,190.8L221.8,190.8z"/>
						</g>
						<g>
							<path fill="#FFFFFF" d="M207.4,182.4c-1.7,0-4.4-0.1-7.6-0.2l-4.5-51.5c0.1,0.1,0.7,0.5,0.7,0.5c0.2,0.1,0.3,0.2,0.5,0.3
								c0.3,0.2,0.5,0.3,0.7,0.4c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.5,0.3,0.7,0.4l0.5,0.2c0.6,0.3,1.2,0.5,1.7,0.7
								c0.3,0.1,0.5,0.2,0.7,0.3l0.5,0.2c0.2,0.1,0.4,0.1,0.6,0.2l0.4,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0,0.3,0.1,0.4,0.1
								c0.3,0.1,0.6,0.1,0.8,0.2c0.2,0,0.4,0.1,0.5,0.1c0,0,0.7,0.1,0.8,0.1l0.3,0c0.1,0,0.6,0,0.6,0c0.2,0,0.4,0,0.5,0
								c0.2,0,0.4,0,0.6,0c0,0,0.5,0,0.7-0.1l0.2,0c0.2,0,0.5-0.1,0.8-0.1l0.1,0c0.2,0,0.5-0.1,0.8-0.1l0.2,0c0.3-0.1,0.5-0.1,0.8-0.2
								l0.3-0.1c0.3-0.1,0.7-0.2,1-0.3l0.1,0c0.4-0.1,0.7-0.2,1-0.4l0.4-0.1c0.3-0.1,0.6-0.2,0.9-0.4l0.3-0.1c0.4-0.2,0.8-0.4,1.3-0.6
								l0.2-0.1c0.4-0.2,0.7-0.4,1.1-0.6l0.4-0.2c0.2-0.1,0.4-0.3,0.6-0.4l-4.4,51.1C213.4,182.3,210.7,182.4,207.4,182.4L207.4,182.4
								L207.4,182.4z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M219.2,129.7c-0.1,0.1-0.2,0.1-0.2,0.2c-0.4,0.3-0.7,0.5-1.1,0.7
								c-0.1,0.1-0.2,0.1-0.4,0.2c-0.4,0.2-0.7,0.4-1,0.6c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.2-0.8,0.4-1.2,0.6c-0.1,0-0.2,0.1-0.3,0.1
								c-0.3,0.1-0.6,0.3-0.9,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.1,0-0.2,0.1c-0.3,0.1-0.7,0.2-1,0.3
								c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1,0-0.2,0-0.3,0.1c-0.2,0-0.5,0.1-0.7,0.1c0,0-0.1,0-0.1,0
								c-0.3,0-0.5,0.1-0.7,0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5,0l0,0c0,0,0,0,0,0l0,0
								c0,0,0,0,0,0l0,0c0,0,0,0,0,0c-0.1,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4-0.1
								c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.4-0.1-0.5-0.1
								c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.1-0.4-0.2-0.7-0.2c0,0,0,0-0.1,0
								c-0.5-0.2-1.1-0.4-1.6-0.7c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.5-0.3-0.7-0.4
								c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.5-0.3-0.7-0.5c-0.4-0.3-0.8-0.6-1.2-0.9c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.1-0.4-0.3-0.5-0.4
								c0.2,0.1,0.3,0.3,0.5,0.4l1.2,13.8c6.9,0.9,15.8,0.4,24.6-5l0.7-8.3C220,129.1,219.6,129.4,219.2,129.7z"/>
						</g>
					</g>
					<g id="front_layer3">
						<g>
							<path fill="#FFFFFF" d="M196.1,182c-0.6,0-1.3-0.1-1.9-0.2c-0.5,0-1-0.1-1.5-0.1l-1.3-0.1c-0.7-0.1-1.4-0.1-2-0.2
								c0,0-1.5-0.2-2-0.2c-9.9-1.3-19.4-3.4-26-5.9c-3.9-1.5-7.6-2.6-11.3-3.6c-3.5-0.9-6.9-1.5-10.1-1.9l36.3-14.8
								c7.9-3.2,11.1-9.5,12.3-14.2c1-3.6,0.9-6.4,0.9-6.4l0-8.8c1.1,1.2,2.2,2.3,3.4,3.2l4.7,53.2C197,182.1,196.5,182,196.1,182z"/>
							<path opacity="5.000000e-02" enableBackground="new    " d="M134.9,170.6L134.9,170.6c3.3,0,8.2,0.4,14.9,2.1l-8.2-4.8
								L134.9,170.6L134.9,170.6z"/>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M188.3,122.6v11.9c0,0.1,0.1,2.7-0.8,6.1
								c2,0.5,4.6,1.2,7.6,1.5l-1.2-13.8C192.1,126.8,190.2,125,188.3,122.6z"/>
						</g>
					</g>
					</svg>
					</div>
					<div className="shape slider female-back">
					<svg version="1.1" id="rear_layer_female_slider" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
						viewBox="0 0 333.5 906.2" enableBackground="new 0 0 333.5 906.2">
							<text textDecoration="underline" fontFamily="PoppinsBold"  x="0" y="120" fill="black" fontSize="15px">Izquierda</text>
							<text textDecoration="underline" fontFamily="PoppinsBold"  x="52%" y="120" fill="black" fontSize="15px">Derecha</text>
					<g id="cuerpo_x2F_sombras_x2F_pelo_x2F_pantalon">
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeMiterlimit="10" d="M332.2,469.2c-0.7-0.4-2.3-0.8-3.4-1.7
							c-14.7-11.9-29.2-19-35.8-25.2c-7.5-7.1-15.4-53-28.2-80.5c-5-10.7-11.8-17.3-12.3-25.1c-2.5-35-17.3-78-17.3-122.6
							c0-44.6-32.3-43.1-32.3-43.1l-45.2-18.4c-12.8-5.2-12.2-19.1-12.2-19.1v-12.6c6.5-8.7,12.9-23.5,16.4-48.3c1-7,1.2-13.2,0.8-18.5
							c-0.3-3.3-0.8-6.3-1.5-9c-1.9-7.6-5.3-13-9.3-16.9c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.6-0.6-1.2-1.1-1.9-1.6
							c-0.5-0.4-1.1-0.8-1.6-1.2c-0.4-0.3-0.8-0.5-1.2-0.8c-0.6-0.4-1.2-0.7-1.8-1.1c-0.4-0.2-0.8-0.5-1.3-0.7c-1.7-0.9-3.4-1.5-5-2.1
							c-1.4-0.5-2.8-0.8-4-1.1c-1.3-0.3-2.5-0.5-3.6-0.6c-2.9-0.4-5-0.3-5.7-0.3c-0.7,0-3.3-0.1-6.6,0.4c-10.7,1.6-30.1,8.8-30.5,39.6
							c-0.1,4.2,0.2,8.9,1,14c2.7,19.5,7.3,32.7,12.2,41.8c0.7,1.3,1.5,2.5,2.2,3.7c0.6,1,1.3,1.9,1.9,2.8v12.6c0,0,0.6,13.9-12.2,19.1
							l-45.2,18.4c0,0-32.3-1.4-32.3,43.1s-7.4,87.1-9.9,122.1c-0.6,7.8,0.4,18.4-0.2,30.3c-1.1,22.1-1.7,48.3-2.1,68
							c-0.3,17.2-2,29.3-2,29.3s-2.7,39-0.5,44.8c2.1,5.9,1.6,13.1,3.5,16.8c1.9,3.7,14.4,22.1,15.5,25.9c1.1,3.7,5.1,3.2,5.6,0
							s-8.3-17.1-8-20.5c0,0,6.1,2.7,6.4-2.7c0.3-5.3-0.2-24.8,1.6-31.2c1.8-6.4-8-28.8-5.9-37.4c2.1-8.5,8-53.1,14.4-79
							c3.6-14.7,7.3-27.5,9-52.5c1-14.3,7.7-44.1,6.9-64.7l6.7,14.3c14.9,25.9,3.3,72.9,0.9,79.1c-4.8,12.5-12.9,38.7-17.8,82.8
							c-7.3,65.9,15.9,146,7.4,208.7c-8.5,62.7-12,72.3-9.6,111c2.3,37.4-1.6,93.2-1.9,96.4c-0.4,1.6-3.3,13.3-2.2,18.6
							c1.2,5.7-6.9,16.2-13.6,21.9c-6.7,5.7-0.9,7.3,1.9,7.9c2.2,0.5,44.6,6.2,40.6-6.5c-1.2-3.8-2.4-2.2-2-15.7
							c0.3-7.8,2.6-8.1,1.1-14.8c0,0-2.4-8.9-2.2-11.5c1-5.4,14.4-83.9,21.6-108.5c4.9-16.6,7.1-23.8,7.7-76.9c0.3-20.8,8.3-19.7,8.3-55
							c0-25,20.7-85.9,21.6-138c0.3-0.1,0.6-0.3,0.9-0.4c0.8-0.4,1.5-0.8,2.3-1.2c0.7-0.5,1.5-0.9,2.1-1.5c0.5-0.4,1-0.9,1.5-1.3
							c0.5,0.5,1,0.9,1.5,1.3c0.7,0.6,1.4,1,2.1,1.5c0.7,0.4,1.5,0.9,2.3,1.2c0.3,0.1,0.6,0.3,0.9,0.4c0.9,52.2,21.6,115.2,21.6,138
							c0,35.2,8,34.2,8.3,55c0.7,53.1,2.9,60.2,7.7,76.9c7.5,25.6,21.6,108.9,21.6,108.9c0,3-2.2,11.2-2.2,11.2c-1.5,6.7,0.9,7,1.1,14.8
							c0.5,13.5-0.8,12-2,15.7c-4,12.7,38.4,7,40.6,6.5c2.8-0.6,8.6-2.2,1.9-7.9c-6.7-5.7-14.8-16.2-13.6-21.9c1.2-5.7-2.3-18.8-2.3-18.8
							c-0.4-4.5-4.1-59.4-1.8-96.2c2.4-38.7-1.1-48.3-9.6-111c-8.5-62.7,14.7-142.8,7.4-208.7c-4.9-44.1-13-70.3-17.8-82.8
							c-1.6-4.2-14.5-52.3,0.9-79.1l6.7-14.1c-0.7,20.6,7.4,48.3,11.9,61.9c7.1,22,7.8,23.8,12.5,38.2c8.7,26.6,24.9,46.9,38,73.1
							c1.5,3,5.6,10.3,9.3,19c2.9,6.8,5.6,14.5,6.6,21.7c0.9,6.3,10.4,33.8,11.2,35.6c1.6,3.6,7.1,1.9,6.6-0.8c-0.3-1.7-0.8-3.7-1.4-6
							c1.5,5.5,2.9,10.8,3.4,13c1.1,4.5,8.4,4,6.7-2.5c-1.8-7.2-6.6-29.5-6.6-29.5s6.1,25.5,8.2,32c1.3,4.1,7.8,3.4,6.8-2.8
							c-0.2-1.3-0.7-3.5-1.3-6.1c0.7,2.5,1.4,4.4,1.7,4.9c1.3,2,8.3,1.6,5.8-6.2c-2.7-8.4-4.1-16.7-5.7-25.2c-1.5-7.6-2.5-13.5-3.1-14.9
							c1-0.2,1.9-0.5,3-0.8c3.1-0.7,7.4,4.5,12.4,6.1C331.7,476.1,334.4,470.5,332.2,469.2z"/>
						<path fill="#EFD3D3" d="M145.4,481c-0.6-0.1-1.1-0.3-1.7-0.4c0,0,0,0,0,0l0,0c-1.4-0.4-2.7,0.2-3.9-0.3c-2-0.4-4-1.9-5.9-2.6
							c-0.5-0.2-1-0.4-1.5-0.6c0.9,30,11.4,81.4,11.4,81.4C139.5,521.7,142.2,496.4,145.4,481z"/>
						<path fill="#EFD3D3" d="M119.1,476.8c-0.6,0.3-1.2,0.6-1.9,0.8c-1.9,0.7-3.9,2.2-5.9,2.6c-1.3,0.5-2.6-0.1-3.9,0.3l0,0c0,0,0,0,0,0
							c-0.4,0.1-0.9,0.2-1.3,0.3c3.2,15.4,5.9,40.7,1.6,77.4C107.7,558.3,118.2,506.8,119.1,476.8z"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M161.3,75.4
							L161.3,75.4c0,0,21.7-0.8-7.1,28.7v0C157,96.5,159.5,87.1,161.3,75.4z"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M89.8,75.4
							L89.8,75.4c0,0-21.7-0.8,7.1,28.7v0C94.1,96.5,91.6,87.1,89.8,75.4z"/>
						<path fill="#DDC7BF" d="M28.1,341.2c0,0,6.4,0.3,9.4-8.2"/>
						<path fill="#DDC7BF" d="M125.6,161.8c0,0,0.1,1.1,0.2,3.2c0.1,2,0.2,4.9,0.3,8.3c0.1,3.5,0.2,7.5,0.2,11.8c0,4.3,0.1,8.9,0.1,13.5
							c0,4.6-0.1,9.2-0.1,13.5c0,4.3-0.2,8.3-0.2,11.8c-0.1,3.5-0.2,6.3-0.3,8.3c-0.1,2-0.2,3.2-0.2,3.2s-0.1-1.2-0.2-3.2
							c-0.1-2-0.2-4.9-0.3-8.3c-0.1-3.5-0.2-7.5-0.2-11.8c0-4.3-0.1-8.9-0.1-13.5c0-4.6,0.1-9.2,0.1-13.5c0-4.3,0.2-8.3,0.2-11.8
							c0.1-3.5,0.2-6.3,0.3-8.3C125.6,162.9,125.6,161.8,125.6,161.8"/>
						<path fill="#DDC7BF" d="M125.6,289.2c0,0,0.1,0.6,0.2,1.6c0.1,1,0.2,2.5,0.3,4.3c0.2,3.6,0.3,8.4,0.3,13.2c0,4.8-0.1,9.6-0.3,13.2
							c-0.1,1.8-0.2,3.3-0.3,4.3c-0.1,1-0.2,1.6-0.2,1.6s-0.1-0.6-0.2-1.6c-0.1-1-0.2-2.5-0.3-4.3c-0.2-3.6-0.3-8.4-0.3-13.2
							c0-4.8,0.1-9.6,0.3-13.2c0.1-1.8,0.2-3.3,0.3-4.3C125.6,289.8,125.6,289.2,125.6,289.2"/>
						<path fill="#DDC7BF" d="M116,133c0,0,0.5-0.1,1.3,0.1c0.4,0.1,0.8,0.3,1.2,0.5c0.4,0.2,0.8,0.6,1.2,0.9c0.4,0.4,0.7,0.8,0.9,1.2
							c0.2,0.4,0.4,0.8,0.5,1.2c0.2,0.7,0.2,1.3,0.2,1.3s-0.5-0.3-1-0.8c-0.2-0.3-0.6-0.5-0.8-0.8c-0.3-0.3-0.6-0.6-0.9-0.9
							c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.6-0.6-0.8-0.9C116.3,133.5,116,133,116,133"/>
						<path fill="#DDC7BF" d="M135.1,133c0,0-0.3,0.4-0.8,0.9c-0.2,0.3-0.5,0.5-0.8,0.9c-0.3,0.3-0.6,0.6-0.9,0.9
							c-0.3,0.3-0.6,0.6-0.9,0.9c-0.3,0.3-0.6,0.6-0.8,0.8c-0.5,0.5-1,0.8-1,0.8s0-0.5,0.2-1.3c0.1-0.4,0.3-0.8,0.5-1.2
							c0.2-0.4,0.5-0.8,0.9-1.2c0.3-0.4,0.8-0.7,1.2-0.9c0.4-0.3,0.8-0.4,1.2-0.5C134.5,133,135.1,133,135.1,133"/>
						<path fill="#DDC7BF" d="M111.3,365.6c0,0,0.6,0.1,1.2,0.5c0.3,0.2,0.6,0.4,0.9,0.7c0.3,0.3,0.5,0.7,0.8,1.1c1.1,1.5,2,3,2,3
							c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0-1.5-1.1-2.9-2.3c-0.4-0.3-0.7-0.6-1-0.8c-0.3-0.3-0.5-0.7-0.6-1c-0.3-0.6-0.4-1.2-0.4-1.2
							C111.2,365.7,111.2,365.6,111.3,365.6C111.3,365.6,111.3,365.6,111.3,365.6"/>
						<path fill="#DDC7BF" d="M139.6,365.7c0,0,0,0.6-0.4,1.2c-0.1,0.3-0.4,0.7-0.6,1c-0.3,0.3-0.7,0.6-1,0.8c-1.4,1.2-2.9,2.3-2.9,2.3
							c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0,0,1-1.6,2-3c0.3-0.4,0.5-0.8,0.8-1.1c0.3-0.3,0.6-0.5,0.9-0.7c0.6-0.4,1.2-0.5,1.2-0.5
							C139.5,365.6,139.6,365.6,139.6,365.7C139.6,365.7,139.6,365.7,139.6,365.7"/>
						<path fill="#DDC7BF" d="M79.9,643.7c0,0-0.3,1.2-0.8,2.9c-0.2,0.9-0.5,1.9-0.7,2.9c-0.3,1.1-0.6,2.2-0.8,3.4
							c-0.3,1.1-0.5,2.3-0.8,3.4c-0.2,1.1-0.5,2.1-0.7,3c-0.4,1.8-0.7,2.9-0.7,2.9s-0.2-1.2-0.2-3c0-0.9,0-2,0.2-3.1
							c0.1-0.6,0.1-1.2,0.2-1.7c0.1-0.6,0.2-1.2,0.4-1.8c0.3-1.2,0.6-2.4,1-3.4c0.4-1.1,0.8-2,1.2-2.9c0.4-0.8,0.8-1.5,1.1-1.9
							C79.7,644,79.9,643.7,79.9,643.7"/>
						<path fill="#DDC7BF" d="M171.4,643.7c0,0,0.2,0.2,0.5,0.7c0.3,0.4,0.7,1.1,1.1,1.9c0.4,0.8,0.9,1.8,1.2,2.9c0.4,1.1,0.7,2.2,1,3.4
							c0.1,0.6,0.2,1.2,0.4,1.8c0.1,0.6,0.1,1.2,0.2,1.7c0.1,1.1,0.2,2.2,0.2,3.1c0,1.8-0.2,3-0.2,3s-0.4-1.2-0.7-2.9
							c-0.2-0.9-0.4-1.9-0.7-3c-0.2-1.1-0.5-2.2-0.8-3.4c-0.2-1.2-0.5-2.3-0.8-3.4c-0.2-1.1-0.5-2.1-0.7-2.9
							C171.7,644.9,171.4,643.7,171.4,643.7"/>
						<path fill="#EFD3D3" d="M222.8,353.6c-4.4-14.1-14.2-34.4-19.3-62.7c-2.7-14.7-2.3-19.6-2.6-26.9c0,0,7.6,51.9,29.6,96.9
							c22.7,46.4,37.5,83.6,37.5,83.6s-1.2-2.2-3.3-6.2c-3.6-7.1-10.3-19.7-20.3-35.6c-2.6-4.1-4.8-8.4-7.1-12.6
							C228.6,374.1,224.1,357.9,222.8,353.6"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M50.4,263.2
							c0,0-1.8-4.2-3.7-11.8"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M200.9,263.2
							c0,0,2.7-4.5,3.7-11.8"/>
						
							<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="295.5" y1="506.4" x2="290.5" y2="488.8"/>
						
							<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="306.3" y1="485.9" x2="312.6" y2="510.8"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M15.8,530.7
							c0,0-2.2-18.7-1.8-19.9"/>
						<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M7.5,529.8
							c0,0,5.4,7.9,9.8,13.2c2.1,2.6,7.1,1.5-2.7-18.5"/>
						<path fill="#DDC7BF" d="M14,506.7c0,0-0.2,0.2-1.1,1.9c-0.2,0.4-0.4,0.9-0.6,1.4c-0.2,0.5-0.4,1.1-0.5,1.7
							c-0.2,0.6-0.2,1.2-0.3,1.8c0,0.6,0,1.3,0,1.9c0.1,0.6,0.1,1.3,0.3,1.9c0.2,0.6,0.2,1.2,0.5,1.8c0.2,0.6,0.4,1.1,0.6,1.6
							c0.2,0.5,0.5,1,0.7,1.4c0.4,0.9,0.9,1.5,1.1,2c0.3,0.5,0.5,0.7,0.5,0.7l0,0.1c0.2,0.4,0.1,0.9-0.3,1.1c-0.4,0.2-0.9,0.1-1.1-0.3
							c0,0,0,0,0-0.1c0,0-0.2-0.3-0.4-0.8c-0.2-0.5-0.7-1.2-1-2.2c-0.2-0.5-0.4-1-0.6-1.5c-0.2-0.5-0.3-1.1-0.5-1.7
							c-0.2-0.6-0.2-1.3-0.4-1.9c-0.1-0.6-0.1-1.3-0.1-2c0-0.7,0.1-1.3,0.1-2c0.1-0.6,0.2-1.3,0.4-1.9c0.2-0.6,0.4-1.2,0.6-1.7
							c0.2-0.5,0.5-1,0.8-1.4c1.1-1.7,1.1-1.7,1.1-1.7"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M17.7,465.1
							c0,0,2.4,12.2-0.8,28.2c-3.2,16-3.1,16.7-2.6,24"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M219.3,887.4
							c0,0-2-5.7-2-11.4"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M31.9,887.4
							c0,0,2-5.7,2-11.4"/>
						<path fill="#DDC7BF" d="M23,903.8c0,0,7.2-1.8,9.8-4.1c0,0,0.8,5.1,8.7,5.8C41.6,905.5,24.8,904.7,23,903.8"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M42.7,905.5
							c-10.8-1-9.9-6.8-9.9-6.8"/>
						<path fill="#DDC7BF" d="M229.3,903.8c0,0-7.2-1.8-9.8-4.1c0,0-0.8,5.1-8.7,5.8C210.7,905.5,227.5,904.7,229.3,903.8"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M209.6,905.6
							c10.8-1,9.9-6.8,9.9-6.8"/>
						<path fill="#DDC7BF" d="M211.8,461.2c0.2-0.8,0.3-1.7,0.3-2.6c0-2.6-0.3-7.8-0.3-7.8c-0.2-3.9-0.5-7.7-0.9-11.5
							c-2.8-25.6-6.7-45.1-10.4-59.5h-0.3c0,0-0.1,0-0.2,0.1c3.7,14.2,7.6,33.8,10.5,59.4C211.3,446.5,211.7,453.8,211.8,461.2z"/>
						<path fill="#DDC7BF" d="M218,342c0,0,1.7,6.2,9.9,6.2"/>
						<line fill="none" stroke="#DDC7BF" strokeMiterlimit="10" x1="125.6" y1="438.3" x2="125.6" y2="472.5"/>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M119.1,476.8
							c0,0-7.5,5-21,4.5"/>
						<path fill="none" stroke="#DDC7BF" strokeMiterlimit="10" d="M332.2,469.2c-0.7-0.4-2.3-0.8-3.4-1.7c-14.7-11.9-29.2-19-35.8-25.2
							c-7.5-7.1-15.4-53-28.2-80.5c-5-10.7-11.8-17.3-12.3-25.1c-2.5-35-17.3-78-17.3-122.6c0-44.6-32.3-43.1-32.3-43.1l-45.2-18.4
							c-12.8-5.2-12.2-19.1-12.2-19.1v-12.6c6.5-8.7,12.9-23.5,16.4-48.3c1-7,1.2-13.2,0.8-18.5c-0.3-3.3-0.8-6.3-1.5-9
							c-1.9-7.6-5.3-13-9.3-16.9c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.6-0.6-1.2-1.1-1.9-1.6c-0.5-0.4-1.1-0.8-1.6-1.2
							c-0.4-0.3-0.8-0.5-1.2-0.8c-0.6-0.4-1.2-0.7-1.8-1.1c-0.4-0.2-0.8-0.5-1.3-0.7c-1.7-0.9-3.4-1.5-5-2.1c-1.4-0.5-2.8-0.8-4-1.1
							c-1.3-0.3-2.5-0.5-3.6-0.6c-2.9-0.4-5-0.3-5.7-0.3c-0.7,0-3.3-0.1-6.6,0.4c-10.7,1.6-30.1,8.8-30.5,39.6c-0.1,4.2,0.2,8.9,1,14
							c2.7,19.5,7.3,32.7,12.2,41.8c0.7,1.3,1.5,2.5,2.2,3.7c0.6,1,1.3,1.9,1.9,2.8v12.6c0,0,0.6,13.9-12.2,19.1l-45.2,18.4
							c0,0-32.3-1.4-32.3,43.1s-7.4,87.1-9.9,122.1c-0.6,7.8,0.4,18.4-0.2,30.3c-1.1,22.1-1.7,48.3-2.1,68c-0.3,17.2-2,29.3-2,29.3
							s-2.7,39-0.5,44.8c2.1,5.9,1.6,13.1,3.5,16.8c1.9,3.7,14.4,22.1,15.5,25.9c1.1,3.7,5.1,3.2,5.6,0s-8.3-17.1-8-20.5
							c0,0,6.1,2.7,6.4-2.7c0.3-5.3-0.2-24.8,1.6-31.2c1.8-6.4-8-28.8-5.9-37.4c2.1-8.5,8-53.1,14.4-79c3.6-14.7,7.3-27.5,9-52.5
							c1-14.3,7.7-44.1,6.9-64.7l6.7,14.3c14.9,25.9,3.3,72.9,0.9,79.1c-4.8,12.5-12.9,38.7-17.8,82.8c-7.3,65.9,15.9,146,7.4,208.7
							c-8.5,62.7-12,72.3-9.6,111c2.3,37.4-1.6,93.2-1.9,96.4c-0.4,1.6-3.3,13.3-2.2,18.6c1.2,5.7-6.9,16.2-13.6,21.9
							c-6.7,5.7-0.9,7.3,1.9,7.9c2.2,0.5,44.6,6.2,40.6-6.5c-1.2-3.8-2.4-2.2-2-15.7c0.3-7.8,2.6-8.1,1.1-14.8c0,0-2.4-8.9-2.2-11.5
							c1-5.4,14.4-83.9,21.6-108.5c4.9-16.6,7.1-23.8,7.7-76.9c0.3-20.8,8.3-19.7,8.3-55c0-25,20.7-85.9,21.6-138
							c0.3-0.1,0.6-0.3,0.9-0.4c0.8-0.4,1.5-0.8,2.3-1.2c0.7-0.5,1.5-0.9,2.1-1.5c0.5-0.4,1-0.9,1.5-1.3c0.5,0.5,1,0.9,1.5,1.3
							c0.7,0.6,1.4,1,2.1,1.5c0.7,0.4,1.5,0.9,2.3,1.2c0.3,0.1,0.6,0.3,0.9,0.4c0.9,52.2,21.6,115.2,21.6,138c0,35.2,8,34.2,8.3,55
							c0.7,53.1,2.9,60.2,7.7,76.9c7.5,25.6,21.6,108.9,21.6,108.9c0,3-2.2,11.2-2.2,11.2c-1.5,6.7,0.9,7,1.1,14.8
							c0.5,13.5-0.8,12-2,15.7c-4,12.7,38.4,7,40.6,6.5c2.8-0.6,8.6-2.2,1.9-7.9c-6.7-5.7-14.8-16.2-13.6-21.9c1.2-5.7-2.3-18.8-2.3-18.8
							c-0.4-4.5-4.1-59.4-1.8-96.2c2.4-38.7-1.1-48.3-9.6-111c-8.5-62.7,14.7-142.8,7.4-208.7c-4.9-44.1-13-70.3-17.8-82.8
							c-1.6-4.2-14.5-52.3,0.9-79.1l6.7-14.1c-0.7,20.6,7.4,48.3,11.9,61.9c7.1,22,7.8,23.8,12.5,38.2c8.7,26.6,24.9,46.9,38,73.1
							c1.5,3,5.6,10.3,9.3,19c2.9,6.8,5.6,14.5,6.6,21.7c0.9,6.3,10.4,33.8,11.2,35.6c1.6,3.6,7.1,1.9,6.6-0.8c-0.3-1.7-0.8-3.7-1.4-6
							c1.5,5.5,2.9,10.8,3.4,13c1.1,4.5,8.4,4,6.7-2.5c-1.8-7.2-6.6-29.5-6.6-29.5s6.1,25.5,8.2,32c1.3,4.1,7.8,3.4,6.8-2.8
							c-0.2-1.3-0.7-3.5-1.3-6.1c0.7,2.5,1.4,4.4,1.7,4.9c1.3,2,8.3,1.6,5.8-6.2c-2.7-8.4-4.1-16.7-5.7-25.2c-1.5-7.6-2.5-13.5-3.1-14.9
							c1-0.2,1.9-0.5,3-0.8c3.1-0.7,7.4,4.5,12.4,6.1C331.7,476.1,334.4,470.5,332.2,469.2z"/>
						<path fill="#4E5663" d="M170.8,40.1c-0.4-1.2-0.8-2.5-1.2-3.7c-2.9-7.4-11.7-26.5-37.1-29.2c-2-0.2-4-0.3-6.1-0.3
							c-0.2,0-0.4,0-0.6,0c-0.1,0-0.1,0-0.2,0l0,0c-0.1,0-0.2,0-0.4,0C95.1,6.9,85.2,28.5,82,36.5c-7.3,18.4,0.5,42.1,0.5,42.1
							s4.2,13.7,8.2,22c2,5.1,6.4,10.8,11.8,15.1c0.2,0.2,0.6,0.4,0.9,0.7c0.8,0.6,2,1.2,3.8,1.7c0,0,0,0,0,0s0,0,0.1,0
							c0.6,0.2,1.3,0.3,2.1,0.4c1.3,0.3,3,0.7,4.9,1.4c1.9,0.6,3.9,1.5,5.7,2.6c2.9,1.8,5.2,5.7,5.8,5.8c0.6-0.1,2.9-4,5.8-5.8
							c1.6-1,3.4-1.8,5.1-2.4c2.2-0.8,4.2-1.3,5.6-1.6c0.5-0.1,0.9-0.2,1.3-0.3c0.4-0.1,0.7-0.1,0.6-0.2c1.7-0.5,2.8-1.1,3.5-1.7
							c0.3-0.2,0.5-0.4,0.7-0.6c5-5.6,10.4-11,12.3-16.3c4.8-9.9,8.4-20.9,8.4-20.9s0.4-1.3,1-3.6c1-3.9,2.3-10.5,2.5-18
							C172.8,51.6,172.4,45.7,170.8,40.1z"/>
						<g>
							<path fill="#39414C" d="M151.7,23.9c0,2.9-1,10.2-2,12.8c-3.4,9.2-13.1,11.5-23.5,11.5c-7.8,0-15.3-2.1-19.8-7.9
								c-3.4-4.4-5.7-9.4-5.7-13.8c0-3.3,0.4-9.7,1.6-12.5C105.9,5.4,116.4,0,126.4,0c9.6,0,17.4,3.5,21.3,11.5
								C149.3,14.7,151.7,20.2,151.7,23.9z"/>
							<g>
								<path fill="#4E5663" d="M108.8,5.9c0,0,0,0.7-0.1,2c-0.1,1.3-0.1,3.1-0.2,5.3c0,2.2,0,4.8,0.2,7.5c0.1,1.4,0.2,2.8,0.3,4.2
									c0.2,1.4,0.4,2.9,0.6,4.3c0.2,1.4,0.6,2.9,0.9,4.2c0.4,1.4,0.8,2.7,1.4,4c0.3,0.6,0.5,1.2,0.8,1.8c0.1,0.3,0.3,0.6,0.4,0.9
									c0.2,0.3,0.3,0.5,0.5,0.8c0.6,1.1,1.3,2,2.1,2.8c0.3,0.4,0.7,0.8,1.1,1.1c0.3,0.4,0.7,0.7,1.1,0.9c0.7,0.6,1.4,0.9,1.9,1.3
									c0.6,0.3,1,0.5,1.3,0.7c0.3,0.1,0.5,0.2,0.5,0.2s-0.2-0.1-0.5-0.2c-0.3-0.1-0.8-0.3-1.4-0.5c-0.6-0.3-1.3-0.6-2-1.2
									c-0.4-0.2-0.8-0.5-1.2-0.9c-0.4-0.3-0.8-0.7-1.2-1.1c-0.8-0.8-1.6-1.7-2.3-2.8c-0.2-0.3-0.4-0.5-0.5-0.8
									c-0.2-0.3-0.3-0.6-0.5-0.9c-0.3-0.6-0.6-1.2-0.9-1.9c-0.6-1.3-1-2.6-1.5-4.1c-0.4-1.4-0.8-2.9-1-4.3c-0.3-1.5-0.4-2.9-0.6-4.4
									c-0.1-1.5-0.2-2.9-0.3-4.3c-0.1-2.8,0-5.4,0.2-7.6c0.1-2.2,0.4-4,0.6-5.3C108.7,6.6,108.8,5.9,108.8,5.9z"/>
							</g>
							<g>
								<path fill="#4E5663" d="M115.7,2.5c0,0,0.8,0.3,2.1,0.9c1.3,0.6,3.2,1.5,5.4,2.7c1.1,0.6,2.3,1.3,3.5,2c1.2,0.7,2.5,1.6,3.8,2.5
									c1.3,0.9,2.6,1.9,3.9,2.9c1.3,1,2.6,2.1,3.9,3.2c1.2,1.1,2.4,2.4,3.5,3.6c0.6,0.6,1.1,1.3,1.6,1.9c0.5,0.7,1,1.3,1.4,2
									c0.9,1.3,1.6,2.7,2.2,4c0.1,0.3,0.3,0.7,0.4,1c0.1,0.3,0.2,0.7,0.3,1c0.1,0.3,0.2,0.6,0.3,1c0.1,0.3,0.1,0.6,0.2,1
									c0.2,0.6,0.2,1.2,0.3,1.8c0,0.3,0,0.6,0.1,0.8c0,0.3,0,0.5,0,0.8c0,1-0.2,1.9-0.3,2.6c-0.1,0.7-0.4,1.3-0.5,1.6
									c-0.1,0.4-0.2,0.6-0.2,0.6s0-0.2,0.1-0.6c0.1-0.4,0.2-0.9,0.3-1.7c0.1-0.7,0.2-1.6,0.1-2.6c0-0.3,0-0.5,0-0.8
									c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.6-0.1-1.1-0.3-1.7c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.2-0.6-0.3-1
									c-0.1-0.3-0.3-0.6-0.4-0.9c-0.6-1.3-1.4-2.6-2.2-3.8c-0.4-0.6-0.9-1.3-1.4-1.9c-0.5-0.6-1-1.2-1.6-1.8c-1.1-1.2-2.3-2.4-3.5-3.5
									c-1.2-1.1-2.5-2.2-3.8-3.2c-1.3-1-2.6-2-3.9-2.9c-1.3-0.9-2.5-1.8-3.7-2.5c-1.2-0.8-2.4-1.5-3.4-2.2c-2.1-1.3-4-2.4-5.2-3
									C116.4,2.9,115.7,2.5,115.7,2.5z"/>
							</g>
						</g>
						<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M132.4,477
							c0,0,7.5,5,21,4.5"/>
					</g>
					<g id="rear_layer15">
						<path fill="#FFFFFF" d="M211.5,825.6c-4.1-19.5-9.6-46.3-8.3-67.1c1.8-29.7,0.1-41.4-4.9-76.2l-0.1-0.5c-1.3-9.2-2.8-19.6-4.6-32.3
							c-4.5-33-0.5-70.7,3.4-107.2c2.4-22.2,4.8-45.1,5.3-67c3.8-2.5,6.7-5.3,8.5-8.3c0.1,24.8-2.7,50.9-5.4,76.2
							c-3.8,36-7.8,73.2-3.4,105.2c1.8,13,3.3,23.5,4.6,32.7c5.1,35.5,6.9,47.4,5,78c-1.3,21.3-0.7,48,0.2,68.2
							C211.7,826.7,211.6,826.2,211.5,825.6z"/>
					</g>
					<g id="rear_layer22">
						<g>
							<path fill="#FFFFFF" d="M191.1,843.2c-0.6-6.9-1.2-16.3-1.8-27.3c-1.4-25.8-3.2-57.8-7.5-72.5l-0.1-0.5
								c-4.4-15.2-6.5-22.2-7.1-73.1c-0.1-10.8-2.1-16.9-3.9-22.9c-2.1-6.8-4.3-13.8-4.3-32c0-10.6-3.5-26.7-7.9-47.1
								c-5.1-23.6-11.4-53-13.2-79.8c-0.2-2.5-0.2-3.4-0.2-5.3c5.7,1.2,13.4,1.6,20.5,1.8c0.7,0,1.5,0,2.2,0c11.9,0,23.5-2.9,32.2-7.9
								c-0.6,21.3-2.9,43.7-5.2,65.3c-3.9,36.6-7.9,74.4-3.4,107.8c1.7,12.7,3.2,23.1,4.6,32.3l0.1,0.5c5,34.6,6.7,46.3,4.9,75.8
								c-1.3,21.1,4.3,48.1,8.4,67.7c1.3,6.1,2.4,11.4,3.2,15.9L191.1,843.2z"/>
						</g>
					</g>
					<g id="rear_layer14">
						<g>
							<path fill="#FFFFFF" d="M187,816c-1.4-25.6-3.2-57.5-7.4-72l-0.1-0.5c-4.5-15.4-6.6-22.5-7.2-73.7c-0.1-10.4-2-16.4-3.8-22.2
								c-2.2-6.9-4.4-14.1-4.4-32.6c0-10.4-3.4-26.4-7.8-46.6c-4.6-21.3-10.6-52.4-13-77c-2,15.4-2.6,29.3-1.4,49.5
								c1.3,6.4,4.4,20.1,5.8,26.8c4.3,19.7,8,36.7,8,47.3c0,19.8,2.6,28,4.8,35.2c1.8,5.7,3.3,10.6,3.5,19.8c0.7,52.4,2.8,59.8,7.5,76
								l0.1,0.5c3.5,12,8.8,37.8,16,77C187.3,821,187.2,818.6,187,816z"/>
							<path fill="#FFFFFF" d="M141.8,540.9c-0.5-7-0.8-16.5-0.6-25.2c0.3-9.9,1.3-19,1.9-24.3c-0.1-1.4-0.5-7.8-0.6-9.2
								c-3.8-0.7-6.6-1.7-8.5-2.5c0,0.9,0.1,1.7,0.1,2.6l0.1,1.6c0.1,1.2,0.1,2.5,0.2,3.7c0,0,0.1,1.2,0.1,1.7c0.1,1.4,0.2,2.7,0.3,4.1
								l0.1,1.2c0.2,1.7,0.3,3.5,0.5,5.2l0.2,1.6c0.1,1.3,0.3,2.5,0.4,3.8l0.2,2c0.1,1.2,0.3,2.3,0.5,3.5l0.3,1.9c0.2,1.7,0.5,3.3,0.7,5
								l0.3,1.8c0.2,1.1,0.4,2.3,0.5,3.4c0.1,0.8,0.3,1.7,0.4,2.5c0.1,0.9,0.3,1.8,0.5,2.6c0.2,0.9,0.3,1.8,0.5,2.7L141.8,540.9z"/>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M141.8,540.9c-0.5-7-0.8-16.5-0.6-25.2
								c0.3-9.9,1.3-19,1.9-24.3c-0.1-1.4-0.5-7.8-0.6-9.2c-3.8-0.7-6.6-1.7-8.5-2.5c0,0.9,0.1,1.7,0.1,2.6l0.1,1.6
								c0.1,1.2,0.1,2.5,0.2,3.7c0,0,0.1,1.2,0.1,1.7c0.1,1.4,0.2,2.7,0.3,4.1l0.1,1.2c0.2,1.7,0.3,3.5,0.5,5.2l0.2,1.6
								c0.1,1.3,0.3,2.5,0.4,3.8l0.2,2c0.1,1.2,0.3,2.3,0.5,3.5l0.3,1.9c0.2,1.7,0.5,3.3,0.7,5l0.3,1.8c0.2,1.1,0.4,2.3,0.5,3.4
								c0.1,0.8,0.3,1.7,0.4,2.5c0.1,0.9,0.3,1.8,0.5,2.6c0.2,0.9,0.3,1.8,0.5,2.7L141.8,540.9z"/>
						</g>
					</g>
					<g id="rear_layer13">
						<g>
							<path fill="#FFFFFF" d="M64,816c1.4-25.6,3.2-57.5,7.4-72l0.1-0.5c4.5-15.4,6.6-22.5,7.2-73.7c0.1-10.4,2-16.4,3.8-22.2
								c2.2-6.9,4.4-14.1,4.4-32.6c0-10.4,3.4-26.4,7.8-46.6c4.6-21.3,10.6-52.4,13-77c2,15.4,2.6,29.3,1.4,49.5
								c-1.3,6.4-4.4,20.1-5.8,26.8c-4.3,19.7-8,36.7-8,47.3c0,19.8-2.6,28-4.8,35.2c-1.8,5.7-3.3,10.6-3.5,19.8
								c-0.7,52.4-2.8,59.8-7.5,76l-0.1,0.5c-3.5,12-8.8,37.8-16,77C63.7,821,63.9,818.6,64,816z"/>
							<path fill="#FFFFFF" d="M109.3,540.9c0.5-7,0.8-16.5,0.6-25.2c-0.3-9.9-1.3-19-1.9-24.3c0.1-1.4,0.5-7.8,0.6-9.2
								c3.8-0.7,6.6-1.7,8.5-2.5c0,0.9-0.1,1.7-0.1,2.6l-0.1,1.6c-0.1,1.2-0.1,2.5-0.2,3.7c0,0-0.1,1.2-0.1,1.7c-0.1,1.4-0.2,2.7-0.3,4.1
								l-0.1,1.2c-0.2,1.7-0.3,3.5-0.5,5.2l-0.2,1.6c-0.1,1.3-0.3,2.5-0.4,3.8l-0.2,2c-0.1,1.2-0.3,2.3-0.5,3.5l-0.3,1.9
								c-0.2,1.7-0.5,3.3-0.7,5l-0.3,1.8c-0.2,1.1-0.4,2.3-0.5,3.4c-0.1,0.8-0.3,1.7-0.4,2.5c-0.1,0.9-0.3,1.8-0.5,2.6
								c-0.2,0.9-0.3,1.8-0.5,2.7L109.3,540.9z"/>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M109.3,540.9c0.5-7,0.8-16.5,0.6-25.2
								c-0.3-9.9-1.3-19-1.9-24.3c0.1-1.4,0.5-7.8,0.6-9.2c3.8-0.7,6.6-1.7,8.5-2.5c0,0.9-0.1,1.7-0.1,2.6l-0.1,1.6
								c-0.1,1.2-0.1,2.5-0.2,3.7c0,0-0.1,1.2-0.1,1.7c-0.1,1.4-0.2,2.7-0.3,4.1l-0.1,1.2c-0.2,1.7-0.3,3.5-0.5,5.2l-0.2,1.6
								c-0.1,1.3-0.3,2.5-0.4,3.8l-0.2,2c-0.1,1.2-0.3,2.3-0.5,3.5l-0.3,1.9c-0.2,1.7-0.5,3.3-0.7,5l-0.3,1.8c-0.2,1.1-0.4,2.3-0.5,3.4
								c-0.1,0.8-0.3,1.7-0.4,2.5c-0.1,0.9-0.3,1.8-0.5,2.6c-0.2,0.9-0.3,1.8-0.5,2.7L109.3,540.9z"/>
						</g>
					</g>
					<g id="rear_layer23">
						<path fill="#FFFFFF" d="M38.7,842c0.8-4.5,1.9-9.7,3.1-15.7c4.1-19.7,9.7-46.6,8.4-67.7c-1.8-29.4-0.1-41.1,4.9-75.7l0.1-0.5
							c1.3-9.2,2.8-19.6,4.6-32.3c4.5-33.3,0.5-71.2-3.4-107.8c-2.3-21.5-4.6-43.8-5.2-65.1c8.6,5,20.1,7.9,32,7.9c1,0,2,0,3.1-0.1
							c7-0.3,14-1.5,18.2-2.2c0,0,1.8-0.3,1.9-0.3c-0.2,2.2-0.2,2.2-0.4,3.9c-1.7,27.3-8.1,57.3-13.3,81.5c-4.4,20.4-7.9,36.5-7.9,47.1
							c0,18.2-2.2,25.2-4.3,32c-1.9,6-3.8,12.1-3.9,22.9c-0.6,50.9-2.7,57.9-7.1,73.1l-0.1,0.5c-4.3,14.7-6.1,46.8-7.5,72.5
							c-0.6,10.9-1.1,20.4-1.8,27.3L38.7,842z"/>
					</g>
					<g id="rear_layer12">
						<path fill="#FFFFFF" d="M39.3,827.3c0.9-20.1,1.6-46.8,0.2-68.1c-1.9-30.6-0.2-42.5,5-78c1.4-9.3,2.9-19.8,4.6-32.7
							c4.4-32,0.4-69.2-3.4-105.2c-2.7-25.4-5.5-51.5-5.4-76.4c1.7,2.9,4.3,5.6,8.5,8.5c0.5,21.9,2.9,44.8,5.3,67
							c3.9,36.5,7.9,74.2,3.4,107.2c-1.7,12.7-3.2,23.1-4.6,32.3l-0.1,0.5c-5,34.8-6.7,46.5-4.9,76.2c1.3,20.8-4.3,47.6-8.3,67.1
							C39.5,826.3,39.4,826.8,39.3,827.3z"/>
					</g>
					<g id="rear_layer8">
						<path fill="none" stroke="#FFFFFF" strokeMiterlimit="10" d="M98,481.3c13.5,0.5,21-4.5,21-4.5s4.3-2,6.5-4.3c0,0,1.8,2.1,6.9,4.5
							c0,0,7.5,5,21,4.5"/>
						<path fill="#FFFFFF" d="M124.7,473.2c-7,6.7-20.7,8.8-38.6,9.5c-1,0-2,0.1-3,0.1c-15.7,0-30.6-5.2-38.1-13.2
							c-1.2-1.3-2.2-2.7-2.9-4.1c-0.2-0.3-0.3-0.6-0.4-0.8l-0.1-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3
							c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.5-0.5-1.6-0.8-2.9c0-7.3,0.4-12.7,1.3-20.1c2.4-22,5.8-41.4,9.9-57.9c1,0.6,2.5,1.3,4.6,2.1
							c0.4,0.1,0.8,0.3,1.2,0.4c0.3,0.1,0.7,0.2,1,0.4c0.5,0.2,1.1,0.4,1.7,0.5l0.5,0.2c0.6,0.2,1.2,0.4,1.9,0.5l0.4,0.1
							c1.9,0.5,6,1.2,10.7,1.9c6.3,1,14.2,2.3,16.4,3.3c9.5,3.9,12.4,12.1,12.7,13l0,0.1l0.1,0.1c0.5,1,1.1,2.1,1.6,3.2
							c3.8,7.7,8.1,16.5,21.3,17.2l0.1,0l0.1,0c13.1-0.8,17.3-9.6,20.9-17.3c0.5-1.1,1.1-2.2,1.6-3.3l0-0.1l0-0.1
							c0.3-0.9,3.1-9.1,12.6-13.2c2.2-0.9,10.1-2.3,16.4-3.4c4.7-0.8,8.8-1.5,10.6-2l0.4-0.1c0.6-0.2,1.3-0.4,1.9-0.6l0.5-0.2
							c0.6-0.2,1.1-0.4,1.7-0.6c0.3-0.1,0.7-0.2,1-0.4c0.4-0.1,0.8-0.3,1.2-0.4c2.1-0.8,3.7-1.6,4.8-2.2c4.3,17,7.7,36.4,10.1,57.8
							c0.7,6.6,1.2,13.7,1.3,21.5c-0.1,0.5-0.2,1-0.4,1.5l-0.1,0.2c-0.1,0.2-0.2,0.5-0.3,0.7l-0.1,0.2c-0.1,0.3-0.2,0.5-0.4,0.8
							c-0.7,1.5-1.7,2.9-2.9,4.2c-7.6,8.3-22.8,13.6-38.8,13.6c0,0,0,0,0,0c-0.7,0-1.4,0-2.2,0c-28-0.3-35-5.3-39.2-9.1l-0.9-0.7"/>
						<path opacity="5.000000e-02" fill="none" stroke="#020202" strokeMiterlimit="10" enableBackground="new    " d="M98,481.3
							c13.5,0.5,21-4.5,21-4.5s4.3-2,6.5-4.3c0,0,1.8,2.1,6.9,4.5c0,0,7.5,5,21,4.5"/>
					</g>
					<g id="rear_layer11">
						<path fill="#FFFFFF" d="M283.4,437.9c-1.8-5-9.2-25.8-21.3-56c-1-2.5-2-5.1-3.1-7.7l-1.6-4c-0.5-1.3-1.1-2.7-1.7-4.1
							c-0.5-1.3-1.2-2.6-1.9-4c-0.2-0.4-0.5-0.8-0.7-1.2c-0.5-0.8-1-1.6-1.5-2.4l-0.4-0.7c-0.3-0.5-0.7-1.1-1-1.6
							c-0.9-1.4-1.8-2.9-2.7-4.4c-0.6-1-1.1-2-1.6-3c-0.3-0.5-0.5-1-0.7-1.5c-0.8-1.6-1.3-3.2-1.8-4.8c-0.4-1.3-0.6-2.6-0.8-3.8
							c-0.1-0.4-0.1-0.9-0.1-1.3c-0.1-1.3-0.2-2.6-0.3-4c-0.4-3.8-0.9-7.8-1.6-12.3c-0.5-3.5-1.2-7.2-1.9-11.5c-0.8-4.3-1.6-8.6-2.3-11.9
							c-0.4-2-0.8-4.1-1.2-6.1c-0.8-4.1-1.6-8.3-2.4-12.5c-0.9-4.8-1.7-9-2.4-13c-0.5-3-1-6-1.5-8.9c-0.6-4.2-1.2-8.2-1.7-12
							c-0.4-3.3-0.8-6.6-1-9.8c-0.1-1.5-0.3-3-0.4-4.4c-0.1-1.2-0.2-2.3-0.2-3.5c3.9-1,6.5-2.6,8.2-4.3c1,23.1,5.4,45.3,9.6,66.7
							c3.3,16.7,6.4,32.5,7.5,46.9c0.4,5.2,3.2,9.7,6.4,14.9c2,3.1,4.2,6.7,6.1,10.8c6.1,13.3,11.2,30.8,15.6,46.3
							c3,10.7,5.9,20.8,8.7,27.3L283.4,437.9z"/>
					</g>
					<g id="rear_layer21">
						<path fill="#FFFFFF" d="M274.8,441.6c-4-8.1-33-66.9-37.8-81.6l-3.1-9.5c-0.8-2.3-1.4-4.5-2.1-6.7c-1.5-4.9-3.4-11-7.2-21.9
							c-8.5-24-13.3-72.6-15.1-93.7c1,0,2,0,2.9,0c1.2,0,3.3-0.1,3.3-0.1l3.2-0.1c0.3,0,0.6,0,0.8,0c1.2-0.1,2.4-0.2,3.6-0.4l0,0l0.1,0
							c1.3,22.8,5.7,45.1,9.6,64.7c3.2,16.4,6.3,31.8,7.3,45.5c0.6,7.8,4.4,13.9,8,19.8c0.3,0.5,0.7,1.1,1,1.6c1.6,2.5,3.2,5.1,4.4,7.9
							c16,39,25.8,66.3,27.7,71.7L274.8,441.6z"/>
					</g>
					<g id="rear_layer10">
						<path fill="#FFFFFF" d="M268.7,444.1c-1.1-2.2-2.1-4-2.8-5.3c-0.6-1.1-1-2-1.4-2.7c-4.9-9.7-10.2-18.7-15.3-27.4
							c-8.8-14.9-17.2-28.9-22.6-45.5l-3.1-9.5c-2.4-7.4-4.2-12.7-9.4-28.8c-8.2-25.4-12.3-46.7-11.8-61.4c0-0.3-0.1-0.6-0.3-1
							c2-4.6,3.6-10.9,4.5-18.1c0.4-2.7,0.7-5.5,1-8.4c2.2,23.2,6.8,64,14.4,85.8l0.2,0.5c0.5,1.5,1,2.9,1.5,4.2l0.4,1.3
							c0.3,0.8,1.2,3.6,1.2,3.6c0.4,1.1,0.7,2.2,1,3.2l0.9,2.8c0.3,0.9,0.5,1.7,0.8,2.5l0.5,1.5l0.8,2.6c0.4,1.3,0.7,2.4,1.1,3.6
							c0.3,1.1,0.7,2.2,1.1,3.4l3.1,9.6c0.3,0.8,0.6,1.8,1,2.8c0.4,1.1,0.9,2.2,1.4,3.5c0.3,0.7,0.6,1.5,1,2.3c1.1,2.7,2.5,5.8,4.1,9.3
							c0.7,1.5,1.4,3.1,2.2,4.8c0,0,1.8,3.9,2.3,5c1.1,2.3,2.1,4.6,3.2,6.9c1.1,2.3,2.2,4.7,3.3,7.1l1.7,3.5c2.2,4.7,4.4,9.3,6.5,13.6
							c1,2.1,2,4.2,3,6.2l2.7,5.5l3.3,6.7l2.3,4.6L268.7,444.1z"/>
					</g>
					<g id="rear_layer19">
						<path fill="#FFFFFF" d="M152.6,386.6L152.6,386.6c0-2.1,0.1-5.1,0.4-8.9c0.1-1.8,0.3-3.8,0.4-6l0-0.5l0,0v-0.1
							c0.1-1.2,0.2-2.5,0.3-3.9c2.3-29.7,7.4-79.7,13.6-124.2c0.7-4.7,1.3-9.5,2-14.2c15.2-0.1,27.8-0.1,36.6-0.2
							c-0.2,4.7-0.7,9.8-1.5,15.6c-0.8,5.8-2.1,13.2-4.9,18.6l-3.2,6.3l-3.7,7.8c-15.3,26.7-3.2,74.5-1,80.4c1.7,4.4,4.3,11.8,7,22.3
							c0,0-0.6,0.3-0.6,0.3l-0.7,0.4l-0.9,0.4l-0.5,0.2l-1.1,0.5l-0.5,0.2c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.2-0.9,0.4l-0.2,0.1
							c-0.3,0.1-0.6,0.2-1,0.4c-0.5,0.2-0.9,0.3-1.4,0.5c-0.4,0.1-0.9,0.3-1.3,0.4l-0.2,0c-0.7,0.2-1.4,0.4-2.2,0.6
							c-2,0.5-5.7,1.1-9.6,1.8c-7.1,1.2-14.4,2.5-16.9,3.6c-2.9,1.3-5.5,3-7.8,5.2L152.6,386.6z"/>
					</g>
					<g id="rear_layer24">
						<path fill="#FFFFFF" d="M125.6,423.3c-11.8-0.6-15.6-8.4-19.3-15.9c-0.5-1.1-1-2.1-1.6-3.1c-0.3-0.9-1.3-3.5-3.6-6.5
							c-0.5-5.7-0.8-10.1-0.8-10.1l0-1l0-1.7l-0.1-2.1l-0.1-2.3l-0.1-1.2l-0.2-2.5l-0.2-2.8l-0.1-1.6c-1.9-26.8-7.9-89.2-16-143.6
							c11,0,23,0.1,35.1,0.1l7,0c14.3,0,28.6,0,41.4-0.1c-0.7,4.6-1.4,9.3-2,13.9c-6.2,44.6-11.3,94.6-13.6,124.3
							c-0.1,1.4-0.2,2.6-0.3,3.9l0,0v0.1l0,0.4c-0.2,2.2-0.3,4.2-0.4,6c-0.3,4.7-0.4,8.1-0.4,10.2l0,0.8l-0.4,8.9c-2.4,3-3.4,5.8-3.7,6.6
							c-0.5,1.1-1.1,2.2-1.6,3.3C141,414.8,137.3,422.6,125.6,423.3z"/>
					</g>
					<g id="rear_layer18">
						<path fill="#FFFFFF" d="M98.7,395c-2.3-2.2-4.9-3.9-7.9-5.1c-2.5-1-9.8-2.2-16.9-3.4c-4.5-0.7-8.7-1.4-10.4-1.9l-0.4-0.1
							c-0.6-0.2-1.2-0.3-1.8-0.5l-0.5-0.2c-0.6-0.2-1.1-0.3-1.6-0.5c-0.3-0.1-0.7-0.2-1-0.3c-0.4-0.1-0.8-0.3-1.1-0.4
							c-2.4-0.9-4-1.8-4.9-2.3c1.1-4.3,2.3-8.4,3.5-12.3c1.2-3.9,2.5-7.5,3.7-10.7c0.4-1.1,1.3-3.9,2.2-8c2.6-11.1,10.1-49.2-3.2-72.4
							l-7.7-16.5l-0.4,0.1c-2.3-5.9-3.8-14.1-4.6-24.5c-0.2-2.4-0.3-4.9-0.4-7.4c8.7,0.1,21,0.2,35.8,0.2c8.1,54.4,14.1,116.9,16,143.8
							l0.3,4.9l0.1,2l0.1,2.5l0.1,2.5l0.1,1.9l0,0.8l0,0.7C98.1,387.8,98.3,390.8,98.7,395z"/>
					</g>
					<g id="rear_layer7">
						<path fill="#FFFFFF" d="M20.5,447.8c-0.2-4.4-0.3-9.9-0.2-16l0-2.1c0-1,0-2.1,0.1-3.1l0.1-2.3c0-1,0.1-2.1,0.1-3.2l0.1-1.6l0.1-1.8
							l0.1-1.7l0.1-1.7l0.1-1.7l0.1-1.7l0.1-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.2-1.7l0.3-1.7l0.3-1.7
							l0.3-1.7l0.3-1.7l0.3-1.7l0.3-1.7l0.4-1.7l0.4-1.7c0.1-0.6,0.3-1.1,0.4-1.7c0.3-1.4,0.6-2.8,0.9-4.1c0.3-1.4,0.6-2.8,0.9-4.1l0-0.1
							c0.3-1.3,0.5-2.7,0.8-4c0.2-1.1,0.4-2.1,0.5-3.1c0,0,0.4-2.3,0.5-3l0.2-1.1c0.1-0.7,0.2-1.3,0.3-2c0.1-0.6,0.3-2,0.3-2l0.2-1.3
							l0.4-2.6l0.3-2.1l0.2-1.8l0.2-1.9c0.2-1.7,0.4-3.3,0.5-4.8l0-0.2c0.2-1.6,0.3-3.1,0.4-4.5c0.1-0.9,0.2-1.8,0.2-2.6l0-0.3
							c0.1-1.2,0.2-2.3,0.3-3.4l0.3-4c0.4-5.5,1.5-12.5,2.7-20.7l0-0.1c1.4-9.2,5.2-47.4,7.5-70.3c0.8,11.3,2.6,20.3,5.3,26.5
							c-0.1,0.2-0.1,0.4-0.1,0.7c0.5,13.1-2.2,30.5-4.3,44.4c-1.2,8-2.3,15-2.6,20.1c-1.6,22.7-4.7,35.2-8,48.4l-1,3.9
							c-4.1,16.4-7.9,40.3-10.7,57.8C21.5,441.5,21,444.9,20.5,447.8z"/>
					</g>
					<g id="rear_layer20">
						<path fill="#FFFFFF" d="M5.2,449.7c2.8-18.1,7.9-51.5,11-77.3c1.9-16,2.2-29.1,2.2-33.5c0-0.7,0-1.2,0-1.3
							c0.9-11.8,2.3-24.5,3.8-38c2.4-21.8,5.2-46.3,5.9-71.7c0.3,0,0.5,0.1,0.8,0.1c0.9,0.1,1.7,0.2,2.5,0.2c1.7,0.1,5.3,0.2,10.7,0.2
							c-2.1,21-6.7,67.9-8.3,78.2c-1.3,8.3-2.3,15.4-2.7,20.9l0,0.6c-0.3,4.4-0.8,11.8-1.9,20.9c-1.3,11.1-3.2,21.5-5.4,31
							c-7.4,30.9-5.7,67.3-5,77.1L5.2,449.7z"/>
					</g>
					<g id="rear_layer6">
						<path fill="#FFFFFF" d="M5.5,431.6c0.3-18.5,0.9-43.8,2-65.1c0.3-6.2,0.2-12.1,0.1-17.3c-0.1-5-0.2-9.2,0.1-12.9
							c0.9-12,2.3-24.9,3.8-38.5c2.5-22.7,5.4-48.4,6-74.6c1.8,1.6,4.4,3.3,8.3,4.2c-0.8,25.2-3.5,49.7-5.9,71.3
							c-1.5,13.5-2.9,26.3-3.8,38.2c0,0.2,0,0.7,0,1.4c-0.1,4.3-0.3,17.4-2.2,33.3c-0.2,1.6-0.4,3.3-0.6,5l-0.6,5
							c-0.4,3.2-0.8,6.5-1.3,9.8c-0.4,3.1-0.9,6.2-1.3,9.3l-0.9,6.1L8.7,410c-0.4,3-0.9,6-1.3,8.9l-0.9,5.8
							C6.2,427.1,5.9,429.4,5.5,431.6z"/>
					</g>
					<g id="rear_layer5">
						<path fill="#FFFFFF" d="M118.7,226.6c-12.6,0-25,0-36.4-0.1c-16-0.1-29.1-0.1-38-0.2l-1,0c-6.1-0.1-10-0.2-11.8-0.3
							c-0.8,0-1.5-0.1-2.4-0.2c-0.7-0.1-1.3-0.2-2-0.3c-5.4-1.1-8.2-3.6-9.7-5.5c0-1.9,0-3.9,0-5.8c0-40.3,27.2-41.5,30.3-41.5
							c0.2,0,0.3,0,0.3,0c0.2,0,0.4,0,0.7-0.1l1.4-0.6c0.5,0,1.1,0,1.8,0c4.2,0,13.2,0.8,26.6,5.8c11.5,4.3,29.9,7.2,46.8,7.2
							c16.9,0,35.3-2.8,46.9-7.2c13.4-5,22.4-5.8,26.6-5.8c0.7,0,1.3,0,1.8,0l1.4,0.6c0.2,0.1,0.4,0.1,0.6,0.1h0.5l0,0
							c1.8,0,9.2,0.4,16.2,5.5c9.3,6.8,14,18.9,14,36c0,1.8,0,3.6,0.1,5.8c-1.4,2-4.2,4.4-9.5,5.5c-1.4,0.3-2.9,0.5-4.5,0.6l-1.7,0.1
							l-2.4,0.1c-0.9,0-1.9,0-3,0.1c-1.2,0-5.4,0.1-5.4,0.1c-8.9,0.1-22.3,0.2-38.7,0.2h-0.1c-13.1,0-27.8,0.1-42.5,0.1L118.7,226.6z"/>
					</g>
					<g id="rear_layer4">
						<path fill="#FFFFFF" d="M137.9,120.8c1.9-0.6,3.5-1,4.7-1.3c0.4-0.1,0.9-0.2,1.4-0.3c0.2,0,0.4-0.1,0.7-0.1
							c-0.2,0.3-0.4,0.5-0.5,0.8c-0.2,0.3-0.3,0.6-0.3,1v12.6c0,0.1-0.5,15,13.3,20.6l38.6,15.7l-1,0l-0.1,0c-6.9,0.6-14.7,2.5-23.1,5.7
							c-7.1,2.7-17.4,4.9-28.3,6L137.9,120.8z"/>
					</g>
					<g id="rear_layer17">
						<path fill="#FFFFFF" d="M125.5,182.5c-5,0-10.2-0.2-15.4-0.7l5.2-60.4c1.5,0.6,2.9,1.3,4.1,2c1.7,1,3.2,2.9,4.2,4.2
							c0.9,1.1,1.3,1.6,1.9,1.8l0.3,0.1l0.3-0.1c0.6-0.1,1-0.7,1.9-1.8c1-1.3,2.5-3.2,4.2-4.2c1-0.6,2.2-1.2,3.5-1.8l5.2,60.1
							C135.6,182.3,130.4,182.5,125.5,182.5L125.5,182.5z"/>
					</g>
					<g id="rear_layer16">
						<path fill="#FFFFFF" d="M107.8,181.6c-11-1.2-21.2-3.4-28.3-6c-8.4-3.2-16.2-5.1-23.1-5.7l-0.1,0l-0.8,0L94.1,154
							c13.8-5.6,13.3-20.5,13.3-20.6l0-12.6c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.4-0.5-0.5-0.8l0.5,0.1c0.7,0.2,1.4,0.3,2.2,0.4
							c1.2,0.3,2.6,0.6,3.8,1L107.8,181.6z"/>
					</g>
					</svg>
					</div>
				</Slider>
			</React.Fragment>
		)

	}

	renderMaleMatriz = () => {

		return (

			<React.Fragment>
				<div className="shape not-slider male-front">
				<svg version="1.1" id="front_layer_male" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
							viewBox="0 0 357.3 965.6" enableBackground="new 0 0 357.3 965.6" >
								<text textDecoration="underline" fontFamily="PoppinsBold" x="25%" y="120" fill="black" fontSize="15px">Derecha</text>
								<text textDecoration="underline" fontFamily="PoppinsBold" x="75%" y="120" fill="black" fontSize="15px">Izquierda</text>
						<g id="cuerpo_x2F_sombras_x2F_pelo_x2F_pantalon">
							<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M355.3,498.9
								c0,0-1.2-8.4-1.8-21.1c0.6,0-1.5-0.7,0.1-51.3c1.7-50.6-2.2-64.8-2.5-67.6c-1.7-14.6-0.7-3.8,0.7-27.7c2.2-35.8-2.6-56.7-2.9-64.9
								s-1.7-8.5,0.6-27.5c2.3-19-6-49.2-21.4-61.3c-10.9-8.5-23.6-7.2-23.6-7.2c-8.2-3.2-39-19.9-49.3-23.8c0,0-0.2-0.1-0.2-0.1
								c-13.2-5-13.2-19.2-13.2-19.2v-17.9c-10.7,18.6-24.4,19.3-28,19.3c-3.7,0-17.3-0.7-28-19.3v17.9c0,0,0,14.3-13.2,19.2
								c0,0-0.2,0.1-0.2,0.1c-10.2,3.9-41.1,20.6-49.3,23.8c0,0-12.8-1.4-23.7,7.2c-15.4,12.1-23.2,42.3-21,61.3c2.3,19,0.9,19.2,0.6,27.5
								c-0.3,8.3-4.5,22.5-4.6,58.3c0,3.8-1.3,28.1-1.3,28.1c-0.3,2.9-14,23.5-18.6,55.7c-7.1,50.1-7.6,53.7-7.6,55.1
								c-2.8,8.4-5.4,14.7-7.9,17c-7.1,6.5-18,15.6-33.9,28c-1.2,1-2.9,1.4-3.7,1.8c-2.5,1.3,0.5,7.1,5.9,5.4c5.4-1.7,10.1-7,13.5-6.3
								c1.1,0.3,2.2,0.6,3.2,0.8c-0.7,1.4-2.1,7.3-3.4,15.9c-1.4,8.9-2.8,17-4.6,27.6c-1.4,8.5,4.9,8.5,6.3,6.5c0.4-0.6,8.1-33.7,8.9-36.4
								c-0.7,2.7-8.2,36.2-8.4,37.6c-1.4,8.3,5.9,7.2,7.4,3c0.2-0.7,0.5-1.6,0.9-2.7c-1.6,6.7,5.1,8.2,7.5,2.5c0.9-2.2,2.8-7.6,4.4-13.4
								c-0.6,2.4-1.2,4.5-1.5,6.3c-0.6,2.8,5.5,4.6,7.1,0.8c0.8-1.9,9.6-24,12.1-30.3c3-7.6,3.9-9.3,6.5-16.9c3.3-9.3,3.9-18.1,5-23.8
								c0.8-4.3,3-16.9,5.6-23.5c0,0,24.2-53.7,26.7-69.2c4.9-30.5,12.4-38.1,14.4-52.3c2.2-16.2,10.1-48.7,7.7-66.3
								c1,7.3,3.4,9.4,4.6,13.4c2.4,8.5,13.3,37.8,12.1,63.2c-0.4,8.7-3.6,23.6-3,34.6c0,0-9.4,19.2-11,39.6c-1.6,20.3-2.2,28-4.4,44.6
								c-2.2,16.7-15.7,47.5-9.4,132c2.2,29.1,8.2,33,5.7,69.8c-2.1,31.7-19.5,64.9-14.5,118.2c4.9,53.2,6.7,76.7,6.6,95.5h-0.1
								c-0.6,11.9-1.2,20.2-1.3,21.9c0,0-3.9,14.6-2.6,21.1c1.3,6.4-7.7,18.1-15.3,24.5s-1,8.2,2.1,8.9c2.5,0.5,38.7,7.2,41.9-7.3
								c0,0,0.9-2.5,1.4-17.6c0.3-8.7,2.9-9,1.3-16.6c0,0-2.5-9.2-2.5-12.5c0,0,1.5-9,3.9-22.4c0,0,13-60.8,19.1-94.2
								c1.6-8.9,6.9-29.2,7.1-31.3c0,0,6.1-22.1,5-60.2c-0.4-12.7,10.5-25.8,15.4-54.4c5.9-34.4,12.3-83.7,15.9-110.3
								c0.6-4.5,0.9-8.8,1.1-12.6c1.3,0.9,2.6,1.7,3.8,2.1c1.4,0.5,2.6,0.7,3.4,0.8c0.4,0,0.8,0,1,0c0.2,0,0.6,0,1,0
								c0.8-0.1,2-0.3,3.4-0.8c1.2-0.5,2.5-1.2,3.8-2.2c0.2,3.6,0.5,7.7,1,12c0,0.2,0.1,0.4,0.1,0.6c3.6,26.6,10,75.9,15.9,110.3
								c4.9,28.6,15.8,41.7,15.4,54.4c0,0,0,0,0,0c-1.1,36.4,5,60.2,5,60.2c0.3,2.2,5.5,22.5,7.2,31.3c6.1,33.4,19.1,94.2,19.1,94.2
								c2.4,13.3,3.9,22.4,3.9,22.4c0,3.3-2.5,12.5-2.5,12.5c-1.7,7.5,1,7.9,1.3,16.6c0.5,15.1,1.4,17.6,1.4,17.6
								c3.2,14.5,39.4,7.8,41.9,7.3c3.1-0.6,9.6-2.5,2.1-8.9c-7.5-6.4-16.6-18.1-15.3-24.5s-2.6-21.1-2.6-21.1c-0.1-1.7-0.7-10.1-1.3-21.9
								h-0.1c-0.1-18.8,1.7-42.3,6.6-95.5c5-53.3-12.4-86.5-14.5-118.2c-2.5-36.8,3.6-40.8,5.7-69.8c6.3-84.4-7.2-115.5-9.3-132
								c-2.2-16.5-2.8-24.2-4.4-44.6c-1.6-20.3-11-39.6-11-39.6c0.6-11-2.6-25.9-3-34.6c-1.2-25.4,9.5-54.7,12.1-63.2
								c1.6-5.2,3.2-10,3.7-14.2l0.2-1.5c0.2,15.2,3,33,4.7,48.4c1.7,15.1,4,25.1,5,33.5c1.6,13.8-0.3,26.9,2.7,55.5
								c0.9,8.6,15.5,50,19.6,66.9c1.9,7.8,2.4,14,3.1,16.6c2.3,8.9-8.4,32.3-6.4,39s1.5,27,1.8,32.6s7,2.8,7,2.8
								c0.3,3.6-9.3,18.1-8.7,21.5c0.6,3.3,4.9,3.9,6.1,0c1.2-3.9,14.8-23.1,16.8-27c2-3.9,1.5-11.4,3.8-17.6
								C358.2,539.6,355.3,498.9,355.3,498.9z"/>
							<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M252.4,34.2
								c-7.5-28.8-35.7-28.4-38.6-28.3c-2.9-0.1-31.1-0.5-38.6,28.3c-2,7.6-2.5,17.2-0.8,29.4c2.1,14.9,3.5,28.2,7.2,37.1
								c11,27,28.1,27.9,32.2,27.9c4.2-0.1,21.2-0.9,32.2-27.9c3.6-8.9,5-22.1,7.2-37.1C254.9,51.4,254.4,41.8,252.4,34.2"/>
							<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M174.7,65.5
								c-5,0.5-18.6,4,4.7,28.3C177.4,86,176.2,76.3,174.7,65.5z"/>
							<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M252.9,65.5
								c5,0.5,18.6,4-4.7,28.3C250.2,86,251.4,76.3,252.9,65.5z"/>
							
								<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="39.6" y1="526.9" x2="31" y2="561.6"/>
							
								<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="49.1" y1="529.2" x2="42.9" y2="550.9"/>
							<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M337.9,568.9
								c0,0,3.3-29.2,2.8-30.4"/>
							<path fill="#DDC7BF" d="M351.6,540.1c0,0-0.2,2.3-0.6,5.7c-0.2,1.7-0.4,3.7-0.7,5.9c-0.1,1.1-0.3,2.2-0.4,3.3
								c-0.2,1.1-0.3,2.3-0.5,3.4c-0.2,1.1-0.4,2.3-0.7,3.4c-0.3,1.2-0.7,2.1-1,3.2c-0.6,2-1.1,4-1.6,5.6c-0.5,1.7-0.8,3.1-1.2,4
								c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1,0.3-0.2,0.4-0.2,0.4c-0.2,0.4-0.8,1.1-1.3,0.9s-0.3,0.6-0.4,0.5
								c-0.2-0.4,0.3-2.1,0.3-2.1l0.1-0.2c0,0,0-0.1,0.1-0.3c0-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.1-0.4,0.2-0.7c0.4-0.9,0.6-2.2,1.2-3.9
								c0.5-1.6,1.1-3.6,1.8-5.6c0.3-1,0.8-2.1,1.1-3.1c0.4-1,0.6-2.1,0.8-3.3c0.3-1.1,0.5-2.2,0.7-3.3c0.2-1.1,0.4-2.2,0.6-3.3
								c0.4-2.1,0.7-4.1,0.9-5.8C351.3,542.4,351.6,540.1,351.6,540.1"/>
							<path fill="#DDC7BF" d="M23.7,509.8c0,0,1.6,0,3.9,0.6c1.1,0.3,2.5,0.5,3.9,0.6c0.7,0.1,1.5,0,2.2,0c0.8-0.1,1.5-0.1,2.3-0.2
								c0.8-0.1,1.5-0.3,2.3-0.5c0.4-0.1,0.7-0.2,1.1-0.3c0.3-0.2,0.7-0.3,1-0.4c0.7-0.3,1.3-0.6,1.9-1c0.3-0.2,0.6-0.3,0.8-0.5
								c0.2-0.2,0.5-0.4,0.7-0.6c0.4-0.4,0.9-0.7,1.2-1c0.3-0.4,0.6-0.7,0.9-0.9c0.5-0.5,0.7-0.8,0.7-0.8s-0.3,0.3-0.7,0.8
								c-0.2,0.3-0.5,0.6-0.8,1c-0.3,0.4-0.7,0.7-1.2,1.1c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.5,0.4-0.8,0.6c-0.6,0.4-1.2,0.8-1.8,1.1
								c-0.3,0.2-0.7,0.3-1,0.5c-0.4,0.2-0.7,0.3-1.1,0.4c-0.7,0.3-1.5,0.5-2.3,0.7c-0.8,0.2-1.6,0.3-2.4,0.4c-0.8,0-1.6,0.1-2.3,0.1
								c-1.5,0-2.9-0.2-4.1-0.3c-2.4-0.4-4-1-4-1l0,0c-0.4-0.2-0.7-0.7-0.5-1.1C22.9,509.5,23.2,509.6,23.7,509.8
								C23.6,509.8,23.5,510,23.7,509.8"/>
							<path fill="#EFD3D3" d="M241.6,125.7V109c-10.7,18.6-24.4,19.3-28,19.3c-3.7,0-17.3-0.7-28-19.3v17.9c0,0,0,5.1-2.8,10.2
								C193.1,139.6,220.6,143.6,241.6,125.7"/>
							<path fill="#EFD3D3" d="M118.4,341.8c2.2-16.2,8-46.7,8.3-64.5c0.2,7.3,0,0,0,0s-6.4,17.9-15.5,65.7
								c-8.5,44.5-30.6,108.5-33.8,120.3c0,0,24.2-53.7,26.7-69.2C108.9,363.7,116.5,356,118.4,341.8"/>
							<path fill="#EFD3D3" d="M306.4,332.7c1.4,9.2,2.8,16.3,3.5,22.5c1.6,13.8-0.3,27,2.7,55.5c0.2,2.1,1.5,7.3,3.4,14
								c-2.6-19.9,4.6-58.4,4.6-58.4C318.6,355.5,306.4,332.7,306.4,332.7"/>
							<path fill="#EFD3D3" d="M130.2,166.6c-3.2,1.6-5.8,2.8-7.5,3.4l37.6,12.1C160.3,182.2,137.9,170.4,130.2,166.6"/>
							<path fill="#EFD3D3" d="M296.8,166.6c3.2,1.6,5.8,2.8,7.5,3.4l-37.6,12.1C266.7,182.2,289.1,170.4,296.8,166.6"/>
							<path fill="#EFD3D3" d="M300.1,252.7c-0.9,1.9-2,4-3.1,6.2c-1.2,2.2-2.5,4.4-4,6.7c-1.5,2.2-3.3,4.5-5.4,6.5
								c-0.8,0.7-1.7,1.5-2.6,2.1c2.9,1,10.7,4.4,13.4,10.5c0,0,0,0,0,0c0.3-1.1,1.2-3.4,1.4-6.6c0.5-7.2,0.6-7.7,1.4-13.9
								c0.6-4.3,2.4-16.8,3-21.5C303.4,245.1,302,248.6,300.1,252.7z"/>
							<path fill="#DDC7BF" d="M305.4,239.3c0,0-0.1,0-0.1,0c0,0-0.6,1.3-1.7,3.5c-0.6,1.1-1.2,2.5-2,4c-0.8,1.5-1.7,3.3-2.7,5.2
								c-1,1.9-2.1,3.9-3.3,6c-1.2,2.1-2.6,4.3-4.1,6.5c-1.5,2.2-3.1,4.4-5,6.3c-1,1-2,1.8-3.2,2.5c-0.3,0.1-0.6,0.3-0.9,0.4l-1,0.4
								l-1,0.3l-1,0.2c-2.7,0.6-5.5,0.8-8.3,0.8c-2.8,0.1-5.5-0.1-8.2-0.3c-5.3-0.4-10.3-1-14.5-1.6c-8.5-1.3-14.1-2.5-14.1-2.5
								c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,5.5,1.4,14,3c4.2,0.8,9.2,1.5,14.5,2c2.7,0.2,5.5,0.4,8.3,0.4c2.8,0,5.7-0.1,8.6-0.7
								l1.1-0.2l1-0.3l1-0.3c0.4-0.1,0.7-0.3,1.1-0.5c0.3-0.2,0.6-0.4,1-0.6c1-0.6,1.8-1.3,2.6-2.1c2.1-2,3.8-4.3,5.4-6.5
								c1.5-2.3,2.9-4.5,4-6.7c1.2-2.2,2.2-4.3,3.1-6.2c1.9-4.2,3.3-7.6,4.2-10c0.8-2.1,1.2-3.3,1.2-3.3
								C305.4,239.4,305.4,239.3,305.4,239.3z"/>
							<path fill="#EFD3D3" d="M134.1,265.6c-1.5-2.3-2.9-4.5-4-6.7c-1.2-2.2-2.2-4.3-3.1-6.2c-1.7-3.7-3-6.9-3.9-9.2
								c-0.7-1.6-1.3-2.8-1.3-2.8s2.5,15.8,3.3,21.1c0.8,6.2,0.9,6.6,1.4,13.9c0.2,3.2,1.1,5.5,1.4,6.6c0,0,0,0,0,0
								c2.6-5.9,9.1-8.9,11.8-9.9c-0.1-0.1-0.2-0.2-0.3-0.3C137.3,270.1,135.6,267.9,134.1,265.6z"/>
							<path fill="#DDC7BF" d="M192.7,271.2c0,0-5.6,1.3-14.1,2.5c-4.2,0.6-9.2,1.3-14.5,1.6c-2.7,0.2-5.4,0.3-8.2,0.3
								c-2.8,0-5.7-0.2-8.3-0.8l-1-0.2l-1-0.4l-1-0.4c-0.2-0.1-0.6-0.3-0.9-0.4c-1.2-0.7-2.2-1.5-3.2-2.5c-1.9-1.9-3.6-4.1-5-6.3
								c-1.5-2.2-2.8-4.4-4.1-6.5c-1.2-2.1-2.3-4.1-3.3-6c-1-1.9-1.9-3.6-2.7-5.2c-0.8-1.6-1.4-2.9-2-4c-1.1-2.3-1.7-3.5-1.7-3.5
								c0,0-0.1-0.1-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0,0.5,1.5,1.5,4.1c0.9,2.3,2.2,5.5,3.9,9.2c0.9,1.9,2,4,3.1,6.2c1.2,2.2,2.5,4.4,4,6.7
								c1.5,2.2,3.3,4.5,5.4,6.5c0.1,0.1,0.2,0.2,0.3,0.3c1,0.9,2,1.7,3.3,2.4c0.4,0.2,0.6,0.3,1.1,0.5l1,0.3l1,0.3l1.1,0.2
								c2.9,0.6,5.8,0.7,8.6,0.7c2.8,0,5.6-0.2,8.3-0.4c5.4-0.5,10.3-1.3,14.5-2c8.5-1.5,14-3,14-3C192.8,271.4,192.8,271.3,192.7,271.2
								C192.8,271.2,192.7,271.2,192.7,271.2z"/>
							<line fill="none" x1="160.3" y1="182.2" x2="122.8" y2="170.1"/>
							<path fill="#DDC7BF" d="M161.5,182.4l-37.8-11.3c-0.4-0.1-0.7-0.6-0.6-1c0.1-0.4,0.6-0.7,1-0.6c0,0,0,0,0,0L161.5,182.4z"/>
							<g>
								<path fill="#DDC7BF" d="M121.8,240.8c0,0,1.5,8.7,2.8,17.4c0.3,2.2,0.7,4.4,1,6.4c0.3,2,0.6,4,0.8,5.6c0.1,0.8,0.2,1.6,0.3,2.2
									c0.1,0.7,0.1,1.3,0.2,1.8c0.1,1,0.1,1.5,0.1,1.5c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5c0,0,0,0,0,0l0-0.1c0,0,0-0.5-0.1-1.5
									c0-0.5,0-1-0.1-1.7c-0.1-0.7-0.1-1.4-0.2-2.2c-0.2-1.6-0.4-3.6-0.6-5.6c-0.3-2.1-0.5-4.2-0.8-6.4
									C123,249.5,121.8,240.8,121.8,240.8z"/>
							</g>
							<line fill="none" x1="304.3" y1="170.1" x2="266.7" y2="182.2"/>
							<path fill="#DDC7BF" d="M303.3,171.3l-37.8,11.3l37.3-12.8c0.4-0.1,0.9,0.1,1.1,0.5C304,170.7,303.7,171.1,303.3,171.3
								C303.3,171.3,303.3,171.3,303.3,171.3"/>
							<g>
								<path fill="#DDC7BF" d="M304.5,242.3c0,0-1,7.9-2,15.8c-0.5,4-0.9,7.9-1.2,10.9c-0.1,1.5-0.2,2.7-0.3,3.6c0,0.9-0.1,1.3-0.1,1.3
									l0,0.1c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5c0,0,0,0,0,0c0,0,0-0.5,0.1-1.4c0.1-0.9,0.2-2.1,0.4-3.6c0.4-3,0.9-6.9,1.5-10.8
									C303.2,250.1,304.5,242.3,304.5,242.3z"/>
							</g>
							<path fill="#DDC7BF" d="M90.3,353.3c-5.9,0.9-12.4-1-16.6-2.6c0,1-0.1,1.7-0.1,1.7c0,0.5-0.4,1.3-1.1,2.6
								C83.2,356.8,90.3,353.3,90.3,353.3"/>
							<path fill="#DDC7BF" d="M314.4,360c2.2,0,5.5,2.9,18.3,2.1c0,0-8.2,4.6-18.3,5L314.4,360L314.4,360z"/>
							<path fill="#DDC7BF" d="M140.8,386c0,0,0.1,0,0.2-0.1c0.2,0,0.6-0.1,1,0c0.3,0.1,0.7,0.2,1.2,0.3c0.4,0.2,0.8,0.5,1.3,0.7
								c0.5,0.2,0.9,0.7,1.4,1.1c0.4,0.4,1,0.8,1.4,1.3c0.8,1,1.8,2,2.6,3.1c0.8,1.1,1.6,2.2,2.3,3.3c0.4,0.5,0.7,1.1,1,1.6
								c0.3,0.5,0.6,1,0.8,1.4c1,1.7,1.7,2.9,1.7,2.9s-0.8-1.1-2-2.8c-0.3-0.4-0.6-0.9-0.9-1.3c-0.3-0.5-0.7-1-1.1-1.5c-0.8-1-1.7-2-2.5-3
								c-0.9-1-1.9-1.9-2.8-2.8c-0.4-0.5-1-0.7-1.4-1.1c-0.5-0.3-0.9-0.7-1.3-0.9c-0.4-0.2-0.8-0.4-1.1-0.5c-0.3-0.1-0.6-0.1-0.9-0.2
								c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2,0.1-0.2,0.1l-0.1,0c-0.4,0.1-0.9-0.1-1-0.5C140.1,386.6,140.3,386.2,140.8,386
								C140.7,386,140.8,386,140.8,386"/>
							<path fill="#DDC7BF" d="M286.6,387.6c0,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.4,0c-0.2,0-0.5,0.1-0.9,0.2c-0.3,0.2-0.7,0.3-1.1,0.5
								c-0.4,0.2-0.8,0.6-1.3,0.9c-0.4,0.4-1,0.6-1.4,1.1c-0.9,0.9-1.9,1.7-2.8,2.8c-0.9,1-1.7,2.1-2.5,3c-0.4,0.5-0.7,1-1.1,1.5
								c-0.3,0.5-0.6,0.9-0.9,1.3c-1.2,1.7-2,2.8-2,2.8s0.7-1.2,1.7-2.9c0.3-0.4,0.5-0.9,0.8-1.4c0.3-0.5,0.6-1,1-1.6
								c0.7-1,1.5-2.2,2.3-3.3c0.8-1.1,1.8-2.1,2.6-3.1c0.4-0.5,1-0.9,1.4-1.3c0.5-0.4,0.9-0.8,1.4-1.1c0.5-0.3,0.9-0.5,1.3-0.7
								c0.5-0.1,0.9-0.2,1.2-0.3c0.4,0,0.8,0,1,0c0.2,0,0.2,0.1,0.2,0.1c0.4,0.1,0.7,0.5,0.6,1c-0.1,0.4-0.5,0.7-1,0.6
								C286.8,387.6,286.7,387.6,286.6,387.6L286.6,387.6z"/>
							<path fill="#4E5663" d="M256.5,29.5c-2-8.3-13-29.3-42.7-29.5c-29.7,0.2-40.7,21.2-42.7,29.5c-1.7,7-2.7,17.7-1.7,23.8
								c2.8,17.8,5.5,26.4,5.5,26.4l2.6,0.7c0,0-3-18.2-3.1-23.7c-0.9-41.9,28.4-29.5,39.5-28.1c11.1-1.4,40.4-13.7,39.5,28.1
								c-0.1,5.5-3.1,23.7-3.1,23.7l2.6-0.7c0,0,2.7-8.6,5.5-26.4C259.2,47.2,258.2,36.5,256.5,29.5z"/>
							<path fill="#EFD3D3" d="M222.5,529.9l-0.4-6.2c0,0,5.1-3.4,10.1-11.7c0,0-8.4,31.8-5.1,54.6L222.5,529.9z"/>
							<path fill="#EFD3D3" d="M205.2,529.9l0.4-6.2c0,0-7.9-6-10.1-11.6c0,0,8.4,31.7,5.1,54.5L205.2,529.9z"/>
							<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M355.3,498.9
								c0,0-1.2-8.4-1.8-21.1c0.6,0-1.5-0.7,0.1-51.3c1.7-50.6-2.2-64.8-2.5-67.6c-1.7-14.6-0.7-3.8,0.7-27.7c2.2-35.8-2.6-56.7-2.9-64.9
								s-1.7-8.5,0.6-27.5c2.3-19-6-49.2-21.4-61.3c-10.9-8.5-23.6-7.2-23.6-7.2c-8.2-3.2-39-19.9-49.3-23.8c0,0-0.2-0.1-0.2-0.1
								c-13.2-5-13.2-19.2-13.2-19.2v-17.9c-10.7,18.6-24.4,19.3-28,19.3c-3.7,0-17.3-0.7-28-19.3v17.9c0,0,0,14.3-13.2,19.2
								c0,0-0.2,0.1-0.2,0.1c-10.2,3.9-41.1,20.6-49.3,23.8c0,0-12.8-1.4-23.7,7.2c-15.4,12.1-23.2,42.3-21,61.3c2.3,19,0.9,19.2,0.6,27.5
								c-0.3,8.3-4.5,22.5-4.6,58.3c0,3.8-1.3,28.1-1.3,28.1c-0.3,2.9-14,23.5-18.6,55.7c-7.1,50.1-7.6,53.7-7.6,55.1
								c-2.8,8.4-5.4,14.7-7.9,17c-7.1,6.5-18,15.6-33.9,28c-1.2,1-2.9,1.4-3.7,1.8c-2.5,1.3,0.5,7.1,5.9,5.4c5.4-1.7,10.1-7,13.5-6.3
								c1.1,0.3,2.2,0.6,3.2,0.8c-0.7,1.4-2.1,7.3-3.4,15.9c-1.4,8.9-2.8,17-4.6,27.6c-1.4,8.5,4.9,8.5,6.3,6.5c0.4-0.6,8.1-33.7,8.9-36.4
								c-0.7,2.7-8.2,36.2-8.4,37.6c-1.4,8.3,5.9,7.2,7.4,3c0.2-0.7,0.5-1.6,0.9-2.7c-1.6,6.7,5.1,8.2,7.5,2.5c0.9-2.2,2.8-7.6,4.4-13.4
								c-0.6,2.4-1.2,4.5-1.5,6.3c-0.6,2.8,5.5,4.6,7.1,0.8c0.8-1.9,9.6-24,12.1-30.3c3-7.6,3.9-9.3,6.5-16.9c3.3-9.3,3.9-18.1,5-23.8
								c0.8-4.3,3-16.9,5.6-23.5c0,0,24.2-53.7,26.7-69.2c4.9-30.5,12.4-38.1,14.4-52.3c2.2-16.2,10.1-48.7,7.7-66.3
								c1,7.3,3.4,9.4,4.6,13.4c2.4,8.5,13.3,37.8,12.1,63.2c-0.4,8.7-3.6,23.6-3,34.6c0,0-9.4,19.2-11,39.6c-1.6,20.3-2.2,28-4.4,44.6
								c-2.2,16.7-15.7,47.5-9.4,132c2.2,29.1,8.2,33,5.7,69.8c-2.1,31.7-19.5,64.9-14.5,118.2c4.9,53.2,6.7,76.7,6.6,95.5h-0.1
								c-0.6,11.9-1.2,20.2-1.3,21.9c0,0-3.9,14.6-2.6,21.1c1.3,6.4-7.7,18.1-15.3,24.5s-1,8.2,2.1,8.9c2.5,0.5,38.7,7.2,41.9-7.3
								c0,0,0.9-2.5,1.4-17.6c0.3-8.7,2.9-9,1.3-16.6c0,0-2.5-9.2-2.5-12.5c0,0,1.5-9,3.9-22.4c0,0,13-60.8,19.1-94.2
								c1.6-8.9,6.9-29.2,7.1-31.3c0,0,6.1-22.1,5-60.2c-0.4-12.7,10.5-25.8,15.4-54.4c5.9-34.4,12.3-83.7,15.9-110.3
								c0.6-4.5,0.9-8.8,1.1-12.6c1.3,0.9,2.6,1.7,3.8,2.1c1.4,0.5,2.6,0.7,3.4,0.8c0.4,0,0.8,0,1,0c0.2,0,0.6,0,1,0
								c0.8-0.1,2-0.3,3.4-0.8c1.2-0.5,2.5-1.2,3.8-2.2c0.2,3.6,0.5,7.7,1,12c0,0.2,0.1,0.4,0.1,0.6c3.6,26.6,10,75.9,15.9,110.3
								c4.9,28.6,15.8,41.7,15.4,54.4c0,0,0,0,0,0c-1.1,36.4,5,60.2,5,60.2c0.3,2.2,5.5,22.5,7.2,31.3c6.1,33.4,19.1,94.2,19.1,94.2
								c2.4,13.3,3.9,22.4,3.9,22.4c0,3.3-2.5,12.5-2.5,12.5c-1.7,7.5,1,7.9,1.3,16.6c0.5,15.1,1.4,17.6,1.4,17.6
								c3.2,14.5,39.4,7.8,41.9,7.3c3.1-0.6,9.6-2.5,2.1-8.9c-7.5-6.4-16.6-18.1-15.3-24.5s-2.6-21.1-2.6-21.1c-0.1-1.7-0.7-10.1-1.3-21.9
								h-0.1c-0.1-18.8,1.7-42.3,6.6-95.5c5-53.3-12.4-86.5-14.5-118.2c-2.5-36.8,3.6-40.8,5.7-69.8c6.3-84.4-7.2-115.5-9.3-132
								c-2.2-16.5-2.8-24.2-4.4-44.6c-1.6-20.3-11-39.6-11-39.6c0.6-11-2.6-25.9-3-34.6c-1.2-25.4,9.5-54.7,12.1-63.2
								c1.6-5.2,3.2-10,3.7-14.2l0.2-1.5c0.2,15.2,3,33,4.7,48.4c1.7,15.1,4,25.1,5,33.5c1.6,13.8-0.3,26.9,2.7,55.5
								c0.9,8.6,15.5,50,19.6,66.9c1.9,7.8,2.4,14,3.1,16.6c2.3,8.9-8.4,32.3-6.4,39s1.5,27,1.8,32.6s7,2.8,7,2.8
								c0.3,3.6-9.3,18.1-8.7,21.5c0.6,3.3,4.9,3.9,6.1,0c1.2-3.9,14.8-23.1,16.8-27c2-3.9,1.5-11.4,3.8-17.6
								C358.2,539.6,355.3,498.9,355.3,498.9z"/>
							<path fill="#DDC7BF" d="M233.3,509.2c-1.5,2.7-3.7,6.3-6.3,9.4c-1.3,1.6-2.8,3-4.3,4.2c-1.5,1.2-3,2-4.4,2.6s-2.6,0.7-3.4,0.8
								c-0.4,0-0.8,0-1,0c-0.2,0-0.6,0-1,0c-0.8-0.1-2-0.3-3.4-0.8c-1.4-0.5-2.9-1.4-4.4-2.6c-1.5-1.2-3-2.6-4.3-4.2
								c-2.7-3.1-4.9-6.7-6.3-9.4c-1.5-2.7-2.3-4.6-2.3-4.6s0.7,2,2.1,4.8c1.3,2.8,3.4,6.5,5.9,9.8c1.3,1.7,2.7,3.2,4.2,4.5
								c1.5,1.3,3.1,2.3,4.6,3c1.5,0.7,2.8,1,3.8,1.1c0.3,0,0.6,0.1,0.8,0.1v0c0,0,0.1,0,0.3,0c0.2,0,0.3,0,0.3,0v0c0.2,0,0.5,0,0.8-0.1
								c1-0.1,2.3-0.4,3.8-1.1c1.5-0.7,3.1-1.7,4.6-3c1.5-1.3,2.9-2.8,4.2-4.5c2.6-3.3,4.6-7,5.9-9.8c1.4-2.8,2.1-4.8,2.1-4.8
								S234.8,506.5,233.3,509.2z"/>
						</g>
						<g id="front_layer13">
							<path fill="#FFFFFF" d="M313.4,896.2c-4.4-8.6-12-34.4-6.3-106.3c2.4-30.9-2.3-54.8-7.9-78.4c-3-12.9-5.9-25-6.7-37.5
								c-1.7-25.1,0.4-36.5,2.6-48.6c1.2-6.5,2.4-13.3,3.2-23.3c1.6-21.3,2-41.1,1.3-58.9l0-1.1l-0.1,0l-0.2-4.1
								c-0.5-8.9-1.3-17.1-2.5-25.1c1.6,0.1,3.1,0.2,4.6,0.4c0.8,0.1,1.7,0.2,2.5,0.3c1,0.1,2,0.3,3,0.4c0.8,0.1,1.7,0.3,2.7,0.5
								c3.3,25.2,3.7,54.2,1.1,88.5c-0.8,10.6-2.1,17.7-3.3,24.6c-2.1,11.8-4,22-2.4,45.5c0.8,11.4,3.5,23.1,6.4,35.4
								c5.3,22.7,11.3,48.4,8.2,82.6c-4.8,51.5-6.8,76.2-6.6,95.7c0,0.2,0,0.3,0.1,0.5C313.1,890.2,313.2,893.2,313.4,896.2z"/>
						</g>
						<g id="front_layer21">
							<path fill="#FFFFFF" d="M292,907.3c2-7.7,4.5-36.5-13.3-140.2c-0.1-0.4-7.8-33-6.8-65.8c0.3-9.9-3.4-17.8-7.3-26.3
								c-4-8.7-6.7-18-8.9-30.2c-2.7-15.7-7.7-46.3-11-75c-0.8-6.9-2.5-20.3-4-32.1c-1.3-9.9-2.4-18.4-2.6-20.6c9.7-1.7,27.9-4.4,46.9-4.4
								c3.2,0,6.4,0.1,9.4,0.2l0.1,0.4c1.1,8,1.9,16.2,2.4,25l0.4,6.2h0c0.7,17.4,0.2,36.7-1.3,57.6c-0.7,9.9-2,16.5-3.1,23
								c-2.2,12.3-4.3,23.9-2.6,49.2c0.9,12.6,3.7,24.9,6.8,37.9c5.5,23.5,10.2,47.1,7.8,77.7c-2.9,37.3-2.7,66.7,0.8,87.4
								c2.3,13.6,5.4,20,7.3,22.8L292,907.3z"/>
						</g>
						<g id="front_layer14">
							<g>
								<path fill="#FFFFFF" d="M290.4,904c-0.7-4.2-1.9-10.7-3.1-17.7c-0.5-2.6-13.2-61.9-19.1-94.2c-1-5.2-3.1-14.3-4.9-21.5l0-0.2
									c-1.1-4.5-2.1-8.8-2.2-9.5l0-0.1l0-0.1c-0.3-1-6-23.3-4.9-59.8c0.2-6.5-2.3-12.9-5.5-21c-3.3-8.5-7.4-19.1-10-33.8
									c-4.9-28.4-10.1-66.7-13.8-94.7c-0.8-5.7-1.5-11-2.1-15.6c-0.5-3.9-0.8-7.6-1-10.8c0.1-0.1,0.2-0.2,0.3-0.3c1.5-1.3,3-2.9,4.4-4.7
									c0.4-0.5,0.8-1,1.2-1.6l0,0c0.5-0.1,1.1-0.2,1.7-0.3c1.4-0.3,2.9-0.6,4.5-0.8c0.3,2.7,1.4,10.9,2.6,20.3c1.5,11.8,3.2,25.2,4,32.1
									c3.3,28.7,8.3,59.4,11,75.1c2.1,12.4,4.9,21.9,9,30.8c3.8,8.2,7.4,16,7.1,25.3c-1,33.1,6.8,66,6.9,66.4
									C292.9,862.9,292,894.2,290.4,904z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M230.9,517.2c-0.6,0.1-1.2,0.3-1.8,0.4
									c0-0.1-0.1,0-0.1,0c-0.5,0.7-0.9,1.4-1.4,2c-1.3,1.7-2.7,3.2-4.2,4.5c-0.3,0.2-0.5,0.4-0.8,0.6c0.2,3.5,0.5,7.4,1,11.4
									c0.6,4.6,1.3,9.8,2.1,15.6c0.2,1.4,0.4,2.9,0.6,4.3C226.2,541.3,229.2,525.3,230.9,517.2z"/>
							</g>
						</g>
						<g id="front_layer18">
							<g>
								<path fill="#FFFFFF" d="M137.2,904.2c-1.6-9.8-2.4-41.1,13.9-136.8c0.1-0.3,7.8-33.2,6.8-66.3c-0.3-9.3,3.3-17.1,7.1-25.3
									c4.1-8.9,6.9-18.4,9-30.8c2.7-15.7,7.8-46.4,11-75.1c0.8-6.9,2.5-20.2,4-32c1.2-9.4,2.3-17.7,2.6-20.4c1.6,0.3,3.1,0.6,4.5,0.9
									c0.6,0.1,1.2,0.2,1.7,0.4c0.4,0.6,0.8,1.1,1.2,1.6c1.4,1.8,2.9,3.4,4.4,4.7c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,3.2-0.5,6.9-1,10.8
									c-0.6,4.6-1.3,9.8-2.1,15.6c-3.8,28-8.9,66.3-13.8,94.7c-2.5,14.7-6.7,25.3-10,33.8c-3.2,8.1-5.6,14.5-5.5,21
									c1.1,36.5-4.7,58.9-4.9,59.8l0,0.1l0,0.1c-0.1,0.8-1.1,5.1-2.2,9.6c-1.8,7.4-4,16.4-4.9,21.6c-5.9,32.3-18.5,91.6-19.1,94.1
									C139.1,893.4,138,899.9,137.2,904.2z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M200,519.7c-0.5-0.6-1-1.3-1.4-2l0,0
									c-0.6-0.1-1.2-0.3-1.9-0.4c1.8,8,4.8,23.9,4.6,38.7c0.2-1.4,0.4-2.9,0.6-4.2c0.8-5.7,1.5-11,2.1-15.6c0.6-4.1,0.8-7.9,1-11.4
									c-0.3-0.2-0.5-0.4-0.8-0.6C202.7,522.9,201.3,521.3,200,519.7z"/>
							</g>
						</g>
						<g id="front_layer22">
							<path fill="#FFFFFF" d="M114.7,899.7c1.9-2.8,5.1-9.2,7.3-22.8c3.4-20.6,3.7-50,0.8-87.4c-2.4-30.6,2.3-54.2,7.8-77.7
								c3-13,5.9-25.2,6.8-37.9c1.7-25.4-0.4-36.9-2.6-49.2c-1.2-6.5-2.4-13.2-3.1-23c-1.6-20.9-2-40.4-1.3-57.8l0.1-0.7l0.3-5.2
								c0.5-8.8,1.3-17,2.4-25l0.1-0.4c2.9-0.2,5.9-0.2,8.9-0.2c18.6,0,37.3,2.8,47.4,4.6c-0.3,2.5-1.3,10.9-2.6,20.5
								c-1.5,11.8-3.2,25.2-4,32c-3.3,28.7-8.3,59.3-11,75c-2.1,12.2-4.8,21.6-8.9,30.2c-3.9,8.5-7.6,16.4-7.3,26.3
								c1,32.8-6.7,65.5-6.8,65.8c-11.6,67.9-14.4,102.9-14.7,120.4c-0.2,11.3,0.6,17,1.3,19.9L114.7,899.7z"/>
						</g>
						<g id="front_layer12">
							<path fill="#FFFFFF" d="M114.2,896.2c0.2-2.9,0.3-5.9,0.5-8.9c0-0.2,0.1-0.3,0.1-0.5c0.1-19.5-1.8-44.2-6.6-95.7
								c-3.2-34.1,2.8-59.9,8.2-82.6c2.9-12.4,5.6-24,6.4-35.4c1.6-23.5-0.3-33.7-2.4-45.5c-1.2-6.9-2.5-14-3.3-24.6
								c-2.6-34.4-2.2-63.3,1.1-88.5c1-0.2,1.9-0.3,2.7-0.5c1-0.2,2-0.3,3-0.4c0.8-0.1,1.7-0.2,2.5-0.3c1.4-0.1,2.9-0.3,4.6-0.4
								c-1.1,8-1.9,16.2-2.5,25.1l-0.2,4.1l0,0l-0.1,1.1c-0.7,17.7-0.3,37.5,1.3,58.9c0.7,10,2,16.7,3.2,23.3c2.2,12.1,4.3,23.6,2.6,48.6
								c-0.8,12.4-3.7,24.6-6.7,37.5c-5.5,23.7-10.3,47.5-7.9,78.4C126.2,861.8,118.6,887.6,114.2,896.2z"/>
						</g>
						<g id="front_layer16">
							<path fill="#FFFFFF" d="M213.8,525.6c-0.2,0-0.5,0-0.9,0c-0.7-0.1-1.8-0.2-3.1-0.7c-1.1-0.4-2.3-1.1-3.5-2c-0.1,0-1.7-1.1-3.8-3.4
								c-0.3-0.3-0.6-0.7-0.9-1.1c-2.7-3.2-4.9-6.8-6.2-9.2c-1.4-2.6-2.2-4.5-2.2-4.5l-2.1,0.8c0,0.1,0.7,2,2.1,4.9c0.5,1,1,2,1.6,3.1
								c0.1,0.6,0.3,1.4,0.6,2.4c-9.6-1.9-31.4-5.6-53.1-5.6c-3.3,0-6.5,0.1-9.5,0.3c-2.3,0.1-4.5,0.3-6.5,0.5c-0.9,0.1-1.7,0.2-2.6,0.3
								c-1,0.1-2.1,0.3-3.1,0.4c-0.6,0.1-1.3,0.2-2,0.3c1.7-12.2,3.8-21.3,5.5-28.6c1.1-4.9,2-8.7,2.4-12.1c2.1-15.4,2.7-23.3,4.1-40.6
								l0.3-4.1c0.2-2.7,0.6-5.5,1.1-8.5c2.4,0.8,6.3,2,11.7,3.2c8.5,1.8,17.8,3,27.4,3.4c3.1,0.1,6.7,0.3,10.4,0.5l0.8,0
								c8.3,0.5,19.7,1.1,30.8,1.1h1.1c11.1,0,22.5-0.6,30.8-1.1l0.6,0c3.8-0.2,7.5-0.4,10.6-0.5c15.4-0.6,27-3.1,34-5
								c1.3-0.3,2.5-0.7,3.7-1.1l0.5-0.1c0.5-0.2,0.9-0.3,1.3-0.4c0.5,2.9,0.9,5.6,1.1,8.3l0.3,4.1c1.4,17.3,2,25.2,4.1,40.5
								c0.4,3.4,1.3,7.2,2.4,12c1.7,7.5,3.8,16.7,5.6,29.1l-0.8-0.1c-6.8-1.1-14.6-1.7-23.3-1.7c-22.5,0-44.4,3.8-53.7,5.6
								c1.3-2.1,2.3-4.1,3-5.6c1.4-2.8,2.1-4.8,2.1-4.9l-2.1-0.8c0,0-0.8,1.9-2.2,4.5c-1.3,2.4-3.5,6-6.2,9.2c-1.3,1.5-2.7,2.9-4.1,4
								c-1.4,1.1-2.8,1.9-4.1,2.4c-1.3,0.5-2.4,0.7-3.1,0.7C214.3,525.6,214,525.6,213.8,525.6z"/>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M195.7,513.1c0.2,0.9,0.6,2.3,1,4.2
								c0.6,0.1,1.3,0.3,1.9,0.4C197.5,516.1,196.6,514.6,195.7,513.1z"/>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M231.9,513.1c-0.2,0.9-0.5,2.2-1,4.1
								c-0.6,0.1-1.3,0.3-1.9,0.4C230.1,516,231.1,514.6,231.9,513.1z"/>
						</g>
						<g id="front_layer7">
							<path fill="#FFFFFF" d="M352.2,477.3c-2.8-17-3.2-27.4-3.8-43.2c-0.1-2.7-0.2-5.5-0.3-8.6c-1.2-29.5-2.9-50.4-5.3-64
								c-0.2-0.9-0.3-1.5-0.3-2l-0.3-2.6c-0.7-6.3-0.8-7.2-0.3-11.3c0.3-2.3,0.7-6.1,1.3-15.3c1.6-26-0.7-43.9-2-54.7
								c-0.5-4-0.9-7-0.9-9.5c-0.1-1.8-0.2-3.2-0.3-4.7c-0.5-5.7-0.7-10.1,1-24.1c1.2-0.5,2.3-1.1,3.3-1.8c0.2-0.2,0.5-0.3,0.7-0.5
								c0.2-0.1,0.4-0.3,0.5-0.4c1.4-1.2,2.5-2.4,3.3-3.5c0,2.5-0.1,4.8-0.4,7c-1.7,14.3-1.4,18.1-1,22.5c0.1,1.5,0.3,3.1,0.3,5.1
								c0.1,2.1,0.4,5.1,0.9,8.8c1.4,11,3.6,29.4,2,56.1c-0.6,9.4-1,13.4-1.3,15.7c-0.4,3.3-0.4,3.4,0.3,9.6l0.3,2.6
								c0,0.3,0.1,0.8,0.2,1.5c0.9,5.3,3.7,21.2,2.2,66c-0.6,17.5-1,40.2-0.1,50.2h-0.1c0,0,0.1,1.5,0.1,1.7
								C352.3,477.8,352.2,477.5,352.2,477.3z"/>
						</g>
						<g id="front_layer23">
							<path fill="#FFFFFF" d="M337.2,492.7c0.2-7,0.1-17.9-1.9-31.8c-2.4-17.1-7.2-35.6-9.8-45.6c-0.9-3.4-1.6-6-1.6-6.7
								c-1.7-16-1.8-27.1-1.9-36.1c-0.1-7.1-0.1-13.2-0.9-19.6c-0.4-3.8-1.1-7.7-1.8-12.2c-1-6.1-2.1-12.9-3.1-21.3
								c-0.6-5.6-1.5-11.5-2.4-17.2c-1.4-9.1-6-46-3.5-62.6l6.6-0.1c4.3-0.1,7.7-0.1,10-0.2c1,0,1.8-0.1,2.4-0.1c0.7,0,1.3-0.1,1.9-0.1
								l0.5,0c0.5-0.1,1-0.1,1.5-0.2l0.3,0c1.1-0.2,2.3-0.4,3.3-0.7l0.1,0c0.5-0.1,1-0.3,1.5-0.4l0.2,0c-1.5,13.2-1.3,17.7-0.8,23.3
								c0.1,1.4,0.2,2.8,0.3,4.6c0.1,2.6,0.5,5.7,1,9.6c1.3,10.7,3.5,28.5,2,54.3c-0.6,9.2-1,12.9-1.3,15.1c-0.5,4.5-0.4,5.6,0.3,11.9
								l0.3,2.6c0.1,0.5,0.2,1.2,0.3,2.1c2.3,13.4,4,34.2,5.2,63.7c0.1,3.1,0.2,5.9,0.3,8.6c0.6,15.8,1,26.3,3.8,43.4
								c1.1,6.8,2.4,13.8,3.4,19l0.1,0.6L337.2,492.7z"/>
						</g>
						<g id="front_layer9">
							<g>
								<path fill="#FFFFFF" d="M334.9,481.1c-0.2-1.2-0.5-2.4-0.8-3.7c-1.9-7.7-5.9-20.5-9.8-32.9c-4.8-15.1-9.3-29.3-9.7-33.9
									c-1.7-16.4-1.8-27.8-1.9-37c-0.1-6.8-0.1-12.6-0.8-18.6c-0.4-3.6-1.1-7.4-1.8-11.8c-1-6.1-2.2-13-3.2-21.8
									c-0.9-8.4-1.9-14.7-2.8-20.4c-1.6-10.6-2.7-17.5-1.9-27.7c0-0.4-0.1-0.8-0.3-1.1l5.3-32l0.9,0c-2.4,17,2.2,53.7,3.5,62.8
									c0.9,5.8,1.8,11.6,2.4,17.2c1,8.5,2.1,15.3,3.1,21.3l0,0.1c0.8,4.5,1.4,8.4,1.8,12.1c0.7,6.3,0.8,12.3,0.9,19.2
									c0.1,9.1,0.2,20.3,1.9,36.4c0.1,0.8,0.6,2.8,1.7,7c2.6,9.9,7.4,28.4,9.8,45.4C334.2,469.3,334.7,475.7,334.9,481.1z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M320.7,366.2c-1.7-8.7-10.1-25.5-13.2-31.4
									c0.5,3.1,1,6,1.4,8.8c0.7,4.4,1.4,8.2,1.8,11.7c0.7,5.9,0.7,11.7,0.8,18.4c0.1,9.2,0.2,20.6,1.9,37.1c0.2,1.8,1,5.1,2.2,9.3
									C314.4,399.5,320.7,366.2,320.7,366.2z"/>
							</g>
						</g>
						<g id="front_layer11">
							<g>
								<path fill="#FFFFFF" d="M246.3,423c12.2-38.8,19.1-108.1,22.1-146.3l0.1-1.1c1.4-18.1,2.1-31.3,2.3-35l8.9,0l15.7-0.1
									c3.4,0,6.5,0,9.5-0.1l-5.2,31.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.4,0.5-0.4,0.8l-0.2,1.3c-0.2,0.6-0.4,1.2-0.6,1.8l-0.2,0.5
									c-0.1,0.5-0.3,1-0.4,1.5l-0.1,0.5c-0.1,0.5-0.3,1-0.4,1.6l-0.1,0.4c-0.2,0.7-0.4,1.3-0.5,2c-0.5,1.9-0.9,3.9-1.4,6.1
									c-0.3,1.5-0.9,3.6-1.5,5.7c-1.3,4.1-2.9,9-4.4,14.6c-2.8,10-4.6,18.9-5.5,27.2c-0.7,5.8-0.9,11.3-0.7,16.2
									c0.2,3.4,0.7,7.6,1.3,12.2c1,7.3,2.1,15.6,1.7,22.3c0,0.3,0,0.6,0.2,0.8c0.1,0.1,6.1,12.7,9.3,28.1c-1.4,0.5-3.3,1.1-5.9,1.8
									c-3.1,0.8-6.4,1.6-9.9,2.2c-8,1.5-15.5,2.4-23.2,2.7C253.3,422.6,249.9,422.8,246.3,423z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M304.3,242.7c-0.9,2.4-2.3,5.8-4.2,10
									c-0.9,1.9-2,4-3.1,6.2c-1.2,2.2-2.5,4.4-4,6.7c-1.5,2.2-3.3,4.5-5.4,6.5c-0.8,0.7-1.7,1.5-2.6,2.1c2.6,0.9,9.3,3.8,12.5,8.9
									c0,0,0,0,0,0c0.2-0.7,0.3-1.3,0.5-2c0-0.1,0.1-0.3,0.1-0.4c0.1-0.5,0.3-1,0.4-1.5c0-0.2,0.1-0.3,0.1-0.5c0.1-0.5,0.3-1,0.4-1.5
									c0.1-0.2,0.1-0.3,0.2-0.5c0.2-0.6,0.4-1.3,0.6-1.9l0.2-1.4c0-0.1,0.1-0.2,0.1-0.3c0.2-3,0.5-4.5,1-8.8
									C301.8,260,303.6,247.4,304.3,242.7z"/>
								<path opacity="0.1" fill="#020202" enableBackground="new    " d="M279.8,276.1l1.1-0.2l1-0.3l1-0.3c0.4-0.1,0.7-0.3,1.1-0.5
									c0.3-0.2,0.6-0.4,1-0.6c1-0.6,1.8-1.3,2.6-2.1c2.1-2,3.8-4.3,5.4-6.5c1.5-2.3,2.9-4.5,4-6.7c1.2-2.2,2.2-4.3,3.1-6.2
									c1.9-4.2,3.3-7.6,4.2-10c0.8-2.1,1.2-3.3,1.2-3.3c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.6,1.3-1.7,3.5
									c-0.6,1.1-1.2,2.5-2,4c-0.8,1.5-1.7,3.3-2.7,5.2c-1,1.9-2.1,3.9-3.3,6c-1.2,2.1-2.6,4.3-4.1,6.5c-1.5,2.2-3.1,4.4-5,6.3
									c-1,1-2,1.8-3.2,2.5c-0.3,0.1-0.6,0.3-0.9,0.4l-1,0.4l-1,0.3l-1,0.2c-2.7,0.6-5.5,0.8-8.3,0.8c-1.3,0-2.5,0-3.8,0
									c0,0.3-0.1,0.7-0.1,1c1.3,0.1,2.6,0.1,3.9,0.1C274,276.8,276.8,276.7,279.8,276.1z"/>
							</g>
						</g>
						<g id="front_layer20">
							<path fill="#FFFFFF" d="M212.9,424.4c-11,0-22.4-0.6-30.7-1.1C170,385,163.1,315.2,160.1,276.9l-0.1-1.1c-1.4-17.8-2.1-31-2.3-34.9
								l0.6,0v0l15.9,0.1c4.8,0,19.2,0,19.2,0l21.6,0l20.3,0l15.3,0l18-0.1c-0.2,4-0.9,17-2.3,34.8l-0.1,1c-3,38.3-9.9,108.2-22.2,146.6
								c-4.1,0.2-20.4,1-29.8,1H212.9z"/>
						</g>
						<g id="front_layer10">
							<g>
								<path fill="#FFFFFF" d="M180,423c-3.4-0.2-6.7-0.4-9.6-0.5c-19.4-0.8-32.6-4.5-38.1-6.3c3.1-15.5,9.3-28.4,9.4-28.5
									c0.1-0.2,0.2-0.5,0.2-0.8c-0.3-6.7,0.8-15,1.7-22.3c0.6-4.5,1.2-8.8,1.3-12.2c1-21.8-6.8-46.6-10.6-58.6c-0.7-2.2-1.2-3.9-1.6-5.1
									c-0.4-1.6-1.1-2.9-1.7-4.2c-0.5-1.1-1-2.1-1.5-3.4l-0.2-0.5c-0.1-0.2-0.3-0.8-0.3-0.8c-0.1-0.5-0.3-0.9-0.4-1.4
									c-0.2-0.9-0.4-1.8-0.5-2.8c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.2-0.2-0.2-0.3l0,0l-0.1-0.1c0,0-0.1-0.1-0.1-0.1l-5.2-33.9
									c2.1,0,4.3,0,6.6,0.1c3.6,0,7.5,0.1,11.5,0.1c3.9,0,15.1,0.1,15.1,0.1c0.2,3.9,0.9,17,2.4,35l0.1,1.1c3,38.1,9.8,107.3,22,146.1h0
									L180,423z"/>
								<g opacity="0.1">
									<path fill="#020202" d="M155.9,275.6c-2.8,0-5.7-0.2-8.3-0.8l-1-0.2l-1-0.3l-1-0.4c-0.2-0.1-0.6-0.3-0.9-0.4
										c-1.2-0.7-2.2-1.5-3.2-2.5c-1.9-1.9-3.6-4.1-5-6.3c-1.5-2.2-2.8-4.4-4.1-6.5c-1.2-2.1-2.3-4.1-3.3-6c-1-1.9-1.9-3.6-2.7-5.2
										c-0.8-1.6-1.4-2.9-2-4c-1.1-2.3-1.7-3.5-1.7-3.5c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0.5,1.5,1.5,4.1
										c0.9,2.3,2.2,5.5,3.9,9.2c0.9,1.9,2,4,3.1,6.2c1.2,2.2,2.5,4.4,4,6.7c1.5,2.2,3.3,4.5,5.4,6.5c0.1,0.1,0.2,0.2,0.3,0.3
										c1,0.9,2,1.7,3.3,2.4c0.4,0.2,0.6,0.3,1.1,0.5l1,0.3l1,0.3l1.1,0.2c2.9,0.6,5.8,0.7,8.6,0.7c1.1,0,2.1,0,3.2-0.1
										c0-0.4-0.1-0.7-0.1-1.1C157.9,275.6,156.9,275.7,155.9,275.6z"/>
									<g>
										<path opacity="0.1" fill="#020202" enableBackground="new    " d="M155.9,275.6c-2.8,0-5.7-0.2-8.3-0.8l-1-0.2l-1-0.3l-1-0.4
											c-0.2-0.1-0.6-0.3-0.9-0.4c-1.2-0.7-2.2-1.5-3.2-2.5c-1.9-1.9-3.6-4.1-5-6.3c-1.5-2.2-2.8-4.4-4.1-6.5c-1.2-2.1-2.3-4.1-3.3-6
											c-1-1.9-1.9-3.6-2.7-5.2c-0.8-1.6-1.4-2.9-2-4c-1.1-2.3-1.7-3.5-1.7-3.5c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1
											c0,0,0.5,1.5,1.5,4.1c0.9,2.3,2.2,5.5,3.9,9.2c0.9,1.9,2,4,3.1,6.2c1.2,2.2,2.5,4.4,4,6.7c1.5,2.2,3.3,4.5,5.4,6.5
											c0.1,0.1,0.2,0.2,0.3,0.3c1,0.9,2,1.7,3.3,2.4c0.4,0.2,0.6,0.3,1.1,0.5l1,0.3l1,0.3l1.1,0.2c2.9,0.6,5.8,0.7,8.6,0.7
											c1.1,0,2.1,0,3.2-0.1c0-0.4-0.1-0.7-0.1-1.1C157.9,275.6,156.9,275.7,155.9,275.6z"/>
									</g>
								</g>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M139.7,272.4c-0.1-0.1-0.2-0.2-0.3-0.3
									c-2.1-2-3.8-4.3-5.4-6.5c-1.5-2.3-2.9-4.5-4-6.7c-1.2-2.2-2.2-4.3-3.1-6.2c-1.7-3.7-3-6.9-3.9-9.2c-0.7-1.6-1.3-2.8-1.3-2.8
									s2.5,15.8,3.3,21.1c0.8,6.1,0.9,6.6,1.4,13.4c0.1,0,0.3,0,0.3,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2
									c0.1,1.1,0.3,2,0.5,2.9c0.1,0.5,0.2,1,0.4,1.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.4,0.3,0.8,0.4,1.2
									C131.2,276.1,137.2,273.3,139.7,272.4z"/>
							</g>
						</g>
						<g id="front_layer8">
							<g>
								<path fill="#FFFFFF" d="M64.5,476.7c1.6-7.6,3-13.2,4.4-16.7l0.2-0.4c9.2-20.3,24.3-56.5,26-67c3-18.6,6.9-29.1,10-37.5
									c2.1-5.6,3.6-9.7,4.3-14.6c0.7-5.1,1.9-11.8,3.1-18.9l0-0.2c2.7-15.7,9.6-63.3,6.6-80.8l0.8,0l5.2,34.3c-0.2,0.3-0.4,0.8-0.3,1.2
									c0.8,5.8,0.5,13.9-0.9,24.7c-0.4,3.2-1,6.6-1.5,10.5c-0.8,5-1.7,9.8-2.6,15.2c-1,5.8-2,11.2-2.6,15.5c-0.8,5.7-2.5,10.3-4.7,16.1
									c-3,8.1-6.8,18.2-9.7,36.2c-2.4,15-26.3,68.3-26.6,68.8c-1.3,3.4-2.7,8.7-4.2,16.3L64.5,476.7z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M126.3,278.8c-1.6,5.1-7.4,24.1-15.1,64.3
									c-8.3,43.4-29.5,105.5-33.6,119.3c3.2-7.3,24-54,26.2-68.2c2.9-17.8,6.6-27.9,9.6-36c2.2-5.9,3.9-10.5,4.7-16.3
									c0.5-3.3,1.2-7.4,1.9-11.7c2.2-14,5.2-31.8,6.2-45.5C126.4,282.6,126.4,280.6,126.3,278.8z"/>
							</g>
						</g>
						<g id="front_layer19">
							<path fill="#FFFFFF" d="M54.9,473.3c1.2-3.3,2.7-7.8,3.2-9.8c0.2-0.7,0.4-2.4,0.9-6.2c0.6-4.3,1.4-10.8,2.9-20.8l3.8-26.8
								c1.5-10.8,4.4-21.6,8.5-32.2c2.6-6.6,5.1-11.8,6.8-15.2c0.9-1.9,1.7-3.4,2.2-4.7c0.6-1.4,0.9-2.6,1-3.8l0-0.3l0-0.4
								c0.1-1,1.4-24.6,1.4-28.7c0-24.4,2-38.3,3.4-47.4c0.6-4.2,1.1-7.5,1.2-10.6c0.1-1.8,0.2-3.1,0.3-4.5c0.5-5.7,0.7-10.1-0.8-23.4
								l0.9,0.4l-0.1-0.2c2.6,0.8,5.5,1.3,8.6,1.4l0.3,0c1.5,0.1,4.8,0.1,10,0.2c1.5,0,2.8,0,4.3,0c1,0,2,0,3.2,0
								c2.9,15.8-3,60.8-6.4,80.5l0,0.3c-1.2,7.1-2.4,13.8-3.1,19c-0.6,4.7-2.1,8.6-4.1,14.1c-3.2,8.6-7.2,19.2-10.2,38
								c-1.6,10.2-16.7,46.2-25.8,66.4l-0.1,0.2l-0.1,0.3c-1.4,3.6-2.9,9.2-4.5,16.8L54.9,473.3z"/>
						</g>
						<g id="front_layer6">
							<path fill="#FFFFFF" d="M46.3,470.3c0.7-1.9,1.4-3.9,2.2-6.3c0.1-0.2,0.1-0.4,0.1-0.5c0-0.9,0.4-4,3.8-27.9l3.8-26.9
								c3.4-24.3,12.1-41.9,16.2-50.3c1.5-3.1,2.2-4.5,2.3-5.5c0-0.3,1.3-24.4,1.3-28.3c0-25.1,2.1-39.3,3.5-48.8c0.6-3.9,1-7,1.1-9.5
								c0.1-2.1,0.2-3.6,0.3-5.1c0.4-4.5,0.7-8.3-1-22.6c-0.3-2.2-0.4-4.5-0.4-6.8c0.4,0.6,1,1.1,1.5,1.7c1.6,1.6,3.6,2.9,5.9,4l0.1,0.2
								c1.6,13.9,1.5,18.3,1,23.9c-0.1,1.5-0.3,2.9-0.3,4.7c-0.1,3-0.6,6.2-1.2,10.3c-1.3,9.2-3.4,23.1-3.4,47.7c0,4.1-1.3,28.4-1.4,28.6
								l0,0.3l0,0.2c-0.1,1-0.4,2-0.9,3.2c-0.5,1.2-1.2,2.6-2.1,4.6c-1.7,3.5-4.3,8.7-6.9,15.4c-4.2,10.7-7.1,21.7-8.6,32.7l-3.8,26.8
								c-1.4,10-2.3,16.5-2.9,20.8c-0.5,3.5-0.7,5.4-0.9,6c-0.4,1.9-1.9,6.3-3.1,9.6L46.3,470.3z"/>
						</g>
						<g id="front_layer5">
							<g>
								<path fill="#FFFFFF" d="M188.8,238.5c-4.9,0-9.8,0-14.6,0l-16.8-0.1l0,0l-5.5,0c-4.2,0-15.6-0.1-15.6-0.1l-8.1-0.1l-0.5,0
									l-8.9-0.1c-3.5,0-6.7-0.1-9.4-0.1c-3.5-0.1-8.1-0.2-9.9-0.2l-0.3,0c-4.1-0.2-7.7-1-10.7-2.3c-2.2-1-4-2.2-5.5-3.7
									c-1.6-1.6-2.6-3.2-3.1-4.2c0.9-16.7,8.8-39.5,20.7-48.8c7.9-6.2,17-6.9,20.6-6.9c1.1,0,1.8,0.1,1.9,0.1l0.2,0c0.2,0,0.4,0,0.6-0.1
									c0.5-0.2,1.1-0.4,1.8-0.7c4.1,0.4,12,1.8,26.7,6.2c13.7,4.1,24.3,4.6,32.8,5.1c4.1,0.2,7.7,0.4,10.9,1c1.5,0.3,2.9,1,4.6,1.8
									c0.8,0.4,1.6,0.8,2.5,1.2v0l0.7,0.3c3.2,1.3,6.5,1.9,10.3,2c3.6,0,6.6-0.6,9.7-1.7l0.7-0.3v-0.1c0,0,0,0,0,0c1-0.4,2-0.9,2.9-1.3
									c1.6-0.8,3.1-1.5,4.6-1.8c3.2-0.5,6.8-0.7,10.9-1c7.3-0.4,16.4-0.9,27.7-3.7c1.1-0.2,2.2-0.5,3.5-0.8c0.5-0.1,1.1-0.3,1.7-0.4
									l0.3-0.1l0.1,0l0.1,0c1-0.3,1.9-0.6,2.8-0.8c0,0,2.4-0.7,3.1-0.9c10.9-3,17.1-4.1,20.4-4.4c0.6,0.3,1.2,0.5,1.7,0.7
									c0.2,0.1,0.4,0.1,0.6,0.1h0.1l0.1,0c0,0,0.7-0.1,1.9-0.1c3.5,0,12.7,0.7,20.6,6.9c12.3,9.7,19.8,31.9,21,49
									c-0.6,1.4-1.8,3.4-4.1,5.3c-0.1,0.1-0.3,0.2-0.5,0.4c-0.2,0.1-0.4,0.3-0.6,0.4c-1.2,0.8-2.5,1.5-3.9,2c-0.4,0.2-0.8,0.3-1.3,0.5
									l-0.1,0c-0.4,0.1-0.9,0.3-1.3,0.4l-0.1,0c-1,0.3-2,0.5-3.1,0.6l-0.3,0c-0.5,0.1-0.9,0.1-1.4,0.2l-0.4,0c-0.6,0.1-1.2,0.1-1.8,0.1
									c-0.6,0-1.4,0.1-2.3,0.1c-2.3,0.1-5.6,0.1-10,0.2l-10.7,0.1l-17.6,0.1l-8.9,0l-8.9,0l-1.1,0l-19.8,0.1l-28.5,0l-6.7,0L188.8,238.5
									z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M144.4,173.8c-10.1-2.7-15.9-3.6-19.1-3.9
									c-0.5,0.2-0.9,0.4-1.3,0.6l36.4,11.7C160.3,182.2,152.4,178,144.4,173.8z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M281.6,174.4c-7.7,4-14.9,7.8-14.9,7.8l36.8-11.8
									c-0.3-0.1-0.6-0.3-1-0.4C299,170.2,292.7,171.3,281.6,174.4z"/>
							</g>
						</g>
						<g id="front_layer4">
							<g>
								<g>
									<path fill="#FFFFFF" d="M235.5,120.5c1.7-1.7,3.2-3.5,4.7-5.6v12.2c0,0.2,0.2,15.5,14.2,20.8l0.2,0.1c5.5,2.1,16.8,7.8,27.7,13.3
										c6,3,11.7,5.9,16,8c-3.6,0.6-9.1,1.8-17.1,4c-0.7,0.2-1.5,0.4-2.3,0.7l-0.8,0.2c-0.9,0.3-1.9,0.5-2.9,0.8
										c-0.6,0.2-1.2,0.3-1.8,0.5l-0.2,0c-0.5,0.1-1.1,0.3-1.6,0.4c-0.6,0.1-1.3,0.3-1.9,0.4c-11.1,2.8-20.1,3.3-27.3,3.7
										c-4.2,0.2-7.8,0.4-11.1,1c-1.8,0.3-3.5,1.1-5.2,2c-0.3,0.1-0.6,0.3-0.9,0.4L235.5,120.5z"/>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M234.4,119.9l-2,12c3-1.7,6-3.7,8.9-6.1v-14.7
										C239.1,114.7,236.8,117.6,234.4,119.9z"/>
								</g>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M302.4,169.9c-1.7-0.7-3.8-1.8-6.2-2.9
									c-3.4,1.7-9.1,4.6-14.6,7.4C292.9,171.2,299,170.2,302.4,169.9z"/>
							</g>
						</g>
						<g id="front_layer17">
							<path fill="#FFFFFF" d="M213.9,186.2c-4.2,0-7.1-0.9-9-1.6l-0.2-0.1l-10.1-61.7c7.1,6,14.2,7.4,19.2,7.5c4.9-0.1,11.9-1.4,19-7.3
								l-10,61.6c0,0-0.8,0.3-0.8,0.3v0.1C219.5,185.8,216.9,186.2,213.9,186.2z"/>
							<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M213.9,131.1c-4.9-0.1-12.1-1.5-19.2-7.5l2.7,16.5
								c9.9,0.5,22.3-0.6,33.9-6.7l1.6-9.6C225.8,129.7,218.8,131.1,213.9,131.1z"/>
						</g>
						<g id="front_layer3">
							<g>
								<g>
									<path fill="#FFFFFF" d="M201.4,183c-1.7-0.8-3.4-1.6-5.2-2c-3.3-0.6-6.9-0.8-11.1-1c-8.4-0.4-18.8-1-32.2-5
										c-11.4-3.4-18.8-5-23.5-5.8c4.3-2.1,10-4.9,15.9-8c10.9-5.5,22.2-11.3,27.7-13.4l0.2-0.1c14.1-5.3,14.2-20.6,14.2-20.8v-12.2
										c1.4,2,2.9,3.8,4.5,5.4l10.3,63.1C201.9,183.3,201.6,183.1,201.4,183z"/>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M193,119.7c-2.3-2.3-4.6-5.1-6.7-8.6v16
										c0,0.1-0.1,5.1-2.8,10.2c3.1,0.7,7.5,1.5,12.6,1.8L193,119.7z"/>
								</g>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M144.4,173.8c-5-2.6-10-5.1-13.2-6.7
									c-2.3,1.1-4.3,2.1-5.9,2.8C128.4,170.2,134.3,171.1,144.4,173.8z"/>
							</g>
						</g>
					</svg>
				</div>
				<div className="shape not-slider male-back">
					<svg version="1.1" id="rear_layer_male" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 357.3 965.2" enableBackground="new 0 0 357.3 965.2" >
							<text textDecoration="underline" fontFamily="PoppinsBold"  x="0" y="120" fill="black" fontSize="15px">Izquierda</text>
							<text textDecoration="underline" fontFamily="PoppinsBold"  x="58%" y="120" fill="black" fontSize="15px">Derecha</text>
							
							<g id="cuerpo_x2F_sombras_x2F_pelo_x2F_pantalon">
							<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M355.9,510.1
								c-0.8-0.4-2.5-0.8-3.7-1.8c-15.9-12.4-26.8-21.5-33.9-28c-2.5-2.3-5.1-8.6-7.9-17c0-1.4-0.5-5-7.6-55.1
								c-4.5-32.2-18.2-52.8-18.6-55.7c0,0-1.3-24.4-1.3-28.1c0-35.9-4.3-50.1-4.6-58.3c-0.3-8.3-1.7-8.5,0.6-27.5
								c2.3-19-5.6-49.2-21-61.3c-10.9-8.5-23.6-7.2-23.6-7.2c-8.2-3.2-39-19.9-49.3-23.8c0,0-0.2-0.1-0.2-0.1
								c-13.2-5-13.2-19.2-13.2-19.2V109c1.5-2.5,2.9-5.4,4.2-8.6c3.2-7.9,4.7-19.3,6.5-32.2c0.2-1.6,0.4-3.2,0.7-4.9
								c1.7-12.2,1.2-21.8-0.8-29.4c-7.5-28.8-35.7-28.4-38.6-28.3c-2.9-0.1-31.1-0.5-38.6,28.3c-2,7.6-2.5,17.2-0.8,29.4
								c0.2,1.6,0.5,3.3,0.7,4.9c1.8,12.9,3.3,24.3,6.5,32.2c1.3,3.2,2.7,6.1,4.2,8.6v17.8c0,0,0,14.3-13.2,19.2c0,0-0.2,0.1-0.2,0.1
								C91.8,150,61,166.7,52.7,170c0,0-12.8-1.4-23.6,7.2c-15.4,12.1-23.6,42.3-21.4,61.3c2.3,19,0.9,19.2,0.6,27.5
								c-0.3,8.3-5.1,29.1-2.9,64.9c1.4,23.9,2.4,13.2,0.7,27.7c-0.3,2.9-4.1,17.1-2.5,67.6c1.6,50.6-0.5,51.3,0.1,51.3
								C3.2,490.3,2,498.6,2,498.6s-2.9,40.7-0.6,46.8c2.3,6.1,1.7,13.7,3.8,17.6c2,3.9,15.7,23.1,16.8,27c1.2,3.9,5.5,3.3,6.1,0
								c0.6-3.3-9-17.8-8.7-21.5c0,0,6.7,2.8,7-2.8c0.3-5.6-0.2-25.9,1.8-32.6c2-6.7-8.7-30.1-6.4-39c0.7-2.7,1.2-8.9,3.1-16.6
								c4.1-16.9,18.7-58.4,19.6-66.9c3-28.6,1.1-41.8,2.8-55.5c1-8.5,3.3-18.5,5-33.5c1.7-15.4,4.3-32.4,4.5-47.6c0,0,3,10.9,4.2,15
								c2.4,8.5,13.3,37.8,12.1,63.2c-0.4,8.7-3.6,23.7-3,34.6c0,0-9.4,19.2-11,39.6c-1.6,20.4-2.2,28-4.4,44.5
								c-2.2,16.5-15.7,47.6-9.4,132c2.2,29.1,8.5,33.1,5.7,69.8c-2.4,32.3-18.9,62.1-14.5,118.2c4.1,53.3,6.7,76.7,6.6,95.5h0
								c-0.6,11.9-1.2,20.2-1.3,21.9c0,0-3.9,14.6-2.6,21.1c1.3,6.4-7.7,18.1-15.3,24.5s-1,8.2,2.1,8.9c2.5,0.5,49.2,7,45.2-7.3
								c-1.2-4.3-2.4-2.5-1.9-17.6c0.3-8.7,2.9-9,1.3-16.6c0,0-2.3-8.3-2.5-12h-0.2c4.9-29.3,11.1-58.4,17.5-87.5
								c2.1-10.7,4.2-21,5.7-29.6c1.6-8.9,6.9-29.2,7.1-31.3c0,0,3.4-13.3,4.6-35.3c0.1-3.4,0.1-6.9,0.1-10.3c0-3.5,0.1-7.1,0.3-10.7
								c0-1.3,0-2.6-0.1-3.9c-0.1-2.7,0.4-5.3,1.3-8c0.4-2.6,1-5.2,1.7-7.8c2.7-9.9,8-18.9,10.5-28.9c4.9-19.3,6.8-39.1,9.6-58.8
								c2.9-20.4,5.8-40.9,8.2-61.4c0,0,0.2-3.4,0.6-7.6c0.3-0.1,0.6-0.2,0.9-0.4c0.9-0.4,1.7-0.8,2.6-1.2l5,0.6l5-0.6
								c1,0.5,2.3,0.9,3.5,1.3c0.2,0.1,0.3,0.1,0.5,0.2c0.2,2.5,0.4,5.2,0.8,7.9c3.6,26.6,10,75.9,15.9,110.3
								c4.9,28.6,15.8,41.7,15.4,54.4c-1.1,38.1,5,60.2,5,60.2c0.3,2.2,5.5,22.5,7.2,31.3c6.1,33.4,19.1,94.2,19.1,94.2
								c2.4,13.3,3.9,22.4,3.9,22.4c0,3.3-2.5,12.5-2.5,12.5c-1.7,7.5,1,7.9,1.3,16.6c0.5,15.1-0.7,13.4-1.9,17.6
								c-4.1,14.3,42.7,7.8,45.2,7.3c3.1-0.6,9.6-2.5,2.1-8.9c-7.5-6.4-16.6-18.1-15.3-24.5c1.3-6.4-2.6-21.1-2.6-21.1
								c-0.1-1.7-0.7-10.1-1.3-21.9h-0.1c-0.1-18.8,1.7-42.3,6.6-95.5c5-53.3-12.4-86.5-14.5-118.2c-2.5-36.8,3.6-40.8,5.7-69.8
								c6.3-84.5-7.1-115.3-9.3-132c-2.2-16.5-2.8-24.2-4.4-44.5c-1.6-20.4-11-39.6-11-39.6c0.6-11-2.6-25.9-3-34.6
								c-1.2-25.4,9.7-54.6,12.1-63.2c1.6-5.9,2-5.1,3.4-12.3c0.3,17.7,6.7,49,8.9,65.2c2,14.2,9.5,21.8,14.4,52.3
								c2.5,15.5,26.7,69.2,26.7,69.2c2.6,6.5,4.7,19.2,5.6,23.5c1.1,5.7,1.7,14.5,5,23.8c2.6,7.5,3.6,9.3,6.5,16.9
								c2.4,6.3,11.2,28.4,12.1,30.3c1.7,3.8,7.7,2,7.1-0.8c-0.4-1.7-0.9-3.9-1.5-6.3c1.6,5.8,3.5,11.2,4.4,13.4c2.4,5.7,9.2,4.2,7.5-2.5
								c0.3,1.1,0.6,2,0.9,2.7c1.4,4.3,8.8,5.4,7.4-3c-0.2-1.4-7.8-34.9-8.4-37.6c0.8,2.6,8.5,35.8,8.9,36.4c1.4,2.1,7.7,2,6.3-6.5
								c-1.8-10.6-3.2-18.7-4.6-27.6c-1.3-8.6-2.7-14.5-3.4-15.9c0,0,2.1-0.5,3.2-0.8c3.3-0.7,8.1,4.7,13.5,6.3
								C355.4,517.2,358.4,511.4,355.9,510.1z"/>
							<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M182.6,65.2
								c5,0.5,18.6,4-4.7,28.3C179.9,85.7,181.1,75.9,182.6,65.2z"/>
							<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M104.4,65.2
								c-5,0.5-18.6,4,4.7,28.3C107.1,85.7,105.9,75.9,104.4,65.2z"/>
							
								<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="317.7" y1="527.9" x2="326.3" y2="561.6"/>
							
								<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="307.9" y1="529.2" x2="314.4" y2="550.5"/>
							<path fill="#DDC7BF" d="M17.4,548.8c0,0,0.6-0.8-0.4,1c-0.3,0.4-0.5,0.9-0.7,1.5c-0.2,0.5-0.4,1.1-0.5,1.7
								c-0.2,0.6-0.2,1.3-0.3,1.9c0,0.7,0,1.3,0,2c0.1,0.7,0.1,1.3,0.3,2c0.2,0.6,0.3,1.3,0.5,1.9c0.2,0.6,0.4,1.2,0.6,1.7
								c0.3,0.5,0.5,1,0.7,1.4c0.4,0.9,1,1.6,1.2,2.1c0.3,0.5,0.5,0.8,0.5,0.8l0,0.1c0.2,0.4,0.1,0.9-0.3,1.1s-0.9,0.1-1.1-0.3
								c0,0,0,0,0-0.1c0,0-0.2-0.3-0.5-0.9c-0.3-0.6-0.8-1.3-1.1-2.3c-0.2-0.5-0.4-1-0.7-1.6c-0.2-0.6-0.4-1.2-0.6-1.8
								c-0.2-0.6-0.3-1.3-0.4-2c-0.2-0.7-0.1-1.4-0.2-2.1c0-0.7,0.1-1.4,0.1-2.1c0.1-0.7,0.2-1.4,0.5-2c0.2-0.6,0.4-1.2,0.7-1.8
								c0.3-0.5,0.5-1,0.8-1.5c1.2-1.7,0.8-1.1,0.8-1.1"/>
							<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M21.5,500.1
								c0,0,2.7,12.8-0.9,29.5c-3.5,16.7-3.6,17.3-3,24.9"/>
							<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M19.4,568.7
								c0,0-2.5-19.6-2-20.9"/>
							<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8.7,568.7
								c0,0,5.8,8.3,10.6,13.9c2.4,2.7,7.7,2.6-2.1-17.7"/>
							<path fill="#EFD3D3" d="M238.6,341.8c-9.2-50.1-8.8-65-8.8-65s6.9,18.5,16.1,66.2c8.5,44.5,30.6,108.5,33.8,120.3
								c0,0-24.2-53.7-26.7-69.2C248.1,363.7,240.5,356,238.6,341.8"/>
							<path fill="#DDC7BF" d="M247,368.2c0,0,2,7.4,12,7.4"/>
							<path fill="#ECF2F6" d="M90.7,689.3c0,0-4.5,8.3-5.4,22.3"/>
							<path fill="#DDC7BF" d="M90.7,689.3c0,0-0.4,1.4-1,3.5c-0.2,1.1-0.6,2.2-0.9,3.5c-0.3,1.3-0.7,2.7-1,4.1c-0.2,0.7-0.3,1.4-0.5,2.1
								c-0.1,0.7-0.3,1.4-0.4,2c-0.1,0.7-0.3,1.3-0.4,1.9c-0.1,0.6-0.2,1.2-0.3,1.7c-0.4,2.1-0.8,3.5-0.8,3.5s-0.1-1.4-0.1-3.6
								c0-1.1,0.1-2.4,0.3-3.7c0.1-0.7,0.2-1.4,0.3-2.1c0.2-0.7,0.3-1.4,0.5-2.1c0.3-1.4,0.7-2.8,1.2-4.1c0.4-1.3,1-2.5,1.4-3.5
								c0.5-1,0.9-1.8,1.3-2.3C90.5,689.6,90.7,689.3,90.7,689.3"/>
							<path fill="#ECF2F6" d="M195.1,689.3c0,0,4.5,8.3,5.4,22.3"/>
							<path fill="#DDC7BF" d="M195.1,689.3c0,0,0.2,0.3,0.6,0.8c0.4,0.5,0.8,1.3,1.3,2.3c0.4,1,1,2.1,1.4,3.5c0.5,1.3,0.9,2.7,1.2,4.1
								c0.2,0.7,0.3,1.4,0.5,2.1c0.1,0.7,0.2,1.4,0.3,2.1c0.2,1.4,0.3,2.6,0.3,3.7c0,2.2-0.1,3.6-0.1,3.6s-0.4-1.4-0.8-3.5
								c-0.1-0.5-0.2-1.1-0.3-1.7c-0.1-0.6-0.3-1.2-0.4-1.9c-0.1-0.7-0.3-1.3-0.4-2c-0.2-0.7-0.3-1.4-0.5-2.1c-0.3-1.4-0.6-2.8-1-4.1
								c-0.3-1.3-0.7-2.5-0.9-3.5C195.5,690.7,195.1,689.3,195.1,689.3"/>
							<line fill="none" x1="124.8" y1="407" x2="133.4" y2="416.3"/>
							<path fill="#DDC7BF" d="M124.9,406.9c0,0,0.8,0.3,1.8,1.1c0.5,0.4,1,0.8,1.5,1.3c0.5,0.6,0.9,1.2,1.4,1.8c2,2.5,3.8,5,3.8,5
								c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0-2.4-2.1-4.7-4.2c-0.6-0.5-1.2-1-1.7-1.6c-0.5-0.5-0.9-1.1-1.2-1.6c-0.6-1-0.9-1.8-0.9-1.8
								C124.8,407,124.8,407,124.9,406.9C124.9,406.9,124.9,406.9,124.9,406.9"/>
							<line fill="none" x1="161" y1="407" x2="152.4" y2="416.3"/>
							<path fill="#DDC7BF" d="M161,407.1c0,0-0.3,0.8-0.9,1.8c-0.3,0.5-0.7,1.1-1.2,1.6c-0.5,0.5-1.1,1-1.7,1.6c-2.3,2.2-4.7,4.2-4.7,4.2
								c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0,0,1.9-2.6,3.8-5c0.5-0.6,0.9-1.3,1.4-1.8c0.5-0.5,1-1,1.5-1.3c1-0.7,1.8-1.1,1.8-1.1
								C161,406.9,161,406.9,161,407.1C161,407,161,407,161,407.1"/>
							<path fill="#DDC7BF" d="M142.9,162.9c0,0,0.1,0.7,0.2,2.1c0.1,1.3,0.2,3.2,0.3,5.4c0.1,2.2,0.2,4.9,0.3,7.7
								c0.1,2.8,0.1,5.8,0.1,8.8c0,3,0,6-0.1,8.8c0,2.8-0.2,5.4-0.3,7.7c-0.1,2.2-0.2,4.1-0.3,5.4c-0.1,1.3-0.2,2.1-0.2,2.1
								s-0.1-0.7-0.2-2.1c-0.1-1.3-0.2-3.2-0.3-5.4c-0.1-2.2-0.2-4.9-0.3-7.7c-0.1-2.8-0.1-5.8-0.1-8.8c0-3,0-6,0.1-8.8
								c0.1-2.8,0.2-5.4,0.3-7.7c0.1-2.2,0.2-4.1,0.3-5.4C142.8,163.6,142.9,162.9,142.9,162.9"/>
							<path fill="#DDC7BF" d="M129,242.9c0,0-0.3,2.3-1.3,5.5c-0.5,1.6-1.2,3.5-2.1,5.4c-0.5,1-0.9,2-1.5,2.9c-0.6,1-1.1,2-1.8,2.9
								c-0.6,0.9-1.3,1.9-2,2.8c-0.7,0.9-1.4,1.7-2.1,2.5c-0.4,0.4-0.7,0.8-1.1,1.2c-0.4,0.3-0.8,0.7-1.1,1c-0.7,0.7-1.4,1.3-2.1,1.8
								c-0.7,0.5-1.3,1-1.9,1.4c-0.6,0.4-1.1,0.7-1.5,0.9c-0.9,0.5-1.4,0.8-1.4,0.8s0.4-0.4,1.2-1c0.4-0.3,0.8-0.7,1.3-1.1
								c0.5-0.5,1-1,1.6-1.6c0.6-0.6,1.2-1.2,1.9-1.9c0.3-0.3,0.7-0.7,1-1.1c0.3-0.4,0.6-0.8,1-1.2c0.3-0.4,0.7-0.8,1-1.2
								c0.3-0.4,0.6-0.9,1-1.3c0.7-0.9,1.3-1.8,1.9-2.7c0.6-0.9,1.2-1.9,1.8-2.8c0.5-1,1.1-1.9,1.6-2.8c0.5-0.9,1-1.8,1.4-2.7
								c0.4-0.9,0.8-1.7,1.2-2.4c0.7-1.5,1.3-2.8,1.6-3.7C128.8,243.4,129,242.9,129,242.9"/>
							<path fill="#DDC7BF" d="M156.8,242.9c0,0,0.2,0.5,0.6,1.4c0.4,0.9,0.9,2.2,1.6,3.7c0.3,0.8,0.7,1.6,1.2,2.4
								c0.4,0.9,0.9,1.7,1.4,2.7c0.5,0.9,1,1.8,1.6,2.8c0.6,0.9,1.1,1.9,1.8,2.8c0.6,0.9,1.2,1.9,1.9,2.7c0.3,0.4,0.6,0.9,1,1.3
								c0.3,0.4,0.7,0.8,1,1.2c0.3,0.4,0.7,0.8,1,1.2c0.3,0.4,0.7,0.7,1,1.1c0.6,0.7,1.2,1.4,1.9,1.9c0.6,0.6,1.1,1.1,1.6,1.6
								c0.5,0.4,1,0.8,1.4,1.1c0.7,0.6,1.2,1,1.2,1s-0.5-0.3-1.4-0.8c-0.4-0.3-0.9-0.6-1.5-0.9c-0.6-0.4-1.2-0.9-1.9-1.4
								c-0.7-0.5-1.4-1.1-2.1-1.8c-0.4-0.3-0.7-0.7-1.1-1c-0.3-0.4-0.7-0.8-1.1-1.2c-0.7-0.8-1.5-1.6-2.1-2.5c-0.7-0.9-1.3-1.8-2-2.8
								c-0.7-0.9-1.2-2-1.8-2.9c-0.5-1-1-2-1.5-2.9c-0.9-1.9-1.6-3.8-2.1-5.4C157.1,245.2,156.8,242.9,156.8,242.9"/>
							<path fill="#ECF2F6" d="M60.7,288.8c0,0-6.4-21.4-9.9-40"/>
							<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M60.7,288.8
								c0,0-6.4-21.4-9.9-40"/>
							<path fill="#DDC7BF" d="M127,122.7c0,0,0.2,0,0.6,0.1c0.4,0.1,0.9,0.2,1.4,0.4c0.6,0.2,1.2,0.5,1.9,1c0.3,0.2,0.6,0.5,1,0.7
								c0.3,0.2,0.6,0.6,0.9,0.8c0.3,0.3,0.6,0.6,0.8,0.9c0.2,0.3,0.4,0.7,0.7,1c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0.3,0.6,0.4,0.9
								c0.4,1.2,0.6,2,0.6,2s-0.6-0.6-1.4-1.5c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.5-0.5-0.7-0.8c-0.2-0.3-0.5-0.5-0.7-0.8
								c-0.3-0.3-0.5-0.5-0.8-0.8c-0.3-0.3-0.5-0.6-0.8-0.8c-0.3-0.2-0.5-0.5-0.8-0.8c-0.3-0.3-0.5-0.5-0.8-0.7c-0.2-0.2-0.5-0.4-0.7-0.6
								C127.6,123.2,127,122.7,127,122.7"/>
							<path fill="#DDC7BF" d="M159.2,122.7c0,0-0.6,0.5-1.5,1.3c-0.2,0.2-0.4,0.4-0.7,0.6c-0.2,0.2-0.5,0.4-0.8,0.7
								c-0.3,0.2-0.5,0.5-0.8,0.8c-0.3,0.2-0.5,0.5-0.8,0.8c-0.3,0.3-0.5,0.5-0.8,0.8c-0.2,0.3-0.5,0.6-0.7,0.8c-0.2,0.3-0.5,0.5-0.7,0.8
								c-0.2,0.2-0.4,0.5-0.6,0.7c-0.7,0.9-1.4,1.5-1.4,1.5s0.1-0.8,0.6-2c0.1-0.3,0.2-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9
								c0.2-0.3,0.4-0.7,0.7-1c0.3-0.3,0.6-0.6,0.8-0.9c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.3,0.6-0.5,1-0.7c0.6-0.5,1.3-0.8,1.9-1
								c0.6-0.2,1.1-0.4,1.4-0.4C158.9,122.7,159.2,122.7,159.2,122.7"/>
							<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M226.3,288.8
								c0,0,6.4-21.4,9.9-40"/>
							<path fill="#EFD3D3" d="M47.1,358.4c-1.6,14.2,0.2,23.4-2.8,52c-0.2,2.1-1.5,7.3-3.4,14c1.8-13.6-3.4-50.1-3.4-50.1
								s1.1-1.6,2.7-3.4C42.7,368.2,47.1,358.4,47.1,358.4z"/>
							<path fill="#DDC7BF" d="M29.3,379c0,0,7.7,0.4,11.4-9.9"/>
							<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M36.7,944.5
								c0,0,2.2-6.3,2.2-12.7"/>
							<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M250.2,944.5
								c0,0-2.2-6.3-2.2-12.7"/>
							<path fill="#DDC7BF" d="M31,963.2c0,0,8.7-2.2,11.5-4.6c0,0,0.9,5.4,9.4,6.1C51.8,964.7,32.8,964.1,31,963.2"/>
							<path fill="#DDC7BF" d="M253.6,963.8c0,0-8.1-2.8-10.8-5.2c0,0-0.9,5.4-9.4,6.1C233.4,964.7,251.7,964.7,253.6,963.8"/>
							<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M52.5,964.7
								c-11.6-1-10.1-7.1-10.1-7.1"/>
							<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M232.1,964.6
								c11.6-0.9,10.7-7,10.7-7"/>
							<path fill="#DDC7BF" d="M278.3,238.4c-1.7,14.2-1.3,18-1,22.4c0.1,1.5,0.3,3.1,0.3,5.2c0.1,2.5,0.5,5.5,1.1,9.4
								c1.4,9.5,3.5,23.8,3.5,48.9c0,3.8,1.3,27.2,1.3,28.2c0.1,0.8,0.8,2.3,2.2,5.1c4.2,8.5,12.9,26.1,16.3,50.6l3.8,26.6
								c3.5,24.8,3.9,27.4,3.8,28.4c0,0.1,0,0.1,0,0.2c1,2.9,1.9,5.4,2.7,7.6l0.4-0.1c-0.6-1.5-2.4-5.8-2.7-7.6c-0.2-1.4-0.5-5-7.6-55.1
								c-4.5-32.2-18.2-52.8-18.6-55.7c0,0-1.3-24.4-1.3-28.1c0-35.9-4.3-50.1-4.6-58.3s-1.7-8.5,0.6-27.5c0.4-3.5,0.5-7.4,0.2-11.5
								c-0.1,0.2-0.2,0.5-0.3,0.9C278.8,231.5,278.7,235.1,278.3,238.4z"/>
							<g>
								<path fill="#EFD3D3" d="M124.5,530.6c2.7,11,4.9,27.7,2.7,50.7c3.7-17.6,4.6-33.9,6.2-45.2c0.4-2.6,0.6-5.2,0.8-7.7
									C131,529.6,127.8,530.3,124.5,530.6z"/>
							</g>
							
								<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="143.1" y1="522.3" x2="143.1" y2="485.4"/>
							<g>
								<path fill="#EFD3D3" d="M162,530.7c-3.3-0.3-6.6-1-9.7-2.1c0.2,2.4,0.4,5,0.8,7.6c1.5,11.3,2.7,27.6,6.4,45.2
									C157.3,558.4,159.3,541.7,162,530.7z"/>
								<path fill="#DDC7BF" d="M172.7,529.7c-3.5,0-6.9-0.1-10.4-0.6c-3.4-0.4-6.8-1.1-10-2.3c-0.8-0.3-1.6-0.6-2.4-0.9
									c-0.8-0.3-1.5-0.7-2.3-1.1c-0.7-0.4-1.5-0.8-2.2-1.3c-0.7-0.4-1.3-0.9-1.9-1.4c-0.1-1.1-0.1-2.3-0.2-3.4c-0.1-1.2-0.2-2.5-0.3-3.7
									c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,1.2-0.2,2.5-0.3,3.7c-0.1,1.1-0.1,2.3-0.2,3.4c-0.6,0.5-1.2,1-1.9,1.4c-0.7,0.5-1.4,0.9-2.2,1.3
									c-0.7,0.4-1.5,0.8-2.3,1.1c-0.8,0.4-1.6,0.6-2.4,0.9c-3.2,1.1-6.6,1.8-10,2.3c-3.4,0.4-6.9,0.6-10.4,0.6c-3.5,0-7-0.2-10.4-0.6
									c1.7,0.5,3.4,0.8,5.2,1.1c1.7,0.3,3.5,0.5,5.2,0.6c1.8,0.1,3.5,0.2,5.3,0.2c1.8,0,3.5-0.1,5.3-0.3c3.5-0.4,7-1.1,10.4-2.3
									c0.8-0.3,1.7-0.7,2.5-1.1c0.8-0.4,1.6-0.8,2.4-1.3c0.8-0.5,1.5-1,2.2-1.5c0.6-0.4,1.1-0.9,1.7-1.4c0.5,0.5,1.1,1,1.7,1.4
									c0.7,0.6,1.4,1.1,2.2,1.5c0.8,0.5,1.6,0.9,2.4,1.3c0.8,0.4,1.6,0.8,2.5,1.1c3.4,1.3,6.9,2,10.4,2.3c1.8,0.2,3.5,0.3,5.3,0.3
									c1.8,0,3.5-0.1,5.3-0.2c1.8-0.1,3.5-0.3,5.2-0.6c1.7-0.3,3.5-0.6,5.2-1.1C179.7,529.5,176.2,529.6,172.7,529.7z"/>
							</g>
							<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M355.9,510.1
								c-0.8-0.4-2.5-0.8-3.7-1.8c-15.9-12.4-26.8-21.5-33.9-28c-2.5-2.3-5.1-8.6-7.9-17c0-1.4-0.5-5-7.6-55.1
								c-4.5-32.2-18.2-52.8-18.6-55.7c0,0-1.3-24.4-1.3-28.1c0-35.9-4.3-50.1-4.6-58.3c-0.3-8.3-1.7-8.5,0.6-27.5
								c2.3-19-5.6-49.2-21-61.3c-10.9-8.5-23.6-7.2-23.6-7.2c-8.2-3.2-39-19.9-49.3-23.8c0,0-0.2-0.1-0.2-0.1
								c-13.2-5-13.2-19.2-13.2-19.2V109c1.5-2.5,2.9-5.4,4.2-8.6c3.2-7.9,4.7-19.3,6.5-32.2c0.2-1.6,0.4-3.2,0.7-4.9
								c1.7-12.2,1.2-21.8-0.8-29.4c-7.5-28.8-35.7-28.4-38.6-28.3c-2.9-0.1-31.1-0.5-38.6,28.3c-2,7.6-2.5,17.2-0.8,29.4
								c0.2,1.6,0.5,3.3,0.7,4.9c1.8,12.9,3.3,24.3,6.5,32.2c1.3,3.2,2.7,6.1,4.2,8.6v17.8c0,0,0,14.3-13.2,19.2c0,0-0.2,0.1-0.2,0.1
								C91.8,150,61,166.7,52.7,170c0,0-12.8-1.4-23.6,7.2c-15.4,12.1-23.6,42.3-21.4,61.3c2.3,19,0.9,19.2,0.6,27.5
								c-0.3,8.3-5.1,29.1-2.9,64.9c1.4,23.9,2.4,13.2,0.7,27.7c-0.3,2.9-4.1,17.1-2.5,67.6c1.6,50.6-0.5,51.3,0.1,51.3
								C3.2,490.3,2,498.6,2,498.6s-2.9,40.7-0.6,46.8c2.3,6.1,1.7,13.7,3.8,17.6c2,3.9,15.7,23.1,16.8,27c1.2,3.9,5.5,3.3,6.1,0
								c0.6-3.3-9-17.8-8.7-21.5c0,0,6.7,2.8,7-2.8c0.3-5.6-0.2-25.9,1.8-32.6c2-6.7-8.7-30.1-6.4-39c0.7-2.7,1.2-8.9,3.1-16.6
								c4.1-16.9,18.7-58.4,19.6-66.9c3-28.6,1.1-41.8,2.8-55.5c1-8.5,3.3-18.5,5-33.5c1.7-15.4,4.3-32.4,4.5-47.6c0,0,3,10.9,4.2,15
								c2.4,8.5,13.3,37.8,12.1,63.2c-0.4,8.7-3.6,23.7-3,34.6c0,0-9.4,19.2-11,39.6c-1.6,20.4-2.2,28-4.4,44.5
								c-2.2,16.5-15.7,47.6-9.4,132c2.2,29.1,8.5,33.1,5.7,69.8c-2.4,32.3-18.9,62.1-14.5,118.2c4.1,53.3,6.7,76.7,6.6,95.5h0
								c-0.6,11.9-1.2,20.2-1.3,21.9c0,0-3.9,14.6-2.6,21.1c1.3,6.4-7.7,18.1-15.3,24.5s-1,8.2,2.1,8.9c2.5,0.5,49.2,7,45.2-7.3
								c-1.2-4.3-2.4-2.5-1.9-17.6c0.3-8.7,2.9-9,1.3-16.6c0,0-2.3-8.3-2.5-12h-0.2c4.9-29.3,11.1-58.4,17.5-87.5
								c2.1-10.7,4.2-21,5.7-29.6c1.6-8.9,6.9-29.2,7.1-31.3c0,0,3.4-13.3,4.6-35.3c0.1-3.4,0.1-6.9,0.1-10.3c0-3.5,0.1-7.1,0.3-10.7
								c0-1.3,0-2.6-0.1-3.9c-0.1-2.7,0.4-5.3,1.3-8c0.4-2.6,1-5.2,1.7-7.8c2.7-9.9,8-18.9,10.5-28.9c4.9-19.3,6.8-39.1,9.6-58.8
								c2.9-20.4,5.8-40.9,8.2-61.4c0,0,0.2-3.4,0.6-7.6c0.3-0.1,0.6-0.2,0.9-0.4c0.9-0.4,1.7-0.8,2.6-1.2l5,0.6l5-0.6
								c1,0.5,2.3,0.9,3.5,1.3c0.2,0.1,0.3,0.1,0.5,0.2c0.2,2.5,0.4,5.2,0.8,7.9c3.6,26.6,10,75.9,15.9,110.3
								c4.9,28.6,15.8,41.7,15.4,54.4c-1.1,38.1,5,60.2,5,60.2c0.3,2.2,5.5,22.5,7.2,31.3c6.1,33.4,19.1,94.2,19.1,94.2
								c2.4,13.3,3.9,22.4,3.9,22.4c0,3.3-2.5,12.5-2.5,12.5c-1.7,7.5,1,7.9,1.3,16.6c0.5,15.1-0.7,13.4-1.9,17.6
								c-4.1,14.3,42.7,7.8,45.2,7.3c3.1-0.6,9.6-2.5,2.1-8.9c-7.5-6.4-16.6-18.1-15.3-24.5c1.3-6.4-2.6-21.1-2.6-21.1
								c-0.1-1.7-0.7-10.1-1.3-21.9h-0.1c-0.1-18.8,1.7-42.3,6.6-95.5c5-53.3-12.4-86.5-14.5-118.2c-2.5-36.8,3.6-40.8,5.7-69.8
								c6.3-84.5-7.1-115.3-9.3-132c-2.2-16.5-2.8-24.2-4.4-44.5c-1.6-20.4-11-39.6-11-39.6c0.6-11-2.6-25.9-3-34.6
								c-1.2-25.4,9.7-54.6,12.1-63.2c1.6-5.9,2-5.1,3.4-12.3c0.3,17.7,6.7,49,8.9,65.2c2,14.2,9.5,21.8,14.4,52.3
								c2.5,15.5,26.7,69.2,26.7,69.2c2.6,6.5,4.7,19.2,5.6,23.5c1.1,5.7,1.7,14.5,5,23.8c2.6,7.5,3.6,9.3,6.5,16.9
								c2.4,6.3,11.2,28.4,12.1,30.3c1.7,3.8,7.7,2,7.1-0.8c-0.4-1.7-0.9-3.9-1.5-6.3c1.6,5.8,3.5,11.2,4.4,13.4c2.4,5.7,9.2,4.2,7.5-2.5
								c0.3,1.1,0.6,2,0.9,2.7c1.4,4.3,8.8,5.4,7.4-3c-0.2-1.4-7.8-34.9-8.4-37.6c0.8,2.6,8.5,35.8,8.9,36.4c1.4,2.1,7.7,2,6.3-6.5
								c-1.8-10.6-3.2-18.7-4.6-27.6c-1.3-8.6-2.7-14.5-3.4-15.9c0,0,2.1-0.5,3.2-0.8c3.3-0.7,8.1,4.7,13.5,6.3
								C355.4,517.2,358.4,511.4,355.9,510.1z"/>
							<path fill="#4E5663" d="M186.4,29.5c-2-8.3-13-29.3-42.7-29.5C114,0.2,102.9,21.2,101,29.5c-1.7,7-2.7,17.7-1.7,23.8
								c0.7,4.6,1.4,8.5,2.1,11.9v0c0,0,1.6,17.4,2.6,21.5c0,0,2.6,14,11.1,20.6c0.8,0.7,1.8,1.2,2.7,1.7c0,0,1.9,1.3,5.4,1.6
								c0.9,0.2,2.6,0.5,4.5,1c2.8,0.8,6.3,2,9.3,3.9c3.4,2.1,6,6.6,6.7,6.7c0.7-0.2,3.3-4.6,6.7-6.7c2.7-1.7,5.8-2.9,8.4-3.6
								c2.7-0.8,4.9-1.2,5.8-1.3c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0l0,0c2.9-0.5,4.6-1.6,4.6-1.7c0.7-0.4,1.4-0.9,2.1-1.4
								c7-5.3,10.1-15.6,11-19.4c0.3-0.6,0.4-1,0.5-1.4c1.1-4.1,2.6-21.5,2.6-21.5v0c0.7-3.4,1.4-7.3,2.1-11.9
								C189.1,47.2,188.1,36.5,186.4,29.5z"/>
						</g>
						<g id="rear_layer15">
							<path fill="#FFFFFF" d="M243.1,897.2c-4.4-8.7-11.9-34.6-6.3-106.2c2.4-30.9-2.3-54.8-7.9-78.4c-3-12.9-5.9-25-6.7-37.5
								c-1.7-25.1,0.4-36.5,2.6-48.6c1.2-6.5,2.4-13.3,3.2-23.3c1.6-21.3,2-41.1,1.3-58.9l0-1.1l-0.1,0l-0.2-4.1
								c-0.2-3.4-0.4-6.8-0.7-10.1c5.5-2.7,9.4-5.7,11.6-8.8c0.1,1.2,0.3,2.5,0.4,3.8l0.1,1.4c2.1,22.6,2.1,48.1-0.1,77.7
								c-0.8,10.6-2.1,17.7-3.3,24.6c-2.1,11.8-4,22-2.4,45.5c0.8,11.4,3.5,23.1,6.4,35.4c5.3,22.7,11.3,48.4,8.2,82.6
								c-4.8,51.5-6.8,76.2-6.6,95.7c0,0.2,0,0.3,0.1,0.5C242.8,890.7,242.9,894,243.1,897.2z"/>
						</g>
						<g id="rear_layer22">
							<path fill="#FFFFFF" d="M221.8,908.6c2-7.7,4.4-36.6-13.3-140.2c-0.1-0.4-7.8-33-6.8-65.8c0.3-9.9-3.4-17.8-7.3-26.3
								c-4-8.7-6.7-18-8.9-30.2c-2.7-15.7-7.7-46.3-11-75c-0.8-7-2.5-20.5-4.1-32.4l-0.1-0.7c3.9,0.6,8.2,0.9,12.7,0.9
								c5,0,10.3-0.4,15.6-1.1c3.9-0.5,7.7-1.2,11.4-2.1l0,0c4.4-1,9.7-2.9,16-5.4c0.3,3.1,0.5,6.2,0.7,9.3l0.4,6.2l0,0
								c0.7,17.4,0.2,36.7-1.3,57.5c-0.7,9.9-2,16.6-3.1,23c-2.2,12.3-4.3,23.8-2.6,49.2c0.9,12.6,3.7,24.9,6.7,37.8
								c5.5,23.5,10.2,47.1,7.8,77.8c-2.9,37.2-2.7,66.5,0.7,87.1c2.3,13.7,5.4,20.2,7.3,23L221.8,908.6z"/>
						</g>
						<g id="rear_layer14">
							<path fill="#FFFFFF" d="M220.2,905.1c-0.7-3.9-1.8-10.5-3.3-18.6c-0.1-0.6-13.1-61.3-19.1-94.1c-1-5.3-3.1-14.3-4.9-21.6
								c-1.1-4.4-2.1-8.8-2.2-9.7l0-0.1l0-0.1c-0.1-0.2-6-22.4-4.9-59.7c0.2-6.5-2.3-12.9-5.5-21c-3.3-8.5-7.4-19.1-10-33.8
								c-4.9-28.4-10-66.6-13.8-94.4l-0.1-0.6c-0.8-5.6-1.4-10.7-2.1-15.2c-0.1-1.1-0.3-2.1-0.4-3.2c0-0.5-0.1-1-0.1-1.4
								c2.9,2.6,7.7,4.6,14.1,5.8l0.2,1.9c1.5,11.7,3.2,25,4,31.9c3.3,28.7,8.3,59.4,11,75.1c2.1,12.4,4.9,21.9,9,30.8
								c3.8,8.2,7.4,16,7.1,25.3c-1,33.1,6.8,66,6.9,66.4C222.5,863.4,221.8,895,220.2,905.1z"/>
						</g>
						<g id="rear_layer13">
							<path fill="#FFFFFF" d="M66.5,905.1c-1.5-10.1-2.2-41.7,14-136.5c0.1-0.3,7.8-33.2,6.8-66.3c-0.3-9.3,3.3-17.1,7.1-25.3
								c4.1-8.9,6.9-18.4,9-30.8c2.7-15.7,7.8-46.3,11-75.1c0.8-6.8,2.5-20.2,4-31.9l0.2-1.9c6.3-1.2,11-3.1,13.9-5.6
								c-0.2,2.2-0.3,3.8-0.3,4c-2,17.4-4.5,35-6.9,52.1l-1.3,9.2c-0.6,4.2-1.1,8.5-1.7,12.8l0,0.2c-2,15.1-4,30.7-7.8,45.6
								c-1.3,5.1-3.3,10.1-5.3,14.8c-1.9,4.5-3.8,9.2-5.2,14.1c-0.7,2.5-1.2,5.1-1.7,7.8c-1,3.1-1.4,5.8-1.3,8.5c0,1.3,0.1,2.6,0.1,3.9
								c-0.2,3.8-0.3,7.7-0.3,10.7l0,3.4c0,2.3,0,4.6-0.1,6.9c-0.1,0.9-0.1,1.9-0.2,2.8l0,0.6c-0.1,0.9-0.1,1.8-0.2,2.6l0,0.4
								c-0.1,0.8-0.1,1.5-0.2,2.3l0,0.5c-0.1,0.8-0.2,1.6-0.2,2.4l-0.1,0.5c-0.1,0.7-0.2,1.5-0.2,2.2l0,0.2c-0.1,0.7-0.2,1.5-0.3,2.2
								l-0.1,0.5c-0.1,0.7-0.2,1.4-0.3,2l-0.3,2.4c-0.1,0.6-0.3,2.2-0.3,2.2c-0.1,0.5-0.3,1.7-0.3,1.7c-0.1,0.5-0.3,1.9-0.3,1.9
								c-0.1,0.5-0.3,1.5-0.3,1.5l-0.3,1.4L97,757c-0.1,0.7-0.3,1.4-0.4,1.9c-0.3,1-0.5,1.6-0.6,1.8l-0.1,0.2l0,0.1c0,0.2-0.1,0.6-0.1,1.1
								l0,0.1c-0.2,0.9-0.5,2-0.8,3.4l-1.1,4.7c-1.8,7.3-3.9,16.4-4.9,21.6c-1.4,7.8-3.3,17.7-5.7,29.6c-1,4.7-2,9.4-3,14l-0.5,2.1
								c-3.7,17.3-6.6,31.4-9.1,44.4c0,0-1,4.4-1,4.4C68.3,894.6,67.2,901.1,66.5,905.1z"/>
						</g>
						<g id="rear_layer23">
							<path fill="#FFFFFF" d="M44,900.9c1.9-2.8,5.1-9.3,7.3-23c3.4-20.6,3.7-49.9,0.7-87.1c-2.4-30.6,2.3-54.2,7.8-77.7
								c3-13,5.9-25.2,6.8-37.9c1.7-25.4-0.4-36.9-2.6-49.2c-1.2-6.5-2.4-13.2-3.1-23c-1.6-20.9-2-40.4-1.3-57.8l0.1-0.7l0.3-5.2
								c0.2-3.1,0.4-6.2,0.7-9.3c6.3,2.5,11.7,4.4,16,5.4l0,0c3.7,0.9,7.5,1.6,11.4,2.1c5.3,0.7,10.5,1.1,15.6,1.1c4.5,0,8.8-0.3,12.7-0.9
								l-0.1,1c-1.5,11.8-3.2,25.2-4,32.2c-3.3,28.7-8.3,59.3-11,75C99,658,96.2,667.3,92.2,676c-3.9,8.5-7.6,16.4-7.3,26.3
								c1,32.8-6.7,65.5-6.8,65.8C66.5,836,63.7,871,63.4,888.5c-0.2,11.3,0.6,17,1.3,19.9L44,900.9z"/>
						</g>
						<g id="rear_layer12">
							<path fill="#FFFFFF" d="M43.6,897.2c0.2-3.2,0.4-6.5,0.5-9.9c0-0.1,0.1-0.3,0.1-0.5c0.1-19.5-1.8-44.2-6.6-95.7
								c-3.2-34.1,2.8-59.9,8.2-82.5c2.9-12.4,5.6-24,6.4-35.4c1.6-23.5-0.3-33.7-2.4-45.5c-1.2-6.9-2.5-14-3.3-24.6
								c-2.2-29.6-2.3-55-0.1-77.7l0.1-1.4c0.1-1.3,0.3-2.5,0.4-3.8c2.2,3.1,6.1,6.1,11.6,8.8c-0.3,3.3-0.5,6.7-0.7,10.1l-0.2,4.1l0,0
								l-0.1,1.1c-0.7,17.7-0.3,37.5,1.3,58.9c0.7,10,2,16.7,3.2,23.3c2.2,12.1,4.3,23.6,2.6,48.6c-0.8,12.4-3.7,24.6-6.7,37.5
								c-5.5,23.7-10.3,47.5-7.9,78.4C55.5,862.6,48,888.5,43.6,897.2z"/>
						</g>
						<g id="rear_layer8">
							<path fill="#FFFFFF" d="M103.7,536.3c-4.9,0-10.1-0.4-15.3-1.1c-3.8-0.5-7.5-1.2-11.2-2.1c-0.1,0-0.2-0.1-0.3-0.1
								c-15.6-3.8-27.4-10.4-29.5-16.6c0.3-2.4,0.6-4.8,1-7.2l0-0.1l0.4-2.8c0.1-0.8,0.4-2.4,0.4-2.4c0.1-0.7,0.4-2.5,0.4-2.5l0.4-2.1
								l0.4-2c0.2-1,0.4-2,0.6-3l0.1-0.6c0.2-0.9,0.4-1.8,0.6-2.7l0.1-0.4c0.4-1.9,0.8-3.7,1.2-5.4l0.5-2.3c1.1-4.9,2-8.7,2.4-12
								c2.1-15.4,2.7-23.3,4.1-40.6l0.3-4.1c0.2-2.4,0.5-5,1-7.7c1.3,0.7,2.7,1.3,4.2,1.9c0.4,0.2,0.8,0.3,1.3,0.5
								c0.4,0.1,0.7,0.3,1.1,0.4c0.6,0.2,1.2,0.4,1.9,0.6l0.6,0.2c0.7,0.2,1.4,0.4,2.1,0.6l0.4,0.1c2.1,0.6,6.7,1.3,11.9,2.2
								c7.1,1.2,15.9,2.6,18.4,3.7c11,4.6,14.2,11.5,14.4,11.8l0.1,0.2c4.7,9,8.8,16.7,25.4,17.6l0.1,0l0.1,0c16.4-1,20.4-8.7,24.9-17.5
								l0.2-0.5c0.2-0.4,3.3-7.4,14.3-12c2.5-1.1,11.3-2.6,18.4-3.8c5.3-0.9,9.8-1.7,11.9-2.3l0.4-0.1c0.7-0.2,1.4-0.4,2.1-0.6l0.6-0.2
								c0.6-0.2,1.3-0.4,1.9-0.6c0.4-0.1,0.8-0.3,1.1-0.4c0.5-0.2,0.9-0.3,1.3-0.5c0.5-0.2,1-0.4,1.5-0.6c1.3-0.4,2.5-0.8,3.6-1.1
								c0.5,2.9,0.9,5.7,1.1,8.4l0.3,4c1.4,17.3,2,25.3,4.1,40.7c0.5,3.4,1.3,7.2,2.4,12.1l1.1,5l0.6,2.8l0.6,3c0,0,0.5,2.5,0.6,3.1
								l0.3,1.5c0.1,0.6,0.2,1.2,0.3,1.8l0.3,1.6c0.1,0.6,0.2,1.3,0.3,1.9l0.3,1.7c0.1,0.7,0.4,2.6,0.4,2.6l0,0.3c0,0,0.2,1.6,0.3,2.1
								l0.1,1c0.2,1.5,0.4,3.1,0.6,4.8c-1.8,6.3-13.7,13.1-29.7,17c-3.7,0.9-7.5,1.6-11.3,2.1c-5.2,0.7-10.3,1.1-15.3,1.1c0,0,0,0,0,0
								c-14.9,0-26.4-3.4-29.5-8.6l-0.2-0.3l-0.5-0.2c-0.4-0.1-0.8-0.3-1.1-0.4l-0.3-0.1c-1.1-0.4-2.2-0.7-3-1.2l-0.3-0.2L143,526
								l-5.3-0.6l-0.3,0.2c-0.8,0.4-1.6,0.8-2.4,1.2c-0.3,0.1-0.7,0.3-1,0.4l-0.3,0.1l-0.4,0.1l-0.2,0.4
								C130.1,532.9,118.5,536.3,103.7,536.3z"/>
							<g opacity="5.000000e-02">
								<path fill="#020202" d="M172.7,529.7c-3.5,0-6.9-0.1-10.4-0.6c-3.4-0.4-6.8-1.1-10-2.3c-0.8-0.3-1.6-0.6-2.4-0.9
									c-0.8-0.3-1.5-0.7-2.3-1.1c-0.7-0.4-1.5-0.8-2.2-1.3c-0.7-0.4-1.3-0.9-1.9-1.4c-0.1-1.1-0.1-2.3-0.2-3.4c-0.1-1.2-0.2-2.5-0.3-3.7
									c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,1.2-0.2,2.5-0.3,3.7c-0.1,1.1-0.1,2.3-0.2,3.4c-0.6,0.5-1.2,1-1.9,1.4c-0.7,0.5-1.4,0.9-2.2,1.3
									c-0.7,0.4-1.5,0.8-2.3,1.1c-0.8,0.4-1.6,0.6-2.4,0.9c-3.2,1.1-6.6,1.8-10,2.3c-3.4,0.4-6.9,0.6-10.4,0.6c-3.5,0-7-0.2-10.4-0.6
									c1.7,0.5,3.4,0.8,5.2,1.1c1.7,0.3,3.5,0.5,5.2,0.6c1.8,0.1,3.5,0.2,5.3,0.2c1.8,0,3.5-0.1,5.3-0.3c3.5-0.4,7-1.1,10.4-2.3
									c0.8-0.3,1.7-0.7,2.5-1.1c0.8-0.4,1.6-0.8,2.4-1.3c0.8-0.5,1.5-1,2.2-1.5c0.6-0.4,1.1-0.9,1.7-1.4c0.5,0.5,1.1,1,1.7,1.4
									c0.7,0.6,1.4,1.1,2.2,1.5c0.8,0.5,1.6,0.9,2.4,1.3c0.8,0.4,1.6,0.8,2.5,1.1c3.4,1.3,6.9,2,10.4,2.3c1.8,0.2,3.5,0.3,5.3,0.3
									c1.8,0,3.5-0.1,5.3-0.2c1.8-0.1,3.5-0.3,5.2-0.6c1.7-0.3,3.5-0.6,5.2-1.1C179.7,529.5,176.2,529.6,172.7,529.7z"/>
							</g>
						</g>
						<g id="rear_layer11">
							<path fill="#FFFFFF" d="M304.5,472.6c-1.2-3.3-2.7-7.7-3.1-9.6c-0.1-0.6-0.4-2.5-0.8-6c-0.6-4.3-1.4-10.8-2.9-20.8l-3.8-26.8
								c-3.3-23.2-11.6-40-15.6-48.1c-1.8-3.7-2.8-5.6-3-7.8l0-0.3l0-0.2c0-0.2-1.4-24.5-1.4-28.6c0-24.6-2.1-38.5-3.4-47.7
								c-0.6-4.1-1.1-7.4-1.2-10.3c-0.1-1.8-0.2-3.2-0.3-4.6c-0.5-5.7-0.7-10.1,0.9-24l0.1-0.2c2.3-1,4.3-2.4,5.9-4
								c0.6-0.6,1.1-1.2,1.6-1.7c0,2.3-0.1,4.5-0.4,6.5c-1.7,14.3-1.4,18.2-1,22.6c0.1,1.5,0.3,3.1,0.3,5.1c0.1,2.5,0.5,5.6,1.1,9.5
								c1.4,9.5,3.5,23.7,3.5,48.8c0,3.9,1.3,28,1.3,28.3c0.1,1,0.8,2.4,2.3,5.5c4.2,8.4,12.8,26,16.2,50.3l3.8,26.6
								c3.4,24.2,3.9,27.3,3.8,28.2c0,0.2,0,0.4,0.1,0.5c0.8,2.5,1.6,4.6,2.3,6.6L304.5,472.6z"/>
						</g>
						<g id="rear_layer21">
							<path fill="#FFFFFF" d="M294.9,476.1c-1.6-7.6-3.1-13.2-4.4-16.8l-0.1-0.2l-0.1-0.3c-9.1-20.3-24.2-56.2-25.9-66.4
								c-3-18.9-7-29.4-10.2-38c-2.1-5.5-3.5-9.4-4.2-14.1c-0.7-5.1-1.9-11.8-3.1-19l0-0.3c-2.7-15.7-9.6-63.6-6.4-80.4
								c3.4-0.1,15.4-0.3,17.8-0.4c3.4-0.2,6.6-0.8,9.4-1.7c-1.5,13.3-1.3,17.7-0.8,23.4c0.1,1.5,0.3,2.8,0.3,4.6
								c0.1,3.1,0.6,6.4,1.2,10.6c1.3,9.2,3.4,23,3.4,47.4c0,4.1,1.3,27.7,1.4,28.7l0,0.3l0,0.4c0.3,2.6,1.3,4.6,3.2,8.6
								c3.9,8,12.1,24.6,15.4,47.4l3.8,26.8c1.4,10,2.3,16.5,2.9,20.8c0.5,3.8,0.7,5.5,0.9,6.2c0.4,2,1.9,6.5,3.2,9.8L294.9,476.1z"/>
						</g>
						<g id="rear_layer10">
							<g>
								<path fill="#FFFFFF" d="M285.4,479.1c-1-5.2-2.5-12.1-4.3-16.5c-0.3-0.6-24.2-53.9-26.6-68.9c-2.9-17.9-6.6-28-9.7-36.2
									c-2.2-5.8-3.9-10.4-4.7-16.1c-0.7-4.8-1.7-10.9-2.8-17.5c-2.6-15.7-5.9-35.1-6.1-47.5c0-0.2,0-0.3-0.1-0.4l0.1-0.6l5.3-34.9l1.5,0
									c-3,17.6,3.8,65.1,6.5,80.8l0,0.3c1.2,7.1,2.4,13.8,3.1,18.9c0.7,4.9,2.2,9,4.3,14.5c3.2,8.5,7.1,19,10.1,37.6
									c1.7,10.4,16.8,46.6,26,67l0.2,0.4c1.4,3.5,2.8,9.1,4.4,16.7L285.4,479.1z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M230.2,278.3c0.4,12.5,3.5,30.9,6,45.9
									c1.1,6.6,2.1,12.7,2.8,17.5c0.8,5.9,2.5,10.5,4.7,16.3c3,8.1,6.8,18.1,9.6,35.9c2.1,12.9,19.5,52.9,25,65.3
									c-5.7-18.4-24.8-75.5-32.6-116.2C238.1,302.9,232,283.4,230.2,278.3z"/>
							</g>
						</g>
						<g id="rear_layer19">
							<path fill="#FFFFFF" d="M174.9,430.4c0.1-3.5,0.1-6,0.1-6c2.9-106.6,22.5-175,25-183.5c0,0,13-0.1,16.9-0.1l8,0l3.6,0l0.5,0l5.4,0
								l-5.3,34.5c-0.5,0.2-0.9,0.6-1,1.2c-0.9,4.5-1.3,5.8-2,7.6c-0.4,1.1-0.8,2.4-1.4,4.6c-0.4,1.3-0.9,3-1.6,5.2l0,0.1
								c-1.5,4.9-3.9,12.3-5.9,20.7c-2,7.8-3.3,15.1-4.1,21.8c-0.6,5.7-0.9,11.1-0.6,15.9c0.2,3.4,0.7,7.6,1.3,12.1
								c1,7.3,2.1,15.7,1.7,22.4l0,0.3l0.1,0.3c0,0,1.7,3.5,3.8,9c1.3,3.5,3.7,10.6,5.5,19.5c-0.1,0-0.3,0.1-0.5,0.1l-0.9,0.3l0,0
								c-0.8,0.3-1.6,0.5-2.4,0.7l-0.1,0l-0.1,0c-0.5,0.2-1,0.4-1.5,0.6c-0.4,0.2-0.8,0.3-1.3,0.5c-0.4,0.1-0.7,0.3-1.1,0.4
								c-0.6,0.2-1.2,0.4-1.8,0.6l-0.6,0.2c-0.6,0.2-1.3,0.4-2,0.6l-0.4,0.1c-2,0.5-6.7,1.4-11.7,2.2c-7.9,1.4-16.1,2.8-18.9,4
								C179.5,427.5,177.1,428.9,174.9,430.4z"/>
						</g>
						<g id="rear_layer24">
							<path fill="#FFFFFF" d="M143.4,455.8c-15.3-0.8-18.7-7.3-23.5-16.4l-0.1-0.2c-0.2-0.5-1.7-3.6-5.7-7c0-2.8-0.1-5.2-0.1-7.3l0,0
								l0.1-1.2c-0.1-2.6-0.2-5.2-0.2-7.8l0-1l0,0c-3.6-99.5-21.5-164-24.7-174.7c12.4,0,25.9,0.1,39.6,0.1l21.3,0l18.3,0
								c10.2,0,20.1,0,29.4-0.1c-3.1,10.4-22.2,78.6-25,183.6c0,0-0.1,3.2-0.2,7.5c-4.3,3.6-5.9,6.9-6.2,7.6l-0.2,0.4
								C161.8,448.4,158.5,454.9,143.4,455.8z"/>
						</g>
						<g id="rear_layer18">
							<path fill="none" d="M70.2,387L70.2,387L70.2,387z"/>
							<path fill="#FFFFFF" d="M64.2,406.5l-0.3,1l0,0c-0.1,0.5-0.2,0.8-0.3,1.2c-0.8,2.8-0.8,2.9-1.7,7.6c1.4,0.7,2.9,1.4,4.6,2.1
								c0.4,0.2,0.8,0.3,1.3,0.5c0.4,0.1,0.7,0.3,1.1,0.4c0.6,0.2,1.2,0.4,1.8,0.6l0.6,0.2c0.6,0.2,1.3,0.4,2,0.6l0.2,0.1l0.2,0
								c2,0.5,6.7,1.3,11.7,2.1c7.9,1.3,16.2,2.7,18.9,3.8c2.8,1.2,5.3,2.5,7.6,4.2c0-2.6-0.1-4.8-0.1-6.7l0-1.2l0.1,0
								c-0.1-2.2-0.1-4.4-0.2-6.5v0l0-0.1C108,314.2,89.3,248.9,86.8,240.7l-9.3,0c-3.1,0-6,0-8.9,0l-2.2,0l-0.3,0l-0.2,0l-0.3,0l-0.3,0
								l-0.4,0l-0.4,0l-0.3,0l-0.3,0l-0.3,0l-0.3,0l-0.8,0l-0.2,0l-0.2,0l-0.2,0c-3.4,0-6.5,0-9.5-0.1l4.9,31.9c0.4,0.2,0.6,0.4,0.8,0.8
								l0.1,0.1l0,0.1l0.4,1.4l0.1,0.3c0.9,3.3,2.8,10.2,3.7,13.2c0.4,1.2,0.9,2.9,1.6,5.1c3.3,10.6,10.2,32.4,10.7,52.6
								c0,2.1,0,4.1-0.1,6c0,0.7-0.1,1.4-0.1,2.2c-0.1,0.9-0.2,1.8-0.3,2.8c-0.2,2.2-0.5,4.5-0.9,7.2c-1,7.3-2,15.6-1.7,22.3
								c0,0.3,0,0.6-0.2,0.8l-1.1-0.4l0,0l1,0.5C70.8,388.3,67.2,396.1,64.2,406.5z"/>
						</g>
						<g id="rear_layer7">
							<g>
								<path fill="#FFFFFF" d="M22.3,481.2c0.2-5.5,0.8-11.9,1.8-19.2c2.4-16.9,7.2-35.4,9.8-45.4c1.1-4.2,1.6-6.2,1.7-7
									c1.7-16.1,1.8-27.3,1.9-36.3c0.1-7,0.1-13,0.9-19.3c0.4-3.8,1.1-7.6,1.8-12.1l0-0.1c1-6,2.1-12.8,3.1-21.3
									c0.6-5.6,1.5-11.4,2.4-17.1l0-0.1c1.4-9.1,5.9-45.8,3.5-62.8l0.9,0l5,32.8c-0.1,0.2-0.1,0.4-0.1,0.6c-0.2,11.7-1.7,24.5-3.2,36.8
									c-0.4,3.6-0.9,7.2-1.3,10.6c-1,8.7-2.1,15.6-3.2,21.7c-0.7,4.5-1.4,8.3-1.8,11.8c-0.7,5.9-0.7,11.8-0.8,18.5
									c-0.1,9.2-0.2,20.6-1.9,37c-0.5,4.5-5,18.8-9.7,33.9c-3.9,12.4-7.9,25.1-9.8,32.9C22.9,478.6,22.6,479.9,22.3,481.2z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M37.6,374.3c0,0,4.6,32,3.7,47.2
									c1.4-5,2.4-8.9,2.6-10.9c1.7-16.5,1.9-27.9,1.9-37.1c0-4.6,0.1-8.8,0.3-12.9c-1.5,3.1-4.2,8.5-5.8,10.3
									C38.7,372.7,37.6,374.3,37.6,374.3z"/>
							</g>
						</g>
						<g id="rear_layer20">
							<path fill="#FFFFFF" d="M4.2,495.3c0.9-4.8,2.1-11.3,3.1-17.5c2.8-17.2,3.2-27.6,3.8-43.5c0.1-2.7,0.2-5.5,0.3-8.6
								c1.2-29.4,2.9-50.2,5.2-63.7c0.2-0.9,0.3-1.6,0.3-2.1l0.3-2.6c0.7-6.3,0.9-7.4,0.3-11.9c-0.3-2.2-0.7-6-1.3-15.1
								c-1.6-25.8,0.7-43.6,2-54.2c0.5-4,0.9-7.1,1-9.7c0.1-1.8,0.2-3.1,0.3-4.5c0.5-5.7,0.7-10.1-0.8-23.3l0.2,0c0.5,0.2,1,0.3,1.5,0.4
								l0.1,0c1.1,0.3,2.2,0.5,3.3,0.7l0.3,0c0.5,0.1,1,0.1,1.5,0.2l0.5,0c0.6,0.1,1.2,0.1,1.9,0.1c0.6,0,1.4,0.1,2.4,0.1
								c2.3,0.1,5.6,0.1,10,0.2l6.6,0.1c2.5,16.6-2.1,53.4-3.5,62.5l0,0.1c-0.9,5.7-1.8,11.6-2.4,17.2c-0.9,8.4-2.1,15.2-3.1,21.2l0,0.1
								c-0.8,4.5-1.4,8.4-1.8,12.2c-0.7,6.4-0.8,12.5-0.9,19.5c-0.1,9-0.2,20.2-1.9,36.2c-0.1,0.6-0.8,3.3-1.6,6.7
								c-2.6,10-7.4,28.6-9.8,45.6c-1.9,13.6-2.1,24.4-1.9,31.4L4.2,495.3z"/>
						</g>
						<g id="rear_layer6">
							<path fill="#FFFFFF" d="M5.6,473.7c0.6-11,0.2-31.4-0.3-47.5c-1.5-44.7,1.3-60.6,2.2-65.9c0.1-0.7,0.2-1.2,0.2-1.5l0.3-2.6
								c0.7-6.2,0.7-6.3,0.3-9.7c-0.3-2.4-0.8-6.3-1.3-15.7c-1.6-26.6,0.7-45,2-56c0.5-3.7,0.8-6.7,0.9-8.8c0.1-2.1,0.2-3.6,0.3-5.1
								c0.4-4.5,0.7-8.3-1-22.7c-0.2-2-0.3-4.1-0.3-6.3c0.7,0.9,1.6,1.9,2.8,2.8c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.3,0.6,0.5
								c1,0.7,2.2,1.3,3.4,1.8c1.7,13.9,1.5,18.4,1,24c-0.1,1.5-0.3,2.9-0.3,4.7c-0.1,2.5-0.5,5.5-0.9,9.4c-1.3,10.8-3.6,28.7-2,54.7
								c0.6,9.3,1,13,1.3,15.3c0.5,4.2,0.4,5-0.3,11.3l-0.3,2.6c0,0.4-0.2,1.1-0.3,2c-2.3,13.5-4.1,34.4-5.3,64c-0.1,3.1-0.2,5.9-0.3,8.6
								C8.3,448.7,7.9,458.7,5.6,473.7z"/>
						</g>
						<g id="rear_layer5">
							<path fill="#FFFFFF" d="M128.8,238.5c-14.3,0-28.3,0-41.2-0.1l-10.1,0c-3.1,0-15.6-0.1-15.6-0.1L50,238.2l-7.6-0.1l-2.1,0
								c-4.3-0.1-7.7-0.1-10-0.2c-1,0-1.8-0.1-2.3-0.1c-0.6,0-1.2-0.1-1.8-0.1l-0.4,0c-0.5-0.1-0.9-0.1-1.4-0.2l-0.3,0
								c-1.1-0.2-2.1-0.4-3.1-0.6l-0.1,0c-0.5-0.1-0.9-0.3-1.3-0.4l-0.2,0c-0.4-0.1-0.9-0.3-1.3-0.5c-1.4-0.6-2.7-1.2-3.9-2
								c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.1-0.3-0.3-0.5-0.4c-2.3-1.8-3.5-3.8-4.1-5.2c1-17.2,8.5-39.7,21-49.4c7.9-6.2,17-6.9,20.6-6.9
								c1.1,0,1.8,0.1,1.9,0.1l0.2,0c0.2,0,0.4,0,0.6-0.1c0.3-0.1,0.7-0.3,1.1-0.5c4.1,0.3,12,1.7,27,6.2c20.1,6,50.5,9.4,61.2,9.6l0.6,0
								l0.6,0c10.6-0.2,41-3.6,61.2-9.6c16.3-4.9,24.2-6,27.6-6.2c0.4,0.2,0.8,0.4,1.2,0.5c0.2,0.1,0.4,0.1,0.6,0.1h0.1l0.1,0
								c0.1,0,0.8-0.1,1.9-0.1c3.5,0,12.7,0.7,20.6,6.9c9,7.1,19.3,25.4,20.6,49c-0.4,1-1.3,2.7-3,4.4c-1.5,1.5-3.4,2.7-5.5,3.7
								c-3,1.3-6.6,2.1-10.7,2.4c-2.7,0.1-9.3,0.3-19.6,0.4c-3.1,0-6.5,0.1-10.2,0.1c-3.6,0-7.5,0.1-11.5,0.1c-3.9,0-7.9,0-12.1,0.1
								l-6.8,0c-9.3,0-19.2,0.1-29.4,0.1l-19.4,0l-6.1,0L128.8,238.5z"/>
						</g>
						<g id="rear_layer4">
							<path fill="#FFFFFF" d="M160.1,112.7c2.2-0.6,4-0.9,4.8-1l3.1-0.4l-0.3-0.2c0.9-0.3,1.7-0.7,2.2-1v16.8c0,0.2,0.2,15.5,14.2,20.8
								l0.2,0.1c5.5,2.1,16.8,7.8,27.7,13.3c6.1,3.1,12,6.1,16.4,8.2c-4.8,0.7-12.3,2.3-24.2,5.9c-12.3,3.7-27.5,6.2-34.4,7.1L160.1,112.7
								z"/>
						</g>
						<g id="rear_layer17">
							<path fill="#FFFFFF" d="M142.7,184.6c-5-0.1-13.8-0.9-22.8-2.1c-0.2,0-0.5-0.1-0.9-0.1l9.7-69.3c3,0.9,5.6,2.1,7.8,3.4
								c2,1.2,3.7,3.4,4.9,4.9c1,1.3,1.5,1.8,2.1,2l0.3,0.1l0.3-0.1c0.6-0.2,1.1-0.7,2.1-2c1.2-1.5,3-3.7,4.9-4.9c1.9-1.2,4.3-2.3,6.9-3.1
								l9.7,69c-9.5,1.3-18.6,2.1-23.9,2.2l-0.5,0L142.7,184.6z"/>
						</g>
						<g id="rear_layer16">
							<path fill="#FFFFFF" d="M116.6,182.2c-6.8-1-22.2-3.5-34.5-7.1c-11.6-3.4-19-5.1-23.8-5.8c4.4-2.1,10.3-5.1,16.5-8.2
								c10.9-5.5,22.2-11.3,27.7-13.3c0,0,0.3-0.1,0.3-0.1l-0.1,0c14.1-5.3,14.3-20.6,14.3-20.8v-16.9c0,0,0.1,0,0.1,0.1
								c0.4,0.3,2.5,1.5,5.8,1.8c0.6,0.1,1.8,0.3,3.4,0.7L116.6,182.2z"/>
						</g>
					</svg>
				</div>
				<Slider {...settings}>
					<div className="shape slider male-front">
						<svg version="1.1" id="front_layer_male_slider" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 357.3 965.6" enableBackground="new 0 0 357.3 965.6" >
								<text textDecoration="underline" fontFamily="PoppinsBold" x="25%" y="120" fill="black" fontSize="15px">Derecha</text>
								<text textDecoration="underline" fontFamily="PoppinsBold" x="75%" y="120" fill="black" fontSize="15px">Izquierda</text>
							<g id="cuerpo_x2F_sombras_x2F_pelo_x2F_pantalon">
								<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M355.3,498.9
									c0,0-1.2-8.4-1.8-21.1c0.6,0-1.5-0.7,0.1-51.3c1.7-50.6-2.2-64.8-2.5-67.6c-1.7-14.6-0.7-3.8,0.7-27.7c2.2-35.8-2.6-56.7-2.9-64.9
									s-1.7-8.5,0.6-27.5c2.3-19-6-49.2-21.4-61.3c-10.9-8.5-23.6-7.2-23.6-7.2c-8.2-3.2-39-19.9-49.3-23.8c0,0-0.2-0.1-0.2-0.1
									c-13.2-5-13.2-19.2-13.2-19.2v-17.9c-10.7,18.6-24.4,19.3-28,19.3c-3.7,0-17.3-0.7-28-19.3v17.9c0,0,0,14.3-13.2,19.2
									c0,0-0.2,0.1-0.2,0.1c-10.2,3.9-41.1,20.6-49.3,23.8c0,0-12.8-1.4-23.7,7.2c-15.4,12.1-23.2,42.3-21,61.3c2.3,19,0.9,19.2,0.6,27.5
									c-0.3,8.3-4.5,22.5-4.6,58.3c0,3.8-1.3,28.1-1.3,28.1c-0.3,2.9-14,23.5-18.6,55.7c-7.1,50.1-7.6,53.7-7.6,55.1
									c-2.8,8.4-5.4,14.7-7.9,17c-7.1,6.5-18,15.6-33.9,28c-1.2,1-2.9,1.4-3.7,1.8c-2.5,1.3,0.5,7.1,5.9,5.4c5.4-1.7,10.1-7,13.5-6.3
									c1.1,0.3,2.2,0.6,3.2,0.8c-0.7,1.4-2.1,7.3-3.4,15.9c-1.4,8.9-2.8,17-4.6,27.6c-1.4,8.5,4.9,8.5,6.3,6.5c0.4-0.6,8.1-33.7,8.9-36.4
									c-0.7,2.7-8.2,36.2-8.4,37.6c-1.4,8.3,5.9,7.2,7.4,3c0.2-0.7,0.5-1.6,0.9-2.7c-1.6,6.7,5.1,8.2,7.5,2.5c0.9-2.2,2.8-7.6,4.4-13.4
									c-0.6,2.4-1.2,4.5-1.5,6.3c-0.6,2.8,5.5,4.6,7.1,0.8c0.8-1.9,9.6-24,12.1-30.3c3-7.6,3.9-9.3,6.5-16.9c3.3-9.3,3.9-18.1,5-23.8
									c0.8-4.3,3-16.9,5.6-23.5c0,0,24.2-53.7,26.7-69.2c4.9-30.5,12.4-38.1,14.4-52.3c2.2-16.2,10.1-48.7,7.7-66.3
									c1,7.3,3.4,9.4,4.6,13.4c2.4,8.5,13.3,37.8,12.1,63.2c-0.4,8.7-3.6,23.6-3,34.6c0,0-9.4,19.2-11,39.6c-1.6,20.3-2.2,28-4.4,44.6
									c-2.2,16.7-15.7,47.5-9.4,132c2.2,29.1,8.2,33,5.7,69.8c-2.1,31.7-19.5,64.9-14.5,118.2c4.9,53.2,6.7,76.7,6.6,95.5h-0.1
									c-0.6,11.9-1.2,20.2-1.3,21.9c0,0-3.9,14.6-2.6,21.1c1.3,6.4-7.7,18.1-15.3,24.5s-1,8.2,2.1,8.9c2.5,0.5,38.7,7.2,41.9-7.3
									c0,0,0.9-2.5,1.4-17.6c0.3-8.7,2.9-9,1.3-16.6c0,0-2.5-9.2-2.5-12.5c0,0,1.5-9,3.9-22.4c0,0,13-60.8,19.1-94.2
									c1.6-8.9,6.9-29.2,7.1-31.3c0,0,6.1-22.1,5-60.2c-0.4-12.7,10.5-25.8,15.4-54.4c5.9-34.4,12.3-83.7,15.9-110.3
									c0.6-4.5,0.9-8.8,1.1-12.6c1.3,0.9,2.6,1.7,3.8,2.1c1.4,0.5,2.6,0.7,3.4,0.8c0.4,0,0.8,0,1,0c0.2,0,0.6,0,1,0
									c0.8-0.1,2-0.3,3.4-0.8c1.2-0.5,2.5-1.2,3.8-2.2c0.2,3.6,0.5,7.7,1,12c0,0.2,0.1,0.4,0.1,0.6c3.6,26.6,10,75.9,15.9,110.3
									c4.9,28.6,15.8,41.7,15.4,54.4c0,0,0,0,0,0c-1.1,36.4,5,60.2,5,60.2c0.3,2.2,5.5,22.5,7.2,31.3c6.1,33.4,19.1,94.2,19.1,94.2
									c2.4,13.3,3.9,22.4,3.9,22.4c0,3.3-2.5,12.5-2.5,12.5c-1.7,7.5,1,7.9,1.3,16.6c0.5,15.1,1.4,17.6,1.4,17.6
									c3.2,14.5,39.4,7.8,41.9,7.3c3.1-0.6,9.6-2.5,2.1-8.9c-7.5-6.4-16.6-18.1-15.3-24.5s-2.6-21.1-2.6-21.1c-0.1-1.7-0.7-10.1-1.3-21.9
									h-0.1c-0.1-18.8,1.7-42.3,6.6-95.5c5-53.3-12.4-86.5-14.5-118.2c-2.5-36.8,3.6-40.8,5.7-69.8c6.3-84.4-7.2-115.5-9.3-132
									c-2.2-16.5-2.8-24.2-4.4-44.6c-1.6-20.3-11-39.6-11-39.6c0.6-11-2.6-25.9-3-34.6c-1.2-25.4,9.5-54.7,12.1-63.2
									c1.6-5.2,3.2-10,3.7-14.2l0.2-1.5c0.2,15.2,3,33,4.7,48.4c1.7,15.1,4,25.1,5,33.5c1.6,13.8-0.3,26.9,2.7,55.5
									c0.9,8.6,15.5,50,19.6,66.9c1.9,7.8,2.4,14,3.1,16.6c2.3,8.9-8.4,32.3-6.4,39s1.5,27,1.8,32.6s7,2.8,7,2.8
									c0.3,3.6-9.3,18.1-8.7,21.5c0.6,3.3,4.9,3.9,6.1,0c1.2-3.9,14.8-23.1,16.8-27c2-3.9,1.5-11.4,3.8-17.6
									C358.2,539.6,355.3,498.9,355.3,498.9z"/>
								<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M252.4,34.2
									c-7.5-28.8-35.7-28.4-38.6-28.3c-2.9-0.1-31.1-0.5-38.6,28.3c-2,7.6-2.5,17.2-0.8,29.4c2.1,14.9,3.5,28.2,7.2,37.1
									c11,27,28.1,27.9,32.2,27.9c4.2-0.1,21.2-0.9,32.2-27.9c3.6-8.9,5-22.1,7.2-37.1C254.9,51.4,254.4,41.8,252.4,34.2"/>
								<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M174.7,65.5
									c-5,0.5-18.6,4,4.7,28.3C177.4,86,176.2,76.3,174.7,65.5z"/>
								<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M252.9,65.5
									c5,0.5,18.6,4-4.7,28.3C250.2,86,251.4,76.3,252.9,65.5z"/>
								
									<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="39.6" y1="526.9" x2="31" y2="561.6"/>
								
									<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="49.1" y1="529.2" x2="42.9" y2="550.9"/>
								<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M337.9,568.9
									c0,0,3.3-29.2,2.8-30.4"/>
								<path fill="#DDC7BF" d="M351.6,540.1c0,0-0.2,2.3-0.6,5.7c-0.2,1.7-0.4,3.7-0.7,5.9c-0.1,1.1-0.3,2.2-0.4,3.3
									c-0.2,1.1-0.3,2.3-0.5,3.4c-0.2,1.1-0.4,2.3-0.7,3.4c-0.3,1.2-0.7,2.1-1,3.2c-0.6,2-1.1,4-1.6,5.6c-0.5,1.7-0.8,3.1-1.2,4
									c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1,0.3-0.2,0.4-0.2,0.4c-0.2,0.4-0.8,1.1-1.3,0.9s-0.3,0.6-0.4,0.5
									c-0.2-0.4,0.3-2.1,0.3-2.1l0.1-0.2c0,0,0-0.1,0.1-0.3c0-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.1-0.4,0.2-0.7c0.4-0.9,0.6-2.2,1.2-3.9
									c0.5-1.6,1.1-3.6,1.8-5.6c0.3-1,0.8-2.1,1.1-3.1c0.4-1,0.6-2.1,0.8-3.3c0.3-1.1,0.5-2.2,0.7-3.3c0.2-1.1,0.4-2.2,0.6-3.3
									c0.4-2.1,0.7-4.1,0.9-5.8C351.3,542.4,351.6,540.1,351.6,540.1"/>
								<path fill="#DDC7BF" d="M23.7,509.8c0,0,1.6,0,3.9,0.6c1.1,0.3,2.5,0.5,3.9,0.6c0.7,0.1,1.5,0,2.2,0c0.8-0.1,1.5-0.1,2.3-0.2
									c0.8-0.1,1.5-0.3,2.3-0.5c0.4-0.1,0.7-0.2,1.1-0.3c0.3-0.2,0.7-0.3,1-0.4c0.7-0.3,1.3-0.6,1.9-1c0.3-0.2,0.6-0.3,0.8-0.5
									c0.2-0.2,0.5-0.4,0.7-0.6c0.4-0.4,0.9-0.7,1.2-1c0.3-0.4,0.6-0.7,0.9-0.9c0.5-0.5,0.7-0.8,0.7-0.8s-0.3,0.3-0.7,0.8
									c-0.2,0.3-0.5,0.6-0.8,1c-0.3,0.4-0.7,0.7-1.2,1.1c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.5,0.4-0.8,0.6c-0.6,0.4-1.2,0.8-1.8,1.1
									c-0.3,0.2-0.7,0.3-1,0.5c-0.4,0.2-0.7,0.3-1.1,0.4c-0.7,0.3-1.5,0.5-2.3,0.7c-0.8,0.2-1.6,0.3-2.4,0.4c-0.8,0-1.6,0.1-2.3,0.1
									c-1.5,0-2.9-0.2-4.1-0.3c-2.4-0.4-4-1-4-1l0,0c-0.4-0.2-0.7-0.7-0.5-1.1C22.9,509.5,23.2,509.6,23.7,509.8
									C23.6,509.8,23.5,510,23.7,509.8"/>
								<path fill="#EFD3D3" d="M241.6,125.7V109c-10.7,18.6-24.4,19.3-28,19.3c-3.7,0-17.3-0.7-28-19.3v17.9c0,0,0,5.1-2.8,10.2
									C193.1,139.6,220.6,143.6,241.6,125.7"/>
								<path fill="#EFD3D3" d="M118.4,341.8c2.2-16.2,8-46.7,8.3-64.5c0.2,7.3,0,0,0,0s-6.4,17.9-15.5,65.7
									c-8.5,44.5-30.6,108.5-33.8,120.3c0,0,24.2-53.7,26.7-69.2C108.9,363.7,116.5,356,118.4,341.8"/>
								<path fill="#EFD3D3" d="M306.4,332.7c1.4,9.2,2.8,16.3,3.5,22.5c1.6,13.8-0.3,27,2.7,55.5c0.2,2.1,1.5,7.3,3.4,14
									c-2.6-19.9,4.6-58.4,4.6-58.4C318.6,355.5,306.4,332.7,306.4,332.7"/>
								<path fill="#EFD3D3" d="M130.2,166.6c-3.2,1.6-5.8,2.8-7.5,3.4l37.6,12.1C160.3,182.2,137.9,170.4,130.2,166.6"/>
								<path fill="#EFD3D3" d="M296.8,166.6c3.2,1.6,5.8,2.8,7.5,3.4l-37.6,12.1C266.7,182.2,289.1,170.4,296.8,166.6"/>
								<path fill="#EFD3D3" d="M300.1,252.7c-0.9,1.9-2,4-3.1,6.2c-1.2,2.2-2.5,4.4-4,6.7c-1.5,2.2-3.3,4.5-5.4,6.5
									c-0.8,0.7-1.7,1.5-2.6,2.1c2.9,1,10.7,4.4,13.4,10.5c0,0,0,0,0,0c0.3-1.1,1.2-3.4,1.4-6.6c0.5-7.2,0.6-7.7,1.4-13.9
									c0.6-4.3,2.4-16.8,3-21.5C303.4,245.1,302,248.6,300.1,252.7z"/>
								<path fill="#DDC7BF" d="M305.4,239.3c0,0-0.1,0-0.1,0c0,0-0.6,1.3-1.7,3.5c-0.6,1.1-1.2,2.5-2,4c-0.8,1.5-1.7,3.3-2.7,5.2
									c-1,1.9-2.1,3.9-3.3,6c-1.2,2.1-2.6,4.3-4.1,6.5c-1.5,2.2-3.1,4.4-5,6.3c-1,1-2,1.8-3.2,2.5c-0.3,0.1-0.6,0.3-0.9,0.4l-1,0.4
									l-1,0.3l-1,0.2c-2.7,0.6-5.5,0.8-8.3,0.8c-2.8,0.1-5.5-0.1-8.2-0.3c-5.3-0.4-10.3-1-14.5-1.6c-8.5-1.3-14.1-2.5-14.1-2.5
									c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,5.5,1.4,14,3c4.2,0.8,9.2,1.5,14.5,2c2.7,0.2,5.5,0.4,8.3,0.4c2.8,0,5.7-0.1,8.6-0.7
									l1.1-0.2l1-0.3l1-0.3c0.4-0.1,0.7-0.3,1.1-0.5c0.3-0.2,0.6-0.4,1-0.6c1-0.6,1.8-1.3,2.6-2.1c2.1-2,3.8-4.3,5.4-6.5
									c1.5-2.3,2.9-4.5,4-6.7c1.2-2.2,2.2-4.3,3.1-6.2c1.9-4.2,3.3-7.6,4.2-10c0.8-2.1,1.2-3.3,1.2-3.3
									C305.4,239.4,305.4,239.3,305.4,239.3z"/>
								<path fill="#EFD3D3" d="M134.1,265.6c-1.5-2.3-2.9-4.5-4-6.7c-1.2-2.2-2.2-4.3-3.1-6.2c-1.7-3.7-3-6.9-3.9-9.2
									c-0.7-1.6-1.3-2.8-1.3-2.8s2.5,15.8,3.3,21.1c0.8,6.2,0.9,6.6,1.4,13.9c0.2,3.2,1.1,5.5,1.4,6.6c0,0,0,0,0,0
									c2.6-5.9,9.1-8.9,11.8-9.9c-0.1-0.1-0.2-0.2-0.3-0.3C137.3,270.1,135.6,267.9,134.1,265.6z"/>
								<path fill="#DDC7BF" d="M192.7,271.2c0,0-5.6,1.3-14.1,2.5c-4.2,0.6-9.2,1.3-14.5,1.6c-2.7,0.2-5.4,0.3-8.2,0.3
									c-2.8,0-5.7-0.2-8.3-0.8l-1-0.2l-1-0.4l-1-0.4c-0.2-0.1-0.6-0.3-0.9-0.4c-1.2-0.7-2.2-1.5-3.2-2.5c-1.9-1.9-3.6-4.1-5-6.3
									c-1.5-2.2-2.8-4.4-4.1-6.5c-1.2-2.1-2.3-4.1-3.3-6c-1-1.9-1.9-3.6-2.7-5.2c-0.8-1.6-1.4-2.9-2-4c-1.1-2.3-1.7-3.5-1.7-3.5
									c0,0-0.1-0.1-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0,0.5,1.5,1.5,4.1c0.9,2.3,2.2,5.5,3.9,9.2c0.9,1.9,2,4,3.1,6.2c1.2,2.2,2.5,4.4,4,6.7
									c1.5,2.2,3.3,4.5,5.4,6.5c0.1,0.1,0.2,0.2,0.3,0.3c1,0.9,2,1.7,3.3,2.4c0.4,0.2,0.6,0.3,1.1,0.5l1,0.3l1,0.3l1.1,0.2
									c2.9,0.6,5.8,0.7,8.6,0.7c2.8,0,5.6-0.2,8.3-0.4c5.4-0.5,10.3-1.3,14.5-2c8.5-1.5,14-3,14-3C192.8,271.4,192.8,271.3,192.7,271.2
									C192.8,271.2,192.7,271.2,192.7,271.2z"/>
								<line fill="none" x1="160.3" y1="182.2" x2="122.8" y2="170.1"/>
								<path fill="#DDC7BF" d="M161.5,182.4l-37.8-11.3c-0.4-0.1-0.7-0.6-0.6-1c0.1-0.4,0.6-0.7,1-0.6c0,0,0,0,0,0L161.5,182.4z"/>
								<g>
									<path fill="#DDC7BF" d="M121.8,240.8c0,0,1.5,8.7,2.8,17.4c0.3,2.2,0.7,4.4,1,6.4c0.3,2,0.6,4,0.8,5.6c0.1,0.8,0.2,1.6,0.3,2.2
										c0.1,0.7,0.1,1.3,0.2,1.8c0.1,1,0.1,1.5,0.1,1.5c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5c0,0,0,0,0,0l0-0.1c0,0,0-0.5-0.1-1.5
										c0-0.5,0-1-0.1-1.7c-0.1-0.7-0.1-1.4-0.2-2.2c-0.2-1.6-0.4-3.6-0.6-5.6c-0.3-2.1-0.5-4.2-0.8-6.4
										C123,249.5,121.8,240.8,121.8,240.8z"/>
								</g>
								<line fill="none" x1="304.3" y1="170.1" x2="266.7" y2="182.2"/>
								<path fill="#DDC7BF" d="M303.3,171.3l-37.8,11.3l37.3-12.8c0.4-0.1,0.9,0.1,1.1,0.5C304,170.7,303.7,171.1,303.3,171.3
									C303.3,171.3,303.3,171.3,303.3,171.3"/>
								<g>
									<path fill="#DDC7BF" d="M304.5,242.3c0,0-1,7.9-2,15.8c-0.5,4-0.9,7.9-1.2,10.9c-0.1,1.5-0.2,2.7-0.3,3.6c0,0.9-0.1,1.3-0.1,1.3
										l0,0.1c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5c0,0,0,0,0,0c0,0,0-0.5,0.1-1.4c0.1-0.9,0.2-2.1,0.4-3.6c0.4-3,0.9-6.9,1.5-10.8
										C303.2,250.1,304.5,242.3,304.5,242.3z"/>
								</g>
								<path fill="#DDC7BF" d="M90.3,353.3c-5.9,0.9-12.4-1-16.6-2.6c0,1-0.1,1.7-0.1,1.7c0,0.5-0.4,1.3-1.1,2.6
									C83.2,356.8,90.3,353.3,90.3,353.3"/>
								<path fill="#DDC7BF" d="M314.4,360c2.2,0,5.5,2.9,18.3,2.1c0,0-8.2,4.6-18.3,5L314.4,360L314.4,360z"/>
								<path fill="#DDC7BF" d="M140.8,386c0,0,0.1,0,0.2-0.1c0.2,0,0.6-0.1,1,0c0.3,0.1,0.7,0.2,1.2,0.3c0.4,0.2,0.8,0.5,1.3,0.7
									c0.5,0.2,0.9,0.7,1.4,1.1c0.4,0.4,1,0.8,1.4,1.3c0.8,1,1.8,2,2.6,3.1c0.8,1.1,1.6,2.2,2.3,3.3c0.4,0.5,0.7,1.1,1,1.6
									c0.3,0.5,0.6,1,0.8,1.4c1,1.7,1.7,2.9,1.7,2.9s-0.8-1.1-2-2.8c-0.3-0.4-0.6-0.9-0.9-1.3c-0.3-0.5-0.7-1-1.1-1.5c-0.8-1-1.7-2-2.5-3
									c-0.9-1-1.9-1.9-2.8-2.8c-0.4-0.5-1-0.7-1.4-1.1c-0.5-0.3-0.9-0.7-1.3-0.9c-0.4-0.2-0.8-0.4-1.1-0.5c-0.3-0.1-0.6-0.1-0.9-0.2
									c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2,0.1-0.2,0.1l-0.1,0c-0.4,0.1-0.9-0.1-1-0.5C140.1,386.6,140.3,386.2,140.8,386
									C140.7,386,140.8,386,140.8,386"/>
								<path fill="#DDC7BF" d="M286.6,387.6c0,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.4,0c-0.2,0-0.5,0.1-0.9,0.2c-0.3,0.2-0.7,0.3-1.1,0.5
									c-0.4,0.2-0.8,0.6-1.3,0.9c-0.4,0.4-1,0.6-1.4,1.1c-0.9,0.9-1.9,1.7-2.8,2.8c-0.9,1-1.7,2.1-2.5,3c-0.4,0.5-0.7,1-1.1,1.5
									c-0.3,0.5-0.6,0.9-0.9,1.3c-1.2,1.7-2,2.8-2,2.8s0.7-1.2,1.7-2.9c0.3-0.4,0.5-0.9,0.8-1.4c0.3-0.5,0.6-1,1-1.6
									c0.7-1,1.5-2.2,2.3-3.3c0.8-1.1,1.8-2.1,2.6-3.1c0.4-0.5,1-0.9,1.4-1.3c0.5-0.4,0.9-0.8,1.4-1.1c0.5-0.3,0.9-0.5,1.3-0.7
									c0.5-0.1,0.9-0.2,1.2-0.3c0.4,0,0.8,0,1,0c0.2,0,0.2,0.1,0.2,0.1c0.4,0.1,0.7,0.5,0.6,1c-0.1,0.4-0.5,0.7-1,0.6
									C286.8,387.6,286.7,387.6,286.6,387.6L286.6,387.6z"/>
								<path fill="#4E5663" d="M256.5,29.5c-2-8.3-13-29.3-42.7-29.5c-29.7,0.2-40.7,21.2-42.7,29.5c-1.7,7-2.7,17.7-1.7,23.8
									c2.8,17.8,5.5,26.4,5.5,26.4l2.6,0.7c0,0-3-18.2-3.1-23.7c-0.9-41.9,28.4-29.5,39.5-28.1c11.1-1.4,40.4-13.7,39.5,28.1
									c-0.1,5.5-3.1,23.7-3.1,23.7l2.6-0.7c0,0,2.7-8.6,5.5-26.4C259.2,47.2,258.2,36.5,256.5,29.5z"/>
								<path fill="#EFD3D3" d="M222.5,529.9l-0.4-6.2c0,0,5.1-3.4,10.1-11.7c0,0-8.4,31.8-5.1,54.6L222.5,529.9z"/>
								<path fill="#EFD3D3" d="M205.2,529.9l0.4-6.2c0,0-7.9-6-10.1-11.6c0,0,8.4,31.7,5.1,54.5L205.2,529.9z"/>
								<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M355.3,498.9
									c0,0-1.2-8.4-1.8-21.1c0.6,0-1.5-0.7,0.1-51.3c1.7-50.6-2.2-64.8-2.5-67.6c-1.7-14.6-0.7-3.8,0.7-27.7c2.2-35.8-2.6-56.7-2.9-64.9
									s-1.7-8.5,0.6-27.5c2.3-19-6-49.2-21.4-61.3c-10.9-8.5-23.6-7.2-23.6-7.2c-8.2-3.2-39-19.9-49.3-23.8c0,0-0.2-0.1-0.2-0.1
									c-13.2-5-13.2-19.2-13.2-19.2v-17.9c-10.7,18.6-24.4,19.3-28,19.3c-3.7,0-17.3-0.7-28-19.3v17.9c0,0,0,14.3-13.2,19.2
									c0,0-0.2,0.1-0.2,0.1c-10.2,3.9-41.1,20.6-49.3,23.8c0,0-12.8-1.4-23.7,7.2c-15.4,12.1-23.2,42.3-21,61.3c2.3,19,0.9,19.2,0.6,27.5
									c-0.3,8.3-4.5,22.5-4.6,58.3c0,3.8-1.3,28.1-1.3,28.1c-0.3,2.9-14,23.5-18.6,55.7c-7.1,50.1-7.6,53.7-7.6,55.1
									c-2.8,8.4-5.4,14.7-7.9,17c-7.1,6.5-18,15.6-33.9,28c-1.2,1-2.9,1.4-3.7,1.8c-2.5,1.3,0.5,7.1,5.9,5.4c5.4-1.7,10.1-7,13.5-6.3
									c1.1,0.3,2.2,0.6,3.2,0.8c-0.7,1.4-2.1,7.3-3.4,15.9c-1.4,8.9-2.8,17-4.6,27.6c-1.4,8.5,4.9,8.5,6.3,6.5c0.4-0.6,8.1-33.7,8.9-36.4
									c-0.7,2.7-8.2,36.2-8.4,37.6c-1.4,8.3,5.9,7.2,7.4,3c0.2-0.7,0.5-1.6,0.9-2.7c-1.6,6.7,5.1,8.2,7.5,2.5c0.9-2.2,2.8-7.6,4.4-13.4
									c-0.6,2.4-1.2,4.5-1.5,6.3c-0.6,2.8,5.5,4.6,7.1,0.8c0.8-1.9,9.6-24,12.1-30.3c3-7.6,3.9-9.3,6.5-16.9c3.3-9.3,3.9-18.1,5-23.8
									c0.8-4.3,3-16.9,5.6-23.5c0,0,24.2-53.7,26.7-69.2c4.9-30.5,12.4-38.1,14.4-52.3c2.2-16.2,10.1-48.7,7.7-66.3
									c1,7.3,3.4,9.4,4.6,13.4c2.4,8.5,13.3,37.8,12.1,63.2c-0.4,8.7-3.6,23.6-3,34.6c0,0-9.4,19.2-11,39.6c-1.6,20.3-2.2,28-4.4,44.6
									c-2.2,16.7-15.7,47.5-9.4,132c2.2,29.1,8.2,33,5.7,69.8c-2.1,31.7-19.5,64.9-14.5,118.2c4.9,53.2,6.7,76.7,6.6,95.5h-0.1
									c-0.6,11.9-1.2,20.2-1.3,21.9c0,0-3.9,14.6-2.6,21.1c1.3,6.4-7.7,18.1-15.3,24.5s-1,8.2,2.1,8.9c2.5,0.5,38.7,7.2,41.9-7.3
									c0,0,0.9-2.5,1.4-17.6c0.3-8.7,2.9-9,1.3-16.6c0,0-2.5-9.2-2.5-12.5c0,0,1.5-9,3.9-22.4c0,0,13-60.8,19.1-94.2
									c1.6-8.9,6.9-29.2,7.1-31.3c0,0,6.1-22.1,5-60.2c-0.4-12.7,10.5-25.8,15.4-54.4c5.9-34.4,12.3-83.7,15.9-110.3
									c0.6-4.5,0.9-8.8,1.1-12.6c1.3,0.9,2.6,1.7,3.8,2.1c1.4,0.5,2.6,0.7,3.4,0.8c0.4,0,0.8,0,1,0c0.2,0,0.6,0,1,0
									c0.8-0.1,2-0.3,3.4-0.8c1.2-0.5,2.5-1.2,3.8-2.2c0.2,3.6,0.5,7.7,1,12c0,0.2,0.1,0.4,0.1,0.6c3.6,26.6,10,75.9,15.9,110.3
									c4.9,28.6,15.8,41.7,15.4,54.4c0,0,0,0,0,0c-1.1,36.4,5,60.2,5,60.2c0.3,2.2,5.5,22.5,7.2,31.3c6.1,33.4,19.1,94.2,19.1,94.2
									c2.4,13.3,3.9,22.4,3.9,22.4c0,3.3-2.5,12.5-2.5,12.5c-1.7,7.5,1,7.9,1.3,16.6c0.5,15.1,1.4,17.6,1.4,17.6
									c3.2,14.5,39.4,7.8,41.9,7.3c3.1-0.6,9.6-2.5,2.1-8.9c-7.5-6.4-16.6-18.1-15.3-24.5s-2.6-21.1-2.6-21.1c-0.1-1.7-0.7-10.1-1.3-21.9
									h-0.1c-0.1-18.8,1.7-42.3,6.6-95.5c5-53.3-12.4-86.5-14.5-118.2c-2.5-36.8,3.6-40.8,5.7-69.8c6.3-84.4-7.2-115.5-9.3-132
									c-2.2-16.5-2.8-24.2-4.4-44.6c-1.6-20.3-11-39.6-11-39.6c0.6-11-2.6-25.9-3-34.6c-1.2-25.4,9.5-54.7,12.1-63.2
									c1.6-5.2,3.2-10,3.7-14.2l0.2-1.5c0.2,15.2,3,33,4.7,48.4c1.7,15.1,4,25.1,5,33.5c1.6,13.8-0.3,26.9,2.7,55.5
									c0.9,8.6,15.5,50,19.6,66.9c1.9,7.8,2.4,14,3.1,16.6c2.3,8.9-8.4,32.3-6.4,39s1.5,27,1.8,32.6s7,2.8,7,2.8
									c0.3,3.6-9.3,18.1-8.7,21.5c0.6,3.3,4.9,3.9,6.1,0c1.2-3.9,14.8-23.1,16.8-27c2-3.9,1.5-11.4,3.8-17.6
									C358.2,539.6,355.3,498.9,355.3,498.9z"/>
								<path fill="#DDC7BF" d="M233.3,509.2c-1.5,2.7-3.7,6.3-6.3,9.4c-1.3,1.6-2.8,3-4.3,4.2c-1.5,1.2-3,2-4.4,2.6s-2.6,0.7-3.4,0.8
									c-0.4,0-0.8,0-1,0c-0.2,0-0.6,0-1,0c-0.8-0.1-2-0.3-3.4-0.8c-1.4-0.5-2.9-1.4-4.4-2.6c-1.5-1.2-3-2.6-4.3-4.2
									c-2.7-3.1-4.9-6.7-6.3-9.4c-1.5-2.7-2.3-4.6-2.3-4.6s0.7,2,2.1,4.8c1.3,2.8,3.4,6.5,5.9,9.8c1.3,1.7,2.7,3.2,4.2,4.5
									c1.5,1.3,3.1,2.3,4.6,3c1.5,0.7,2.8,1,3.8,1.1c0.3,0,0.6,0.1,0.8,0.1v0c0,0,0.1,0,0.3,0c0.2,0,0.3,0,0.3,0v0c0.2,0,0.5,0,0.8-0.1
									c1-0.1,2.3-0.4,3.8-1.1c1.5-0.7,3.1-1.7,4.6-3c1.5-1.3,2.9-2.8,4.2-4.5c2.6-3.3,4.6-7,5.9-9.8c1.4-2.8,2.1-4.8,2.1-4.8
									S234.8,506.5,233.3,509.2z"/>
							</g>
							<g id="front_layer13">
								<path fill="#FFFFFF" d="M313.4,896.2c-4.4-8.6-12-34.4-6.3-106.3c2.4-30.9-2.3-54.8-7.9-78.4c-3-12.9-5.9-25-6.7-37.5
									c-1.7-25.1,0.4-36.5,2.6-48.6c1.2-6.5,2.4-13.3,3.2-23.3c1.6-21.3,2-41.1,1.3-58.9l0-1.1l-0.1,0l-0.2-4.1
									c-0.5-8.9-1.3-17.1-2.5-25.1c1.6,0.1,3.1,0.2,4.6,0.4c0.8,0.1,1.7,0.2,2.5,0.3c1,0.1,2,0.3,3,0.4c0.8,0.1,1.7,0.3,2.7,0.5
									c3.3,25.2,3.7,54.2,1.1,88.5c-0.8,10.6-2.1,17.7-3.3,24.6c-2.1,11.8-4,22-2.4,45.5c0.8,11.4,3.5,23.1,6.4,35.4
									c5.3,22.7,11.3,48.4,8.2,82.6c-4.8,51.5-6.8,76.2-6.6,95.7c0,0.2,0,0.3,0.1,0.5C313.1,890.2,313.2,893.2,313.4,896.2z"/>
							</g>
							<g id="front_layer21">
								<path fill="#FFFFFF" d="M292,907.3c2-7.7,4.5-36.5-13.3-140.2c-0.1-0.4-7.8-33-6.8-65.8c0.3-9.9-3.4-17.8-7.3-26.3
									c-4-8.7-6.7-18-8.9-30.2c-2.7-15.7-7.7-46.3-11-75c-0.8-6.9-2.5-20.3-4-32.1c-1.3-9.9-2.4-18.4-2.6-20.6c9.7-1.7,27.9-4.4,46.9-4.4
									c3.2,0,6.4,0.1,9.4,0.2l0.1,0.4c1.1,8,1.9,16.2,2.4,25l0.4,6.2h0c0.7,17.4,0.2,36.7-1.3,57.6c-0.7,9.9-2,16.5-3.1,23
									c-2.2,12.3-4.3,23.9-2.6,49.2c0.9,12.6,3.7,24.9,6.8,37.9c5.5,23.5,10.2,47.1,7.8,77.7c-2.9,37.3-2.7,66.7,0.8,87.4
									c2.3,13.6,5.4,20,7.3,22.8L292,907.3z"/>
							</g>
							<g id="front_layer14">
								<g>
									<path fill="#FFFFFF" d="M290.4,904c-0.7-4.2-1.9-10.7-3.1-17.7c-0.5-2.6-13.2-61.9-19.1-94.2c-1-5.2-3.1-14.3-4.9-21.5l0-0.2
										c-1.1-4.5-2.1-8.8-2.2-9.5l0-0.1l0-0.1c-0.3-1-6-23.3-4.9-59.8c0.2-6.5-2.3-12.9-5.5-21c-3.3-8.5-7.4-19.1-10-33.8
										c-4.9-28.4-10.1-66.7-13.8-94.7c-0.8-5.7-1.5-11-2.1-15.6c-0.5-3.9-0.8-7.6-1-10.8c0.1-0.1,0.2-0.2,0.3-0.3c1.5-1.3,3-2.9,4.4-4.7
										c0.4-0.5,0.8-1,1.2-1.6l0,0c0.5-0.1,1.1-0.2,1.7-0.3c1.4-0.3,2.9-0.6,4.5-0.8c0.3,2.7,1.4,10.9,2.6,20.3c1.5,11.8,3.2,25.2,4,32.1
										c3.3,28.7,8.3,59.4,11,75.1c2.1,12.4,4.9,21.9,9,30.8c3.8,8.2,7.4,16,7.1,25.3c-1,33.1,6.8,66,6.9,66.4
										C292.9,862.9,292,894.2,290.4,904z"/>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M230.9,517.2c-0.6,0.1-1.2,0.3-1.8,0.4
										c0-0.1-0.1,0-0.1,0c-0.5,0.7-0.9,1.4-1.4,2c-1.3,1.7-2.7,3.2-4.2,4.5c-0.3,0.2-0.5,0.4-0.8,0.6c0.2,3.5,0.5,7.4,1,11.4
										c0.6,4.6,1.3,9.8,2.1,15.6c0.2,1.4,0.4,2.9,0.6,4.3C226.2,541.3,229.2,525.3,230.9,517.2z"/>
								</g>
							</g>
							<g id="front_layer18">
								<g>
									<path fill="#FFFFFF" d="M137.2,904.2c-1.6-9.8-2.4-41.1,13.9-136.8c0.1-0.3,7.8-33.2,6.8-66.3c-0.3-9.3,3.3-17.1,7.1-25.3
										c4.1-8.9,6.9-18.4,9-30.8c2.7-15.7,7.8-46.4,11-75.1c0.8-6.9,2.5-20.2,4-32c1.2-9.4,2.3-17.7,2.6-20.4c1.6,0.3,3.1,0.6,4.5,0.9
										c0.6,0.1,1.2,0.2,1.7,0.4c0.4,0.6,0.8,1.1,1.2,1.6c1.4,1.8,2.9,3.4,4.4,4.7c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,3.2-0.5,6.9-1,10.8
										c-0.6,4.6-1.3,9.8-2.1,15.6c-3.8,28-8.9,66.3-13.8,94.7c-2.5,14.7-6.7,25.3-10,33.8c-3.2,8.1-5.6,14.5-5.5,21
										c1.1,36.5-4.7,58.9-4.9,59.8l0,0.1l0,0.1c-0.1,0.8-1.1,5.1-2.2,9.6c-1.8,7.4-4,16.4-4.9,21.6c-5.9,32.3-18.5,91.6-19.1,94.1
										C139.1,893.4,138,899.9,137.2,904.2z"/>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M200,519.7c-0.5-0.6-1-1.3-1.4-2l0,0
										c-0.6-0.1-1.2-0.3-1.9-0.4c1.8,8,4.8,23.9,4.6,38.7c0.2-1.4,0.4-2.9,0.6-4.2c0.8-5.7,1.5-11,2.1-15.6c0.6-4.1,0.8-7.9,1-11.4
										c-0.3-0.2-0.5-0.4-0.8-0.6C202.7,522.9,201.3,521.3,200,519.7z"/>
								</g>
							</g>
							<g id="front_layer22">
								<path fill="#FFFFFF" d="M114.7,899.7c1.9-2.8,5.1-9.2,7.3-22.8c3.4-20.6,3.7-50,0.8-87.4c-2.4-30.6,2.3-54.2,7.8-77.7
									c3-13,5.9-25.2,6.8-37.9c1.7-25.4-0.4-36.9-2.6-49.2c-1.2-6.5-2.4-13.2-3.1-23c-1.6-20.9-2-40.4-1.3-57.8l0.1-0.7l0.3-5.2
									c0.5-8.8,1.3-17,2.4-25l0.1-0.4c2.9-0.2,5.9-0.2,8.9-0.2c18.6,0,37.3,2.8,47.4,4.6c-0.3,2.5-1.3,10.9-2.6,20.5
									c-1.5,11.8-3.2,25.2-4,32c-3.3,28.7-8.3,59.3-11,75c-2.1,12.2-4.8,21.6-8.9,30.2c-3.9,8.5-7.6,16.4-7.3,26.3
									c1,32.8-6.7,65.5-6.8,65.8c-11.6,67.9-14.4,102.9-14.7,120.4c-0.2,11.3,0.6,17,1.3,19.9L114.7,899.7z"/>
							</g>
							<g id="front_layer12">
								<path fill="#FFFFFF" d="M114.2,896.2c0.2-2.9,0.3-5.9,0.5-8.9c0-0.2,0.1-0.3,0.1-0.5c0.1-19.5-1.8-44.2-6.6-95.7
									c-3.2-34.1,2.8-59.9,8.2-82.6c2.9-12.4,5.6-24,6.4-35.4c1.6-23.5-0.3-33.7-2.4-45.5c-1.2-6.9-2.5-14-3.3-24.6
									c-2.6-34.4-2.2-63.3,1.1-88.5c1-0.2,1.9-0.3,2.7-0.5c1-0.2,2-0.3,3-0.4c0.8-0.1,1.7-0.2,2.5-0.3c1.4-0.1,2.9-0.3,4.6-0.4
									c-1.1,8-1.9,16.2-2.5,25.1l-0.2,4.1l0,0l-0.1,1.1c-0.7,17.7-0.3,37.5,1.3,58.9c0.7,10,2,16.7,3.2,23.3c2.2,12.1,4.3,23.6,2.6,48.6
									c-0.8,12.4-3.7,24.6-6.7,37.5c-5.5,23.7-10.3,47.5-7.9,78.4C126.2,861.8,118.6,887.6,114.2,896.2z"/>
							</g>
							<g id="front_layer16">
								<path fill="#FFFFFF" d="M213.8,525.6c-0.2,0-0.5,0-0.9,0c-0.7-0.1-1.8-0.2-3.1-0.7c-1.1-0.4-2.3-1.1-3.5-2c-0.1,0-1.7-1.1-3.8-3.4
									c-0.3-0.3-0.6-0.7-0.9-1.1c-2.7-3.2-4.9-6.8-6.2-9.2c-1.4-2.6-2.2-4.5-2.2-4.5l-2.1,0.8c0,0.1,0.7,2,2.1,4.9c0.5,1,1,2,1.6,3.1
									c0.1,0.6,0.3,1.4,0.6,2.4c-9.6-1.9-31.4-5.6-53.1-5.6c-3.3,0-6.5,0.1-9.5,0.3c-2.3,0.1-4.5,0.3-6.5,0.5c-0.9,0.1-1.7,0.2-2.6,0.3
									c-1,0.1-2.1,0.3-3.1,0.4c-0.6,0.1-1.3,0.2-2,0.3c1.7-12.2,3.8-21.3,5.5-28.6c1.1-4.9,2-8.7,2.4-12.1c2.1-15.4,2.7-23.3,4.1-40.6
									l0.3-4.1c0.2-2.7,0.6-5.5,1.1-8.5c2.4,0.8,6.3,2,11.7,3.2c8.5,1.8,17.8,3,27.4,3.4c3.1,0.1,6.7,0.3,10.4,0.5l0.8,0
									c8.3,0.5,19.7,1.1,30.8,1.1h1.1c11.1,0,22.5-0.6,30.8-1.1l0.6,0c3.8-0.2,7.5-0.4,10.6-0.5c15.4-0.6,27-3.1,34-5
									c1.3-0.3,2.5-0.7,3.7-1.1l0.5-0.1c0.5-0.2,0.9-0.3,1.3-0.4c0.5,2.9,0.9,5.6,1.1,8.3l0.3,4.1c1.4,17.3,2,25.2,4.1,40.5
									c0.4,3.4,1.3,7.2,2.4,12c1.7,7.5,3.8,16.7,5.6,29.1l-0.8-0.1c-6.8-1.1-14.6-1.7-23.3-1.7c-22.5,0-44.4,3.8-53.7,5.6
									c1.3-2.1,2.3-4.1,3-5.6c1.4-2.8,2.1-4.8,2.1-4.9l-2.1-0.8c0,0-0.8,1.9-2.2,4.5c-1.3,2.4-3.5,6-6.2,9.2c-1.3,1.5-2.7,2.9-4.1,4
									c-1.4,1.1-2.8,1.9-4.1,2.4c-1.3,0.5-2.4,0.7-3.1,0.7C214.3,525.6,214,525.6,213.8,525.6z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M195.7,513.1c0.2,0.9,0.6,2.3,1,4.2
									c0.6,0.1,1.3,0.3,1.9,0.4C197.5,516.1,196.6,514.6,195.7,513.1z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M231.9,513.1c-0.2,0.9-0.5,2.2-1,4.1
									c-0.6,0.1-1.3,0.3-1.9,0.4C230.1,516,231.1,514.6,231.9,513.1z"/>
							</g>
							<g id="front_layer7">
								<path fill="#FFFFFF" d="M352.2,477.3c-2.8-17-3.2-27.4-3.8-43.2c-0.1-2.7-0.2-5.5-0.3-8.6c-1.2-29.5-2.9-50.4-5.3-64
									c-0.2-0.9-0.3-1.5-0.3-2l-0.3-2.6c-0.7-6.3-0.8-7.2-0.3-11.3c0.3-2.3,0.7-6.1,1.3-15.3c1.6-26-0.7-43.9-2-54.7
									c-0.5-4-0.9-7-0.9-9.5c-0.1-1.8-0.2-3.2-0.3-4.7c-0.5-5.7-0.7-10.1,1-24.1c1.2-0.5,2.3-1.1,3.3-1.8c0.2-0.2,0.5-0.3,0.7-0.5
									c0.2-0.1,0.4-0.3,0.5-0.4c1.4-1.2,2.5-2.4,3.3-3.5c0,2.5-0.1,4.8-0.4,7c-1.7,14.3-1.4,18.1-1,22.5c0.1,1.5,0.3,3.1,0.3,5.1
									c0.1,2.1,0.4,5.1,0.9,8.8c1.4,11,3.6,29.4,2,56.1c-0.6,9.4-1,13.4-1.3,15.7c-0.4,3.3-0.4,3.4,0.3,9.6l0.3,2.6
									c0,0.3,0.1,0.8,0.2,1.5c0.9,5.3,3.7,21.2,2.2,66c-0.6,17.5-1,40.2-0.1,50.2h-0.1c0,0,0.1,1.5,0.1,1.7
									C352.3,477.8,352.2,477.5,352.2,477.3z"/>
							</g>
							<g id="front_layer23">
								<path fill="#FFFFFF" d="M337.2,492.7c0.2-7,0.1-17.9-1.9-31.8c-2.4-17.1-7.2-35.6-9.8-45.6c-0.9-3.4-1.6-6-1.6-6.7
									c-1.7-16-1.8-27.1-1.9-36.1c-0.1-7.1-0.1-13.2-0.9-19.6c-0.4-3.8-1.1-7.7-1.8-12.2c-1-6.1-2.1-12.9-3.1-21.3
									c-0.6-5.6-1.5-11.5-2.4-17.2c-1.4-9.1-6-46-3.5-62.6l6.6-0.1c4.3-0.1,7.7-0.1,10-0.2c1,0,1.8-0.1,2.4-0.1c0.7,0,1.3-0.1,1.9-0.1
									l0.5,0c0.5-0.1,1-0.1,1.5-0.2l0.3,0c1.1-0.2,2.3-0.4,3.3-0.7l0.1,0c0.5-0.1,1-0.3,1.5-0.4l0.2,0c-1.5,13.2-1.3,17.7-0.8,23.3
									c0.1,1.4,0.2,2.8,0.3,4.6c0.1,2.6,0.5,5.7,1,9.6c1.3,10.7,3.5,28.5,2,54.3c-0.6,9.2-1,12.9-1.3,15.1c-0.5,4.5-0.4,5.6,0.3,11.9
									l0.3,2.6c0.1,0.5,0.2,1.2,0.3,2.1c2.3,13.4,4,34.2,5.2,63.7c0.1,3.1,0.2,5.9,0.3,8.6c0.6,15.8,1,26.3,3.8,43.4
									c1.1,6.8,2.4,13.8,3.4,19l0.1,0.6L337.2,492.7z"/>
							</g>
							<g id="front_layer9">
								<g>
									<path fill="#FFFFFF" d="M334.9,481.1c-0.2-1.2-0.5-2.4-0.8-3.7c-1.9-7.7-5.9-20.5-9.8-32.9c-4.8-15.1-9.3-29.3-9.7-33.9
										c-1.7-16.4-1.8-27.8-1.9-37c-0.1-6.8-0.1-12.6-0.8-18.6c-0.4-3.6-1.1-7.4-1.8-11.8c-1-6.1-2.2-13-3.2-21.8
										c-0.9-8.4-1.9-14.7-2.8-20.4c-1.6-10.6-2.7-17.5-1.9-27.7c0-0.4-0.1-0.8-0.3-1.1l5.3-32l0.9,0c-2.4,17,2.2,53.7,3.5,62.8
										c0.9,5.8,1.8,11.6,2.4,17.2c1,8.5,2.1,15.3,3.1,21.3l0,0.1c0.8,4.5,1.4,8.4,1.8,12.1c0.7,6.3,0.8,12.3,0.9,19.2
										c0.1,9.1,0.2,20.3,1.9,36.4c0.1,0.8,0.6,2.8,1.7,7c2.6,9.9,7.4,28.4,9.8,45.4C334.2,469.3,334.7,475.7,334.9,481.1z"/>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M320.7,366.2c-1.7-8.7-10.1-25.5-13.2-31.4
										c0.5,3.1,1,6,1.4,8.8c0.7,4.4,1.4,8.2,1.8,11.7c0.7,5.9,0.7,11.7,0.8,18.4c0.1,9.2,0.2,20.6,1.9,37.1c0.2,1.8,1,5.1,2.2,9.3
										C314.4,399.5,320.7,366.2,320.7,366.2z"/>
								</g>
							</g>
							<g id="front_layer11">
								<g>
									<path fill="#FFFFFF" d="M246.3,423c12.2-38.8,19.1-108.1,22.1-146.3l0.1-1.1c1.4-18.1,2.1-31.3,2.3-35l8.9,0l15.7-0.1
										c3.4,0,6.5,0,9.5-0.1l-5.2,31.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.4,0.5-0.4,0.8l-0.2,1.3c-0.2,0.6-0.4,1.2-0.6,1.8l-0.2,0.5
										c-0.1,0.5-0.3,1-0.4,1.5l-0.1,0.5c-0.1,0.5-0.3,1-0.4,1.6l-0.1,0.4c-0.2,0.7-0.4,1.3-0.5,2c-0.5,1.9-0.9,3.9-1.4,6.1
										c-0.3,1.5-0.9,3.6-1.5,5.7c-1.3,4.1-2.9,9-4.4,14.6c-2.8,10-4.6,18.9-5.5,27.2c-0.7,5.8-0.9,11.3-0.7,16.2
										c0.2,3.4,0.7,7.6,1.3,12.2c1,7.3,2.1,15.6,1.7,22.3c0,0.3,0,0.6,0.2,0.8c0.1,0.1,6.1,12.7,9.3,28.1c-1.4,0.5-3.3,1.1-5.9,1.8
										c-3.1,0.8-6.4,1.6-9.9,2.2c-8,1.5-15.5,2.4-23.2,2.7C253.3,422.6,249.9,422.8,246.3,423z"/>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M304.3,242.7c-0.9,2.4-2.3,5.8-4.2,10
										c-0.9,1.9-2,4-3.1,6.2c-1.2,2.2-2.5,4.4-4,6.7c-1.5,2.2-3.3,4.5-5.4,6.5c-0.8,0.7-1.7,1.5-2.6,2.1c2.6,0.9,9.3,3.8,12.5,8.9
										c0,0,0,0,0,0c0.2-0.7,0.3-1.3,0.5-2c0-0.1,0.1-0.3,0.1-0.4c0.1-0.5,0.3-1,0.4-1.5c0-0.2,0.1-0.3,0.1-0.5c0.1-0.5,0.3-1,0.4-1.5
										c0.1-0.2,0.1-0.3,0.2-0.5c0.2-0.6,0.4-1.3,0.6-1.9l0.2-1.4c0-0.1,0.1-0.2,0.1-0.3c0.2-3,0.5-4.5,1-8.8
										C301.8,260,303.6,247.4,304.3,242.7z"/>
									<path opacity="0.1" fill="#020202" enableBackground="new    " d="M279.8,276.1l1.1-0.2l1-0.3l1-0.3c0.4-0.1,0.7-0.3,1.1-0.5
										c0.3-0.2,0.6-0.4,1-0.6c1-0.6,1.8-1.3,2.6-2.1c2.1-2,3.8-4.3,5.4-6.5c1.5-2.3,2.9-4.5,4-6.7c1.2-2.2,2.2-4.3,3.1-6.2
										c1.9-4.2,3.3-7.6,4.2-10c0.8-2.1,1.2-3.3,1.2-3.3c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.6,1.3-1.7,3.5
										c-0.6,1.1-1.2,2.5-2,4c-0.8,1.5-1.7,3.3-2.7,5.2c-1,1.9-2.1,3.9-3.3,6c-1.2,2.1-2.6,4.3-4.1,6.5c-1.5,2.2-3.1,4.4-5,6.3
										c-1,1-2,1.8-3.2,2.5c-0.3,0.1-0.6,0.3-0.9,0.4l-1,0.4l-1,0.3l-1,0.2c-2.7,0.6-5.5,0.8-8.3,0.8c-1.3,0-2.5,0-3.8,0
										c0,0.3-0.1,0.7-0.1,1c1.3,0.1,2.6,0.1,3.9,0.1C274,276.8,276.8,276.7,279.8,276.1z"/>
								</g>
							</g>
							<g id="front_layer20">
								<path fill="#FFFFFF" d="M212.9,424.4c-11,0-22.4-0.6-30.7-1.1C170,385,163.1,315.2,160.1,276.9l-0.1-1.1c-1.4-17.8-2.1-31-2.3-34.9
									l0.6,0v0l15.9,0.1c4.8,0,19.2,0,19.2,0l21.6,0l20.3,0l15.3,0l18-0.1c-0.2,4-0.9,17-2.3,34.8l-0.1,1c-3,38.3-9.9,108.2-22.2,146.6
									c-4.1,0.2-20.4,1-29.8,1H212.9z"/>
							</g>
							<g id="front_layer10">
								<g>
									<path fill="#FFFFFF" d="M180,423c-3.4-0.2-6.7-0.4-9.6-0.5c-19.4-0.8-32.6-4.5-38.1-6.3c3.1-15.5,9.3-28.4,9.4-28.5
										c0.1-0.2,0.2-0.5,0.2-0.8c-0.3-6.7,0.8-15,1.7-22.3c0.6-4.5,1.2-8.8,1.3-12.2c1-21.8-6.8-46.6-10.6-58.6c-0.7-2.2-1.2-3.9-1.6-5.1
										c-0.4-1.6-1.1-2.9-1.7-4.2c-0.5-1.1-1-2.1-1.5-3.4l-0.2-0.5c-0.1-0.2-0.3-0.8-0.3-0.8c-0.1-0.5-0.3-0.9-0.4-1.4
										c-0.2-0.9-0.4-1.8-0.5-2.8c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.2-0.2-0.2-0.3l0,0l-0.1-0.1c0,0-0.1-0.1-0.1-0.1l-5.2-33.9
										c2.1,0,4.3,0,6.6,0.1c3.6,0,7.5,0.1,11.5,0.1c3.9,0,15.1,0.1,15.1,0.1c0.2,3.9,0.9,17,2.4,35l0.1,1.1c3,38.1,9.8,107.3,22,146.1h0
										L180,423z"/>
									<g opacity="0.1">
										<path fill="#020202" d="M155.9,275.6c-2.8,0-5.7-0.2-8.3-0.8l-1-0.2l-1-0.3l-1-0.4c-0.2-0.1-0.6-0.3-0.9-0.4
											c-1.2-0.7-2.2-1.5-3.2-2.5c-1.9-1.9-3.6-4.1-5-6.3c-1.5-2.2-2.8-4.4-4.1-6.5c-1.2-2.1-2.3-4.1-3.3-6c-1-1.9-1.9-3.6-2.7-5.2
											c-0.8-1.6-1.4-2.9-2-4c-1.1-2.3-1.7-3.5-1.7-3.5c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1c0,0,0.5,1.5,1.5,4.1
											c0.9,2.3,2.2,5.5,3.9,9.2c0.9,1.9,2,4,3.1,6.2c1.2,2.2,2.5,4.4,4,6.7c1.5,2.2,3.3,4.5,5.4,6.5c0.1,0.1,0.2,0.2,0.3,0.3
											c1,0.9,2,1.7,3.3,2.4c0.4,0.2,0.6,0.3,1.1,0.5l1,0.3l1,0.3l1.1,0.2c2.9,0.6,5.8,0.7,8.6,0.7c1.1,0,2.1,0,3.2-0.1
											c0-0.4-0.1-0.7-0.1-1.1C157.9,275.6,156.9,275.7,155.9,275.6z"/>
										<g>
											<path opacity="0.1" fill="#020202" enableBackground="new    " d="M155.9,275.6c-2.8,0-5.7-0.2-8.3-0.8l-1-0.2l-1-0.3l-1-0.4
												c-0.2-0.1-0.6-0.3-0.9-0.4c-1.2-0.7-2.2-1.5-3.2-2.5c-1.9-1.9-3.6-4.1-5-6.3c-1.5-2.2-2.8-4.4-4.1-6.5c-1.2-2.1-2.3-4.1-3.3-6
												c-1-1.9-1.9-3.6-2.7-5.2c-0.8-1.6-1.4-2.9-2-4c-1.1-2.3-1.7-3.5-1.7-3.5c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1
												c0,0,0.5,1.5,1.5,4.1c0.9,2.3,2.2,5.5,3.9,9.2c0.9,1.9,2,4,3.1,6.2c1.2,2.2,2.5,4.4,4,6.7c1.5,2.2,3.3,4.5,5.4,6.5
												c0.1,0.1,0.2,0.2,0.3,0.3c1,0.9,2,1.7,3.3,2.4c0.4,0.2,0.6,0.3,1.1,0.5l1,0.3l1,0.3l1.1,0.2c2.9,0.6,5.8,0.7,8.6,0.7
												c1.1,0,2.1,0,3.2-0.1c0-0.4-0.1-0.7-0.1-1.1C157.9,275.6,156.9,275.7,155.9,275.6z"/>
										</g>
									</g>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M139.7,272.4c-0.1-0.1-0.2-0.2-0.3-0.3
										c-2.1-2-3.8-4.3-5.4-6.5c-1.5-2.3-2.9-4.5-4-6.7c-1.2-2.2-2.2-4.3-3.1-6.2c-1.7-3.7-3-6.9-3.9-9.2c-0.7-1.6-1.3-2.8-1.3-2.8
										s2.5,15.8,3.3,21.1c0.8,6.1,0.9,6.6,1.4,13.4c0.1,0,0.3,0,0.3,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2
										c0.1,1.1,0.3,2,0.5,2.9c0.1,0.5,0.2,1,0.4,1.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.4,0.3,0.8,0.4,1.2
										C131.2,276.1,137.2,273.3,139.7,272.4z"/>
								</g>
							</g>
							<g id="front_layer8">
								<g>
									<path fill="#FFFFFF" d="M64.5,476.7c1.6-7.6,3-13.2,4.4-16.7l0.2-0.4c9.2-20.3,24.3-56.5,26-67c3-18.6,6.9-29.1,10-37.5
										c2.1-5.6,3.6-9.7,4.3-14.6c0.7-5.1,1.9-11.8,3.1-18.9l0-0.2c2.7-15.7,9.6-63.3,6.6-80.8l0.8,0l5.2,34.3c-0.2,0.3-0.4,0.8-0.3,1.2
										c0.8,5.8,0.5,13.9-0.9,24.7c-0.4,3.2-1,6.6-1.5,10.5c-0.8,5-1.7,9.8-2.6,15.2c-1,5.8-2,11.2-2.6,15.5c-0.8,5.7-2.5,10.3-4.7,16.1
										c-3,8.1-6.8,18.2-9.7,36.2c-2.4,15-26.3,68.3-26.6,68.8c-1.3,3.4-2.7,8.7-4.2,16.3L64.5,476.7z"/>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M126.3,278.8c-1.6,5.1-7.4,24.1-15.1,64.3
										c-8.3,43.4-29.5,105.5-33.6,119.3c3.2-7.3,24-54,26.2-68.2c2.9-17.8,6.6-27.9,9.6-36c2.2-5.9,3.9-10.5,4.7-16.3
										c0.5-3.3,1.2-7.4,1.9-11.7c2.2-14,5.2-31.8,6.2-45.5C126.4,282.6,126.4,280.6,126.3,278.8z"/>
								</g>
							</g>
							<g id="front_layer19">
								<path fill="#FFFFFF" d="M54.9,473.3c1.2-3.3,2.7-7.8,3.2-9.8c0.2-0.7,0.4-2.4,0.9-6.2c0.6-4.3,1.4-10.8,2.9-20.8l3.8-26.8
									c1.5-10.8,4.4-21.6,8.5-32.2c2.6-6.6,5.1-11.8,6.8-15.2c0.9-1.9,1.7-3.4,2.2-4.7c0.6-1.4,0.9-2.6,1-3.8l0-0.3l0-0.4
									c0.1-1,1.4-24.6,1.4-28.7c0-24.4,2-38.3,3.4-47.4c0.6-4.2,1.1-7.5,1.2-10.6c0.1-1.8,0.2-3.1,0.3-4.5c0.5-5.7,0.7-10.1-0.8-23.4
									l0.9,0.4l-0.1-0.2c2.6,0.8,5.5,1.3,8.6,1.4l0.3,0c1.5,0.1,4.8,0.1,10,0.2c1.5,0,2.8,0,4.3,0c1,0,2,0,3.2,0
									c2.9,15.8-3,60.8-6.4,80.5l0,0.3c-1.2,7.1-2.4,13.8-3.1,19c-0.6,4.7-2.1,8.6-4.1,14.1c-3.2,8.6-7.2,19.2-10.2,38
									c-1.6,10.2-16.7,46.2-25.8,66.4l-0.1,0.2l-0.1,0.3c-1.4,3.6-2.9,9.2-4.5,16.8L54.9,473.3z"/>
							</g>
							<g id="front_layer6">
								<path fill="#FFFFFF" d="M46.3,470.3c0.7-1.9,1.4-3.9,2.2-6.3c0.1-0.2,0.1-0.4,0.1-0.5c0-0.9,0.4-4,3.8-27.9l3.8-26.9
									c3.4-24.3,12.1-41.9,16.2-50.3c1.5-3.1,2.2-4.5,2.3-5.5c0-0.3,1.3-24.4,1.3-28.3c0-25.1,2.1-39.3,3.5-48.8c0.6-3.9,1-7,1.1-9.5
									c0.1-2.1,0.2-3.6,0.3-5.1c0.4-4.5,0.7-8.3-1-22.6c-0.3-2.2-0.4-4.5-0.4-6.8c0.4,0.6,1,1.1,1.5,1.7c1.6,1.6,3.6,2.9,5.9,4l0.1,0.2
									c1.6,13.9,1.5,18.3,1,23.9c-0.1,1.5-0.3,2.9-0.3,4.7c-0.1,3-0.6,6.2-1.2,10.3c-1.3,9.2-3.4,23.1-3.4,47.7c0,4.1-1.3,28.4-1.4,28.6
									l0,0.3l0,0.2c-0.1,1-0.4,2-0.9,3.2c-0.5,1.2-1.2,2.6-2.1,4.6c-1.7,3.5-4.3,8.7-6.9,15.4c-4.2,10.7-7.1,21.7-8.6,32.7l-3.8,26.8
									c-1.4,10-2.3,16.5-2.9,20.8c-0.5,3.5-0.7,5.4-0.9,6c-0.4,1.9-1.9,6.3-3.1,9.6L46.3,470.3z"/>
							</g>
							<g id="front_layer5">
								<g>
									<path fill="#FFFFFF" d="M188.8,238.5c-4.9,0-9.8,0-14.6,0l-16.8-0.1l0,0l-5.5,0c-4.2,0-15.6-0.1-15.6-0.1l-8.1-0.1l-0.5,0
										l-8.9-0.1c-3.5,0-6.7-0.1-9.4-0.1c-3.5-0.1-8.1-0.2-9.9-0.2l-0.3,0c-4.1-0.2-7.7-1-10.7-2.3c-2.2-1-4-2.2-5.5-3.7
										c-1.6-1.6-2.6-3.2-3.1-4.2c0.9-16.7,8.8-39.5,20.7-48.8c7.9-6.2,17-6.9,20.6-6.9c1.1,0,1.8,0.1,1.9,0.1l0.2,0c0.2,0,0.4,0,0.6-0.1
										c0.5-0.2,1.1-0.4,1.8-0.7c4.1,0.4,12,1.8,26.7,6.2c13.7,4.1,24.3,4.6,32.8,5.1c4.1,0.2,7.7,0.4,10.9,1c1.5,0.3,2.9,1,4.6,1.8
										c0.8,0.4,1.6,0.8,2.5,1.2v0l0.7,0.3c3.2,1.3,6.5,1.9,10.3,2c3.6,0,6.6-0.6,9.7-1.7l0.7-0.3v-0.1c0,0,0,0,0,0c1-0.4,2-0.9,2.9-1.3
										c1.6-0.8,3.1-1.5,4.6-1.8c3.2-0.5,6.8-0.7,10.9-1c7.3-0.4,16.4-0.9,27.7-3.7c1.1-0.2,2.2-0.5,3.5-0.8c0.5-0.1,1.1-0.3,1.7-0.4
										l0.3-0.1l0.1,0l0.1,0c1-0.3,1.9-0.6,2.8-0.8c0,0,2.4-0.7,3.1-0.9c10.9-3,17.1-4.1,20.4-4.4c0.6,0.3,1.2,0.5,1.7,0.7
										c0.2,0.1,0.4,0.1,0.6,0.1h0.1l0.1,0c0,0,0.7-0.1,1.9-0.1c3.5,0,12.7,0.7,20.6,6.9c12.3,9.7,19.8,31.9,21,49
										c-0.6,1.4-1.8,3.4-4.1,5.3c-0.1,0.1-0.3,0.2-0.5,0.4c-0.2,0.1-0.4,0.3-0.6,0.4c-1.2,0.8-2.5,1.5-3.9,2c-0.4,0.2-0.8,0.3-1.3,0.5
										l-0.1,0c-0.4,0.1-0.9,0.3-1.3,0.4l-0.1,0c-1,0.3-2,0.5-3.1,0.6l-0.3,0c-0.5,0.1-0.9,0.1-1.4,0.2l-0.4,0c-0.6,0.1-1.2,0.1-1.8,0.1
										c-0.6,0-1.4,0.1-2.3,0.1c-2.3,0.1-5.6,0.1-10,0.2l-10.7,0.1l-17.6,0.1l-8.9,0l-8.9,0l-1.1,0l-19.8,0.1l-28.5,0l-6.7,0L188.8,238.5
										z"/>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M144.4,173.8c-10.1-2.7-15.9-3.6-19.1-3.9
										c-0.5,0.2-0.9,0.4-1.3,0.6l36.4,11.7C160.3,182.2,152.4,178,144.4,173.8z"/>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M281.6,174.4c-7.7,4-14.9,7.8-14.9,7.8l36.8-11.8
										c-0.3-0.1-0.6-0.3-1-0.4C299,170.2,292.7,171.3,281.6,174.4z"/>
								</g>
							</g>
							<g id="front_layer4">
								<g>
									<g>
										<path fill="#FFFFFF" d="M235.5,120.5c1.7-1.7,3.2-3.5,4.7-5.6v12.2c0,0.2,0.2,15.5,14.2,20.8l0.2,0.1c5.5,2.1,16.8,7.8,27.7,13.3
											c6,3,11.7,5.9,16,8c-3.6,0.6-9.1,1.8-17.1,4c-0.7,0.2-1.5,0.4-2.3,0.7l-0.8,0.2c-0.9,0.3-1.9,0.5-2.9,0.8
											c-0.6,0.2-1.2,0.3-1.8,0.5l-0.2,0c-0.5,0.1-1.1,0.3-1.6,0.4c-0.6,0.1-1.3,0.3-1.9,0.4c-11.1,2.8-20.1,3.3-27.3,3.7
											c-4.2,0.2-7.8,0.4-11.1,1c-1.8,0.3-3.5,1.1-5.2,2c-0.3,0.1-0.6,0.3-0.9,0.4L235.5,120.5z"/>
										<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M234.4,119.9l-2,12c3-1.7,6-3.7,8.9-6.1v-14.7
											C239.1,114.7,236.8,117.6,234.4,119.9z"/>
									</g>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M302.4,169.9c-1.7-0.7-3.8-1.8-6.2-2.9
										c-3.4,1.7-9.1,4.6-14.6,7.4C292.9,171.2,299,170.2,302.4,169.9z"/>
								</g>
							</g>
							<g id="front_layer17">
								<path fill="#FFFFFF" d="M213.9,186.2c-4.2,0-7.1-0.9-9-1.6l-0.2-0.1l-10.1-61.7c7.1,6,14.2,7.4,19.2,7.5c4.9-0.1,11.9-1.4,19-7.3
									l-10,61.6c0,0-0.8,0.3-0.8,0.3v0.1C219.5,185.8,216.9,186.2,213.9,186.2z"/>
								<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M213.9,131.1c-4.9-0.1-12.1-1.5-19.2-7.5l2.7,16.5
									c9.9,0.5,22.3-0.6,33.9-6.7l1.6-9.6C225.8,129.7,218.8,131.1,213.9,131.1z"/>
							</g>
							<g id="front_layer3">
								<g>
									<g>
										<path fill="#FFFFFF" d="M201.4,183c-1.7-0.8-3.4-1.6-5.2-2c-3.3-0.6-6.9-0.8-11.1-1c-8.4-0.4-18.8-1-32.2-5
											c-11.4-3.4-18.8-5-23.5-5.8c4.3-2.1,10-4.9,15.9-8c10.9-5.5,22.2-11.3,27.7-13.4l0.2-0.1c14.1-5.3,14.2-20.6,14.2-20.8v-12.2
											c1.4,2,2.9,3.8,4.5,5.4l10.3,63.1C201.9,183.3,201.6,183.1,201.4,183z"/>
										<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M193,119.7c-2.3-2.3-4.6-5.1-6.7-8.6v16
											c0,0.1-0.1,5.1-2.8,10.2c3.1,0.7,7.5,1.5,12.6,1.8L193,119.7z"/>
									</g>
									<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M144.4,173.8c-5-2.6-10-5.1-13.2-6.7
										c-2.3,1.1-4.3,2.1-5.9,2.8C128.4,170.2,134.3,171.1,144.4,173.8z"/>
								</g>
							</g>
						</svg>
					</div>
					<div className="shape slider male-back">
						<svg version="1.1" id="rear_layer_male_slider" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 357.3 965.2" enableBackground="new 0 0 357.3 965.2" >
								<text textDecoration="underline" fontFamily="PoppinsBold"  x="0" y="120" fill="black" fontSize="15px">Izquierda</text>
								<text textDecoration="underline" fontFamily="PoppinsBold"  x="58%" y="120" fill="black" fontSize="15px">Derecha</text>
								<g id="cuerpo_x2F_sombras_x2F_pelo_x2F_pantalon">
									<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M355.9,510.1
										c-0.8-0.4-2.5-0.8-3.7-1.8c-15.9-12.4-26.8-21.5-33.9-28c-2.5-2.3-5.1-8.6-7.9-17c0-1.4-0.5-5-7.6-55.1
										c-4.5-32.2-18.2-52.8-18.6-55.7c0,0-1.3-24.4-1.3-28.1c0-35.9-4.3-50.1-4.6-58.3c-0.3-8.3-1.7-8.5,0.6-27.5
										c2.3-19-5.6-49.2-21-61.3c-10.9-8.5-23.6-7.2-23.6-7.2c-8.2-3.2-39-19.9-49.3-23.8c0,0-0.2-0.1-0.2-0.1
										c-13.2-5-13.2-19.2-13.2-19.2V109c1.5-2.5,2.9-5.4,4.2-8.6c3.2-7.9,4.7-19.3,6.5-32.2c0.2-1.6,0.4-3.2,0.7-4.9
										c1.7-12.2,1.2-21.8-0.8-29.4c-7.5-28.8-35.7-28.4-38.6-28.3c-2.9-0.1-31.1-0.5-38.6,28.3c-2,7.6-2.5,17.2-0.8,29.4
										c0.2,1.6,0.5,3.3,0.7,4.9c1.8,12.9,3.3,24.3,6.5,32.2c1.3,3.2,2.7,6.1,4.2,8.6v17.8c0,0,0,14.3-13.2,19.2c0,0-0.2,0.1-0.2,0.1
										C91.8,150,61,166.7,52.7,170c0,0-12.8-1.4-23.6,7.2c-15.4,12.1-23.6,42.3-21.4,61.3c2.3,19,0.9,19.2,0.6,27.5
										c-0.3,8.3-5.1,29.1-2.9,64.9c1.4,23.9,2.4,13.2,0.7,27.7c-0.3,2.9-4.1,17.1-2.5,67.6c1.6,50.6-0.5,51.3,0.1,51.3
										C3.2,490.3,2,498.6,2,498.6s-2.9,40.7-0.6,46.8c2.3,6.1,1.7,13.7,3.8,17.6c2,3.9,15.7,23.1,16.8,27c1.2,3.9,5.5,3.3,6.1,0
										c0.6-3.3-9-17.8-8.7-21.5c0,0,6.7,2.8,7-2.8c0.3-5.6-0.2-25.9,1.8-32.6c2-6.7-8.7-30.1-6.4-39c0.7-2.7,1.2-8.9,3.1-16.6
										c4.1-16.9,18.7-58.4,19.6-66.9c3-28.6,1.1-41.8,2.8-55.5c1-8.5,3.3-18.5,5-33.5c1.7-15.4,4.3-32.4,4.5-47.6c0,0,3,10.9,4.2,15
										c2.4,8.5,13.3,37.8,12.1,63.2c-0.4,8.7-3.6,23.7-3,34.6c0,0-9.4,19.2-11,39.6c-1.6,20.4-2.2,28-4.4,44.5
										c-2.2,16.5-15.7,47.6-9.4,132c2.2,29.1,8.5,33.1,5.7,69.8c-2.4,32.3-18.9,62.1-14.5,118.2c4.1,53.3,6.7,76.7,6.6,95.5h0
										c-0.6,11.9-1.2,20.2-1.3,21.9c0,0-3.9,14.6-2.6,21.1c1.3,6.4-7.7,18.1-15.3,24.5s-1,8.2,2.1,8.9c2.5,0.5,49.2,7,45.2-7.3
										c-1.2-4.3-2.4-2.5-1.9-17.6c0.3-8.7,2.9-9,1.3-16.6c0,0-2.3-8.3-2.5-12h-0.2c4.9-29.3,11.1-58.4,17.5-87.5
										c2.1-10.7,4.2-21,5.7-29.6c1.6-8.9,6.9-29.2,7.1-31.3c0,0,3.4-13.3,4.6-35.3c0.1-3.4,0.1-6.9,0.1-10.3c0-3.5,0.1-7.1,0.3-10.7
										c0-1.3,0-2.6-0.1-3.9c-0.1-2.7,0.4-5.3,1.3-8c0.4-2.6,1-5.2,1.7-7.8c2.7-9.9,8-18.9,10.5-28.9c4.9-19.3,6.8-39.1,9.6-58.8
										c2.9-20.4,5.8-40.9,8.2-61.4c0,0,0.2-3.4,0.6-7.6c0.3-0.1,0.6-0.2,0.9-0.4c0.9-0.4,1.7-0.8,2.6-1.2l5,0.6l5-0.6
										c1,0.5,2.3,0.9,3.5,1.3c0.2,0.1,0.3,0.1,0.5,0.2c0.2,2.5,0.4,5.2,0.8,7.9c3.6,26.6,10,75.9,15.9,110.3
										c4.9,28.6,15.8,41.7,15.4,54.4c-1.1,38.1,5,60.2,5,60.2c0.3,2.2,5.5,22.5,7.2,31.3c6.1,33.4,19.1,94.2,19.1,94.2
										c2.4,13.3,3.9,22.4,3.9,22.4c0,3.3-2.5,12.5-2.5,12.5c-1.7,7.5,1,7.9,1.3,16.6c0.5,15.1-0.7,13.4-1.9,17.6
										c-4.1,14.3,42.7,7.8,45.2,7.3c3.1-0.6,9.6-2.5,2.1-8.9c-7.5-6.4-16.6-18.1-15.3-24.5c1.3-6.4-2.6-21.1-2.6-21.1
										c-0.1-1.7-0.7-10.1-1.3-21.9h-0.1c-0.1-18.8,1.7-42.3,6.6-95.5c5-53.3-12.4-86.5-14.5-118.2c-2.5-36.8,3.6-40.8,5.7-69.8
										c6.3-84.5-7.1-115.3-9.3-132c-2.2-16.5-2.8-24.2-4.4-44.5c-1.6-20.4-11-39.6-11-39.6c0.6-11-2.6-25.9-3-34.6
										c-1.2-25.4,9.7-54.6,12.1-63.2c1.6-5.9,2-5.1,3.4-12.3c0.3,17.7,6.7,49,8.9,65.2c2,14.2,9.5,21.8,14.4,52.3
										c2.5,15.5,26.7,69.2,26.7,69.2c2.6,6.5,4.7,19.2,5.6,23.5c1.1,5.7,1.7,14.5,5,23.8c2.6,7.5,3.6,9.3,6.5,16.9
										c2.4,6.3,11.2,28.4,12.1,30.3c1.7,3.8,7.7,2,7.1-0.8c-0.4-1.7-0.9-3.9-1.5-6.3c1.6,5.8,3.5,11.2,4.4,13.4c2.4,5.7,9.2,4.2,7.5-2.5
										c0.3,1.1,0.6,2,0.9,2.7c1.4,4.3,8.8,5.4,7.4-3c-0.2-1.4-7.8-34.9-8.4-37.6c0.8,2.6,8.5,35.8,8.9,36.4c1.4,2.1,7.7,2,6.3-6.5
										c-1.8-10.6-3.2-18.7-4.6-27.6c-1.3-8.6-2.7-14.5-3.4-15.9c0,0,2.1-0.5,3.2-0.8c3.3-0.7,8.1,4.7,13.5,6.3
										C355.4,517.2,358.4,511.4,355.9,510.1z"/>
									<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M182.6,65.2
										c5,0.5,18.6,4-4.7,28.3C179.9,85.7,181.1,75.9,182.6,65.2z"/>
									<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M104.4,65.2
										c-5,0.5-18.6,4,4.7,28.3C107.1,85.7,105.9,75.9,104.4,65.2z"/>
									
										<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="317.7" y1="527.9" x2="326.3" y2="561.6"/>
									
										<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="307.9" y1="529.2" x2="314.4" y2="550.5"/>
									<path fill="#DDC7BF" d="M17.4,548.8c0,0,0.6-0.8-0.4,1c-0.3,0.4-0.5,0.9-0.7,1.5c-0.2,0.5-0.4,1.1-0.5,1.7
										c-0.2,0.6-0.2,1.3-0.3,1.9c0,0.7,0,1.3,0,2c0.1,0.7,0.1,1.3,0.3,2c0.2,0.6,0.3,1.3,0.5,1.9c0.2,0.6,0.4,1.2,0.6,1.7
										c0.3,0.5,0.5,1,0.7,1.4c0.4,0.9,1,1.6,1.2,2.1c0.3,0.5,0.5,0.8,0.5,0.8l0,0.1c0.2,0.4,0.1,0.9-0.3,1.1s-0.9,0.1-1.1-0.3
										c0,0,0,0,0-0.1c0,0-0.2-0.3-0.5-0.9c-0.3-0.6-0.8-1.3-1.1-2.3c-0.2-0.5-0.4-1-0.7-1.6c-0.2-0.6-0.4-1.2-0.6-1.8
										c-0.2-0.6-0.3-1.3-0.4-2c-0.2-0.7-0.1-1.4-0.2-2.1c0-0.7,0.1-1.4,0.1-2.1c0.1-0.7,0.2-1.4,0.5-2c0.2-0.6,0.4-1.2,0.7-1.8
										c0.3-0.5,0.5-1,0.8-1.5c1.2-1.7,0.8-1.1,0.8-1.1"/>
									<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M21.5,500.1
										c0,0,2.7,12.8-0.9,29.5c-3.5,16.7-3.6,17.3-3,24.9"/>
									<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M19.4,568.7
										c0,0-2.5-19.6-2-20.9"/>
									<path fill="#EFE6E1" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8.7,568.7
										c0,0,5.8,8.3,10.6,13.9c2.4,2.7,7.7,2.6-2.1-17.7"/>
									<path fill="#EFD3D3" d="M238.6,341.8c-9.2-50.1-8.8-65-8.8-65s6.9,18.5,16.1,66.2c8.5,44.5,30.6,108.5,33.8,120.3
										c0,0-24.2-53.7-26.7-69.2C248.1,363.7,240.5,356,238.6,341.8"/>
									<path fill="#DDC7BF" d="M247,368.2c0,0,2,7.4,12,7.4"/>
									<path fill="#ECF2F6" d="M90.7,689.3c0,0-4.5,8.3-5.4,22.3"/>
									<path fill="#DDC7BF" d="M90.7,689.3c0,0-0.4,1.4-1,3.5c-0.2,1.1-0.6,2.2-0.9,3.5c-0.3,1.3-0.7,2.7-1,4.1c-0.2,0.7-0.3,1.4-0.5,2.1
										c-0.1,0.7-0.3,1.4-0.4,2c-0.1,0.7-0.3,1.3-0.4,1.9c-0.1,0.6-0.2,1.2-0.3,1.7c-0.4,2.1-0.8,3.5-0.8,3.5s-0.1-1.4-0.1-3.6
										c0-1.1,0.1-2.4,0.3-3.7c0.1-0.7,0.2-1.4,0.3-2.1c0.2-0.7,0.3-1.4,0.5-2.1c0.3-1.4,0.7-2.8,1.2-4.1c0.4-1.3,1-2.5,1.4-3.5
										c0.5-1,0.9-1.8,1.3-2.3C90.5,689.6,90.7,689.3,90.7,689.3"/>
									<path fill="#ECF2F6" d="M195.1,689.3c0,0,4.5,8.3,5.4,22.3"/>
									<path fill="#DDC7BF" d="M195.1,689.3c0,0,0.2,0.3,0.6,0.8c0.4,0.5,0.8,1.3,1.3,2.3c0.4,1,1,2.1,1.4,3.5c0.5,1.3,0.9,2.7,1.2,4.1
										c0.2,0.7,0.3,1.4,0.5,2.1c0.1,0.7,0.2,1.4,0.3,2.1c0.2,1.4,0.3,2.6,0.3,3.7c0,2.2-0.1,3.6-0.1,3.6s-0.4-1.4-0.8-3.5
										c-0.1-0.5-0.2-1.1-0.3-1.7c-0.1-0.6-0.3-1.2-0.4-1.9c-0.1-0.7-0.3-1.3-0.4-2c-0.2-0.7-0.3-1.4-0.5-2.1c-0.3-1.4-0.6-2.8-1-4.1
										c-0.3-1.3-0.7-2.5-0.9-3.5C195.5,690.7,195.1,689.3,195.1,689.3"/>
									<line fill="none" x1="124.8" y1="407" x2="133.4" y2="416.3"/>
									<path fill="#DDC7BF" d="M124.9,406.9c0,0,0.8,0.3,1.8,1.1c0.5,0.4,1,0.8,1.5,1.3c0.5,0.6,0.9,1.2,1.4,1.8c2,2.5,3.8,5,3.8,5
										c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0-2.4-2.1-4.7-4.2c-0.6-0.5-1.2-1-1.7-1.6c-0.5-0.5-0.9-1.1-1.2-1.6c-0.6-1-0.9-1.8-0.9-1.8
										C124.8,407,124.8,407,124.9,406.9C124.9,406.9,124.9,406.9,124.9,406.9"/>
									<line fill="none" x1="161" y1="407" x2="152.4" y2="416.3"/>
									<path fill="#DDC7BF" d="M161,407.1c0,0-0.3,0.8-0.9,1.8c-0.3,0.5-0.7,1.1-1.2,1.6c-0.5,0.5-1.1,1-1.7,1.6c-2.3,2.2-4.7,4.2-4.7,4.2
										c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0,0,1.9-2.6,3.8-5c0.5-0.6,0.9-1.3,1.4-1.8c0.5-0.5,1-1,1.5-1.3c1-0.7,1.8-1.1,1.8-1.1
										C161,406.9,161,406.9,161,407.1C161,407,161,407,161,407.1"/>
									<path fill="#DDC7BF" d="M142.9,162.9c0,0,0.1,0.7,0.2,2.1c0.1,1.3,0.2,3.2,0.3,5.4c0.1,2.2,0.2,4.9,0.3,7.7
										c0.1,2.8,0.1,5.8,0.1,8.8c0,3,0,6-0.1,8.8c0,2.8-0.2,5.4-0.3,7.7c-0.1,2.2-0.2,4.1-0.3,5.4c-0.1,1.3-0.2,2.1-0.2,2.1
										s-0.1-0.7-0.2-2.1c-0.1-1.3-0.2-3.2-0.3-5.4c-0.1-2.2-0.2-4.9-0.3-7.7c-0.1-2.8-0.1-5.8-0.1-8.8c0-3,0-6,0.1-8.8
										c0.1-2.8,0.2-5.4,0.3-7.7c0.1-2.2,0.2-4.1,0.3-5.4C142.8,163.6,142.9,162.9,142.9,162.9"/>
									<path fill="#DDC7BF" d="M129,242.9c0,0-0.3,2.3-1.3,5.5c-0.5,1.6-1.2,3.5-2.1,5.4c-0.5,1-0.9,2-1.5,2.9c-0.6,1-1.1,2-1.8,2.9
										c-0.6,0.9-1.3,1.9-2,2.8c-0.7,0.9-1.4,1.7-2.1,2.5c-0.4,0.4-0.7,0.8-1.1,1.2c-0.4,0.3-0.8,0.7-1.1,1c-0.7,0.7-1.4,1.3-2.1,1.8
										c-0.7,0.5-1.3,1-1.9,1.4c-0.6,0.4-1.1,0.7-1.5,0.9c-0.9,0.5-1.4,0.8-1.4,0.8s0.4-0.4,1.2-1c0.4-0.3,0.8-0.7,1.3-1.1
										c0.5-0.5,1-1,1.6-1.6c0.6-0.6,1.2-1.2,1.9-1.9c0.3-0.3,0.7-0.7,1-1.1c0.3-0.4,0.6-0.8,1-1.2c0.3-0.4,0.7-0.8,1-1.2
										c0.3-0.4,0.6-0.9,1-1.3c0.7-0.9,1.3-1.8,1.9-2.7c0.6-0.9,1.2-1.9,1.8-2.8c0.5-1,1.1-1.9,1.6-2.8c0.5-0.9,1-1.8,1.4-2.7
										c0.4-0.9,0.8-1.7,1.2-2.4c0.7-1.5,1.3-2.8,1.6-3.7C128.8,243.4,129,242.9,129,242.9"/>
									<path fill="#DDC7BF" d="M156.8,242.9c0,0,0.2,0.5,0.6,1.4c0.4,0.9,0.9,2.2,1.6,3.7c0.3,0.8,0.7,1.6,1.2,2.4
										c0.4,0.9,0.9,1.7,1.4,2.7c0.5,0.9,1,1.8,1.6,2.8c0.6,0.9,1.1,1.9,1.8,2.8c0.6,0.9,1.2,1.9,1.9,2.7c0.3,0.4,0.6,0.9,1,1.3
										c0.3,0.4,0.7,0.8,1,1.2c0.3,0.4,0.7,0.8,1,1.2c0.3,0.4,0.7,0.7,1,1.1c0.6,0.7,1.2,1.4,1.9,1.9c0.6,0.6,1.1,1.1,1.6,1.6
										c0.5,0.4,1,0.8,1.4,1.1c0.7,0.6,1.2,1,1.2,1s-0.5-0.3-1.4-0.8c-0.4-0.3-0.9-0.6-1.5-0.9c-0.6-0.4-1.2-0.9-1.9-1.4
										c-0.7-0.5-1.4-1.1-2.1-1.8c-0.4-0.3-0.7-0.7-1.1-1c-0.3-0.4-0.7-0.8-1.1-1.2c-0.7-0.8-1.5-1.6-2.1-2.5c-0.7-0.9-1.3-1.8-2-2.8
										c-0.7-0.9-1.2-2-1.8-2.9c-0.5-1-1-2-1.5-2.9c-0.9-1.9-1.6-3.8-2.1-5.4C157.1,245.2,156.8,242.9,156.8,242.9"/>
									<path fill="#ECF2F6" d="M60.7,288.8c0,0-6.4-21.4-9.9-40"/>
									<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M60.7,288.8
										c0,0-6.4-21.4-9.9-40"/>
									<path fill="#DDC7BF" d="M127,122.7c0,0,0.2,0,0.6,0.1c0.4,0.1,0.9,0.2,1.4,0.4c0.6,0.2,1.2,0.5,1.9,1c0.3,0.2,0.6,0.5,1,0.7
										c0.3,0.2,0.6,0.6,0.9,0.8c0.3,0.3,0.6,0.6,0.8,0.9c0.2,0.3,0.4,0.7,0.7,1c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0.3,0.6,0.4,0.9
										c0.4,1.2,0.6,2,0.6,2s-0.6-0.6-1.4-1.5c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.5-0.5-0.7-0.8c-0.2-0.3-0.5-0.5-0.7-0.8
										c-0.3-0.3-0.5-0.5-0.8-0.8c-0.3-0.3-0.5-0.6-0.8-0.8c-0.3-0.2-0.5-0.5-0.8-0.8c-0.3-0.3-0.5-0.5-0.8-0.7c-0.2-0.2-0.5-0.4-0.7-0.6
										C127.6,123.2,127,122.7,127,122.7"/>
									<path fill="#DDC7BF" d="M159.2,122.7c0,0-0.6,0.5-1.5,1.3c-0.2,0.2-0.4,0.4-0.7,0.6c-0.2,0.2-0.5,0.4-0.8,0.7
										c-0.3,0.2-0.5,0.5-0.8,0.8c-0.3,0.2-0.5,0.5-0.8,0.8c-0.3,0.3-0.5,0.5-0.8,0.8c-0.2,0.3-0.5,0.6-0.7,0.8c-0.2,0.3-0.5,0.5-0.7,0.8
										c-0.2,0.2-0.4,0.5-0.6,0.7c-0.7,0.9-1.4,1.5-1.4,1.5s0.1-0.8,0.6-2c0.1-0.3,0.2-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9
										c0.2-0.3,0.4-0.7,0.7-1c0.3-0.3,0.6-0.6,0.8-0.9c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.3,0.6-0.5,1-0.7c0.6-0.5,1.3-0.8,1.9-1
										c0.6-0.2,1.1-0.4,1.4-0.4C158.9,122.7,159.2,122.7,159.2,122.7"/>
									<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M226.3,288.8
										c0,0,6.4-21.4,9.9-40"/>
									<path fill="#EFD3D3" d="M47.1,358.4c-1.6,14.2,0.2,23.4-2.8,52c-0.2,2.1-1.5,7.3-3.4,14c1.8-13.6-3.4-50.1-3.4-50.1
										s1.1-1.6,2.7-3.4C42.7,368.2,47.1,358.4,47.1,358.4z"/>
									<path fill="#DDC7BF" d="M29.3,379c0,0,7.7,0.4,11.4-9.9"/>
									<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M36.7,944.5
										c0,0,2.2-6.3,2.2-12.7"/>
									<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M250.2,944.5
										c0,0-2.2-6.3-2.2-12.7"/>
									<path fill="#DDC7BF" d="M31,963.2c0,0,8.7-2.2,11.5-4.6c0,0,0.9,5.4,9.4,6.1C51.8,964.7,32.8,964.1,31,963.2"/>
									<path fill="#DDC7BF" d="M253.6,963.8c0,0-8.1-2.8-10.8-5.2c0,0-0.9,5.4-9.4,6.1C233.4,964.7,251.7,964.7,253.6,963.8"/>
									<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M52.5,964.7
										c-11.6-1-10.1-7.1-10.1-7.1"/>
									<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M232.1,964.6
										c11.6-0.9,10.7-7,10.7-7"/>
									<path fill="#DDC7BF" d="M278.3,238.4c-1.7,14.2-1.3,18-1,22.4c0.1,1.5,0.3,3.1,0.3,5.2c0.1,2.5,0.5,5.5,1.1,9.4
										c1.4,9.5,3.5,23.8,3.5,48.9c0,3.8,1.3,27.2,1.3,28.2c0.1,0.8,0.8,2.3,2.2,5.1c4.2,8.5,12.9,26.1,16.3,50.6l3.8,26.6
										c3.5,24.8,3.9,27.4,3.8,28.4c0,0.1,0,0.1,0,0.2c1,2.9,1.9,5.4,2.7,7.6l0.4-0.1c-0.6-1.5-2.4-5.8-2.7-7.6c-0.2-1.4-0.5-5-7.6-55.1
										c-4.5-32.2-18.2-52.8-18.6-55.7c0,0-1.3-24.4-1.3-28.1c0-35.9-4.3-50.1-4.6-58.3s-1.7-8.5,0.6-27.5c0.4-3.5,0.5-7.4,0.2-11.5
										c-0.1,0.2-0.2,0.5-0.3,0.9C278.8,231.5,278.7,235.1,278.3,238.4z"/>
									<g>
										<path fill="#EFD3D3" d="M124.5,530.6c2.7,11,4.9,27.7,2.7,50.7c3.7-17.6,4.6-33.9,6.2-45.2c0.4-2.6,0.6-5.2,0.8-7.7
											C131,529.6,127.8,530.3,124.5,530.6z"/>
									</g>
									
										<line fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="143.1" y1="522.3" x2="143.1" y2="485.4"/>
									<g>
										<path fill="#EFD3D3" d="M162,530.7c-3.3-0.3-6.6-1-9.7-2.1c0.2,2.4,0.4,5,0.8,7.6c1.5,11.3,2.7,27.6,6.4,45.2
											C157.3,558.4,159.3,541.7,162,530.7z"/>
										<path fill="#DDC7BF" d="M172.7,529.7c-3.5,0-6.9-0.1-10.4-0.6c-3.4-0.4-6.8-1.1-10-2.3c-0.8-0.3-1.6-0.6-2.4-0.9
											c-0.8-0.3-1.5-0.7-2.3-1.1c-0.7-0.4-1.5-0.8-2.2-1.3c-0.7-0.4-1.3-0.9-1.9-1.4c-0.1-1.1-0.1-2.3-0.2-3.4c-0.1-1.2-0.2-2.5-0.3-3.7
											c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,1.2-0.2,2.5-0.3,3.7c-0.1,1.1-0.1,2.3-0.2,3.4c-0.6,0.5-1.2,1-1.9,1.4c-0.7,0.5-1.4,0.9-2.2,1.3
											c-0.7,0.4-1.5,0.8-2.3,1.1c-0.8,0.4-1.6,0.6-2.4,0.9c-3.2,1.1-6.6,1.8-10,2.3c-3.4,0.4-6.9,0.6-10.4,0.6c-3.5,0-7-0.2-10.4-0.6
											c1.7,0.5,3.4,0.8,5.2,1.1c1.7,0.3,3.5,0.5,5.2,0.6c1.8,0.1,3.5,0.2,5.3,0.2c1.8,0,3.5-0.1,5.3-0.3c3.5-0.4,7-1.1,10.4-2.3
											c0.8-0.3,1.7-0.7,2.5-1.1c0.8-0.4,1.6-0.8,2.4-1.3c0.8-0.5,1.5-1,2.2-1.5c0.6-0.4,1.1-0.9,1.7-1.4c0.5,0.5,1.1,1,1.7,1.4
											c0.7,0.6,1.4,1.1,2.2,1.5c0.8,0.5,1.6,0.9,2.4,1.3c0.8,0.4,1.6,0.8,2.5,1.1c3.4,1.3,6.9,2,10.4,2.3c1.8,0.2,3.5,0.3,5.3,0.3
											c1.8,0,3.5-0.1,5.3-0.2c1.8-0.1,3.5-0.3,5.2-0.6c1.7-0.3,3.5-0.6,5.2-1.1C179.7,529.5,176.2,529.6,172.7,529.7z"/>
									</g>
									<path fill="none" stroke="#DDC7BF" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M355.9,510.1
										c-0.8-0.4-2.5-0.8-3.7-1.8c-15.9-12.4-26.8-21.5-33.9-28c-2.5-2.3-5.1-8.6-7.9-17c0-1.4-0.5-5-7.6-55.1
										c-4.5-32.2-18.2-52.8-18.6-55.7c0,0-1.3-24.4-1.3-28.1c0-35.9-4.3-50.1-4.6-58.3c-0.3-8.3-1.7-8.5,0.6-27.5
										c2.3-19-5.6-49.2-21-61.3c-10.9-8.5-23.6-7.2-23.6-7.2c-8.2-3.2-39-19.9-49.3-23.8c0,0-0.2-0.1-0.2-0.1
										c-13.2-5-13.2-19.2-13.2-19.2V109c1.5-2.5,2.9-5.4,4.2-8.6c3.2-7.9,4.7-19.3,6.5-32.2c0.2-1.6,0.4-3.2,0.7-4.9
										c1.7-12.2,1.2-21.8-0.8-29.4c-7.5-28.8-35.7-28.4-38.6-28.3c-2.9-0.1-31.1-0.5-38.6,28.3c-2,7.6-2.5,17.2-0.8,29.4
										c0.2,1.6,0.5,3.3,0.7,4.9c1.8,12.9,3.3,24.3,6.5,32.2c1.3,3.2,2.7,6.1,4.2,8.6v17.8c0,0,0,14.3-13.2,19.2c0,0-0.2,0.1-0.2,0.1
										C91.8,150,61,166.7,52.7,170c0,0-12.8-1.4-23.6,7.2c-15.4,12.1-23.6,42.3-21.4,61.3c2.3,19,0.9,19.2,0.6,27.5
										c-0.3,8.3-5.1,29.1-2.9,64.9c1.4,23.9,2.4,13.2,0.7,27.7c-0.3,2.9-4.1,17.1-2.5,67.6c1.6,50.6-0.5,51.3,0.1,51.3
										C3.2,490.3,2,498.6,2,498.6s-2.9,40.7-0.6,46.8c2.3,6.1,1.7,13.7,3.8,17.6c2,3.9,15.7,23.1,16.8,27c1.2,3.9,5.5,3.3,6.1,0
										c0.6-3.3-9-17.8-8.7-21.5c0,0,6.7,2.8,7-2.8c0.3-5.6-0.2-25.9,1.8-32.6c2-6.7-8.7-30.1-6.4-39c0.7-2.7,1.2-8.9,3.1-16.6
										c4.1-16.9,18.7-58.4,19.6-66.9c3-28.6,1.1-41.8,2.8-55.5c1-8.5,3.3-18.5,5-33.5c1.7-15.4,4.3-32.4,4.5-47.6c0,0,3,10.9,4.2,15
										c2.4,8.5,13.3,37.8,12.1,63.2c-0.4,8.7-3.6,23.7-3,34.6c0,0-9.4,19.2-11,39.6c-1.6,20.4-2.2,28-4.4,44.5
										c-2.2,16.5-15.7,47.6-9.4,132c2.2,29.1,8.5,33.1,5.7,69.8c-2.4,32.3-18.9,62.1-14.5,118.2c4.1,53.3,6.7,76.7,6.6,95.5h0
										c-0.6,11.9-1.2,20.2-1.3,21.9c0,0-3.9,14.6-2.6,21.1c1.3,6.4-7.7,18.1-15.3,24.5s-1,8.2,2.1,8.9c2.5,0.5,49.2,7,45.2-7.3
										c-1.2-4.3-2.4-2.5-1.9-17.6c0.3-8.7,2.9-9,1.3-16.6c0,0-2.3-8.3-2.5-12h-0.2c4.9-29.3,11.1-58.4,17.5-87.5
										c2.1-10.7,4.2-21,5.7-29.6c1.6-8.9,6.9-29.2,7.1-31.3c0,0,3.4-13.3,4.6-35.3c0.1-3.4,0.1-6.9,0.1-10.3c0-3.5,0.1-7.1,0.3-10.7
										c0-1.3,0-2.6-0.1-3.9c-0.1-2.7,0.4-5.3,1.3-8c0.4-2.6,1-5.2,1.7-7.8c2.7-9.9,8-18.9,10.5-28.9c4.9-19.3,6.8-39.1,9.6-58.8
										c2.9-20.4,5.8-40.9,8.2-61.4c0,0,0.2-3.4,0.6-7.6c0.3-0.1,0.6-0.2,0.9-0.4c0.9-0.4,1.7-0.8,2.6-1.2l5,0.6l5-0.6
										c1,0.5,2.3,0.9,3.5,1.3c0.2,0.1,0.3,0.1,0.5,0.2c0.2,2.5,0.4,5.2,0.8,7.9c3.6,26.6,10,75.9,15.9,110.3
										c4.9,28.6,15.8,41.7,15.4,54.4c-1.1,38.1,5,60.2,5,60.2c0.3,2.2,5.5,22.5,7.2,31.3c6.1,33.4,19.1,94.2,19.1,94.2
										c2.4,13.3,3.9,22.4,3.9,22.4c0,3.3-2.5,12.5-2.5,12.5c-1.7,7.5,1,7.9,1.3,16.6c0.5,15.1-0.7,13.4-1.9,17.6
										c-4.1,14.3,42.7,7.8,45.2,7.3c3.1-0.6,9.6-2.5,2.1-8.9c-7.5-6.4-16.6-18.1-15.3-24.5c1.3-6.4-2.6-21.1-2.6-21.1
										c-0.1-1.7-0.7-10.1-1.3-21.9h-0.1c-0.1-18.8,1.7-42.3,6.6-95.5c5-53.3-12.4-86.5-14.5-118.2c-2.5-36.8,3.6-40.8,5.7-69.8
										c6.3-84.5-7.1-115.3-9.3-132c-2.2-16.5-2.8-24.2-4.4-44.5c-1.6-20.4-11-39.6-11-39.6c0.6-11-2.6-25.9-3-34.6
										c-1.2-25.4,9.7-54.6,12.1-63.2c1.6-5.9,2-5.1,3.4-12.3c0.3,17.7,6.7,49,8.9,65.2c2,14.2,9.5,21.8,14.4,52.3
										c2.5,15.5,26.7,69.2,26.7,69.2c2.6,6.5,4.7,19.2,5.6,23.5c1.1,5.7,1.7,14.5,5,23.8c2.6,7.5,3.6,9.3,6.5,16.9
										c2.4,6.3,11.2,28.4,12.1,30.3c1.7,3.8,7.7,2,7.1-0.8c-0.4-1.7-0.9-3.9-1.5-6.3c1.6,5.8,3.5,11.2,4.4,13.4c2.4,5.7,9.2,4.2,7.5-2.5
										c0.3,1.1,0.6,2,0.9,2.7c1.4,4.3,8.8,5.4,7.4-3c-0.2-1.4-7.8-34.9-8.4-37.6c0.8,2.6,8.5,35.8,8.9,36.4c1.4,2.1,7.7,2,6.3-6.5
										c-1.8-10.6-3.2-18.7-4.6-27.6c-1.3-8.6-2.7-14.5-3.4-15.9c0,0,2.1-0.5,3.2-0.8c3.3-0.7,8.1,4.7,13.5,6.3
										C355.4,517.2,358.4,511.4,355.9,510.1z"/>
									<path fill="#4E5663" d="M186.4,29.5c-2-8.3-13-29.3-42.7-29.5C114,0.2,102.9,21.2,101,29.5c-1.7,7-2.7,17.7-1.7,23.8
										c0.7,4.6,1.4,8.5,2.1,11.9v0c0,0,1.6,17.4,2.6,21.5c0,0,2.6,14,11.1,20.6c0.8,0.7,1.8,1.2,2.7,1.7c0,0,1.9,1.3,5.4,1.6
										c0.9,0.2,2.6,0.5,4.5,1c2.8,0.8,6.3,2,9.3,3.9c3.4,2.1,6,6.6,6.7,6.7c0.7-0.2,3.3-4.6,6.7-6.7c2.7-1.7,5.8-2.9,8.4-3.6
										c2.7-0.8,4.9-1.2,5.8-1.3c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0l0,0c2.9-0.5,4.6-1.6,4.6-1.7c0.7-0.4,1.4-0.9,2.1-1.4
										c7-5.3,10.1-15.6,11-19.4c0.3-0.6,0.4-1,0.5-1.4c1.1-4.1,2.6-21.5,2.6-21.5v0c0.7-3.4,1.4-7.3,2.1-11.9
										C189.1,47.2,188.1,36.5,186.4,29.5z"/>
								</g>
								<g id="rear_layer15">
									<path fill="#FFFFFF" d="M243.1,897.2c-4.4-8.7-11.9-34.6-6.3-106.2c2.4-30.9-2.3-54.8-7.9-78.4c-3-12.9-5.9-25-6.7-37.5
										c-1.7-25.1,0.4-36.5,2.6-48.6c1.2-6.5,2.4-13.3,3.2-23.3c1.6-21.3,2-41.1,1.3-58.9l0-1.1l-0.1,0l-0.2-4.1
										c-0.2-3.4-0.4-6.8-0.7-10.1c5.5-2.7,9.4-5.7,11.6-8.8c0.1,1.2,0.3,2.5,0.4,3.8l0.1,1.4c2.1,22.6,2.1,48.1-0.1,77.7
										c-0.8,10.6-2.1,17.7-3.3,24.6c-2.1,11.8-4,22-2.4,45.5c0.8,11.4,3.5,23.1,6.4,35.4c5.3,22.7,11.3,48.4,8.2,82.6
										c-4.8,51.5-6.8,76.2-6.6,95.7c0,0.2,0,0.3,0.1,0.5C242.8,890.7,242.9,894,243.1,897.2z"/>
								</g>
								<g id="rear_layer22">
									<path fill="#FFFFFF" d="M221.8,908.6c2-7.7,4.4-36.6-13.3-140.2c-0.1-0.4-7.8-33-6.8-65.8c0.3-9.9-3.4-17.8-7.3-26.3
										c-4-8.7-6.7-18-8.9-30.2c-2.7-15.7-7.7-46.3-11-75c-0.8-7-2.5-20.5-4.1-32.4l-0.1-0.7c3.9,0.6,8.2,0.9,12.7,0.9
										c5,0,10.3-0.4,15.6-1.1c3.9-0.5,7.7-1.2,11.4-2.1l0,0c4.4-1,9.7-2.9,16-5.4c0.3,3.1,0.5,6.2,0.7,9.3l0.4,6.2l0,0
										c0.7,17.4,0.2,36.7-1.3,57.5c-0.7,9.9-2,16.6-3.1,23c-2.2,12.3-4.3,23.8-2.6,49.2c0.9,12.6,3.7,24.9,6.7,37.8
										c5.5,23.5,10.2,47.1,7.8,77.8c-2.9,37.2-2.7,66.5,0.7,87.1c2.3,13.7,5.4,20.2,7.3,23L221.8,908.6z"/>
								</g>
								<g id="rear_layer14">
									<path fill="#FFFFFF" d="M220.2,905.1c-0.7-3.9-1.8-10.5-3.3-18.6c-0.1-0.6-13.1-61.3-19.1-94.1c-1-5.3-3.1-14.3-4.9-21.6
										c-1.1-4.4-2.1-8.8-2.2-9.7l0-0.1l0-0.1c-0.1-0.2-6-22.4-4.9-59.7c0.2-6.5-2.3-12.9-5.5-21c-3.3-8.5-7.4-19.1-10-33.8
										c-4.9-28.4-10-66.6-13.8-94.4l-0.1-0.6c-0.8-5.6-1.4-10.7-2.1-15.2c-0.1-1.1-0.3-2.1-0.4-3.2c0-0.5-0.1-1-0.1-1.4
										c2.9,2.6,7.7,4.6,14.1,5.8l0.2,1.9c1.5,11.7,3.2,25,4,31.9c3.3,28.7,8.3,59.4,11,75.1c2.1,12.4,4.9,21.9,9,30.8
										c3.8,8.2,7.4,16,7.1,25.3c-1,33.1,6.8,66,6.9,66.4C222.5,863.4,221.8,895,220.2,905.1z"/>
								</g>
								<g id="rear_layer13">
									<path fill="#FFFFFF" d="M66.5,905.1c-1.5-10.1-2.2-41.7,14-136.5c0.1-0.3,7.8-33.2,6.8-66.3c-0.3-9.3,3.3-17.1,7.1-25.3
										c4.1-8.9,6.9-18.4,9-30.8c2.7-15.7,7.8-46.3,11-75.1c0.8-6.8,2.5-20.2,4-31.9l0.2-1.9c6.3-1.2,11-3.1,13.9-5.6
										c-0.2,2.2-0.3,3.8-0.3,4c-2,17.4-4.5,35-6.9,52.1l-1.3,9.2c-0.6,4.2-1.1,8.5-1.7,12.8l0,0.2c-2,15.1-4,30.7-7.8,45.6
										c-1.3,5.1-3.3,10.1-5.3,14.8c-1.9,4.5-3.8,9.2-5.2,14.1c-0.7,2.5-1.2,5.1-1.7,7.8c-1,3.1-1.4,5.8-1.3,8.5c0,1.3,0.1,2.6,0.1,3.9
										c-0.2,3.8-0.3,7.7-0.3,10.7l0,3.4c0,2.3,0,4.6-0.1,6.9c-0.1,0.9-0.1,1.9-0.2,2.8l0,0.6c-0.1,0.9-0.1,1.8-0.2,2.6l0,0.4
										c-0.1,0.8-0.1,1.5-0.2,2.3l0,0.5c-0.1,0.8-0.2,1.6-0.2,2.4l-0.1,0.5c-0.1,0.7-0.2,1.5-0.2,2.2l0,0.2c-0.1,0.7-0.2,1.5-0.3,2.2
										l-0.1,0.5c-0.1,0.7-0.2,1.4-0.3,2l-0.3,2.4c-0.1,0.6-0.3,2.2-0.3,2.2c-0.1,0.5-0.3,1.7-0.3,1.7c-0.1,0.5-0.3,1.9-0.3,1.9
										c-0.1,0.5-0.3,1.5-0.3,1.5l-0.3,1.4L97,757c-0.1,0.7-0.3,1.4-0.4,1.9c-0.3,1-0.5,1.6-0.6,1.8l-0.1,0.2l0,0.1c0,0.2-0.1,0.6-0.1,1.1
										l0,0.1c-0.2,0.9-0.5,2-0.8,3.4l-1.1,4.7c-1.8,7.3-3.9,16.4-4.9,21.6c-1.4,7.8-3.3,17.7-5.7,29.6c-1,4.7-2,9.4-3,14l-0.5,2.1
										c-3.7,17.3-6.6,31.4-9.1,44.4c0,0-1,4.4-1,4.4C68.3,894.6,67.2,901.1,66.5,905.1z"/>
								</g>
								<g id="rear_layer23">
									<path fill="#FFFFFF" d="M44,900.9c1.9-2.8,5.1-9.3,7.3-23c3.4-20.6,3.7-49.9,0.7-87.1c-2.4-30.6,2.3-54.2,7.8-77.7
										c3-13,5.9-25.2,6.8-37.9c1.7-25.4-0.4-36.9-2.6-49.2c-1.2-6.5-2.4-13.2-3.1-23c-1.6-20.9-2-40.4-1.3-57.8l0.1-0.7l0.3-5.2
										c0.2-3.1,0.4-6.2,0.7-9.3c6.3,2.5,11.7,4.4,16,5.4l0,0c3.7,0.9,7.5,1.6,11.4,2.1c5.3,0.7,10.5,1.1,15.6,1.1c4.5,0,8.8-0.3,12.7-0.9
										l-0.1,1c-1.5,11.8-3.2,25.2-4,32.2c-3.3,28.7-8.3,59.3-11,75C99,658,96.2,667.3,92.2,676c-3.9,8.5-7.6,16.4-7.3,26.3
										c1,32.8-6.7,65.5-6.8,65.8C66.5,836,63.7,871,63.4,888.5c-0.2,11.3,0.6,17,1.3,19.9L44,900.9z"/>
								</g>
								<g id="rear_layer12">
									<path fill="#FFFFFF" d="M43.6,897.2c0.2-3.2,0.4-6.5,0.5-9.9c0-0.1,0.1-0.3,0.1-0.5c0.1-19.5-1.8-44.2-6.6-95.7
										c-3.2-34.1,2.8-59.9,8.2-82.5c2.9-12.4,5.6-24,6.4-35.4c1.6-23.5-0.3-33.7-2.4-45.5c-1.2-6.9-2.5-14-3.3-24.6
										c-2.2-29.6-2.3-55-0.1-77.7l0.1-1.4c0.1-1.3,0.3-2.5,0.4-3.8c2.2,3.1,6.1,6.1,11.6,8.8c-0.3,3.3-0.5,6.7-0.7,10.1l-0.2,4.1l0,0
										l-0.1,1.1c-0.7,17.7-0.3,37.5,1.3,58.9c0.7,10,2,16.7,3.2,23.3c2.2,12.1,4.3,23.6,2.6,48.6c-0.8,12.4-3.7,24.6-6.7,37.5
										c-5.5,23.7-10.3,47.5-7.9,78.4C55.5,862.6,48,888.5,43.6,897.2z"/>
								</g>
								<g id="rear_layer8">
									<path fill="#FFFFFF" d="M103.7,536.3c-4.9,0-10.1-0.4-15.3-1.1c-3.8-0.5-7.5-1.2-11.2-2.1c-0.1,0-0.2-0.1-0.3-0.1
										c-15.6-3.8-27.4-10.4-29.5-16.6c0.3-2.4,0.6-4.8,1-7.2l0-0.1l0.4-2.8c0.1-0.8,0.4-2.4,0.4-2.4c0.1-0.7,0.4-2.5,0.4-2.5l0.4-2.1
										l0.4-2c0.2-1,0.4-2,0.6-3l0.1-0.6c0.2-0.9,0.4-1.8,0.6-2.7l0.1-0.4c0.4-1.9,0.8-3.7,1.2-5.4l0.5-2.3c1.1-4.9,2-8.7,2.4-12
										c2.1-15.4,2.7-23.3,4.1-40.6l0.3-4.1c0.2-2.4,0.5-5,1-7.7c1.3,0.7,2.7,1.3,4.2,1.9c0.4,0.2,0.8,0.3,1.3,0.5
										c0.4,0.1,0.7,0.3,1.1,0.4c0.6,0.2,1.2,0.4,1.9,0.6l0.6,0.2c0.7,0.2,1.4,0.4,2.1,0.6l0.4,0.1c2.1,0.6,6.7,1.3,11.9,2.2
										c7.1,1.2,15.9,2.6,18.4,3.7c11,4.6,14.2,11.5,14.4,11.8l0.1,0.2c4.7,9,8.8,16.7,25.4,17.6l0.1,0l0.1,0c16.4-1,20.4-8.7,24.9-17.5
										l0.2-0.5c0.2-0.4,3.3-7.4,14.3-12c2.5-1.1,11.3-2.6,18.4-3.8c5.3-0.9,9.8-1.7,11.9-2.3l0.4-0.1c0.7-0.2,1.4-0.4,2.1-0.6l0.6-0.2
										c0.6-0.2,1.3-0.4,1.9-0.6c0.4-0.1,0.8-0.3,1.1-0.4c0.5-0.2,0.9-0.3,1.3-0.5c0.5-0.2,1-0.4,1.5-0.6c1.3-0.4,2.5-0.8,3.6-1.1
										c0.5,2.9,0.9,5.7,1.1,8.4l0.3,4c1.4,17.3,2,25.3,4.1,40.7c0.5,3.4,1.3,7.2,2.4,12.1l1.1,5l0.6,2.8l0.6,3c0,0,0.5,2.5,0.6,3.1
										l0.3,1.5c0.1,0.6,0.2,1.2,0.3,1.8l0.3,1.6c0.1,0.6,0.2,1.3,0.3,1.9l0.3,1.7c0.1,0.7,0.4,2.6,0.4,2.6l0,0.3c0,0,0.2,1.6,0.3,2.1
										l0.1,1c0.2,1.5,0.4,3.1,0.6,4.8c-1.8,6.3-13.7,13.1-29.7,17c-3.7,0.9-7.5,1.6-11.3,2.1c-5.2,0.7-10.3,1.1-15.3,1.1c0,0,0,0,0,0
										c-14.9,0-26.4-3.4-29.5-8.6l-0.2-0.3l-0.5-0.2c-0.4-0.1-0.8-0.3-1.1-0.4l-0.3-0.1c-1.1-0.4-2.2-0.7-3-1.2l-0.3-0.2L143,526
										l-5.3-0.6l-0.3,0.2c-0.8,0.4-1.6,0.8-2.4,1.2c-0.3,0.1-0.7,0.3-1,0.4l-0.3,0.1l-0.4,0.1l-0.2,0.4
										C130.1,532.9,118.5,536.3,103.7,536.3z"/>
									<g opacity="5.000000e-02">
										<path fill="#020202" d="M172.7,529.7c-3.5,0-6.9-0.1-10.4-0.6c-3.4-0.4-6.8-1.1-10-2.3c-0.8-0.3-1.6-0.6-2.4-0.9
											c-0.8-0.3-1.5-0.7-2.3-1.1c-0.7-0.4-1.5-0.8-2.2-1.3c-0.7-0.4-1.3-0.9-1.9-1.4c-0.1-1.1-0.1-2.3-0.2-3.4c-0.1-1.2-0.2-2.5-0.3-3.7
											c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,1.2-0.2,2.5-0.3,3.7c-0.1,1.1-0.1,2.3-0.2,3.4c-0.6,0.5-1.2,1-1.9,1.4c-0.7,0.5-1.4,0.9-2.2,1.3
											c-0.7,0.4-1.5,0.8-2.3,1.1c-0.8,0.4-1.6,0.6-2.4,0.9c-3.2,1.1-6.6,1.8-10,2.3c-3.4,0.4-6.9,0.6-10.4,0.6c-3.5,0-7-0.2-10.4-0.6
											c1.7,0.5,3.4,0.8,5.2,1.1c1.7,0.3,3.5,0.5,5.2,0.6c1.8,0.1,3.5,0.2,5.3,0.2c1.8,0,3.5-0.1,5.3-0.3c3.5-0.4,7-1.1,10.4-2.3
											c0.8-0.3,1.7-0.7,2.5-1.1c0.8-0.4,1.6-0.8,2.4-1.3c0.8-0.5,1.5-1,2.2-1.5c0.6-0.4,1.1-0.9,1.7-1.4c0.5,0.5,1.1,1,1.7,1.4
											c0.7,0.6,1.4,1.1,2.2,1.5c0.8,0.5,1.6,0.9,2.4,1.3c0.8,0.4,1.6,0.8,2.5,1.1c3.4,1.3,6.9,2,10.4,2.3c1.8,0.2,3.5,0.3,5.3,0.3
											c1.8,0,3.5-0.1,5.3-0.2c1.8-0.1,3.5-0.3,5.2-0.6c1.7-0.3,3.5-0.6,5.2-1.1C179.7,529.5,176.2,529.6,172.7,529.7z"/>
									</g>
								</g>
								<g id="rear_layer11">
									<path fill="#FFFFFF" d="M304.5,472.6c-1.2-3.3-2.7-7.7-3.1-9.6c-0.1-0.6-0.4-2.5-0.8-6c-0.6-4.3-1.4-10.8-2.9-20.8l-3.8-26.8
										c-3.3-23.2-11.6-40-15.6-48.1c-1.8-3.7-2.8-5.6-3-7.8l0-0.3l0-0.2c0-0.2-1.4-24.5-1.4-28.6c0-24.6-2.1-38.5-3.4-47.7
										c-0.6-4.1-1.1-7.4-1.2-10.3c-0.1-1.8-0.2-3.2-0.3-4.6c-0.5-5.7-0.7-10.1,0.9-24l0.1-0.2c2.3-1,4.3-2.4,5.9-4
										c0.6-0.6,1.1-1.2,1.6-1.7c0,2.3-0.1,4.5-0.4,6.5c-1.7,14.3-1.4,18.2-1,22.6c0.1,1.5,0.3,3.1,0.3,5.1c0.1,2.5,0.5,5.6,1.1,9.5
										c1.4,9.5,3.5,23.7,3.5,48.8c0,3.9,1.3,28,1.3,28.3c0.1,1,0.8,2.4,2.3,5.5c4.2,8.4,12.8,26,16.2,50.3l3.8,26.6
										c3.4,24.2,3.9,27.3,3.8,28.2c0,0.2,0,0.4,0.1,0.5c0.8,2.5,1.6,4.6,2.3,6.6L304.5,472.6z"/>
								</g>
								<g id="rear_layer21">
									<path fill="#FFFFFF" d="M294.9,476.1c-1.6-7.6-3.1-13.2-4.4-16.8l-0.1-0.2l-0.1-0.3c-9.1-20.3-24.2-56.2-25.9-66.4
										c-3-18.9-7-29.4-10.2-38c-2.1-5.5-3.5-9.4-4.2-14.1c-0.7-5.1-1.9-11.8-3.1-19l0-0.3c-2.7-15.7-9.6-63.6-6.4-80.4
										c3.4-0.1,15.4-0.3,17.8-0.4c3.4-0.2,6.6-0.8,9.4-1.7c-1.5,13.3-1.3,17.7-0.8,23.4c0.1,1.5,0.3,2.8,0.3,4.6
										c0.1,3.1,0.6,6.4,1.2,10.6c1.3,9.2,3.4,23,3.4,47.4c0,4.1,1.3,27.7,1.4,28.7l0,0.3l0,0.4c0.3,2.6,1.3,4.6,3.2,8.6
										c3.9,8,12.1,24.6,15.4,47.4l3.8,26.8c1.4,10,2.3,16.5,2.9,20.8c0.5,3.8,0.7,5.5,0.9,6.2c0.4,2,1.9,6.5,3.2,9.8L294.9,476.1z"/>
								</g>
								<g id="rear_layer10">
									<g>
										<path fill="#FFFFFF" d="M285.4,479.1c-1-5.2-2.5-12.1-4.3-16.5c-0.3-0.6-24.2-53.9-26.6-68.9c-2.9-17.9-6.6-28-9.7-36.2
											c-2.2-5.8-3.9-10.4-4.7-16.1c-0.7-4.8-1.7-10.9-2.8-17.5c-2.6-15.7-5.9-35.1-6.1-47.5c0-0.2,0-0.3-0.1-0.4l0.1-0.6l5.3-34.9l1.5,0
											c-3,17.6,3.8,65.1,6.5,80.8l0,0.3c1.2,7.1,2.4,13.8,3.1,18.9c0.7,4.9,2.2,9,4.3,14.5c3.2,8.5,7.1,19,10.1,37.6
											c1.7,10.4,16.8,46.6,26,67l0.2,0.4c1.4,3.5,2.8,9.1,4.4,16.7L285.4,479.1z"/>
										<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M230.2,278.3c0.4,12.5,3.5,30.9,6,45.9
											c1.1,6.6,2.1,12.7,2.8,17.5c0.8,5.9,2.5,10.5,4.7,16.3c3,8.1,6.8,18.1,9.6,35.9c2.1,12.9,19.5,52.9,25,65.3
											c-5.7-18.4-24.8-75.5-32.6-116.2C238.1,302.9,232,283.4,230.2,278.3z"/>
									</g>
								</g>
								<g id="rear_layer19">
									<path fill="#FFFFFF" d="M174.9,430.4c0.1-3.5,0.1-6,0.1-6c2.9-106.6,22.5-175,25-183.5c0,0,13-0.1,16.9-0.1l8,0l3.6,0l0.5,0l5.4,0
										l-5.3,34.5c-0.5,0.2-0.9,0.6-1,1.2c-0.9,4.5-1.3,5.8-2,7.6c-0.4,1.1-0.8,2.4-1.4,4.6c-0.4,1.3-0.9,3-1.6,5.2l0,0.1
										c-1.5,4.9-3.9,12.3-5.9,20.7c-2,7.8-3.3,15.1-4.1,21.8c-0.6,5.7-0.9,11.1-0.6,15.9c0.2,3.4,0.7,7.6,1.3,12.1
										c1,7.3,2.1,15.7,1.7,22.4l0,0.3l0.1,0.3c0,0,1.7,3.5,3.8,9c1.3,3.5,3.7,10.6,5.5,19.5c-0.1,0-0.3,0.1-0.5,0.1l-0.9,0.3l0,0
										c-0.8,0.3-1.6,0.5-2.4,0.7l-0.1,0l-0.1,0c-0.5,0.2-1,0.4-1.5,0.6c-0.4,0.2-0.8,0.3-1.3,0.5c-0.4,0.1-0.7,0.3-1.1,0.4
										c-0.6,0.2-1.2,0.4-1.8,0.6l-0.6,0.2c-0.6,0.2-1.3,0.4-2,0.6l-0.4,0.1c-2,0.5-6.7,1.4-11.7,2.2c-7.9,1.4-16.1,2.8-18.9,4
										C179.5,427.5,177.1,428.9,174.9,430.4z"/>
								</g>
								<g id="rear_layer24">
									<path fill="#FFFFFF" d="M143.4,455.8c-15.3-0.8-18.7-7.3-23.5-16.4l-0.1-0.2c-0.2-0.5-1.7-3.6-5.7-7c0-2.8-0.1-5.2-0.1-7.3l0,0
										l0.1-1.2c-0.1-2.6-0.2-5.2-0.2-7.8l0-1l0,0c-3.6-99.5-21.5-164-24.7-174.7c12.4,0,25.9,0.1,39.6,0.1l21.3,0l18.3,0
										c10.2,0,20.1,0,29.4-0.1c-3.1,10.4-22.2,78.6-25,183.6c0,0-0.1,3.2-0.2,7.5c-4.3,3.6-5.9,6.9-6.2,7.6l-0.2,0.4
										C161.8,448.4,158.5,454.9,143.4,455.8z"/>
								</g>
								<g id="rear_layer18">
									<path fill="none" d="M70.2,387L70.2,387L70.2,387z"/>
									<path fill="#FFFFFF" d="M64.2,406.5l-0.3,1l0,0c-0.1,0.5-0.2,0.8-0.3,1.2c-0.8,2.8-0.8,2.9-1.7,7.6c1.4,0.7,2.9,1.4,4.6,2.1
										c0.4,0.2,0.8,0.3,1.3,0.5c0.4,0.1,0.7,0.3,1.1,0.4c0.6,0.2,1.2,0.4,1.8,0.6l0.6,0.2c0.6,0.2,1.3,0.4,2,0.6l0.2,0.1l0.2,0
										c2,0.5,6.7,1.3,11.7,2.1c7.9,1.3,16.2,2.7,18.9,3.8c2.8,1.2,5.3,2.5,7.6,4.2c0-2.6-0.1-4.8-0.1-6.7l0-1.2l0.1,0
										c-0.1-2.2-0.1-4.4-0.2-6.5v0l0-0.1C108,314.2,89.3,248.9,86.8,240.7l-9.3,0c-3.1,0-6,0-8.9,0l-2.2,0l-0.3,0l-0.2,0l-0.3,0l-0.3,0
										l-0.4,0l-0.4,0l-0.3,0l-0.3,0l-0.3,0l-0.3,0l-0.8,0l-0.2,0l-0.2,0l-0.2,0c-3.4,0-6.5,0-9.5-0.1l4.9,31.9c0.4,0.2,0.6,0.4,0.8,0.8
										l0.1,0.1l0,0.1l0.4,1.4l0.1,0.3c0.9,3.3,2.8,10.2,3.7,13.2c0.4,1.2,0.9,2.9,1.6,5.1c3.3,10.6,10.2,32.4,10.7,52.6
										c0,2.1,0,4.1-0.1,6c0,0.7-0.1,1.4-0.1,2.2c-0.1,0.9-0.2,1.8-0.3,2.8c-0.2,2.2-0.5,4.5-0.9,7.2c-1,7.3-2,15.6-1.7,22.3
										c0,0.3,0,0.6-0.2,0.8l-1.1-0.4l0,0l1,0.5C70.8,388.3,67.2,396.1,64.2,406.5z"/>
								</g>
								<g id="rear_layer7">
									<g>
										<path fill="#FFFFFF" d="M22.3,481.2c0.2-5.5,0.8-11.9,1.8-19.2c2.4-16.9,7.2-35.4,9.8-45.4c1.1-4.2,1.6-6.2,1.7-7
											c1.7-16.1,1.8-27.3,1.9-36.3c0.1-7,0.1-13,0.9-19.3c0.4-3.8,1.1-7.6,1.8-12.1l0-0.1c1-6,2.1-12.8,3.1-21.3
											c0.6-5.6,1.5-11.4,2.4-17.1l0-0.1c1.4-9.1,5.9-45.8,3.5-62.8l0.9,0l5,32.8c-0.1,0.2-0.1,0.4-0.1,0.6c-0.2,11.7-1.7,24.5-3.2,36.8
											c-0.4,3.6-0.9,7.2-1.3,10.6c-1,8.7-2.1,15.6-3.2,21.7c-0.7,4.5-1.4,8.3-1.8,11.8c-0.7,5.9-0.7,11.8-0.8,18.5
											c-0.1,9.2-0.2,20.6-1.9,37c-0.5,4.5-5,18.8-9.7,33.9c-3.9,12.4-7.9,25.1-9.8,32.9C22.9,478.6,22.6,479.9,22.3,481.2z"/>
										<path opacity="5.000000e-02" fill="#020202" enableBackground="new    " d="M37.6,374.3c0,0,4.6,32,3.7,47.2
											c1.4-5,2.4-8.9,2.6-10.9c1.7-16.5,1.9-27.9,1.9-37.1c0-4.6,0.1-8.8,0.3-12.9c-1.5,3.1-4.2,8.5-5.8,10.3
											C38.7,372.7,37.6,374.3,37.6,374.3z"/>
									</g>
								</g>
								<g id="rear_layer20">
									<path fill="#FFFFFF" d="M4.2,495.3c0.9-4.8,2.1-11.3,3.1-17.5c2.8-17.2,3.2-27.6,3.8-43.5c0.1-2.7,0.2-5.5,0.3-8.6
										c1.2-29.4,2.9-50.2,5.2-63.7c0.2-0.9,0.3-1.6,0.3-2.1l0.3-2.6c0.7-6.3,0.9-7.4,0.3-11.9c-0.3-2.2-0.7-6-1.3-15.1
										c-1.6-25.8,0.7-43.6,2-54.2c0.5-4,0.9-7.1,1-9.7c0.1-1.8,0.2-3.1,0.3-4.5c0.5-5.7,0.7-10.1-0.8-23.3l0.2,0c0.5,0.2,1,0.3,1.5,0.4
										l0.1,0c1.1,0.3,2.2,0.5,3.3,0.7l0.3,0c0.5,0.1,1,0.1,1.5,0.2l0.5,0c0.6,0.1,1.2,0.1,1.9,0.1c0.6,0,1.4,0.1,2.4,0.1
										c2.3,0.1,5.6,0.1,10,0.2l6.6,0.1c2.5,16.6-2.1,53.4-3.5,62.5l0,0.1c-0.9,5.7-1.8,11.6-2.4,17.2c-0.9,8.4-2.1,15.2-3.1,21.2l0,0.1
										c-0.8,4.5-1.4,8.4-1.8,12.2c-0.7,6.4-0.8,12.5-0.9,19.5c-0.1,9-0.2,20.2-1.9,36.2c-0.1,0.6-0.8,3.3-1.6,6.7
										c-2.6,10-7.4,28.6-9.8,45.6c-1.9,13.6-2.1,24.4-1.9,31.4L4.2,495.3z"/>
								</g>
								<g id="rear_layer6">
									<path fill="#FFFFFF" d="M5.6,473.7c0.6-11,0.2-31.4-0.3-47.5c-1.5-44.7,1.3-60.6,2.2-65.9c0.1-0.7,0.2-1.2,0.2-1.5l0.3-2.6
										c0.7-6.2,0.7-6.3,0.3-9.7c-0.3-2.4-0.8-6.3-1.3-15.7c-1.6-26.6,0.7-45,2-56c0.5-3.7,0.8-6.7,0.9-8.8c0.1-2.1,0.2-3.6,0.3-5.1
										c0.4-4.5,0.7-8.3-1-22.7c-0.2-2-0.3-4.1-0.3-6.3c0.7,0.9,1.6,1.9,2.8,2.8c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.3,0.6,0.5
										c1,0.7,2.2,1.3,3.4,1.8c1.7,13.9,1.5,18.4,1,24c-0.1,1.5-0.3,2.9-0.3,4.7c-0.1,2.5-0.5,5.5-0.9,9.4c-1.3,10.8-3.6,28.7-2,54.7
										c0.6,9.3,1,13,1.3,15.3c0.5,4.2,0.4,5-0.3,11.3l-0.3,2.6c0,0.4-0.2,1.1-0.3,2c-2.3,13.5-4.1,34.4-5.3,64c-0.1,3.1-0.2,5.9-0.3,8.6
										C8.3,448.7,7.9,458.7,5.6,473.7z"/>
								</g>
								<g id="rear_layer5">
									<path fill="#FFFFFF" d="M128.8,238.5c-14.3,0-28.3,0-41.2-0.1l-10.1,0c-3.1,0-15.6-0.1-15.6-0.1L50,238.2l-7.6-0.1l-2.1,0
										c-4.3-0.1-7.7-0.1-10-0.2c-1,0-1.8-0.1-2.3-0.1c-0.6,0-1.2-0.1-1.8-0.1l-0.4,0c-0.5-0.1-0.9-0.1-1.4-0.2l-0.3,0
										c-1.1-0.2-2.1-0.4-3.1-0.6l-0.1,0c-0.5-0.1-0.9-0.3-1.3-0.4l-0.2,0c-0.4-0.1-0.9-0.3-1.3-0.5c-1.4-0.6-2.7-1.2-3.9-2
										c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.1-0.3-0.3-0.5-0.4c-2.3-1.8-3.5-3.8-4.1-5.2c1-17.2,8.5-39.7,21-49.4c7.9-6.2,17-6.9,20.6-6.9
										c1.1,0,1.8,0.1,1.9,0.1l0.2,0c0.2,0,0.4,0,0.6-0.1c0.3-0.1,0.7-0.3,1.1-0.5c4.1,0.3,12,1.7,27,6.2c20.1,6,50.5,9.4,61.2,9.6l0.6,0
										l0.6,0c10.6-0.2,41-3.6,61.2-9.6c16.3-4.9,24.2-6,27.6-6.2c0.4,0.2,0.8,0.4,1.2,0.5c0.2,0.1,0.4,0.1,0.6,0.1h0.1l0.1,0
										c0.1,0,0.8-0.1,1.9-0.1c3.5,0,12.7,0.7,20.6,6.9c9,7.1,19.3,25.4,20.6,49c-0.4,1-1.3,2.7-3,4.4c-1.5,1.5-3.4,2.7-5.5,3.7
										c-3,1.3-6.6,2.1-10.7,2.4c-2.7,0.1-9.3,0.3-19.6,0.4c-3.1,0-6.5,0.1-10.2,0.1c-3.6,0-7.5,0.1-11.5,0.1c-3.9,0-7.9,0-12.1,0.1
										l-6.8,0c-9.3,0-19.2,0.1-29.4,0.1l-19.4,0l-6.1,0L128.8,238.5z"/>
								</g>
								<g id="rear_layer4">
									<path fill="#FFFFFF" d="M160.1,112.7c2.2-0.6,4-0.9,4.8-1l3.1-0.4l-0.3-0.2c0.9-0.3,1.7-0.7,2.2-1v16.8c0,0.2,0.2,15.5,14.2,20.8
										l0.2,0.1c5.5,2.1,16.8,7.8,27.7,13.3c6.1,3.1,12,6.1,16.4,8.2c-4.8,0.7-12.3,2.3-24.2,5.9c-12.3,3.7-27.5,6.2-34.4,7.1L160.1,112.7
										z"/>
								</g>
								<g id="rear_layer17">
									<path fill="#FFFFFF" d="M142.7,184.6c-5-0.1-13.8-0.9-22.8-2.1c-0.2,0-0.5-0.1-0.9-0.1l9.7-69.3c3,0.9,5.6,2.1,7.8,3.4
										c2,1.2,3.7,3.4,4.9,4.9c1,1.3,1.5,1.8,2.1,2l0.3,0.1l0.3-0.1c0.6-0.2,1.1-0.7,2.1-2c1.2-1.5,3-3.7,4.9-4.9c1.9-1.2,4.3-2.3,6.9-3.1
										l9.7,69c-9.5,1.3-18.6,2.1-23.9,2.2l-0.5,0L142.7,184.6z"/>
								</g>
								<g id="rear_layer16">
									<path fill="#FFFFFF" d="M116.6,182.2c-6.8-1-22.2-3.5-34.5-7.1c-11.6-3.4-19-5.1-23.8-5.8c4.4-2.1,10.3-5.1,16.5-8.2
										c10.9-5.5,22.2-11.3,27.7-13.3c0,0,0.3-0.1,0.3-0.1l-0.1,0c14.1-5.3,14.3-20.6,14.3-20.8v-16.9c0,0,0.1,0,0.1,0.1
										c0.4,0.3,2.5,1.5,5.8,1.8c0.6,0.1,1.8,0.3,3.4,0.7L116.6,182.2z"/>
								</g>
						</svg>
					</div>
				</Slider>
			</React.Fragment>
		)

	}

    renderMatriz = () => {

        return (
            <React.Fragment> 
				{ this.props.gender === 'female' ? this.renderFemaleMatriz() : this.renderMaleMatriz() }
			</React.Fragment>
        )

    }

    render() {

        return (
            <React.Fragment>
               <div className="wrapper-shape">
                    {this.renderMatriz()}
                </div>
            </React.Fragment>
        );

    }
    
};

export default Matriz;