import React from 'react';
import ExtraTopBar from '../../components/navbar/extraTopBar';
import GradientNav from '../../components/navbar/gradient';
import { Link } from 'react-router-dom';
import './legal.scss';
import SubMenu from '../../components/navbar/submenu';
import TopBar from '../../components/navbar/topbar';
import I18n from '../../../i18n';
import Footer from '../../components/footer';

class LegalAdvice extends React.Component {

    gotoLogin = () => {

        this.props.history.push(`/auth/login`);
        
    };

    render() {

        return (
            <React.Fragment>
                {!localStorage.getItem('jwt') && <ExtraTopBar onClick={ this.gotoLogin } className="register" hasButton={true}/>}
                {localStorage.getItem('jwt') && <GradientNav active="" {...this.props} /> }
                <div className={"main-container " + (!localStorage.getItem('jwt') ? 'hasExtraTopBar' : '')}>
                    {localStorage.getItem('jwt') && <TopBar callback={() => this.props.history.goBack()} {...this.props} text={I18n.t('menu.legal')}/>}
                    <div className="inner-container">
                        <h1>Aviso Legal</h1>
                        <p>Lea cuidadosamente este documento (en adelante, el “Aviso Legal”). El mismo, regula el acceso, navegación y utilización del sitio yogabot.app  (en adelante, el “Sitio Web”) y la APP.</p>
                        <p>El Sitio Web y/o la APP pone al alcance del usuario una plataforma dedicada a la práctica de actividades deportivas y de bienestar, entre las que destaca principalmente la práctica de yoga. De esta manera, a través del Sitio Web y/o la APP el usuario podrá mejorar su práctica, profundizar en su aprendizaje y recibir información para perfeccionar su técnica en la práctica de esta disciplina.</p>
                        <p>El presente Sitio Web y/o la APP es titularidad de NEXOYOGA,S.L. Para su información, a continuación se recogen los datos identificativos de la sociedad:</p>
                        <p>Denominación social: NEXOYOGA, S.L. (en adelante, “Yogabot”).</p>
                        <p>NIF: B76704923</p>
                        <p>Dirección:Calle Vereda del Aire, 15</p>
                        <p>Correo electrónico: nexo@nexoyoga.com</p>
                        <p>Datos de inscripción en el Registro Mercantil: 26 Mayo del 2016. Tomo: 3442 Folio: 130</p>
                        <p>El acceso, navegación y utilización del Sitio Web y/o la APP por parte del usuario, implica la aceptación expresa y sin reservas de todos los términos del presente Aviso Legal, teniendo la misma validez y eficacia que cualquier contrato celebrado por escrito y firmado.</p>
                        <p>Su observancia y cumplimiento será exigible respecto de cualquier persona que acceda, navegue o utilice el Sitio Web y/o la APP. Si usted no está de acuerdo con los términos y condiciones aquí expuestos, no debe acceder, navegar o utilizar el Sitio Web y/o la APP.</p>
                        <h1>1.Objeto y ámbito de aplicación</h1>
                        <p>El presente documento regula el acceso, navegación y utilización del Sitio Web y/o la APP titularidad de YOGABOT y gestionado por dicha entidad. No obstante, YOGABOT se reserva el derecho a modificar la presentación, configuración y contenido del Sitio Web y/o la APP, así como las condiciones requeridas para su acceso y/o utilización. El acceso y utilización de los contenidos del Sitio Web y/o la APP así como de  los servicios prestados a través del mismo tras la entrada en vigor de sus modificaciones o cambios suponen la aceptación de los mismos.</p>
                        
                        <h1>2.Acceso y registro</h1>
                        <p>El acceso al Sitio Web y/o a la APP y a algunos de sus contenidos tiene carácter gratuito y no requiere registro. No obstante, para poder acceder y utilizar determinados servicios sí será necesario que el usuario se registre, pudiendo serle solicitada información personal a tal fin. En caso de registro, la contraseña, personal e intransferible, deberá ser generada por el usuario de acuerdo con las reglas de robustez y complejidad que se establezcan en cada momento por YOGABOT. El Sitio Web y/o la APP disponen de las funcionalidades necesarias para que el usuario pueda cambiar su contraseña cuando lo considere oportuno, por ejemplo, cuando sospeche o constate que se haya producido una quiebra de la confidencialidad de la contraseña.</p>
                        <p>Queda prohibido el acceso al Sitio Web y/o a la APP por parte de menores de edad, salvo que cuenten con la autorización previa y expresa de sus padres, tutores o representantes legales, los cuales serán considerados como responsables de los actos que lleven a cabo los menores a su cargo, conforme a la normativa vigente. En todo caso, se presumirá que el acceso realizado por un menor al Sitio Web y/o la APP, se ha realizado con autorización previa y expresa de sus padres, tutores o representantes legales.</p>
                        <h1>Derechos de propiedad intelectual e industrial</h1>

                        <p>YOGABOT es titular o, en su caso, cuenta con las licencias correspondientes sobre los derechos de explotación de propiedad intelectual e industrial del Sitio Web y las aplicaciones móviles publicadas bajo su nombre, así como de todos los contenidos ofrecidos en el mismo, incluyendo la propia plataforma, textos, fotografías o ilustraciones, logos, marcas, grafismos, diseños, interfaces, o cualquier otra información o contenido, y los servicios disponibles a través del mismo.</p>
                        <p>En ningún caso se entenderá que el acceso, navegación y utilización del Sitio Web y/o la APP por parte del usuario implica una renuncia, transmisión, licencia o cesión total o parcial de cualquier derecho que corresponda a YOGABOT. El usuario únicamente dispone de un derecho de uso de los contenidos y/o servicios del Sitio Web y/o la APP, dentro de un ámbito estrictamente particular y únicamente con la finalidad de disfrutar de las prestaciones del Sitio Web y/o la APP.</p>
                        <p>Las referencias a marcas o nombres comerciales registrados, u otros signos distintivos, ya sean titularidad de YOGABOT o de terceras empresas, llevan implícita la prohibición sobre su uso sin el consentimiento de sus legítimos titulares. En ningún momento, salvo manifestación expresa en contrario, el acceso, navegación o utilización del Sitio Web y/o de sus contenidos y/o servicios así como de la APP, le confiere al usuario derecho alguno sobre signos distintivos en ellos incluidos.</p>
                        <h1>4.Licencia sobre los contenidos</h1>

                        <p>En el caso de que el usuario envíe información o contenidos de cualquier tipo a través del Sitio Web y/o la APP, el usuario declara, garantiza y acepta que tiene derecho a hacerlo libremente, que dicha información no infringe ningún derecho de propiedad intelectual, industrial, secreto comercial o cualesquiera otros derechos de terceros, y que dicha información no tiene carácter confidencial ni es perjudicial para terceros.</p>
                        <p>El usuario reconoce asumir la responsabilidad y mantendrá indemne a YOGABOT por cualquier comunicación que suministre personalmente o a su nombre, alcanzando dicha responsabilidad sin restricción alguna la exactitud, legalidad, originalidad y titularidad de la misma.
                        </p>

                        <h1>5.Enlaces</h1>

                        <p><b>5.1. Enlaces a otras páginas web.</b></p>
                        <p>En caso de que el Sitio Web y/o la APP contengan enlaces a otras páginas web mediante diferentes botones, links, banners, etc., éstos estarán en todo caso gestionados por terceros. YOGABOT no tiene facultad ni medios humanos ni técnicos para conocer, controlar ni aprobar toda la información, contenidos, productos o servicios facilitados por otros sitios web a los que se puedan establecer enlaces desde el Sitio Web y/o la APP.</p>
                        <p>En consecuencia, YOGABOT no podrá asumir ningún tipo de responsabilidad por cualquier aspecto relativo a la página web enlazada desde el Sitio Web y/o la APP, en concreto, a título enunciativo y no limitativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos, en general.
                        </p>
                        <p>En este sentido, si los usuarios tuvieran conocimiento efectivo de la ilicitud de actividades desarrolladas a través de estas páginas web de terceros, deberán comunicarlo inmediatamente a YOGABOT a los efectos de que se proceda a deshabilitar el enlace de acceso a las mismas.
                        </p>
                        <p>El establecimiento de cualquier tipo de enlace desde el Sitio Web y/o la APP a otra página web ajena no implicará que exista algún tipo de relación, colaboración o dependencia entre YOGABOT  y el responsable de la página web ajena.
                        </p>
                        <p><b>5.2. Enlaces a los canales de YOGABOT en entornos ajenos (medios sociales).</b>
                        </p>
                        <p>YOGABOT podría poner a disposición de los usuarios, a través de diferentes herramientas medios de enlace que permiten a los usuarios acceder a los canales y páginas del Servicio en diferentes medios sociales pertenecientes y/o gestionadas por terceros. La instalación de estas aplicaciones en el Sitio Web y/o la APP tiene por único objeto facilitar a los usuarios el acceso a dichos canales en las diferentes redes sociales.
                        </p>
                        <p>El establecimiento de estas aplicaciones no implica la existencia de relación alguna entre  YOGABOT y el propietario, fabricante o distribuidor de la página web enlazada, como tampoco la aceptación y aprobación por parte de YOGABOT  de sus contenidos y/o servicios, siendo su propietario, fabricante o distribuidor el único responsable de los mismos.
                        </p>
                        <p>Debido a que YOGABOT  no tiene control alguno sobre el contenido alojado en dichos canales, el usuario reconoce y acepta que ésta no asume responsabilidad alguna por el contenido ni por los servicios a los que el usuario pueda acceder en dichas páginas ni por cualquier contenido, productos, servicios, publicidad, ni cualquier otro material disponible en los mismos. Por tal motivo, el usuario debe extremar la prudencia en la valoración y utilización de la información, contenidos y servicios existentes en los canales enlazados, y sobre la información propia o de terceros que quiera compartir en dichos canales.
                        </p>
                        <p><b>5.3. Enlaces en otras páginas web con destino al Sitio Web.</b>
                        </p>
                        <p>YOGABOT no autoriza el establecimiento de un enlace al Sitio Web y/o la APP desde aquellas páginas web que contengan materiales, información o contenidos ilícitos, ilegales, degradantes, obscenos y, en general, que contravengan las leyes, la moral, el orden público o las normas sociales generalmente aceptadas.
                        </p>
                        <p>YOGABOT no tiene facultad ni medios humanos y técnicos para conocer, controlar ni aprobar toda la información, contenidos, productos o servicios facilitados por otros sitios web que tengan establecidos enlaces con destino al Sitio Web y/o la APP.
                        </p>
                        <p>YOGABOT no asume ningún tipo de responsabilidad por cualquier aspecto relativo al sitio web que establece ese enlace con destino al Sitio Web, en concreto, a título enunciativo y no limitativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos, en general.
                        </p>

                        <h1>6.Reglas de uso de YOGABOT</h1>

                        <p>No está permitido y, por tanto, serán responsabilidad exclusiva del usuario, las consecuencias derivadas del acceso o la utilización del Sitio Web y/o la APP con fines ilegales o no autorizados, con o sin finalidad económica y, más específicamente y sin que el siguiente listado tenga carácter absoluto, queda prohibido:
                        </p>
                        <p>Usar el Sitio Web y/o la APP en forma alguna que pueda provocar daños, interrupciones, ineficiencias o defectos en su funcionalidad o en el ordenador de un tercero; 
                        </p>
                        <p>Usar el Sitio Web y/o la APP para transmitir o publicar cualquier material de carácter difamatorio, ofensivo, racista, vulgar, denigrante, pornográfico, o de naturaleza obscena o amenazadora o que pueda causar molestia a cualquier persona;
                        </p>
                        <p>Usar el Sitio Web y/o la APP para transmitir material con fines publicitarios o de promoción, incluidos spam, correos electrónicos en cadena o similares;
                        </p>
                        <p>Usar el Sitio Web y/o la APP para recoger datos de carácter personal de otros usuarios sin contar con el consentimiento expreso de los mismos; 
                        </p>
                        <p>Acceder al Sitio Web y/o la APP con una identidad falsa, suplantando a terceros o utilizando un perfil o realizando cualquier otra acción que pueda confundir a otros usuarios sobre la identidad del origen de un mensaje; 
                        </p>
                        <p>Quebrantar, o intentar quebrantar, las medidas de seguridad o autenticación del Sitio Web y/o la APP o de cualquier red conectada a la misma, o las medidas de seguridad o protección inherentes a los contenidos y servicios ofrecidos en el Sitio Web y/o la APP; 
                        </p>
                        <p>Llevar a cabo alguna acción que provoque una saturación desproporcionada o innecesaria en la infraestructura del Sitio Web y/o la APP o en los sistemas o redes de YOGABOT, así como en los sistemas y redes conectados al Sitio Web; 
                        </p>
                        <p>En caso de incumplimiento por parte del usuario de cualquiera de las anteriores obligaciones, YOGABOT podrá adoptar las medidas necesarias, pudiendo llegar a la eliminación o bloqueo de la cuenta desde la que se ha producido la infracción.
                        </p>

                        <h1>7.Responsabilidades y garantías</h1>

                        <p>YOGABOT no será responsable en caso de indisponibilidad del servicio cuando ello sea por causa de fuerza mayor, datos facilitados por el usuario. Sin embargo, en dichos casos, YOGABOT se pondrá inmediatamente en contacto con el usuario a fin de encontrar la mejor solución al caso.
                        </p>
                        <p>En consecuencia, YOGABOT no garantiza ni se hace responsable de: (i) la continuidad de los contenidos y/o de la información del Sitio Web y la APP; (ii) la ausencia de errores en dichos contenidos y/o información; (iii) la ausencia de virus y/o demás componentes dañinos en el Sitio Web y/o en el servidor que lo suministra; (iv) la invulnerabilidad del Sitio Web y/o la APP y/o la inexpugnabilidad de las medidas de seguridad que se adopten en el mismo; (v) la falta de utilidad o rendimiento de los contenidos del Sitio Web y/o la APP; y (vi) los daños o perjuicios que cause, a sí mismo o a un tercero, cualquier persona que infringiera las condiciones, normas e instrucciones que  YOGABOT  establece en el Sitio Web y/o la APP o a través de la vulneración de los sistemas de seguridad establecidos.
                        </p>
                        <p>No obstante, YOGABOT declara que ha adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la técnica, para garantizar el funcionamiento del Sitio Web y/o la APP y evitar la existencia y transmisión de virus y demás componentes dañinos a los sistemas informáticos de los usuarios.
                        </p>
                        <p>En cualquier caso, YOGABOT empleará todos los esfuerzos comercial y técnicamente a su alcance para mantener disponibles sus servicios a través del Sitio Web y/o la APP que constituye una obligación que, no obstante, no será de aplicación para cualquier falta de disponibilidad o de rendimiento. En todos los casos ajenos al control y la diligencia debida por YOGABOT , no habrá lugar a indemnización de YOGABOT al usuario por lucro cesante, daños o perjuicios.
                        </p>
                        <p>En caso de cierre o suspensión del Sitio Web y/o la APP por causas ajenas a la actuación de las partes, se informará puntualmente al usuario del traslado del servicio a un nuevo dominio, modificándose únicamente las estipulaciones del presente contrato en lo relativo al dominio en que el Servicio permanece activo.
                        </p>
                        <p>Si el usuario tuviera conocimiento de la existencia de algún contenido ilícito, ilegal, contrario a las leyes o que pudiera suponer una infracción de derechos de propiedad intelectual o industrial, de la normativa aplicable en materia de protección de datos personales y/o cualquier otro derecho, deberá notificarlo inmediatamente a YOGABOT para que ésta pueda proceder a la adopción de las medidas oportunas.
                        </p>
                        <p>YOGABOT no será responsable de los daños y perjuicios de toda naturaleza derivados de la utilización del Sitio Web y/o la APP por terceros o por los usuarios de los servicios ofrecidos a través de la misma.
                        </p>

                        <h1>8.Política de privacidad</h1>

                        <p>El tratamiento de datos personales derivado de la utilización del Sitio Web y/o la APP  se encuentra sujeto a lo dispuesto en la <Link to="/legal/privacy">política de privacidad del Servicio</Link>.</p>

                        <h1>9.Generales</h1>

                        <p>YOGABOT podrá modificar los términos y condiciones aquí estipulados, total o parcialmente, publicando cualquier cambio en la misma forma en que aparece este Aviso Legal o a través de cualquier tipo de comunicación dirigida a los usuarios. No obstante lo anterior, cuando así lo exija la normativa, se respetarán los términos aplicables a cualquier servicio que estuviese en proceso de ejecución.
                        </p>
                        <p>Los encabezamientos de las distintas cláusulas son sólo informativos, y no afectarán, calificarán o ampliarán la interpretación del presente documento.
                        </p>
                        <p>En el caso de que cualquier disposición o disposiciones de este Aviso Legal fueran consideradas nulas o inaplicables, en su totalidad o en parte, por cualquier Juzgado, Tribunal u órgano administrativo competente, dicha nulidad o inaplicación no afectará a las otras disposiciones del presente documento.
                        </p>
                        <p>El no ejercicio o ejecución por parte de YOGABOT de cualquier derecho o disposición contenida en el presente documento no constituirá una renuncia al mismo, salvo reconocimiento y acuerdo por escrito por su parte.
                        </p>

                        <h1>10.Ley aplicable y jurisdicción competente</h1>

                        <p>La normativa imperativa vigente determinará las leyes que deban regir y la jurisdicción que deba conocer de las relaciones entre YOGABOT  y los usuarios. Ello no obstante, en aquellos casos en los que dicha normativa vigente prevea la posibilidad para las partes de someterse a un fuero determinado, YOGABOT y los usuarios, con renuncia expresa a cualquier otro fuero que pudiera corresponderles se someten a la legislación española y a los Juzgados y Tribunales de San Cristóbal de La Laguna (Tenerife).
                        </p>
                        <p>Asimismo, la Comisión Europea facilita una plataforma online de resolución de litigios, que se encuentra disponible en el siguiente enlace: http://ec.europa.eu/consumers/odr/, a través del cual se podrán tramitar reclamaciones de contrataciones realizadas online.
                        </p>
                        <p>Última actualización: 11 de diciembre de 2017.
                        </p>
                        <p>© 2020 NEXOYOGA, S.L. Todos los derechos reservados.</p>

                    </div>
                    <Footer {...this.props} type="branded"/>
                </div>
                {localStorage.getItem('jwt') && <SubMenu active="" {...this.props}  />}
            </React.Fragment>
        );

    }

}
export default LegalAdvice;