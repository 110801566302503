import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import ChallengeHeader from '../../../components/header';
import ProgressIndicator from '../../../../../components/progressIndicator';
import GuideOptionMatrixElement from '../../../../../components/guide/matrix';

import { getRole } from '../../../../../../redux-store/role';

import { FrameBackIcon, Frame } from '../../styles';
import { Container, InnerContainer } from '../../../styles';

import I18n from '../../../../../../i18n';

 class GuideOptionMatrix extends Component {
    
    render() {

        const { bioMetricMatrix} = this.props;

        return (
            <Container>
                <InnerContainer>
                    <ChallengeHeader {...this.props} />
                    <FrameBackIcon>
                        <div className="i-back notHide" onClick={()=> this.props.history.push('/mode/grow/guide')}><Icon name="angle left" /><span>{I18n.t('actions.back')}</span></div>
                    </FrameBackIcon>
                    <Frame style={{paddingTop: '0'}}>
                        <ProgressIndicator progress={ bioMetricMatrix.progress || 0 } title={ I18n.t('steps.step2.title') } progressText={ I18n.t('steps.step2.progress.matrix', { progress: bioMetricMatrix.progress || 0 }) } />
                    </Frame>
                    <Frame>
                        <GuideOptionMatrixElement />
                    </Frame>
                </InnerContainer>
            </Container>
        )
    }

}

const mapStateToProps = state => {

    return {
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        auth: state.auth,
        role: _.cloneDeep(_.get(state,'role', {})),
        
    };
};

export default connect(mapStateToProps, {getRole})(GuideOptionMatrix);