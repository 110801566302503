import styled from 'styled-components/macro';

const ExperienceGrid = styled.div`

    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    @media (min-width: 800px) {
        flex-direction:row;
        flex-wrap:nowrap;
    }
    margin-top:4rem;
`;

const ExperienceItem = styled.div`

    border-radius:10px;
    background-color:var(--light-grey-4);
    overflow:hidden;
    flex:0 1 100%;
    min-width:0;
    padding:2rem;
    margin:0 0 1rem 0;
    @media (min-width: 800px) {
        flex:0 1 100%;
        margin:0;
        & + & {
            margin:0 0 0 1rem;
        }
    }

`;

const ExperienceStep = styled.p`
    color: var(--brand-secondary);
    font-family: var(--font-primary);
    line-height: 1;
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    @media (min-width: 800px) {
        
        font-size: 4rem;
    }
`;

const CustomPracticeGrid = styled.div`

    display:flex;
    flex-direction:column-reverse;
    position:relative;
    @media (min-width: 800px) {
        padding-top:2rem;
        padding-bottom:2rem;
        flex-direction:row;
        
    }
`;

const CustomPracticeImage = styled.div`
    flex: 0 0 40%;
    min-width:0;
    
    img {
        max-width: 100%;
        width:100%;
        height:auto;
        display:block;
    }
    @media (min-width: 800px) {
        transform: translateX(-7rem) translateY(-2rem);
        position:relative;
        z-index:1;
        flex: 0 0 calc(40% + 7rem);
        
    }
`;
const CustomPracticeText = styled.div`
    background-color:#fff;
    padding:2rem 0;
    @media (min-width: 800px) {
        transform: translateY(2rem);
        position:relative;
        z-index:2;
        flex: 0 0 60%;
        padding:2rem 2rem 2rem 0;
    }
    .heading-2 {
        line-height:1.4;
    }
`;

export {
    ExperienceGrid,
    ExperienceItem,
    ExperienceStep,
    CustomPracticeGrid,
    CustomPracticeImage,
    CustomPracticeText
}