// Third party libraries
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Icon, List } from 'semantic-ui-react';

// Styled components
import { FrameClose, FrameModal, ModalContent, Content } from '../../styles/genericModal';
import OButton from '../../../../styled/components/button';

// Locales
import I18n from '../../../../../i18n';

// Styles
import './index.scss';

const InjuriesModal = ({ open, getInjuryText, injuries, injuriesSelected, onFinish, scrolling = false, title }) => {

    const [injuriesList, setInjuriesList] = useState(injuriesSelected);
    const [injuriesLevel1Options, setInjuriesLevel1Options] = useState([]);
    const [injuriesLevel2Options, setInjuriesLevel2Options] = useState([]);
    const [injuriesLevel3Options, setInjuriesLevel3Options] = useState([]);
    const [injuriesLevel1SelectedOption, setInjuriesLevel1SelectedOption] = useState(0);
    const [injuriesLevel2SelectedOption, setInjuriesLevel2SelectedOption] = useState(undefined);
    const [injuriesLevel3SelectedOption, setInjuriesLevel3SelectedOption] = useState(undefined);

    useEffect(() => {

        const newInjuriesLevel1Options = [{ key: 0, value: 0, text: I18n.t('profile.none') }];
        _.each(_.get(injuries, 'datas'), item => newInjuriesLevel1Options.push({ key: item.value, value: item.value, text: I18n.t(`profile.injuriesDescriptions.${ item.value }.label`) }));
        setInjuriesLevel1Options(newInjuriesLevel1Options);

    }, [open]);

    useEffect(() => {

        let newInjuriesLevel2Options = [];

        if (injuriesLevel1SelectedOption !== 0) {

            const injurieSelected = _.find(injuries.datas, injurie => injurie.value === injuriesLevel1SelectedOption);
            if (!_.isEmpty(injurieSelected.childs || [])) {

                _.each(injurieSelected.childs, item => newInjuriesLevel2Options.push({
                    key: item.value,
                    value: item.value,
                    text: I18n.t(`profile.injuriesDescriptions.${ injuriesLevel1SelectedOption }.childs.${ item.value }.label`)
                }));

            }

        }
        setInjuriesLevel2Options(newInjuriesLevel2Options);

    }, [injuriesLevel1SelectedOption]);

    useEffect(() => {

        let newInjuries2Selected = undefined;

        if (!_.isEmpty(injuriesLevel2Options)) {

            newInjuries2Selected = _.first(injuriesLevel2Options).value;

        }

        setInjuriesLevel2SelectedOption(newInjuries2Selected);

    }, [injuriesLevel2Options]);

    useEffect(() => {

        let newInjuriesLevel3Options = [];

        if (injuriesLevel2SelectedOption) {

            const injurie1Selected = _.find(injuries.datas, injurie => injurie.value === injuriesLevel1SelectedOption);
            const injurie2Selected = _.find(injurie1Selected.childs, injurie => injurie.value === injuriesLevel2SelectedOption);
            if (!_.isEmpty(injurie2Selected.childs || [])) {

                _.each(injurie2Selected.childs, item => newInjuriesLevel3Options.push({
                    key: item.value,
                    value: item.value,
                    text: I18n.t(`profile.injuriesDescriptions.${ injuriesLevel1SelectedOption }.childs.${ injuriesLevel2SelectedOption }.childs.${ item.value }`)
                }));

            }

        }

        setInjuriesLevel3Options(newInjuriesLevel3Options);

    }, [injuriesLevel2SelectedOption]);

    useEffect(() => {

        let newInjuries3Selected = undefined;

        if (!_.isEmpty(injuriesLevel3Options)) {

            newInjuries3Selected = _.first(injuriesLevel3Options).value;

        }

        setInjuriesLevel3SelectedOption(newInjuries3Selected);

    }, [injuriesLevel3Options]);

    useEffect(() => setInjuriesLevel1SelectedOption(0), [injuriesList]);

    const onSubmit = op => {

        if (injuriesLevel1SelectedOption !== 0) {

            const newInjuriesList = [ ...injuriesList ];

            newInjuriesList.push(injuriesLevel1SelectedOption + '.' + injuriesLevel2SelectedOption + (!!!injuriesLevel3SelectedOption ? '' : '.' + injuriesLevel3SelectedOption));

            setInjuriesList(_.uniq(newInjuriesList));

            if (op === 2) {

                onFinish(newInjuriesList);

            }

        }

    };

    return (
        <FrameModal>
            <FrameClose>
                <Icon name="close" size="large" onClick={ () => onFinish(injuriesList) }/>
            </FrameClose>
            <ModalContent>
                <Content scroll={ scrolling || false }>
                    <p className={ title.classes.join(" ") } >{ title.text }</p>
                    <Form name='profileObjectivesForm' noValidate onSubmit={ onSubmit }>
                        <Form.Field >
                            <Dropdown onChange={ (e, { value }) => setInjuriesLevel1SelectedOption(value) }
                                options={ injuriesLevel1Options }
                                value={ injuriesLevel1SelectedOption }
                                selection
                                fluid
                            />
                        </Form.Field>
                        <Form.Field >
                            <Dropdown onChange={ (e, { value }) => setInjuriesLevel2SelectedOption(value) }
                                options={ injuriesLevel2Options }
                                value={ injuriesLevel2SelectedOption }
                                selection
                                fluid
                                placeholder={ I18n.t('profile.selectFirstTypeOfInjury') }
                                disabled={ _.size(injuriesLevel2Options) === 0 }
                            />
                        </Form.Field>
                        <Form.Field >
                            <Dropdown onChange={ (e, { value }) => setInjuriesLevel3SelectedOption(value) }
                                options={ injuriesLevel3Options }
                                value={ injuriesLevel3SelectedOption }
                                selection
                                fluid
                                placeholder={ I18n.t(injuriesLevel2SelectedOption === undefined ? 'profile.selectFirstSubtypeOfInjury' : 'profile.nothingToAdd') }
                                disabled={ _.size(injuriesLevel3Options) === 0 }
                            />
                        </Form.Field>
                        <OButton
                            upper
                            className='addInjuryButton'
                            type='submit'
                            color='#1bb89f'
                            terciary
                            onClick={ () => onSubmit(1) }
                            disabled={ injuriesLevel1SelectedOption === 0 || !!!injuriesLevel2SelectedOption || (_.size(injuriesLevel3Options) > 0 && !!!injuriesLevel3SelectedOption) }
                        >
                            <span>{ I18n.t('actions.add') }</span>
                        </OButton>
                        <OButton
                            upper
                            className='addInjuryButton'
                            type='submit'
                            color='#FFF'
                            primary
                            onClick={ () => onSubmit(2) }
                            disabled={ injuriesLevel1SelectedOption === 0 || !!!injuriesLevel2SelectedOption || (_.size(injuriesLevel3Options) > 0 && !!!injuriesLevel3SelectedOption) }
                        >
                            <span>{ I18n.t('profile.addAndClose') }</span>
                        </OButton>
                    </Form>
                    <div className='verticalPad'>
                        <div className='field label-primary injuryList'>
                            <label style={{ display: 'block', marginTop: '10px', marginBottom: '10px' }}>{ I18n.t('profile.injuries') }</label>
                            { _.isEmpty(injuriesList) ? I18n.t('profile.noneF') :
                                <List bulleted>
                                    { _.map(injuriesList, (injury, index) => getInjuryText(injury, index)) }
                                </List>
                            }
                        </div>
                    </div>
                </Content>
            </ModalContent>
        </FrameModal>
    );

}

export default InjuriesModal;