import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

const AccordionHeader = styled(motion.header)`
    background-color: var(--brand-primary);
    padding:1.5rem 4rem 1.5rem 1.5rem;
    border-radius:0.75rem;
    margin-bottom: 1.5rem;
    box-shadow: 0px 4px 11px rgb(0 0 0 / 25%);
    cursor:pointer;
    position:relative;
    h2 {
        margin-bottom:0;
    }
`;

const AccordionContent = styled(motion.section)`
   
    background-color: var(--brand-white);
    overflow: hidden;
    
`;

const AccordionText = styled(motion.section)`
    padding:1.5rem 1.5rem 2rem 1.5rem;
    transform-origin: top center;
`;

const AccordionIcon =  styled(motion.section)`
    position:absolute;
    right:0;
    top:0;
    width:4rem;
    display:flex;
    align-items:center;
    justify-content:center;
    bottom:0;
    i {
        margin:0;
        width:auto;
    }
    
`;

export {
    AccordionHeader,
    AccordionContent,
    AccordionText,
    AccordionIcon
}