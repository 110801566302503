// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';

// Locales
import I18n from '../../../../i18n';

// Styles
import './topbar.scss';

// Assets
import Logo from '../../../assets/img/logo_large.svg';

class TopBar extends Component {

    render() {

        const { products } = this.props;
        const itemsLength = _.filter(products, ({ added }) => added).length;

        return (
            <div className='top-bar'>
                <div className='content-top-bar'>
                    <div className='arrow-top-bar'>
                        { typeof this.props.callback === 'function' && <Icon name='angle left' onClick={ () => this.props.callback() }></Icon> }
                        { !_.get(this.props, 'invert', true) && typeof this.props.menuCallback === 'function' && <span className='hambuger-menu'><Icon name='bars' onClick={ this.props.menuCallback } size='large'/></span>}
                    </div>
                    <div className='logo-top-bar'>
                        {this.props.text ? <p>{this.props.text}</p> : <img src={Logo} alt='yogabot' />}
                    </div>
                    <div className='profile-top-bar'>
                        { _.get(this.props, 'showProfile', true) && <i className='f-icon-icono_14' onClick={ ()=> this.props.history.push(`/profile/account`)}></i> }
                        { _.get(this.props, 'invert', false) && typeof this.props.menuCallback === 'function' && <span className='hambuger-menu'><Icon name='bars' onClick={ this.props.menuCallback } size='large'/></span>}
                    </div>
                    { itemsLength === 0 ? <Popup
                            trigger={ <div className='basket-top-bar'><i className='f-icon-basket'></i></div> }
                            content={ <div className='basket-empty'>{ I18n.t('studio.emptyCart') }</div> }
                            position='bottom right'
                            on='click'
                            hideOnScroll
                        /> :
                        <div className='basket-top-bar' onClick={ () => this.props.history.push('/cart') }><i className='f-icon-basket'></i><span className='basket-budget'><p>{ itemsLength }</p></span></div>
                    }
                </div>
            </div>
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.cart);

export default connect(mapStateToProps)(TopBar)
