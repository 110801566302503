import styled, {css} from 'styled-components/macro';

const WrapperSteps = styled.div`

    background: rgba(166, 166, 166, 0.15);
    padding:1em;
    
`;

const WrapperStepsContent = styled.div`
    display:flex;
    justify-content:flex-start;
    margin:0 auto;
    max-width:1440px;
    width:100%;
    padding:0 30px;
`;

const CircleStep = styled.div`

    width:22px;
    height:22px;
    flex-shrink:0;
    background-color:#A4A6A5;
    border-radius:50%;
    margin:0.5rem;
    display:flex;
    i {
        width:auto;
        height:auto;
        margin:auto;
        color:#fff;
    }

    
`;

const BuySteps = styled.div`

    display:flex;
    flex-direction:column;
    align-items:center;
    & + & {
        margin-left:1rem;
    }

    ${({active}) => active && css`

        ${CircleStep} {
            background-color:#27948F;
            i {
                display:none;
            }
        }

        span {
            color: var(--brand-primary);
            font-family: var(--font-primary);
        }
    
    `};

    span {
        color:#A4A6A5;
        font-family: var(--font-secondary);
    }

`;

//correct/incorrect checkout page

const WalletResultWrapper = styled.div`

    display:flex;
    align-items:center;
    justify-content:center;
    padding: 0 30px;
    min-height:100%;
`;

const WalletResultBox = styled.div`
    width:100%;
    max-width: 558px;
    background:var(--brand-white);
    border-radius: 18px;
    overflow:hidden;
    padding:1rem;
    margin-top:10rem;
`;

const WalletResultBoxHeader = styled.div`

    display:flex;
    justify-content:center;
    align-items: center;
`;

const WalletResultBoxBody = styled.div`
    text-align:center;
`;

const WrapperTransaction = styled.div`
    width:100%;
    max-width:1110px;
    margin:0 auto;
    padding-bottom:3rem;
`;

const HistoryDetails = styled.div`
    padding:1rem;
    background:rgba(241,244,244,0.77);
    opacity:0;
    height:0;
    transition: height 0.5s linear;
    margin-top: -0.5rem;
`;

const HistoryInfo = styled.div`
    cursor:pointer;
    ${({$selectedRow}) => $selectedRow && css`
        ${HistoryDetails} {
            opacity:1;
            height:auto;
        }
    
    `};

`;

const HistoryRow = styled.div`
    display:flex;
    padding:1rem;
    flex-wrap:wrap;
    background:#fff;
    align-items:center;
    border-top: 1px solid #ECECEC;
    &:last-child {
        border-bottom: 1px solid #ECECEC;
    }
    margin-bottom:0.5rem;
    ${({isCancelled}) => isCancelled && css`background:#ffe4e1`};

`;

const HistoryActionIcon = styled.div`
    flex-shrink:0;
    width:30px;
    height:30px;
    background:#27948F;
    border-radius:50%;
    display:flex;
    i {
        margin:auto;
        height:auto;
        width:auto;
        &:before{
            color:#fff;
        }
    }
`;

const HistoryActionInfo = styled.div`
    padding:0 2rem;
    flex:1;
    p {
        margin-bottom:0;
    }
`;

const HistoryAmount = styled.div`
    margin-left:auto;
    padding-left:1rem;
    flex:0 1 auto;
    p {
        margin-bottom:0;
    }
`;

const HistoryActions = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:0.5rem;
`;

const FiltersWrapper = styled.div`
    width:100%;
    max-width:1110px;
    margin:0 auto;
`;

const LinkWrapper = styled.div`

    background: #EAEAEA;
    border-radius: 12px;
    padding:1rem;
    align-items:center;
    justify-content:space-between;
    flex-wrap:wrap;
    display:flex;
`;

const WalletSendWrapper = styled.div`

    max-width:30rem;
    width:100%;
    
`;

export{
    WrapperSteps,
    BuySteps,
    CircleStep,
    WrapperStepsContent,
    WalletResultWrapper,
    WalletResultBox,
    WalletResultBoxHeader,
    WalletResultBoxBody,
    WrapperTransaction,
    HistoryRow,
    HistoryActionIcon,
    HistoryActionInfo,
    HistoryAmount,
    HistoryActions,
    FiltersWrapper,
    HistoryDetails,
    HistoryInfo,
    LinkWrapper,
    WalletSendWrapper
}