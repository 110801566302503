import React from 'react';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import { FrameHeader, FrameContent, FrameBody, FrameTriangle, FrameAwards, Awards, AwardIcon, AwardText } from './styles';
import { FrameModal } from '../../styles/genericModal';
import OButton from '../../../../styled/components/button';
import I18n from '../../../../../i18n';


/***
 * * CHALLENGE COMPLETE MODAL
 * 
 * @param buttons Array Objects
 * @param onClose Function
 * @params @buttons { text, callback, options = { any button props } }
 * @description This modal handle when the user complete the challenge mode succefully
 */

export default function ChallengeCompletedModal({ buttons = [], onClose, ...restProps }) {

    return (
        <FrameModal>
            <FrameHeader>
                <FrameContent>
                    <Icon name="trophy" size="huge" />
                    <p className="heading-2 white upper" style={{margin: '20px 0 0 0'}}>{I18n.t('modals.challengeCompleteModal.title')}</p>
                    <p className="heading-2 white">{I18n.t('modals.challengeCompleteModal.subtitle')}</p>
                    <FrameAwards>
                        <Awards>
                            <AwardIcon>
                                <Icon name="check circle outline" size="big" />
                            </AwardIcon>
                            <AwardText>
                                <span className="caption white regular">{I18n.t('modals.challengeCompleteModal.firstAward')}</span>
                            </AwardText>
                        </Awards>
                        <Awards>
                            <AwardIcon>
                                <Icon name="check circle outline" size="big" />
                            </AwardIcon>
                            <AwardText>
                                <span className="caption white regular">{I18n.t('modals.challengeCompleteModal.secondAward')}</span>
                            </AwardText>
                        </Awards>
                    </FrameAwards>
                </FrameContent>
            </FrameHeader>
            <FrameTriangle />
            <FrameBody>
                <p className="paragraph t-center" dangerouslySetInnerHTML={{__html: I18n.t('modals.challengeCompleteModal.secondaryText')}}></p>
                {
                    _.map(buttons, ({text, callback, options }, index) => (
                        <OButton key={index} type="button" {...options} onClick={ callback }><span>{text}</span></OButton>
                    ))
                }
            </FrameBody>
        </FrameModal>
    )

}