import React from 'react';
import _ from 'lodash';
import { FrameCircle, FrameCirclePercent, CircleSvg, CircleShape, CircleNumber } from './styles';

function CircleProgress({percent, large = false, radius = 43}) {

 const CIRCUMFERENCE = (2 * Math.PI * radius);
 const PROGRESS = percent / 100;

  return !_.isNaN(percent) && (

        <FrameCircle>
            <FrameCirclePercent large={large}>
                <CircleSvg>
                    <CircleShape large={large} r={radius} cx={radius} cy={radius}></CircleShape>
                    <CircleShape r={radius} cx={radius} cy={radius} style={{stroke: getProgressColor(percent || 0) }} large={large} dashoffset={_.round(CIRCUMFERENCE)}>
                        <animate attributeType='XML' begin='1s' attributeName='stroke-dashoffset' from={_.round(CIRCUMFERENCE)} to={_.round(CIRCUMFERENCE) * (1 - PROGRESS)} dur='2s' fill='freeze'/>
                    </CircleShape>
                </CircleSvg>
                <CircleNumber ><span style={{color: getProgressColor(percent) }} className='heading-3'>{percent}%</span></CircleNumber>
            </FrameCirclePercent>
        </FrameCircle>

  )
}


function getProgressColor(percent) {

    if (percent<50) {

        return '#e20d0d';

    } else if (percent>=50 && percent<75) {

        return '#ffcf00';

    } else {

        return '#1FD5B9'
    }

}

export default CircleProgress;