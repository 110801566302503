import React, { Component, useState, useEffect, useRef } from 'react'
import Slider from "react-slick";
import { FrameGrid, FrameSlide, SlideContent, SliderArrows, FrameIndicator, FrameImageRatio, FrameImage, Image } from '../sharedStyles';
import { ContentBody, ContentFooter, ContentTitle, FrameLocked } from './styles';
import '../sharedStyles/styles.scss';

function PrevArrow(props) {
    const { onClick, className } = props;
    return (
        <SliderArrows type="button" className={"prev-a " + className} onClick={onClick}>
            <svg alt="" color="white" role="img" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" style={{height: '40px', width: '40px', zIndex: 'auto'}}>
            <path fill="#31425a" d="M22.324 28.008c.537.577.355 1.433-.326 1.809a1.44 1.44 0 0 1-.72.183c-.398 0-.786-.151-1.048-.438L10.06 18.588a1.126 1.126 0 0 1 0-1.555L20.233 6.09c.438-.468 1.198-.564 1.767-.25.681.377.863 1.23.325 1.808l-9.446 10.164 9.446 10.196zM11.112 17.615a.31.31 0 0 1 0 .391l.182-.195-.182-.196zM21.308 7.094c-.01-.006-.053 0-.029-.027a.07.07 0 0 0 .029.027zm-.025 21.499a.95.95 0 0 1-.006-.008l.006.008z"></path></svg>
        </SliderArrows>
    );
  }

  function NextArrow(props) {
    const { onClick , className } = props;
    return (
        <SliderArrows type="button" className={"next-a " + className} onClick={onClick}>
            <svg alt="" color="white" role="img" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" style={{height: '40px', width: '40px', zIndex: 'auto'}}>
            <path fill="#31425a" d="M13.065 7.65c-.538-.578-.355-1.433.325-1.81a1.44 1.44 0 0 1 .72-.182c.398 0 .786.15 1.048.437L25.327 17.07a1.126 1.126 0 0 1 0 1.555L15.155 29.568c-.438.468-1.198.563-1.767.25-.681-.377-.863-1.23-.325-1.809l9.446-10.164L13.065 7.65zm11.211 10.393a.31.31 0 0 1 0-.391l-.181.194.181.197zM14.081 28.564c.01.006.053 0 .028.027a.07.07 0 0 0-.028-.027zm.024-21.5a.95.95 0 0 1 .007.008l-.007-.007z" ></path></svg>
        </SliderArrows>
    );
  }
      
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    mobileFirst: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1590,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
  
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 995,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


function HandleType({ mainAction, mode, list, isAllowed, renderFooterItems, renderTitle, renderDescription, renderIndicator,  thumbnail, sliderToGo }) {

    /**
     * Handle swipe event provided from slick library
     * Avoid trigger click when the user is swipping/dragging
     */
    const sliderRef = useRef();
    const [swiped, onChangeSwiped] = useState(true);
    
    useEffect(() => {

        let timeout = null;
        clearInterval(timeout)
        swiped && (timeout = setTimeout(()=> {

            onChangeSwiped(false);

        },500))

        return () => clearInterval(timeout);
        
      }, [swiped]);


      useEffect(() => {

        if (sliderToGo && sliderToGo >-1 && sliderRef.current) {

          sliderRef.current.slickGoTo(sliderToGo);

        }
        
      }, [sliderToGo]);

    switch(mode) {

        case 'slider':

            return (
                
                <FrameGrid className="with-slider">
                    <Slider {...settings} onSwipe={() => onChangeSwiped(true)} ref={ sliderRef }>
                        {list.map((item, index) => (
                            <FrameSlide onClickCapture={ () => isAllowed(item) && !swiped && mainAction(item, index) } key={index}>
                                <SlideContent>
                                    {typeof renderIndicator === 'function' && <FrameIndicator zIndex={1} floating>
                                      <span className="paragraph upper">{ renderIndicator(item) }</span>
                                    </FrameIndicator>}
                                    <FrameLocked zIndex={2} visibled={typeof isAllowed === 'function' && !isAllowed(item)}>
                                        <div>
                                            <div><i className="f-icon-candado-cerrado"></i></div>
                                            {/* <div><span>{I18n.t('practice.premium')}</span></div> */}
                                        </div>
                                    </FrameLocked>
                                    <FrameImageRatio ratio={thumbnail.ratio && thumbnail.ratio }>
                                        <FrameImage>
                                            <Image src={item[thumbnail.attribute]} alt="" />
                                        </FrameImage>
                                    </FrameImageRatio>
                                    <ContentBody>
                                        { typeof renderTitle === 'function' && <ContentTitle className="card-title">{ renderTitle(item) }</ContentTitle> }
                                        { typeof renderDescription === 'function' &&  <p className="caption regular" style={{padding: '0 8px'}}>{ renderDescription(item) }</p> }
                                        { typeof renderFooterItems === 'function' &&  <ContentFooter className="card-footer">{ renderFooterItems(item) }</ContentFooter> }
                                    </ContentBody>
                                </SlideContent>
                            </FrameSlide>
                        ))}
                    </Slider>
                </FrameGrid>

            )

        default:

            return ''

    }

}


export default class SimpleCard extends Component {
   
    render() {

        return (
            <>
                <HandleType {...this.props}/>
            </>
        )
    }
}
