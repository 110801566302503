import _ from 'lodash';
import React from 'react';
import { Form, Input as SemanticInput, Label } from 'semantic-ui-react';

import { convertToNumeric, trimString } from '../../../../config/validations';

class CustomInput extends React.Component {

    onChange = (success, event) => {

        if (_.get(this.props, 'restrictions[0].trim', false) ) {

            event.target.value = trimString(event.target.value);

        }

        if (_.get(this.props, 'restrictions[0].canBeFloat', false) || _.get(this.props, 'restrictions[0].numeric', false)) {

            event.target.value = convertToNumeric(event.target.value, _.get(this.props, 'restrictions[0].canBeFloat', false));

        }

        success(event);

    }

    //!remember why onBlur was added commit: 8cc4fa95615768dadecb385cad09f3ed731379d9

    render() {

        return (
            <Form.Field className={ this.props.fieldClasses } error={ this.props.meta.touched && this.props.meta.invalid }>
                <label>{ this.props.label }</label>
                <SemanticInput
                    disabled={this.props.disabled || false}
                    { ...this.props.input }
                    placeholder={ this.props.placeholder }
                    // onBlur={ e => this.onChange(this.props.input.onChange, e) }
                    onChange={ e => this.onChange(this.props.input.onChange, e) }
                    type={ this.props.type || 'text'} />
                { this.props.meta.touched && this.props.meta.invalid && <Label basic color={ this.props.colorLabel || 'red' } pointing>{ this.props.meta.error }</Label> }
            </Form.Field>
        );

    }

}

export default CustomInput;