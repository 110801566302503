// Third party libraries
import _ from 'lodash';
import React from 'react';
import { Field, initialize, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Dropdown, Form, Icon, List } from 'semantic-ui-react';

// Models
import SecurityModel from '../../../../data/models/security/security';

// Redux
import { closeGenericModal, openGenericModal } from '../../../../redux-store/genericModal';
import { openToast } from '../../../../redux-store/toast';
import { updateProfile } from '../../../../redux-store/auth';

// Components
import sideBarProFileHoc from '../../../components/sidebar/profile';
import Footer from '../../../components/footer';
import CustomTextArea from '../../../components/form/textarea';
import TopBar from '../../../components/navbar/topbar';
import MenuProfile from '../../../components/menu/profile';

// Styled components
import OButton from '../../../styled/components/button';

// Locales
import I18n from '../../../../i18n';

// Styles
import '../profile_new.scss';

class UserSecurity extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            injuries: [],
            injuriesSelected: [],
            objectives: [],
            objectivesLevel1Options: [],
            objectivesLevel2Options: [],
            objectivesLevel1SelectedOption: 0,
            objectivesLevel2SelectedOption: undefined
        };

    }

    componentDidMount() {

        this.setFormInitialData();

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.userSession, this.props.userSession)) {

            this.setFormInitialData();

        }

    }

    setFormInitialData = async () => {

        const injuries = await SecurityModel.getListData('userInjuries');
        const objectives = await SecurityModel.getListData('userObjectives');

        const objectivesLevel1Options = [{ key: 0, value: 0, text: I18n.t('profile.none') }];
        _.each(_.get(objectives, 'datas'), item => objectivesLevel1Options.push({ key: item.value, value: item.value, text: I18n.t(`profile.objectivesDescriptions.${ item.value }.label`) }));

        const userSessionPro = _.get(this.props, 'userSession.pro', {});

        let objectives1 = 0;
        let objectives2 = undefined;
        if (!_.isEmpty(userSessionPro.objective)) {

            if (userSessionPro.objective.includes('.')) {

                objectives1 = userSessionPro.objective.substring(0, userSessionPro.objective.indexOf('.'));
                objectives2 = userSessionPro.objective.substring(userSessionPro.objective.indexOf('.') + 1);
                this.onSelect('objectivesLevel1', objectives1);

            } else {

                objectives1 = userSessionPro.objective;

            }

        }

        this.setState({ injuries, objectives, objectivesLevel1Options, injuriesSelected: userSessionPro.injuries || [], objectivesLevel1SelectedOption: objectives1, objectivesLevel2SelectedOption: objectives2 });
        const initialValues = { objectives1, objectives2, observations: userSessionPro.observations };

        this.props.dispatch(initialize(
            'profileObjectivesForm',
            initialValues
        ));

    }

    onSelect = (selectId, value) => {

        const objectivesLevel2Options = [];
        let objectives1Selected = undefined;
        let objectives2Selected = undefined;

        const { objectives } = this.state;

        switch (selectId) {

            case 'objectivesLevel1':

                objectives1Selected = _.find(objectives.datas, objective => objective.value === value);
                if (!_.isEmpty(objectives1Selected) && !_.isEmpty(objectives1Selected.childs || [])) {

                    _.each(objectives1Selected.childs, item => objectivesLevel2Options.push({
                        key: item.value,
                        value: item.value,
                        text: I18n.t(`profile.objectivesDescriptions.${ value }.childs.${ item.value }`)
                    }));
                    objectives2Selected = _.first(objectivesLevel2Options).value;

                }

                this.setState({ objectivesLevel1SelectedOption: value, objectivesLevel2SelectedOption: objectives2Selected, objectivesLevel2Options });

                break;

            case 'objectivesLevel2':

                this.setState({ objectivesLevel2SelectedOption: value });
                break;

        }

    }

    onOpenAddInjuriesModal = () => {

        const { closeGenericModal, openGenericModal } = this.props;
        const { injuries, injuriesSelected } = this.state;

       openGenericModal({
            type: 'addInjuriesModal',
            size: 'small',
            scrolling: true,
            title: {
                text: I18n.t('profile.addInjuries'),
                classes: ['heading-2']
            },
            injuries,
            injuriesSelected,
            onFinish: injuriesSelectedModified => {

                this.setState({ injuriesSelected: injuriesSelectedModified });
                closeGenericModal();

            },
            getInjuryText: this.getInjuryText
        });

    }

    onSubmit = async () => {

        const { reduxFormState: { observations }} = this.props;
        const { injuriesSelected, objectivesLevel1SelectedOption, objectivesLevel2SelectedOption } = this.state

        try {

            const pro = {
                objective: objectivesLevel1SelectedOption === 0 ? undefined : objectivesLevel2SelectedOption === undefined ? objectivesLevel1SelectedOption : objectivesLevel1SelectedOption + '.' + objectivesLevel2SelectedOption,
                injuries: injuriesSelected,
                observations: observations
            };

            await this.props.updateProfile(_.merge(_.omit(_.get(this.props, 'userSession', {}), ['pro']), { pro }));

            openToast({ message: I18n.t('messages.dataSaved'), type: 'success' });

        } catch (errorCode) {

            openToast({ message: I18n.t(`status.code.${errorCode}`), type: 'error' });

        }

    }

    onEraseInjury = injury => {

        const { injuriesSelected } = this.state;
        injuriesSelected.splice(this.state.injuriesSelected.indexOf(injury), 1);
        this.setState({ injuriesSelected });

    };

    getInjuryText = (injury, index) => {

        const firstLevel = injury.substring(0, injury.indexOf('.'));
        let secondLevel = injury.substring(injury.indexOf('.') + 1);
        const hasThirdLevel = secondLevel.includes('.');
        let thirdLevel = '';
        if (hasThirdLevel) {

            thirdLevel = secondLevel.substring(secondLevel.indexOf('.') + 1);
            secondLevel = secondLevel.substring(0, secondLevel.indexOf('.'));

        }

        return <List.Item key={ index }>{ I18n.t(`profile.injuriesDescriptions.${ firstLevel }.label`) + ' - ' +
            I18n.t(`profile.injuriesDescriptions.${ firstLevel }.childs.${ secondLevel }.label`) +
            (hasThirdLevel ? ' - ' + I18n.t(`profile.injuriesDescriptions.${ firstLevel }.childs.${ secondLevel }.childs.${ thirdLevel }`) : '') }
            <Icon name='remove' style={{ marginLeft: '10px' }} onClick={ () => this.onEraseInjury(injury) } />
        </List.Item>;

    };

    render() {

        const { injuriesSelected, objectivesLevel1Options, objectivesLevel1SelectedOption, objectivesLevel2Options, objectivesLevel2SelectedOption } = this.state;

        return <div className='p-wrapper'>
            <TopBar { ...this.props } menuCallback={ () => this.props.onToggle() } showProfile={ false } text={ I18n.t('profile.securityEscaped') } invert={ true } />
            <div className='inner'>
                <div className='p-flex'>
                    <div className='p-aside'>
                        <MenuProfile active='objectives' { ...this.props } />
                    </div>
                    <div className='p-content'>
                        <div className='a-wrapper'>
                            <div className='a-f-wrapper'>
                                <p className="p-section-text">{I18n.t('menu.objectives')}</p>
                                <p dangerouslySetInnerHTML={{ __html: I18n.t('profile.objectivesText') }} />
                                <Form name='profileObjectivesForm' noValidate onSubmit={ this.props.handleSubmit(this.onSubmit) }>
                                    <div className='verticalPad'>
                                        <div className='field label-primary'>
                                            <label>{ I18n.t('profile.injuries') }</label>
                                            { _.isEmpty(injuriesSelected) ? I18n.t('profile.noneF') :
                                                <List bulleted>
                                                    { _.map(injuriesSelected, (injury, index) => this.getInjuryText(injury, index)) }
                                                </List>
                                            }
                                            <div style={{ marginTop: '10px' }}>
                                                <OButton upper type='button' color='#1bb89f' terciary onClick={ this.onOpenAddInjuriesModal } >
                                                    <span>{ I18n.t('actions.add') }</span>
                                                </OButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='verticalPad'>
                                        <Form.Field className='label-primary' >
                                            <label>{ I18n.t('profile.mainObjective') }</label>
                                            <Dropdown
                                                value={ objectivesLevel1SelectedOption }
                                                placeholder={ I18n.t('profile.mainObjective') }
                                                selection
                                                options={ objectivesLevel1Options }
                                                fluid
                                                onChange={ (e, { value }) => this.onSelect('objectivesLevel1', value) }
                                            />
                                        </Form.Field>
                                        <Form.Field className='label-primary' >
                                            <Dropdown
                                                value={ objectivesLevel2SelectedOption }
                                                placeholder={ I18n.t(objectivesLevel1SelectedOption === 0 ? 'profile.selectFirstTypeOfObjective' : 'profile.nothingToAdd') }
                                                selection
                                                options={ objectivesLevel2Options }
                                                fluid
                                                onChange={ (e, { value }) => this.onSelect('objectivesLevel2', value) }
                                                disabled={ _.size(objectivesLevel2Options) === 0 }
                                            />
                                        </Form.Field>
                                    </div>
                                    <div className='verticalPad'>
                                        <Field
                                            component={ CustomTextArea }
                                            placeholder={ I18n.t('profile.observations') }
                                            name='observations'
                                            fieldClasses='label-primary'
                                            label={ I18n.t('profile.observations') }
                                            type='text'
                                        />
                                    </div>
                                    <div className='verticalPad'>
                                        <OButton upper type='submit' color='#FFF' primary>
                                            <span>{ I18n.t('actions.save') }</span>
                                        </OButton>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer { ...this.props } type='branded' />
        </div>;

    }

}

const Wrapped = sideBarProFileHoc(UserSecurity, 'objectives');

const mapStateToProps = state => {

    return {
        reduxFormState: _.get(state, 'form.profileObjectivesForm.values', {})
    };

};

export default reduxForm({
    form: 'profileObjectivesForm',
    touchOnBlur: true,
    touchOnChange: false,
    enableReinitialize: true
})(connect(mapStateToProps, { closeGenericModal, openGenericModal, updateProfile })(Wrapped));