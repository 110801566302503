import styled from 'styled-components/macro';

const FrameContainer = styled.div`

    display:flex;
    width:100%;
    @media only screen and (max-width: 820px) {
        flex-direction:column-reverse;
    }
`;

const FrameIndicator = styled.div`

    flex-shrink:0;
    flex-grow:0;
    flex-basis:500px;
    align-self: center;
    @media only screen and (max-width: 820px) {
        flex-basis:auto;
    }
`;

const MatrixBodies = styled.div`

    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;

`;

export {
    FrameContainer,
    FrameIndicator,
    MatrixBodies
}