import _ from 'lodash';
import React from 'react';
import queryString from 'query-string';

import Onboarding from './components/onboarding';
import MainLanding from './components/mainLanding';

class Landing extends React.Component {

    constructor(props) {

        super(props);

        if (window.localStorage.getItem('onboarding') === 'viewed') {

            this.props.history.push('/auth/login');

        }

    }

    componentDidMount() {

        if (_.get(this.props, 'location.search', false)) {

            const UrlQueryStrings = this.props.location.search;
            const queryValues = queryString.parse(UrlQueryStrings);

            if (queryValues.ref) {

                localStorage.setItem('ref', queryValues.ref);

            }

            if (queryValues.pro) {

                localStorage.setItem('pro', queryValues.pro);

            }

        }

    }

    render() {

        return (
            <React.Fragment>
                { window.cordova ? <Onboarding { ...this.props } /> : <MainLanding { ...this.props } /> }
            </React.Fragment>
        );

    }

}
export default Landing;