import React from 'react';
import _ from 'lodash';
import './plansBlocks.scss';

class PlansBlocks extends React.Component {

    render() {

        return (
           <React.Fragment>
               <div className="plans-block-wrapper">
                   <div className="plan-block">
                       {_.get(this.props, 'showHeader', true) && <div className="plan-block-header">
                           <div className="title">¿POR QUÉ YOGABOT PREMIUM?</div>
                           <div className="price">Descubre todo lo que vas a disfrutar</div>
                       </div>}
                       <span className="question-text">¿Qué incluye premium?</span>
                       <div className="plan-block-body">
                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                                    <p>Autodiagnóstico corporal por Rangos de movilidad (actual)</p>
                               </div>
                           </div>

                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                                    <p>Autodiagnóstico corporal por análisis automático de imágenes para una mayor precisión (próximamente)</p>
                               </div>
                           </div>

                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                                <p>Podrás actualizar tu Matriz Biométrica y secuencia personalizada cada 7 días</p>
                               </div>
                           </div>

                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                                <p>Enviar tu Matriz Biomética a un profesor (próximamente)</p>
                               </div>
                           </div>

                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                               <p>Descargar tu secuencia personalizada en PDF (próximamente)</p>
                               </div>
                           </div>

                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                              <p>Practicar tu secuencia personalizada en videos</p>
                               </div>
                           </div>


                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                               <p>Podrás incluir un audio técnico para cada postura, en tu práctica (próximamente)</p>
                               </div>
                           </div>


                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                               <p>Adquirir videos de ejecución técnica de posturas a un precio preferente.</p>
                               </div>
                           </div>

                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                               <p>Acceso completo a contenido exclusivo de prácticas alternativas (ilimitado)</p>
                               </div>
                           </div>

                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                               <p>Practicar secuencias alternativas con video</p>
                               </div>
                           </div>

                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                               <p>Acceso al sistema de logros. Premiaremos tu esfuerzo y crecimiento (próximamente) </p>
                               </div>
                           </div>

                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                               <p>Sistema de Recompensa. Premiaremos tu evolución (próximamente) </p>
                               </div>
                           </div>
                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                                   <p>Sin anuncios</p>
                               </div>
                           </div>
                           <div className="rowed">
                               <div className="col col1">
                                   <i className="f-icon-icono_4"></i>
                               </div>
                               <div className="col">
                               <p>Y mucho más</p>
                               </div>
                           </div>
                       </div>
                   </div>
                </div>
           </React.Fragment>
        )

    }

}

export default PlansBlocks;