import styled, { css } from 'styled-components/macro';

export const ContainerDiscount = styled.span`
    position: relative;
    background-color: var(--brand-alarm);
    padding: 5px;
    z-index:2;
    display:inline-block;
    border-radius:5px;

    ${({floating}) => floating &&
        css`
            position: absolute;
            top: 8px;
            left: 8px;
            
    `};
    
`;

export const DiscountPriceElement = styled.div`
    margin-top:5px
`;