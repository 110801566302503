import _ from 'lodash';
import Moment from 'moment';

import Config from '../config';

const hasMonthSubscriptionPromotion = () => {

    if (Moment().startOf('days') > Moment('2020-07-31', 'YYYY-MM-DD').startOf('days')) {

        return false;

    }

    return true;

};

const hasYearSubscriptionPromotion = () => {

    if (Moment().startOf('days') > Moment('2021-01-15', 'YYYY-MM-DD').startOf('days')) {

        return false;

    }

    return true;

};

const price = {
    annualPayment: hasYearSubscriptionPromotion() ? { USD: 53.99, EUR: 48.99 } : { USD: 59.99, EUR: 53.99 },
    monthlyPayment: hasMonthSubscriptionPromotion() ? { USD: 7.99, EUR: 6.99 } : { USD: 9.99, EUR: 8.99 }
};

const cordovaPrices = Config.inapppurchase.premiumSubscription;

const hasStripeCouponPromotion = (type, couponCode = 'YBOT20OFF') => {

    const expireDay = {
        HAPPY2022: '2022-01-31',
        YBOT20OFF: '2022-12-31',
        YBOT50OFF: '2022-03-20',
        YOGASASTRA20: '2022-12-31'
    };

    if (Moment().startOf('days').isAfter(Moment(expireDay[couponCode], 'YYYY-MM-DD').startOf('days')) || (couponCode !== 'YOGASASTRA20' && type !== 'annualPayment')) {

        return false;

    } else {

        return true;

    };

};

const getStripeCouponPrices = () => {

    const isExpiredHAPPY2022 = !hasStripeCouponPromotion('annualPayment', 'HAPPY2022');
    const isExpiredYBOT20OFF = !hasStripeCouponPromotion('annualPayment', 'YBOT20OFF');
    const isExpiredYBOT50OFF = !hasStripeCouponPromotion('annualPayment', 'YBOT50OFF');
    const isExpiredYOGASASTRA20 = !hasStripeCouponPromotion('annualPayment', 'YOGASASTRA20');

    return {
        annualPayment: {
            HAPPY2022: {
                USD: isExpiredHAPPY2022 ? 0 : 5,
                EUR: isExpiredHAPPY2022 ? 0 : 4
            },
            YBOT20OFF: {
                USD: isExpiredYBOT20OFF ? 0 : _.round(price.annualPayment.USD * 0.2, 2),
                EUR: isExpiredYBOT20OFF ? 0 : _.round(price.annualPayment.EUR * 0.2, 2)
            },
            YBOT50OFF: {
                USD: isExpiredYBOT50OFF ? 0 : _.round(price.annualPayment.USD * 0.5, 2),
                EUR: isExpiredYBOT50OFF ? 0 : _.round(price.annualPayment.EUR * 0.5, 2)
            },
            YOGASASTRA20: {
                USD: isExpiredYOGASASTRA20 ? 0 : _.round(price.annualPayment.USD * 0.2, 2),
                EUR: isExpiredYOGASASTRA20 ? 0 : _.round(price.annualPayment.EUR * 0.2, 2)
            }
        },
        monthlyPayment: {
            YOGASASTRA20: {
                USD: isExpiredYOGASASTRA20 ? 0 : _.round(price.annualPayment.USD * 0.2, 2),
                EUR: isExpiredYOGASASTRA20 ? 0 : _.round(price.annualPayment.EUR * 0.2, 2)
            }
        }
    };

};

const getSubscriptionPrices = () => {

    return {
        annualPayment: !window.cordova ? price.annualPayment : { USD: cordovaPrices.annualPayment.price.USD[window.device.platform], EUR: cordovaPrices.annualPayment.price.EUR[window.device.platform] },
        monthlyPayment: !window.cordova ? price.monthlyPayment : { USD: cordovaPrices.monthlyPayment.price.USD[window.device.platform], EUR: cordovaPrices.monthlyPayment.price.EUR[window.device.platform] }
    };

};

const getOneYearPaymentPromotionPrice = () => {

    return {
        USD: 39.99,
        EUR: 35.99
    };

};

const hasOneYearPaymentPromotion = () => {

    if (Moment().startOf('days') > Moment('2020-08-31', 'YYYY-MM-DD').startOf('days')) {

        return false;

    }

    return true;

};

export {
    getOneYearPaymentPromotionPrice,
    hasYearSubscriptionPromotion,
    getStripeCouponPrices,
    getSubscriptionPrices,
    hasMonthSubscriptionPromotion,
    hasOneYearPaymentPromotion,
    hasStripeCouponPromotion
};