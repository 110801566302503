import _ from 'lodash';
import ProgressModel from '../data/models/progress/progress';
// Types
import challenge1Image from '../ui/assets/img/challenges/r1.jpg';
import challenge2Image from '../ui/assets/img/challenges/r2.jpg'
import challenge3Image from '../ui/assets/img/challenges/r3.jpg';
import challenge4Image from '../ui/assets/img/challenges/r4.jpg';
import challenge5Image from '../ui/assets/img/challenges/r5.jpg';
import challenge6Image from '../ui/assets/img/challenges/r6.jpg';

import challenge1ImageLarge from '../ui/assets/img/challenges/r1_large.jpg';
import challenge2ImageLarge from '../ui/assets/img/challenges/r2_large.jpg'
import challenge3ImageLarge from '../ui/assets/img/challenges/r3_large.jpg';
import challenge4ImageLarge from '../ui/assets/img/challenges/r4_large.jpg';
import challenge5ImageLarge from '../ui/assets/img/challenges/r5_large.jpg';
import challenge6ImageLarge from '../ui/assets/img/challenges/r6_large.jpg';

// Types
const UPDATE_ASANA_VALUE = 'UPDATE_ASANA_VALUE';
const UPDATE_CHALLENGES  = 'UPDATE_CHALLENGES';
const UPDATE_CHALLENGE_AVAILIBILITY = 'UPDATE_CHALLENGE_AVAILIBILITY';

//actions
/*
@params : asanaId, challengeId, selectedValue

*/
export const updateAsana = params  => async dispatch => {

    try {

        const response = await ProgressModel.updateAsanaValue({ ...params });

        if (response.status === 200) {

            dispatch ({ type: UPDATE_ASANA_VALUE, payload: { ...params } });

        }

    } catch (errorCode) {

        throw errorCode;

    }

};

export const getChallenges = () => async dispatch => {

    try {

        const response = await ProgressModel.getProgress();

        if (response.status === 200) {

            dispatch ({ type: UPDATE_CHALLENGES, payload: response.data });

        }

    } catch (errorCode) {

        //dispatch ({ type: UPDATE_CHALLENGES , payload: null });
        throw errorCode;

    }

};

/*
@params : challengeId (control para activar, desactivar un reto)
@desc: Se usa en la pantalla de final de reto para determinar la finalización de un reto. Se habilita la disponible del reto siguiente al reto finalizado.
*/
export const updateChallengeAvailibility = params => async dispatch => {

    try {

        const response = await ProgressModel.updateChallengeStatus({ ...params });

        if (response.status === 200) {

            dispatch({ type: UPDATE_CHALLENGE_AVAILIBILITY, payload: { ...response.data } } )

        }

    } catch (errorCode) {

        throw errorCode;

    }

};

// Reducer

//TODO LITERALES
const INITIAL_STATE = {

    redoing: false,
    nextUnlockDate: '',
    challenges : [

        {
            title: 'Reto 1',
            description: 'Fuerza y Elasticidad de Piernas y Pelvis: PARTE 1',
            subtitle: 'Nivel de Equilibrio de Flexión y Extensión',
            targetText: 'Medir tu capacidad de flexión y extensión de las piernas y la pelvis, en cuanto a tono y elasticidad.',
            cover: challenge1Image,
            coverLarge: challenge1ImageLarge,
            locked: false,
            time: '11',
            positionCount: '7',
            elasticity: '3',
            tone: '4',
            asanas: [
                {
                    description: 'Virabhadrasana I',
                    subtitle: 'Cara frontal Pierna derecha',
                    blockText: 'Elasticidad pierna derecha',
                    underBlockText: 'La pierna extendida',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/1_3.png',
                    locked: false,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO1.1.mp4',
                    selectedValue: -1,
                    type: 'elasticity',
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/1.png',
                            title: 'Rango 1',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/1_2.png',
                            title: 'Rango 2',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/1_3.png',
                            title: 'Rango 3',
                            value: 3
                        }
                    ]

                },
                {
                    description: 'Virabhadrasana I',
                    subtitle: 'Cara frontal Pierna izquierda',
                    blockText: 'Elasticidad pierna izquierda',
                    underBlockText: 'La pierna extendida',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/19_3.png',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO1.2.mp4',
                    selectedValue: -1,
                    type: 'elasticity',
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/19.png',
                            title: 'Rango 1',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/19_2.png',
                            title: 'Rango 2',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/19_3.png',
                            title: 'Rango 3',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Virabhadrasana II',
                    subtitle: 'Cara frontal Pierna derecha',
                    blockText: 'Tono pierna derecha',
                    underBlockText: 'La pierna flexionada',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/24.png',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO1.3.mp4',
                    selectedValue: -1,
                    type: 'tone',
                    ranges: {
                        min: 50,
                        max: 100
                    },
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/24.png',
                            title: 'Menos de 50 seg de permanencia',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/24.png',
                            title: 'Entre 50 - 100 seg de permanencia',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/24.png',
                            title: 'Más de 100 seg de permanencia',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Virabhadrasana II',
                    subtitle: 'Cara frontal Pierna izquierda',
                    blockText: 'Tono pierna izquierda',
                    underBlockText: 'La pierna flexionada',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/29.png',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO1.4.mp4',
                    selectedValue: -1,
                    type: 'tone',
                    ranges: {
                        min: 50,
                        max: 100
                    },
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/29.png',
                            title: 'Menos de 50 seg de permanencia',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/29.png',
                            title: 'Entre 50 - 100 seg de permanencia',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/29.png',
                            title: 'Más de 100 seg de permanencia',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Uttanasana',
                    subtitle: 'Cara posterior de ambas piernas',
                    blockText: 'Elasticidad ambas piernas',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/2_3.png',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO1.5.mp4',
                    selectedValue: -1,
                    type: 'elasticity',
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/2.png',
                            title: 'Rango 1',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/2_2.png',
                            title: 'Rango 2',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/2_3.png',
                            title: 'Rango 3',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Ardha Shalabhasana',
                    subtitle: 'Cara posterior Pierna izquierda',
                    blockText: 'Tono Pierna izquierda',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/30.png',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO1.6.mp4',
                    selectedValue: -1,
                    type: 'tone',
                    ranges: {
                        min: 50,
                        max: 100
                    },
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/30.png',
                            title: 'Menos de 50 seg de permanencia',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/30.png',
                            title: 'Entre 50 - 100 seg de permanencia',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/30.png',
                            title: 'Más de 100 seg de permanencia',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Ardha Shalabhasana',
                    subtitle: 'Cara posterior Pierna derecha',
                    blockText: 'Tono Pierna derecha',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/26.png',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO1.7.mp4',
                    selectedValue: -1,
                    type: 'tone',
                    ranges: {
                        min: 50,
                        max: 100
                    },
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/26.png',
                            title: 'Menos de 50 seg de permanencia',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/26.png',
                            title: 'Entre 50 - 100 seg de permanencia',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/26.png',
                            title: 'Más de 100 seg de permanencia',
                            value: 3
                        }
                    ]
                }
            ]
        },
        {
            title: 'Reto 2',
            description: 'Fuerza y Elasticidad de Piernas y Pelvis: PARTE 2',
            subtitle: 'Nivel de Equilibrio de Abducción y Aducción',
            targetText: 'Medir tu capacidad actual de abducción, aducción y rotación de las piernas y la pelvis, en cuanto a su nivel de tono y elasticidad.',
            cover: challenge2Image,
            coverLarge: challenge2ImageLarge,
            locked: false,
            time: '14',
            positionCount: '8',
            elasticity: '4',
            tone: '4',
            asanas:[{
                description: 'Trikonasana',
                subtitle: 'Cara interna pierna derecha',
                blockText: 'Elasticidad Pierna derecha',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/10_3_r.png',
                locked: false,
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO2.1.mp4',
                selectedValue: -1,
                type: 'elasticity',
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/10_r.png',
                        title: 'Rango 1',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/10_2_r.png',
                        title: 'Rango 2',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/10_3_r.png',
                        title: 'Rango 3',
                        value: 3
                    }
                ]
            },
            {
                description: 'Trikonasana',
                subtitle: 'Cara interna pierna izquierda',
                blockText: 'Elasticidad Pierna izquierda',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/10_3.png',
                locked: true,
                selectedValue: -1,
                type: 'elasticity',
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO2.2.mp4',
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/10.png',
                        title: 'Rango 1',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/10_2.png',
                        title: 'Rango 2',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/10_3.png',
                        title: 'Rango 3',
                        value: 3
                    }
                ]
            },
            {
                description: 'Preparatoria Koundiyasana',
                subtitle: 'Cara interna pierna derecha',
                blockText: 'Tono Pierna derecha',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/27.png',
                locked: true,
                type: 'tone',
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO2.3.mp4',
                selectedValue: -1,
                ranges: {
                    min: 50,
                    max: 100
                },
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/27.png',
                        title: 'Menos de 50 seg de permanencia',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/27.png',
                        title: 'Entre 50 - 100 seg de permanencia',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/27.png',
                        title: 'Más de 100 seg de permanencia',
                        value: 3
                    }
                ]
            },
            {
                description: 'Preparatoria Koundiyasana',
                subtitle: 'Cara interna pierna izquierda',
                blockText: 'Tono Pierna izquierda',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/32.png',
                locked: true,
                type: 'tone',
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO2.4.mp4',
                selectedValue: -1,
                ranges: {
                    min: 50,
                    max: 100
                },
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/32.png',
                        title: 'Menos de 50 seg de permanencia',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/32.png',
                        title: 'Entre 50 - 100 seg de permanencia',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/32.png',
                        title: 'Más de 100 seg de permanencia',
                        value: 3
                    }
                ]
            },
            {
                description: 'Supta Padangusta III',
                subtitle: 'Cara externa pierna derecha',
                blockText: 'Elasticidad Pierna derecha',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/18_3.png',
                locked: true,
                type: 'elasticity',
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO2.5.mp4',
                selectedValue: -1,
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/18.png',
                        title: 'Rango 1',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/18_2.png',
                        title: 'Rango 2',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/18_3.png',
                        title: 'Rango 3',
                        value: 3
                    }
                ]
            },
            {
                description: 'Supta Padangusta III',
                subtitle: 'Cara externa pierna izquierda',
                blockText: 'Elasticidad Pierna izquierda',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/3_3.png',
                locked: true,
                type: 'elasticity',
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO2.6.mp4',
                selectedValue: -1,
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/3.png',
                        title: 'Rango 1',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/3_2.png',
                        title: 'Rango 2',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/3_3.png',
                        title: 'Rango 3',
                        value: 3
                    }
                ]
            },
            {
                description: 'Preparatoria Anantasana',
                subtitle: 'Cara externa pierna derecha',
                blockText: 'Tono Pierna derecha',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/28.png',
                locked: true,
                type: 'tone',
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO2.7.mp4',
                selectedValue: -1,
                ranges: {
                    min: 50,
                    max: 100
                },
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/28.png',
                        title: 'Menos de 50 seg de permanencia',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/28.png',
                        title: 'Entre 50 - 100 seg de permanencia',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/28.png',
                        title: 'Más de 100 seg de permanencia',
                        value: 3
                    }
                ]
            },
            {
                description: 'Preparatoria Anantasana',
                subtitle: 'Cara externa pierna izquierda',
                blockText: 'Tono Pierna izquierda',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/31.png',
                locked: true,
                type: 'tone',
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO2.8.mp4',
                selectedValue: -1,
                ranges: {
                    min: 50,
                    max: 100
                },
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/31.png',
                        title: 'Menos de 50 seg de permanencia',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/31.png',
                        title: 'Entre 50 - 100 seg de permanencia',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/31.png',
                        title: 'Más de 100 seg de permanencia',
                        value: 3
                    }
                ]
            }]
        },
        {
            title: 'Reto 3',
            description: 'Fuerza y Elasticidad del Torso: PARTE 1',
            subtitle: 'Nivel de Equilibrio de Flexión y Extensión',
            targetText: 'Medir tu capacidad actual de flexión y extensión del torso, en cuanto a su nivel de tono y elasticidad.',
            cover: challenge3Image,
            coverLarge: challenge3ImageLarge,
            locked: false,
            time: '7',
            positionCount: '4',
            elasticity: '2',
            tone: '2',
            asanas:[
                {
                    description: 'Bhujangasana',
                    subtitle: 'Cara frontal',
                    blockText: 'Elasticidad',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/6_3.png',
                    locked: false,
                    type: 'elasticity',
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO3.1.mp4',
                    selectedValue: -1,
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/6.png',
                            title: 'Rango 1',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/6_2.png',
                            title: 'Rango 2',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/6_3.png',
                            title: 'Rango 3',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Shalabhasana',
                    subtitle: 'Cara posterior',
                    blockText: 'Tono',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/34.png',
                    locked: true,
                    type: 'tone',
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO3.4.mp4',
                    selectedValue: -1,
                    ranges: {
                        min: 30,
                        max: 70
                    },
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/34.png',
                            title: 'Menos de 30 seg de permanencia',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/34.png',
                            title: 'Entre 30 - 70 seg de permanencia',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/34.png',
                            title: 'Más de 70 seg de permanencia',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Preparatoria Navasana',
                    subtitle: 'Cara frontal',
                    blockText: 'Tono',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/33.png',
                    locked: true,
                    type: 'tone',
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO3.2.mp4',
                    selectedValue: -1,
                    ranges: {
                        min: 30,
                        max: 70
                    },
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/33.png',
                            title: 'Menos de 30 seg de permanencia',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/33.png',
                            title: 'Entre 30 - 70 seg de permanencia',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/33.png',
                            title: 'Más de 70 seg de permanencia',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Malasana',
                    subtitle: 'Cara posterior',
                    blockText: 'Elasticidad',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/7_3.png',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO3.3.mp4',
                    selectedValue: -1,
                    type: 'elasticity',
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/7.png',
                            title: 'Rango 1',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/7_2.png',
                            title: 'Rango 2',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/7_3.png',
                            title: 'Rango 3',
                            value: 3
                        }
                    ]
                }
            ]
        },
        {

            title: 'Reto 4',
            description: 'Fuerza y Elasticidad del Torso: PARTE 2',
            subtitle: 'Nivel de Equilibrio de Lateralización y Rotación',
            targetText: 'Medir tu capacidad actual de rotación y lateralización del torso, en cuanto a su nivel de tono y elasticidad.',
            cover: challenge4Image,
            coverLarge: challenge4ImageLarge,
            locked: true,
            time: '7',
            positionCount: '4',
            elasticity: '2',
            tone: '2',
            asanas:[
                {
                    description: 'Parighasana',
                    subtitle: 'Costado derecho',
                    blockText: 'Elasticidad',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/9_3.png',
                    locked: false,
                    type: 'elasticity',
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO4.1.mp4',
                    selectedValue: -1,
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/9.png',
                            title: 'Rango 1',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/9_2.png',
                            title: 'Rango 2',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/9_3.png',
                            title: 'Rango 3',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Parighasana',
                    subtitle: 'Costado izquierdo',
                    blockText: 'Elasticidad',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/12_3.png',
                    locked: true,
                    type: 'elasticity',
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO4.2.mp4',
                    selectedValue: -1,
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/12.png',
                            title: 'Rango 1',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/12_2.png',
                            title: 'Rango 2',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/12_3.png',
                            title: 'Rango 3',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Jathara Parivartanasana',
                    subtitle: 'Costado izquierdo',
                    blockText: 'Tono',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/36.png',
                    locked: true,
                    type: 'tone',
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO4.3.mp4',
                    selectedValue: -1,
                    ranges: {
                        min: 30,
                        max: 70
                    },
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/36.png',
                            title: 'Menos de 30 seg de permanencia',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/36.png',
                            title: 'Entre 30 - 70 seg de permanencia',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/36.png',
                            title: 'Más de 70 seg de permanencia',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Jathara Parivartanasana',
                    subtitle: 'Costado derecho',
                    blockText: 'Tono',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/35.png',
                    locked: true,
                    type: 'tone',
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO4.4.mp4',
                    selectedValue: -1,
                    ranges: {
                        min: 30,
                        max: 70
                    },
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/35.png',
                            title: 'Menos de 30 seg de permanencia',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/35.png',
                            title: 'Entre 30 - 70 seg de permanencia',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/35.png',
                            title: 'Más de 70 seg de permanencia',
                            value: 3
                        }
                    ]
                }
            ]
        },
        {
            title: 'Reto 5',
            description: 'Fuerza y Elasticidad de Hombros y Brazos: PARTE 1',
            subtitle: 'Nivel de Equilibrio del cinturón escapular',
            targetText: 'Medir tu capacidad actual de abducción, aducción y rotación de hombros y brazos, en cuanto a su nivel de tono y elasticidad.',
            cover: challenge5Image,
            coverLarge: challenge5ImageLarge,
            locked: true,
            time: '9',
            positionCount: '6',
            elasticity: '3',
            tone: '3',
            asanas:[
                {
                    description: 'Preparatoria Namaskarasana',
                    subtitle: 'Cara interna ambos brazos',
                    blockText: 'Tono',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/21.png',
                    locked: false,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO6.1.mp4',
                    selectedValue: -1,
                    type: 'tone',
                    ranges: {
                        min: 20,
                        max: 60
                    },
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/21.png',
                            title: 'Menos de 20 seg de permanencia',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/21.png',
                            title: 'Entre 20 - 60 seg de permanencia',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/21.png',
                            title: 'Más de 60 seg de permanencia',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Supta Tadasana',
                    subtitle: 'Cara interna ambos brazos',
                    blockText: 'Elasticidad',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/4_3.png',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO6.2.mp4',
                    selectedValue: -1,
                    type: 'elasticity',
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/4.png',
                            title: 'Rango 1',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/4_2.png',
                            title: 'Rango 2',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/4_3.png',
                            title: 'Rango 3',
                            value: 3
                        }
                    ]
                },

                {
                    description: 'Preparatoria Supta Garudasana',
                    subtitle: 'Cara externa brazo derecho',
                    blockText: 'Elasticidad',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/16_3.png',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO6.3.mp4',
                    selectedValue: -1,
                    type: 'elasticity',
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/16.png',
                            title: 'Rango 1',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/16_2.png',
                            title: 'Rango 2',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/16_3.png',
                            title: 'Rango 3',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Preparatoria Supta Garudasana',
                    subtitle: 'Cara externa brazo izquierdo',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/5_3.png',
                    blockText: 'Elasticidad',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO6.4.mp4',
                    selectedValue: -1,
                    type: 'elasticity',
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/5.png',
                            title: 'Rango 1',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/5_2.png',
                            title: 'Rango 2',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/5_3.png',
                            title: 'Rango 3',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Preparatoria Jathara parivartanasana',
                    subtitle: 'Cara externa brazo derecho',
                    blockText: 'Tono',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/22.png',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO6.5.mp4',
                    selectedValue: -1,
                    type: 'tone',
                    ranges: {
                        min: 20,
                        max: 60
                    },
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/22.png',
                            title: 'Menos de 20 seg de permanencia',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/22.png',
                            title: 'Entre 20 - 60 seg de permanencia',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/22.png',
                            title: 'Más de 60 seg de permanencia',
                            value: 3
                        }
                    ]
                },
                {
                    description: 'Preparatoria Jathara parivartanasana',
                    subtitle: 'Cara externa brazo izquierdo',
                    cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/23.png',
                    blockText: 'Tono',
                    locked: true,
                    video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO6.6.mp4',
                    selectedValue: -1,
                    type: 'tone',
                    ranges: {
                        min: 20,
                        max: 60
                    },
                    blocks:[
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/23.png',
                            title: 'Menos de 20 seg de permanencia',
                            value: 1
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/23.png',
                            title: 'Entre 20 - 60 seg de permanencia',
                            value: 2
                        },
                        {
                            image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/23.png',
                            title: 'Más de 60 seg de permanencia',
                            value: 3
                        }
                    ]
                }]

        },
        {
            title: 'Reto 6',
            description: 'Fuerza y Elasticidad de Hombros y Brazos: PARTE 2',
            subtitle: 'Nivel de Equilibrio de Flexión y Extensión',
            targetText: 'Medir tu capacidad actual de flexión y extensión de hombros y brazos, en cuanto a su nivel de tono y elasticidad.',
            cover: challenge6Image,
            coverLarge: challenge6ImageLarge,
            locked: true,
            time: '9',
            positionCount: '5',
            elasticity:'3',
            tone: '2',
            asanas:[ {
                description: 'Preparatoria Purvottanasana',
                subtitle: 'Cara frontal ambos brazos',
                blockText: 'Elasticidad',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/8_3.png',
                locked: false,
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO5.1.mp4',
                selectedValue: -1,
                type: 'elasticity',
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/8.png',
                        title: 'Rango 1',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/8_2.png',
                        title: 'Rango 2',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/8_3.png',
                        title: 'Rango 3',
                        value: 3
                    }
                ]
            },
            {
                description: 'Preparatoria Chaturanga',
                subtitle: 'Cara posterior ambos brazos',
                blockText: 'Tono',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/15.png',
                locked: true,
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO5.2.mp4',
                selectedValue: -1,
                type: 'tone',
                ranges: {
                    min: 20,
                    max: 60
                },
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/15.png',
                        title: 'Menos de 20 seg de permanencia',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/15.png',
                        title: 'Entre 20 - 60 seg de permanencia',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/15.png',
                        title: 'Más de 60 seg de permanencia',
                        value: 3
                    }
                ]

            },
            {
                description: 'Preparatoria Mayurasana',
                subtitle: 'Cara frontal ambos brazos',
                blockText: 'Tono',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/20.png',
                locked: true,
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO5.3.mp4',
                selectedValue: -1,
                type: 'tone',
                ranges: {
                    min: 20,
                    max: 60
                },
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/20.png',
                        title: 'Menos de 20 seg de permanencia',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/20.png',
                        title: 'Entre 20 - 60 seg de permanencia',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/20.png',
                        title: 'Más de 60 seg de permanencia',
                        value: 3
                    }
                ]

            },
            {
                description: 'Ardha Urdhva Hastasana',
                subtitle: 'Cara posterior brazo derecho',
                blockText: 'Elasticidad',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/17_3.png',
                locked: true,
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO5.4.mp4',
                selectedValue: -1,
                type: 'elasticity',
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/17.png',
                        title: 'Rango 1',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/17_2.png',
                        title: 'Rango 2',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/17_3.png',
                        title: 'Rango 3',
                        value: 3
                    }
                ]

            },
            {
                description: 'Ardha Urdhva Hastasana',
                subtitle: 'Cara posterior brazo izquierdo',
                blockText: 'Elasticidad',
                cover: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/11_3.png',
                locked: true,
                video: 'https://s3-yogabot-app.s3-eu-west-1.amazonaws.com/asanas/videos/mediciones/RETO5.5.mp4',
                selectedValue: -1,
                type: 'elasticity',
                blocks:[
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/11.png',
                        title: 'Rango 1',
                        value: 1
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/11_2.png',
                        title: 'Rango 2',
                        value: 2
                    },
                    {
                        image: 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/siluetas_formulario/11_3.png',
                        title: 'Rango 3',
                        value: 3
                    }
                ]

            }]

        }

    ]

};

function reducer (state = INITIAL_STATE, action) {

    switch (action.type) {

        case UPDATE_ASANA_VALUE:

            let tempState = {...state}
            let asanaId = action.payload.asanaId;
            tempState.challenges[action.payload.challengeId].asanas[action.payload.asanaId].selectedValue = action.payload.selectedValue;
            tempState.challenges[action.payload.challengeId].asanas[action.payload.asanaId].locked = false;
            asanaId++;

            if (_.get(tempState.challenges, `[${action.payload.challengeId}].asanas[${asanaId}]`, false)) {

                tempState.challenges[action.payload.challengeId].asanas[asanaId].locked = false;

            }

            return { ...state, challenges: tempState.challenges , nextUnlockDate: tempState.nextUnlockDate || '', redoing: tempState.redoing };

        case UPDATE_CHALLENGES:

            let currentChallenges = {...state};
            let challenges = _.cloneDeep(action.payload);//el que viene de api
            currentChallenges.progress = action.payload.progress;
            currentChallenges.nextUnlockDate = challenges.nextUnlockDate || '';
            currentChallenges.redoing = challenges.redoing || false;

            //TODO hay que quitar este pop cuando normalicemos en api el segundo reto que debe tener 8 en vez de 9 ásana, sólo pasa con registros viejos..
            if (challenges.challenges[1].exercises.length > 8) {

                challenges.challenges[1].exercises.pop();

            }

            _.each(challenges.challenges, (challenge, challengeIndex) => {

                currentChallenges.challenges[challengeIndex].locked = challenge.locked;
                let availableAsanaIndex = _.findIndex(challenges.challenges[challengeIndex].exercises,  exercise => exercise.value === 0);

                _.each(challenge.exercises, (exercise, asanaIndex) => {

                    currentChallenges.challenges[challengeIndex].asanas[asanaIndex].selectedValue = exercise.value;
                    currentChallenges.challenges[challengeIndex].asanas[asanaIndex].locked = exercise.value === 0 && availableAsanaIndex !== asanaIndex;

                });

            });

            return { ...state, challenges: currentChallenges.challenges , nextUnlockDate: currentChallenges.nextUnlockDate || '',  redoing: currentChallenges.redoing };

        case UPDATE_CHALLENGE_AVAILIBILITY:

                let current = {...state};
                let updatedChallenges =  action.payload.challenges;

                _.each(updatedChallenges, (challenge, challengeIndex) => {

                    current.challenges[challengeIndex].locked = challenge.locked;

                });


            return { ...state, challenges: current.challenges, progress: current.progress };

        default:

            return state;

    }

};

export default reducer;