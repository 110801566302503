import React from 'react'
import I18n from '../../../../../i18n';
import { FrameNav, NavItems, Item } from './styles';

export default function StepsNav ({ activedStep = 1, history, ...restProps }) {

    return (
        <FrameNav>
            <NavItems>
                <Item active={ activedStep === 1 }>
                    <span className="paragraph">{ I18n.t('navSteps.stepText', { step: 1 }) }</span>
                </Item>
                <Item active={ activedStep === 2 }>
                    <span className="paragraph">{ I18n.t('navSteps.stepText', { step: 2 }) }</span>
                </Item>
                <Item active={ activedStep === 3 }>
                    <span className="paragraph">{ I18n.t('navSteps.stepText', { step: 3 }) }</span>
                </Item>
            </NavItems>
        </FrameNav>

    )
}