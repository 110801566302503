import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react';
import { AccordionHeader, AccordionContent, AccordionText, AccordionIcon } from './styled';

const faqs = [{

        title: <h2 className="heading-3 upper white">¿Necesito tener experiencia en yoga para usar Yogabot?</h2>,
        content:[
            <p key={1} className="paragraph regular">No necesitas tener experiencia previa en la práctica de yoga para sacarle partido a Yogabot.</p>,
            <p key={2} className="paragraph regular"> Gracias al sistema de<strong> autodiagnóstico, personalización y acompañamiento</strong> en vídeo por el maestro José Antonio Cao, con más de 20 años de experiencia impartiendo cursos y formando a instructores internacionales, Yogabot es la puerta de entrada ideal si quieres <strong>iniciarte en la práctica de yoga.</strong></p>
    ]},
    {
        title: <h2 className="heading-3 upper white">¿Las sesiones de yoga personalizadas están guiadas?</h2>,
        content:[
            <p key={3} className="paragraph regular">Sí. En la versión Premium de Yogabot <strong>podrás seguir en vídeo tus secuencias de asanas realizadas por el maestro José Antonio Cao.</strong> Además, podrás escoger entre escuchar las indicaciones técnicas sobre la ejecución óptima para cada postura o experimentar tu práctica en silencio</p>,
            <p key={4} className="paragraph regular">Si tienes una cuenta Yogabot Lite también disfrutarás de sesiones guiadas, pero en este caso serán secuencias en imágenes en lugar de vídeos.</p>
        ]
    },
    {
        title: <h2 className="heading-3 upper white">¿Me sirve Yogabot si soy profesor de yoga?</h2>,
        content:[
            <p key={5} className="paragraph regular">Por supuesto. Es más, <strong>si impartes clases de yoga</strong>, con Yogabot no solo sentirás la diferencia en tu propia práctica, sino que también <strong>llevarás tus clases a otro nivel:</strong></p>,
            <ul key={6}>
                <li><p className="paragraph regular">Aprenderás cómo analizar y abordar las capacidades de cada estudiante según la Pedagogía NEXOYOGA.</p></li>
                <li><p className="paragraph regular">Si tienes una cuenta PREMIUM, tus alumnos podrán compartir su Matriz Biométrica contigo.</p></li>
                <li><p className="paragraph regular">Serás capaz de crear secuencias de asanas optimizadas para cada grupo combinando sus matrices individuales.</p></li>
            </ul>
        ]
    },
    {
        title: <h2 className="heading-3 upper white">¿Qué ocurre con mi cuenta PREMIUM cuando terminan los 15 días gratis?</h2>,
        content:[
            <p key={7} className="paragraph regular">Tras el período de prueba, tienes dos opciones:</p>,
            <ul key={8}>
                <li><p className="paragraph regular">Puedes suscribirte a Yogabot PREMIUM por solo 4,99$/mes (pago anual) para mantener todas las ventajas y funcionalidades de Yogabot.</p></li>
                <li><p className="paragraph regular">O mantener tu cuenta con la versión gratuita Yogabot LITE, siempre conservando tu matriz biométrica actualizada y el acceso a secuencias personalizadas.</p></li>
            </ul>
        ]
    },
    {
        title: <h2 className="heading-3 upper white">¿Puedo cancelar cuando quiera?</h2>,
        content:[
            <p key={9} className="paragraph regular">Por supuesto. Puedes para cancelar tu suscripción PREMIUM en cualquier momento o cancelar tu cuenta por completo desde el apartado «cuenta» en la aplicación.</p>
        ]
    },
    {
        title: <h2 className="heading-3 upper white">¿Cada cuánto tiempo podré actualizar mi diagnóstico personal?</h2>,
        content:[
            <p key={10}className="paragraph regular">Después de completar tu primer diagnóstico, los retos quedan bloqueados durante un tiempo mínimo recomendado para que pongas en práctica tu secuencia de asanas personalizada.</p>,
            <p key={11} className="paragraph regular">El desbloqueo se realizará dependiendo de tu nivel de suscripción a Yogabot: </p>,
            <ul key={12}>
                <li><p className="paragraph regular">Cuenta PREMIUM: cada 7 días o 10 horas de práctica.</p></li>
                <li><p className="paragraph regular">Cuenta LITE (básico): cada 21 días o 25 horas de práctica.</p></li>
            </ul>
        ]
    },
    {
        title: <h2 className="heading-3 upper white">¿Cuánto tiempo debo practicar mi secuencia de asanas recomendada?</h2>,
        content:[
            <p key={13} className="paragraph regular">¿Ya has completado los 6 retos de tu primer diagnóstico y tienes tu Matriz Biométrica actualizada al 100%? </p>,
            <p key={14} className="paragraph regular">Este es el momento de poner en práctica tu secuencia personalizada durante varias semanas para notar cambios reales en tu cuerpo.</p>,
            <p key={15} className="paragraph regular">Cuando hayas acumulado entre 25 y 30 horas de práctica, vuelve a completar los 6 retos del diagnóstico para actualizar tu Matriz Biométrica y optimizar así tu nueva secuencia de asanas a medida.</p>,

        ]
    }];

const Accordion = ({ i, expanded, setExpanded, item: {title, content} }) => {

    const isOpen = i === expanded;

    return (
        <>
            <AccordionHeader initial={false} onClick={() => setExpanded(isOpen ? false : i)} role="button" aria-hidden={!isOpen} aria-expanded={isOpen}>
                {title}
                <AccordionIcon >
                    {isOpen ? 
                            <Icon className="brand-white" name="caret down" size="large"></Icon>
                        : 
                            <Icon size="large" className="brand-white" name="caret right"></Icon>
                    }
                </AccordionIcon>
            </AccordionHeader>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <AccordionContent 
                        key={i}
                        initial="collapsed"
                        animate="open"
                        exit="collapsed" 
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}>
                        <AccordionText variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }} transition={{ duration: 0.8 }}>
                            {content}
                        </AccordionText>
                    </AccordionContent>
                )}
             </AnimatePresence>
        </>
    )
}


export const FaqAccordion = () => {

    const [expanded, setExpanded] = useState(0);

    return faqs.map((item, i) => (
        <Accordion i={i} key={i} expanded={expanded} setExpanded={setExpanded} item={item}/>
      ));

}