import React, { Component } from 'react'
import moment from 'moment'

import './countDownSimple.scss';

class CountDownSimple extends Component {
    
    constructor(props) {

        super(props);

        this.state = {
            countdown: '00:00:00:00'
        }

    }

    componentWillUnmount() {

        window.clearInterval(this.interval);

    }

    componentDidMount() {

        this.interval = setInterval(() => {

            const { endDate } = this.props;
            const then = moment(endDate);
            const now = moment();
            const countdown = moment(then - now);
            const hours = countdown.format('HH');
            const minutes = countdown.format('mm');
            const seconds = countdown.format('ss');
            const days = then.diff(now, 'days');
            let formatted = days + ':' + hours + ':' + minutes + ':' + seconds;

            if (then <= now) {

                clearInterval(this.interval);
                this.setState({ countdown: '00:00:00:00' });

            } else {

                this.setState({ countdown: formatted });

            }


        }, 1000);
    }

    render() {

        const { countdown } = this.state

        return <span className="counter-items">{ countdown }</span>;

    }

}

export default CountDownSimple;