import React, { Component } from 'react'
import { LandingHeader } from '../../components/header'
import { ButtonWrapper, CtaLogin, CtaRegister, InnerSection, LandingWrap, LegacyPoint, LegacyPointIcon, LegacyPoints, Section, SectionGrid, SectionGridItemImage, SectionGridItemText, SectionSubHeaderCta, SectionSubHeaderLogo } from '../../styled'
import OButton from '../../../../../../styled/components/button';

import HeroImage from '../../../../../../assets/img/main_landing/tecnica.svg';
import StudioImage from '../../../../../../assets/img/main_landing/tecnica2.svg';

import StudioImage2 from '../../../../../../assets/img/main_landing/tecnica3.svg';

import StudioImage3 from '../../../../../../assets/img/main_landing/tecnica4.png';
import StudioImage4 from '../../../../../../assets/img/main_landing/tecnica5.svg';
import StudioImage5 from '../../../../../../assets/img/main_landing/tecnica6.jpg';

import Footer from '../../../../../../components/footer';
import { Icon } from 'semantic-ui-react';
import { InstructionsWrapper } from './styled';
import { motion } from 'framer-motion';
import { CtaMarkets } from '../../components/ctaMarket';
import { motionSubtitle, motionTitle, transition, motionButton } from '../../../../../../../utils/framerAnimations';
import { TopSeparator } from '../../components/prices/styled';

export default class TechniquesLanding extends Component {
    
    render() {
        return (
            <>
                <LandingWrap>
                    <LandingHeader active="studio" />
                    <Section>
                        <InnerSection sectionSubHeader>
                            <SectionSubHeaderLogo onClick={() => this.props.history.push("/")} />
                            <SectionSubHeaderCta>
                                    <CtaLogin to="/auth/login">Inicia sesión</CtaLogin>
                                    <CtaRegister to="/auth/register">Prueba gratis</CtaRegister>
                            </SectionSubHeaderCta>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid>
                                <SectionGridItemImage >
                                    <motion.img src={HeroImage} alt="" initial={{ y: 20, opacity: 0 }} animate = {{y: 0,opacity: 1, transition: transition}}/>
                                </SectionGridItemImage>
                                <SectionGridItemText style={{textAlign:'left'}}>
                                    <motion.div initial="initial" animate="animate" exit='exit'>
                                        <motion.h1 variants={motionTitle} className="landing-heading-1">Perfecciona tu técnica</motion.h1>
                                        <motion.p variants={motionSubtitle} className="landing-heading-2 regular">Aprende a Enriquecer y Expandir tu experiencia en Asana.</motion.p>
                                    </motion.div>
                                    <motion.div initial="initial" animate="animate" exit='exit' variants={motionButton}>
                                        <ButtonWrapper reverse>
                                            <OButton upper type="button" color="#FFF" primary size="large" onClick={() => this.props.history.push('/auth/login')}>
                                                <span className="toregular">EMPIEZA AHORA</span>
                                            </OButton>
                                        </ButtonWrapper>
                                    </motion.div>
                                </SectionGridItemText>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid reverse>
                                <SectionGridItemText reverse>
                                        <p className="paragraph regular">Yogabot es un excelente sistema de acompañamiento de práctica, pero no sería una aplicación tan completa si no contara con un apartado de estudio, donde puedas <strong>aprender y profundizar</strong> con detalles, la <strong>técnica de ejecución de cada ejercicio</strong>.</p>
                                        <p className="paragraph regular">En el apartado de ESTUDIO de Yogabot dispones de <strong>58 videos técnicos</strong> que corresponden a las posturas de nivel 0 y 1 de complejidad en la Pedagogía NEXOYOGA.</p>
                                        <p className="paragraph regular">Puedes disfrutar de una práctica personalizada en tres formatos.</p>
                                </SectionGridItemText>
                                <SectionGridItemImage reverse fullImage={true}>
                                    <img src={StudioImage} alt="" />
                                </SectionGridItemImage>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection>
                            <InstructionsWrapper>
                            <p className="landing-heading-2 regular title">Cada video explica de forma rigurosa, y con un enfoque pedagógico, los principales aspectos e instrucciones que debes dominar en la técnica de una postura:</p>
                                <LegacyPoints>
                                        <LegacyPoint>
                                            <LegacyPointIcon>
                                                <Icon name='check' />
                                            </LegacyPointIcon>
                                            <p className="paragraph regular">Instrucciones de seguridad: para que puedas sacar el máximo rendimiento físico de una postura, sin lesión.</p>
                                        </LegacyPoint>
                                        <LegacyPoint>
                                            <LegacyPointIcon>
                                                <Icon name='check' />
                                            </LegacyPointIcon>
                                            <p className="paragraph regular">Instrucciones de integridad: para que puedas coordinar todo tu cuerpo y aumentar el impacto de la postura a nivel fisiológico.</p>
                                        </LegacyPoint>
                                        <LegacyPoint>
                                            <LegacyPointIcon>
                                                <Icon name='check' />
                                            </LegacyPointIcon>
                                            <p className="paragraph regular">Instrucciones de expansión, para que logres intensificar la acción de cada área corporal y aumentes el nivel de energía de tu cuerpo y tu mente.</p>
                                        </LegacyPoint>
                                        <LegacyPoint>
                                            <LegacyPointIcon>
                                                <Icon name='check' />
                                            </LegacyPointIcon>
                                            <p className="paragraph regular">Instrucciones de permanencia: para que aprendas a profundizar en el estado de consciencia del asana mientras sostienes el ejercicio, ganando estabilidad, focalización y lucidez mental.</p>
                                        </LegacyPoint>
                                </LegacyPoints>
                            </InstructionsWrapper>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid >
                            <SectionGridItemImage fullImage={true}>
                                    <img src={StudioImage2} alt=""  />
                                </SectionGridItemImage>
                                <SectionGridItemText style={{textAlign:'left'}} >
                                    <h1 className="landing-heading-2">¿Quieres perfeccionar tu técnica en alguna postura en particular?</h1>
                                    <p className="paragraph regular">Adquiere su vídeo técnico y estudialo tantas veces como quieras, sin límite de tiempo y desde la comodidad de tu dispositivo. Donde quieras, cuando quieras y a tu ritmo.</p>
                                    <p className="paragraph regular">Aprende a sacar el mayor rendimiento en la ejecución de cada esa postura, disfruta de un conocimiento de práctica mayor y explota el máximo potencial que ofrece el asana en tu secuencia.</p>
                                </SectionGridItemText>
                                
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid reverse>
                                <SectionGridItemText style={{textAlign:'left'}} reverse>
                                    <h1 className="landing-heading-2">Prioriza tu estudio de Asana</h1>
                                    <p className="paragraph regular">¿Qué asanas debes estudiar primero?</p>
                                    <p className="paragraph regular">Prioriza el estudio de aquellas posturas que formen parte de tu actual secuencia de Práctica Personalizada y sobre todo en las que te sientas inseguro/a. Las instrucciones de seguridad son las primeras que debes dominar, para evitar lesiones.  Escucha a tu cuerpo.</p>
                                    <p className="paragraph regular">Luego, prioriza las posturas en las que sientas algún tipo de resistencia, porque probablemente son las que están limitando tu crecimiento en ASANA.</p>
                                    <p className="paragraph regular">Si no tienes claro qué posturas deberías estudiar primero, te sugerimos realizar una consulta para que un profesor Advisor de Yogabot te comente cuáles son las asanas fundamentales a controlar según tu matriz biométrica y tus objetivos de práctica. Así podrás maximizar tus resultados.</p>
                                </SectionGridItemText>
                                <SectionGridItemImage fullImage={true} reverse >
                                    <img src={StudioImage3} alt="" />
                                </SectionGridItemImage>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid >
                            <SectionGridItemImage fullImage={true}>
                                    <img src={StudioImage4} alt="" />
                                </SectionGridItemImage>
                                <SectionGridItemText style={{textAlign:'left'}} >
                                    <h1 className="landing-heading-2">Actualización del sistema de Asanas.</h1>
                                    <p className="paragraph regular">Aunque el sistema de 58 posturas que puedes estudiar en Yogabot es más que suficiente para mantener la evolución de tu nivel de práctica personal, se irán añadiendo nuevos videos de ejecución técnica.</p>
                                    <p className="paragraph regular">Es decir, se incluirán posturas de Nivel 2 y 3 dentro del sistema de Asana de la Pedagogía NEXOYOGA, con mayor nivel de complejidad e intensidad.</p>
                                </SectionGridItemText>
                                
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <Section>
                        <InnerSection sectionGrid>
                            <SectionGrid reverse>
                                <SectionGridItemText style={{textAlign:'left'}} reverse>
                                    <h1 className="landing-heading-2">Estudiar todas las asanas</h1>
                                    <p className="paragraph regular">Si eres profesor/a de Yoga y/o quieres estudiar todo el “Sistema de Asana” de Matriz Biométrica, te recomendamos tomar el curso completo: <a className="brand-secondary" href="https://academianexoyoga.com/p/yoga-a-tu-medida" target="_blank" rel="noopener noreferrer">El arte de dominar Asana, en la Academia NEXOYOGA.</a></p>
                                    <p className="paragraph regular">Si adquieres el curso completo, se incluirán los 58 videos de ejecución técnica en el apartado de Estudio de Yogabot.</p>
                                </SectionGridItemText>
                                <SectionGridItemImage reverse >
                                    <img src={StudioImage5} alt="" />
                                </SectionGridItemImage>
                            </SectionGrid>
                        </InnerSection>
                    </Section>
                    <TopSeparator/>
                    <CtaMarkets />
                    <Footer {...this.props} type="branded" hasExtraFooter={true}/>
               </LandingWrap>
            </>
        )
    }
}
